import React, { createRef } from "react";
import { Link } from "react-router-dom";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "pages/_components/ListView/ListView";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import FileExport from "../../../_components/FileExport/FileExport";
// css
import "./css/Workflow.css";
// icons
import SearchIcon from "img/search-icn.png";
const child = createRef();

class WorkflowList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 15,
      tlConfig: {
        columnId: "RuleId",
        headCells: [
          {
            id: "RuleName",
            value: "RuleName",
            label: "Rule Name",
            type: "link",
          },
          {
            id: "Cattr1",
            value: "Cattr1",
            label: "Module",
            type: "",
          },
          {
            id: "RuleType",
            value: "RuleType",
            label: "Execute On",
            type: "",
          },
          // {
          //   id: "Actions",
          //   value: "Actions",
          //   label: "Actions",
          //   type: "",
          // },
          {
            id: "ModifiedOn",
            value: "ModifiedOn",
            label: "Last Modified",
            type: "date",
          },
          {
            id: "Active",
            value: "Active",
            label: "Status",
            type: "",
          },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "RuleName",
        selected: [],
        uniqueKey: "WorkRulecolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
      filterRuleName: "",
      userPermissions: common.getPermission(this.props, "configure:automation"),
      headers: [
        { label: 'ID', key: 'RuleId' },
        { label: 'Rule Name', key: 'RuleName' },
        { label: 'Module', key: 'Cattr1' },
        { label: 'Execute On', key: 'RuleType' },
        { label: 'Last Modified', key: 'ModifiedOn' },
        { label: 'Status', key: 'Active' }
      ],
      urls: "",
      totalResults: "",
      keyArray: ["RuleId", "RuleName", "Cattr1", "RuleType", "ModifiedOn_date", "Active"]
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map(
            (n) => n.RuleId
          );
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterRuleName,
      userPermissions,
    } = this.state;
    if (currentPage > 0) {
      this.setState({ isLoading: true });

      let filter = `?q=UPPER(RuleName) LIKE '*${filterRuleName}*' &totalResults=true&orderBy=CreatedBy:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
        }`;

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_AUTOMATION + filter
      );

      restServices.getRequest(
        url,
        (response) => {
          let deletePermission = userPermissions.delete;
          tlConfig.enableMultipleDelete =
            deletePermission &&
              response &&
              response.items &&
              response.items.length > 0
              ? true
              : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete
            ? response.items.length
            : 0;

          let temp = {};
          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.map((item) => {
              list.push(item);
            });
            temp.items = list;
          } else {
            temp = response;
          }

          this.setState({
            listRes: temp,
            isLoading: false,
            tlConfig: tlConfig,
            currentPage: response.hasMore ? currentPage + 1 : 0,
            urls: envConfig.BASE_API + Services.CHOLA_AUTOMATION,
            totalResults: response.totalResults
          }, () => {
            window.addEventListener("scroll", this.onScroll);
            if (child.current) {
              child.current.getCsvData();
            }
          });
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
          });
        }
      );
    }
  };

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      isLoading,
      filterRuleName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      headers,
      urls,
      totalResults,
      keyArray,
      OrderBy
    } = this.state;

    const isHided = (RueId) =>
      tlConfig.columnsSelected.indexOf(RueId) !== -1;
    const isSelected = (RueId) =>
      tlConfig.selected.indexOf(RueId) !== -1;

    const handleClick = (event, RueId) => {
      const selectedIndex = tlConfig.selected.indexOf(RueId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, RueId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?q=UPPER(RuleName) LIKE '*${filterRuleName}*' &totalResults=true&orderBy=CreatedBy:desc&limit=${totalResults}&offset=0`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }
    return (
      <div className="deal-wraper">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            {/* end of Secondary Nav */}
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-4">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">Automation Rule</h2>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  <div className="form-row justify-content-end">
                    <div className="col-lg-5">
                      <label className="sr-only" htmlFor="inlineFormInputGroup">
                        Filter and Search
                      </label>
                      <div className="input-group search-filter-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder="+ Filter Rule Name"
                          name="filterRuleName"
                          value={filterRuleName}
                          onChange={this.handleFieldChange}
                        />
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <img
                              src={SearchIcon}
                              alt="Search"
                              onClick={this.filterData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <EnhancedTableColumns
                        tlConfig={tlConfig}
                        updateTLConfig={this.updateTLConfig}
                      />
                    </div>
                    {userPermissions.export && (
                      <div className="col-auto">
                        <FileExport
                          ref={child}
                          headers={headers}
                          filename={"Work_flow_details.xls"}
                          urls={urls}
                          keyArray={keyArray}
                          filter={filter}
                        />
                      </div>
                    )}
                    {userPermissions.add && (
                      <div className="col-auto">
                        <Link to="/setup/configure/automation/workflow/add">
                          <button type="button" className="btn btn-add">
                            Add Rule
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            <div className="crm-data-wrap">
              <div className="clearfix"></div>
              <ListView
                tlConfig={tlConfig}
                updateTLConfig={this.updateTLConfig}
                isLoading={isLoading}
                data={listRes}
                isSelected={isSelected}
                handleClick={handleClick}
                isHided={isHided}
                renderStatus={this.renderStatus}
                handlePageChange={this.handlePageChange}
                currentPage={currentPage}
                pageLimit={pageLimit}
                search={filterRuleName}
                from="rule"
                filterData={this.filterData}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WorkflowList;