import React, { createRef } from "react";
// Import redux packages
import { connect } from "react-redux";
import { commonActions } from "_actions/common.actions";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "../_components/ListView/ListView";
import KanbanBoard from "../_components/KanbanView/KanbanBoard";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import { Row, Col, Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../_components/_loader/Loader";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import NoRecordFound from "../../_components/_noRecordFound/NoRecordFound";
import Select from 'react-select';
// css
import "./Reports.css";
// icons
import SearchIcon from "img/search-icn.png";
const child = createRef();
const userInfo = common.authInfo();
class ReportsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 10,
      tlConfig: {
        columnId: "ReportId",
        columnName: "ReportName",
        columnCode: "ReportCode",
        headCells: [
          {
            id: "ReportName",
            value: "ReportName",
            label: "NAME",
            type: "link",
          },
          // {
          //   id: "ReportCode",
          //   value: "ReportCode",
          //   label: "Code",
          //   type: "",
          // },
          {
            id: "Description",
            value: "Description",
            label: "DESCRIPTION",
            type: "",
          },
          // {
          //   id: "StartDate",
          //   value: "StartDate",
          //   label: "START DATE",
          //   type: "date",
          // }
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "StartDate",
        selected: [],
        uniqueKey: "ReportsListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0
      },
      filterReportName: "",
      crmStatusList: [],
      userPermissions: common.getPermission(this.props, "reports"),
      headers: [
        { label: 'ID', key: 'ReportId' },
        { label: 'Report Name', key: 'ReportName' },
        { label: 'Report Code', key: 'ReportCode' },
        { label: 'Description', key: 'Description' },
        { label: 'Start Date', key: 'StartDate' },
      ],
      urls: "",
      totalResults: "",
      keyArray: ["ReportId", "ReportName", "ReportCode", "Description", "StartDate_date"],
      userId: userInfo.EmpId,
      userName: userInfo.UserName,
      isModal: false,
      paramList: [],
      isModalLoading: false,
      reportId: "",
      reportName: "",
      reportCode: "",
      fieldsArray: [],
      formSubmit: false
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      // this.getLookUpData("TASK_STATUS");
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.TaskId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (_) => {
    this.setState(
      {
        isListView: !this.state.isListView,
      },
      (_) => {
        if (this.state.isListView) {
          window.addEventListener("scroll", this.onScroll);
        } else {
          window.removeEventListener("scroll", this.onScroll, false);
        }
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterReportName,
      userPermissions,
    } = this.state;

    if (currentPage > 0) {
      this.setState({ isLoading: true });
      let filter = `?q=ActiveReport='${"Y"}' AND UPPER(ReportName) LIKE '*${filterReportName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(currentPage - 1) * pageLimit
        }&limit=${pageLimit}`;

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }
      let url = encodeURI(envConfig.BASE_API + Services.REPORTS + filter);
      restServices.getRequest(
        url,
        (response) => {
          let deletePermission = userPermissions.delete;
          tlConfig.enableMultipleDelete =
            deletePermission &&
              response &&
              response.items &&
              response.items.length > 0
              ? true
              : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete
            ? response.items.length
            : 0;

          let temp = {};
          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.length > 0 && response.items.map((item) => {
              // if(item.ActiveReport === "Y"){
              list.push(item);
              // }
            });
            temp.items = list;
          } else {
            // let filterarr = response.items.length > 0 && response.items.filter((val => { return val.ActiveReport == "Y"}));
            // response.items = filterarr;
            temp = response;
          }
          this.setState({
            listRes: temp,
            isLoading: false,
            tlConfig: tlConfig,
            currentPage: response.hasMore ? currentPage + 1 : 0,
            urls: envConfig.BASE_API + Services.REPORTS,
            totalResults: response.totalResults
          }, () => {
            // if(temp.items.length > 10){
            //   this.getList();
            // }
            window.addEventListener("scroll", this.onScroll);
            if (child.current) {
              child.current.getCsvData();
            }
          });
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
          });
        }
      );
    }
  };

  getLookUpData = (lookupValue) => {
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let crmStatusList = response.items;
          this.setState({
            crmStatusList
          });
        }
      },
      (error) => { }
    )
  };


  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmDealStatus } = props;

    var crmStatusData = state.crmStatusList;

    if (crmDealStatus && crmDealStatus.list && crmDealStatus.list.success) {
      crmStatusData = crmDealStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  handleModal = (id, name, code) => {
    const { currentPage, tlConfig, pageLimit } = this.state;
    this.setState({
      isModalLoading: true,
      reportId: id,
      reportName: name,
      reportCode: code,
      formSubmit: false
    })
    let filter =
      `?finder=FindReportParams;pReportId=${id}&totalResults=true&expand=all`;

    if (tlConfig.OrderBy) {
      filter += "&orderBy=" + tlConfig.OrderBy;
    }

    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_REPORT_PARAMS + filter
    );

    restServices.getRequest(
      url,
      (response) => {
        let fieldArray = [];
        response.items.length > 0 && response.items.map((item) => {
          if(item.Active == "Y"){
            let fieldData = {
              name: item.RequiredParam == "N" ? item.ParamName : item.ParamName + "*",
              value: "",
              code: item.ParamCode,
              isReq: item.RequiredParam == "N" ? false : true,
              error: item.RequiredParam == "N" ? "" : "Please enter the " + item.ParamName,
              type: item.ParamType,
              CholaVlookupValuesView: common.getOptionsData(item.CholaVlookupValuesView, "Name", "Code")
            };
            fieldArray.push(fieldData);
          }
        })
        this.setState({
          paramList: response,
          isModal: true,
          isModalLoading: false,
          fieldsArray: fieldArray
        })
      },
      (error) => {
        this.setState({
          isModalLoading: false
        })
      }
    );
  }

  closeModal = () => {
    this.setState({
      isModal: false
    })
  }

  handleOnChange = (event) => {
    const { fieldsArray } = this.state;
    const { name, value } = event.target;
    fieldsArray.length > 0 && fieldsArray.map((item) => {
      if (name == item.name) {
        if (value !== "") {
          item.value = value;
          item.error = "";
        } else {
          item.value = "";
          if(item.RequiredParam == "Y"){
            item.error = "Please enter the " + item.name;
          }
        }
      }
    })
    this.setState(
      {
        fieldsArray,
      });
  };

  handleDateOnChange = (date, name) => {
    const { fieldsArray } = this.state;
    fieldsArray.length > 0 && fieldsArray.map((item) => {
      if (name == item.name) {
        if (date !== "") {
          item.value = date;
          item.error = "";
        } else {
          item.value = "";
          if(item.RequiredParam == "Y"){
            item.error = "Please enter the " + item.name;
          }
        }
      }
    })
    this.setState(
      {
        fieldsArray,
      });
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fieldsArray } = this.state;
    fieldsArray.length > 0 && fieldsArray.map((item) => {
      if (name == item.name) {
          item.value = value;
          item.error = "";
        } else {
          // item.value = "";
          if(item.RequiredParam == "Y"){
            item.error = "Please enter the " + item.name;
          }
        }
    })
    this.setState(
      {
        fieldsArray,
      });
  }

  getFile = (format) => {
    const { fieldsArray, reportName, reportCode } = this.state;
    let fields = {};
    this.setState({
      formSubmit: true
    })
    let isValid = this.validateAll();
    if (isValid) {
      fieldsArray.length > 0 && fieldsArray.map((item) => {
        if (item.value !== "") {
          fields[item.code] = item.value;
        }
      })
      let filter = `?repname=${reportCode}&repformat=${format}`;
      let url = encodeURI(envConfig.FILE_URL + Services.CHOLA_REPORT_DOWNLOAD + filter);
      restServices.filePostRequest(
        url,
        fields,
        (response) => {
          if (response.size > 0) {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            let extension = format == "pdf" ? "pdf" : "xls"
            link.setAttribute('download', `${reportName.replace("Opportunity", "Deal")}.${extension}`);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          } else {
            common.snack("E", "No Data Found")
          }
          this.filterDataReset();
          this.setState({
            formSubmit: false
          })
        },
        (error) => {
          common.snack("E", error);
          this.setState({
            formSubmit: false
          })
        }
      );
    }
  }

  filterDataReset = () => {
    const { paramList } = this.state;
    let fieldArray = [];
    paramList.items.length > 0 && paramList.items.map((item) => {
      if(item.Active == "Y"){
        let fieldData = {
          name: item.RequiredParam == "N" ? item.ParamName : item.ParamName + "*",
          value: "",
          code: item.ParamCode,
          isReq: item.RequiredParam == "N" ? false : true,
          error: item.RequiredParam == "N" ? "" : "Please enter the " + item.ParamName,
          type: item.ParamType,
          CholaVlookupValuesView: common.getOptionsData(item.CholaVlookupValuesView, "Name", "Code")
        };
        fieldArray.push(fieldData);
      }
    })
    this.setState({
      fieldsArray: fieldArray
    })
  }

  validateAll() {
    const { fieldsArray, errors } = this.state;
    let isValid = true;
    fieldsArray.length > 0 && fieldsArray.map((item) => {
      if (item.isReq) {
        if (item.value == "" || item.value == null) {
          item.error = "Please enter the " + item.name;
          isValid = false
        }
      }
    })
    this.setState({
      fieldsArray
    })
    return isValid;
  }

  render() {
    const {
      isLoading,
      filterReportName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      isModal,
      isModalLoading,
      fieldsArray,
      formSubmit
    } = this.state;
    const isHided = (TaskId) =>
      tlConfig.columnsSelected.indexOf(TaskId) !== -1;
    const isSelected = (TaskId) =>
      tlConfig.selected.indexOf(TaskId) !== -1;

    const handleClick = (event, TaskId) => {
    };

    return (
      <div className="deal-wraper">
        {userPermissions && !userPermissions.access ? (
          <AccessDenied />
        ) : (
            <div>
              {/* end of Secondary Nav */}
              <div className="filter-wrap">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-4">
                    <div className="form-row">
                      <div className="col">
                        <h2 className="crm-type-title">Reports</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    {this.state.isListView && (
                      <div className="form-row justify-content-end">
                        <div className="col-lg-5">
                          {/* <label className="sr-only" htmlFor="inlineFormInputGroup">
                            Filter and Search
                      </label> */}
                          <div className="input-group search-filter-group mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder="+ Filter Report Name"
                              name="filterReportName"
                              value={filterReportName}
                              onChange={this.handleFieldChange}
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <img
                                  src={SearchIcon}
                                  alt="Search"
                                  onClick={this.filterData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <EnhancedTableColumns
                            tlConfig={tlConfig}
                            updateTLConfig={this.updateTLConfig}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
              {/* end of Filter Wrap */}
              <div className="crm-data-wrap">
                <div className="clearfix"></div>
                  <ListView
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                    isLoading={isLoading}
                    data={listRes}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    isHided={isHided}
                    renderStatus={this.renderStatus}
                    handlePageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageLimit={pageLimit}
                    search={filterReportName}
                    from="reports"
                    filterData={this.filterData}
                    deptEmployees={this.props.deptEmployees}
                    modalOpen={this.handleModal}
                  />
              </div>
              {/* end of Switch View Control */}
            </div>
          )}


        <Dialog
          open={isModal}
          onClose={this.closeModal}
          aria-labelledby="responsive-dialog-title"
          className="leave-dialog"
        >
          <DialogTitle id="responsive-dialog-title" className="popup-title">
            {"Reports Filter"}
          </DialogTitle>
          <DialogContent>
          {fieldsArray && fieldsArray.length == 0 &&
            <NoRecordFound />
          }
           
            {!isModalLoading && (
              <div className="popup-form-wrap">
                {fieldsArray && fieldsArray.length > 0 && fieldsArray.map((item, key) => {
                  return (
                    <div className="list-row" key={key}>
                      <Form.Group>
                        <Form.Label>{item.name}</Form.Label>
                        {item.type == "Text" &&
                          <Form.Control
                            type="text"
                            name={item.name}
                            value={item.value}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                        }
                        {item.type == "Date" &&
                          <DatePicker
                            name={item.name}
                            value={item.value}
                            onChange={(date) =>
                              this.handleDateOnChange(date, item.name)
                            }
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          //minDate={new Date()}
                          />
                        }
                        {item.type == "SelectOneChoice" && 
                          <Select
                            value={item.CholaVlookupValuesView.find(o => o.value === item.value)}
                            onChange={(e) => this.selectHandleOnChange(e, item.name)}
                            options={item.CholaVlookupValuesView}
                          />
                        }
                      </Form.Group>
                      { formSubmit && item.error !== "" &&
                        <p>{item.error}</p>}
                    </div>
                  )
                })}
              </div>
            )}
            {isModalLoading && (
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Loader />
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions className="popup-footer justify-content-start">
            <Row className="botBtn">
              <Col sm={12}>
                {/* {fieldsArray && fieldsArray.length > 0 && */}
                  {/* <span> */}
                      <Button
                        onClick={() => this.getFile("pdf")}
                      >
                        PDF
                      </Button>
                      <Button
                        onClick={() => this.getFile("excel")}
                      >
                        Excel
                      </Button>
                   {/* </span> */}
                 {/* } */}
                <Button
                  className="cancelBtn"
                  onClick={this.closeModal}
                  type="button"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, crmCampaignStatus } = state;
  return { alert, crmCampaignStatus };
};

export default connect(mapStateToProps)(ReportsList);