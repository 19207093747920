import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import Checkbox from 'material-ui/Checkbox';
import Select from 'react-select';
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
  LocationCode: {
    isReq: 'Location code is required!',
  },
  LocationName: {
    isReq: 'Location name is required!',
  },
  Description: {
    isReq: 'Description is required!',
  },
  // CountryCode: { //as per client request - removed mandatory
  //   isReq: "Country is required!",
  // },
  // State: {
  //   isReq: "State is required!",
  // },
  // City: {
  //   isReq: "City is required!",
  // },
  StartDate: {
    isReq: 'StartDate is required!',
  },
  Active: {
    isReq: 'Active is required!',
  },
  // AddressLine1: {
  //   isReq: 'AddressLine 1 is required!',
  // },
  // AddressLine2: {
  //   isReq: 'AddressLine 2 is required!',
  // }
};

class AddLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        LocationCode: '',
        LocationName: '',
        Description: '',
        RegionCode: '',
        CountryCode: '',
        State: '',
        City: '',
        AddressLine1: '',
        AddressLine2: '',
        AddressLine3: '',
        PostalCode: '',
        IsShipToLocation: null,
        IsBillToLocation: null,
        IsLegalAddress: null,
        IsHub: null,
        Active: 'Y',
        StartDate: null,
        EndDate: null,
        TenantId: crm.userInfo().pTenantId,
      },
      commonResource: {
        LocationId: 0,
        CountryId: 0,
        StateId: 0
      },
      countryData: [{ label: "Select", value: "", id: 0 }],
      stateData: [{ label: "Select", value: "", id: 0 }],
      cityData: [{ label: "Select", value: "", id: 0 }],
      errors: {},
      filterName: "",
      page: 1,
      userPermissions: common.getPermission(this.props, "vendor:locations"),
    };
  }

  /**
   * Hook Methods
   */
  componentDidMount() {
    this.defineInitErrorMsg();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let filterName = params.get("filterName");
    let page = params.get("page");

    const { commonResource } = this.state;
    if (this.props.match.params.locationId !== undefined & this.props.match.params.locationId !== 0) {
      commonResource.LocationId = this.props.match.params.locationId;

      this.setState({
        commonResource: commonResource,
        filterName,
        page
      }, () => {
        this.getLocation();
      });
    } else {
      this.getCountryData();
    }
  }

  /**
   * Get Locations by ID
   */
  getLocation = () => {
    this.setState({
      isLoading: true,
    });
    const { fields, commonResource, errors } = this.state;

    let url = envConfig.BASE_API + Services.CRM_LOCATIONS + '/' + commonResource.LocationId;

    restServices.getRequest(url, (response) => {
      if (response) {
        for (var key of Object.keys(response)) {
          if (response[key]) {
            if (fields.hasOwnProperty(key)) {
              if (
                key === 'StartDate' ||
                key === 'EndDate'
              ) {
                fields[key] = response[key] ? new Date(response[key]) : '';
              } else {
                fields[key] = response[key];

              }
            }
            if (key === "LocationId") {
              commonResource.LocationId = response[key] ? response[key] : '';
            }

            // Remove Default Errors
            if (errors.hasOwnProperty(key)) {
              // Optional Validation Here
              if (key === "Active" && response[key] === "Y") {
                errors[key] = "";
              } else if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                errors[key] = "";
              }
            }
          }

        }
      }

      this.setState({
        fields: fields,
        commonResource: commonResource,
        isLoading: false
      }, () => {
        if (this.state.fields.CountryCode != null) {
          this.getCountryData();
        }
      });

      // if (this.state.fields.State != null) {
      //   this.getStateData();
      // }
      // if (this.state.fields.City != null) {
      //   this.getCityData();
      // }
    });
  }

  /**
   * Initialize error message
   */
  defineInitErrorMsg = () => {
    const { errors, fields } = this.state;
    for (var key of Object.keys(defErrs)) {
      switch (key) {
        case "Active":
          if (fields[key] == "N") {
            errors[key] = defErrs[key].isReq;
          } else {
            errors[key] = "";
          }
          break;
        default:
          if (fields[key] == null || fields[key] == "") {
            errors[key] = defErrs[key].isReq;
          }
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to locations
   */
  onCancel = () => {
    history.push("/setup/vendor/location");
  }

  /**
     * Input onchange event handling
     */
  selectHandleOnChange = (event, name) => {
    const { fields, countryData, stateData, commonResource } = this.state;
    const { value } = event;
    var inputValue = value;

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, inputValue);
    });
    if (name == "CountryCode") {
      if (value !== "") {

        let filteredLists = countryData.filter((data) => {
          return data["value"] == value;
        });
        commonResource.CountryId = filteredLists[0].id;

        this.setState({
          commonResource: commonResource,
          countryData: countryData,
          stateData: [{ label: "Select", value: "", id: 0 }],
        }, () => {
          if (commonResource.CountryId > 0) {
            this.getStateData();
          }
        });
      } else {
        this.setState({ stateData: [{ label: "Select", value: "", id: 0 }], cityData: [{ label: "Select", value: "", id: 0 }] });
      }


    } else if (name == "State") {
      if (value !== "") {
        let filteredLists = stateData.filter((data) => {
          return data["value"] == value;
        });

        commonResource.StateId = filteredLists[0].id

        this.setState({
          commonResource: commonResource,
          cityData: [{ label: "Select", value: "", id: 0 }]
        }, () => {
          if (commonResource.StateId > 0) {
            this.getCityData();
          }
        });
      } else {
        this.setState({ cityData: [{ label: "Select", value: "", id: 0 }] });
      }

    }
  };

  /**
   * Input onchange event handling
   */
  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  /**
   * Get selected date values 
   */
  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, date);
    });
  }

  /**
   * Validate form
   */
  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "Active":
          if (value == "N") {
            errors[name] = defErrs[name].isReq;
          } else {
            errors[name] = "";
          }
          break;
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };


  /**
   * Handle form submit
   */
  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.LocationId > 0) {
        this.update()
      } else {
        this.create()
      }
    }
  };

  /**
   * Update location details
   */
  update = () => {

    const { fields, commonResource, filterName, page } = this.state;
    var formAttr = Object.assign({}, fields);

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default: break;
      }
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_LOCATIONS + "/" + commonResource.LocationId);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        history.push("/locations?locationId=" + commonResource.LocationId + "&filterName=" +
          filterName +
          "&page=" +
          page);
      },
      (error) => { }
    );
  }

  /**
   * Create new locations
   */
  create = () => {
    const { fields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_LOCATIONS);
    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        history.push("/setup/vendor/location");
      },
      (error) => { }
    )
  }

  /**
   * Get all countries list
   */
  getCountryData = () => {
    const { fields, commonResource } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_COUNTRIES +
      `?totalResults=true&limit=1000&offset=0&q=TenantId=${crm.userInfo().pTenantId};&orderBy=CountryName:asc`);

    restServices.getRequest(
      url,
      (response) => {

        let countryData = [{ label: "Select", value: "", id: 0 }];
        if (response && response.items.length > 0) {
          response.items.map((obj) => {
            let options = {
              label: obj.CountryName,
              value: obj.CountryCode,
              id: obj.CountryId
            }
            countryData.push(options);
          });
        }
        //filter country id by name and set in commonResource
        if (fields.CountryCode != null) {
          let filteredLists = countryData.filter((data) => {
            return data["value"] === fields.CountryCode;
          });
          if (filteredLists.length > 0) {
            commonResource.CountryId = filteredLists[0].id
          }
        }
        this.setState({
          countryData: countryData,
          stateData: [{ label: "Select", value: "", id: 0 }],
          commonResource: commonResource
        }, () => {
          //Get state list if country is selected
          if (this.state.commonResource.CountryId > 0) {
            this.getStateData()
          }
        });

      },
      (error) => {
      }
    );
  }

  /**
   * Get all states list
   */
  getStateData = () => {
    const { fields, commonResource } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_STATES +
      `?totalResults=true&limit=1000&offset=0&q=TenantId=${crm.userInfo().pTenantId};CountryId=${commonResource.CountryId}&orderBy=StateName:asc`);

    restServices.getRequest(
      url,
      (response) => {
        let stateList = [{ label: "Select", value: "", id: 0 }];
        if (response && response.items.length > 0) {
          response.items.map((obj) => {
            let options = {
              label: obj.StateName,
              value: obj.StateCode,
              id: obj.StateId
            }
            stateList.push(options);
          });
        }

        // let stateList = common.getOptionsData(response.items, "StateName", "StateId");

        //filter state id by name and set in commonResource
        // if(fields.State != null)
        // {
        let filteredLists = stateList.filter((data) => {
          return data["value"] === fields.State;
        });

        commonResource.StateId = filteredLists.length > 0 ? filteredLists[0].id : 0;
        this.setState({ commonResource: commonResource }, () => {

        });
        // }
        let citylist = [{ label: "Select", value: "", id: 0 }];
        this.setState({
          stateData: stateList,
          cityData: citylist
        });

        //Get city list if state is selected
        if (commonResource.StateId > 0) {
          this.getCityData();
        }
      },
      (error) => {
      }
    );
  }

  /**
   * Get all cities list
   */
  getCityData = () => {
    const { fields, commonResource } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_CITIES +
      `?totalResults=true&limit=1000&offset=0&q=TenantId=${crm.userInfo().pTenantId};StateId=${commonResource.StateId}&orderBy=CityName:asc`);

    restServices.getRequest(
      url,
      (response) => {
        //let cityData = common.getOptionsData(response.items, "CityName", "CityId");
        let cityData = [{ label: "Select", value: "", id: 0 }];
        if (response && response.items.length > 0) {
          response.items.map((obj) => {
            let options = {
              label: obj.CityName,
              value: obj.CityCode,
              id: obj.StateId
            }
            cityData.push(options);
          });
        }

        this.setState({
          cityData: cityData,
          commonResource: commonResource
        });
      },
      (error) => { }
    );
  }

  render() {
    const {
      fields,
      commonResource,
      errors,
      formSubmit,
      countryData,
      stateData,
      cityData,
      userPermissions
    } = this.state;

    const isChecked = (value) => value == 'Y';

    return (
      !userPermissions.read || ((commonResource.LocationId == 0 && !userPermissions.add) || (commonResource.LocationId > 0 && !userPermissions.update)) ? (
        <AccessDenied />
      ) : (
        <div className="lead-wraper">
          <Form onSubmit={this.save}>
            <div>
              {/* Location Information */}
              <Row className="whiteBgRow" style={{ marginTop: 28 }}>
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Location Information</h2>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Location Code*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="LocationCode"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.LocationCode}
                          disabled={commonResource.LocationId !== 0}
                        />
                        {errors.LocationCode !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.LocationCode}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Location Name*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="LocationName"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.LocationName}
                        />
                        {errors.LocationName !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.LocationName}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Start Date*</Form.Label>
                        <DatePicker
                          name="StartDate"
                          selected={fields.StartDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, 'StartDate')
                          }
                          dateFormat="dd/MM/yyyy"
                          autoComplete="off"
                          minDate={new Date()}
                        />
                        {errors.StartDate !== '' && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.StartDate}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>End Date*</Form.Label>
                        <DatePicker
                          name="EndDate"
                          selected={fields.EndDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, 'EndDate')
                          }
                          dateFormat="dd/MM/yyyy"
                          autoComplete="off"
                          minDate={new Date()}
                        />
                        {errors.EndDate !== '' && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.EndDate}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Description*</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          placeholder=""
                          name="Description"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.Description}
                          maxLength={240}
                        />
                        {errors.Description !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.Description}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Checkbox
                          name="Active"
                          checked={isChecked(fields.Active)}
                          label="Active"
                          labelStyle={{ color: '#2d2d2d' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                        {errors.Active !== 'Y' && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.Active}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* Primary Site Information */}
              <Row className="whiteBgRow" style={{ marginTop: 28 }}>
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Address Details</h2>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Address Line 1*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="AddressLine1"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.AddressLine1}
                        />
                        {errors.AddressLine1 !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.AddressLine1}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Address Line 2*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="AddressLine2"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.AddressLine2}
                        />
                        {errors.AddressLine2 !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.AddressLine2}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Address Line 3</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="AddressLine3"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.AddressLine3}
                        />
                        {errors.AddressLine3 !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.AddressLine3}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Country*</Form.Label>
                        <div className="select-control">
                          <Select
                            value={countryData.find(o => o.value == fields.CountryCode)}
                            onChange={(e) => this.selectHandleOnChange(e, 'CountryCode')}
                            options={countryData}
                          />
                        </div>
                      </Form.Group>
                      {/* {errors.CountryCode !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.CountryCode}</Form.Text>
                      )} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>State*</Form.Label>
                        <div className="select-control filter-selectbox">
                          <Select
                            value={stateData.find(o => o.value == fields.State)}
                            onChange={(e) => this.selectHandleOnChange(e, 'State')}
                            options={stateData}
                          />
                        </div>
                      </Form.Group>
                      {/* {errors.State !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.State}</Form.Text>
                      )} */}
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>City*</Form.Label>
                        <div className="select-control">
                          <Select
                            value={cityData.find(o => o.value == fields.City)}
                            onChange={(e) => this.selectHandleOnChange(e, 'City')}
                            options={cityData}
                          />
                        </div>
                      </Form.Group>
                      {/* {errors.City !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.City}</Form.Text>
                      )} */}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Region</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="SiteCode"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.RegionCode}
                        />
                        {errors.RegionCode !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.RegionCode}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="PostalCode"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.PostalCode}
                        />
                        {errors.PostalCode !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.PostalCode}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Telephone No</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="SiteCode"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.TelNumber1}
                        />
                        {errors.TelNumber1 !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.TelNumber1}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Alternate Telephone No</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="TelNumber2"
                          onChange={(e) => this.handleOnChange(e)}
                          value={fields.TelNumber2}
                        />
                        {errors.TelNumber2 !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.TelNumber2}</Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Checkbox
                          name="IsBillToLocation"
                          checked={isChecked(fields.IsBillToLocation)}
                          label="Bill To Location"
                          labelStyle={{ color: '#2d2d2d' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Checkbox
                          name="IsHub"
                          className="filter-checkbox"
                          checked={isChecked(fields.IsHub)}
                          label="Hub?"
                          labelStyle={{ color: '#2d2d2d' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Checkbox
                          name="IsLegalAddress"
                          className="filter-checkbox"
                          checked={isChecked(fields.IsLegalAddress)}
                          label="Legal Address?"
                          labelStyle={{ color: '#2d2d2d' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Checkbox
                          name="IsShipToLocation"
                          className="filter-checkbox"
                          checked={isChecked(fields.IsShipToLocation)}
                          label="Ship To Location?"
                          labelStyle={{ color: '#2d2d2d' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>



              {/* Action section */}
              <Row className="botBtn">
                <Col sm={12}>
                  <Button className="saveBtn" type="submit">
                    {commonResource.LocationId > 0 ? "Update" : "Save"}
                  </Button>
                  <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      )
    );
  }
}

export { AddLocations };
