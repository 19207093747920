import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

//Import CSS
import "../css/Customer.css";
// includes
import { crm } from '_utils';
// Import Images
import SearchIcon from "img/search-icn.png";
import EditIcon from "img/edit.svg";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

import Attachments from "../../../_components/Attachments/Attachments";
import ContactLinks from "../../../_components/Contacts/ContactLinks";
import TaxIdentification from "./Tabs/TaxIdentification";
import CustomerSiteList from "./Tabs/CustomerSiteList";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: [],
      selectedCustomer: {},
      selectedCustomerId: 0,
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      isQuery: false,
      // permission
      userPermissions: common.getPermission(this.props, "vendor:customers"),
    };

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentTab = params.get("tab");
    this.state.currentTab = currentTab;
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let customerId = parseInt(params.get("customerId"));
      let filterName = params.get("filterName");
      let page = params.get("page");
      if (customerId) {
        let selectedCustomerId = this.state.selectedCustomerId;
        let paging = this.state.paging;
        paging.page = parseInt(page);
        paging.size = 5;
        paging.total = 5;
        this.setState(
          {
            isSearchLoad: true,
            selectedCustomerId,
            filterName,
            paging,
            isQuery: true,
          },
          (_) => {
            this.getList(this.state.isQuery);
          }
        );
      } else {
        this.getList(this.state.isQuery);
      }
    }
  }

  /**
   * Methods
   */

  handleClickList = (customer) => {
    this.setState(
      {
        selectedCustomer: customer,
        selectedCustomerId: customer.CustomerId,
        isLoading: true,
      },
      (_) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState(
      {
        filterName: value,
      },
      () => {
        //reset page number to 1
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
      }
    );
  };

  /**
   * Service Request
   */
  getList(queryParam) {
    const { paging, searchList, filterName } = this.state;

    let tempData = [];

    if (!queryParam && paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
        paging: paging,
      });
      tempData = [];
    } else {
      searchList.map((item) => {
        tempData.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(CustomerName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(CustomerName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${paging.page * paging.size
        }`;
    }

    let url = envConfig.BASE_API + Services.CRM_CUSTOMER + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedCustomer = this.state.selectedCustomer;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let customerId = params.get("customerId");

          response.items.map((item, key) => {
            tempData.push(item);
            if (customerId != undefined) {
              if (item.CustomerId == parseInt(customerId)) {
                selectedCustomer = item;
              }
            }
          });
          if (!queryParam && paging.page === 1) {
            selectedCustomer = tempData[0];
          }
          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempData,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedCustomer);
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
          });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  deleteSelectedItem = (CustomerId) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(CustomerId);
    });
  };

  /**
   * Delete Calendar Leave by ID
   * @param {*} CustomerId
   */
  handleClickDeleteList = (CustomerId) => {
    const { paging } = this.state;

    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + CustomerId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );

        common.snack("S", "Customer List deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
 * Tab onclick event
 */
  handleSelect(key) {
    let url = window.location.pathname;
    let queryParam = window.location.search;
    if (queryParam.includes("tab=")) {
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab=" + tab, "tab=" + key);
    }
    history.push(url + queryParam);
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedCustomer: selectedCustomer,
      selectedCustomerId,
      isLoading,
      paging,
      totalRecords,
      currentTab,
      filterName,
      userPermissions,
    } = this.state;

    return !userPermissions.access ? (
      <AccessDenied />
    ) : (
      <div className="crm-list-wrap">
        <Row>
          <Col sm={3}>
            <div className="marketing-list-wrap">
              <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                <h2 className="crm-type-title">Customer List</h2>
                {userPermissions.add && (
                  <Link to={"/setup/vendor/customer/add"} className="btn btn-add">
                    Add
                  </Link>
                )}
              </div>

              <Paper>
                <div className="input-group search-ml-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img
                        src={SearchIcon}
                        alt="Search"
                        onClick={this.getList}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="filterName"
                    onChange={(e) => this.handleSearchChange(e)}
                    placeholder="Search Customer"
                    name="filterName"
                    value={filterName}
                  />
                </div>
                <ul className="marketing-list">
                  {isSearchLoad == false &&
                    searchList.length > 0 &&
                    searchList.map((row, index) => {
                      return (
                        <li
                          onClick={(e) => this.handleClickList(row)}
                          key={index}
                          className={`d-flex justify-content-between align-items-center ${row.CustomerCode == selectedCustomer.CustomerCode
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="mlLeft mr-3">
                            <h4 className="ml-name">{row.CustomerName}</h4>
                            <p className="ml-type">{row.CustomerCode}</p>
                          </div>
                          <div className="mlRight">

                            {userPermissions.update && (
                              <Link to={"/setup/vendor/customer/edit/" + row.CustomerId}>
                                <img src={EditIcon} alt="Edit" />
                              </Link>
                            )}

                            {userPermissions.delete && (
                              <Link to="#"
                                style={{ marginLeft: 10 }}
                                onClick={() =>
                                  this.deleteSelectedItem(row.CustomerId)
                                }
                              >
                                <img src={DeleteIcon} alt="Delete" />
                              </Link>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  {isSearchLoad == true && <Loader />}
                  {isSearchLoad == false && searchList.length === 0 && (
                    <NoRecordFound />
                  )}
                </ul>
                {/* Pagination */}
                {searchList.length > 0 && (
                  <div className="load-more-wrap">
                    {paging.page != paging.total && searchList.length > 0 && (
                      <Link
                        to="#"
                        onClick={(e) => this.pagination(e)}
                        className="load-more"
                      >
                        {" "}
                        Load More{" "}
                      </Link>
                    )}
                    <div className="paging-text">
                      1 to {searchList.length} of {totalRecords} entries
                    </div>
                  </div>
                )}
              </Paper>
            </div>
          </Col>
          <Col sm={9}>
            <div className="formTabSection position-r">
              {!isLoading && searchList.length > 0 && (
                <Tabs defaultActiveKey={currentTab ? currentTab : "sites"}
                  onSelect={this.handleSelect}>
                  <Tab eventKey="sites" title="Sites">
                    <CustomerSiteList
                      objectId={selectedCustomerId}
                      filterName={this.state.filterName}
                      page={paging.page}
                      isQuery={this.state.isQuery}
                    />
                  </Tab>
                  <Tab
                    eventKey="taxidentification"
                    title="Tax Identification"
                    disabled
                  >
                    <TaxIdentification objectId={selectedCustomerId} />
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="Contacts"
                    disabled={selectedCustomerId == 0 ? "disabled" : ""}
                  >
                    {/* <ContactListTableViewRenderer
                      objectId={selectedCustomerId}
                      objectType={common.getObjectNameByPage("customer")}
                      retrun={
                        "/setup/vendor/customer?tab=contact&customerId=" +
                        selectedCustomerId +
                        "&filterName=" +
                        this.state.filterName +
                        "&page=" +
                        paging.page
                      }
                    /> */}
                    <ContactLinks
                        objectId={selectedCustomerId}
                        objectType={common.getObjectNameByPage("customer")}
                      />
                  </Tab>
                  <Tab
                    eventKey="attachments"
                    title="Attachments"
                    disabled={
                      selectedCustomer.CustomerId == 0 ? "disabled" : ""
                    }
                  >
                    <Attachments
                      objectType="customer"
                      objectId={selectedCustomer.CustomerId}
                    />
                  </Tab>
                  {/* <Tab eventKey="history" title="History" disabled >
                  History
                </Tab>                 */}
                </Tabs>
              )}
              {isLoading && <Loader />}
              {isSearchLoad == false && searchList.length === 0 && (
                <div className="formTabSection position-r">
                  <Paper style={styles.boxLayout}>
                    <NoRecordFound />
                  </Paper>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export { CustomerList };
