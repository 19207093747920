import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import editIcon from "img/template_edit.png";
import deleteIcon from "img/template_delete.png";
class TemplateDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let userPermissions = this.props.userPermissions;
    let template = this.props.selectedTemplate;
    //let templateContent = JSON.parse(template.HtmlFormat);
    let templateId = template.EmailTemplateId;
    function createMarkup() {
      return { __html: template.HtmlFormat };
    }
    return (
      <div className="list-view-wrap bottom-set">
        <div className="box-title-wrap justify-content-between">
          <h3 className="crm-type-title">{template.Subject}</h3>

          <div className="form-row justify-content-end">
            <div className="col">
              {userPermissions.update && (
                <Link
                  to={"/setup/configure/template/edit/" + templateId}
                  style={{ padding: "0 5px" }}
                >
                  <img src={editIcon} alt="Edit" />
                </Link>
              )}
              {userPermissions.delete && (
                <Link to="#" style={{ padding: "0 5px" }} onClick={() => this.props.deleteTemplate(templateId)}>
                  <img src={deleteIcon} alt="Edit" />
                </Link>
              )}
            </div>
          </div>
        </div>

        <Paper style={styles.boxLayout}>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Paper>
      </div>
    );
  }
}
const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};
export default TemplateDetail;
