// Import default packages
import React from "react";
import { Link } from "react-router-dom";
// Import table packages
import { makeStyles } from "@material-ui/core/styles";

// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { tableConfig, restServices } from "_helpers";

// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Images
import SearchIcon from "img/search-icn.png";
import TickIcon from "img/hrms/green-tick.png";
import EditIcon from "img/edit.svg";
import DeleteIcon from "img/delete.svg";

import { Table } from "react-bootstrap";

import '../css/Shift.css';

const classes = makeStyles({
    table: {
        minWidth: 650,
    },
});

class ShiftMasterDetails extends React.Component {
    constructor() {
        super();
        this.state = {
            parentId: 0,
            listRes: {},
            isLoading: false,
            currentPage: 1,
            pageLimit: 20,
            tlConfig: {
                headCells: [
                    { id: "AddressId", value: "AddressId", label: "Select action" }
                    // { id: "SiteName", value: "SiteName", label: "ORDERING" },
                    // { id: "SiteCode", value: "SiteCode", label: "TIME TYPE" },
                    // { id: "PrimaryFlag", value: "PrimaryFlag", label: "START TIME" },
                    // { id: "ServiceTo", value: "ServiceTo", label: "END TIME" },
                    // { id: "BillTo", value: "BillTo", label: "MON" },
                    // { id: "ShipTo", value: "ShipTo", label: "TUE" },
                    // { id: "AddressId", value: "AddressId", label: "WED" },
                    // { id: "AddressId", value: "AddressId", label: "THU" },
                    // { id: "AddressId", value: "AddressId", label: "FRI" },
                    // { id: "AddressId", value: "AddressId", label: "SAT" },
                    // { id: "AddressId", value: "AddressId", label: "SUN" },
                ],
                columnsSelected: [],
                order: "asc",
                orderBy: "SiteName",
                selected: [],
                uniqueKey: "ShiftMastercolumns",
            },
            filterName: "",
        };
    }

    /**
     * Component Hooks
     */
    componentDidMount() {
        if (this.props.objectId != undefined) {
            this.getList();
        }
    }

    /**
     * Table list config data update method
     */
    updateTLConfig = (key, value, object) => {
        const { tlConfig } = this.state;

        if (object) {
            for (var oKey of Object.keys(object)) {
                if (tlConfig.hasOwnProperty(oKey)) {
                    tlConfig[oKey] = object[oKey];
                }
            }
            this.setState({
                tlConfig: tlConfig,
            });
        } else {
            if (tlConfig.hasOwnProperty(key)) {
                tlConfig[key] = value;
                this.setState({
                    tlConfig: tlConfig,
                });
            }
        }
    };

    /**
     * Handle pagination number changed
     * @param {*} pageNumber
     */
    handlePageChange = (pageNumber) => {
        const { currentPage } = this.state;
        if (currentPage != pageNumber) {
            this.setState(
                {
                    currentPage: pageNumber,
                },
                () => {
                    this.getList();
                }
            );
        }
    };

    handleFieldChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    handleChange = (fieldName, e) => {
        this.setState(
            {
                [fieldName]: e.target.value,
                currentPage: 1,
            },
            (_) => {
                this.getList();
            }
        );
    };

    /**
     * Get all list
     */
    getList = () => {
        const { currentPage, tlConfig, pageLimit } = this.state;
        this.setState({ isLoading: true });

        let filter = '?q=CustomerId=' + this.props.objectId;

        if (tlConfig.OrderBy) {
            filter += "&orderBy=" + tlConfig.OrderBy;
        }

        let url = envConfig.BASE_API + Services.CRM_CUSTOMER_SITE + filter;

        restServices.getRequest(
            url,
            (response) => {
                this.setState({
                    listRes: response,
                    isLoading: false,
                });
            },
            (error) => {
                this.setState({
                    listRes: {},
                    isLoading: false,
                });
            }
        );
    };

    render() {
        const {
            isLoading,
            filterName,
            tlConfig,
            listRes,
            currentPage,
            pageLimit,
        } = this.state;

        const isHided = (columnName) =>
            tlConfig.columnsSelected.indexOf(columnName) !== -1;

        return (
            <div className="territory-wraper">
                {/* end of Secondary Nav */}
                <div className="filter-wrap shift-form">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-3">
                            <div className="form-row select-action">
                                {/* <EnhancedTableColums
                                    tlConfig={tlConfig}
                                    updateTLConfig={this.updateTLConfig}
                                /> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="form-row justify-content-end">
                                <div className="col-lg-5">
                                    <label className="sr-only" htmlFor="inlineFormInputGroup">
                                        Filter and Search
                                    </label>
                                    <div className="input-group search-filter-group mb-2 filter-box">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="inlineFormInputGroup"
                                            placeholder="+ Filter and search"
                                            name="filterName"
                                            value={filterName}
                                            onChange={this.handleFieldChange}
                                        />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <img
                                                    src={SearchIcon}
                                                    alt="Search"
                                                    onClick={this.getList}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 sort-select">
                                    <EnhancedTableColumns
                                        tlConfig={tlConfig}
                                        updateTLConfig={this.updateTLConfig}
                                    />
                                </div>

                                <div className="col-auto">
                                    <Link to="/hrms/shiftmaster/addbreaktime">
                                        <button type="button" className="btn btn-add">
                                            Add Break Time
                                    </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                {/* end of Filter Wrap */}
                <div className="crm-data-wrap">

                    <div className="clearfix"></div>

                    <div className="list-view-wrap">
                        <Paper style={{ padding: 20 }}>
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th style={{ fontWeight: "bold" }}>ORDERING</th>
                                        <th style={{ fontWeight: "bold" }}>TIME TYPE</th>
                                        <th style={{ fontWeight: "bold" }}>START TIME</th>
                                        <th style={{ fontWeight: "bold" }}>END TIME</th>
                                        <th style={{ fontWeight: "bold" }}>MON</th>
                                        <th style={{ fontWeight: "bold" }}>TUE</th>
                                        <th style={{ fontWeight: "bold" }}>WED</th>
                                        <th style={{ fontWeight: "bold" }}>THU</th>
                                        <th style={{ fontWeight: "bold" }}>FRI</th>
                                        <th style={{ fontWeight: "bold" }}>SAT</th>
                                        <th style={{ fontWeight: "bold" }}>SUN</th>
                                        <th style={{ fontWeight: "bold" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Food Break</td>
                                        <td>1:00 PM</td>
                                        <td>2:00 PM</td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td>
                                            <img src={EditIcon} style={{ marginRight: 25 }} />
                                            <img src={DeleteIcon} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Break 1</td>
                                        <td>11:00 AM</td>
                                        <td>11:20 PM</td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td></td>
                                        <td><img src={TickIcon} /></td>
                                        <td>
                                            <img src={EditIcon} style={{ marginRight: 25 }} />
                                            <img src={DeleteIcon} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Break 2</td>
                                        <td>11:00 AM</td>
                                        <td>11:20 PM</td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td></td>
                                        <td>
                                            <img src={EditIcon} style={{ marginRight: 25 }} />
                                            <img src={DeleteIcon} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Break 3</td>
                                        <td>11:00 AM</td>
                                        <td>11:20 PM</td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td>
                                            <img src={EditIcon} style={{ marginRight: 25 }} />
                                            <img src={DeleteIcon} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Break 4</td>
                                        <td>11:00 AM</td>
                                        <td>11:20 PM</td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td><img src={TickIcon} /></td>
                                        <td></td>
                                        <td>
                                            <img src={EditIcon} style={{ marginRight: 25 }} />
                                            <img src={DeleteIcon} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Paper>


                        {/* <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <EnhancedTableHead
                                    tlConfig={tlConfig}
                                    updateTLConfig={this.updateTLConfig}
                                />
                                <TableBody>
                                    {isLoading == false && listRes.items &&
                                        listRes.items.length > 0 &&
                                        tableConfig
                                            .stableSort(
                                                listRes.items,
                                                tableConfig.getComparator(
                                                    tlConfig.order,
                                                    tlConfig.orderBy
                                                )
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.PartySiteId}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={
                                                                !isHided("SiteName") ? "hideCell" : ""
                                                            }>
                                                            <Link to={"/addcustomersite/" + this.props.objectId + "/" + row.PartySiteId}>
                                                                {row.SiteName ? row.SiteName : "-"}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("SiteCode") ? "hideCell" : ""
                                                            }>
                                                            {row.SiteCode ? row.SiteCode : "-"}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("PrimaryFlag") ? "hideCell" : ""
                                                            }>
                                                            {row.PrimaryFlag ? row.PrimaryFlag : "-"}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("ServiceTo") ? "hideCell" : ""
                                                            }>
                                                            {row.ServiceTo ? row.ServiceTo : "-"}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("BillTo")
                                                                    ? "hideCell"
                                                                    : ""
                                                            } >
                                                            {row.BillTo ? row.BillTo : "-"}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("ShipTo") ? "hideCell" : ""
                                                            } >
                                                            {row.ShipTo ? row.ShipTo : "-"}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                !isHided("AddressId") ? "hideCell" : ""
                                                            }>
                                                            {row.AddressId ? row.AddressId : "-"}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    {isLoading == true && (
                                        <TableRow>
                                            <TableCell colSpan={12}><Loader /> </TableCell>
                                        </TableRow>
                                    )}
                                    {isLoading == false && listRes.items &&
                                        listRes.items.length == 0 && (
                                            <TableRow>
                                                <TableCell colSpan={12}><NoRecordFound /> </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>

                            </Table>

                        </TableContainer> */}
                        {isLoading == false && listRes.totalResults > 0 && (
                            <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                                <div className="mr-auto">
                                    {tableConfig.showEntries(currentPage, pageLimit, listRes)}
                                </div>
                                <div className="pagination-wrap">
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={currentPage}
                                        itemsCountPerPage={listRes.limit}
                                        totalItemsCount={listRes.totalResults}
                                        pageRangeDisplayed={7}
                                        onChange={this.handlePageChange.bind(this)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                {/* end of Switch View Control */}
            </div>
        );
    }
}

export default ShiftMasterDetails;
