import React from 'react';
//Import CSS
import './AccessDenied.css';
import AccessDeniedImg from "img/accessdenied.png";
class AccessDenied extends React.Component {
    render() {
        return(
            <div className="access-denied text-center">
                <img src={AccessDeniedImg} alt="Access Denied" className="mb-3" />
                <h5>Access Denied</h5>
                <p>Sorry, But you don't have permission to access this page</p>
            </div>
        )
    }
}
export default AccessDenied;