import jsonPermission from "../json/permission.json";
const initialState = {
	permissions:{},
}


export default function SetupReducers(state = initialState,action){
	 switch(action.type){
	 	case 'SET_ROLE_PERMISSION':
            return{
                ...state,
                permissions:action.payload,
            }
        default:
            return state;
        

	 }
}