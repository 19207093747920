import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices } from "_helpers";

//Import CSS
import "../css/SubDivision.css";
import "react-datepicker/dist/react-datepicker.css";
// includes
import { crm } from '_utils';
const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// Defined Errors
const defErrs = {
  SubDivCode: {
    isReq: "Sub Division code is required!",
  },
  SubDivisionName: {
    isReq: "Sub Division name is required!",
  }
};

class AddSubDivision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        SubDivCode: "",
        SubDivisionName: "",
        Active: "Y",
        StartDate: new Date(),
        EndDate: null,
        TenantId: crm.userInfo().pTenantId,
        BgId: common.getLocalStorageValue("user", "GroupId"),
        DivisionId: 1289
      },
      operatingUnitData: [],
      errors: {},
      formSubmit: false,
      updateId: 0
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.defineInitErrorMsg();

    if (this.props.match.params.subdivId) {
      this.setState({
        updateId: this.props.match.params.subdivId
      }, () => {
        this.getDetailByUpdateId()
      });
    }

  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      errors[name] = "";
    }
    this.setState({
      errors: errors,
    });
  };

  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState({
      fields: fields
    }, () => {
      this.validateForm(name, date);
    });
  }

  save = (event) => {
    event.preventDefault();

    const { errors, updateId } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (updateId > 0) {
        this.update()
      } else {
        this.create()
      }
    }

  };

  update = () => {

    const { fields, updateId } = this.state;
    var formAttr = Object.assign({}, fields);

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default: break;
      }
    }

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_SUB_DIVISION + "/" + updateId);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        history.push("/hrms/division");
      },
      (error) => { }
    );

  }

  create = () => {
    const { fields } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_SUB_DIVISION);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        history.push("/hrms/division");
      },
      (error) => { }
    )


  }

  getDetailByUpdateId = () => {
    const { updateId, fields, errors } = this.state

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_SUB_DIVISION + "/" + updateId);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {

                switch (key) {
                  case "StartDate":
                  case "EndDate":
                    fields[key] = response[key] ? new Date(response[key]) : null
                    break;
                  default:
                    fields[key] = response[key];
                }

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState({
            fields: fields,
            errors: errors
          });

        } else { }
      },
      (error) => { }
    );
  }

  onCancel = () => {
    history.push("/hrms/division");
  }

  render() {
    const {
      fields,
      errors,
      formSubmit,
      updateId,
      operatingUnitData
    } = this.state;

    const isChecked = (value) => value == "Y";

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>

          <div className="formWhiteBg">
            <div className="formHead">
              <h2>SUB DIVISION INFORMATION</h2>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Sub Division Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="SubDivCode"
                    value={fields.SubDivCode}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.SubDivCode !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.SubDivCode}</Form.Text>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    readOnly
                    name="StartDate"
                    value={fields.StartDate}
                    onChange={(date) => this.handleDateOnChange(date, 'StartDate')}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Sub Division Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="SubDivisionName"
                    value={fields.SubDivisionName}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.SubDivisionName !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.SubDivisionName}</Form.Text>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    name="EndDate"
                    value={fields.EndDate}
                    onChange={(date) => this.handleDateOnChange(date, 'EndDate')}
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </Col>
            </Row>

          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {updateId > 0 ? "Update" : "Save"}
              </Button>
              <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export { AddSubDivision };
