import React from 'react';
//Import CSS
import './NoRecordFound.css';
import NoRecordFoundImg from "img/no-records-found.png";

class NoRecordFound extends React.Component {
    render() {
        return(
            <div className="no-records-found-wrap text-center">
                <img src={NoRecordFoundImg} alt="No Records Found" className="mb-3" />
                <h5>No Records Found</h5>
            </div>
        )
    }
}
export default NoRecordFound;