import React from "react";

//Import CSS
import "../css/Dashboard.css";

import Chart from "react-google-charts";
import Select from "react-select";

import { envConfig } from "_config";
import * as Services from "_config/api";
import { restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      isLoading: true,
      targetTableData: {
        rowHead: [],
        columnHead: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
        columnData: {},
      },
      chartData: [["", "Sales"]],
      maxChartValue: 0,
      selectYear: new Date().getFullYear(),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.getTargets();
  }

  getTargets = () => {
    const { selectYear } = this.state;
    let filter = `?q=TargetRscYear=${selectYear};TargetRscMonth=JAN OR FEB OR MAR OR APR OR MAY OR JUN OR JUL OR AUG OR SEP OR OCT OR NOV OR DEC;&orderBy=CreatedOn:desc&totalResults=true&limit=10000&offset=0`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_GET_TARGETS + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          this.createTargetTableData(response.items);
        } else {
          this.setState({
            isLoading: false,
          });
        }
      },
      (error) => {}
    );
  };

  createTargetTableData = (res) => {
    const { targetTableData, chartData } = this.state;
    let { maxChartValue } = this.state;
    res.map((r) => {
      const rowHead = r.TargetRscType;
      let heading = r.TargetRscType.replaceAll("_", " ");
      heading = heading.replaceAll("OPPTS", "DEALS");
      heading = heading.replaceAll("OPPT", "DEALS");
      const rowHeadFormatted = heading;
      const columnHead = r.TargetRscMonth;
      if (!targetTableData.columnData[rowHead]) {
        targetTableData.columnData[rowHead] = {
          targetTotal: 0,
          actualTotal: 0,
        };
      }
      if (!targetTableData.columnData[rowHead][columnHead]) {
        targetTableData.columnData[rowHead][columnHead] = {
          target: 0,
          actual: 0,
        };
      }
      let idx = targetTableData.rowHead.findIndex((item) => {
        return item.id === rowHead;
      });
      if (idx < 0) {
        targetTableData.rowHead.push({ name: rowHeadFormatted, id: rowHead });
      }
      targetTableData.columnData[rowHead][columnHead].target +=
        r.TargetRscValue;
      targetTableData.columnData[rowHead].targetTotal += r.TargetRscValue;
      targetTableData.columnData[rowHead][columnHead].actual += r.ActualValue;
      targetTableData.columnData[rowHead].actualTotal += r.ActualValue;
      let cIdx = chartData.findIndex((c) => {
        return c[0] == rowHeadFormatted;
      });
      let targetPercentage =
        (targetTableData.columnData[rowHead].actualTotal *
          targetTableData.columnData[rowHead].targetTotal) /
        10000;
      if (cIdx < 0) {
        chartData.push([rowHeadFormatted, targetPercentage]);
      } else {
        chartData[cIdx] = [rowHeadFormatted, targetPercentage];
      }
      maxChartValue =
        maxChartValue < targetPercentage ? targetPercentage : maxChartValue;
    });
    this.setState({
      targetTableData,
      chartData,
      maxChartValue,
      isLoading: false,
    });
  };

  selectHandler = (obj) => {
    this.setState(this.initialState);
    this.setState({ selectYear: obj.value }, () => {
      this.getTargets();
    });
  };

  render() {
    const { targetTableData, chartData, maxChartValue, isLoading, selectYear } =
      this.state;
    const d = new Date();
    var year = d.getFullYear();
    let yearList = [];
    for (let i = 2018; i <= year; i++) {
      yearList.push({
        label: i,
        value: i,
      });
    }

    return (
      <div>
        <div className="row justify-content-between">
          <div className="col-lg-8"></div>
          <div className="col-lg-4">
            <div className="form-row justify-content-end">
              <div className="col-lg-6">
                <h4 className="taget-period">Select Year -</h4>
              </div>
              <div className="col-lg-6">
                <Select
                  name="selectYear"
                  value={yearList.find((o) => o.value == selectYear)}
                  onChange={(val) => this.selectHandler(val)}
                  options={yearList}
                />
              </div>
            </div>
          </div>
        </div>

        {isLoading == false &&
          targetTableData.columnData.length !== 0 &&
          targetTableData.rowHead.length !== 0 && (
            <div className="dashboard-wrap">
              <div className="db-section mb-5">
                <h3 className="db-section-title">Area covered</h3>
                <Chart
                  width={"100%"}
                  height={"600px"}
                  chartType="AreaChart"
                  loader={<div>Loading Chart</div>}
                  data={chartData}
                  options={{
                    backgroundColor: "#f5f9fc",
                    title: "",
                    hAxis: {
                      title: "",
                      titleTextStyle: { color: "#333" },
                      textStyle: {
                        color: "#908280",
                        fontSize: 10,
                        bold: true,
                      },
                      slantedText: true,
                      slantedTextAngle: 90,
                    },
                    vAxis: {
                      minValue: 0,
                      maxValue: 1.0,
                      gridlines: {
                        color: "transparent",
                      },
                      textStyle: {
                        color: "#908280",
                        fontSize: 12,
                        bold: true,
                      },
                      format: "##%",
                    },
                    // For the legend to fit, we make the chart area smaller
                    chartArea: {
                      width: "95%",
                      height: "500px",
                      backgroundColor: {
                        fill: "#92d050",
                        opacity: 100,
                      },
                      left: 50,
                      top: 50,
                    },
                    areaOpacity: 1.0,
                    series: {
                      0: { color: "#254061" },
                    },
                    legend: { position: "none" },
                    // focusTarget: 'category'
                    // lineWidth: 25,
                    // backgroundColor: "red"
                  }}
                  // For tests
                  rootProps={{ "data-testid": "1" }}
                />
              </div>

              <div className="db-section">
                <h3 className="db-section-title mb-3">
                  Numbers Dashboard (Targets vs Actuals) {year}
                </h3>
                <div className="number-table-wrap">
                  <ul className="ntw-list d-flex">
                    <li className="ntw-lables">
                      <h4 className="crm-type invisible">&nbsp;</h4>
                      <h5 className="ntwf-col-title invisible">&nbsp;</h5>
                      <ul className="month-list">
                        {targetTableData.columnHead.map((item) => (
                          <li key={item}>{item}</li>
                        ))}
                        <li></li>
                      </ul>
                    </li>
                    {targetTableData.rowHead.map((rh, rIdx) => (
                      <li key={rIdx}>
                        <h4 className="crm-type">{rh.name}</h4>
                        <div className="d-flex justify-content-between">
                          <div className="ntwf-col">
                            <h5 className="ntwf-col-title">Targets</h5>
                            <ul className="month-list">
                              {targetTableData.columnHead.map((ch, idx) => (
                                <li key={idx}>
                                  {targetTableData.columnData[rh.id] &&
                                  targetTableData.columnData[rh.id][ch]
                                    ? targetTableData.columnData[rh.id][ch]
                                        .target
                                    : 0}
                                </li>
                              ))}
                              <li>
                                {targetTableData.columnData[rh.id]
                                  ? targetTableData.columnData[rh.id]
                                      .targetTotal
                                  : 0}
                              </li>
                            </ul>
                          </div>

                          <div className="ntwf-col">
                            <h5 className="ntwf-col-title">Actuals</h5>
                            <ul className="month-list">
                              {targetTableData.columnHead.map((ch, idx) => (
                                <li key={idx}>
                                  {targetTableData.columnData[rh.id] &&
                                  targetTableData.columnData[rh.id][ch]
                                    ? targetTableData.columnData[rh.id][ch]
                                        .actual
                                    : 0}
                                </li>
                              ))}
                              <li>
                                {targetTableData.columnData[rh.id]
                                  ? targetTableData.columnData[rh.id]
                                      .actualTotal
                                  : 0}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        {isLoading == true && <Loader />}
        {!isLoading &&
          (targetTableData.columnData.length == 0 ||
            targetTableData.rowHead.length == 0) && <NoRecordFound />}
      </div>
    );
  }
}

export { Dashboard };
