import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// material
import Paper from "@material-ui/core/Paper";
import Checkbox from "material-ui/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import ResponsibleList from "./includes/ResponsibleList";
// css
import "./css/Roles.css";

class RolesResponsibleList extends React.Component {
  constructor(props) {
    super(props);
    const selectVal = { label: "Select", value: "" };
    this.state = {
      selectVal,
      isLoading: {
        roles: true,
        rolesList: true,
      },
      isAdding: false,
      formSubmit: {
        roles: false,
        responsible: false
      },
      filter: {
        roles: ""
      },
      role: selectVal,
      roleInfo: {
        roleId: "",
        groupId: ""
      },
      fields: {
        name: "",
        area: "",
        shift: selectVal,
        frequency: selectVal,
        duration: "",
        carry: "Y",
      },
      errors: {
        name: {
          error: "",
          isReq: "Responsibility Name is required",
        },
        area: {
          error: "",
          isReq: "Area is required",
        },
        shift: {
          error: "",
          isReq: "Shift is required",
        },
        frequency: {
          error: "",
          isReq: "Frequency is required",
        },
        duration: {
          error: "",
          isReq: "duration is required",
        },
      },
      modal: {
        roles: false,
        responsible: false
      },
      sData: {
        rolesList: [],
        roleTypeList: [],
        shiftList: [],
        frequencyList: [],
      },
      userInfo: crm.userInfo(),
      userPermissions: common.getPermission(this.props, "hrms:roles & responsibilities"),
    };
  }

  // cycle
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getRoles();
      this.getShifts();
      this.getFrequency();
      this.getRolesList();
    }
  }

  // api calls
  getRoles() {
    const { userInfo, sData } = this.state;
    let filter = `?q=GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_2
      }','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${userInfo.pTenantId}&limit=1000&offset=0&finder=FindParentGroups&orderBy=CreatedOn:desc`;
    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData.roleTypeList = common.getOptionsData(response.items, "GroupDesc", "GroupId");
          this.setState({
            sData
          });
        }
      },
      (error) => { }
    );
  };

  getShifts = (_) => {
    const { sData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=SHIFT_TYPE&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData.shiftList = common.getOptionsData(response.items, "Name", "Code")
          this.setState({
            sData
          });
        }
      },
      (error) => { }
    );
  };

  getFrequency = (_) => {
    const { sData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=CHOLA_DAILY_TASK_FREQ&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData.frequencyList = common.getOptionsData(response.items, "Name", "Code")
          this.setState({ sData });
        }
      },
      (error) => { }
    );
  };

  getRolesList = async () => {
    this.setLoading("roles", true);
    const { userInfo, filter, sData } = this.state;
    let params = `?limit=1000&offset=0&expand=all&totalResults=true&orderBy=CreatedOn:desc&q=TenantId=${userInfo.pTenantId}`;
    if (filter.roles) {
      params += ` AND UPPER(GroupDesc) LIKE '*${filter.roles}%`;
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_ROLES_HDR + params);
    sData.rolesList = [];
    let roleInfo = {
      roleId: "",
      groupId: ""
    }
    restServices.getRequest(
      url,
      (response) => {
        if (response.items) {
          roleInfo = {
            roleId: response.items[0].RoleHdrId,
            groupId: response.items[0].GroupId
          }
          sData.rolesList = response.items;
        }
        this.setState({
          sData,
          roleInfo
        }, () => {
          this.ResponsibleList.onGetList(roleInfo.roleId);
          this.setLoading("roles", false);
        })
      },
      (error) => {
        this.setState({
          sData,
          roleInfo
        }, () => {
          this.setLoading("roles", false);
        })
      }
    );
  };

  addRole() {
    this.setSubmit("roles", true);
    const { userInfo, role } = this.state;
    let body = {
      Active: "Y",
      GroupId: role.value,
      TenantId: userInfo.pTenantId,
    };
    let url = encodeURI(envConfig.BASE_API + Services.CRM_ROLES_HDR);
    restServices.postRequest(
      url,
      body,
      (response) => {
        if (response) {
          this.getRolesList();
          this.setSubmit("roles", false);
          this.setModal("roles", false)
        }
      },
      (error) => {
        //common.snack("E", "Unable to add role!");
        this.setSubmit("roles", false);
        this.setModal("roles", false)
      }
    );
  }

  deleteRole = (id) => {
    const { roleInfo } = this.state;
    let url = envConfig.BASE_API + Services.CRM_ROLES_DTL + "/" + id;
    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "The Responsibilities has been deleted successfully.")
        this.ResponsibleList.onGetList(roleInfo.roleId);
      },
      (error) => {
        common.snack("E", "Unable To Delete Responsibility!");
      });
  }

  // # handler #
  onChange = (e) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onCheck = (e) => {
    const { fields } = this.state;
    const { name, checked } = e.target;
    fields[name] = (checked ? "Y" : "N");
    this.setState({ fields })
  }

  onSelect = (e, name) => {
    const { fields } = this.state;
    fields[name] = e;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onRoleSelect = (e) => {
    this.setState({
      role: e
    })
  }

  onRoleChange = (item) => {
    let roleInfo = {
      roleId: item.RoleHdrId,
      groupId: item.GroupId
    }
    this.setState({
      roleInfo
    }, () => {
      this.ResponsibleList.onGetList(roleInfo.roleId);
    })
  }

  onFilter = (event) => {
    const { filter } = this.state;
    const { name, value } = event.target;
    filter[name] = value;
    this.setState({
      filter,
    }, () => {
      this.getRolesList();
    });
  };

  // # validate #
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null || fields[key].value == "") {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].minLength && fields[key].length < errors[key].minLength) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (errors[key].isValidEmail && !crm.ValidateEmail(fields[key])) {
        errors[key].error = errors[key].isValidEmailReq;
        isValid = false;
      } else if (errors[key].matchPassword) {
        if (fields[key] !== fields["password"]) {
          errors[key].error = errors[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  // # submit handler #
  onSubmitRole() {
    const { role } = this.state;
    if (role.value == "") {
      common.snack("E", "Please select the role");
    } else {
      this.addRole();
    }
  }

  onSubmitResponsible() {
    this.setSubmit("responsible", true);
    const { userInfo, fields, roleInfo } = this.state;
    let isValid = this.validateForm();
    if (isValid) {
      this.setAdding(true);
      let query = {
        Responsibilities: fields.name,
        Area: fields.area,
        Shift: fields.shift.value,
        ReportingFreq: fields.frequency.value,
        Duration: fields.duration,
        CarryForward: fields.carry,
        Active: "Y",
        RoleHdrId: roleInfo.roleId,
        GroupId: roleInfo.groupId,
        TenantId: userInfo.pTenantId,
      }
      let url = encodeURI(envConfig.BASE_API + Services.CRM_ROLES_DTL);
      restServices.postRequest(
        url,
        query,
        (response) => {
          if (response) {
            common.snack("S", "Responsibility has been added");
            this.setAdding(false);
            this.closeResponsibleModal()
            this.ResponsibleList.onGetList(roleInfo.roleId);
          }
        },
        (error) => {
          common.snack("E", "Failed to add responsibility");
          this.setAdding(false);
          this.closeResponsibleModal()
        }
      );
    }
  }

  // # support function #
  deleteData(id) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to delete this item?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                this.deleteRole(id);
                onClose();
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  closeResponsibleModal() {
    const { selectVal } = this.state;
    this.setModal("responsible", false);
    this.setState({
      fields: {
        name: "",
        area: "",
        shift: selectVal,
        frequency: selectVal,
        duration: "",
        carry: "Y",
      },
      formSubmit: {
        roles: false,
        responsible: false
      }
    });
  }

  // common
  setLoading(name, status) {
    const { isLoading } = this.state;
    isLoading[name] = status
    this.setState({ isLoading })
  }

  setModal(name, status) {
    const { modal } = this.state;
    modal[name] = status
    this.setState({ modal })
  }

  setSubmit(name, status) {
    const { formSubmit } = this.state;
    formSubmit[name] = status
    this.setState({ formSubmit })
  }

  setAdding(status) {
    this.setState({
      isAdding: status
    })
  }

  render() {
    const {
      isLoading,
      isAdding,
      errors,
      formSubmit,
      filter,
      role,
      roleInfo,
      fields,
      modal,
      sData,
      userInfo,
      userPermissions,
    } = this.state;

    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <Row>
              <Col lg={3} sm={4}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Roles</h2>
                    <button type="button" className="btn btn-add" onClick={() => this.setModal("roles", true)}>Add</button>
                  </div>
                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img src={common.loadImg("search-icn.png")} alt="Search" />
                        </div>
                      </div>
                      <input
                        type="text"
                        name="roles"
                        className="form-control"
                        placeholder="Search Roles"
                        onChange={(e) => this.onFilter(e)}
                      />
                    </div>
                    {isLoading.roles ? (
                      <Loader />
                    ) : sData.rolesList == "" ? (
                      <NoRecordFound />
                    ) : (
                      <ul className="marketing-list">
                        {sData.rolesList.map((item, index) => {
                          return (
                            <li key={item.RoleHdrId} className={`d-flex justify-content-between align-items-center ${roleInfo.roleId == item.RoleHdrId ? "active" : ""}`} onClick={(e) => this.onRoleChange(item)}>
                              <div className="mlLeft mr-3">
                                <h4 className="role-name">
                                  {item.GroupDesc || "Role"}
                                </h4>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </Paper>
                </div>
              </Col>
              <Col lg={9} sm={8}>
                <div className="formTabSection position-r">
                  <ResponsibleList
                    ref={(instance) => {
                      this.ResponsibleList = instance;
                    }}
                    userPermissions={userPermissions}
                    onAddResponsible={() => this.setModal("responsible", true)}
                    onDelete={(id) => this.deleteData(id)}
                  />
                </div>
              </Col>
            </Row>
            {/* Role dialog */}
            <Dialog
              open={modal.roles}
              aria-labelledby="responsive-dialog-title"
              className="role-response-dialog"
            >
              <DialogTitle id="responsive-dialog-title" className="popup-title">Add Roles</DialogTitle>
              <DialogContent>
                <div className="popup-form-wrap">
                  <div className="list-row">
                    <Form.Group>
                      <Form.Label>Select Role*</Form.Label>
                      <Select
                        name="role"
                        value={role}
                        onChange={(val) => this.onRoleSelect(val)}
                        options={sData.roleTypeList}
                      />
                    </Form.Group>
                  </div>
                </div>
              </DialogContent>
              <DialogActions className="popup-footer justify-content-start">
                <Row className="botBtn">
                  <Col sm={12}>
                    <Button
                      className="saveBtn"
                      onClick={() => this.onSubmitRole()}
                      type="submit"
                      disabled={formSubmit.roles}
                    >
                      {formSubmit.roles ? "Processing" : "Submit"}
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={() => this.setModal("roles", false)}
                      type="button"
                      disabled={formSubmit.roles}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </Dialog>
            {/* Responsible dialog */}
            <Dialog
              open={modal.responsible}
              aria-labelledby="responsive-dialog-title"
              className="leave-dialog"
            >
              <DialogTitle id="responsive-dialog-title" className="popup-title">Add Responsibility</DialogTitle>
              <DialogContent>
                <div className="popup-form-wrap">
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <Form.Group>
                        <Form.Label>Responsibility Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={fields.name}
                          onChange={(e) => this.onChange(e)}
                        />
                        {errors.name.error !== "" && formSubmit.responsible === true && (
                          <Form.Text className="error">
                            {errors.name.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <Form.Group>
                        <Form.Label>Area*</Form.Label>
                        <Form.Control
                          type="text"
                          name="area"
                          value={fields.area}
                          onChange={(e) => this.onChange(e)}
                        />
                        {errors.area.error !== "" && formSubmit.responsible === true && (
                          <Form.Text className="error">
                            {errors.area.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <Form.Group>
                        <Checkbox
                          name="carry"
                          checked={fields.carry == "Y" ? true : false}
                          label="Carry Forward"
                          labelStyle={{ color: "#A7AFC2" }}
                          labelPosition="left"
                          onClick={(e) => this.onCheck(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <Form.Group>
                        <Form.Label>Shift*</Form.Label>
                        <Select
                          value={fields.shift}
                          onChange={(e) => this.onSelect(e, "shift")}
                          options={sData.shiftList}
                        />
                        {errors.shift.error !== "" && formSubmit.responsible === true && (
                          <Form.Text className="error">
                            {errors.shift.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-md-6 mb-6">
                      <Form.Group>
                        <Form.Label>Reporting Frequency*</Form.Label>
                        <Select
                          value={fields.frequency}
                          onChange={(e) => this.onSelect(e, "frequency")}
                          options={sData.frequencyList}
                        />
                        {errors.frequency.error !== "" && formSubmit.responsible === true && (
                          <Form.Text className="error">
                            {errors.frequency.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-12">
                      <Form.Group>
                        <Form.Label>Duration*</Form.Label>
                        <Form.Control
                          type="text"
                          name="duration"
                          value={fields.duration}
                          onChange={(e) => this.onChange(e)}
                        />
                        {errors.duration.error !== "" && formSubmit.responsible === true && (
                          <Form.Text className="error">
                            {errors.duration.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                </div>
              </DialogContent>
              <DialogActions className="popup-footer justify-content-start">
                <Row className="botBtn">
                  <Col sm={12}>
                    <Button
                      className="saveBtn"
                      onClick={() => this.onSubmitResponsible()}
                      type="submit"
                      disabled={isAdding}
                    >
                      {isAdding ? "Processing" : "Submit"}
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={() => this.closeResponsibleModal()}
                      type="button"
                      disabled={isAdding}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

export default RolesResponsibleList;