import React, { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools } from "_helpers";
// json
import lookupList from "crm/files/lookupList.json";

const DynamicSelect = forwardRef((props, ref) => {
  const { field, fieldIndex, data, params } = props;
  const lookup = lookupList[field.attr.lookup];
  const [mount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  var delayTimer;
  //permission from lead and deal to control access for other department, responsible person
  const userPermissions = props.userPermissions ? props.userPermissions : {
    read: "1",
    add: "1",
    update: "1",
    delete: "1",
    import: "1",
    export: "1",
    access: "1",
  };

  // console.log('userPermissions', userPermissions)
  let userData = tools.deCrypt(localStorage.getItem("userData"));
  //let territory = userData["territory"];
  let isAdmin = userData["roleId"] === 0;
  let deptEmployess = userData["employeeList"];
  let empIdList = [];
  if (!isAdmin) {
    if(props.recordId == "")
    {
      if (userPermissions.add == "3") {
        empIdList.push(userData.EmpId);
      } else if (userPermissions.add == "4") {
        empIdList.push(...deptEmployess.active);
      } else if (userPermissions.add == "5") {
        empIdList.push(...deptEmployess.active);
        empIdList.push(...deptEmployess.sub);
      }
    }else{
      if (userPermissions.update == "3") {
        empIdList.push(userData.EmpId);
      } else if (userPermissions.update == "4") {
        empIdList.push(...deptEmployess.active);
      } else if (userPermissions.update == "5") {
        empIdList.push(...deptEmployess.active);
        empIdList.push(...deptEmployess.sub);
      }
    }
  }

  // effect
  useEffect(() => {
    if (isRender) {
      if (checkDependent()) {
        loadData();
      } else {
        setMount(true);
        setResultList([]);
        setIsLoading(false);
      }
    }
  }, [isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  useEffect(() => {
    let dependent = field.attr.dependent;
    if (mount && fieldIndex !== null && dependent !== null) {
      let activeField = data.fields[fieldIndex];
      let isExist = (activeField.key in dependent);
      if (isExist) {
        let value = activeField.value;
        if (typeof (value) == "object") {
          value = value.value;
        }
        onChange("");
        setOffset(0);
        setResultList([]);
        if (value !== "") {
          setIsLoading(true);
          loadData();
        }
      }
    }
  }, [field]);

  // support
  const checkDependent = () => {
    let dependent = field.attr.dependent;
    let allow = true;
    if (dependent !== null) {
      Object.keys(dependent).map(item => {
        let dependField = data.fields.filter(fItem => fItem.key === item);
        let value = dependField[0].value;
        if (typeof (value) == "object") {
          value = value.value;
        }
        if (value === "") {
          allow = false;
        }
      })
    }
    return allow;
  }

  const dependentData = () => {
    let dependent = field.attr.dependent;
    let result = "";
    if (dependent !== null) {
      Object.keys(dependent).map(item => {
        let dependField = data.fields.filter(fItem => fItem.key === item);
        let value = dependField[0].value;
        if (typeof (value) == "object") {
          value = value.value;
        }
        if (value !== "") {
          result += ` AND ${dependent[item]}=${value}`;
        }
      })
    }
    return result;
  }

  const getUrl = () => {
    
    let url = `${envConfig.BASE_API}${Services[lookup.name]}`;
    let fields = `?fields=${lookup.get},${lookup.set}`;
    let trasaction = `&q=${lookup.name == "TRANSACTION_STATUS" ? `TransactionTypeId=${common.getTransactionTypeId(envConfig[field.attr.data1])} AND Active='Y' AND ` : ""}`;
    let vTenant = `${lookup.name == "CRM_EMPLOYEES" ? "TenantId" : "TenantId"}=${userInfo.pTenantId}`;

    let vEmp = `${lookup.name == "CRM_EMPLOYEES" ? (empIdList.length > 0 ? " AND WorkingStatus='ACTIVE' AND EmployeeId IN (" + empIdList.join(",") + ")" : " AND WorkingStatus='ACTIVE'") : ""}`;

    let search = ` AND UPPER(${lookup.get}) LIKE '*{SEARCH}*'`;
    let orderBylimit = `&orderBy=${lookup.get}:asc&limit=${limit}&offset=${offset * limit}&totalResults=true`;
    let query = dependentData();
    // if(lookup.name == "CRM_EMPLOYEES" && empIdList.length > 0){
    //    console.log('emp url', url + fields + trasaction + vTenant + query + orderBylimit);
    //   return (url + fields + trasaction + vTenant + vEmp + query + orderBylimit);  
    // }
    return (url + fields + trasaction + vTenant + vEmp + search + query + orderBylimit);
  }

  // api
  const loadData = () => {
    let url = getUrl().replace("{SEARCH}", "");
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let data = common.getOptionsOnlyData(response.items, lookup.get, lookup.set, true);
          setResultList(oldData => {
            return [...new Set([...oldData, ...data])];
          })
          setMount(true);
          setIsLoadingMore(false);
          setHasMore(response.hasMore);
          if (!props.isRender) {
            setIsMenuOpen(true);
            setIsFocus(true);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    )
  }

  // load
  const loadOptions = async (inputValue) => {
    var url = getUrl().replace("{SEARCH}", inputValue);
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(response.items, lookup.get, lookup.set, true)
              resolve(data);
            } else {
              resolve([])
            }
          },
          (error) => {
            resolve([]);
          }
        )
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = value => {
    props.onSelect(value, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  const loadMore = () => {
    if (hasMore && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset(prev => ++prev);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <AsyncSelect
        ref={ref}
        isMulti={props.isMulti}
        isClearable={true}
        value={field.value}
        defaultOptions={resultList}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
        onMenuScrollToBottom={() => loadMore()}
        isLoading={isLoadingMore}
        isDisabled={!checkDependent() || props.disabled}
      />
    )
  );
})

export default DynamicSelect;