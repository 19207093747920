import React, { useEffect, useState, Fragment } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
// Import Dialog from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// material
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// api
import * as Services from "_config/api";
import { envConfig, eduTechConfig } from "_config/config";
import { common, restServices, eduTechSerivces, history } from "_helpers";
import { FormFieldAuto } from "pages/_components/FormFields";
import Loader from "_components/_loader/Loader";
import "./includes/DynamicForm.css";

const selectDefault = { label: "", value: "" };
const reviewStatus = [
  { value: "missing", label: "Missing" },
  { value: "under_review", label: "Under Review" },
  { value: "reviewed", label: "Reviewed" },
];
const canApprStatus = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];

function EduTechLead(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [fields, setFields] = useState({}); //crm lead response
  const [univeristies, setUniveristies] = useState([]);
  const [course1List, setCourse1List] = useState([]);
  const [course2List, setCourse2List] = useState([]);
  // const [leadSource, setLeadSource] = useState([]);
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [disableSuggestion, setDisableSuggestion] = useState(false);
  const [leadStatus, setLeadStatus] = useState(eduTechConfig.LeadStatus);
  const [mSel, setMSel] = useState([]);
  const initialMFields = {
    field: "",
    desc: "",
    status: "missing",
  };
  const [mFields, setMFields] = useState([initialMFields]);

  // // effect
  useEffect(() => {
    if (props.data.value1 == "") {
      history.push("/crm/lead");
    } else {
      setFields(props.data.leadData);
      setCRMLeadData(props.data.leadData);
      getETUniversities();
      //getColumnsData();
    }
  }, []);

  useEffect(() => {
    mFields.filter((n) => n);
    let selected = mFields.map((a) => a.field);
    setMSel(selected);
  }, [mFields]);

  // #Api#
  // const getColumnsData = () => {
  //   let filter = `?q=EserviceCode=${envConfig.ESERVICE_LEAD_CODE}`;
  //   let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
  //   restServices.getRequest(
  //     url,
  //     (response) => {
  //       if (response) {
  //         let formFields = JSON.parse(response.items[0].FormFields);
  //         let jsonFields = formFields.form;
  //         if (jsonFields.fields) {
  //           let sourceKey = jsonFields.fields.filter(
  //             (obj) => obj.key == "LeadSource"
  //           );
  //           let options = [];
  //           if (sourceKey[0].attr && sourceKey[0].attr.option) {
  //             sourceKey[0].attr.option.map((obj) => {
  //               options.push({ label: obj, value: obj });
  //             });
  //             setLeadSource(options);
  //           }
  //         }
  //       }
  //     },
  //     (error) => {}
  //   );
  // };

  //set CRM lead value
  const setCRMLeadData = (data) => {
    let newFields = common.reParse(data);
    newFields.AppCourse = data.Clattr1
      ? JSON.parse(data.Clattr1)
      : { course: "", university: "", month: "", year: "" };
    console.log("newFields.AppCourse", newFields.AppCourse);
    newFields.EduData = data.Clattr2 ? JSON.parse(data.Clattr2) : "";
    newFields.workData = data.Clattr3 ? JSON.parse(data.Clattr3) : "";
    newFields.testData = data.Clattr4 ? JSON.parse(data.Clattr4) : "";
    newFields.extraActivity = data.Clattr7
      ? JSON.parse(data.Clattr7)
      : { extraCurricular: [], otherActivity: [] };

    if (data.Eattr3) {
      setDisableSuggestion(true);
    }

    newFields.CourseNameS1 = selectDefault;
    newFields.UniversityNameS1 = selectDefault;

    newFields.CourseNameS2 = selectDefault;
    newFields.UniversityNameS2 = selectDefault;

    newFields.isUpdate = false;

    let sCourses = data.Clattr5 ? JSON.parse(data.Clattr5) : [];
    if (sCourses.length > 0) {
      //setDisableSuggestion(true);
      sCourses.map((course, idx) => {
        if (idx == 0) {
          newFields.CourseNameS1 = course.course;
          newFields.UniversityNameS1 = course.university;
        } else {
          newFields.CourseNameS2 = course.course;
          newFields.UniversityNameS2 = course.university;
        }
      });
    }

    newFields.SalespersonId = {
      label: data.SalespersonIdDesc,
      value: data.SalespersonId,
    };
    newFields.LeadSource = { label: data.LeadSource, value: data.LeadSource };
    setLeadStatus(common.enableLeadStatus(data.LeadStatus));
    newFields.LeadStatus = { label: data.LeadStatus, value: data.LeadStatus };
    setFields(newFields);

    if (data.Clattr5) {
      getUniversityColleges(newFields);
    }
    if (data.Clattr6 && data.Cattr19) {
      setMFields(JSON.parse(data.Clattr6));
      setMSel(data.Cattr19.split(","));
    }
    setStatus("completed");
  };

  //Get universities from edutech
  const getETUniversities = async () => {
    let result = await eduTechSerivces.getUniversities();
    let udata = common.getOptionsData(result, "name", "_id");
    setUniveristies(udata);
  };

  //Get courses from edutech
  const getETCourseByUId = async (universityId, idx, isEdit, leadData) => {
    let result = await eduTechSerivces.getCoursesbyUniversity(universityId);
    let optionsData = [];
    optionsData.push({ label: "Select Course", value: "" });

    if (result && result.length) {
      await result.map((obj) => {
        let options = {
          label: obj.name,
          value: obj,
        };
        optionsData.push(options);
      });
    }

    if (idx == 1) setCourse1List(optionsData);
    else setCourse2List(optionsData);
  };

  //Bind university and colleges list in selectbox
  const getUniversityColleges = (field) => {
    getETCourseByUId(field.UniversityNameS1.value, 1, true, field);
    getETCourseByUId(field.UniversityNameS2.value, 2, true, field);
  };

  // const getResposiblePersonEmail = (empId) => {
  //   let url = encodeURI(
  //     envConfig.BASE_API +
  //       Services.CRM_PAY_EMPLOYEE +
  //       "/" +
  //       empId +
  //       "?fields=Email"
  //   );
  //   restServices.getRequest(
  //     url,
  //     (response) => {
  //       if (response) {
  //         let newFields = common.reParse(fields);
  //         newFields.responsiblePersonEmail = response.Email;
  //         setFields(newFields);
  //       }
  //     },
  //     (error) => {}
  //   );
  // };

  // Form field auto handler
  const onSelect = (value, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = value || selectDefault;
    newFields.isUpdate = true;
    setFields(newFields);
    // if (name == "SalespersonId" && value) {
    //   getResposiblePersonEmail(value.value);
    // }
  };

  //text, textarea handler
  const onChange = (e) => {
    const { name, value } = e.target;
    let newFields = common.reParse(fields);
    newFields[name] = value;
    newFields.isUpdate = true;
    setFields(newFields);
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    if (name == "Cattr24" && !e.target.checked) {
      setMFields([initialMFields]);
      newFields.Cattr19 = "";
      setMSel([]);
    }
    newFields[name] = e.target.checked ? "Y" : "N";
    setFields(newFields);
  };

  // Missing Select handler
  const onMissingSelect = (data, name, idx) => {
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData[idx][name] = name == "desc" ? data : data.value;
      return newData;
    });
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    newFields.isUpdate = true;

    if (name == "UniversityNameS1") {
      newFields.CourseNameS1 = selectDefault;
    } else if (name == "UniversityNameS2") {
      newFields.CourseNameS2 = selectDefault;
    }
    setFields(newFields);

    if (name == "UniversityNameS1" || name == "UniversityNameS2") {
      getETCourseByUId(data.value, name == "UniversityNameS1" ? 1 : 2, false);
    }
    if (name == "CourseNameS1" || name == "CourseNameS2") {
      if (
        newFields.CourseNameS1.value._id == fields.AppCourse.courseId &&
        newFields.UniversityNameS1.value == fields.AppCourse.universityId
      ) {
        common.snack(
          "E",
          "You selected applied course as suggested course 1. Please select another course"
        );
      } else if (
        newFields.CourseNameS2.value._id == fields.AppCourse.courseId &&
        newFields.UniversityNameS2.value == fields.AppCourse.universityId
      ) {
        common.snack(
          "E",
          "You selected applied course as suggested course 2. Please select another course"
        );
      } else if (
        newFields.CourseNameS1.value._id == newFields.CourseNameS2.value._id
      ) {
        common.snack(
          "E",
          "Both suggested courses are same. Please select different courses"
        );
      }
    }
  };

  const addMRow = (e) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData.push({
        field: "",
        desc: "",
        status: "missing",
      });
      return newData;
    });
  };

  const removeMRow = (e, idx) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      let removeLabel = newData[idx].field;
      newData.splice(idx, 1);
      return newData;
    });
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  //go back to list page
  const onCancel = () => {
    history.push("/crm/lead");
  };

  const update = async () => {
    let inputs = await formInputs();
    console.log("inputs", inputs);
    if (inputs.valid == true && inputs.leadData && inputs.appData) {
      setIsSubmit(true);
      let url =
        envConfig.BASE_API + Services.CRM_LEADS + "/" + props.data.value1;

      restServices.patchRequest(
        url,
        inputs.leadData,
        async (response) => {
          window.scrollTo(0, 0);
          setIsSubmit(false);
          if (Object.keys(inputs.appData).length > 0) {
            await eduTechSerivces.updateApplicationInfo(
              fields.Cattr1,
              inputs.appData
            );
          }
          if (typeof props.onSave !== "undefined") {
            props.onSave(response);
            setCRMLeadData(response);
          }
          common.snack("S", "Successfully Updated");
          setLeadStatus(common.enableLeadStatus(fields.LeadStatus.value));
        },
        (error) => {
          setIsSubmit(false);
        }
      );
    }
  };

  const formInputs = async () => {
    let appData = {};
    let uFields = {};
    let valid = true;

    uFields.Cattr24 = fields.Cattr24;
    uFields.Cattr26 = fields.Cattr26;

    //Responsible person
    if (fields.SalespersonId.value) {
      uFields.SalespersonId = fields.SalespersonId.value;
      appData.salesPersonId = fields.SalespersonId.value;
      // appData.responsiblePersonEmail =
      //   fields.responsiblePersonEmail || fields.SalespersonIdDesc;
    }

    //Lead status
    if (fields.LeadStatus.value) uFields.LeadStatus = fields.LeadStatus.value;

    if (fields.LeadStatus.value == "Processing" && fields.Cattr24 == "Y") {
      uFields.Cattr19 = "";
      uFields.Clattr6 = null;
      //Missing Fields
      if (mFields.length > 0) {
        appData.status = "review_missing_info";
        let mData = [];
        mFields.map((obj) => {
          if (obj.field && obj.desc && obj.status) {
            mData.push({
              field: obj.field,
              description: obj.desc,
              status: obj.status,
            });
          } else {
            valid = false;
          }
        });
        if (valid == false) {
          common.snack("E", "Plese fill the missing information");
        }
        appData.missingInfo = mData;

        var filtered = mSel.filter(Boolean);
        uFields.Cattr19 = filtered.join(",");
        uFields.Clattr6 = JSON.stringify(mFields);
      } else {
        valid = false;
        common.snack("E", "Plese fill the missing information");
      }
    } else if (
      fields.LeadStatus.value == "Processing" &&
      fields.Cattr24 == "N"
    ) {
      uFields.Cattr19 = "";
      uFields.Clattr6 = null;
      appData.status = "review_missing_info";
      appData.missingInfo = [];
    }
    if (fields.LeadStatus.value == "Processing" && fields.Cattr26 == "Y") {
      let leadSCourse = [];
      let appSCourse = [];
      let validSCourse = true;
      //Course1 Note
      if (fields.Cattr29) {
        uFields.Cattr29 = fields.Cattr29;
        appData.feedBack = fields.Cattr29;
      }

      if (fields.CourseNameS1.value && fields.UniversityNameS1.value) {
        if (
          fields.CourseNameS1.value._id == fields.AppCourse.courseId &&
          fields.UniversityNameS1.value == fields.AppCourse.universityId
        ) {
          validSCourse = false;
        } else {
          leadSCourse.push({
            course: fields.CourseNameS1,
            university: fields.UniversityNameS1,
          });

          uFields.Eattr4 =
            fields.CourseNameS1.label + ", " + fields.UniversityNameS1.label;

          appSCourse.push({
            course: fields.CourseNameS1.value._id,
          });
        }
      }
      if (fields.CourseNameS2.value && fields.UniversityNameS2.value) {
        if (
          fields.CourseNameS2.value._id == fields.AppCourse.courseId &&
          fields.UniversityNameS2.value == fields.AppCourse.universityId
        ) {
          validSCourse = false;
        } else {
          leadSCourse.push({
            course: fields.CourseNameS2,
            university: fields.UniversityNameS2,
          });
          uFields.Eattr5 =
            fields.CourseNameS2.label + ", " + fields.UniversityNameS2.label;

          appSCourse.push({
            course: fields.CourseNameS2.value._id,
          });
        }
      }

      // suggested Courses
      if (validSCourse) {
        if (fields.CourseNameS1.value._id == fields.CourseNameS2.value._id) {
          valid = false;
          common.snack(
            "E",
            "Both suggested courses are same. Please select different courses"
          );
        } else if (appSCourse.length == 2 && leadSCourse.length == 2) {
          uFields.Clattr5 = JSON.stringify(leadSCourse);
          uFields.Cattr34 =
            fields.AppCourse.course +
            "," +
            fields.AppCourse.university +
            ", " +
            fields.AppCourse.year +
            ", " +
            fields.AppCourse.month;
          appData.status = "review_missing_info";
          if (fields.Eattr3 == null) {
            appData.feedBack = fields.Cattr29;
            appData.suggestedCourses = appSCourse;
          }
        } else {
          valid = false;
          common.snack("E", "Please fill the all details in suggested courses");
        }
      } else {
        valid = false;
        common.snack(
          "E",
          "You selected applied course as suggested course. Please select another course"
        );
      }
    } else if (fields.LeadStatus.value == "Complete Lead") {
      //clear missing fields
      uFields.Cattr19 = "";
      uFields.Clattr6 = null;
      if (
        fields.Cattr34 != null &&
        fields.Clattr5 != null &&
        fields.Eattr3 == null
      ) {
        valid = false;
        common.snack(
          "E",
          "You can't change Lead status as Complete Lead. Because suggested course not picked by student!"
        );
      }
    } else if (fields.Cattr35 && fields.Cattr36 && fields.Cattr36.value) {
      uFields.Cattr35 = fields.Cattr35;
      uFields.Cattr36 = fields.Cattr36.value;
      if (fields.Cattr36.value == "accepted") {
        uFields.LeadStatus = "Application Withdrawn";
        appData.status = "cancelled";
        appData.action = "accepted";
      } else {
        appData.status = "cancelled";
        appData.action = "rejected";
        appData.description = fields.Cattr39;
        uFields.Cattr39 = fields.Cattr39;
      }
      //appData
    } else if (fields.LeadStatus.value == "Application Rejected") {
      appData.status = "rejected";
      appData.description = fields.Cattr32;
      uFields.Cattr32 = fields.Cattr32;
    }

    return {
      leadData: uFields,
      appData: appData,
      valid: valid,
    };
  };

  const renderAttachment = (docs) => {
    return (
      <div>
        {docs.map((image, idx) => {
          return ["jpg", "jpeg", "png"].includes(image.split(".").pop()) ? (
            <img
              key={idx}
              src={common.loadEduImage(image)}
              className="eduTechImg"
              onClick={(e) => previewImage(e, image)}
            />
          ) : (
            <div className="attach-link" key={idx}>
              <a href={common.loadEduImage(image)} download>
                <img src={common.loadImg("attachment.png")} />
                {image.split("/").pop()}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  // render
  return (
    <Fragment>
      {status == "process" ? (
        <Loader />
      ) : (
        status == "completed" && (
          <Fragment>
            <div className="mb-100">
              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Application Information</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Application ID</Form.Label>{" "}
                        {fields.Cattr1 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Student Email</Form.Label>{" "}
                        {fields.Email || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Agent Email</Form.Label>
                        {fields.Cattr27 && fields.Cattr28
                          ? fields.Cattr27 + " & " + fields.Cattr28
                          : "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Application Date</Form.Label>{" "}
                        {fields.Dattr2
                          ? common.formatDate(
                              fields.Dattr2,
                              "DD-MM-YYYY HH:MM a"
                            )
                          : ""}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Responsible Person</Form.Label>
                      <FormFieldAuto
                        isClearable={false}
                        isRender={true}
                        name="SalespersonId"
                        type="FindEmployee"
                        value={fields.SalespersonId}
                        onSelect={onSelect}
                      />
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Lead Source</Form.Label>
                        {fields.LeadSource.value || "-"}
                        {/* <Select
                      defaultValue={fields["LeadSource"]}
                      onChange={(data) => onSelectHandler(data, "LeadSource")}
                      options={leadSource}
                    ></Select> */}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Application Status</h2>
                  </div>
                  <Row className="mb-3">
                    <Col sm={3}>
                      <Form.Label>Lead Status</Form.Label>
                      <Select
                        onChange={(data) => onSelectHandler(data, "LeadStatus")}
                        options={leadStatus}
                        isOptionDisabled={(option) => option.disabled}
                        defaultValue={fields["LeadStatus"]}
                      />
                    </Col>
                    {fields.LeadStatus.value == "Application Rejected" && (
                      <Col sm={3}>
                        <Form.Label>Rejected Reason</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="Cattr32"
                          rows={2}
                          value={fields.Cattr32}
                          onChange={(e) => onChange(e)}
                          maxLength={150}
                        />
                      </Col>
                    )}
                    {fields.LeadStatus.value == "Processing" && (
                      <Col sm={9}>
                        <Form.Group className="mt-4">
                          <FormControlLabel
                            value={fields.Cattr24}
                            control={<Checkbox color="primary" />}
                            label={"Is Missing Information?"}
                            labelPlacement="end"
                            checked={fields.Cattr24 === "Y" ? true : false}
                            onChange={(e) => onFieldChange(e, "Cattr24")}
                          />
                          <FormControlLabel
                            value={fields.Cattr26}
                            control={<Checkbox color="primary" />}
                            label={"Course Suggestion?"}
                            labelPlacement="end"
                            disabled={fields.Eattr3 != null}
                            checked={fields.Cattr26 === "Y" ? true : false}
                            onChange={(e) => onFieldChange(e, "Cattr26")}
                          />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  {fields.LeadStatus.value == "Processing" &&
                    fields.Cattr24 == "Y" && (
                      <>
                        <Row>
                          <Col className="sub-head-title">
                            <h2>Missing Information</h2>
                          </Col>
                        </Row>
                        {mFields.map((mObj, idx) => {
                          let clCount = idx;
                          return (
                            <Row key={clCount}>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Missing Field</Form.Label>
                                  <Select
                                    id={clCount}
                                    value={eduTechConfig.missingFields.find(
                                      (o) => o.value == mObj.field
                                    )}
                                    isMulti={false}
                                    onChange={(data) =>
                                      onMissingSelect(data, "field", clCount)
                                    }
                                    options={eduTechConfig.missingFields.filter(
                                      (obj) => !mSel.includes(obj.value)
                                    )}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Missing Field Note</Form.Label>
                                  <Form.Control
                                    id={clCount}
                                    as="textarea"
                                    rows={1}
                                    value={mObj.desc}
                                    onChange={(e) =>
                                      onMissingSelect(
                                        e.target.value,
                                        "desc",
                                        clCount
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Review Status</Form.Label>
                                  <Select
                                    id={clCount}
                                    value={reviewStatus.find(
                                      (o) => o.value == mObj.status
                                    )}
                                    isMulti={false}
                                    onChange={(data) =>
                                      onMissingSelect(data, "status", idx)
                                    }
                                    options={reviewStatus}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                {/* <div className="col"> */}
                                <div className="mt-4 form-group">
                                  {mFields.length === 1 && idx === 0 ? (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => {
                                        addMRow(e);
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    idx === 0 && (
                                      <Link
                                        to="#"
                                        className="add-rule-link remove-rule"
                                        onClick={(e) => {
                                          removeMRow(e, clCount);
                                        }}
                                      >
                                        -
                                      </Link>
                                    )
                                  )}
                                  {idx > 0 && (
                                    <React.Fragment>
                                      <Link
                                        to="#"
                                        className="add-rule-link remove-rule"
                                        onClick={(e) => removeMRow(e, clCount)}
                                      >
                                        -
                                      </Link>
                                      {mFields.length - 1 == idx && (
                                        <Link
                                          to="#"
                                          className="add-rule-link add-rule"
                                          onClick={(e) => addMRow(e)}
                                        >
                                          +
                                        </Link>
                                      )}
                                    </React.Fragment>
                                  )}
                                </div>
                                {/* </div> */}
                              </Col>
                            </Row>
                          );
                        })}

                        {/* <Row className="mb-3">
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Missing Fields</Form.Label>
                          <Select
                            name="Cattr19"
                            defaultValue={fields.Cattr19}
                            isMulti={true}
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr19")
                            }
                            options={eduTechConfig.missingFields}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Missing Information Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="Cattr23"
                            rows={2}
                            value={fields.Cattr23}
                            onChange={(e) => onChange(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row> */}
                      </>
                    )}
                  {fields.LeadStatus.value == "Processing" &&
                    fields.Cattr26 == "Y" &&
                    !disableSuggestion && (
                      <>
                        <Row>
                          <Col className="sub-head-title">
                            <h2>Suggested Course 1</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Label>University Name </Form.Label>
                            <Select
                              options={univeristies}
                              name="UniversityNameS1"
                              defaultValue={fields["UniversityNameS1"]}
                              onChange={(data) =>
                                onSelectHandler(data, "UniversityNameS1")
                              }
                            />
                          </Col>
                          <Col sm={6}>
                            <Form.Label>Course Name:</Form.Label>
                            <Select
                              options={course1List}
                              name="CourseNameS1"
                              defaultValue={fields["CourseNameS1"]}
                              onChange={(data) =>
                                onSelectHandler(data, "CourseNameS1")
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="sub-head-title">
                            <h2>Suggested Course 2</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Label>University Name </Form.Label>
                            <Select
                              options={univeristies}
                              name="UniversityNameS2"
                              defaultValue={fields["UniversityNameS2"]}
                              onChange={(data) =>
                                onSelectHandler(data, "UniversityNameS2")
                              }
                            />
                          </Col>
                          <Col sm={6}>
                            <Form.Label>Course Name:</Form.Label>
                            <Select
                              options={course2List}
                              name="CourseNameS2"
                              defaultValue={fields["CourseNameS2"]}
                              onChange={(data) =>
                                onSelectHandler(data, "CourseNameS2")
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col className="sub-head-title">
                            <h2>Applied Course Not Eligiblity Reason</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Reason</Form.Label>
                              <Form.Control
                                as="textarea"
                                placeholder="Why applied course not eligible?"
                                rows={3}
                                value={fields["Cattr29"]}
                                name={"Cattr29"}
                                onChange={(e) => onChange(e)}
                                maxLength={150}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Course Histroy</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Applied Course</Form.Label>{" "}
                        {fields.AppCourse.course || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>University</Form.Label>{" "}
                        {fields.AppCourse.university || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Intake</Form.Label>
                        {fields.AppCourse.month || "-"} &{" "}
                        {fields.AppCourse.year || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {fields.Eattr3 && (
                        <Col sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Previously Applied Course</Form.Label>{" "}
                            {fields.Eattr3 || "-"}
                          </Form.Group>
                        </Col>
                    )}
                  </Row>
                  {fields.Eattr4 && (
                    <Row>
                      <Col sm={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Suggested Courses</Form.Label>{" "}
                          {fields.Eattr4} <br />
                              {fields.Eattr5}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              {fields.Cattr23 && (
                <Row className="mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Fee Payment</h2>
                    </div>
                    <Row>
                      <Col sm={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Mode</Form.Label>{" "}
                          {fields.Cattr23 || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Date</Form.Label>{" "}
                          {fields.Dattr3
                            ? common.formatDate(
                                fields.Dattr3,
                                "DD-MM-YYYY HH:MM a"
                              )
                            : "-"}
                        </Form.Group>
                      </Col>
                      {/* <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Amount</Form.Label>
                          {fields.Cattr15 || "-"}
                        </Form.Group>
                      </Col> */}
                      {fields.Cattr23 == "Direct" ? (
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Payment Document</Form.Label>
                            {renderAttachment(fields.Cattr31)}
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col sm={2}>
                          <Form.Group className="mb-3">
                            <Form.Label>Payment Transaction</Form.Label>{" "}
                            {fields.Cattr30 || "-"}
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}

              {fields.Cattr35 && (
                <Row className="mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Application Cancel Request</h2>
                    </div>
                    <Row>
                      <Col sm={9}>
                        <Form.Group className="mb-3">
                          <Form.Label>Request Message</Form.Label>{" "}
                          {fields.Cattr35 || "-"}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Request Acceptance</Form.Label>{" "}
                          <Select
                            value={fields.Cattr36}
                            isMulti={false}
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr36")
                            }
                            options={canApprStatus}
                          />
                        </Form.Group>
                      </Col>
                      {fields.Cattr36 && fields.Cattr36.value == "rejected" && (
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Request Rejected Reason</Form.Label>{" "}
                            <Form.Control
                              as="textarea"
                              name="Cattr39"
                              rows={2}
                              value={fields.Cattr39}
                              onChange={(e) => onChange(e)}
                              maxLength={150}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>General Information</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        {fields.Cattr3 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        {fields.Cattr4 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        {fields.Email || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Mobile</Form.Label>
                        {fields.Mobile || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Dob</Form.Label>
                        {fields
                          ? common.formatDate(fields.Dattr1, "DD-MM-YYYY")
                          : "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        {fields.Cattr6 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nationality</Form.Label>
                        {fields.Cattr7 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Language</Form.Label>
                        {fields.Cattr8 || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Marital Status</Form.Label>
                        {fields.Cattr9 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Passport Number</Form.Label>
                        {fields.Cattr10 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address Line 1</Form.Label>
                        {fields.Cattr11 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        {fields.Cattr12 || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>province</Form.Label>
                        {fields.Cattr5 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>City/Town</Form.Label>
                        {fields.Cattr13 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Postcode</Form.Label>
                        {fields.Cattr14 || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Educational Information</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Country of Education</Form.Label>
                        {fields.Cattr16 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Highest Level of Education</Form.Label>
                        {fields.Cattr17 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Grade</Form.Label>
                        {fields.Cattr18 || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {fields.EduData &&
                        fields.EduData.map((college, idx) => {
                          return (
                            <Row className="row-space" key={idx}>
                              <Col sm={12}>
                                <div className="education-count">
                                  <h4>Education</h4>
                                </div>
                              </Col>
                              <Col sm="3">
                                <Form.Label>Name of Institution</Form.Label>
                                {college.institutionName || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Country of Education</Form.Label>{" "}
                                {college.institutionCountry || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Qualification</Form.Label>
                                {college.qualification || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Degree Name</Form.Label>
                                {college.degree || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Score</Form.Label>
                                {college.score || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Grading System</Form.Label>
                                {college.gradingSystem || "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Graduation Date</Form.Label>
                                {college.graduationDate
                                  ? common.formatDate(
                                      college.graduationDate,
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>Start Date</Form.Label>
                                {college.startDate
                                  ? common.formatDate(
                                      college.startDate,
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Col>
                              <Col sm={3}>
                                <Form.Label>End Date</Form.Label>
                                {college.endDate
                                  ? common.formatDate(
                                      college.endDate,
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </Col>
                              <Col sm={3} className="col-attachment">
                                <Form.Label>Documents</Form.Label>
                                {renderAttachment(college.docs)}
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  </Row>
                </Col>
              </Row>

              {fields.testData.length > 0 && (
                <Row className="mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>English Exam Scores</h2>
                    </div>
                    <Row>
                      <Col sm={12}>
                        <Form.Group className="mb-3">
                          {fields.testData &&
                            fields.testData.map((test, idx) => {
                              return (
                                <Row className="row-space" key={idx}>
                                  <Col sm={12}>
                                    <div className="test-count">
                                      <h4>Exam</h4>
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Exam Type</Form.Label>{" "}
                                    {test.examType}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Date of Issue</Form.Label>
                                    {test.dateOfIssue
                                      ? common.formatDate(
                                          test.dateOfIssue,
                                          "DD-MM-YYYY"
                                        )
                                      : "-"}{" "}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Listening</Form.Label>{" "}
                                    {test.listening || "-"}{" "}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Reading</Form.Label>{" "}
                                    {test.reading || "-"}{" "}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Writing</Form.Label>{" "}
                                    {test.writing || "-"}{" "}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Speaking</Form.Label>{" "}
                                    {test.speaking || "-"}
                                  </Col>
                                  <Col sm={3} className="col-attachment">
                                    <Form.Label>Documents</Form.Label>
                                    {renderAttachment(test.docs)}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Background Information</h2>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Did you apply for a visa before as a part of your
                          entry clearance application?
                        </Form.Label>
                        {fields.Cattr21 == "Y" ? "Yes" : "No"}
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Did your visa have been rejected before as a part of
                          your entry clearance application?
                        </Form.Label>
                        {fields.Cattr22 == "Y" ? "Yes" : "No"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {/* {fields.Cattr37 && (
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Visa Documents</Form.Label>
                          {renderAttachment([fields.Cattr37])}
                        </Form.Group>
                      </Col>
                    )} */}
                    {fields.Cattr38 && (
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Passport Document</Form.Label>
                          {renderAttachment([fields.Cattr38])}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              {fields.extraActivity &&
                fields.extraActivity.extraCurricular.length > 0 && (
                  <Row className="mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Extra Curricular activities</h2>
                      </div>
                      {fields.extraActivity.extraCurricular.map((obj, idx) => {
                        return (
                          <Row key={idx}>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                {obj.title}
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                {obj.description}
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                {renderAttachment(obj.docs)}
                              </Form.Group>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                )}

              {fields.extraActivity &&
                fields.extraActivity.otherActivity.length > 0 && (
                  <Row className="mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Other activities</h2>
                      </div>
                      {fields.extraActivity.otherActivity.map((obj, idx) => {
                        return (
                          <Row key={idx}>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                {obj.title}
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                {obj.description}
                              </Form.Group>
                            </Col>
                            <Col sm={4}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                {renderAttachment(obj.docs)}
                              </Form.Group>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                )}

              {fields.workData.length > 0 && (
                <Row className="mb-100">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Work History</h2>
                    </div>
                    <Row>
                      <Col sm={12}>
                        <Form.Group className="mb-3">
                          {fields.workData &&
                            fields.workData.map((exp, idx) => {
                              return (
                                <Row className="row-space" key={idx}>
                                  <Col sm={12}>
                                    <div className="exp-count">
                                      <h4>Experience</h4>
                                    </div>
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Company Name</Form.Label>{" "}
                                    {exp.companyName || "-"}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Designation</Form.Label>{" "}
                                    {exp.designation || "-"}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>Joining Date</Form.Label>
                                    {exp.joinDate || "-"}
                                  </Col>
                                  <Col sm={3}>
                                    <Form.Label>End Date</Form.Label>
                                    {exp.endDate || "-"}
                                  </Col>
                                  <Col sm={3} className="col-attachment">
                                    <Form.Label>Documents</Form.Label>
                                    {renderAttachment(exp.docs)}
                                  </Col>
                                </Row>
                              );
                            })}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <div className="leadfooter-btn">
                <Row className={!props.quick ? "botBtn" : ""}>
                  <Col sm={12}>
                    <Button
                      className="saveBtn"
                      type="submit"
                      onClick={() => update()}
                      disabled={false}
                    >
                      {isSubmit ? "Processing..." : "Update"}
                    </Button>
                    <Button
                      className="cancelBtn"
                      type="button"
                      onClick={() => onCancel()}
                      disabled={isSubmit}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>

              {imgPreview && (
                <Dialog
                  open={imgPreview}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  maxWidth="false"
                  minWidth="false"
                >
                  <DialogTitle
                    id="responsive-dialog-title"
                    className="popup-title"
                  >
                    <a target="_blank" href={common.loadEduImage(imgPreviewSrc)} download>
                      View in New Tab
                    </a>{" "}
                    {"  "}
                    <Link to="#" onClick={handleClose}>
                      Close
                    </Link>
                  </DialogTitle>
                  <DialogContent>
                    <div className="popup-form-wrap imgDlgContent">
                      <div className="list-row text-center">
                        <img src={imgPreviewSrc ? common.loadEduImage(imgPreviewSrc) : null} alt="image" />
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export default EduTechLead;
