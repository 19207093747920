import React, { Component } from "react";
import { Col, Row, Tabs, Tab, Button } from "react-bootstrap";
import { Accordion, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { common } from "_helpers";

class DemoFormSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "create",
    };
  }
  render() {
    const { currentTab } = this.state;
    return (
      <div className="lead-wraper form-settings-content-wrap">
        <Row>
          <Col sm={4}>
            <h2 className="crm-type-title pt-3 mb-4">Form Settings</h2>
            <div className="fs-sidebar-wrap">
              <Accordion defaultActiveKey="0">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    New Fields
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="field-box field-single-line">
                            <span>Single Line</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-multi-line">
                            <span>Multi Line</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-email">
                            <span>Email</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-phone">
                            <span>Phone</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-pick-list">
                            <span>Pick List</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-multi-select">
                            <span>Multi Select</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-date">
                            <span>Date</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-date-time">
                            <span>Date/Time</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-number">
                            <span>Number</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-auto-number">
                            <span>Auto Number</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-currency">
                            <span>Currency</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-decimal">
                            <span>Decimal</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-percent">
                            <span>Percent</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-long-integer">
                            <span>Long Integer</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-checkbox">
                            <span>Checkbox</span>
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="field-box field-url">
                            <span>URL</span>
                          </div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <div className="field-box field-sub-form">
                            <span>Sub Form</span>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="field-box field-new-section">
                            <span>New Section</span>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">Unused Items</p>
                      <span className="unused-count">3</span>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <div className="unused-items-wrap">
                        <p>All fields are in use.</p>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <div className="field-left-count">Custom Fields Left: 287</div>
            </div>

            {/* <div className="fs-sidebar-wrap quick-create-sidebar">
              <p className="card-header mb-2">Available Fields</p>
              <div className="input-group search-filter-group mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search"
                  name="filterFieldName"
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <img src={common.loadImg("search-icn.png")} alt="Search" />
                  </div>
                </div>
              </div>

              <div className="quick-fields-box mb-4">
                <p className="card-header mb-3">Lead Information</p>
                <div className="mb-3">
                  <div className="field-box field-single-line">
                    <span>Single Line</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-multi-line">
                    <span>Multi Line</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-email">
                    <span>Email</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-phone">
                    <span>Phone</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-pick-list">
                    <span>Pick List</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-multi-select">
                    <span>Multi Select</span>
                  </div>
                </div>
              </div>

              <div className="quick-fields-box mb-4">
                <p className="card-header mb-3">Address Information</p>
                <div className="mb-3">
                  <div className="field-box field-single-line">
                    <span>Single Line</span>
                  </div>
                </div>

                <div className="mb-3">
                  <div className="field-box field-multi-line">
                    <span>Multi Line</span>
                  </div>
                </div>
              </div>

              <div className="quick-fields-box">
                <p className="card-header mb-3">Description Information</p>
                <div className="mb-3">
                  <div className="field-box field-single-line">
                    <span>Single Line</span>
                  </div>
                </div>
              </div>
            </div> */}
          </Col>
          <Col sm={8}>
            <div className="formTabSection position-r">
              <Tabs defaultActiveKey={currentTab ? currentTab : "general"}>
                <Tab eventKey="create" title="Create">
                  <div className="fs-form-wrap mb-3">
                    <div className="fs-title-wrap d-flex justify-content-between align-items-center mb-3">
                      <h2>Atom</h2>
                      <div className="form-section-settings-wrap input-settings-cntrl">
                        <Link to="#">
                          <img
                            src={common.loadImg("form-settings/setting.png")}
                            alt=""
                          />
                        </Link>
                        <div className="input-settings-menu-wrap">
                          <ul className="input-settings-menu-list">
                            <li>
                              <Link to="#">Single Column</Link>
                            </li>
                            <li>
                              <Link to="#">Two Column</Link>
                            </li>
                            <li>
                              <Link to="#">Edit</Link>
                            </li>
                            <li>
                              <Link to="#">Delete</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Lfirstname</label>
                          <span className="fs-field-type">Single Line</span>
                          <div className="fs-field-cntrl input-settings-cntrl">
                            <span className="field-settings">&nbsp;</span>
                            <div className="input-settings-menu-wrap fs-settings-menu-wrap">
                              <ul className="input-settings-menu-list">
                                <li>
                                  <Link to="#">Mark as required</Link>
                                </li>
                                <li>
                                  <Link to="#">Edit Properties</Link>
                                </li>
                                <li>
                                  <Link to="#">Create Layout Rule</Link>
                                </li>
                                <li>
                                  <Link to="#">Create Validation Rule</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LDateTime</label>
                          <span className="fs-field-type">Date/Time</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LNum</label>
                          <span className="fs-field-type">Number</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LUser</label>
                          <span className="fs-field-type">Multi User</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LURL</label>
                          <span className="fs-field-type">URL</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LMulti</label>
                          <span className="fs-field-type">
                            Multi Select Lookup
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LCheck</label>
                          <span className="fs-field-type">-</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">LDecimal</label>
                          <span className="fs-field-type">Decimal</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">leadtype</label>
                          <span className="fs-field-type">
                            Multi Select Lookup
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">
                            Multi-Select 1
                          </label>
                          <span className="fs-field-type">
                            Option 1, Option 2
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="fs-form-wrap">
                    <div className="fs-title-wrap d-flex justify-content-between align-items-center mb-3">
                      <h2>Lead Information</h2>
                      <div className="form-section-settings-wrap">
                        <img
                          src={common.loadImg("form-settings/setting.png")}
                          alt=""
                        />
                      </div>
                    </div>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Lead Owner</label>
                          <span className="fs-field-type">Lookup</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Company</label>
                          <span className="fs-field-type">Date/Time</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">First Name</label>
                          <span className="fs-field-type">Number</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Last Name</label>
                          <span className="fs-field-type">Multi User</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Phone</label>
                          <span className="fs-field-type">URL</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Email</label>
                          <span className="fs-field-type">
                            Multi Select Lookup
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Mobile</label>
                          <span className="fs-field-type">-</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">Fax</label>
                          <span className="fs-field-type">Decimal</span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">leadtype</label>
                          <span className="fs-field-type">
                            Multi Select Lookup
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                          <label className="fs-field-name">
                            Multi-Select 1
                          </label>
                          <span className="fs-field-type">
                            Option 1, Option 2
                          </span>
                          <div className="fs-field-cntrl">
                            <span>&nbsp;</span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                <Tab eventKey="quickCreate" title="Quick Create">
                  <div className="fs-form-wrap mb-3">
                    <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                      <label className="fs-field-name">Lfirstname</label>
                      <span className="fs-field-type">Single Line</span>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                    </div>

                    <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                      <label className="fs-field-name">LDateTime</label>
                      <span className="fs-field-type">Date/Time</span>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                    </div>

                    <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                      <label className="fs-field-name">LNum</label>
                      <span className="fs-field-type">Number</span>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                    </div>

                    <div className="fs-field-box d-flex justify-content-between align-items-center mb-3">
                      <label className="fs-field-name">LUser</label>
                      <span className="fs-field-type">Multi User</span>
                      <div>
                        <span>&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>

        <div className="fs-btns-wrap">
          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                Update
              </Button>
              <Button className="cancelBtn" type="button">
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DemoFormSettings;
