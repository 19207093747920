import React from "react";
//Import CSS
import "./Loader.css";
import LoaderImg from "img/crm-loader.gif";

class Loader extends React.Component {
	render() {
		return (
			<div className="loader-wrap text-center">
				<img src={LoaderImg} alt="CRM Loader" />
				<p className="loading-text">Loading...</p>
			</div>
		);
	}
}
export default Loader;
