import React from "react";
import { Link } from "react-router-dom";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tableConfig } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

//Import CSS
import "./SalesTeam.css";

import SearchIcon from "img/search-icn.png";
import Pagination from "react-js-pagination";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from '@material-ui/core/Checkbox';
import Paper from "@material-ui/core/Paper";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class SalesTeam extends React.Component {
  constructor() {
    super();
    
    this.state = {
      pageEntries: 0,
      SalesTeamReponse: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      filterSummary: "",
      crmStatusList: [],
      objectId: 0,
      objectType: "",
      tlConfig: {
        headCells: [
          { id: "ResourceType", value: "ResourceType", label: "Resource Type" },
          { id: "EmployeeName", value: "EmployeeName", label: "Resource Name" },
          { id: "CreatedBy", value: "CreatedBy", label: "Created By" },
          { id: "CreatedOn", value: "CreatedOn", label: "Created On" },
          { id: "ModifiedBy", value: "ModifiedBy", label: "Modified By" },
          { id: "ModifiedOn", value: "ModifiedOn", label: "Modified On" },
        ],
        columnsSelected: [],
        order: "desc",
        orderBy: "CreatedOn",
        selected: [],
        uniqueKey: "SalesListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0
      }
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getSalesTeam();
        }
      );
    }
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMultileSelectChange = (selected) => {
    this.setState({
      columnsSelected: selected,
    });
    this.storecolumnsSelected(selected);
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getSalesTeam();
      }
    );
  };

  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.setState(
        {
          objectId: this.props.objectId,
          objectType: this.props.objectType,
        },
        () => {
          this.getSalesTeam();
        }
      );
    }
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, SalesTeamReponse, objectType } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          let newSelecteds;
          switch (objectType) {
            case "lead":
              newSelecteds = SalesTeamReponse.items.map((n) => n.LeadsTeamId);
              break;
            case "deal":
              newSelecteds = SalesTeamReponse.items.map((n) => n.OppTeamId);
              break;
            case "territory":
              newSelecteds = SalesTeamReponse.items.map((n) => n.TerritoryTeamId);
              break;
          }
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig, objectType } = this.state;
    let inputData = [];
    let resourceName;
    switch (objectType) {
      case "lead":
        resourceName = Services.CRM_SALES_TEAM;
        break;
      case "deal":
        resourceName = Services.CRM_DEAL_SALES_TEAM;
      case "territory":
        resourceName = Services.CRM_TERRITORY_TEAM;
        break;
      default: break;
    }

    tlConfig.selected.map((id, index) => {
      inputData.push({
        "id": "part" + (index + 1),
        "path": "/" + resourceName + "/" + id,
        "operation": "delete",
        "payload": {}
      });
    })

    var formAttr = { "parts": inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getSalesTeam();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig
          })
          common.snack("S", "Selected items has been deleted successfully.")
        }
      },
      (error) => {
        common.snack("E", error);
      }
    )
  }

  handlePageEntries = (e, val) => {
    this.setState({
      pageEntries: val,
    });
  };

  getRequestURLByType = () => {
    const {
      objectType,
      objectId,
      currentPage,
      OrderBy,
      pageLimit,
      filterSummary
    } = this.state;

    var filterByType = "";

    switch (objectType) {
      case "lead":
        filterByType = Services.CRM_SALES_TEAM + `?q=LeadId=${objectId} AND `
        break;
      case "deal":
        filterByType = Services.CRM_DEAL_SALES_TEAM + `?q=OpportunityId=${objectId} AND `
        break;
      case "territory":
        filterByType = Services.CRM_TERRITORY_TEAM + `?q=TerritoryId=${objectId} AND `
        break;
      default: break;
    }

    let filter = filterByType + `UPPER(ResourceType) LIKE '*${filterSummary}*' &orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${
      (currentPage - 1) * pageLimit
      }`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return encodeURI(envConfig.BASE_API + filter);

  }

  /**
   * Get all Sales team from DB
   */
  getSalesTeam = () => {
    this.setState({ isLoading: true });

    const {
      currentPage,
      OrderBy,
      pageLimit,
      filterSummary,
      objectId,
      tlConfig
    } = this.state;

    let url = this.getRequestURLByType()
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          tlConfig.enableMultipleDelete = (response && response.items && response.items.length) ? true : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete ? response.items.length : 0;

          this.setState({
            SalesTeamReponse: response,
            isLoading: false,
            tlConfig: tlConfig
          });
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      isLoading,
      SalesTeamReponse,
      currentPage,
      pageLimit,
      filterSummary,
      objectId,
      objectType,
      tlConfig
    } = this.state;


    const isHided = (LeadId) => tlConfig.columnsSelected.indexOf(LeadId) !== -1;
    const isSelected = (LeadId) => tlConfig.selected.indexOf(LeadId) !== -1;

    const handleClick = (event, LeadId) => {
      const selectedIndex = tlConfig.selected.indexOf(LeadId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, LeadId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1),
        );
      }
      if (newSelected.length === SalesTeamReponse.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig
      });
    };

    const getSalesTeamIdByType = (row) => {
      switch (objectType) {
        case "lead":
          return row.LeadsTeamId
          break;
        case "deal":
          return row.OppTeamId
          break;
        case "territory":
          return row.TerritoryTeamId
          break;
        default:
          return 0
      }
    }

    return (
      <div className="lead-wraper">
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-4">
              <div className="form-row">
                <div className="col">
                  <h2 className="crm-type-title">Sales Team</h2>
                </div>
                <div className="col-auto">
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="form-row justify-content-end">
                <div className="col-lg-4">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Filter and Search
                  </label>
                  <div className="input-group search-filter-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="+ Filter and Search"
                      name="filterSummary"
                      value={filterSummary}
                      onChange={this.handleFieldChange}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getSalesTeam}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  <button type="button" className="btn btn-export">
                    Export
                  </button>
                </div>

                <div className="col-auto">
                  <Link
                    to={"/addsalesteam/" + objectType + "/" + objectId}
                    className="btn btn-add"
                  >
                    Add
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <EnhancedTableToolbar tlConfig={tlConfig} deleteSelected={this.deleteSelected} />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false && SalesTeamReponse.items &&
                    SalesTeamReponse.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        SalesTeamReponse.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(getSalesTeamIdByType(row));
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, getSalesTeamIdByType(row))}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                !isHided("ResourceType") ? "hideCell" : ""
                              }
                            >
                              <Link
                                to={
                                  "/addsalesteam/" +
                                  objectType +
                                  "/" +
                                  objectId +
                                  "/" +
                                  getSalesTeamIdByType(row)
                                }
                              >
                                {row.ResourceType ? row.ResourceType : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={!isHided("EmployeeName") ? "hideCell" : ""}
                            >
                              {row.EmployeeName ? row.EmployeeName : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("CreatedBy") ? "hideCell" : ""}
                            >
                              {row.CreatedBy ? row.CreatedBy : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("CreatedOn") ? "hideCell" : ""}
                            >
                              {row.CreatedOn
                                ? common.formatDate(
                                  row.CreatedOn,
                                  "DD/MM/YYYY | HH:mm"
                                )
                                : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("ModifiedBy") ? "hideCell" : ""}
                            >
                              {row.ModifiedBy ? row.ModifiedBy : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("ModifiedOn") ? "hideCell" : ""}
                            >
                              {row.ModifiedOn
                                ? common.formatDate(row.ModifiedOn, "DD/MM/YYYY")
                                : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false &&
                    SalesTeamReponse.items &&
                    SalesTeamReponse.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* )} */}

            {isLoading == false && SalesTeamReponse.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  Showing {(currentPage - 1) * pageLimit + 1} to{" "}
                  {(currentPage - 1) * pageLimit + SalesTeamReponse.count} of{" "}
                  {SalesTeamReponse.totalResults} entries
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={SalesTeamReponse.limit}
                    totalItemsCount={SalesTeamReponse.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
          {/* )} */}
        </div>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default SalesTeam;
