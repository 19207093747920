import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// mui
import Button from "@material-ui/core/Button";
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tools, restServices } from "_helpers";
// files
import serviceList from "crm/files/serviceList.json";
import lookupList from "crm/files/lookupList.json";

function DemoFormLocalUpdate(props) {
    const params = useParams();
    const [submit, setSubmit] = useState(false);
    const [update, setUpdate] = useState([]);
    useEffect(() => {
        // console.log(params)
        if (submit) {
            updateForms()
        }
    }, [submit])

    const updateForms = async () => {
        Object.keys(serviceList).filter(i => serviceList[i].dynamic).map((item, key) => {
            if ((params.module === "all" || params.module === item)) {
                const data = require(`crm/files/dynamic/${item}.json`);
                let filter = `?q=EserviceCode=${serviceList[item].code}`;
                let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter
                restServices.getRequest(
                    url,
                    (response) => {
                        if (response) {
                            let serviceId = response.items[0].EserviceId
                            let fields = JSON.parse(response.items[0].FormFields);
                            if (fields === null) { fields = {}; }
                            fields.form = data;
                            let apiData = {
                                FormFields: JSON.stringify(fields)
                            }
                            let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + "/" + serviceId;
                            restServices.patchRequest(
                                url,
                                apiData,
                                (response) => {
                                    // update entity
                                    if (params.entity === "yes") {
                                        filter = `?q=EntityTypeValue=${Services[serviceList[item].api]}&expand=CholaEntityDtlsView&limit=1000&offset=0`;
                                        url = envConfig.BASE_API + Services.CHOLA_ENTITIES + filter
                                        restServices.getRequest(
                                            url,
                                            (response) => {
                                                if (response) {
                                                    let result = response.items[0].CholaEntityDtlsView;
                                                    let entityData = {};
                                                    result.map(item => {
                                                        entityData[item.DtlKeyValue] = item.EntityDtlId;
                                                    })

                                                    // update entity
                                                    let parts = [];
                                                    data.fields.map((item, key) => {
                                                        let entityItem = (entityData[item.attr.entity] || entityData[item.key]);
                                                        if (item.store && entityItem) {
                                                            let payLoad = {
                                                                "DtlKeyLabel": item.label,
                                                                "DtlMappedApi": null,
                                                                "DtlMappedKey": null,
                                                                "DtlMappedValue": null
                                                            }
                                                            if (item.attr.lookup) {
                                                                let lookup = lookupList[item.attr.lookupEntity || item.attr.lookup]
                                                                payLoad.DtlMappedApi = Services[lookup.name];
                                                                payLoad.DtlMappedKey = lookup.set;
                                                                payLoad.DtlMappedValue = lookup.get;
                                                            }
                                                            parts.push({
                                                                "id": tools.randomString() + '_' + item.key,
                                                                "path": "/" + Services.CHOLA_ENTITY_DETAILS + "/" + entityItem,
                                                                "operation": "update",
                                                                "payload": payLoad
                                                            });
                                                        }
                                                    });
                                                    var formAttr = { "parts": parts };
                                                    url = encodeURI(envConfig.BASE_API);
                                                    restServices.batchRequest(
                                                        url,
                                                        formAttr,
                                                        (response) => {
                                                            if (response != null) {
                                                                setUpdate(oldData => {
                                                                    let newData = reParse(oldData)
                                                                    newData.push(item);
                                                                    return newData;
                                                                })
                                                            }
                                                        },
                                                        (error) => {
                                                            common.snack("E", error);
                                                        }
                                                    )
                                                }
                                            },
                                            (error) => { }
                                        )
                                    } else {
                                        setUpdate(oldData => {
                                            let newData = reParse(oldData)
                                            newData.push(item);
                                            return newData;
                                        })
                                    }
                                },
                                (error) => { }
                            );
                        }
                    },
                    (error) => { }
                )
            }
        })
    }

    const reParse = (data) => {
        return JSON.parse(JSON.stringify(data))
    }

    return (
        <div className="mt-5">
            <h4>Update Local JSON{((params.module === "all" && update.length === 8) || (params.module !== "all" && update.length === 1)) ? " - Fully Completed" : ""}</h4>
            {update.length === 0 ? (
                <div>
                    <Button variant="outlined" color="primary" size="large" disabled={submit} onClick={() => setSubmit(true)}>
                        {submit ? "Updating data" : "Yes Update Local Json"}
                    </Button>
                    <p className="mt-3">
                        <b className="error">Warning:</b> This will update local JSON file to form fields.
                    </p>
                </div>
            ) : update.map((i, k) => (
                <p key={k}>{i + " has been updated"}</p>
            ))}
        </div>
    )
}

export default DemoFormLocalUpdate;