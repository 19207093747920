import React from "react";
import { Tabs, Tab } from "react-bootstrap";
// includes
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common } from "_helpers";
// components
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import { StatusBadge } from "pages/_components/StatusBadge";
// pages
import InvoiceCrud from "./includes/InvoiceCrud";
import Attachments from "pages/_components/Attachments/Attachments";
import History from "pages/_components/History/pages/History";

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      isLoading: true,
      badgeStatus: "",
      invoiceData: [],
      currentTab: "general",
      userPermissions: common.getPermission(this.props, "crm:invoice")
    };
  }

  componentDidMount() {
    const { params } = this.state;
    if (params.invoiceId) {
      this.getInvoiceDetail();
    } else {
      this.setLoading(false);
    }
  }

  getInvoiceDetail = () => {
    const { params } = this.state;
    this.setLoading(true);
    let filter = '?q=InvoiceId=' + params.invoiceId;
    let url = envConfig.BASE_API + Services.CRM_INVOICE + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            badgeStatus: response.items[0].ApprStatus,
            invoiceData: response,
          });
        }
        this.setLoading(false);
      },
      (error) => {
        this.setLoading(false);
      }
    );
  };

  setBadgeStatus(status) {
    this.setState({ badgeStatus: status })
  }

  // common
  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const {
      params,
      isLoading,
      invoiceData,
      badgeStatus,
      currentTab,
      userPermissions
    } = this.state;

    return (
      <div>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
            <div className="lead-wraper">
              {isLoading ? (
                <Loader />
              ) : (
                  <React.Fragment>
                    <div className="secondary-head">
                      {invoiceData != "" ? (
                        <h1>Invoice No: {invoiceData.items[0].InvoiceId}</h1>
                      ) : (
                          <h1>Add Invoice</h1>
                        )}
                    </div>
                    <StatusBadge type="INVOICE" status={badgeStatus} onSelect={(status) => this.setBadgeStatus(status)} />
                    <div className="formTabSection position-r">
                      <Tabs defaultActiveKey={currentTab ? currentTab : "general"}>
                        <Tab eventKey="general" title="General">
                          <InvoiceCrud invoiceId={params.invoiceId} invoiceData={invoiceData} badgeStatus={badgeStatus} permissions={this.props.permissions} />
                        </Tab>
                        {/* <Tab eventKey="receipts" title="Receipts" disabled={!params.invoiceId ? "disabled" : ""}>
                          <p>Receipts</p>
                        </Tab> */}
                        <Tab eventKey="attachments" title="Attachments" disabled={!params.invoiceId ? "disabled" : ""}>
                          <Attachments objectId={params.invoiceId} objectType="invoice" />
                        </Tab>
                        <Tab eventKey="history" title="History" disabled={!params.invoiceId ? "disabled" : ""}>
                          {!isLoading && params.invoiceId > 0 && (
                            <History campaign={invoiceData} />
                          )}
                        </Tab>
                      </Tabs>
                    </div>
                  </React.Fragment>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default Invoice;
