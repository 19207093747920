import React from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import csc from "countries-states-cities";
import Select from "react-select";

//Import CSS
import "./Signup.css";
import LandingHeader from "../../_components/_landingHeader/landingHeader";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices } from "_helpers";

const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// Defined Errors
const defErrs = {
  TenantName: {
    isReq: "Company name is required!",
    minLength: 3,
    isMinLengthReq: "Company name must be at least 3 characters",
  },
  FirstName: {
    isReq: "First name is required!",
  },
  LastName: {
    isReq: "Last name is required!",
  },
  Address: {
    isReq: "Address is required!",
  },
  Country: {
    isReq: "Country is required!",
  },
  State: {
    isReq: "State is required!",
  },
  City: {
    isReq: "City is required!",
  },
  PostalCode: {
    isReq: "Postal code is required!",
  },
  TimezoneCode: {
    isReq: "Timezone is required!",
  },
  MobileNo: {
    isReq: "Mobile is required!",
  },
  EmailId: {
    isReq: "Email is required!",
    isValidEmail: "Please enter valid email address!",
  },
  Password: {
    isReq: "Password is required!",
    minLength: 6,
    isMinLengthReq: "Password must be at least 6 characters",
  },
};
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      fields: {
        TenantCode: "",
        TenantName: "",
        EmailId: "",
        Password: "",
        FirstName: "",
        LastName: "",
        Address: "",
        Country: "",
        State: "",
        City: "",
        PostalCode: "",
        Locale: "en-IN",
        TimezoneCode: "",
        MobileNo: "",
        CurrencyCode: "",
        ParentTenantId: envConfig.PARENT_TENANT_ID,
      },
      errors: {
        TenantName: "Please enter company name",
        FirstName: "Please enter firstname",
        LastName: "Please enter lastname",
        Address: "Please enter address",
        Country: "Please enter country",
        State: "Please enter state",
        City: "Please enter city",
        PostalCode: "Please enter postal code",
        TimezoneCode: "Please enter timezone code",
        MobileNo: "Please enter mobile number",
        EmailId: "Please enter email address",
        Password: "Please enter password",
      },
      errMessage: "",
      isLoading: false,
      masterData: {
        countries: common.getOptionsData(
          csc.getAllCountries(),
          "name",
          "id",
          "Country"
        ),
        states: [{ label: "Select State", value: "" }],
        Cities: [{ label: "Select City", value: "" }],
        TimeZones: [{ label: "Select TimeZone", value: "" }],
      },
    };
  }
  componentDidMount() {
    this.defineInitErrorMsg();
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  validateForm = (name, value) => {
    const { errors, fields } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "EmailId":
          if (!emailPattern.test(value)) {
            errors[name] = defErrs.EmailId.isValidEmail;
          } else {
            errors[name] = "";
          }
          break;
        case "Password":
          if (fields[name].length < defErrs.Password.minLength) {
            errors[name] = defErrs.Password.isMinLengthReq;
          } else {
            errors[name] = "";
          }
          break;
        case "TenantName":
          if (fields[name].length < defErrs.TenantName.minLength) {
            errors[name] = defErrs.TenantName.isMinLengthReq;
          } else {
            errors[name] = "";
            this.setState({
              fields: {
                ...this.state.fields,
                TenantCode: value.toUpperCase(),
              },
            });
          }
          break;
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [name]: value,
        },
      },
      (_) => {
        this.validateForm(name, value);
      }
    );
  };

  selectHandleOnChange = (event, name) => {
    const { label, value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = label;
      if (name == "Country") {
        let country = csc.getCountryById(value);

        let stateList = common.getOptionsData(
          csc.getStatesOfCountry(value),
          "name",
          "id",
          "State"
        );
        let timeZoneList = common.getOptionsData(
          country.timezones,
          "zoneName",
          "zoneName",
          "Timezone"
        );

        fields.CurrencyCode = country.currency;
        fields.State = "";
        fields.City = "";
        fields.TimezoneCode = "";
        fields.Locale = country.iso2;
        this.setState({
          masterData: {
            ...this.state.masterData,
            states: stateList,
            Cities: [{ label: "Select City", value: "" }],
            TimeZones: timeZoneList,
          },
          fields,
        });
      } else if (name == "State") {
        let cityList = common.getOptionsData(
          csc.getCitiesOfState(value),
          "name",
          "id",
          "City"
        );
        this.setState({
          masterData: {
            ...this.state.masterData,
            Cities: cityList,
          },
          fields: {
            ...this.state.fields,
            City: "",
          },
        });
      }
    }
    this.setState({ fields: fields }, () => {
      this.validateForm(name, value);
    });
  };

  handleOnSubmit = (event) => {
    event.preventDefault();

    const { errors } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      this.setState({
        isLoading: true,
      });
      this.createTenant();
    }
  };

  createTenant = () => {
    const { fields } = this.state;

    var createParams = {
      name: "createTenant",
      parameters: [
        { TenantCode: fields.TenantCode.trim() },
        { TenantName: fields.TenantName.trim() },
        { EmailId: fields.EmailId.trim() },
        { Password: fields.Password.trim() },
        { FirstName: fields.FirstName },
        { LastName: fields.LastName },
        { Address: fields.Address },
        { Country: fields.Country },
        { State: fields.State },
        { City: fields.City },
        { PostalCode: fields.PostalCode },
        { Locale: fields.Locale },
        { TimezoneCode: fields.TimezoneCode },
        { MobileNo: fields.MobileNo },
        { CurrencyCode: fields.CurrencyCode },
        { ParentTenantId: envConfig.PARENT_TENANT_ID },
      ],
    };

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.CRM_CREATE_USER);
    restServices.postRequest(
      url,
      createParams,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          let tenantId = 0;
          response.text().then(function (text) {
            let tenantRes = JSON.parse(text);
            tenantId = parseInt(tenantRes.result);
            console.log("text :>> ", tenantRes);
          });
          setTimeout(() => {
            if (tenantId > 0) {
              this.createTenantEntity(tenantId);
            } else {
              common.snack(
                "E",
                "Error occurred in configuration. Please contact administrator."
              );
            }
          }, 1000);
        } else {
          let errorText = "";
          response.text().then(function (text) {
            if (text) {
              if (text.includes("Error while creating the Work Lsocation")) {
                errorText = "Please enter valid postal code";
              } else if (text.includes("unique constraint")) {
                errorText = "Already registered company name or email address";
              } else {
                errorText = "Something went wrong!";
              }
            } else {
              errorText = "Something went wrong!";
            }
          });
          setTimeout(() => {
            console.log("errorText", errorText);
            if (errorText) {
              this.setState({
                errMessage: errorText,
                isLoading: false,
              });
            }
          }, 2000);
        }
      },
      (error) => {
        common.snack("E", "Something went wrong!");
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  createTenantEntity = (tenantId) => {
    var createParams = {
      name: "createTenantEntity",
      parameters: [
        { TenantId: tenantId },
        { ParentTenantId: envConfig.PARENT_TENANT_ID },
      ],
    };

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.CRM_CREATE_USER);
    restServices.postRequest(
      url,
      createParams,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          setTimeout(() => {
            common.snack("S", "Registeration Successfully completed");
            history.push("/login");
          }, 200);
        } else {
          let errorText = "";
          response.text().then(function (text) {
            errorText =
              "Error occurred in setup configuration. Please contact support team";
          });
          setTimeout(() => {
            if (errorText) {
              this.setState({
                errMessage: errorText,
                isLoading: false,
              });
            }
          }, 2000);
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          isLoading: false,
        });
      }
    );
  };
  render() {
    const { fields, errors, formSubmit, errMessage, isLoading, masterData } =
      this.state;
    return (
      <div className="content-wraper">
        <div className="signup-top-wrap">
          <LandingHeader />
          <div className="signup-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-3 signup-form-left">
                  <div className="title-section mb-5 text-center">
                    <h2 className="lp-title">Sign up for your Free Demo</h2>
                    <p className="lp-description">
                      Our CRM free trial uses our Professional plan with the
                      Marketing tool activated, so you
                      <br />
                      can best see how the CRM could work for you.
                    </p>
                  </div>
                </div>
                <div className="signup-form-wrap col-md-9">
                  <div className="sform">
                    <Form onSubmit={this.handleOnSubmit}>
                      <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Col sm="12">
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            name="TenantName"
                            value={fields.TenantName}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.TenantName !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.TenantName}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            name="FirstName"
                            value={fields.FirstName}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.FirstName !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.FirstName}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            name="LastName"
                            value={fields.LastName}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.LastName !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.LastName}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="EmailId"
                            value={fields.EmailId}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.EmailId !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.EmailId}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            name="Password"
                            value={fields.Password}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.Password !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.Password}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Address"
                            name="Address"
                            value={fields.Address}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.Address !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.Address}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Select
                            value={masterData.countries.find(
                              (o) => o.value === fields.Country
                            )}
                            onChange={(e) =>
                              this.selectHandleOnChange(e, "Country")
                            }
                            options={masterData.countries}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                boxShadow: "none",
                                border: "none",
                              }),
                            }}
                          />
                          {/* <Form.Control
														type="text"
														placeholder="Country"
														name="Country"
														value={fields.Country}
														onChange={(e) => this.handleOnChange(e)}
														/> */}
                          {errors.Country !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.Country}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          {/* <Form.Control
														type="text"
														placeholder="State"
														name="State"
														value={fields.State}
														onChange={(e) => this.handleOnChange(e)}
														/> */}
                          <Select
                            value={masterData.states.find(
                              (o) => o.value === fields.State
                            )}
                            onChange={(e) =>
                              this.selectHandleOnChange(e, "State")
                            }
                            options={masterData.states}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                boxShadow: "none",
                                border: "none",
                              }),
                            }}
                          />
                          {errors.State !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.State}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Select
                            value={masterData.Cities.find(
                              (o) => o.value === fields.City
                            )}
                            onChange={(e) =>
                              this.selectHandleOnChange(e, "City")
                            }
                            options={masterData.Cities}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                boxShadow: "none",
                                border: "none",
                              }),
                            }}
                          />
                          {/* <Form.Control
														type="text"
														placeholder="City"
														name="City"
														value={fields.City}
														onChange={(e) => this.handleOnChange(e)}
														/> */}
                          {errors.City !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.City}
                            </Form.Text>
                          )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Postal Code"
                            name="PostalCode"
                            value={fields.PostalCode}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.PostalCode !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.PostalCode}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Select
                            value={masterData.TimeZones.find(
                              (o) => o.value === fields.TimezoneCode
                            )}
                            onChange={(e) =>
                              this.selectHandleOnChange(e, "TimezoneCode")
                            }
                            options={masterData.TimeZones}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                boxShadow: "none",
                                border: "none",
                              }),
                            }}
                          />
                          {/* <Form.Control
															type="text"
															placeholder="Time Zone"
															name="TimezoneCode"
															value={fields.TimezoneCode}
															onChange={(e) => this.handleOnChange(e)}
															/> */}
                          {errors.TimezoneCode !== "" &&
                            formSubmit === true && (
                              <Form.Text className="error">
                                {errors.TimezoneCode}
                              </Form.Text>
                            )}
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Mobile No"
                            name="MobileNo"
                            value={fields.MobileNo}
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.MobileNo !== "" && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.MobileNo}
                            </Form.Text>
                          )}
                        </Col>

                        <Col sm="6">
                          <Form.Control
                            type="text"
                            placeholder="Currency Code"
                            name="CurrencyCode"
                            value={fields.CurrencyCode}
                            // disabled
                            onChange={(e) => this.handleOnChange(e)}
                          />
                          {errors.CurrencyCode !== "" &&
                            formSubmit === true && (
                              <Form.Text className="error">
                                {errors.CurrencyCode}
                              </Form.Text>
                            )}
                        </Col>
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox">
                        <p className="check-txt">
                          By signing up you are agreeing to our{" "}
                          <Link to="#">Subscription Agreement</Link>.
                        </p>
                      </Form.Group>
                      <p className="signup-err">{errMessage}</p>
                      <Form.Group as={Row} controlId="formPlaintextPassword">
                        <Col sm="6"></Col>

                        <Col sm="6">
                          <button
                            type="submit"
                            className="btn btn-signup"
                            disabled={true}
                            //disabled={isLoading}
                          >
                            {!isLoading ? "Get Started" : "Processing..."}
                          </button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <div>
            <Dialog
              aria-labelledby="responsive-dialog-title"
              maxWidth={"sm"}
              open={isLoading}
            >
              <DialogContent>
                <div className="signup-loader-wrap text-center">
                  <img
                    src={common.loadImg("gear_loader.gif")}
                    alt="send mail"
                  />
                  <h4 className="loader-title">
                    Configuring your installation. Please wait...
                  </h4>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}
export default Signup;
