import React from "react";
import { CSVLink } from "react-csv";
// Import config and helpers
import { common, restServices } from "_helpers";

class FileExport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          csv_data: [],          
        }
    }

    getCsvData = () => {
      const { keyArray, urls, filter } = this.props;
      let url = encodeURI(urls + filter);
      restServices.getRequest(
        url,
        (response) => {
          let csv_data = [];
          response.items && response.items.length > 0 && response.items.map((item, key) => {
            let result = {};
            keyArray.map((column, i) => {
              let SplitData = column.split('_');
              if(SplitData.length == 1){
                result[column] = item[column] == null ? '-' : item[column]
              } else {
                result[SplitData[0]] = item[SplitData[0]] == null ? '-' : common.formatDate(item[SplitData[0]], "DD MMMM")
              }
            })
            csv_data.push(result);
          })
          this.setState({
            csv_data
          })
        }
      )
    }

    render(){
        const { headers, filename } = this.props;
        const { csv_data } = this.state;
        return(
            <CSVLink
            data={csv_data}
            headers={headers}
            filename={filename}
            target="_blank"
          >
            <button type="button" className="btn btn-export">
              Export
            </button>
          </CSVLink>
        )
    }
}

export default FileExport;
