import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
// includes
import { crm } from '_utils';
// Detail page
import DesignationDetail from "./DesignationDetail";

// Import Images
import SearchIcon from "img/search-icn.png";
import MaleProIcon from "img/male-employee.png";
import FemaleProIcon from "img/female-employee.png";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

class DesignationList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: [],
      selectedDesignation: {},
      divisionName: "",
      subDivisionName: "",
      departmentName: "",
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      isQuery: false,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let desgId = parseInt(params.get("desgId"));
    let filterName = params.get("filterName");
    let page = params.get("page");
    if (desgId) {
      let paging = this.state.paging;
      paging.page = parseInt(page);
      paging.size = 5;
      paging.total = 5;

      this.setState(
        {
          filterName,
          paging,
          isQuery: true,
        },
        (_) => {
          this.getList(this.state.isQuery);
        }
      );
    } else {
      this.getList(this.state.isQuery);
    }
  }

  /**
   * Methods
   */

  handleClickList = (designation) => {
    this.setState(
      {
        selectedDesignation: designation,
        divisionName: "",
        subDivisionName: "",
        departmentName: "",
      },
      (_) => {
        const { selectedDesignation } = this.state;
        if (selectedDesignation.DivisionId) {
          this.getDivisionData(selectedDesignation.DivisionId);
        }
        if (selectedDesignation.SubDivId) {
          this.getSubDivisionData(selectedDesignation.SubDivId);
        }
        if (selectedDesignation.DepartmentId) {
          this.getDepartmentData(selectedDesignation.DepartmentId);
        }
        // this.getSectionData(selectedDesignation.SectionId);
      }
    );
  };

  /**
   * Get Designation Data by ID
   */
  getDepartmentData = (id) => {
    let departmentID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DEPARTMENTS + `/${departmentID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          departmentName: response ? response.DepartmentName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Get Sub Division Data by ID
   */
  getSubDivisionData = (id) => {
    let subDivisionID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_SUB_DIVISION + `/${subDivisionID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          subDivisionName: response ? response.SubDivisionName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Get Division Data by ID
   */
  getDivisionData = (id) => {
    let divisionID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DIVISION + `/${divisionID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          divisionName: response ? response.DivisionName : "",
        });
      },
      (error) => { }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState(
      {
        filterName: value,
      },
      () => {
        //reset page number to 1
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
      }
    );
  };

  /**
   * Trigger Delete Designation List Popup
   * @param {*} id
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  };

  /**
   * Delete Location by ID
   * @param {*} designationId
   */
  handleClickDeleteList = (designationId) => {
    const { paging } = this.state;
    this.setState({ isLoading: true });
    let url =
      envConfig.BASE_API + Services.HRMS_DESIGNATIONS + "/" + designationId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
        common.snack("S", "Designation deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Service Request
   */
  getList(queryParam = false) {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (!queryParam && paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(DesignationName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(DesignationName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${(paging.page) * paging.size}`;
    }

    let url = envConfig.BASE_API + Services.HRMS_DESIGNATIONS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedDesignation = this.state.selectedDesignation;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          let search = window.location.search;
          let params = new URLSearchParams(search);
          let desgId = params.get("desgId");

          response.items.map((item, key) => {
            tempList.push(item);
            if (desgId != undefined) {
              if (item.DesignationId == parseInt(desgId)) {
                selectedDesignation = item
              }
            }
          });
          if (!queryParam && paging.page === 1) {
            selectedDesignation = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedDesignation);
            }
          );
        } else {
          this.setState(
            {
              isSearchLoad: false,
              searchList: tempList,
            },
            (_) => {
              //this.handleClickList(0);
            }
          );
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      paging,
      totalRecords,
      searchList,
      selectedDesignation,
      divisionName,
      subDivisionName,
      designationName,
      departmentName,
    } = this.state;

    return (
      <div className="crm-list-wrap">
        <Row>
          <Col sm={3}>
            <div className="marketing-list-wrap">
              <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                <h2 className="crm-type-title">Designation List</h2>
                <Link to={"/hrms/designation/add"} className="btn btn-add">
                  Add
                </Link>
              </div>

              <Paper>
                <div className="input-group search-ml-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img
                        src={SearchIcon}
                        alt="Search"
                      //onClick={this.getList}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="filterName"
                    onChange={(e) => this.handleSearchChange(e)}
                    placeholder="Search"
                    name="filterName"
                  />
                </div>
                <ul className="marketing-list">
                  {isSearchLoad == false &&
                    searchList.length > 0 &&
                    searchList.map((row, index) => {
                      return (
                        <li
                          onClick={(e) => this.handleClickList(row)}
                          key={row.DesignationCode}
                          className={`d-flex align-items-center justify-content-between ${row.DesignationCode ==
                            selectedDesignation.DesignationCode
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="mlLeft d-flex align-items-center justify-content-between mr-3">
                            <div className="mr-3">
                              {row.Gender == "Female" ? (
                                <img src={FemaleProIcon} />
                              ) : (
                                <img src={MaleProIcon} />
                              )}
                            </div>
                            <div>
                              <h4 className="ml-name">{row.DesignationName}</h4>
                              <p className="ml-type">{row.DesignationCode}</p>
                            </div>
                          </div>
                          <div className="mlRight">
                            <Link to="#"
                              style={{ marginLeft: 10 }}
                              onClick={() =>
                                this.deleteSelectedItem(row.DesignationId)
                              }
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  {isSearchLoad == true && (
                    <li>
                      <Loader />
                    </li>
                  )}
                  {isSearchLoad == false && searchList.length === 0 && (
                    <li>
                      <NoRecordFound />
                    </li>
                  )}
                </ul>
                {/* Pagination */}
                {searchList.length > 0 && (
                  <div className="load-more-wrap">
                    {paging.page != paging.total && searchList.length > 0 && (
                      <Link
                        to="#"
                        onClick={(e) => this.pagination(e)}
                        className="load-more"
                      >
                        {" "}
                        Load More{" "}
                      </Link>
                    )}
                    <div className="paging-text">
                      1 to {searchList.length} of {totalRecords} entries
                    </div>
                  </div>
                )}
              </Paper>
            </div>
          </Col>
          <Col sm={9}>
            <div className="formTabSection position-r">
              {isSearchLoad == false && searchList.length > 0 ? (
                <DesignationDetail
                  selectedDesignation={
                    selectedDesignation ? selectedDesignation : {}
                  }
                  empAssignment={{
                    divisionName: divisionName,
                    subDivisionName: subDivisionName,
                    // sectionName: sectionName,
                    designationName: designationName,
                    departmentName: departmentName,
                  }}
                  filterName={this.state.filterName}
                  page={paging.page}
                  isQuery={this.state.isQuery}
                />
              ) : (
                isSearchLoad == false && (
                  <div className="formTabSection position-r">
                    <Paper style={styles.boxLayout}>
                      <NoRecordFound />
                    </Paper>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export { DesignationList };
