// Import default packages
import React from "react";
import { Link } from "react-router-dom";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// Import internal components
import CrmMenu from "_components/_crmMenu/CrmMenu";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
import KanbanBoard from "../../../_components/KanbanView/KanbanBoard";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// css
import "../css/Deal.css";
const userInfo = common.authInfo();
class DealList extends React.Component {
  loggedEmpId = userInfo.EmpId;
  constructor(props) {
    super(props);
    this.state = {
      isListView: common.getParam("view") == "kanban" ? false : true,
      crmStatusList: [],
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.deal
      ),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.read != 2) {
      this.getCRMStatusList();
    }
  }

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (e, status) => {
    e.preventDefault();
    this.setState({
      isListView: status,
    });
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    let dealTypeId = common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    let params =
      `?q=TransactionTypeId=${dealTypeId};OrgId=` +
      `${userInfo.DefaultOrgId};TenantId=` +
      `${userInfo.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items ? response.items : [],
          });
        }
      },
      (error) => { }
    );
  };

  onAction = (data) => {
    if (data.type == "edit" || data.type == "view") {
      history.push("/crm/deal/edit/" + data.id + "?view=list");
    } else if (data.type == "add") {
      history.push("/crm/deal/add");
    }
  };

  render() {
    const { userPermissions, crmStatusList } = this.state;

    return (
      <div className="deal-wraper">
        <CrmMenu />
        {userPermissions.read == 2 ? (
          <AccessDenied />
        ) : (
          <React.Fragment>
            <div className="crm-data-wrap">
              <div className="tab-section float-left">
                <ul className="tab-list">
                  <li className={this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, true)}>
                      List
                    </Link>
                  </li>
                  <li className={!this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, false)}>
                      Kanban
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="clearfix"></div>
              <div className={this.state.isListView ? "crm-show" : "crm-hide"}>
                <OsmoMaterialTable
                  userPermissions={userPermissions}
                  module="deal"
                  add="Add New"
                  update="Mass Update"
                  sort="CreationDate:desc"
                  onAction={(data) => this.onAction(data)}
                />
              </div>
              {!this.state.isListView && crmStatusList != "" && (
                <KanbanBoard
                  kanbanType="deal"
                  status={crmStatusList}
                  userPermissions={userPermissions}
                  deptEmployees={this.props.deptEmployees}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default DealList;
