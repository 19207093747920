// Import default packages
import React from "react";
import { Link } from "react-router-dom";
import Papa from "papaparse";
// Import table packages
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
// css
import "../css/Lead.css";

// Import internal components
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tableConfig, restServices } from "_helpers";
// includes
import { crm } from "_utils";
// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
const classes = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  table: {
    minWidth: 650,
  },
}));
const userInfo = common.authInfo();
class LeadImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hdrListRes: {},
      dataListRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      tlConfig: {
        headCells: [
          { id: "BatchNo", value: "BatchNo", label: "BATCH ID" },
          { id: "FileName", value: "FileName", label: "File Name" },
          { id: "UploadStatus", value: "UploadStatus", label: "Upload Status" },
          {
            id: "NoOfProcessed",
            value: "NoOfProcessed",
            label: "No Of Processed",
          },
          { id: "NoOfErrors", value: "NoOfErrors", label: "No Of Errors" },
          { id: "NoOfSuccess", value: "NoOfSuccess", label: "No Of Success" },
          { id: "CreatedOn", value: "CreatedOn", label: "Upload On" },
          { id: "", value: "", label: "Action" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "BatchNo",
        selected: [],
        uniqueKey: "LeadImportcolumns",
      },
      userPermissions: common.getPermission(this.props, "crm:lead"),
      urls: "",
      totalResults: 0,
      fileName: "",
      LeadIntfBatchId: 0,
      uploadProgress: false,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.import) {
      this.getHdrList();
    }
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig } = this.state;

    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }
    }
  };

  /**
   * Handle pagination number changed
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getHdrList();
        }
      );
    }
  };

  /**
   * Get all list
   */
  getHdrList = () => {
    const { currentPage, OrderBy, pageLimit, tlConfig } = this.state;
    this.setState({ isLoading: true });
    let filter = `?totalResults=true&limit=${pageLimit}&offset=${
      (currentPage - 1) * pageLimit
    }`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    let url = encodeURI(envConfig.BASE_API + Services.LEAD_IMPORT_HDR + filter);

    restServices.getRequest(
      url,
      (response) => {
        tlConfig.totalResults = response.totalResults;
        this.setState({
          hdrListRes: response,
          isLoading: false,
          urls: envConfig.BASE_API + Services.LEAD_IMPORT_HDR,
          tlConfig,
        });
      },
      (error) => {
        this.setState({
          hdrListRes: {},
          isLoading: false,
        });
      }
    );
  };

  /**
   * File upload
   */
  handleFileInputChange = (e, LeadIntfBatchId) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      this.setState(
        {
          fileName: file.name,
          LeadIntfBatchId,
          isLoading: true,
        },
        () => {
          Papa.parse(file, {
            complete: (result) => {
              let data = result.data;

              let inputs = this.formLeadData(data, LeadIntfBatchId);

              if (inputs.length > 0) {
                if (LeadIntfBatchId == 0) {
                  this.uploadNewRecords(inputs);
                } else {
                  this.updloadErrorRecords(inputs);
                }
              }
            },
            header: true,
          });
        }
      );
    }
  };

  /**
   * Upload New Records
   */
  uploadNewRecords = (data) => {
    const { fileName, tlConfig } = this.state;
    let fields = {
      FileName: fileName,
      UploadStatus: "PROCESS",
      BatchNo: tlConfig.totalResults + 1,
      TenantId: crm.userInfo().pTenantId,
      OrgId: crm.userInfo().pOrgId,
      CholaCrmLeadsDtlIntfView: data,
    };

    let url = encodeURI(envConfig.BASE_API + Services.LEAD_IMPORT_HDR);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        common.snack("S", "Leads are uploaded successfully");
        this.getHdrList();
      },
      (error) => {
        this.setLoading();
      }
    );
  };

  /**
   * Upload Error Records
   */
  updloadErrorRecords = (data) => {
    const { LeadIntfBatchId } = this.state;

    let fields = {
      UploadStatus: "RE-PROCESS",
      CholaCrmLeadsDtlIntfView: data,
    };

    let url = encodeURI(
      envConfig.BASE_API + Services.LEAD_IMPORT_HDR + "/" + LeadIntfBatchId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        common.snack("S", "Leads are uploaded successfully");
        this.getHdrList();
      },
      (error) => {
        this.setLoading();
      }
    );
  };

  setLoading = () => {
    this.setState({
      isLoading: false,
    });
  };

  /**
   * Organized Lead Input Records
   */
  formLeadData = (memberArray, batchId) => {
    let inputData = [];
    let isNewRecord = true;

    //form lead json data for batch insert request
    memberArray.map((member) => {
      if (member.LeadName != null && member.LeadName != "") {
        var formAttr = {};

        if (member.LeadIntfId && member.LeadIntfId > 0) {
          isNewRecord = false;
          formAttr["LeadIntfId"] =
            member.LeadIntfId !== "" ? member.LeadIntfId : null;
        }

        formAttr["ReferredBy"] =
          member.ReferredBy !== "" ? member.ReferredBy : null;
        formAttr["LeadName"] = member.LeadName !== "" ? member.LeadName : null;
        formAttr["LeadType"] = member.LeadType !== "" ? member.LeadType : null;
        formAttr["LeadTypeCode"] =
          member.LeadTypeCode !== "" ? member.LeadTypeCode : null;
        formAttr["Description"] =
          member.Description !== "" ? member.Description : null;
        formAttr["Salutation"] =
          member.Salutation !== "" ? member.Salutation : null;
        formAttr["FirstName"] =
          member.FirstName !== "" ? member.FirstName : null;
        formAttr["LastName"] = member.LastName !== "" ? member.LastName : null;
        formAttr["Email"] = member.Email !== "" ? member.Email : null;
        formAttr["Phone"] = member.Phone !== "" ? member.Phone : null;
        formAttr["PhoneExtn"] =
          member.PhoneExtn !== "" ? member.PhoneExtn : null;
        formAttr["Fax"] = member.Fax !== "" ? member.Fax : null;
        formAttr["Mobile"] = member.Mobile !== "" ? member.Mobile : null;
        formAttr["Website"] = member.Website !== "" ? member.Website : null;
        formAttr["LeadSource"] =
          member.LeadSource !== "" ? member.LeadSource : null;
        formAttr["LeadStatus"] =
          member.LeadStatus !== "" ? member.LeadStatus : null;
        formAttr["LeadStatusCode"] =
          member.LeadStatusCode !== "" ? member.LeadStatusCode : "NEW";
        formAttr["LeadRating"] =
          member.LeadRating !== "" ? member.LeadRating : null;
        formAttr["LeadActive"] = "Y";
        formAttr["Industry"] = member.Industry !== "" ? member.Industry : null;
        formAttr["IndustryCode"] =
          member.IndustryCode !== "" ? member.IndustryCode : null;
        formAttr["TenantId"] = crm.userInfo().pTenantId;
        formAttr["OrgId"] = crm.userInfo().pOrgId;
        formAttr["Address1"] = member.Address1 !== "" ? member.Address1 : null;
        formAttr["Address2"] = member.Address2 !== "" ? member.Address2 : null;
        formAttr["City"] = member.City !== "" ? member.City : null;
        formAttr["State"] = member.State !== "" ? member.State : null;
        formAttr["Country"] = member.Country !== "" ? member.Country : null;
        formAttr["PostalCode"] =
          member.PostalCode !== "" ? member.PostalCode : null;
        formAttr["LinkedIn"] = member.LinkedIn !== "" ? member.LinkedIn : null;
        formAttr["FacebookId"] =
          member.FacebookId !== "" ? member.FacebookId : null;
        formAttr["SkypeId"] = member.SkypeId !== "" ? member.SkypeId : null;
        formAttr["Twitter"] = member.Twitter !== "" ? member.Twitter : null;
        formAttr["NoOfEmployees"] =
          member.NoOfEmployees !== "" ? member.NoOfEmployees : null;
        formAttr["AnnualRevenue"] =
          member.AnnualRevenue !== "" ? member.AnnualRevenue : null;
        formAttr["LeadRating"] =
          member.LeadRating !== "" ? member.LeadRating : null;
        formAttr["EmailOptout"] =
          member.EmailOptout !== "" ? member.EmailOptout : null;
        formAttr["SecondaryEmail"] =
          member.SecondaryEmail !== "" ? member.SecondaryEmail : null;
        formAttr["SalespersonName"] =
          member.SalespersonName !== "" ? member.SalespersonName : null;
          console.log('userInfo :>> ', userInfo);
        if (member.SalespersonName == null) {
          formAttr.SalespersonId = userInfo.EmpId;
        }
        if (userInfo.territory.length > 0) {
          formAttr.TerritoryId = userInfo.territory[0];
        }
        inputData.push(formAttr);
      }
    });

    if (inputData.length == 0) {
      common.snack("E", "Please sumbit valid records");
      this.setLoading();
    }
    if (!isNewRecord && batchId == 0) {
      inputData = [];
      common.snack(
        "E",
        "Upload new records here. Try error records in appropriate actions"
      );
      this.setLoading();
    }
    return inputData;
  };

  /**
   * Get Error Records
   */
  getErrorRecords = (e, LeadIntfBatchId, FileName, batchNo) => {
    e.preventDefault();
    this.setState({
      LeadIntfBatchId,
    });
    let filter = `?q=LeadIntfBatchId=${LeadIntfBatchId};RecordStatus=E&limit=100000&offset=0`;
    let url = encodeURI(envConfig.BASE_API + Services.LEAD_IMPORT_DTL + filter);
    FileName = "Error_Leads_batch_" + batchNo + ".csv";

    restServices.getRequest(
      url,
      (response) => {
        let items = response.items.map(function (v) {
          delete v.links;
          delete v.CreatedBy;
          delete v.CreatedOn;
          delete v.ModifiedBy;
          delete v.ModifiedOn;
          return v;
        });
        this.downloadCSV(FileName, items);
        this.setState({
          dataListRes: response,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          dataListRes: {},
          isLoading: false,
        });
      }
    );
  };

  /**
   * Download csv file
   */
  downloadCSV(fileName, data) {
    var csv = Papa.unparse(data);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, fileName);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  }

  render() {
    const {
      isLoading,
      tlConfig,
      hdrListRes,
      currentPage,
      pageLimit,
      userPermissions,
    } = this.state;

    const isHided = (ColumnId) =>
      tlConfig.columnsSelected.indexOf(ColumnId) !== -1;

    return !userPermissions.import ? (
      <AccessDenied />
    ) : (
      <div className="lead-wraper">
        {/* end of Secondary Nav */}
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-3">
              <div className="form-row">
                <div className="col">
                  <h2 className="crm-type-title">Lead Import</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="form-row justify-content-end">
                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>
                <div className="col-auto">
                  {userPermissions.import != 2 && (
                    <div className={classes.root}>
                      <input
                        accept=".csv"
                        className="hideColumn"
                        id="upload_new"
                        onChange={(e) => this.handleFileInputChange(e, 0)}
                        multiple={false}
                        type="file"
                      />
                      <label htmlFor="upload_new">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload New
                        </Button>
                      </label>
                    </div>
                  )}
                </div>
                <div className="col-auto">
                  <Link
                    className="btn btn-add"
                    to="../../csv/Leadsample.zip"
                    target="_blank"
                    download
                  >
                    Download Sample
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false &&
                    hdrListRes.items &&
                    hdrListRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        hdrListRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.LeadIntfBatchId}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={!isHided("BatchNo") ? "hideCell" : ""}
                            >
                              {row.BatchNo ? row.BatchNo : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("FileName") ? "hideCell" : ""}
                            >
                              {row.FileName ? row.FileName : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("UploadStatus") ? "hideCell" : ""
                              }
                            >
                              {row.UploadStatus ? row.UploadStatus : "-"}
                            </TableCell>

                            <TableCell
                              className={
                                !isHided("NoOfProcessed") ? "hideCell" : ""
                              }
                            >
                              {row.NoOfProcessed ? row.NoOfProcessed : "-"}
                            </TableCell>

                            <TableCell
                              className={
                                !isHided("NoOfErrors") ? "hideCell" : ""
                              }
                            >
                              {row.NoOfErrors ? row.NoOfErrors : ""}
                            </TableCell>

                            <TableCell
                              className={
                                !isHided("NoOfSuccess") ? "hideCell" : ""
                              }
                            >
                              {row.NoOfSuccess ? row.NoOfSuccess : ""}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("CreatedOn") ? "hideCell" : ""
                              }
                            >
                              {row.CreatedOn
                                ? common.formatDate(row.CreatedOn, "DD/MM/YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("CreatedBy") ? "hideCell" : ""
                              }
                            >
                              {row.CreatedBy ? row.CreatedBy : ""}
                            </TableCell>
                            <TableCell
                              className={!isHided("") ? "hideCell" : ""}
                            >
                              {row.NoOfProcessed !== row.NoOfSuccess ? (
                                <span>
                                  <Link
                                    to="#"
                                    className="btn btn-add"
                                    onClick={(e) =>
                                      this.getErrorRecords(
                                        e,
                                        row.LeadIntfBatchId,
                                        row.FileName,
                                        row.BatchNo
                                      )
                                    }
                                  >
                                    View Error
                                  </Link>
                                  <br />
                                  <div className={classes.root}>
                                    <input
                                      accept=".csv"
                                      className="hideColumn"
                                      id={"upload_err" + index}
                                      name={"upload_err" + index}
                                      onChange={(e) =>
                                        this.handleFileInputChange(
                                          e,
                                          row.LeadIntfBatchId
                                        )
                                      }
                                      multiple={false}
                                      type="file"
                                    />
                                    <label
                                      htmlFor={"upload_err" + index}
                                      className="mtbtn"
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                      >
                                        Reupload
                                      </Button>
                                    </label>
                                  </div>
                                </span>
                              ) : (
                                <span> - </span>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {hdrListRes.items &&
                    hdrListRes.items.length == 0 &&
                    isLoading == false && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading == false && hdrListRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  {tableConfig.showEntries(currentPage, pageLimit, hdrListRes)}
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={hdrListRes.limit}
                    totalItemsCount={hdrListRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default LeadImport;
