import React from "react";

import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

import Checkbox from "material-ui/Checkbox";
//Import CSS
import "../css/product.css";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
  ItemNumber: {
    isReq: "Product number is required!",
  },
  ItemName: {
    isReq: "Product name is required!",
  },
  Description: {
    isReq: "Description is required!",
  },
  PrimaryUom: {
    isReq: "Primary Uom is required!",
  },
  ItemType: {
    isReq: "Item Type is required!",
  },
};

class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        ItemNumber: "",
        ItemName: "",
        Description: "",
        ItemType: "",
        PrimaryUom: "",
        SecondaryUom: "",
        ItemGroup: "",
        Active: "Y",
      },
      updateId: 0,
      masterData: {
        ItemType: [],
        UOM: [],
        SecondaryUOM: [],
      },
      errors: {},
      currentPage: 1,
      pageLimit: 10,
      isLoading: false,
      userPermissions: common.getPermission(this.props, "vendor:products"),
    };
  }

  componentDidMount() {
    this.defineInitErrorMsg();
    this.getLookUpData("CHOLA_ITEM_GROUP", "ItemType");
    this.getLookUpData("UOM", "UOM");
    if (this.props.match.params.productId != undefined) {
      let updateId = this.props.match.params.productId
        ? this.props.match.params.productId
        : 0;
      this.setState(
        {
          updateId: updateId,
        },
        () => {
          const { updateId } = this.state;
          if (updateId > 0) {
            this.getDetailById(updateId);
          }
        }
      );
    }
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.ITEM_MATSER + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }
          this.setState({
            fields: fields,
            errors: errors,
          });
        } else {
        }
      },
      (error) => { }
    );
  };

  /**
   * Redirect to territory list page
   */
  onCancel = () => {
    history.push("/setup/vendor/product");
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, value);
      }
    );
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0&orderBy=Name:asc`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] = common.getOptionsData(
            response.items,
            "Name",
            "Code"
          );
          this.setState({
            masterData,
          });
        }
      },
      (error) => { }
    );
  };

  save = (event) => {
    event.preventDefault();

    const { errors, updateId } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (updateId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, updateId } = this.state;

    var formAttributes = Object.assign({}, fields);
    //delete formAttributes.selectedItemName;

    let url = encodeURI(envConfig.BASE_API + Services.ITEM_MATSER + "/" + updateId);
    restServices.patchRequest(
      url,
      formAttributes,
      (response) => {
        if (response) {
          history.push("/setup/vendor/product");
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields } = this.state;

    var formAttributes = Object.assign({}, fields);
    //delete formAttributes.selectedItemName;

    let url = encodeURI(envConfig.BASE_API + Services.ITEM_MATSER);
    restServices.postRequest(
      url,
      formAttributes,
      (response) => {
        if (response) {
          history.push("/setup/vendor/product");
        }
      },
      (error) => { }
    );
  };

  render() {
    const { fields, masterData, updateId, errors, formSubmit } = this.state;
    const isChecked = (value) => value == "Y";
    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Product/Service Information</h2>
            </div>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Product Number*</Form.Label>
                  <Form.Control
                    type="text"
                    name="ItemNumber"
                    value={fields.ItemNumber}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.ItemNumber !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.ItemNumber}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Product Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="ItemName"
                    value={fields.ItemName}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.ItemName !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.ItemName}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    type="text"
                    name="Description"
                    value={fields.Description}
                    onChange={(e) => this.handleOnChange(e)}
                    maxLength={3000}
                  />
                  {errors.Description !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.Description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Product Type</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.ItemType.find(
                        (o) => o.value == fields.ItemType
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "ItemType")}
                      options={masterData.ItemType}
                    />
                  </div>
                  {errors.ItemType !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.ItemType}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Primary UOM</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.UOM.find(
                        (o) => o.value == fields.PrimaryUom
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "PrimaryUom")}
                      options={masterData.UOM}
                    />
                  </div>
                  {errors.PrimaryUom !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.PrimaryUom}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Secondary UOM</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.UOM.find(
                        (o) => o.value == fields.SecondaryUom
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "SecondaryUom")}
                      options={masterData.UOM}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>&nbsp; </Form.Label>
                  <Checkbox
                    name="Active"
                    className="filter-checkbox"
                    checked={isChecked(fields.Active)}
                    label="Active"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {updateId > 0 ? "Update" : "Save"}
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export { AddProduct };
