import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import RaisedButton from "material-ui/RaisedButton";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
// includes
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common } from "_helpers";
// pages
import SalesOrderCrud from "./includes/SalesOrderCrud";
import Meeting from "pages/_components/Meeting/Meeting";
import Attachments from "pages/_components/Attachments/Attachments";
import CallLogsList from "pages/_components/CallLogs/CallLogsList/CallLogsList";
import ContactLinks from "pages/_components/Contacts/ContactLinks";
import History from "pages/_components/History/pages/History";
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import { StatusBadge } from "pages/_components/StatusBadge";

class SalesOrder extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      isLoading: true,
      badgeStatus: "",
      salesOrderData: [],
      currentTab: "general",
      userPermissions: common.getPermission(this.props, "crm:salesorder"),
      open: false,
    };
  }

  componentDidMount() {
    const { params } = this.state;
    if (params.salesOrderId) {
      this.getSalesOrderDetail();
    } else {
      this.setLoading(false);
    }
  }

  getSalesOrderDetail = () => {
    const { params } = this.state;
    this.setLoading(true);
    let filter = '?q=CustOrderId=' + params.salesOrderId;
    let url = envConfig.BASE_API + Services.CRM_SALES_ORDER + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            badgeStatus: response.items[0].ApprStatus,
            salesOrderData: response,
          });
        }
        this.setLoading(false);
      },
      (error) => {
        this.setLoading(false);
      }
    );
  };

  setBadgeStatus(status) {
    this.setState({ badgeStatus: status })
  }

  // common
  setLoading(status) {
    this.setState({ isLoading: status })
  }

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  convertToInvoice = (e) => {
    e.preventDefault();
    const { params, salesOrderData } = this.state;
    if(salesOrderData.items[0].InvoiceConverted == "Y"){
      common.snack("E", "Already converted this Order");
    }else {
      var createParams = {
        name: "convertOrderToInvoice",
        parameters: [
          { P_CUST_ORDER_ID: params.salesOrderId },
          { P_DESCRIPTION: "invoice" },
          { P_INVOICE_TYPE: "" },
          { P_TRANS_SOURCE : "" },
          { P_TERRITORY_ID : "" },
          { P_CAMPAIGN_ID : "" },
        ],
      };

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
      );
      restServices.postRequest(
        url,
        createParams,
        (response) => {
          if (response.status >= 200 && response.status <= 300) {
            common.snack("S", "Successfully Converted as Order");
            this.setState({
              open: false,
            });
          }
        },
        (error) => {
          common.snack("E", error);
        }
      );
    }
  }

  render() {
    const {
      params,
      isLoading,
      salesOrderData,
      badgeStatus,
      currentTab,
      userPermissions
    } = this.state;

    return (
      <div>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
            <div className="lead-wraper">
              {isLoading ? (
                <Loader />
              ) : (
                  <React.Fragment>
                    <div className="secondary-head">
                      {salesOrderData != "" ? (
                        <h1>Sales Order No: {salesOrderData.items[0].CustOrderId}</h1>
                      ) : (
                          <h1>Add Sales Order</h1>
                        )}
                    </div>
                    <StatusBadge type="SALESORDER" status={badgeStatus} onSelect={(status) => this.setBadgeStatus(status)} />
                    <div className="formTabSection position-r">
                      <Tabs defaultActiveKey={currentTab ? currentTab : "general"}>
                        <Tab eventKey="general" title="General">
                          <SalesOrderCrud salesOrderId={params.salesOrderId} salesOrderData={salesOrderData} badgeStatus={badgeStatus} permissions={this.props.permissions} />
                        </Tab>
                        <Tab eventKey="contact" title="Contacts" disabled={!params.salesOrderId ? "disabled" : ""}>
                          <ContactLinks
                            objectId={params.salesOrderId}
                            objectType={common.getObjectNameByPage("order")}
                          />
                        </Tab>
                        <Tab eventKey="attachments" title="Attachments" disabled={!params.salesOrderId ? "disabled" : ""}>
                          <Attachments objectId={params.salesOrderId} objectType="order" />
                        </Tab>
                        <Tab eventKey="meetings" title="Events" disabled={!params.salesOrderId ? "disabled" : ""}>
                          <Meeting objectId={params.salesOrderId} objectType="order" />
                        </Tab>
                        <Tab eventKey="call" title="Call Logs" disabled={!params.salesOrderId ? "disabled" : ""}>
                          <CallLogsList objectId={params.salesOrderId} objectType="order" />
                        </Tab>
                        <Tab eventKey="despatch" title="Despatch Plans" disabled={!params.salesOrderId ? "disabled" : ""}>
                          <p>Despatch Plans</p>
                        </Tab>
                        <Tab eventKey="history" title="History" disabled={!params.salesOrderId ? "disabled" : ""}>
                          {!isLoading && params.salesOrderId && (<History campaign={salesOrderData} />)}
                        </Tab>
                      </Tabs>
                      {salesOrderData != "" &&
                        <div className="action-btn-wrap position-a action-btn-position">
                          <RaisedButton
                            disabled={params.salesOrderId === 0}
                            onClick={this.handleClick}
                            label="Action"
                          />
                          <Popover
                            open={this.state.open}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                            targetOrigin={{ horizontal: "left", vertical: "top" }}
                            onRequestClose={this.handleRequestClose}
                          >
                            <Menu autoFocusItem={true}>
                              <MenuItem
                                primaryText="Convert to Invoice"
                                onClick={(e) => this.convertToInvoice(e)}
                              />
                            </Menu>
                          </Popover>
                        </div>
                      }
                    </div>
                  </React.Fragment>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default SalesOrder;
