import React from "react";
import DynamicForm from "crm/DynamicForm";
//API SERVICES
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, history } from "_helpers";
import Loader from "_components/_loader/Loader";
class AddEnquiryMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      isLoading: true,
      listId: 0,
      detailId: 0,
      enquiryData: {}
    };
  }

  componentDidMount() {
    const { params } = this.state;
    if (params.memberId !== undefined) {
      this.setState(
        {
          listId: params.enquiryId,
          detailId: params.memberId,
        },
        () => {
          this.getEnquiryById(params.enquiryId);
        }
      );
    } else {
      this.setState({
        listId: params.enquiryId,
      },
        () => {
          this.getEnquiryById(params.enquiryId);
        });
    }
  }

  /**
   * Get Enquiry Details by ID
   */
  getEnquiryById = (id) => {
    let url = envConfig.BASE_API + Services.CRM_LISTS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            enquiryData: response,
            isLoading: false,
          });
        }
      },
      (error) => { }
    );
  };

  saveCallback = (res) => {
    console.log("seve res :>> ", res);
    history.push("/crm/enquiry/edit/" + res.ListId + "?tab=details");
  };

  cancelCallBack = () => {
    const { listId } = this.state;
    console.log(listId)
    history.push(
      "/crm/enquiry/edit/" + listId + "?tab=details"
    );
  };

  render() {
    const { listId, detailId, isLoading, enquiryData } = this.state;
    return (
      <div className="lead-wraper">
        {isLoading && <Loader />}
        {!isLoading && (
          <DynamicForm
            module="enquiryMember"
            data={{
              value1: (detailId > 0 ? detailId : ""),
              value2: listId
            }}
            disabled={{ LeadConverted: true }}
            onSave={this.saveCallback}
            onCancel={this.cancelCallBack}
          />
        )}
      </div>
    );
  }
}

export default AddEnquiryMember;
