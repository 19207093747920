import React from "react";
import { Link } from "react-router-dom";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tableConfig } from "_helpers";
import Select from 'react-select';
// includes
import { crm } from '_utils';
//Import CSS
import "../css/StatusTransition.css";
import ActiveIcon from "img/active.svg";
import deactiveIcon from "img/deactive.svg";

import ConfigureMenu from "_components/_configureMenu/ConfigureMenu";
import Pagination from "react-js-pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from '@material-ui/core/Checkbox';

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class StatusTransitionList extends React.Component {
  constructor(props) {
    super(props);
    let transCode = localStorage.getItem("TransCode") || envConfig.CAMPAIGN_TRANS_TYPE_ID;
    this.state = {
      pageEntries: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      transitionTypeId: common.getTransactionTypeId(transCode),
      tlConfig: {
        headCells: [
          { id: "OrderNo", value: "OrderNo", label: "ORDER NO" },
          {
            id: "TransitionName",
            value: "TransitionName",
            label: "TRANSITION NAME",
          },
          {
            id: "FromStatusCode",
            value: "FromStatusCode",
            label: "FROM STATUS CODE",
          },
          { id: "ToStatusCode", value: "ToStatusCode", label: "TO STATUS CODE" },
          { id: "TransTag", value: "TransTag", label: "TAG" },
          { id: "Active", value: "Active", label: "ACTIVE" },
          { id: "StartDate", value: "StartDate", label: "START DATE" },
          { id: "EndDate", value: "EndDate", label: "END DATE" },
          {
            id: "VersionNumber",
            value: "VersionNumber",
            label: "VERSION NUMBER",
          },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "OrderNo",
        selected: [],
        uniqueKey: "StatusTranscolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0
      },
      formList: [
        { label: "Campaign", value: envConfig.CAMPAIGN_TRANS_TYPE_ID },
        { label: "Enquiry", value: envConfig.LIST_TRANS_TYPE_ID },
        { label: "Lead", value: envConfig.LEAD_TRANS_TYPE_ID },
        { label: "Deal", value: envConfig.DEAL_TRANS_TYPE_ID }
      ],
      userPermissions: common.getPermission(this.props, "configure:form trasactions"),
      transCode: transCode,
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getAllStatusTrans();
        }
      );
    }
  }

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getAllStatusTrans();
      }
    );
  };

  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getAllStatusTrans();
    }
  }

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    let typeId = common.getTransactionTypeId(value);

    this.setState({ transitionTypeId: typeId, transCode: value }, () => {
      localStorage.setItem("TransCode", value);
      this.getAllStatusTrans();
    });
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.StatusTranId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig } = this.state;
    let inputData = [];
    tlConfig.selected.map((id, index) => {
      inputData.push({
        "id": "part" + (index + 1),
        "path": "/" + Services.STATUS_TRANSITION + "/" + id,
        "operation": "delete",
        "payload": {}
      });
    })

    var formAttr = { "parts": inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getAllStatusTrans();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig
          })
          common.snack("S", "Selected items has been deleted successfully.")
        }
      },
      (error) => {
        common.snack("E", error);
      }
    )
  }

  handlePageEntries = (e, val) => {
    this.setState({
      pageEntries: val,
    });
  };

  /**
   * Get all leads from DB
   */
  getAllStatusTrans = () => {
    this.setState({ isLoading: true });

    const { tlConfig, currentPage, pageLimit, transitionTypeId, userPermissions } = this.state;

    let filter = `?finder=FindStatusTransByType;pTenantId=${crm.userInfo().pTenantId
      },pOrgId=${crm.userInfo().pOrgId},pTransactionTypeId=${transitionTypeId
      }&totalResults=true&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }`;

    let url = envConfig.BASE_API + Services.STATUS_TRANSITION + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let deletePermission = userPermissions.delete;
          tlConfig.enableMultipleDelete = deletePermission && (response && response.items && response.items.length) ? true : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete ? response.items.length : 0;

          this.setState({
            listRes: response,
            isLoading: false,
            tlConfig: tlConfig
          });
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      isLoading,
      listRes,
      currentPage,
      pageLimit,
      tlConfig,
      transitionTypeId,
      formList,
      userPermissions,
      transCode
    } = this.state;

    const isHided = (StatusTranId) => tlConfig.columnsSelected.indexOf(StatusTranId) !== -1;
    const isSelected = (StatusTranId) => tlConfig.selected.indexOf(StatusTranId) !== -1;

    const handleClick = (event, StatusTranId) => {
      const selectedIndex = tlConfig.selected.indexOf(StatusTranId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, StatusTranId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1),
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig
      });
    };

    return (
      <div className="lead-wraper">
        <ConfigureMenu />
        {/* Secondary Nav end */}
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-sm-5">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">Status Transition</h2>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="form-row justify-content-end">
                    <div className="col-sm-5">
                      <div className="select-control filter-selectbox">
                        <Select
                          value={formList.find(
                            (o) => o.value === transCode
                          )}
                          onChange={(e) =>
                            this.selectHandleOnChange(e, "transCode")
                          }
                          options={formList}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <EnhancedTableColumns
                        tlConfig={tlConfig}
                        updateTLConfig={this.updateTLConfig}
                      />
                    </div>
                    <div className="col-auto">
                      <div className="form-row justify-content-end">
                        <div className="col-auto">
                          <Link
                            to={
                              "/setup/configure/form-transition/" + transitionTypeId
                            }
                            className="btn btn-add"
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            <div className="crm-data-wrap">
              <div className="list-view-wrap">
                <EnhancedTableToolbar
                  tlConfig={tlConfig}
                  deleteSelected={this.deleteSelected}
                />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      tlConfig={tlConfig}
                      updateTLConfig={this.updateTLConfig}
                    />
                    <TableBody>
                      {isLoading == false &&
                        listRes.items &&
                        listRes.items.length > 0 &&
                        tableConfig
                          .stableSort(
                            listRes.items,
                            tableConfig.getComparator(
                              tlConfig.order,
                              tlConfig.orderBy
                            )
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(
                              row.StatusTranId
                            );
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, row.StatusTranId)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.StatusTranId}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    !isHided("OrderNo") ? "hideCell" : ""
                                  }
                                >
                                  {row.OrderNo ? row.OrderNo : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    !isHided("TransitionName")
                                      ? "hideCell"
                                      : ""
                                  }
                                >
                                  <Link
                                    to={
                                      "/setup/configure/form-transition/" +
                                      transitionTypeId +
                                      "/" +
                                      row.StatusTranId
                                    }
                                  >
                                    {row.TransitionName
                                      ? row.TransitionName
                                      : "-"}
                                  </Link>
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("FromStatusCode")
                                      ? "hideCell"
                                      : ""
                                  }
                                >
                                  {row.FromStatusCode
                                    ? row.FromStatusCode
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("ToStatusCode") ? "hideCell" : ""
                                  }
                                >
                                  {row.ToStatusCode ? row.ToStatusCode : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("TransTag") ? "hideCell" : ""
                                  }
                                >
                                  {row.TransTag ? row.TransTag : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("Active") ? "hideCell" : ""
                                  }
                                >
                                  {row.Active == "Y" ? (
                                    <img src={ActiveIcon} alt="Call" />
                                  ) : (
                                    <img src={deactiveIcon} alt="Call" />
                                  )}
                                  <span
                                    style={
                                      row.Active == "Y"
                                        ? { color: "#333333", paddingLeft: 3 }
                                        : { color: "#cccccc", paddingLeft: 3 }
                                    }
                                  >
                                    {row.Active == "Y"
                                      ? "Active"
                                      : "Deactive"}{" "}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("StartDate") ? "hideCell" : ""
                                  }
                                >
                                  {row.StartDate
                                    ? common.formatDate(
                                      row.StartDate,
                                      "DD/MM/YYYY"
                                    )
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("EndDate") ? "hideCell" : ""
                                  }
                                >
                                  {row.EndDate
                                    ? common.formatDate(
                                      row.EndDate,
                                      "DD/MM/YYYY"
                                    )
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("VersionNumber")
                                      ? "hideCell"
                                      : ""
                                  }
                                >
                                  {row.VersionNumber
                                    ? row.VersionNumber
                                    : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {isLoading == true && (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Loader />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading == false &&
                        listRes.items &&
                        listRes.items.length == 0 && (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <NoRecordFound />{" "}
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {isLoading == false && listRes.totalResults > 0 && (
                  <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                    <div className="mr-auto">
                      Showing {(currentPage - 1) * pageLimit + 1} to{" "}
                      {(currentPage - 1) * pageLimit + listRes.count} of{" "}
                      {listRes.totalResults} entries
                    </div>
                    <div className="pagination-wrap">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={currentPage}
                        itemsCountPerPage={listRes.limit}
                        totalItemsCount={listRes.totalResults}
                        pageRangeDisplayed={7}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export { StatusTransitionList };
