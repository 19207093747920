import { commonConstants } from "_constants";
import { commonService } from "_services";

export const commonActions = {
  getCRMStatus,
  getCRMCampaignStatus,
  getCRMEnquiryStatus,
  getCRMDealStatus,
  getCRMQuoteStatus,
  getCRMOrderStatus,
  getCRMInvoiceStatus,
  updateRolesPermissions,
};

/**
 * Get CRM status
 */
function getCRMStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(success(commonConstants.CRM_STATUS_GET_ALL_SUCCESS, result));
        } else {
          dispatch(
            error(commonConstants.CRM_STATUS_GET_ALL_ERROR, result.error)
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Campaign status
 */
function getCRMCampaignStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_SUCCESS, result)
          );
        } else {
          dispatch(
            error(
              commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_ERROR,
              result.error
            )
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Enquiry status
 */
function getCRMEnquiryStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_SUCCESS, result)
          );
        } else {
          dispatch(
            error(
              commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_ERROR,
              result.error
            )
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Deal status
 */
function getCRMDealStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_DEAL_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_DEAL_SUCCESS, result)
          );
        } else {
          dispatch(
            error(commonConstants.CRM_STATUS_GET_ALL_DEAL_ERROR, result.error)
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_DEAL_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Quote status
 */
function getCRMQuoteStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_QUOTE_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_QUOTE_SUCCESS, result)
          );
        } else {
          dispatch(
            error(commonConstants.CRM_STATUS_GET_ALL_QUOTE_ERROR, result.error)
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_QUOTE_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Invoice status
 */
function getCRMInvoiceStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_INVOICE_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_INVOICE_SUCCESS, result)
          );
        } else {
          dispatch(
            error(
              commonConstants.CRM_STATUS_GET_ALL_INVOICE_ERROR,
              result.error
            )
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_INVOICE_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Order status
 */
function getCRMOrderStatus(statusType) {
  return (dispatch) => {
    dispatch(request(commonConstants.CRM_STATUS_GET_ALL_ORDER_REQUEST));
    commonService.getCRMStatus(statusType).then(
      (result) => {
        if (!result.error) {
          dispatch(
            success(commonConstants.CRM_STATUS_GET_ALL_ORDER_SUCCESS, result)
          );
        } else {
          dispatch(
            error(commonConstants.CRM_STATUS_GET_ALL_ORDER_ERROR, result.error)
          );
        }
      },
      (err) => {
        dispatch(error(commonConstants.CRM_STATUS_GET_ALL_ORDER_ERROR, err));
      }
    );
  };
}

/**
 * Get CRM Order status
 */
function updateRolesPermissions(result) {
  return (dispatch) => {
    dispatch(success(commonConstants.CRM_ROLES_PERMISSIONS_SUCCESS, result));
  };
}

function request(type) {
  return { type: type };
}

function success(type, message) {
  return { type, message };
}

function error(type, message) {
  return { type, message };
}

function clear(type) {
  return { type };
}
