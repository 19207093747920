import React from "react";
import { Form, Col, Button, Row, Modal } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { crm } from '_utils';
import { FormFieldSelect, FormFieldAuto } from "pages/_components/FormFields"
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tools, restServices, history } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// css
import "react-datepicker/dist/react-datepicker.css";
// const
const selectDefault = { label: "", value: "" };
const authInfo = common.authInfo();

class SalesOrderCrud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props,
      isLoading: true,
      isAdding: false,
      isAddingItem: false,
      formSubmit: false,
      formSubmitItem: false,
      isModal: false,
      fullWidth: true,
      maxWidth: "md",
      editKey: "",
      fields: {
        CustQuoId: selectDefault,
        CustQuoNo: "",
        WoNo: "",
        WoDate: null,
        OrderType: selectDefault,
        OrderSource: selectDefault,
        Remarks: "",
        QuoDate: null,
        CustomerId: selectDefault,
        CustPoNo: "",
        CustPoDate: null,
        OrderGivenBy: selectDefault,
        SalesPersonId: selectDefault,
        Currency: selectDefault,
        ValidDate: null,
        BillTo: selectDefault,
        ShipToId: selectDefault,
        VerifiedBy: selectDefault,
        ApprovedBy: selectDefault,
        PreparedBy: {
          label: authInfo.EmployeeName,
          value: authInfo.EmpId
        },
        GstEnabled: "N",
        ModeOfDespatch: selectDefault,
        Freight: "",
        Discount: "",
        PaymentStatus: selectDefault,
        Packing: "",
        OrderStatus: "",
        isUpdate: false
      },
      fieldsItem: {
        CustOrderDtlId: "",
        CustOrderId: "",
        ProductId: selectDefault,
        Uom: selectDefault,
        Qty: "",
        Rate: "",
        DiscPer: "",
        Tax: "",
        ReqDeliveryDate: "",
        PromisedDelDate: "",
        SchShipDate: "",
        OrderDtlStatus: selectDefault,
        isNew: true,
        isUpdate: false
      },
      errors: {
        WoNo: {
          error: "",
          isReq: "Sales order number is required",
        },
        CustomerId: {
          error: "",
          isReq: "Please select the customer",
          isObject: true,
        },
        OrderType: {
          error: "",
          isReq: "Please select order type",
          isObject: true,
        },
        Remarks: {
          error: "",
          isReq: "Please enter remarks",
        }
      },
      errorsItem: {
        ProductId: {
          error: "",
          isReq: "Please select the item",
          isObject: true,
        },
        Qty: {
          error: "",
          isReq: "Quantity is required",
        },
        Rate: {
          error: "",
          isReq: "Rate is required",
        },
        Tax: {
          error: "",
          isReq: "Tax is required",
        },
        ReqDeliveryDate: {
          error: "",
          isReq: "Date is required",
        },
        PromisedDelDate: {
          error: "",
          isReq: "Date is required",
        },
        SchShipDate: {
          error: "",
          isReq: "Date is required",
        },
      },
      sData: {
        itemList: [],
        itemDeleted: [],
        statusList: [],
        locationInfo: {
          BillTo: {
            isLoading: "",
            Address1: "",
            Address2: "",
            Landmark: ""
          },
          ShipToId: {
            isLoading: "",
            Address1: "",
            Address2: "",
            Landmark: ""
          }
        },
        amountInfo: {
          rate: 0,
          discount: 0,
          subTotal: 0,
          tax: 0,
          grandTotal: 0,
        }
      },
      userInfo: crm.userInfo(),
      userPermissions: common.getPermission(this.props, "crm:salesorder"),
    };
    this.count = 0;
  }

  componentDidMount() {
    const { params } = this.state;
    if (params.salesOrderId) {
      if (params.salesOrderData == "") {
        common.snack("E", "Unable to load sales order details!");
        history.push("/crm/sales-order");
      } else {
        this.getSalesOrderDetails();
      }
    } else {
      this.setLoading(false);
    }
  }

  // api
  getSalesOrderDetails() {
    const { params, sData } = this.state;
    let filter = '?q=CustOrderId=' + params.salesOrderId;
    let url = envConfig.BASE_API + Services.CRM_SALES_ORDER_DETAILS + filter;

    restServices.getRequest(
      url,
      (response) => {
        // quote details
        let salesOrderInfo = params.salesOrderData.items[0];
        let fields = {
          CustQuoId: { label: salesOrderInfo.CustQuoNo, value: tools.setEmpty(salesOrderInfo.CustQuoId) },
          CustQuoNo: tools.setEmpty(salesOrderInfo.CustQuoNo),
          WoNo: tools.setEmpty(salesOrderInfo.WoNo),
          WoDate: tools.setEmpty(salesOrderInfo.WoDate),
          OrderType: { label: salesOrderInfo.OrderTypeName, value: tools.setEmpty(salesOrderInfo.OrderType) },
          OrderSource: { label: salesOrderInfo.OrderSourceName, value: tools.setEmpty(salesOrderInfo.OrderSource) },
          Remarks: tools.setEmpty(salesOrderInfo.Remarks),
          QuoDate: tools.setEmpty(salesOrderInfo.QuoDate),
          CustomerId: { label: salesOrderInfo.CustomerName, value: tools.setEmpty(salesOrderInfo.CustomerId) },
          CustPoNo: tools.setEmpty(salesOrderInfo.CustPoNo),
          CustPoDate: tools.setEmpty(salesOrderInfo.CustPoDate),
          OrderGivenBy: { label: salesOrderInfo.OrderGivenBy, value: tools.setEmpty(Number(salesOrderInfo.OrderGivenBy)) },
          SalesPersonId: { label: salesOrderInfo.SalesPersonName, value: tools.setEmpty(salesOrderInfo.SalesPersonId) },
          Currency: { label: salesOrderInfo.CurrencyName, value: tools.setEmpty(salesOrderInfo.Currency) },
          ValidDate: tools.setEmpty(salesOrderInfo.ValidDate),
          BillTo: { label: salesOrderInfo.BillToSiteName, value: tools.setEmpty(salesOrderInfo.BillTo) },
          ShipToId: { label: salesOrderInfo.ShipToSiteName, value: tools.setEmpty(salesOrderInfo.ShipToId) },
          PaymentTerms: { label: salesOrderInfo.PaymentTermsName, value: tools.setEmpty(salesOrderInfo.PaymentTerms) },
          PreparedBy: { label: salesOrderInfo.PreparedByName, value: tools.setEmpty(salesOrderInfo.PreparedBy) },
          VerifiedBy: { label: salesOrderInfo.VerifiedByName, value: tools.setEmpty(salesOrderInfo.VerifiedBy) },
          ApprovedBy: { label: salesOrderInfo.ApprovedByName, value: tools.setEmpty(salesOrderInfo.ApprovedBy) },
          GstEnabled: tools.setEmpty(salesOrderInfo.GstEnabled),
          ModeOfDespatch: { label: salesOrderInfo.ModeOfDespatchName, value: tools.setEmpty(salesOrderInfo.ModeOfDespatch) },
          Freight: tools.setEmpty(salesOrderInfo.Freight),
          Discount: tools.setEmpty(salesOrderInfo.Discount),
          PaymentStatus: { label: salesOrderInfo.PaymentStatusName, value: tools.setEmpty(salesOrderInfo.PaymentStatus) },
          Packing: tools.setEmpty(salesOrderInfo.Packing),
          OrderStatus: tools.setEmpty(salesOrderInfo.OrderStatus),
          isUpdate: false
        }
        if (response) {
          // quote items
          response.items.map((item, key) => {
            sData.itemList.push({
              CustOrderDtlId: item.CustOrderDtlId,
              CustOrderId: item.CustOrderId,
              ProductId: { label: item.ItemName, value: item.ProductId },
              Uom: { label: item.UomName, value: item.Uom },
              Qty: item.Qty,
              Rate: item.Rate,
              DiscPer: item.DiscPer,
              Tax: item.TaxPer,
              ReqDeliveryDate: item.ReqDeliveryDate,
              PromisedDelDate: item.PromisedDelDate,
              SchShipDate: item.SchShipDate,
              OrderDtlStatus: { label: item.DtlOrderStatusName, value: item.OrderDtlStatus },
              isNew: false,
              isUpdate: false
            })
          })
        }
        this.setState({
          fields,
          sData,
        }, () => {
          this.setLoading(false);
          this.calculateItem();
          this.getLocation("BillTo");
          this.getLocation("ShipToId");
        })
      },
      (error) => {
        common.snack("E", "Unable to load sales order items!");
        history.push("/crm/sales-order");
      }
    )
  }


  getProduct(name) {
    if (name == "CustQuoId") {
      const { fields, sData } = this.state;
      sData.itemList.map((item, key) => {
        if (!item.isNew) {
          sData.itemDeleted.push(item.CustOrderDtlId);
        }
      })
      if (!fields.CustQuoId.value) {
        this.setState(prev => ({
          sData: {
            ...prev.sData,
            itemList: []
          }
        }), () => {
          this.calculateItem();
        })
      } else {
        let filter = '?q=CustQuotId=' + fields.CustQuoId.value;
        let url = envConfig.BASE_API + Services.CRM_QUOTE_DETAILS + filter;

        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              // quote items
              response.items.map((item, key) => {
                sData.itemList.push({
                  CustOrderDtlId: item.CustOrderDtlId,
                  CustQuotId: item.CustQuotId,
                  ProductId: { label: item.ProductName, value: item.ProductId },
                  Uom: { label: item.UomName, value: item.Uom },
                  Qty: item.Quantity,
                  Rate: item.Rate,
                  DiscPer: item.DiscPer,
                  Tax: item.Nattr1,
                  ReqDeliveryDate: item.ReqDeliveryDate,
                  PromisedDelDate: item.PromisedDelDate,
                  SchShipDate: item.SchShipDate,
                  OrderDtlStatus: { label: item.LineApprovalStatusName, value: item.LineApprovalStatus },
                  isNew: true,
                  isUpdate: false
                })
              })
            }
            this.setState({
              sData,
            }, () => {
              this.calculateItem();
            })
          },
          (error) => { }
        )
      }
    }
  }

  getLocation(name) {
    if (name == "BillTo" || name == "ShipToId") {
      const { fields, sData } = this.state;
      sData.locationInfo[name] = tools.ObjectEmpty(sData.locationInfo[name]);
      sData.locationInfo[name].isLoading = "yes";
      this.setState({
        sData
      })
      if (fields[name]) {
        let filter = '/' + fields[name].value;
        let url = envConfig.BASE_API + Services.CRM_CUSTOMER_SITE + filter;
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              sData.locationInfo[name] = {
                isLoading: "",
                Address1: response.Address1,
                Address2: response.Address2,
                Landmark: response.Landmark
              };
            }
            sData.locationInfo[name].isLoading = "";
            this.setState({
              sData
            })
          },
          (error) => {
            sData.locationInfo[name].isLoading = "";
            this.setState({
              sData
            })
          }
        )
      }
    }
  }

  // submit handler
  onSubmitItem() {
    const { editKey, sData, fieldsItem } = this.state;
    this.setSubmitItem(true);
    let isValid = this.validateItemForm();
    if (isValid) {
      if (editKey !== "") {
        sData.itemList[editKey] = tools.ObjectAssign(fieldsItem);
      } else {
        sData.itemList.push(tools.ObjectAssign(fieldsItem));
      }
      this.setState({
        sData,
        fieldsItem: tools.ObjectAssign(this.getItem()),
        isModal: false
      }, () => {
        this.calculateItem();
        this.setSubmitItem(false);
        common.snack("S", `Item has been ${editKey !== "" ? 'updated' : 'added'}`);
      });
    }
  }

  onSubmit() {
    this.setSubmit(true);
    const { params, fields, sData } = this.state;
    let isValid = this.validateForm();
    if (isValid) {
      this.setSubmit(false);
      this.setAdding(true);
      let paramsData = this.getParam();
      if (!params.salesOrderId) {
        var formAttributes = Object.assign({}, paramsData);
        let url = encodeURI(envConfig.BASE_API + Services.CRM_SALES_ORDER);
        restServices.postRequest(
          url,
          formAttributes,
          (response) => {
            if (response) {
              // add items
              if (sData.itemList.length > 0) {
                let itemParam = [];
                sData.itemList.map((item, key) => {
                  let formData = {
                    id: "key_" + crm.uniqueKey(),
                    path: "/CholaCustomerOrderDtl",
                    operation: "create",
                    payload: this.getItemParam(response.CustOrderId, item),
                  };
                  itemParam.push(formData);
                });
                let formAttr = { parts: itemParam };
                url = encodeURI(envConfig.BASE_API);
                restServices.batchRequest(url, formAttr, (response) => {
                  common.snack("S", "Sales Order Successfully Added");
                  history.push("/crm/sales-order");
                }, (error) => {
                  this.setAdding(false);
                  common.snack("E", "Failed to add quote items");
                });
              } else {
                common.snack("S", "Sales Order Successfully Added");
                history.push("/crm/sales-order");
              }
            }
          }, (error) => {
            this.setAdding(false);
            common.snack("E", "Failed to add quote");
          }
        );
      } else {
        let itemParam = [];
        let formData = {};
        // update quote
        if (fields.isUpdate || this.props.badgeStatus !== fields.OrderStatus) {
          formData = {
            id: "key_" + crm.uniqueKey(),
            path: "/CholaCustomerOrder/" + params.salesOrderId,
            operation: "update",
            payload: paramsData,
          };
          itemParam.push(formData);
        }
        // update quote items
        sData.itemList.map((item, key) => {
          if (item.isNew) {
            formData = {
              id: "key_" + crm.uniqueKey(),
              path: "/CholaCustomerOrderDtl",
              operation: "create",
              payload: this.getItemParam(params.salesOrderId, item),
            };
            itemParam.push(formData);
          } else if (item.isUpdate) {
            formData = {
              id: "key_" + crm.uniqueKey(),
              path: "/CholaCustomerOrderDtl/" + item.CustOrderDtlId,
              operation: "update",
              payload: this.getItemParam(params.salesOrderId, item),
            };
            itemParam.push(formData);
          }
        });

        // delete quote item
        sData.itemDeleted.map((item, key) => {
          formData = {
            id: "key_" + crm.uniqueKey(),
            path: "/CholaCustomerOrderDtl/" + item,
            operation: "delete",
            payload: {},
          }
          itemParam.push(formData);
        });

        if (itemParam.length === 0) {
          common.snack("W", "You have not modifed any items!");
          this.setAdding(false);
        } else {
          let formAttr = { parts: itemParam };
          let url = encodeURI(envConfig.BASE_API);
          restServices.batchRequest(url, formAttr, (response) => {
            common.snack("S", "Sales Order Successfully Updated");
            history.push("/crm/sales-order");
          }, (error) => {
            this.setAdding(false);
            common.snack("E", "Failed to update quote");
          });
        }
      }
    } else {
      common.snack("E", "Please fill all the required fields.");
    }
  }

  // support
  addItem() {
    this.setState({
      editKey: "",
      isModal: true
    })
  }

  editItem(key) {
    const { sData } = this.state;
    this.setState({
      isModal: true,
      editKey: key,
      fieldsItem: tools.ObjectAssign(sData.itemList[key]),
    })
  }

  removeItem(key) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to delete this item?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                const { sData } = this.state;
                let item = sData.itemList[key];
                if (!item.isNew) {
                  sData.itemDeleted.push(item.CustOrderDtlId);
                }
                this.setState({
                  fieldsItem: sData.itemList.splice(key, 1),
                  sData
                }, () => {
                  onClose();
                  this.calculateItem();
                })
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  cancelItem() {
    this.setState({
      isModal: false,
      formSubmitItem: false,
      fieldsItem: tools.ObjectAssign(this.getItem())
    })
  }

  calculateItem() {
    const { sData } = this.state;
    let rate = 0;
    let discount = 0;
    let subTotal = 0;
    let tax = 0;
    let grandTotal = 0;
    sData.itemList.map((item, key) => {
      let discountRate = ((Number(item.Rate) / 100) * Number(item.DiscPer))
      let subTotalRate = Number(item.Rate) - Number(discountRate);
      rate += Number(item.Rate);
      discount += Number(discountRate);
      subTotal += subTotalRate;
      tax += ((subTotalRate / 100) * Number(item.Tax));
    })
    grandTotal = (subTotal + tax);
    sData.amountInfo = {
      rate: rate.toFixed(2),
      discount: discount.toFixed(2),
      subTotal: subTotal.toFixed(2),
      tax: tax.toFixed(2),
      grandTotal: grandTotal.toFixed(2)
    };
    this.setState({
      sData
    })
  }

  getItem() {
    return {
      CustOrderDtlId: "",
      CustOrderId: "",
      ProductId: selectDefault,
      Uom: selectDefault,
      Qty: "",
      Rate: "",
      DiscPer: "",
      Tax: "",
      ReqDeliveryDate: "",
      PromisedDelDate: "",
      SchShipDate: "",
      OrderDtlStatus: selectDefault,
      isNew: true,
      isUpdate: false
    }
  }

  getItemParam(CustOrderId, fieldsItem) {
    const { userInfo } = this.state;
    let params = {
      CustOrderId: CustOrderId,
      ProductId: fieldsItem.ProductId.value,
      Uom: fieldsItem.Uom.value,
      Qty: parseFloat(fieldsItem.Qty),
      Rate: parseFloat(fieldsItem.Rate),
      DiscPer: parseFloat(fieldsItem.DiscPer),
      TaxPer: parseFloat(fieldsItem.Tax),
      ReqDeliveryDate: fieldsItem.ReqDeliveryDate,
      PromisedDelDate: fieldsItem.PromisedDelDate,
      SchShipDate: fieldsItem.SchShipDate,
      OrderDtlStatus: fieldsItem.OrderDtlStatus.value,
      TenantId: userInfo.pTenantId,
      OrgId: userInfo.pOrgId,
    };
    return params;
  }

  getParam() {
    const { fields, userInfo } = this.state;
    let params = {
      CustQuoId: fields.CustQuoId.value,
      CustQuoNo: fields.CustQuoId.label,
      WoNo: fields.WoNo,
      WoDate: fields.WoDate || null,
      OrderType: fields.OrderType.value,
      OrderSource: fields.OrderSource.value,
      Remarks: fields.Remarks,
      QuoDate: fields.QuoDate || null,
      CustomerId: fields.CustomerId.value,
      CustPoNo: fields.CustPoNo,
      CustPoDate: fields.CustPoDate || null,
      OrderGivenBy: fields.OrderGivenBy.value,
      SalesPersonId: fields.SalesPersonId.value,
      Currency: fields.Currency.value,
      ValidDate: fields.ValidDate || null,
      BillTo: fields.BillTo.value,
      ShipToId: fields.ShipToId.value,
      VerifiedBy: fields.VerifiedBy.value,
      ApprovedBy: fields.ApprovedBy.value,
      PreparedBy: fields.PreparedBy.value,
      GstEnabled: "N",
      ModeOfDespatch: fields.ModeOfDespatch.value,
      Freight: fields.Freight,
      Discount: fields.Discount,
      PaymentStatus: fields.PaymentStatus.value,
      Packing: fields.Packing,
      OrderStatus: this.props.badgeStatus,
      TenantId: userInfo.pTenantId,
      OrgId: userInfo.pOrgId,
    }
    return params;
  }

  // handler
  // field
  onChange = (e, type) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = tools.validateType(value, type);
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onSelect = (value, name) => {
    const { fields } = this.state;
    fields[name] = value || selectDefault;
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
      this.getLocation(name);
      this.getProduct(name);
    })
  }

  onDateChange = (date, name) => {
    const { fields } = this.state;
    fields[name] = date;
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  // fields item
  onItemChange = (e, type) => {
    const { fieldsItem } = this.state;
    const { name, value } = e.target;
    fieldsItem[name] = tools.validateType(value, type);
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  onItemSelect = (value, name) => {
    const { fieldsItem } = this.state;
    fieldsItem[name] = value || selectDefault;
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  onItemDateChange = (date, name) => {
    const { fieldsItem } = this.state;
    fieldsItem[name] = date;
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  // validate
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (errors[key].isObject && fields[key].value == "") {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].minLength && fields[key].length < errors[key].minLength) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (errors[key].isValidEmail && !crm.ValidateEmail(fields[key])) {
        errors[key].error = errors[key].isValidEmail;
        isValid = false;
      } else if (errors[key].isValidMultiEmail) {
        let emails = fields[key].split(",");
        for (let i = 0; i < emails.length; i++) {
          if (!crm.ValidateEmail(emails[i])) {
            errors[key].error = errors[key].isValidMultiEmail;
            isValid = false;
          }
        }
      } else if (errors[key].matchPassword) {
        if (fields[key] !== fields["password"]) {
          errors[key].error = errors[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateItemForm() {
    const { fieldsItem, errorsItem } = this.state;
    let isValid = true;
    for (var key of Object.keys(errorsItem)) {
      errorsItem[key].error = "";
      if (errorsItem[key].isObject && fieldsItem[key].value == "") {
        errorsItem[key].error = errorsItem[key].isReq;
        isValid = false;
      } else if (fieldsItem[key] == "" || fieldsItem[key] == null) {
        errorsItem[key].error = errorsItem[key].isReq;
        isValid = false;
      } else if (errorsItem[key].minLength && fieldsItem[key].length < errorsItem[key].minLength) {
        errorsItem[key].error = errorsItem[key].isMinLengthReq;
        isValid = false;
      } else if (errorsItem[key].isValidEmail && !crm.ValidateEmail(fieldsItem[key])) {
        errorsItem[key].error = errorsItem[key].isValidEmail;
        isValid = false;
      } else if (errorsItem[key].isValidMultiEmail) {
        let emails = fieldsItem[key].split(",");
        for (let i = 0; i < emails.length; i++) {
          if (!crm.ValidateEmail(emails[i])) {
            errorsItem[key].error = errorsItem[key].isValidMultiEmail;
            isValid = false;
          }
        }
      } else if (errorsItem[key].matchPassword) {
        if (fieldsItem[key] !== fieldsItem["password"]) {
          errorsItem[key].error = errorsItem[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errorsItem,
    });
    return isValid;
  }

  // others
  setDate(date) {
    if (date != "" && date != null) {
      return moment(date).format("MMM DD, YYYY")
    } else {
      return "-";
    }
  }

  // common
  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  setAdding(status) {
    this.setState({ isAdding: status })
  }

  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setSubmitItem(status) {
    this.setState({ formSubmitItem: status })
  }

  setModal(status) {
    this.setState({ isModal: status });
  }

  render() {
    const {
      params,
      isLoading,
      isAdding,
      isAddingItem,
      isModal,
      fields,
      fieldsItem,
      errors,
      errorsItem,
      sData,
      formSubmit,
      formSubmitItem,
      userPermissions,
    } = this.state;
    return (
      !userPermissions.access ? (
        <AccessDenied />
      ) : (
        isLoading ? (
          <Loader />
        ) : (
          <div>
            {/* Sales Order */}
            <Row className="whiteBgRow">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Sales Order Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Quote No</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="CustQuoId"
                        type="FindQuote"
                        value={fields.CustQuoId}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Sales Order Number*</Form.Label>
                      <Form.Control
                        type="text"
                        name="WoNo"
                        value={fields.WoNo}
                        onChange={(e) => this.onChange(e)}
                      />
                      {errors.WoNo.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.WoNo.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Order Date</Form.Label>
                      <DatePicker
                        name="WoDate"
                        value={fields.WoDate ? moment(fields.WoDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "WoDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Order Type</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="OrderType"
                        type="ORDER_TYPE"
                        lookup={true}
                        value={fields.OrderType}
                        onSelect={this.onSelect}
                      />
                      {errors.OrderType.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.OrderType.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Order Source</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="OrderSource"
                        type="ORDER_SOURCE"
                        lookup={true}
                        value={fields.OrderSource}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Quotation Date</Form.Label>
                      <DatePicker
                        name="QuoDate"
                        value={fields.QuoDate ? moment(fields.QuoDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "QuoDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col >
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        name="Remarks"
                        value={fields.Remarks}
                        onChange={(e) => this.onChange(e)}
                      />
                      {errors.Remarks.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.Remarks.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Customer PO No</Form.Label>
                      <Form.Control
                        type="text"
                        name="CustPoNo"
                        value={fields.CustPoNo}
                        onChange={(e) => this.onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Customer*</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="CustomerId"
                        type="FindCustomers"
                        value={fields.CustomerId}
                        onSelect={this.onSelect}
                      />
                      {errors.CustomerId.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.CustomerId.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Customer PO Date</Form.Label>
                      <DatePicker
                        name="CustPoDate"
                        value={fields.CustPoDate ? moment(fields.CustPoDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "CustPoDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Order Given By</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="OrderGivenBy"
                        type="FindEmployee"
                        value={fields.OrderGivenBy}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Sales Person</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="SalesPersonId"
                        type="FindEmployee"
                        value={fields.SalesPersonId}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Currency</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="Currency"
                        type="CURRENCIES"
                        lookup={true}
                        value={fields.Currency}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Valid Date</Form.Label>
                      <DatePicker
                        name="ValidDate"
                        value={fields.ValidDate ? moment(fields.ValidDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "ValidDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Product */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Products On Sales Order</h2>
                  <Button className="addBtn" type="button" onClick={() => this.addItem()}>Add Item</Button>
                </div>
                {/* Delivery Section */}
                <Row style={{ marginTop: 30 }}>
                  <Col sm={12}>
                    <Row className="mb-3">
                      <Col sm={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>HSN Code</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Discount %</TableCell>
                                <TableCell>Tax %</TableCell>
                                <TableCell>Requested Delivery Date</TableCell>
                                <TableCell>Promised Delivery Date</TableCell>
                                <TableCell>Scheduled Delivery Date</TableCell>
                                <TableCell>Approved Status</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sData.itemList.length === 0 ? (
                                <TableRow>
                                  <TableCell align="center" colSpan={11}>Sorry no items added</TableCell>
                                </TableRow>
                              ) : sData.itemList.map((item, key) => (
                                <TableRow key={key}>
                                  <TableCell>{item.ProductId.label}</TableCell>
                                  <TableCell>{item.Uom.label}</TableCell>
                                  <TableCell>{item.Qty}</TableCell>
                                  <TableCell>{item.Rate}</TableCell>
                                  <TableCell>{item.DiscPer}</TableCell>
                                  <TableCell>{item.Tax}</TableCell>
                                  <TableCell>{this.setDate(item.ReqDeliveryDate)}</TableCell>
                                  <TableCell>{this.setDate(item.PromisedDelDate)}</TableCell>
                                  <TableCell>{this.setDate(item.SchShipDate)}</TableCell>
                                  <TableCell>{item.OrderDtlStatus.label}</TableCell>
                                  <TableCell>
                                    <Tooltip title="Edit">
                                      <img src={common.loadImg("edit.svg")} onClick={() => this.editItem(key)} />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <img src={common.loadImg("delete.svg")} className="ml-2" onClick={() => this.removeItem(key)} />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Shipping Method</Form.Label>
                              <FormFieldSelect
                                isRender={true}
                                name="ModeOfDespatch"
                                type="SHIPPING_MODE"
                                lookup={true}
                                value={fields.ModeOfDespatch}
                                onSelect={this.onSelect}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Freight</Form.Label>
                              <Form.Control
                                type="text"
                                name="Freight"
                                value={fields.Freight}
                                onChange={(e) => this.onChange(e, "n")}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Payment Terms</Form.Label>
                              <FormFieldSelect
                                isRender={true}
                                name="PaymentStatus"
                                type="CUS_PAYMENT_TERMS"
                                lookup={true}
                                value={fields.PaymentStatus}
                                onSelect={this.onSelect}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Packing %</Form.Label>
                              <Form.Control
                                type="text"
                                name="Packing"
                                value={fields.Packing}
                                onChange={(e) => this.onChange(e, "f")}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6} className="right-text">
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Rate:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.rate}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Total Discount Amount:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.discount}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Sub Total:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.subTotal}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Tax:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.tax}</span>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>
                                <strong>Grand Total:</strong>
                              </span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>
                                <strong>
                                  {sData.amountInfo.grandTotal}
                                </strong>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Address */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <Row className="mb-3">
                  {/* Bill to site section */}
                  <Col sm={6}>
                    <Col sm={12} className="boxBorder">
                      <Row className="bgBillSite">
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Label>BILL TO SITE</Form.Label>
                            <FormFieldAuto
                              isRender={true}
                              name="BillTo"
                              type="FindSites"
                              value={fields.BillTo}
                              onSelect={this.onSelect}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {sData.locationInfo.BillTo.isLoading === "yes" ? (
                        <Row><Col sm={12}><Loader /></Col></Row>
                      ) : (
                        <React.Fragment>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillTo.Address1}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillTo.Address2}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Land Mark</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillTo.Landmark}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Col>
                  {/* Ship to site section */}
                  <Col sm={6}>
                    <Col sm={12} className="boxBorder">
                      <Row className="bgBillSite">
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Label>SHIP TO SITE</Form.Label>
                            <FormFieldAuto
                              isRender={true}
                              name="ShipToId"
                              type="FindSites"
                              value={fields.ShipToId}
                              onSelect={this.onSelect}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {sData.locationInfo.ShipToId.isLoading === "yes" ? (
                        <Row><Col sm={12}><Loader /></Col></Row>
                      ) : (
                        <React.Fragment>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToId.Address1}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToId.Address2}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Land Mark</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToId.Landmark}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Prepared By</Form.Label>
                      <Form.Control
                        type="text"
                        name="PreparedBy"
                        defaultValue={fields.PreparedBy.label}
                        readOnly={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Approved By</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="ApprovedBy"
                        type="FindEmployee"
                        value={fields.ApprovedBy}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Verified By</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="VerifiedBy"
                        type="FindEmployee"
                        value={fields.VerifiedBy}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Modal */}
            <Modal size="lg" show={isModal} backdrop="static">
              <Modal.Header>
                <Modal.Title>Add Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Item Number</Form.Label>
                          <FormFieldAuto
                            isRender={true}
                            name="ProductId"
                            type="FindItems"
                            value={fieldsItem.ProductId}
                            onSelect={this.onItemSelect}
                          />
                          {errorsItem.ProductId.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.ProductId.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Uom</Form.Label>
                          <FormFieldSelect
                            isRender={true}
                            name="Uom"
                            type="UOM"
                            lookup={true}
                            value={fieldsItem.Uom}
                            onSelect={this.onItemSelect}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Quantity*</Form.Label>
                          <Form.Control
                            type="text"
                            name="Qty"
                            value={fieldsItem.Qty}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                          {errorsItem.Qty.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.Qty.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Rate*</Form.Label>
                          <Form.Control
                            type="text"
                            name="Rate"
                            value={fieldsItem.Rate}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                          {errorsItem.Rate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.Rate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Discount %</Form.Label>
                          <Form.Control
                            type="text"
                            name="DiscPer"
                            value={fieldsItem.DiscPer}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Tax %</Form.Label>
                          <Form.Control
                            type="text"
                            name="Tax"
                            value={fieldsItem.Tax}
                            onChange={(e) => this.onItemChange(e, "f")}
                            maxLength={3}
                          />
                          {errorsItem.Tax.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.Tax.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Requested Delivery Date*</Form.Label>
                          <DatePicker
                            name="ReqDeliveryDate"
                            value={fieldsItem.ReqDeliveryDate ? moment(fieldsItem.ReqDeliveryDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "ReqDeliveryDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.ReqDeliveryDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.ReqDeliveryDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Promised Delivery Date*</Form.Label>
                          <DatePicker
                            name="PromisedDelDate"
                            value={fieldsItem.PromisedDelDate ? moment(fieldsItem.PromisedDelDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "PromisedDelDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.PromisedDelDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.PromisedDelDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Scheduled Delivery Date*</Form.Label>
                          <DatePicker
                            name="SchShipDate"
                            value={fieldsItem.SchShipDate ? moment(fieldsItem.SchShipDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "SchShipDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.SchShipDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.SchShipDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Approved Status</Form.Label>
                          <FormFieldAuto
                            isRender={true}
                            name="OrderDtlStatus"
                            type="FindTransactionStatus"
                            eType="SALESORDER"
                            value={fieldsItem.OrderDtlStatus}
                            onSelect={this.onItemSelect}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="saveBtn"
                  onClick={() => this.onSubmitItem()}
                  type="button"
                  disabled={isAddingItem}
                >{isAddingItem ? "Processing..." : "Submit"}</Button>
                <Button
                  variant="primary"
                  onClick={() => this.cancelItem()}
                  className="cancelBtn btn btn-primary"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Action */}
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  onClick={() => this.onSubmit()}
                  type="button"
                  disabled={isAdding}
                >{isAdding ? "Processing..." : (!params.salesOrderId ? "Save" : "Update")}</Button>
                <Button
                  className="cancelBtn"
                  onClick={() => history.push("/crm/sales-order")}
                  type="button"
                  disabled={isAdding}
                >Cancel</Button>
              </Col>
            </Row>
          </div >
        )
      )
    );
  }
}
export default SalesOrderCrud;