import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history } from "_helpers";
// includes
import { crm } from '_utils';

// Defined Errors
const defErrs = {
  DisplayOrder: {
    isReq: "Display order is required!",
  },
  AnnexType: {
    isReq: "Annexure type is required!",
  },
  AnnexContent: {
    isReq: "Annexure content is required!",
  }
};

class AddAnnexures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        CustQuoteId: null,
        DisplayOrder: null,
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        AnnexType: "",
        AnnexContent: "",
      },
      commonResource: {
        CustQuoteAnnxId: 0,
      },
      errors: {}
    };
  }

  /**
   * Hook Methods
   */
  componentDidMount() {
    const { commonResource, fields } = this.state;
    this.defineInitErrorMsg();

    if (this.props.match.params.objectId != undefined) {

      fields.CustQuoteId = this.props.match.params.objectId;
      commonResource.CustQuoteAnnxId = this.props.match.params.annexureId ? this.props.match.params.annexureId : 0;

      this.setState({
        commonResource: commonResource,
        fields: fields
      }, () => {
        if (this.state.commonResource.CustQuoteAnnxId > 0) {
          this.getDetailById(commonResource.CustQuoteAnnxId)
        }
      });

    }
  }

  /**
   * Intialize error handling
   */
  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to quote annexure tab
   */
  onCancel = () => {
    // const { fields } = this.state;
    // history.push("/crm/quote/edit/" + fields.CustQuoteId + "?tab=annexures");
    history.goBack()
  }

  /**
   * Input onchange event handling
   */
  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  /**
   * Get selected date values 
   */
  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState({
      fields: fields
    }, () => {
      this.validateForm(name, date);
    });
  }

  /**
   * Validate form
   */
  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Get annexure detaild by Id
   */
  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_ANNEXURES + "/" + id);


    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState({
            fields: fields,
            errors: errors
          }, () => {
          });

        }
      },
      (error) => {
      }
    );
  }

  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.CustQuoteAnnxId > 0) {
        this.update();
      } else {
        this.create();
      }
    }

  };

  update = () => {
    const { fields, commonResource } = this.state;

    let url = envConfig.BASE_API + Services.CRM_ANNEXURES + "/" + commonResource.CustQuoteAnnxId;
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/crm/quote/edit/" + fields.CustQuoteId + "?tab=annexures");
        }
      },
      (error) => { }
    );
  }

  create = () => {
    const { fields } = this.state;

    let url = envConfig.BASE_API + Services.CRM_ANNEXURES;
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/crm/quote/edit/" + fields.CustQuoteId + "?tab=annexures");
        }
      },
      (error) => { }
    );
  }

  render() {
    const {
      fields,
      commonResource,
      errors,
      formSubmit,
    } = this.state;

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Annexure INFORMATION</h2>
            </div>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Display Order*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="DisplayOrder"
                    onChange={(e) => this.handleOnChange(e)}
                    value={fields.DisplayOrder}
                  />
                  {errors.DisplayOrder !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.DisplayOrder}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Annexure Type*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="AnnexType"
                    onChange={(e) => this.handleOnChange(e)}
                    value={fields.AnnexType}
                  />
                  {errors.AnnexType !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.AnnexType}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Annexure Content*</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder=""
                    name="AnnexContent"
                    onChange={(e) => this.handleOnChange(e)}
                    value={fields.AnnexContent}
                  />
                  {errors.AnnexContent !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.AnnexContent}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  {commonResource.CustQuoteAnnxId > 0 ? "Update" : "Save"}
                </Button>
                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

export default AddAnnexures;
