import React from 'react';
import { withSnackbar } from 'notistack';

/**
 * Procedures
 * ==>
 * Step 1: Have created { useSnackbarRef } variable to store component props, init null value is assigned
 * Step 2: Have created a function { setUseSnackbarRef } use to set value for { useSnackbarRef } variable
 * Step 3: Have created { InnerCommonSnackbarConfig } to get componenet props
 * Step 4: Have stored { InnerCommonSnackbarConfig } component to a { ExportICSC } and exported for use
 * Step 5: Once you called { CommonSnackbarConfig } function, it'll render { InnerCommonSnackbarConfig } component 
 * and stored props value to { useSnackbarRef } with help of { setUseSnackbarRef } callback
 * Step 6: The default exported have five diffent functionalities, when you call their method they use { useSnackbarRef }
 * provide snakbar message based on above references,
 */

/**
 * Variable Declaration
 */
var useSnackbarRef = null
const setUseSnackbarRef = (props) => {
    useSnackbarRef = props
}

/**
 * Inner Common Snackbar Configurator Componenet Declaration
 */
class InnerCommonSnackbarConfig extends React.Component {
    constructor(props) { super(props); }
    componentDidMount(){ this.props.setUseSnackbarRef(this.props) }
    render() { return null }
}

/**
 * Export ICSC compoenent
 */
const ExportICSC = withSnackbar(InnerCommonSnackbarConfig)

/**
 * Export Methods
 */
export const CommonSnackbarConfig = () => {
    return <ExportICSC setUseSnackbarRef={setUseSnackbarRef} />
}
export default {
    success(msg) { this.toast(msg, 'success') },
    warning(msg) { this.toast(msg, 'warning') },
    info(msg) { this.toast(msg, 'info') },
    error(msg) { this.toast(msg, 'error') },
    toast(msg, variant = 'default') { useSnackbarRef.enqueueSnackbar(msg, { variant }) }
}