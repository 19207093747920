import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// mui
import Button from "@material-ui/core/Button";
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices } from "_helpers";
// files
import serviceList from "crm/files/serviceList.json";

function DemoFormLiveUpdate(props) {
    const params = useParams();
    const [submit, setSubmit] = useState(false);
    const [update, setUpdate] = useState([]);
    useEffect(() => {
        // console.log(params)
        if (submit) {
            updateForms()
        }
    }, [submit])

    const updateForms = async () => {
        Object.keys(serviceList).filter(i => serviceList[i].dynamic).map((item, key) => {
            if ((params.module === "all" || params.module === item)) {
                let jsonData = require(`crm/files/dynamic/${item}.json`);
                let activityFields = ["CreatedBy", "ModifiedBy", "CreatedOn", "ModifiedOn", "CreationDate", "ModificationDate"];
                // api call
                let filter = `?q=EserviceCode=${serviceList[item].code}`;
                let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter
                restServices.getRequest(
                    url,
                    (response) => {
                        if (response) {
                            let serviceId = response.items[0].EserviceId
                            let resultData = JSON.parse(response.items[0].FormFields);
                            if (resultData !== null) {
                                // Field Update
                                let inc = 0;
                                let newFields = [];
                                resultData.form.sections.map(sItem => { // section field
                                    resultData.form.fields.filter(item => (item.layout === sItem.id && !activityFields.includes(item.key))).map(fItem => {
                                        fItem.index = inc;
                                        newFields.push(fItem);
                                        inc++;
                                    })
                                })

                                // unused fields
                                resultData.form.fields.filter(i => (i.status === "unUse")).map(fItem => {
                                    fItem.index = inc;
                                    newFields.push(fItem);
                                    inc++;
                                })

                                // no layout
                                jsonData.fields.filter(i => (!i.layout && !activityFields.includes(i.key))).map(fItem => { // non section field
                                    fItem.index = inc;
                                    newFields.push(fItem);
                                    inc++;
                                })

                                // Section Update
                                let activity = resultData.form.sections.filter(i => (i.id === "activity"));
                                if (activity.length === 0) {
                                    resultData.form.sections.push({
                                        "id": "activity",
                                        "label": "Activities",
                                        "layout": "double",
                                        "fixed": false
                                    });
                                }

                                // Activity field Update                                
                                jsonData.fields.filter(i => activityFields.includes(i.key)).map(fItem => {
                                    fItem.index = inc;
                                    fItem.layout = "activity";
                                    fItem.visibility = true;
                                    fItem.disabled = false;
                                    fItem.attr.lookupEntity = "";
                                    if (["CreatedBy", "ModifiedBy"].includes(fItem.key)) {
                                        fItem.type = "lookup";
                                        fItem.attr.lookup = "employeeEmailList";
                                    }
                                    newFields.push(fItem);
                                    inc++;
                                })

                                // fieldupdate
                                newFields.map(fItem => {
                                    if (fItem.fieldUpdate || fItem.variable === "custom") {
                                        fItem.fieldUpdate = true;
                                    } else {
                                        fItem.fieldUpdate = false;
                                    }
                                });
                                resultData.form.fields = newFields;
                            }

                            console.log(resultData);

                            //TODO - compare system fields live & local

                            // api call
                            let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + "/" + serviceId;
                            let apiData = {
                                FormFields: JSON.stringify(resultData)
                            }
                            restServices.patchRequest(
                                url,
                                apiData,
                                (response) => {
                                    setUpdate(oldData => {
                                        let newData = reParse(oldData)
                                        newData.push(item);
                                        return newData;
                                    })
                                },
                                (error) => { }
                            );
                        }
                    },
                    (error) => { }
                )
            }
        })
    }

    const reParse = (data) => {
        return JSON.parse(JSON.stringify(data))
    }

    return (
        <div className="mt-5">
            <h4>Update Live JSON{((params.module === "all" && update.length === 8) || (params.module !== "all" && update.length === 1)) ? " - Fully Completed" : ""}</h4>
            {update.length === 0 ? (
                <div>
                    <Button variant="outlined" color="primary" size="large" disabled={submit} onClick={() => setSubmit(true)}>
                        {submit ? "Updating data" : "Yes update LIVE JSON"}
                    </Button>
                    <p className="mt-3">
                        <b className="error">Warning:</b> This will update live JSON values.
                    </p>
                </div>
            ) : update.map((i, k) => (
                <p key={k}>{i + " has been updated"}</p>
            ))}
        </div>
    )
}

export default DemoFormLiveUpdate;