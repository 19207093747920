import React from "react";

// Import layout design packages
import { Form, Col, Button, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Select from "react-select";

// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Import Images
import SearchIcon from "img/search-icn.png";
import EditIcon from "img/edit.svg";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import LeaveDayList from "./LeaveDayList";
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import Checkbox from "material-ui/Checkbox";
// includes
import { crm } from '_utils';
class LeaveCalendarList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      open: false,
      isLoading: false,
      isSearchLoad: false,
      isLoadingAdd: false,
      searchList: [],
      selectedLeave: {},
      selectedLeaveCalId: 0,
      fields: {
        LeaveCalYear: "",
        StartDate: new Date(),
        EndDate: null,
        Active: "Y",
        TenantId: crm.userInfo().pTenantId,
      },
      errors: {
        LeaveCalYear: {
          error: "",
          isReq: "Year is required!",
        },
        StartDate: {
          error: "",
          isReq: "Start Date is required!",
        },
        EndDate: {
          error: "",
          isReq: "End Date is required!",
        },
        Active: {
          error: "",
          isReq: "Active is required!",
        },
      },
      commonResource: {
        LeaveCalId: 0,
      },
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      userPermissions: common.getPermission(this.props, "hrms:leave calendar"),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  handleYearChange = (obj) => {
    const { fields } = this.state;
    fields.LeaveCalYear = obj.value;
    this.setState({ fields: fields },
      () => {
        this.validateForm();
      }
    );
  };

  /**
   * Add Leave calender year modal popup
   */
  addYearModal = (event, leaveCalId) => {
    event.preventDefault();
    const { commonResource } = this.state;
    commonResource.LeaveCalId = leaveCalId;
    this.setState({
      open: true,
      commonResource: commonResource,
    });
    if (leaveCalId > 0) {
      this.getDetailById(leaveCalId);
    }
  };
  /**
   * Get details by id & set data in fields
   */
  getDetailById = (id) => {
    const { fields, errors } = this.state;
    this.setState({
      isLoadingAdd: true,
    });

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_LEAVE_CALENDER + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                if (key === "StartDate" || key === "EndDate") {
                  fields[key] = response[key] ? new Date(response[key]) : "";
                } else {
                  fields[key] = response[key];
                }

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key].error = "";
                }
              }
            }
          }
          this.setState({
            fields: fields,
            errors: errors,
            isLoadingAdd: false,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Close Modal Popup - leave calender year
   */
  closeAddYearModal = () => {
    this.resetFields(this.state.searchList);
  };

  resetFields = (searchList) => {
    const { fields, errors } = this.state;
    fields.LeaveCalYear = "";
    fields.StartDate = "";
    fields.EndDate = "";
    fields.Active = "Y";
    // Remove Default Errors
    for (var key of Object.keys(errors)) {
      if (errors.hasOwnProperty(key)) {
        errors[key].error = "";
      }
    }
    this.setState({
      fields: fields,
      open: false,
      isLoadingAdd: false,
      //searchList: searchList,
      formSubmit: false,
      errors: errors,
    });
  };

  /**
   * Methods
   */
  handleClickList = (leaveYear) => {
    this.setState(
      {
        selectedLeave: leaveYear ? leaveYear : {},
        selectedLeaveCalId: leaveYear ? leaveYear.LeaveCalId : 0,
        isLoading: true,
      },
      (_) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  /**
   * Service Request
   */
  getList() {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LeaveCalYear) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    let url = envConfig.BASE_API + Services.HRMS_LEAVE_CALENDER + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedLeave = this.state.selectedLeave;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          response.items.map((item, key) => {
            tempList.push(item);
          });

          if (paging.page === 1) {
            selectedLeave = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedLeave);
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
            searchList: tempList,
          },
            (_) => {
              this.handleClickList();
            });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };
  // support
  yearList() {
    let yearList = [];
    for (let i = 1988; i <= new Date().getFullYear(); i++) {
      yearList.push(i);
    }
    return yearList;
  }

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;

    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();
    if (isValid) {
      if (commonResource.LeaveCalId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, searchList } = this.state;

    let url = encodeURI(
      envConfig.BASE_API +
      Services.HRMS_LEAVE_CALENDER +
      "/" +
      commonResource.LeaveCalId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {

          // let items = searchList.items;

          // items.forEach(function (item, index) {
          //   if (item.LeaveCalId === commonResource.LeaveCalId) {
          //     items[index] = response;
          //     return;
          //   }
          // });

          // searchList.items = items;
          this.resetFields();
          this.getList();
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, searchList } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_LEAVE_CALENDER);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          // let items = searchList.items;
          // items.push(response);
          // searchList.items = items;
          // this.resetFields(searchList);
          this.resetFields();
          this.getList();
        }
      },
      (error) => { }
    );
  };

  /**
   * Trigger Calendar Leave List Popup
   * @param {*} id 
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  }

  /**
   * Delete Calendar Leave by ID
   * @param {*} leaveId 
   */
  handleClickDeleteList = (leaveId) => {
    const { paging } = this.state;

    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.HRMS_LEAVE_CALENDER + "/" + leaveId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState({
          paging: paging
        }, () => {
          this.getList();
        });

        common.snack("S", "Calendar Leave deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      });
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedLeave: selectedLeave,
      selectedLeaveCalId,
      isLoading,
      fields,
      errors,
      formSubmit,
      commonResource,
      isLoadingAdd,
      open,
      paging,
      totalRecords,
      filterName,
      userPermissions
    } = this.state;
    const isChecked = (value) => value == "Y";

    const yearListData = this.yearList().map((data) => {
      let obj = {
        label: data,
        value: data,
      };
      return obj;
    });

    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <Row>
              <Col lg={3} sm={4}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Leave Calendar</h2>
                    {userPermissions.add && (
                      <Link
                        to="#"
                        className="btn btn-add"
                        onClick={(e) => this.addYearModal(e, 0)}
                      >
                        Add
                      </Link>
                    )}
                  </div>

                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                          //onClick={this.getList}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="filterName"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search Year"
                        name="filterName"
                      />
                    </div>
                    <ul className="marketing-list">
                      {isSearchLoad == false &&
                        searchList.length > 0 &&
                        searchList.map((row, index) => {
                          return (
                            <li
                              key={row.LeaveCalId}
                              className={`d-flex justify-content-between align-items-center ${row.LeaveCalId == selectedLeave.LeaveCalId
                                ? "active"
                                : ""
                                }`}
                            >
                              <div className="mlLeft mr-3">
                                <h4
                                  className="ml-name"
                                  onClick={(e) => this.handleClickList(row)}
                                >
                                  {row.LeaveCalYear}
                                </h4>
                                <p className="ml-type">
                                  {row.StartDate == null
                                    ? "-"
                                    : common.formatDate(
                                      row.StartDate,
                                      "DD/MM/YYYY"
                                    )}
                                </p>
                              </div>
                              <div className="mlRight">
                                {userPermissions.update && (
                                  <Link to="#"
                                    onClick={(e) =>
                                      this.addYearModal(e, row.LeaveCalId)
                                    }
                                  >
                                    <img src={EditIcon} alt="Edit" />
                                  </Link>
                                )}
                                {userPermissions.delete && (
                                  <Link to="#"
                                    style={{ marginLeft: 10 }}
                                    onClick={() =>
                                      this.deleteSelectedItem(row.LeaveCalId)
                                    }
                                  >
                                    <img src={DeleteIcon} alt="Delete" />
                                  </Link>
                                )}
                              </div>
                            </li>
                          );
                        })}
                      {isSearchLoad == true && (
                        <li>
                          <Loader />
                        </li>
                      )}
                      {isSearchLoad == false && searchList.length === 0 && (
                        <li>
                          <NoRecordFound />
                        </li>
                      )}
                    </ul>
                    {/* Pagination */}
                    {searchList.length > 0 && (
                      <div className="load-more-wrap">
                        {paging.page != paging.total && searchList.length > 0 && (
                          <Link
                            to="#"
                            onClick={(e) => this.pagination(e)}
                            className="load-more"
                          >
                            {" "}
                            Load More{" "}
                          </Link>
                        )}
                        <div className="paging-text">
                          1 to {searchList.length} of {totalRecords} entries
                        </div>
                      </div>
                    )}
                  </Paper>
                </div>
              </Col>
              <Col lg={9} sm={8}>
                <div className="formTabSection position-r">
                  {isLoading ? (
                    <Loader />
                  ) : (!isLoading &&
                    <LeaveDayList
                      objectId={selectedLeaveCalId}
                      userPermissions={userPermissions}
                      selectedLeave={selectedLeave} />
                  )}
                </div>
              </Col>
            </Row>
            <Dialog
              open={open}
              onClose={this.closeAddYearModal}
              aria-labelledby="responsive-dialog-title"
              className="leave-dialog"
            >
              <DialogTitle id="responsive-dialog-title" className="popup-title">
                {"Holiday Year"}
              </DialogTitle>
              <DialogContent>
                {!isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Calendar Year*</Form.Label>
                        <Select
                          name="LeaveCalYear"
                          value={yearListData.find(o => o.value == fields.LeaveCalYear)}
                          onChange={(val) => this.handleYearChange(val)}
                          options={yearListData}
                        />
                        {errors.LeaveCalYear.error !== "" &&
                          formSubmit === true && (
                            <Form.Text className="error">
                              {errors.LeaveCalYear.error}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </div>
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Start Date*</Form.Label>
                        <DatePicker
                          name="StartDate"
                          value={fields.StartDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, "StartDate")
                          }
                          format="dd/MM/yyyy"
                          autoComplete="off"
                        //minDate={new Date()}
                        />
                        {errors.StartDate.error !== "" && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.StartDate.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>End Date*</Form.Label>
                        <DatePicker
                          name="EndDate"
                          value={fields.EndDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, "EndDate")
                          }
                          format="dd/MM/yyyy"
                          autoComplete="off"
                        //minDate={new Date()}
                        />
                        {errors.EndDate.error !== "" && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.EndDate.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>&nbsp; </Form.Label>
                        <Checkbox
                          name="Active"
                          className="filter-checkbox"
                          checked={isChecked(fields.Active)}
                          label="Active"
                          labelStyle={{ color: "#2d2d2d" }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Loader />
                    </div>
                  </div>
                )}
              </DialogContent>
              <DialogActions className="popup-footer justify-content-start">
                <Row className="botBtn">
                  <Col sm={12}>
                    <Button
                      className="saveBtn"
                      onClick={this.onSubmit}
                      type="submit"
                    >
                      {commonResource.LeaveCalId === 0 ? "Add" : "Update"}
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={this.closeAddYearModal}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

export { LeaveCalendarList };
