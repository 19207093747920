import React, { useState, useEffect } from "react";
// mui
import Button from "@material-ui/core/Button";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices } from "_helpers";
import { crm } from "_utils";
function DemoUserRoles() {
  const [count, setCount] = useState(0);
  let paging = {
    page: 1,
    size: 5000,
    total: 1,
  };
  let filterName = "";

  useEffect(() => {
    //getList();
  }, []);

  const getList = () => {
    let filter = `?q=TenantId=${
      crm.userInfo().pTenantId
    }&GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${
      Services.CRM_DEFAULT_GROUPS_2
    }','${Services.CRM_DEFAULT_GROUPS_3}','${
      Services.CRM_DEFAULT_GROUPS_4
    }')&limit=${
      paging.size
    }&offset=${
      (paging.page - 1) * paging.size
    }&orderBy=CreatedOn:desc&totalResults=true`;

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + filter);

    restServices.getRequest(
      url,
      (response) => {
        setCount(response.items.length);
        console.log('response.items.length :>> ', response.items.length);
        if (response && response.items && response.items.length > 0) {
          response.items.forEach((item) => {
            if (item.UserAccess) {
              let permissions = JSON.parse(item.UserAccess);
              console.log("permissions :>> ", permissions);
              //if (!permissions.configure.hasOwnProperty("subemail")) {
                permissions.configure["subemail"] = {
                  r: "2",
                  a: "2",
                  u: "2",
                  d: "2",
                };

                permissions.configure["conversion mapping"] = {
                  r: "2",
                  a: "2",
                  u: "2",
                  d: "2",
                };
              //}
              console.log(item.GroupDesc, permissions);

              // api call
              let url =
                envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + "/" + item.UserGroupId;
              let apiData = {
                UserAccess: JSON.stringify(permissions),
              };
              restServices.patchRequest(
                url,
                apiData,
                (response) => {
                  console.log("response :>> ", response.UserGroupId);
                },
                (error) => {}
              );
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={() => getList()}
      >
        Update User Groups
      </Button><br /><br />
      <h1>{count}</h1>
      <span>
        * New modules permission update (sub email & configure mapping)
      </span>
    </div>
  );
}

export default DemoUserRoles;
