import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
// includes
import { crm } from '_utils';
//Import CSS
import "../css/Location.css";

// Import Images
import SearchIcon from "img/search-icn.png";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import LocationDetail from "./LocationDetail";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class LocationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: [],
      selectedLoc: {},
      selectedLocId: 0,
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      isQuery: false,
      // permission
      userPermissions: common.getPermission(this.props, "vendor:locations"),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let locationId = parseInt(params.get("locationId"));
      let filterName = params.get("filterName");
      let page = params.get("page");
      if (locationId) {
        let paging = this.state.paging;
        paging.page = parseInt(page);
        paging.size = 5;
        paging.total = 5;

        this.setState(
          {
            filterName,
            paging,
            isQuery: true,
          },
          (_) => {
            this.getList(this.state.isQuery);
          }
        );
      } else {
        this.getList(this.state.isQuery);
      }
    }
  }

  /**
   * Methods
   */

  handleClickList = (location) => {
    this.setState(
      {
        selectedLoc: location ? location : {},
        selectedLocId: location ? location.LocationId : 0,
        isLoading: true,
      },
      (_) => {
        const { selectedLoc: selectedLoc } = this.state;
        if (selectedLoc.SiteId) {
        }
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState(
      {
        filterName: value,
      },
      () => {
        //reset page number to 1
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
      }
    );
  };

  /**
   * Trigger Delete Location List Popup
   * @param {*} id
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  };

  /**
   * Delete Location by ID
   * @param {*} locationId
   */
  handleClickDeleteList = (locationId) => {
    const { paging } = this.state;
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_LOCATIONS + "/" + locationId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );

        common.snack("S", "Location deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Service Request
   */
  getList(queryParam) {
    const { paging, searchList, filterName } = this.state;

    let tempData = [];


    if (!queryParam && paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
      tempData = [];
    } else {
      searchList.map((item) => {
        tempData.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LocationName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LocationName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${(paging.page) * paging.size}`;
    }

    let url = envConfig.BASE_API + Services.CRM_LOCATIONS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedLocation = this.state.selectedLoc;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          let search = window.location.search;
          let params = new URLSearchParams(search);
          let locationId = params.get("locationId");

          response.items.map((item, key) => {
            tempData.push(item);
            if (locationId != undefined) {
              if (item.LocationId == parseInt(locationId)) {
                selectedLocation = item
              }
            }
          });
          if (!queryParam && paging.page === 1) {
            selectedLocation = tempData[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempData,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedLocation);
            }
          );
        } else {
          this.setState(
            {
              isSearchLoad: false,
              searchList: tempData,
            },
            (_) => {
              // this.handleClickList();
            }
          );
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedLoc: selectedLoc,
      paging,
      totalRecords,
      isLoading,
      userPermissions
    } = this.state;

    return (
      !userPermissions.access ? (
        <AccessDenied />
      ) : (
        <div className="crm-list-wrap">
          <Row>
            <Col sm={3}>
              <div className="marketing-list-wrap">
                <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                  <h2 className="crm-type-title">Location List</h2>
                  {userPermissions.add && (
                    <Link to={"/setup/vendor/location/add"} className="btn btn-add">
                      Add
                    </Link>
                  )}
                </div>

                <Paper>
                  <div className="input-group search-ml-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getList}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="filterName"
                      onChange={(e) => this.handleSearchChange(e)}
                      placeholder="Search"
                      name="filterName"
                    />
                  </div>
                  <ul className="marketing-list">
                    {isSearchLoad == false &&
                      searchList.length > 0 &&
                      searchList.map((row, index) => {
                        return (
                          <li
                            onClick={(e) => this.handleClickList(row)}
                            key={row.LocationId}
                            className={`d-flex justify-content-between align-items-center ${row.LocationCode == selectedLoc.LocationCode
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="mlLeft mr-3">
                              <h4 className="ml-name">{row.LocationName}</h4>
                              <p className="ml-type">{row.LocationCode}</p>
                            </div>
                            {userPermissions.delete && (
                              <div className="mlRight">
                                <Link
                                  to="#"
                                  style={{ marginLeft: 10 }}
                                  onClick={() =>
                                    this.deleteSelectedItem(row.LocationId)
                                  }
                                >
                                  <img src={DeleteIcon} alt="Delete" />
                                </Link>
                              </div>
                            )}
                          </li>
                        );
                      })}

                    {isSearchLoad == true && (
                      <li>
                        <Loader />
                      </li>
                    )}
                    {isSearchLoad == false && searchList.length === 0 && (
                      <li>
                        <NoRecordFound />
                      </li>
                    )}
                  </ul>
                  {/* Pagination */}
                  {searchList.length > 0 && (
                    <div className="load-more-wrap">
                      {paging.page != paging.total && searchList.length > 0 && (
                        <Link
                          to="#"
                          onClick={(e) => this.pagination(e)}
                          className="load-more"
                        >
                          {" "}
                          Load More{" "}
                        </Link>
                      )}
                      <div className="paging-text">
                        1 to {searchList.length} of {totalRecords} entries
                      </div>
                    </div>
                  )}
                </Paper>
              </div>
            </Col>
            <Col sm={9}>
              <div className="formTabSection position-r">
                {!isLoading && (
                  <LocationDetail
                    selectedLoc={selectedLoc ? selectedLoc : {}}
                    filterName={this.state.filterName}
                    page={paging.page}
                    isQuery={this.state.isQuery}
                    userPermissions={userPermissions}
                  />
                )}
                {isLoading && <Loader />}
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export { LocationList };
