import React from "react";
import "./ScrollButton.css";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
class ScrollButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            scrollHeight: document.documentElement.scrollHeight,
            top: 350
        };
    }

    scrollToTop = () =>{
        this.state.top = this.state.top - 350
        window.scrollTo({
          top: this.state.top, 
          behavior: 'smooth'
        });
    };

    scrollToBottom = () =>{ 
        this.state.top = this.state.top + 350
        window.scrollTo({ 
          top: this.state.top, //document.documentElement.scrollHeight, 
          behavior: 'smooth'
        }); 
      };
      
      toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0){
          this.setState({
            visible: true
          })
        } 
        else {
          this.setState({
              visible: false
          })
        }
      };
    
    render() {
        const { visible, scrollHeight } = this.state;
        const scrolled = document.documentElement.scrollTop;
        window.addEventListener('scroll', this.toggleVisible);
        return(
            <div className="scroll-button">
                <div className="scroll-up">
                    {scrolled < scrollHeight &&
                        <ArrowDownwardIcon style={{ fontSize: 20, backgroundColor: "#fff" }} onClick={this.scrollToBottom} />
                    }
                </div>
                <div className="scroll-down">
                    {visible &&
                        <ArrowUpwardIcon style={{ fontSize: 20, backgroundColor: "#fff" }} onClick={this.scrollToTop} />
                    }
                </div>
            </div>
        )
    }
}

export default ScrollButton;