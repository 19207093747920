import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Form, Col, Button, Row } from "react-bootstrap";
import MuiButton from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import moment from "moment";
// material
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools, history } from "_helpers";
// includes
import DynamicSelect from "./DynamicSelect";
// pages
import Loader from "_components/_loader/Loader";
// files
import lookupList from "./files/lookupList.json";
import serviceList from "./files/serviceList.json";
import leadFields from "./files/dynamic/lead.json";
// css
import "./includes/DynamicForm.css";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function DynamicForm(props) {
  const myRefs = useRef([]);
  const propsDefault = props.default || {};
  const propsDisabled = props.disabled || {};
  const service = serviceList[props.module];
  const authInfo = common.authInfo();
  const [isMount, setIsMount] = useState(false);
  const [isFormSubmit, setFormSubmit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [serviceId, setServiceId] = useState(0);
  const [data, setData] = useState([]);
  const [fieldTypes] = useState({
    "default": ["singleLine", "multiLine", "email", "phone", "number", "decimal", "currency", "url"],
    "bool": ["checkbox"],
    "date": ["date", "dateTime"],
    "singleSelect": ["pickList", "lookup"],
    "multipleSelect": ["multiSelect", "multiLookup"],
    "file": ["imageUpload", "fileUpload"],
  });
  const [fieldIndex, setFieldIndex] = useState(null);
  // const
  let fieldCount = 0;
  //permission from lead and deal to control access for other department, responsible person
  const userPermissions = props.userPermissions ? props.userPermissions : {
    read: "1",
    add: "1",
    update: "1",
    delete: "1",
    import: "1",
    export: "1",
    access: "1",
  };

  // effect
  useEffect(() => {
    // console.log(authInfo);
    // console.log(params);
    setStatus("process");
    getColumnsData();
    // setData(setFormData(leadFields));
    // setStatus("completed");
    // setIsMount(true);
  }, [props.module])

  // #Api#
  const getColumnsData = () => {
    let filter = `?q=EserviceCode=${service.code}`;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let formFields = JSON.parse(response.items[0].FormFields);
          setServiceId(response.items[0].EserviceId)
          // console.log(formFields);
          if (props.data.value1) {
            setData(formFields.form);
            getEditData();
          } else {
            setData(setFormData(formFields.form));
            setStatus("completed");
            setIsMount(true);
          }
        }
      },
      (error) => { }
    )
  };

  // edit
  const getEditData = () => {
    let url = envConfig.BASE_API + Services[service.api] + '/' + props.data.value1;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          // set value
          setData(oldData => {
            let newData = reParse(oldData);
            newData.fields.map(item => {
              let value = response[item.key];
              value = (value === null ? "" : value);
              if (item.type === "pickList") {
                value = value ? { label: value, value: value } : value;
              } else if (item.type === "lookup") {
                value = value ? { label: (response[`${item.key}Desc`] || value), value: String(value) } : value;
              } else if (item.type === "multiSelect" || item.type === "multiLookup") {
                //ToDo - Reminder. if you add more Multilookup fields, please check form fields json structure
                if(item.display){
                  let dField = item.display;
                  if(dField.storeType == "multiple" && response[dField.name]){
                    let nameList = response[dField.name].split(",");
                    value = value ? value.split(",").map((i, idx) => ({ label: nameList[idx], value: i })) : "";
                  }
                }else {
                  value = value ? value.split(",").map(i => ({ label: i, value: i })) : "";
                }
              } else if (item.type === "currency") {
                value = tools.onlyFloat(String(value)).replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
              }
              // default
              if (!value && (item.key in propsDefault)) {
                value = propsDefault[item.key];
              }
              item.value = value;
              // disabled
              if (item.key in propsDisabled) {
                item.disabled = propsDisabled[item.key];
              }
            })
            return newData;
          })
          setStatus("completed");
          setIsMount(true);
        }
      },
      (error) => { }
    )
  };

  const fillData = (field) => {
    if (field.value !== "" && field.attr.fill !== null) {
      if (field.attr.fill.type === "api") {
        let lookup = lookupList[field.attr.lookup];
        let url = `${envConfig.BASE_API}${Services[lookup.name]}?q=${lookup.set}=${field.value.value}`;
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              let result = response.items[0];
              setData(oldData => {
                let newData = reParse(oldData);
                newData.fields.map((item, key) => {
                  if ((item.key in field.attr.fill.params)) {
                    let value = result[field.attr.fill.params[item.key]];
                    if (item.type === "pickList") {
                      item.value = { label: value, value: value }
                    } else {
                      item.value = value;
                    }
                  }
                })
                return newData;
              })
            }
          },
          (error) => { }
        )
      }
    }
  }

  // forms
  const setFormData = (form) => {
    form.fields.map(item => {
      // default
      if (item.key in propsDefault) {
        item.value = propsDefault[item.key];
      }
      // disabled
      if (item.key in propsDisabled) {
        item.disabled = propsDisabled[item.key];
      }
    })
    return form;
  }

  const getFormFields = (sKey) => {
    let nData = reParse(data);
    let sId = data.sections[sKey].id;
    let quickList = [true, false];
    if (props.quick) {
      quickList = [true];
    }
    return nData.fields.filter(item => (item.layout === sId && item.status === "use" && item.visibility && item.store && quickList.includes(item.quick)));
  }

  // handler
  const onFieldChange = (e, index) => {
    let newData = reParse(data);
    
    let field = newData.fields[index];
    console.log(field);

    let value = "";  
    if (fieldTypes.default.includes(field.type) && field.type == 'phone') {
        value = e;
    } else if(fieldTypes.default.includes(field.type)) {
      value = e.target.value;
    } else if (fieldTypes.bool.includes(field.type)) {
      value = e.target.checked ? "Y" : "N";
    } else if (fieldTypes.date.includes(field.type)) {
      value = e;
    } else if (fieldTypes.singleSelect.includes(field.type)) {
      value = e ? e : "";
    } else if (fieldTypes.multipleSelect.includes(field.type)) {
      value = e ? e : "";
    }
    value = validateType(index, value);
    field.error = validateField(index, value);
    field.value = value;
    field.touched = true;
    setData(newData);
    setFieldIndex(index);
    fillData(field);
  }

  const onImageChange = (e, index) => {
    let file = "";
    if (e.target.files) {
      file = e.target.files[0];
      if (!file) {
        file = "";
      }
      let newData = reParse(data);
      let field = newData.fields[index];
      field.value = file;
      field.error = validateField(index, file);
      field.touched = true;
      setData(newData);
    }
  }

  const onFileChange = (e, index) => {

  }

  // submit handler
  const onSubmit = () => {
    setFormSubmit(true);
    let allow = validateForm();
    if (allow) {
      setIsSubmit(true);
      // fields
      let fields = { TenantId: authInfo.TenantId, OrgId: authInfo.DefaultOrgId };
      data.fields.filter(i => i.store === true).map(item => {
        let value = item.value;
        if (item.props !== "") {
          value = props.data[item.props];
        } else if (fieldTypes.singleSelect.includes(item.type)) {
          value = value ? value.value : "";
        } else if (fieldTypes.multipleSelect.includes(item.type)) {
          value = value ? value.map(i => i.value).join(",") : "";
        } else if (fieldTypes.date.includes(item.type)) {
          value = value ? common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ") : null;
        } else if (item.type === "currency") {
          value = String(value).replace(/,/g, "");
        }
        fields[item.key] = value;
      })
      // console.log("fields", fields);

      // create
      if (!props.data.value1) {
        let url = envConfig.BASE_API + Services[service.api];
        restServices.postRequest(
          url,
          fields,
          (response) => {
            console.log(response);
            common.snack("S", "Successfully Created");
            //history.push(props.redirectURL);
            if (typeof (props.onSave) !== "undefined") {
              props.onSave(response);
            }
            window.scrollTo(0, 0);
            setIsSubmit(false);
          },
          (error) => {
            setIsSubmit(false);
            console.log(error);
          }
        );
      } else {
        let url = envConfig.BASE_API + Services[service.api] + '/' + props.data.value1;
        restServices.patchRequest(
          url,
          fields,
          (response) => {
            console.log(response);
            common.snack("S", "Successfully Updated");
            //history.push(props.redirectURL);
            if (typeof (props.onSave) !== "undefined") {
              props.onSave(response);
            }
            window.scrollTo(0, 0);
            setIsSubmit(false);
          },
          (error) => {
            setIsSubmit(false);
            console.log(error);
          }
        );
      }
    }
  }

  const onCancel = () => {
    if (typeof (props.onCancel) !== "undefined") {
      props.onCancel();
    }
  }

  // validate
  const validateForm = () => {
    let allow = true;
    let focus = true;
    let newData = reParse(data);
    newData.fields.map((item) => {
      let error = "";
      if (item.required && (item.value === "" || item.value === null)) {
        error = `${item.label} is required`;
        allow = false;
      } else if (item.type === "email" && item.value !== "" && !tools.ValidateEmail(item.value)) {
        error = `Please enter a valid email for ${item.label}`;
        allow = false;
      }
      if (error !== "" && focus) {
        try {
          if (fieldTypes.date.includes(item.type)) {
            myRefs.current[item.index].setFocus(true);
          } else {
            myRefs.current[item.index].focus();
          }
        } catch (e) { 
          //alert(e); 
        }
        focus = false;
      }
      item.error = error;
    })

    if (!allow) { setData(newData); }
    return allow;
  }

  const validateField = (index, value) => {
    let error = "";
    if (isFormSubmit) {
      let newData = reParse(data);
      let field = newData.fields[index];
      if (field.required && (value === "" || value === null)) {
        error = `${field.label} is required`;
      } else if (field.type === "email" && value !== "" && !tools.ValidateEmail(value)) {
        error = `Please enter a valid email`;
      }
    }
    return error;
  }

  const validateType = (index, value) => {
    let newData = reParse(data);
    let field = newData.fields[index];
    if (field.type === "number" || field.type === "phone") {
      return tools.onlyNumbers(value);
    } else if (field.type === "decimal") {
      return tools.onlyFloat(value);
    } else if (field.type == "currency") {
      return tools.onlyFloat(value).replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
    }
    return value;
  }

  // support
  const authData = () => {
    return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() }
  }

  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data))
  }

  const labelValue = (data) => {
    if (typeof (data) == "object") {
      return data ? data.map(item => ({ label: item, value: item })) : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  }
  
  // render
  return (
    <Fragment>
      {status == "process" ? (
        <Loader />
      ) : (
        <Fragment>
          {Object.keys(data.sections).map((sItem, sKey) => {
            let sData = data.sections[sItem];
            let fields = getFormFields(sKey);
            if (fields.length > 0) {
              ++fieldCount;
              return (
                <Row className={`${!props.quick ? 'mb-3' : 'mt-3'}`} key={sKey}>
                  <Col sm={12} className={!props.quick ? "formWhiteBg" : ""}>
                    <div className={`${!props.quick ? "formHead" : "quickHead"}`}>
                      <h2>{sData.label}</h2>
                    </div>
                    <Row>
                      {fields.map((fItem, fKey) => {
                        return (
                          <Col sm={6} key={fKey}>
                            <Form.Group className="mb-3">
                              <Form.Label>{fItem.label}{fItem.required && <span className="mf-required">*</span>}</Form.Label>
                              {fItem.type == "singleLine" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "multiLine" ? (
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "email" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "phone" ? (
                                
                                <PhoneInput
                                    country={'lk'}
                                    value={fItem.value}
                                    onChange={(e) => onFieldChange(e, fItem.index)}
                                    ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "pickList" ? (
                                <Select
                                  value={!fItem.touched && !fItem.value ? labelValue(fItem.attr.optionDefault) : fItem.value}
                                  isClearable={true}
                                  options={labelValue(fItem.attr.option)}
                                  onChange={(data) => onFieldChange(data, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "multiSelect" ? (
                                <Select
                                  isMulti={true}
                                  isClearable={true}
                                  value={fItem.value}
                                  options={labelValue(fItem.attr.option)}
                                  onChange={(data) => onFieldChange(data, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "date" ? (
                                <DatePicker
                                  isClearable={true}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Select Date"
                                  selected={fItem.value ? moment(fItem.value).toDate() : null}
                                  onChange={(date) => onFieldChange(date, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "dateTime" ? (
                                <DatePicker
                                  isClearable={true}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  showTimeSelect
                                  timeIntervals={1}
                                  timeCaption="Time"
                                  dateFormat="MMMM d, yyyy hh:mm aa"
                                  placeholderText="Select Date and Time"
                                  selected={fItem.value ? moment(fItem.value).toDate() : null}
                                  onChange={(date) => onFieldChange(date, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "number" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "decimal" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "currency" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "checkbox" ? (
                                <FormControlLabel
                                  value={fItem.value}
                                  control={<Checkbox color="primary" />}
                                  label={fItem.label}
                                  labelPlacement="end"
                                  checked={!fItem.value ? fItem.attr.defaultChecked : fItem.value === "Y" ? true : false}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                  disabled={fItem.disabled}
                                />
                              ) : fItem.type == "url" ? (
                                <Form.Control
                                  type="text"
                                  placeholder={`Enter ${fItem.label}`}
                                  maxLength={fItem.attr.maxChar}
                                  value={fItem.value}
                                  onChange={(e) => onFieldChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "lookup" ? (
                                <DynamicSelect
                                  isRender={true}
                                  field={fItem}
                                  fieldIndex={fieldIndex}
                                  data={data}
                                  params={props}
                                  userPermissions={userPermissions}
                                  recordId={props.data.value1}
                                  onSelect={(data) => onFieldChange(data, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                  disabled={fItem.disabled}
                                />
                              ) : fItem.type == "multiLookup" ? (
                                <DynamicSelect
                                  isMulti={true}
                                  isRender={true}
                                  field={fItem}
                                  fieldIndex={fieldIndex}
                                  data={data}
                                  params={props}
                                  userPermissions={userPermissions}
                                  onSelect={(data) => onFieldChange(data, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "fileUpload" ? (
                                <Form.Control
                                  type="file"
                                  onChange={(e) => onFileChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : fItem.type == "imageUpload" ? (
                                <Form.Control
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => onImageChange(e, fItem.index)}
                                  ref={(el) => (myRefs.current[fItem.index] = el)}
                                />
                              ) : null}
                              {isFormSubmit && fItem.error !== "" ? (
                                <Form.Text className="mf-error">{fItem.error}</Form.Text>
                              ) : (
                                <Form.Text className="mf-error"></Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                        )
                      })}
                    </Row>
                  </Col>
                </Row>
              )
            }
          })}
          {fieldCount === 0 ? (
            <div className="dynamicFieldNone">
              <p>Quick create fields are not available please create under form settings</p>
              <img src={common.loadImg("noRecordFound.svg")} alt="Select" />
              <MuiButton color="secondary" variant="outlined" size="small" className="mt-4" onClick={() => onCancel()}>Got It!</MuiButton>
            </div>
          ) : (
            <div className={!props.quick ? "" : "quickDynBot"}>
              <Row className={!props.quick ? "botBtn" : ""}>
                <Col sm={12}>
                  <Button className="saveBtn" type="submit" onClick={() => onSubmit()} disabled={false}>
                    {isSubmit ? "Processing..." : (props.data.value1 ? "Update" : "Create")}
                  </Button>
                  <Button className="cancelBtn" type="button" onClick={() => onCancel()} disabled={isSubmit}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default DynamicForm;