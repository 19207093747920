import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import moment from "moment";
// mui
import Button from "@material-ui/core/Button";
// api
import { common } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices } from "_helpers";
// files
import serviceList from "crm/files/serviceList.json";
// const
const zip = require('jszip')();

function DemoFormLiveDownload(props) {
    const params = useParams();
    const [submit, setSubmit] = useState(false);
    const [update, setUpdate] = useState([]);
    const authInfo = common.authInfo();
    // effect
    useEffect(() => {
        if (submit) {
            downloadForms()
        }
    }, [submit])

    const downloadForms = async () => {
        Object.keys(serviceList).filter(i => serviceList[i].dynamic).map((item, key) => {
            if ((params.module === "all" || params.module === item)) {
                // api call
                let filter = `?q=EserviceCode=${serviceList[item].code}`;
                let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter
                restServices.getRequest(
                    url,
                    (response) => {
                        if (response) {
                            let resultData = JSON.parse(response.items[0].FormFields);
                            zip.file(`${item}.json`, JSON.stringify(resultData.form));
                            if ((params.module === "all" && key === 7) || (params.module !== "all")) {
                                setTimeout(() => {
                                    zip.generateAsync({ type: "blob" }).then(function (content) {
                                        saveAs(content, `tenant_${authInfo.TenantId}_user_${authInfo.UserId}_${moment().format("DD-MM-YYYY-hh-mm-A_")}json.zip`);
                                    });
                                }, 2000);
                            }
                            setUpdate(oldData => {
                                let newData = reParse(oldData)
                                newData.push(item);
                                return newData;
                            })
                        }
                    },
                    (error) => { }
                )
            }
        })
    }

    const reParse = (data) => {
        return JSON.parse(JSON.stringify(data))
    }

    return (
        <div className="mt-5">
            <h4>Download Live JSON{((params.module === "all" && update.length === 8) || (params.module !== "all" && update.length === 1)) ? " - Fully Downloaded" : ""}</h4>
            {update.length === 0 ? (
                <div>
                    <Button variant="outlined" color="primary" size="large" disabled={submit} onClick={() => setSubmit(true)}>
                        {submit ? "Downloading..." : "Download"}
                    </Button>
                </div>
            ) : update.map((i, k) => (
                <p key={k}>{i + " has been downloaded"}</p>
            ))}
        </div>
    )
}

export default DemoFormLiveDownload;