import React from "react";
import Paper from "material-ui/Paper";
import moment from "moment";
// includes
import { crm } from '_utils';
// Import config and helpers
import { restServices, common } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
// Import internal components
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// css
import "./css/Meeting.css";

class MeetingDetails extends React.Component {

  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      isLoading: true,
      sData: {
        meetingDetails: []
      },
      userInfo: crm.userInfo(),
      userPermissions: common.getPermission(this.props, "configure:automation"),
    };
  }

  componentDidMount() {
    this.getMeetingById();
  }

  getMeetingById = () => {
    const { sData, params } = this.state;
    let filter = '/' + params.id;
    let url = envConfig.BASE_API + Services.CRM_MEETINGS + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData.meetingDetails = response;
          this.setState({
            isLoading: false,
            sData
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false })
      }
    )
  };

  render() {
    const {
      isLoading,
      sData
    } = this.state;
    return (
      <div>
        {/* {!userPermissions.read && <AccessDenied />} */}
        {isLoading ? <Loader /> : (
          <div className="lead-wraper">
            <div className="row">
              <div className="col">
                <Paper style={styles.boxLayout}>
                  <div>
                    <div className="box-title-wrap justify-content-between">
                      <h3 className="card-title">Meeting Details</h3>
                    </div>
                    {sData.meetingDetails == "" ? (
                      <NoRecordFound />
                    ) : (
                      <div>
                        <div className="row">
                          <div className="col-6 list-row">
                            <label class="form-label">Event Type</label>
                            <p class="form-value">{sData.meetingDetails.EventType}</p>
                          </div>
                          <div className="col-6 list-row">
                            <label class="form-label">Event Title</label>
                            <p class="form-value">{sData.meetingDetails.title}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 list-row">
                            <label class="form-label">Location</label>
                            <p class="form-value">{sData.meetingDetails.EventLocation || "-"}</p>
                          </div>
                          <div className="col-6 list-row">
                            <label class="form-label">Time Zone</label>
                            <p class="form-value">{sData.meetingDetails.EventTimezone}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 list-row">
                            <label class="form-label">Start Date</label>
                            <p class="form-value">{moment(sData.meetingDetails.endTime).format("DD/MM/YYY hh:mm A")}</p>
                          </div>
                          <div className="col-6 list-row">
                            <label class="form-label">End Date</label>
                            <p class="form-value">{moment(sData.meetingDetails.startTime).format("DD/MM/YYY hh:mm A")}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        )
        }
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default MeetingDetails;