import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
// Import roles json file
import jsonRole from "json/roles.json";


import permissionsv2 from "json/permission.json";
import permissionsv1 from "json/permissionv1.json";

// Import others
import { Form, Col, Button, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { common, restServices, tools } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
// Import Loader
import Loader from "_components/_loader/Loader";
// //Import Images
import expandIcon from "img/expand.png";
import collapseIcon from "img/collapse.png";
// includes
import { crm } from "_utils";
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class AddRolePermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      headCells: [],
      roleList: {},
      expandList: [],
      selectedRoleId: 0,
      formSubmit: false,
      userPermissions: this.props.userPermissions,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    if (this.props.selectedRole != undefined) {
      this.getRoles();
    }
  }

  /**
   * Component Hooks
   */
  componentDidUpdate(prevProps) {
    if (this.props.selectedRole.GroupId != prevProps.selectedRole.GroupId) {
      this.getRoles();
    }
  }

  
  updatePermission = (permissionsJson) => {
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + this.props.selectedRole.GroupId
    );

    let parms = {
      TenantId: crm.userInfo().pTenantId,
      UserAccess: JSON.stringify(permissionsJson),
    };
    restServices.patchRequest(url, parms, (response) => {
       console.log(response)
    });
  };

  //_json v2 convertion
  _json_v2_convert = (updateJson) => {
    let _newFormJson_ = permissionsv2;
    let accPermissions = ["read","add","update","delete","import","export"];
    let shortFormPermission = {"read":"r","add":"a","update":"u","delete":"d","import":"i","export":"e"};
    Object.keys(updateJson).map((parent) => {
        let ischildAvaliable = Object.keys(updateJson[parent])
        const output = ischildAvaliable.filter(function (obj) {
                return accPermissions.indexOf(obj) !== -1;
        });
        let isChild = output.length > 0 ? false : true;
        if(isChild){
           Object.keys(updateJson[parent]).map(child => {
            let _child_ = updateJson[parent][child];
            Object.keys(_child_).map((keyValue) => { 
               _newFormJson_[parent][child][shortFormPermission[keyValue]] = updateJson[parent][child][keyValue]
            })
           });
        }else{
          Object.keys(updateJson[parent]).map(child => {
           // console.log(parent);
           _newFormJson_[parent][shortFormPermission[child]] = updateJson[parent][child];
          })
        }
    });

   // console.log(_newFormJson_);
    this.updatePermission(_newFormJson_)
  }

  //_json v1 convertion
  _json_v1_convert = (CholaGetGroupsChildView) => {

    //newforate json
      let newformateJson = permissionsv1;
      //flow to update the json record
      let update = permissionsv1;
      //extract permission name
      let accPermissions = ["read","add","update","delete","import","export"];
      CholaGetGroupsChildView.map((parent) => {
        let _parent_ = parent.GroupDesc.toLowerCase();
        if(newformateJson[_parent_] !== undefined){
            let ischildAvaliable = Object.keys(newformateJson[_parent_])
              const output = ischildAvaliable.filter(function (obj) {
                      return accPermissions.indexOf(obj) !== -1;
              });
              let isChild = output.length > 0 ? false : true;
            if(isChild){
              parent.CholaGetGroupsChildView.map(childsecond => {
                let getChildName = childsecond.GroupDesc.toLowerCase();
                childsecond.CholaGetGroupsChildView.map(extractPermission => {
                  update[_parent_][getChildName][extractPermission.GroupDesc.toLowerCase()] = extractPermission.Active;
                });
              })
            }else{
              parent.CholaGetGroupsChildView.map(extractPermission => {
                update[_parent_][extractPermission.GroupDesc.toLowerCase()] = extractPermission.Active;
              });
            }
        }
       
      })
      this._json_v2_convert(update);
}



  /**
   * Get all child level permissions for selected roles
   */
  getRoles = () => {
    let headCells = [];

    if (jsonRole && jsonRole.module) {
      jsonRole.module.map((parent) => {
        //Check first level is module permissions
        if (parent.hasOwnProperty("name")) {
          this.addCellHeading(headCells, parent["permissions"]);
          parent["GroupId"] = 0;
          parent["GroupParentId"] = 0;
          parent["Active"] = "1";
        }
        //Check first level have childs
        if (parent.hasOwnProperty("child")) {
          parent["GroupId"] = 0;
          parent["GroupParentId"] = 0;
          parent["Active"] = "1";
          //loop child levels and get module permissions
          if (parent["subLevel"]) {
            parent["subLevel"].map((child) => {
              child["GroupId"] = 0;
              child["GroupParentId"] = 0;
              child["Active"] = "1";
              this.addCellHeading(headCells, child["permissions"]);
            });
          }
        }
      });

      this.setState({
        isLoaded: false,
        formSubmit: false,
        headCells: headCells,
        selectedRoleId: this.props.selectedRole.GroupId,
        roleList: jsonRole,
        //expandList: [],
      });

      //get role details from db
      this.getAllPermissions(this.props.selectedRole.GroupId);
    }
  };

  getAllPermissions = async (groupId) => {
    let filter = `?q=GroupId=${groupId}&expand=CholaGetGroupsChildView,CholaGetGroupsChildView.CholaGetGroupsChildView,CholaGetGroupsChildView.CholaGetGroupsChildView.CholaGetGroupsChildView&limit=10000&offset=0`;
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.CHOLA_GROUP_VIEW + filter
    );

    restServices.getRequest(
      url,
      async (response) => {
        if (response) {
          if (response && response.items) {
            let groupView = response.items[0].CholaGetGroupsChildView;
            let result = jsonRole.module;
            this._json_v1_convert(response.items[0].CholaGetGroupsChildView);
            if (groupView && groupView.length > 0) {
              const finalResult = await common.evaluateRolePermissions(
                groupView,
                result
              );
              let rolesList = { module: finalResult };
              console.log('rolesList :>> ', rolesList);
              this.setState({
                roleList: rolesList,
                isLoaded: true,
              });
            } else {
              this.setState({
                roleList: { module: result },
                isLoaded: true,
              });
            }
          }
        }
      },
      (error) => {}
    );
  };

  /**
   * Add table cell heading
   */
  addCellHeading = (cellHeading, addData) => {
    if (addData && addData.length > 0) {
      addData.map((data) => {
        data["GroupId"] = 0;
        data["GroupParentId"] = 0;
        data["Active"] = data["access"] == 'DELETE' ? "2" : "1";
        if (!cellHeading.includes(data.access)) {
          cellHeading.push(data.access);
        }
      });
    }
    return cellHeading;
  };

  /**
   * Handle expand/collapse list of modules
   */
  expandCollapse(module) {
    const { expandList } = this.state;
    if (expandList.includes(module)) {
      var filtered = expandList.filter(function (value, index, arr) {
        return value != module;
      });
      this.setState({ expandList: filtered });
    } else {
      expandList.push(module);
      this.setState({ expandList: expandList });
    }
  }

  /**
   * Update roles permissions
   */
  Update = () => {
    this.setState({ formSubmit: true }, () => {
      localStorage.setItem("updateLen", 0);

      const { roleList, selectedRoleId } = this.state;
      // loop the json parent level
      roleList.module.map((jsonParent) => {
        if (jsonParent.hasOwnProperty("name")) {
          //let isCreateParent = false;

          // jsonParent["permissions"].map((permissions) => {
          //     if (permissions.Active != null && permissions.GroupId === 0) {
          //         isCreateParent = true;
          //     }
          // })
          //if (isCreateParent && jsonParent.GroupId === 0) {
          if (jsonParent.GroupId === 0) {
            //create parent records
            let fields = {
              GroupName: tools.randomString(),
              GroupDesc: jsonParent["name"],
              GroupParentId: selectedRoleId,
              Active: "1",
              LdapSync: "Y",
              TenantId: crm.userInfo().pTenantId,
            };

            let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES);
            restServices.postRequest(
              url,
              fields,
              (response) => {
                if (response) {
                  jsonParent["GroupId"] = response.GroupId;
                  this.createPermissions(jsonParent, response.GroupId);
                }
              },
              (error) => {}
            );
          } else {
            this.createPermissions(jsonParent, jsonParent.GroupId);
          }
        }

        if (jsonParent.hasOwnProperty("child")) {
          //let isCreateParent = false;

          // jsonParent["subLevel"].map((child) => {
          //     child["permissions"].map((permissions) => {
          //         if (permissions.Active != null && permissions.GroupId === 0) {
          //             isCreateParent = true;

          //             return;
          //         }
          //     })
          //     if(isCreateParent){
          //         return;
          //     }
          // });

          //create top level parent (CRM, testing)
          //if(isCreateParent && jsonParent.GroupId === 0){
          if (jsonParent.GroupId === 0) {
            let fields = {
              GroupName: tools.randomString(),
              GroupDesc: jsonParent["child"],
              GroupParentId: selectedRoleId,
              Active: "1",
              LdapSync: "Y",
              TenantId: crm.userInfo().pTenantId,
            };

            let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES);
            restServices.postRequest(url, fields, (response) => {
              if (response) {
                jsonParent.GroupId = response.GroupId;
                jsonParent.Active = "Y";

                jsonParent["subLevel"].map((child) => {
                  //Assign GroupId to child level( GroupParentId )
                  child.GroupParentId = response.GroupId;

                  //Create parent records(ie. Campaigns, Enquiry)
                  if (child.GroupId === 0) {
                    let fields = {
                      GroupName: tools.randomString(),
                      GroupDesc: child["name"],
                      GroupParentId: response.GroupId,
                      Active: "1",
                      LdapSync: "Y",
                      TenantId: crm.userInfo().pTenantId,
                    };

                    let url = encodeURI(
                      envConfig.AUTH_REST_URL + Services.ROLES
                    );
                    restServices.postRequest(
                      url,
                      fields,
                      (response) => {
                        if (response) {
                          child["GroupId"] = response.GroupId;
                          this.createPermissions(child, child["GroupId"]);
                        }
                      },
                      (error) => {}
                    );
                  } else {
                    this.createPermissions(child, child["GroupId"]);
                  }
                });
              }
            });
          } else if (jsonParent.GroupId > 0) {
            jsonParent["subLevel"].map((child) => {
              //Create parent records(ie. Campaigns, Enquiry)
              if (child.GroupId === 0) {
                let fields = {
                  GroupName: tools.randomString(),
                  GroupDesc: child["name"],
                  GroupParentId: jsonParent.GroupId,
                  Active: "1",
                  LdapSync: "Y",
                  TenantId: crm.userInfo().pTenantId,
                };

                let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES);
                restServices.postRequest(
                  url,
                  fields,
                  (response) => {
                    if (response) {
                      child["GroupId"] = response.GroupId;
                      this.createPermissions(child, child["GroupId"]);
                    }
                  },
                  (error) => {}
                );
              } else {
                this.createPermissions(child, child["GroupId"]);
              }
            });
          }
        }
      });
    });
  };

  /**
   * create/update permissions
   */
  async createPermissions(jsonParent, GroupId) {
    let inputData = [];

    //create permissions
    jsonParent["permissions"].map((permissions, index) => {
      permissions.GroupParentId = GroupId;

      //if (permissions.Active != null && permissions.GroupId === 0) {
      if (permissions.GroupId === 0) {
        //create permissions
        let fields = {
          GroupName: tools.randomString(),
          GroupDesc: permissions.access,
          GroupParentId: permissions.GroupParentId,
          Active: permissions.Active,
          LdapSync: "Y",
          TenantId: crm.userInfo().pTenantId,
        };

        inputData.push({
          id: "part" + (index + 1),
          path: "/" + Services.ROLES,
          operation: "create",
          payload: fields,
        });
      } else if (permissions.GroupId > 0) {
        //update permissions
        let fields = {
          GroupDesc: permissions.access,
          GroupParentId: permissions.GroupParentId,
          Active: permissions.Active,
          LdapSync: "Y",
          TenantId: crm.userInfo().pTenantId,
        };

        inputData.push({
          id: "part" + (index + 1),
          path: "/" + Services.ROLES + "/" + permissions.GroupId,
          operation: "update",
          payload: fields,
        });
      }
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.AUTH_REST_URL);
    if (inputData.length > 0) {
      let updateLen = JSON.parse(localStorage.getItem("updateLen"));
      let updateLength = updateLen ? updateLen + 1 : 1;
      localStorage.setItem("updateLen", updateLength);

      restServices.batchRequest(
        url,
        formAttr,
        (response) => {
          let updateLen = JSON.parse(localStorage.getItem("updateLen"));
          let updateLength = updateLen - 1;
          localStorage.setItem("updateLen", updateLength);

          if (updateLength === 0) {
            setTimeout(() => {
              this.setState({ formSubmit: false });
              this.getRoles();
            }, 500);
          }
        },
        (error) => {
          common.snack("E", error);
        }
      );
    }

    let updateLength = JSON.parse(localStorage.getItem("updateLen"));

    if (updateLength === 0) {
      setTimeout(() => {
        this.setState({ formSubmit: false });
        this.getRoles();
      }, 500);
    }
  }

  /**
   * Handle to change input values and send to parent component
   */
  handleClick = (event, parent, child, permission) => {
    const { value } = event;
    //const { roleList } = this.state;
    let tempList = this.state.roleList;

    if (tempList && tempList.module) {
      tempList.module.forEach(function (parentObj, index) {
        if (parentObj.hasOwnProperty("name") && parentObj["name"] == parent) {
          parentObj["permissions"].forEach(function (permissionObj) {
            if (permissionObj.access === permission) {
              permissionObj.Active = value;
            }
          });
        }
        if (parentObj.hasOwnProperty("child") && parentObj["child"] == parent) {
          parentObj["subLevel"].forEach(function (childObj) {
            if (childObj["name"] === child) {
              childObj["permissions"].forEach(function (permissionObj) {
                if (permissionObj.access === permission) {
                  permissionObj.Active = value;
                }
              });
            }
          });
        }
      });
      this.setState({ roleList: tempList });
    }
  };

  render() {
    const { selectedRole } = this.props;
    const {
      headCells,
      roleList,
      isLoaded,
      formSubmit,
      userPermissions,
    } = this.state;
    const isChecked = (value) => {
      return value === null ? false : value ? true : false;
    };

    return (
      <div className="list-view-wrap bottom-set">
        <h2 className="crm-type-title">
          {selectedRole.GroupDesc ? selectedRole.GroupDesc : ""}
        </h2>
        {isLoaded && (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell key="module"> Module </TableCell>
                  {isLoaded &&
                    roleList &&
                    headCells &&
                    headCells.map((obj) => (
                      <TableCell key={obj}>{obj}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody key="tb_body">
                {isLoaded &&
                  roleList &&
                  roleList.module &&
                  roleList.module.map((parent, index) => {
                    return parent.hasOwnProperty("name") ? (
                      <TableRow key={"row_" + index}>
                        <TableCell className="module-name">
                          <span className="td-set">{parent["name"]}</span>
                        </TableCell>
                        {parent.hasOwnProperty("permissions") &&
                          headCells &&
                          headCells.map((headCell) => {
                            const permissions = parent["permissions"];
                            return (
                              <TableCell key={parent["name"] + "_" + headCell}>
                                {permissions.map((access, index) => {
                                  return access.access === headCell ? (
                                    <Form.Group
                                      key={parent["name"] + "_" + headCell}
                                    >
                                      <Select
                                              key={parent["name"] +
                                              "_" +
                                              headCell +
                                              index}
                                              value={envConfig.allPermissions.find(
                                                (o) => o.value == access.Active
                                              )}
                                              onChange={(e) =>
                                                this.handleClick(
                                                  e,
                                                  parent["name"],
                                                  null,
                                                  headCell
                                                )
                                              }
                                              name={
                                                parent["name"] + "_" + headCell
                                              }
                                              options={envConfig.permissions}
                                            />
                                      {/* <FormControlLabel
                                        className="d-flex justify-content-between"
                                        control={
                                          <Switch 
                                            key={
                                              parent["name"] +
                                              "_" +
                                              headCell +
                                              index
                                            }
                                            checked={isChecked(access.Active)}
                                            onChange={(e) =>
                                              this.handleClick(
                                                e,
                                                parent["name"],
                                                null,
                                                headCell
                                              )
                                            }
                                            name={
                                              parent["name"] + "_" + headCell
                                            }
                                            color="primary"
                                          />
                                        }
                                      /> */}
                                    </Form.Group>
                                  ) : null;
                                })}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    ) : (
                      this.renderChild(parent, headCells, parent["child"])
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isLoaded && roleList && (
          <Row className="bottomBtn">
            <Col sm={12}>
              {userPermissions.update && (
                <Button
                  disabled={formSubmit}
                  className="saveBtn"
                  type="button"
                  onClick={this.Update}
                >
                  {!formSubmit ? "Update" : "Updating..."}
                </Button>
              )}
            </Col>
          </Row>
        )}
        {!isLoaded && <Loader />}
        {/* {formSubmit && <div className="updateImg">
                    <img src={saveLoader} alt="updating..." />
                </div>} */}
      </div>
    );
  }

  /**
   * Handle child level
   */
  renderChild = (parent, headCells, childName) => {
    const { expandList } = this.state;
    const isChecked = (value) => {
      return value === null ? false : value ? true : false;
    };

    const allowFullAccess = (module) => {
      return (
        ["Lead", "Deal", "Quote", "Salesorder", "Invoice"].indexOf(module) > -1
      );
    };

    return Object.keys(parent).map((key, index) =>
      key == "child" ? (
        <TableRow key={"row_" + index}>
          <TableCell className="module-name">
            <Link
              to="#"
              onClick={() => {
                this.expandCollapse(parent["child"]);
              }}
              style={{ marginRight: 15, position: "relative", top: -1 }}
            >
              <img
                src={
                  expandList.includes(parent["child"])
                    ? collapseIcon
                    : expandIcon
                }
                alt="expand"
              />
            </Link>
            <span className="module-name">{parent["child"]}</span>
          </TableCell>
          <TableCell colSpan={headCells.length}></TableCell>
        </TableRow>
      ) : (
        key === "subLevel" &&
        parent["child"] === childName &&
        parent["subLevel"].map((child, index) => {
          return (
            <TableRow
              className={expandList.includes(parent["child"]) ? "" : "hideCell"}
              key={parent["subLevel"] + "_" + child["name"]}
            >
              <TableCell style={{ paddingLeft: 60 }}>{child["name"]}</TableCell>
              {child.hasOwnProperty("permissions") &&
                headCells &&
                headCells.map((headCell) => {
                  const permissions = child["permissions"];
                  return (
                    <TableCell key={child["name"] + "_" + headCell}>
                      {permissions.map((access, index) => {
                        return access.access === headCell ? (
                          <Form.Group key={child["name"] + "_" + headCell}>
                            <Select
                                              key={parent["name"] + "_" + headCell + index}
                                              value={envConfig.allPermissions.find(
                                                (o) => o.value == access.Active
                                              )}
                                              onChange={(e) =>
                                              this.handleClick(
                                                e,
                                                parent["child"],
                                                child["name"],
                                                headCell
                                              )
                                            }
                                            name={child["name"] + "_" + headCell}
                                            options={
                                              allowFullAccess(child["name"])
                                                ? envConfig.allPermissions
                                                : envConfig.permissions
                                            }
                                            />
                            {/* <FormControlLabel
                              className="d-flex justify-content-between"
                              control={
                                <Switch
                                  key={parent["name"] + "_" + headCell + index}
                                  checked={isChecked(access.Active)}
                                  onChange={(e) =>
                                    this.handleClick(
                                      e,
                                      parent["child"],
                                      child["name"],
                                      headCell
                                    )
                                  }
                                  name={child["name"] + "_" + headCell}
                                  color="primary"
                                />
                              }
                            /> */}
                          </Form.Group>
                        ) : null;
                      })}
                    </TableCell>
                  );
                })}
            </TableRow>
          );
        })
      )
    );
  };
}

export default AddRolePermissions;
