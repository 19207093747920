import React from "react";
import Paper from "material-ui/Paper";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
// switch
import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// Import redux packages
import { connect } from "react-redux";
import { commonActions } from "_actions/common.actions";

// Import config and helpers
import { common, restServices } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";

//Import CSS
import "../css/Campaign.css";

// Import internal components
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class CampaignDetails extends React.Component {
  constructor(props) {
    super(props);
    /**t
     * layoutFormat => Get form layout form API resources
     * jsonAttributes => Have stored all input type json properties
     * => USAGE: Reduce looping system have created ( jsonAttributes ) object
     * fieldConfig => Have stored all input key configuration
     */
    this.state = {
      campaignId: 0,
      campaignData: {},
      crmStatusList: [],
      layoutFormat: {},
      jsonAttributes: {},
      returnQuery: "",
      fieldConfig: {
        CampaignId: {
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        ParentCampaignId: {
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        CampaignOwnerId: {
          getOne:
            envConfig.BASE_API + Services.CRM_EMPLOYEES + "/EMPLOYEE_NAME",
          get: "EmployeeId",
          set: "EmployeeName",
          searchKey: "EMPLOYEE_NAME",
        },
      },
      userPermissions: common.getPermission(this.props, "crm:campaigns"),
    };

    this.state.returnQuery = common.getCrmReturnQuery();
  }

  /**
   * Hooks Methods
   */
  componentDidMount() {
    this.setState(
      {
        campaignId: this.props.match.params.campaignId,
      },
      (_) => {
        if (this.state.campaignId != null) {
          this.getFormJSON();
        }
      }
    );
    this.getCRMStatusList();
  }

  // MAR: API Methods
  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_CAMPAIGN_CODE;
    this.setState({
      isLoading: true,
    });
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          this.setState(
            {
              layoutFormat: JSON.parse(result.FormFields),
              isLoading: false,
            },
            () => {
              this.getCampaignDetail(this.state.campaignId);
              this.createDynamicFormFields();
            }
          );
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  /**
   * Get each dynamic field API details and bind value to json attributes
   */
  getDetail = (url, name) => {
    const { fieldConfig, jsonAttributes } = this.state;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          let config = fieldConfig[name];

          if (jsonAttributes[name]) {
            jsonAttributes[name].value = response[config.set];
            this.setState({
              jsonAttributes: jsonAttributes,
            });
          }

        }
      },
      (error) => { }
    );
  };

  /**
   * Get Campaign Details by ID
   */
  getCampaignDetail = (id) => {
    const { jsonAttributes, fieldConfig } = this.state;
    let url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response) {
            for (var key of Object.keys(response)) {
              if (response[key]) {
                if (jsonAttributes[key] !== undefined) {
                  jsonAttributes[key].value = response[key];
                }
                if (fieldConfig[key]) {
                  if (fieldConfig[key].hasOwnProperty("getOne")) {
                    fieldConfig[key].getOne = fieldConfig[key].getOne.replace(
                      fieldConfig[key].searchKey,
                      response[key]
                    );
                    this.getDetail(fieldConfig[key].getOne, key);
                  }
                }
              }
            }
            this.setState({
              campaignData: response,
              jsonAttributes: jsonAttributes,
              fieldConfig: fieldConfig,
            });
          }
        }
      },
      (error) => {
        this.setState({
          campaignData: {},
        });
      }
    );
  };

  /**
   * Assign default value based on input type
   * @param {*} type
   */
  getDefaultValueByType = (type) => {
    switch (type) {
      case "integer":
        return 0;
      case "string":
        return "";
      default:
        return null;
    }
  };

  /**
   * Create dynamic form through given json attributes
   */
  createDynamicFormFields = () => {
    const { layoutFormat, jsonAttributes } = this.state;

    if (
      layoutFormat &&
      layoutFormat.columns &&
      layoutFormat.columns.length > 0
    ) {
      layoutFormat.columns.forEach((column) => {
        column.groups.forEach((column) => {
          column.rows.forEach((row) => {
            row.columns.forEach((rowColum) => {
              jsonAttributes[rowColum.key] = rowColum;
              if (rowColum.associate != undefined) {
                jsonAttributes[rowColum.associate.key] = rowColum.associate;
              }
            });
          });
        });
      });

      this.setState({
        jsonAttributes: jsonAttributes,
      });
    }
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let campaignTypeId = common.getTransactionTypeId(envConfig.CAMPAIGN_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMCampaignStatus(campaignTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmCampaignStatus } = props;

    var crmStatusData = state.crmStatusList;

    if (
      crmCampaignStatus &&
      crmCampaignStatus.list &&
      crmCampaignStatus.list.success
    ) {
      crmStatusData = crmCampaignStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  /**
   * Load Campaign status position based on filtered index
   */
  getStatusPosition = () => {
    const { campaignData, crmStatusList } = this.state;
    if (
      campaignData.CampaignStatus &&
      campaignData.CampaignStatus != "" &&
      campaignData.CampaignStatus != null
    ) {
      var position = crmStatusList.findIndex(
        (x) => x.StatusCode == campaignData.CampaignStatus
      );
      return position >= 0 ? position : -1;
    }
  };

  /**
   * Load block of code or component based on the input key
   */
  renderBlock = (attr) => {
    let userData = common.authInfo();
    let role = userData.roleId || 0;
    let access = "";
    let permissions = attr.permission ? attr.permission : [];

    permissions.map((obj) => {
      if (obj.role == role) {
        access = obj.access;
      }
    });
    if (access != "DontShow") {
      switch (attr.input) {
        case "check_box":
          return (
            <Form.Group>
              <FormControlLabel
                className="d-flex justify-content-between margin-none"
                control={
                  <Switch
                    checked={attr.value == "Y" ? true : false}
                    name={attr.name}
                    color="primary"
                    onChange={() => { return false; }}
                  />
                }
                label={attr.name}
                labelPlacement="start"
              />
            </Form.Group>
          );
        default:
          if (
            attr.associate != undefined &&
            attr.value != null &&
            attr.value != ""
          ) {
            return (
              <div>
                <label className="form-label">{attr.name}</label>
                <p className="form-value">
                  {attr.type === "datetime"
                    ? attr.value
                      ? common.formatDate(attr.value, "DD/MM/YYYY hh:mm a")
                      : "-"
                    : attr.value
                      ? attr.value
                      : "-"}
                </p>
                <p>{attr.associate.value}</p>
              </div>
            );
          } else {
            return (
              <div>
                <label className="form-label">{attr.name}</label>
                <p className="form-value">
                  {attr.type === "datetime"
                    ? attr.value
                      ? common.formatDate(attr.value, "DD/MM/YYYY hh:mm a")
                      : "-"
                    : attr.value
                      ? attr.value
                      : "-"}
                </p>
              </div>
            );
          }
      }
    }
  };

  render() {
    const { isLoading, campaignId, crmStatusList, layoutFormat, userPermissions } = this.state;
    let update = userPermissions.update;
    let read = userPermissions.read;
    return (
      <div>
        {!read && (
          <AccessDenied />
        )}
        {read && isLoading == false && (
          <div className="lead-wraper">
            <div className="formTab">
              <ul>
                {crmStatusList.map((data, idx) => (
                  <li
                    key={idx}
                    className={`tabShape whiteTab ${this.getStatusPosition() >= idx ? "active" : ""
                      }`}
                  >
                    {" "}
                    {/*  style={{backgroundColor: data.StatusColor}} */}
                    <span className="tri-right"></span>{" "}
                    {/*  style={{borderBottomColor: data.StatusColor}} */}
                    <Badge badgeContent={++idx} color="primary">
                      {data.StatusName}
                    </Badge>
                  </li>
                ))}
              </ul>
            </div>

            <div className="row">
              {layoutFormat.columns &&
                layoutFormat.columns.length > 0 &&
                layoutFormat.columns.map((pcoloum, pindex) => (
                  <div className="col" key={pindex}>
                    {pcoloum.groups.map((group, gindex) => (
                      <Paper style={styles.boxLayout} key={gindex}>
                        <div className="box-title-wrap justify-content-between">
                          <h3 className="card-title">{group.title}</h3>
                          {update && (
                            <Link
                              className="card-link"
                              to={
                                "/crm/campaign/edit/" +
                                campaignId + this.state.returnQuery
                              }
                            >
                              Edit
                            </Link>
                          )}
                        </div>
                        {group.rows.map((row, rindex) => (
                          <div
                            className={
                              row.noOfColumns == 1 ? "list-row" : "row"
                            }
                            key={rindex}
                          >
                            {row.columns.map((rcoloum, rcindex) => (
                              common.checkFieldPermission(rcoloum) && (
                                <div
                                  className={
                                    1 == row.noOfColumns
                                      ? ``
                                      : `col-${12 / row.noOfColumns}`
                                  }
                                  key={rcindex}
                                >
                                  <div
                                    className={
                                      1 == row.noOfColumns ? `` : `list-row`
                                    }
                                  >
                                    {this.renderBlock(rcoloum)}
                                  </div>
                                </div>
                              )
                            ))}
                          </div>
                        ))}
                      </Paper>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        )}
        {read && isLoading == true && <Loader />}
      </div>
    );

  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

const mapStateToProps = (state) => {
  const { crmCampaignStatus } = state;
  return { crmCampaignStatus };
};
export default connect(mapStateToProps)(CampaignDetails);
