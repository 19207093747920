import React from "react";
// meterial
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
// component
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tableConfig, restServices } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class ResponsibleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: "",
      listRes: [],
      isLoading: false,
      currentPage: 1,
      pageLimit: 1000,
      tlConfig: {
        headCells: [
          { id: "Responsibilities", value: "Responsibilities", label: "RESPONSIBILITY" },
          { id: "Area", value: "Area", label: "AREA" },
          { id: "Shift", value: "Shift", label: "SHIFT" },
          { id: "CarryForward", value: "CarryForward", label: "CARRY FORWARD" },
          { id: "ReportingFreq", value: "ReportingFreq", label: "REPORTING FREQUENCY" },
          { id: "Duration", value: "Duration", label: "DURATION" },
          { id: "Action", value: "Action", label: "ACTIONS" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "SiteName",
        selected: [],
        uniqueKey: "ResponsibiltyColumns",
      },
      filterName: "",
      userPermissions: this.props.userPermissions,
    };
  }

  // parent call
  onGetList(roleId) {
    this.setState({
      roleId,
      isLoading: true
    }, () => {
      this.getList();
    })
  }

  // api
  getList = () => {
    const { tlConfig, roleId } = this.state;
    this.setState({ isLoading: true });
    let filter = `?q=RoleHdrId=${roleId}`;

    if (tlConfig.OrderBy) {
      filter += "&orderBy=" + tlConfig.OrderBy;
    }
    let url = envConfig.BASE_API + Services.CRM_ROLES_DTL + filter;
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          listRes: response.items,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          listRes: [],
          isLoading: false,
        });
      }
    );
  };

  // support
  updateTLConfig = (key, value, object) => {
    const { tlConfig } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }
    }
  };

  // pagination
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      isLoading,
      tlConfig,
      listRes,
      userPermissions,
    } = this.state;

    const isHided = (ListDetailId) => tlConfig.columnsSelected.indexOf(ListDetailId) !== -1;

    return !userPermissions.access ? (
      <AccessDenied />
    ) : (
        <div className="lead-wraper">
          {/* end of Secondary Nav */}
          <div className="filter-wrap">
            <div className="row justify-content-between">
              <div className="col-12 col-lg-3">
                <div className="form-row">
                  <div className="col">
                    <h2 className="crm-type-title">Responsibilities</h2>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="form-row justify-content-end">
                  <div className="col-lg-3">
                    <EnhancedTableColumns
                      tlConfig={tlConfig}
                      updateTLConfig={this.updateTLConfig}
                    />
                  </div>
                  {userPermissions.add && (
                    <div className="col-auto">
                      <button type="button" className="btn btn-add" onClick={() => this.props.onAddResponsible()}>
                        Add
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end of Filter Wrap */}
          <div className="crm-data-wrap">
            <div className="clearfix"></div>
            <div className="list-view-wrap">
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <EnhancedTableHead
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                  <TableBody>
                    {isLoading == false &&
                      listRes &&
                      listRes.length > 0 &&
                      tableConfig
                        .stableSort(
                          listRes,
                          tableConfig.getComparator(
                            tlConfig.order,
                            tlConfig.orderBy
                          )
                        )
                        .map((res, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell className={!isHided("Responsibilities") ? "hideCell" : ""}>{res.Responsibilities}</TableCell>
                              <TableCell className={!isHided("Area") ? "hideCell" : ""}>{res.Area}</TableCell>
                              <TableCell className={!isHided("Shift") ? "hideCell" : ""}>{res.Shift}</TableCell>
                              <TableCell className={!isHided("CarryForward") ? "hideCell" : ""}>{res.CarryForward === "Y" ? "Yes" : "No"}</TableCell>
                              <TableCell className={!isHided("ReportingFreq") ? "hideCell" : ""}>{res.ReportingFreq}</TableCell>
                              <TableCell className={!isHided("Duration") ? "hideCell" : ""}>{res.Duration} Days</TableCell>
                              <TableCell className={!isHided("Action") ? "hideCell" : ""}><img src={common.loadImg("delete.svg")} style={{ cursor: "pointer" }} onClick={() => this.props.onDelete(res.RoleDtlId)} /></TableCell>
                            </TableRow>
                          );
                        })}
                    {listRes &&
                      listRes.length == 0 &&
                      isLoading == false && (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <NoRecordFound />
                          </TableCell>
                        </TableRow>
                      )}
                    {isLoading == true && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          {/* end of Switch View Control */}
        </div>
      );
  }
}

export default ResponsibleList;