import React from "react";
import { common } from "_helpers";
import { Row, Col } from "react-bootstrap";
import "../css/History.css";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

// Images
import userIcon from "img/work/user.png";
import timeLineUserIcon from "img/work/timline_user.png";

class History extends React.Component {
  constructor() {
    super();
    this.state = {
      history: {},
    };
  }

  componentDidMount() {
    if (this.props.campaign != undefined) {
      this.setState({
        history: this.props.campaign,
      });
    }
  }

  render() {
    const { history } = this.state;
    return (
      <div>
        <div className="activity">
          <h1>History</h1>
          {history.CreatedOn === null && history.ModifiedOn === null && (
            <NoRecordFound />
          )}
          {history.ModifiedOn !== null && (
            <div>
              <Row>
                <Col sm={1} className="dot-top">
                  <span className="dot">.</span>
                </Col>
                <Col sm={11}>
                  <h2>{common.formatDate(history.ModifiedOn, "MMMM YYYY")}</h2>
                </Col>
              </Row>

              <Row>
                <Col sm={1} className="side-border">
                  <img src={timeLineUserIcon} />
                </Col>
                <Col sm={11} className="activity-details">
                  <Row>
                    <Col sm={1}>
                      <img src={userIcon} />
                    </Col>
                    <Col sm={11}>
                      <p>
                        Modified By <span className="text-bold">{history.ModifiedBy}</span>
                      </p>
                      <h5>Modified On <span className="text-bold">
                          {common.formatDateAMPM(history.ModifiedOn, "DD/MM/YYYY", false)}
                        </span>
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
          {history.CreatedOn !== null && (
            <div>
              <Row>
                <Col sm={1} className="dot-top">
                  <span className="dot">.</span>
                </Col>
                <Col sm={11}>
                  <h2>{common.formatDate(history.CreatedOn, "MMMM YYYY")}</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={1} className="side-border">
                  <img src={timeLineUserIcon} />
                </Col>
                <Col sm={11} className="activity-details">
                  <Row>
                    <Col sm={1}>
                      <img src={userIcon} />
                    </Col>
                    <Col sm={11}>
                      <p>
                        Created By <span className="text-bold">{history.CreatedBy}</span>
                      </p>
                      <h5>Created On <span className="text-bold">{common.formatDateAMPM(history.CreatedOn, "DD/MM/YYYY", false)}</span></h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div >
    );
  }
}

//export { History };
export default History;
