export const commonConstants = {
    CRM_STATUS_GET_ALL_REQUEST: 'CRM_STATUS_GET_ALL_REQUEST',
    CRM_STATUS_GET_ALL_SUCCESS: 'CRM_STATUS_GET_ALL_SUCCESS',
    CRM_STATUS_GET_ALL_RESET: 'CRM_STATUS_GET_ALL_RESET',
    CRM_STATUS_GET_ALL_ERROR: 'CRM_STATUS_GET_ALL_ERROR',

    CRM_STATUS_GET_ALL_CAMPAIGN_REQUEST: 'CRM_STATUS_GET_ALL_CAMPAIGN_REQUEST',
    CRM_STATUS_GET_ALL_CAMPAIGN_SUCCESS: 'CRM_STATUS_GET_ALL_CAMPAIGN_SUCCESS',
    CRM_STATUS_GET_ALL_CAMPAIGN_RESET: 'CRM_STATUS_GET_ALL_CAMPAIGN_RESET',
    CRM_STATUS_GET_ALL_CAMPAIGN_ERROR: 'CRM_STATUS_GET_ALL_CAMPAIGN_ERROR',

    CRM_STATUS_GET_ALL_ENQUIRY_REQUEST: 'CRM_STATUS_GET_ALL_ENQUIRY_REQUEST',
    CRM_STATUS_GET_ALL_ENQUIRY_SUCCESS: 'CRM_STATUS_GET_ALL_ENQUIRY_SUCCESS',
    CRM_STATUS_GET_ALL_ENQUIRY_RESET: 'CRM_STATUS_GET_ALL_ENQUIRY_RESET',
    CRM_STATUS_GET_ALL_ENQUIRY_ERROR: 'CRM_STATUS_GET_ALL_ENQUIRY_ERROR',

    CRM_STATUS_GET_ALL_DEAL_REQUEST: 'CRM_STATUS_GET_ALL_DEAL_REQUEST',
    CRM_STATUS_GET_ALL_DEAL_SUCCESS: 'CRM_STATUS_GET_ALL_DEAL_SUCCESS',
    CRM_STATUS_GET_ALL_DEAL_RESET: 'CRM_STATUS_GET_ALL_DEAL_RESET',
    CRM_STATUS_GET_ALL_DEAL_ERROR: 'CRM_STATUS_GET_ALL_DEAL_ERROR',

    CRM_STATUS_GET_ALL_QUOTE_REQUEST: 'CRM_STATUS_GET_ALL_QUOTE_REQUEST',
    CRM_STATUS_GET_ALL_QUOTE_SUCCESS: 'CRM_STATUS_GET_ALL_QUOTE_SUCCESS',
    CRM_STATUS_GET_ALL_QUOTE_RESET: 'CRM_STATUS_GET_ALL_QUOTE_RESET',
    CRM_STATUS_GET_ALL_QUOTE_ERROR: 'CRM_STATUS_GET_ALL_QUOTE_ERROR',

    CRM_STATUS_GET_ALL_ORDER_REQUEST: 'CRM_STATUS_GET_ALL_ORDER_REQUEST',
    CRM_STATUS_GET_ALL_ORDER_SUCCESS: 'CRM_STATUS_GET_ALL_ORDER_SUCCESS',
    CRM_STATUS_GET_ALL_ORDER_RESET: 'CRM_STATUS_GET_ALL_ORDER_RESET',
    CRM_STATUS_GET_ALL_ORDER_ERROR: 'CRM_STATUS_GET_ALL_ORDER_ERROR',

    CRM_STATUS_GET_ALL_INVOICE_REQUEST: 'CRM_STATUS_GET_ALL_INVOICE_REQUEST',
    CRM_STATUS_GET_ALL_INVOICE_SUCCESS: 'CRM_STATUS_GET_ALL_INVOICE_SUCCESS',
    CRM_STATUS_GET_ALL_INVOICE_RESET: 'CRM_STATUS_GET_ALL_INVOICE_RESET',
    CRM_STATUS_GET_ALL_INVOICE_ERROR: 'CRM_STATUS_GET_ALL_INVOICE_ERROR',

    CRM_ROLES_PERMISSIONS_REQUEST: 'CRM_ROLES_PERMISSIONS_REQUEST',
    CRM_ROLES_PERMISSIONS_SUCCESS: 'CRM_ROLES_PERMISSIONS_SUCCESS',
    CRM_ROLES_PERMISSIONS_RESET: 'CRM_ROLES_PERMISSIONS_RESET',
    CRM_ROLES_PERMISSIONS_ERROR: 'CRM_ROLES_PERMISSIONS_ERROR',
}