import React from "react";
import { connect } from "react-redux";

import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { common } from "_helpers";
import FormSetting from "./FormSetting";
import { restServices } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// includes
import { crm } from '_utils';
class LeadFormSetting extends React.Component {
  constructor(props) {
    super(props);
    let transitionTypeId = localStorage.getItem("serviceCode");
    this.state = {
      layoutFormat: {},
      serviceId: 0,
      serviceCode:
        transitionTypeId !== undefined && transitionTypeId > 0
          ? parseInt(transitionTypeId)
          : envConfig.ESERVICE_CAMPAIGN_CODE,
      roleData: [],
      userPermissions: common.getPermission(
        this.props,
        "configure:form settings"
      ),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.getFormJSON();
    this.getAllParentRoles();
  }

  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    const { serviceCode } = this.state;
    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + "?q=EserviceCode=" + serviceCode;
    //envConfig.ESERVICE_LEAD_CODE;
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          this.setState({
            layoutFormat: JSON.parse(result.FormFields),
            serviceId: result.EserviceId
          });
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  updateId = (value) => {
    this.setState(
      {
        serviceCode: value,
      },
      () => {
        this.getFormJSON();
      }
    );
  };

  updateFormData = (layoutFormat) => {
    const { serviceId } = this.state;
    const newFields = {
      FormFields: JSON.stringify(layoutFormat),
    };

    let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + "/" + serviceId;
    restServices.patchRequest(
      url,
      newFields,
      (response) => {
        common.snack("S", "Form fields saved successfully");
        //history.push("/formSetting/lead");
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };

  /**
   * Get all roles
   */
  getAllParentRoles() {
    let roleData = [];

    let filter = `?q=GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_2
      }','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${crm.userInfo().pTenantId}&limit=1000&offset=0&finder=FindParentGroups&orderBy=CreatedOn:desc`;

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          roleData = common.getOptionsData(
            response.items,
            "GroupDesc",
            "GroupId"
          );

          this.setState({
            roleData: roleData,
          });
        }
      },
      (error) => { }
    );
  }

  render() {
    const { layoutFormat, roleData, userPermissions } = this.state;
    return !userPermissions.access ? (
      <AccessDenied />
    ) : (
      <FormSetting
        layoutFormat={layoutFormat}
        updateFormData={this.updateFormData}
        updateId={this.updateId}
        roleData={roleData}
        userPermissions={userPermissions}
      // moduleType={moduleType}
      />
    );
  }
}
//export default FormSetting;
const mapStateToProps = (state) => {
  const { alert } = state;
  return { alert };
};
export default connect(mapStateToProps)(LeadFormSetting);
