import React, { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// json
import lookupList from "crm/files/lookupList.json";

const AutomationSelect = forwardRef((props, ref) => {
  const { field, value } = props;
  const lookup = lookupList[field.attr.lookup];
  const [mount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [search, setSearch] = useState("");
  const [userInfo] = useState(crm.userInfo());
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  var delayTimer;

  // effect
  useEffect(() => {
    if (isRender) {
      loadData();
    }
  }, [isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  useEffect(() => {
    if (mount) {
      setIsLoading(true);
      setResultList([]);
      loadData();
    }
  }, [field]);

  // support
  const getUrl = (type) => {
    let url = `${envConfig.BASE_API}${Services[lookup.name]}`;
    let fields = `?fields=${lookup.get},${lookup.set}`;
    let trasaction = `&q=${lookup.name == "TRANSACTION_STATUS" ? `TransactionTypeId=${common.getTransactionTypeId(envConfig[field.attr.data1])} AND Active='Y' AND ` : ""}`;
    let vTenant = `TenantId=${userInfo.pTenantId}`;
    let search = ` AND UPPER(${lookup.get}) LIKE '*{SEARCH}*'`;
    let orderBylimit = `&orderBy=${lookup.get}:asc&limit=${limit}&offset=${type === "search" ? 0 : (offset * limit)}&totalResults=true`;
    let query = "";
    return (url + fields + trasaction + vTenant + search + query + orderBylimit);
  }

  // api
  const loadData = () => {
    let url = getUrl("load").replace("{SEARCH}", "");
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let data = common.getOptionsOnlyData(response.items, lookup.get, lookup.set, true);
          setResultList(oldData => {
            return [...new Set([...oldData, ...data])];
          })
          setMount(true);
          setIsLoadingMore(false);
          setHasMore(response.hasMore);
          if (!props.isRender) {
            setIsMenuOpen(true);
            setIsFocus(true);
          }
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    )
  }

  // load
  const loadOptions = async (inputValue) => {
    var url = getUrl("search").replace("{SEARCH}", inputValue);
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setSearch(inputValue);
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(response.items, lookup.get, lookup.set, true)
              resolve(data);
            } else {
              resolve([])
            }
          },
          (error) => {
            resolve([]);
          }
        )
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = value => {
    props.onSelect(value);
  }

  const onInputChange = value => {
    if (value === "") {
      setSearch("");
    }
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  const loadMore = () => {
    if (hasMore && !search && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset(prev => ++prev);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <AsyncSelect
        ref={ref}
        isMulti={props.isMulti}
        isClearable={true}
        value={value}
        defaultOptions={resultList}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        onInputChange={onInputChange}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
        onMenuScrollToBottom={() => loadMore()}
        isLoading={isLoadingMore}
        isDisabled={props.disabled}
      />
    )
  );
})

export default AutomationSelect;