import React, { useState, useEffect } from "react";
// Import permission json file
import jsonPermission from "json/permission.json";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Form, Col, Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { restServices } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
// Import Loader
import Loader from "_components/_loader/Loader";
// //Import Images
import expandIcon from "img/expand.png";
import collapseIcon from "img/collapse.png";
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});


export default function ViewEditPermission({
  GroupId,
  userPermissions,
  TenantId,
}) {
  const [permission,setPermission] = useState(jsonPermission);
  const [accPermissions, setAccPermissions] = useState([
    "r",
    "a",
    "u",
    "d",
    "i",
    "e",
  ]);


  const [accPermissionsName, setAccPermissionsName] = useState([
    "read",
    "add",
    "update",
    "delete",
    "import",
    "export",
  ]);

  const [expandList, setExpandList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateLoader, setUpdateLoader] = useState(false);
  
  useEffect(() => {
  
    _get_groupid_permission(GroupId);
    setPermission(jsonPermission)
  }, [GroupId]);


  const _get_groupid_permission = (GroupId) => {
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + GroupId
    );
    setLoading(true);
    restServices.getRequest(url, async (response) => {
      if (response) {
        if (response.UserAccess !== null) {
          let parseStringify = JSON.parse(response.UserAccess);
          setPermission(parseStringify)
          setLoading(false);
        } else {
          setPermission(jsonPermission)
          setLoading(false);
        }
      }
    });
  };


  const collapse = (data) => {
    let _expandList = expandList;
    if (_expandList.indexOf(data) !== -1) {
      _expandList = _expandList.filter((item) => item !== data);
      setExpandList(_expandList);
    } else {
      _expandList.push(data);
      setExpandList(_expandList);
    }
  };

  const handleChange = (
    event,
    isparent,
    parentname,
    access,
    _permission,
    child
  ) => {
    let { value } = event;
    let tempList = JSON.parse(JSON.stringify(permission));
    if (isparent) {
      tempList[parentname][access] = _permission;
      setPermission(tempList)
    } else {
      tempList[parentname][child][access] = _permission;
      setPermission(tempList);
    }
  };


  const updatePermission = () => {
    setUpdateLoader(true);
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + GroupId
    );

    let parms = {
      TenantId: TenantId,
      UserAccess: JSON.stringify(permission),
    };
    restServices.patchRequest(url, parms, (response) => {
      setPermission(JSON.parse(response.UserAccess))
      setUpdateLoader(false);
    });
  };


  return (
    <div className="list-view-wrap bottom-set">
      <h2 className="crm-type-title">Title</h2>
      {loading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Module</TableCell>
                {accPermissionsName.map((parent, parentIndex) => {
                  return <TableCell key={parentIndex}>{parent}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            {Object.keys(permission).length ? (
              <TableBody key="tb_body">
                {Object.keys(permission).map((module, mIdx) => {
                  let child = Object.keys(permission[module]);
                  const output = child.filter(function (obj) {
                    return accPermissions.indexOf(obj) !== -1;
                  });
                  let isChild = output.length > 0 ? false : true;
                  return !isChild ? (
                    <TableRow>
                      <TableCell key={mIdx}>
                        <span className="td-set">{module}</span>
                      </TableCell>
                      {Object.keys(permission[module]).map((access, aIndex) => {
                        return (
                          <TableCell key={aIndex}>
                            <Form.Group>
                              <Select
                                key={module + "_" + access}
                                options={envConfig.permissions}
                                value={envConfig.permissions.find(
                                  (o) => o.value == permission[module][access]
                                )}
                                onChange={(e) =>
                                  handleChange(e, 1, module, access, e.value, 0)
                                }
                                name={module + "_" + access}
                              />
                            </Form.Group>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      <TableRow>
                        <TableCell className="module-name">
                          <Link
                            to="#"
                            style={{
                              marginRight: 15,
                              position: "relative",
                              top: -1,
                            }}
                            onClick={() => collapse(module)}
                          >
                            <img
                              src={
                                expandList.indexOf(module) !== -1
                                  ? collapseIcon
                                  : expandIcon
                              }
                              alt="expand"
                            />
                          </Link>
                          <span className="module-name">{module}</span>
                        </TableCell>
                      </TableRow>
                      {Object.keys(permission[module]).map((child, cIdx) => {
                        return (
                          <TableRow
                            id={module}
                            className={
                              expandList.indexOf(module) !== -1
                                ? ""
                                : "hideCell"
                            }
                          >
                            <TableCell key={cIdx} className="module-name ">
                              <span className="td-set">{child}</span>
                            </TableCell>
                            {Object.keys(permission[module][child]).map(
                              (access, aIndex) => {
                                return (
                                  <TableCell key={aIndex}>
                                    <Form.Group>
                                      <Select
                                        key={
                                          module + "_" + child + "_" + access
                                        }
                                        options={
                                          child == "lead" || child == "deal"
                                            ? envConfig.allPermissions
                                            : envConfig.permissions
                                        }
                                        value={envConfig.allPermissions.find(
                                          (o) =>
                                            o.value ==
                                            permission[module][child][access]
                                        )}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            0,
                                            module,
                                            access,
                                            e.value,
                                            child
                                          )
                                        }
                                        name={
                                          module + "_" + child + "_" + access
                                        }
                                      />
                                    </Form.Group>
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      )}
      {loading ? (
        <Loader />
      ) : (
        <Row className="bottomBtn">
          <Col sm={12}>
            {userPermissions.update && (
              <Button
                className="saveBtn"
                type="button"
                onClick={updatePermission}
              >
                {!updateLoader ? "Update" : "Updating..."}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}