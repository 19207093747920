import React from "react";
// includes
import { history } from "_helpers";
import DynamicForm from "crm/DynamicForm";
// css
import "./AddContact.css";
class AddContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      isLoading: true,
    };
  }

  saveCallback = (res) => {
    history.push("/crm/contact");
  };

  cancelCallBack = () => {
    history.push("/crm/contact");
  };

  render() {
    const { params } = this.state;
    return (
      <div className="lead-wraper">
        <div className="formTabSection position-r">
          <DynamicForm
            module="contacts"
            data={{
              value1: (params.contactId || "")
            }}
            onSave={this.saveCallback}
            onCancel={this.cancelCallBack}
          />
        </div>
      </div>
    );
  }
}

export default AddContact;
