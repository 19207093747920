// Import default packages
import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Row } from "react-bootstrap";
// Import table packages
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// Import internal components
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tableConfig, restServices } from "_helpers";

// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// Import confirmAlert utils
import "react-confirm-alert/src/react-confirm-alert.css";

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import editActionIcon from "img/edit.svg";
import deleteActionIcon from "img/delete.svg";
// Import Images
import SearchIcon from "img/search-icn.png";
import Checkbox from "material-ui/Checkbox";
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// includes
import { crm } from '_utils';
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class ReportLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 10,
      isLoadingDay: false,
      tlConfig: {
        headCells: [
          { id: "ParamCode", value: "ParamCode", label: "Param Code" },
          { id: "ParamName", value: "ParamName", label: "Param Name" },
          { id: "ParamType", value: "ParamType", label: "Param Type" },
          { id: "StartDate", value: "StartDate", label: "Start Date" },
          // { id: "ACTION", value: "ACTION", label: "ACTION" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "CreatedOn",
        selected: [],
        uniqueKey: "ReportListcolumns",
      },
      filterName: "",
      open: false,
      formSubmit: false,
      fields: {
        ParamCode: "",
        ParamName: "",
        ParamType: "",
        Description: "",
        Active: "Y",
        RequiredParam: "Y",
        StartDate: new Date(),
        TenantId: crm.userInfo().pTenantId,
        ReportId: "",
      },
      errors: {
        ParamCode: {
          error: "",
          isReq: "Param code is required!",
        },
        ParamName: {
          error: "",
          isReq: "Param Name is required!",
        },
        ParamType: {
          error: "",
          isReq: "Param Type is required!",
        },
        Description: {
          error: "",
          isReq: "Description is required!",
        },
        StartDate: {
          error: "",
          isReq: "Start date is required!",
        },
      },
      masterData: {
        // LeaveType: [],
      },
      commonResource: {
        ReportParamId: 0,
      },
      valStartDate: "",
      valEndDate: "",
      reportId: 0,
      userPermissions: this.props.userPermissions,
      paramTypeList: [],
      lookUp: false,
      lookUpList: [],
      lookUpValue: "",
      autoloading: false,
      loading: false,
      selectedLookup: {}
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { fields } = this.state;
    if (this.props.objectId != undefined) {
      fields.ReportId = this.props.objectId;
      this.setState(
        {
          fields: fields,
          reportId: this.props.selectedReport.ReportId,
        },
        () => {
          this.getList();
          this.getParamTypeList();
        }
      );
    }
  }

  getParamTypeList = () => {
    let filter =
      `?finder=FindLookupVals;pLookupCode=REPORT_PARAM_TYPE&totalResults=true&limit=100&offset=0`;
    let url = encodeURI(
      envConfig.BASE_API + Services.LOOKUPS_LOV + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          paramTypeList: response.items
        })
      },
      (error) => { }
    );
  }

  /**
   * Get details by id & set data in fields
   */
  getDetailById = (id) => {
    const { fields, errors, reportId } = this.state;
    this.setState({
      isLoadingDay: true,
    });
    let filter =
      `?finder=FindReportParams;pReportId=${reportId}`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_REPORT_PARAMS + "/" + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          response.items && response.items.map(item => {
            if (id === item.ReportParamId) {
              for (var key of Object.keys(item)) {
                if (fields.hasOwnProperty(key)) {
                  if (item[key]) {
                    if (key === "StartDate") {
                      fields[key] = item[key] ? new Date(item[key]) : "";
                    } else {
                      fields[key] = item[key];
                    }

                    // Remove Default Errors
                    if (errors.hasOwnProperty(key)) {
                      errors[key].error = "";
                    }
                  }
                  if (key == "ParamType" && item[key] == "SelectOneChoice") {
                    this.setState({
                      lookUp: true,
                      lookUpValue: item.LookupName
                    },
                      _ => {
                        this.getLookUpValue(item.LookupName);
                      })
                  }
                }
              }
            }
          })
          this.setState({
            fields: fields,
            errors: errors,
            isLoadingDay: false,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig } = this.state;

    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  addReportParamModal = (ReportParamId) => {
    const { commonResource, fields, errors } = this.state;
    commonResource.ReportParamId = ReportParamId;
    this.setState({
      open: true,
      commonResource: commonResource,
    });
    if (ReportParamId > 0) {
      this.getDetailById(ReportParamId);
    } else {
      for (var key of Object.keys(fields)) {
        fields[key] = "";
      }
      for (var key of Object.keys(errors)) {
        errors[key].error = "";
      }
      fields["StartDate"] = new Date();
      fields["Active"] = "Y";
      fields["RequiredParam"] = "Y";
      this.setState({
        fields,
        errors
      })
    }
  };

  // validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;
    if (name == "ParamType" && value == "SelectOneChoice") {
      this.setState({
        lookUp: true
      })
      this.getLookUpValue("");
    } else {
      this.setState({
        lookUp: false,
        lookUpValue: ""
      })
    }

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  getLookUpValue = (val) => {
    const { lookUpValue, pageLimit, currentPage } = this.state;
    let filter =
      `?q=UPPER(Name) LIKE '*${lookUpValue}*'&totalResults=true&offset=${(currentPage - 1) * pageLimit
      }&limit=${pageLimit}`;
    let url = encodeURI(
      envConfig.BASE_API + Services.LOOKUPS_LOV + "/" + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (val !== "") {
            response.items && response.items.length > 0 && response.items.map((opt) => {
              if (opt.Name === val)
                this.setState({
                  selectedLookup: opt
                })
            })
          }
          this.setState({
            lookUpList: response
          })
        }
      },
      (error) => { }
    );
  }

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };
  /**
   * Close Modal Popup
   */
  closeaddReportParamModal = () => {
    this.resetFields(this.state.listRes);
  };

  resetFields = (listRes) => {
    const { fields, errors } = this.state;
    for (var key of Object.keys(fields)) {
      fields[key] = "";
    }
    fields["StartDate"] = new Date();
    fields["Active"] = "Y";
    fields["RequiredParam"] = "Y";
    // Remove Default Errors
    for (var key of Object.keys(errors)) {
      if (errors.hasOwnProperty(key)) {
        errors[key].error = "";
      }
    }
    this.setState({
      fields: fields,
      open: false,
      isLoadingDay: false,
      listRes: listRes,
      formSubmit: false,
      errors: errors,
    });
  };

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;
    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      if (commonResource.ReportParamId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, listRes, reportId, lookUpValue } = this.state;
    let filter =
      `?finder=FindReportParams;pReportId=${reportId}`;
    let url = encodeURI(
      envConfig.BASE_API +
      Services.CHOLA_REPORT_PARAMS +
      "/" +
      commonResource.ReportParamId + filter
    );
    if (lookUpValue !== "") {
      fields["LookupName"] = lookUpValue;
    }
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = listRes.items;

          items.forEach(function (item, index) {
            if (item.ReportParamId === commonResource.ReportParamId) {
              items[index] = response;
              return;
            }
          });

          listRes.items = items;
          this.resetFields(listRes);
          this.getList();
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, listRes, reportId, lookUpValue } = this.state;
    let filter =
      `?finder=FindReportParams;pReportId=${reportId}`;
    let url = encodeURI(envConfig.BASE_API + Services.CHOLA_REPORT_PARAMS + filter);
    fields["ReportId"] = reportId;
    fields["TenantId"] = crm.userInfo().pTenantId;
    if (lookUpValue !== "") {
      fields["LookupName"] = lookUpValue;
    }

    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = listRes.items;
          items.push(response);
          listRes.items = items;
          this.resetFields(listRes);
          this.getList();
        }
      },
      (error) => { }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const { currentPage, tlConfig, pageLimit, filterName, fields, reportId, paging } = this.state;
    this.setState({ isLoading: true });
    if (reportId) {
      let filter =
        `?finder=FindReportParams;pReportId=${reportId}&q=UPPER(ParamName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(currentPage - 1) * pageLimit
        }&limit=${pageLimit}&totalResults=true`;

      if (tlConfig.OrderBy) {
        filter += "&orderBy=" + tlConfig.OrderBy;
      }

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_REPORT_PARAMS + filter
      );

      restServices.getRequest(
        url,
        (response) => {
          this.setState({
            listRes: response,
            isLoading: false,
          });
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
          });
        }
      );
    }
  };

  /**
 * Trigger Calendar Leave List Popup
 * @param {*} id 
 */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete(id);
    });
  }

  handleClickDelete = (id) => {
    const { listRes } = this.state;
    //this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CHOLA_REPORT_PARAMS + "/" + id;
    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Report param deleted successfully.");

        let updateData = listRes.items.filter((item) => {
          return id !== item.ReportParamId;
        });

        listRes.items = updateData;
        this.setState({ listRes: listRes });
      },
      (error) => { }
    );
  };

  handleAutoChange = (event, selectedValue) => {
    this.setState({
      lookUpValue: selectedValue.Name
    })
  }

  handleInputChange = (event, terms) => {
    this.setState({
      lookUpValue: terms
    },
      _ => {
        this.getLookUpValue("");
      }
    )
  }

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      fields,
      errors,
      formSubmit,
      open,
      masterData,
      commonResource,
      isLoadingDay,
      userPermissions,
      paramTypeList,
      lookUpList,
      lookUpValue,
      autoloading,
      loading,
      lookUp,
      selectedLookup
    } = this.state;
    const isHided = (columnName) =>
      tlConfig.columnsSelected.indexOf(columnName) !== -1;

    const isChecked = (value) => value == "Y";
    return (
      <div className="territory-wraper">
        {/* end of Secondary Nav */}
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="form-row justify-content-end">
                <div className="col-lg-4">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Filter and Search
                  </label>
                  <div className="input-group search-filter-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="+ Filter Param Name"
                      name="filterName"
                      value={filterName}
                      onChange={this.handleFieldChange}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getList}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  {/* {userPermissions.add && (
                    <Link to="#" onClick={(e) => this.addReportParamModal(0)}>
                      <button type="button" className="btn btn-add">
                        Add
                      </button>
                    </Link>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        listRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.ReportParamId}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                !isHided("ParamCode") ? "hideCell" : ""
                              }
                            >
                              <Link to="#">
                                {row.ParamCode ? row.ParamCode : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("ParamName") ? "hideCell" : ""
                              }
                            >
                              {row.ParamName ? row.ParamName : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("ParamType") ? "hideCell" : ""}
                            >
                              {row.ParamType ? row.ParamType : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("StartDate") ? "hideCell" : ""
                              }
                            >
                              {row.StartDate == null
                                ? "-"
                                : common.formatDate(
                                  row.StartDate,
                                  "DD/MM/YYYY"
                                )}
                            </TableCell>

                            {/* <TableCell
                              className={!isHided("ACTION") ? "hideCell" : ""}
                            >
                              {userPermissions.update && (
                                <Link to="#"
                                  style={{ padding: "0 5px" }}
                                  onClick={(e) =>
                                    this.addReportParamModal(row.ReportParamId)
                                  }
                                >
                                  <img src={editActionIcon} alt="Edit" />
                                </Link>
                              )}
                              {userPermissions.delete && (
                                <Link to="#"
                                  style={{ padding: "0 5px" }}
                                  name={row.ReportParamId}
                                  onClick={() =>
                                    this.deleteSelectedItem(row.ReportParamId)
                                  }
                                >
                                  <img src={deleteActionIcon} alt="Delete" />
                                </Link>
                              )}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading == false && listRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  {tableConfig.showEntries(currentPage, pageLimit, listRes)}
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={listRes.limit}
                    totalItemsCount={listRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={open}
          onClose={this.closeaddReportParamModal}
          aria-labelledby="responsive-dialog-title"
          className="leave-dialog"
        >
          <DialogTitle id="responsive-dialog-title" className="popup-title">
            {"Add Report Param"}
          </DialogTitle>
          <DialogContent>
            {!isLoadingDay && (
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Param Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="ParamCode"
                      value={fields.ParamCode}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={1000}
                    />
                    {errors.ParamCode.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.ParamCode.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Param Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="ParamName"
                      value={fields.ParamName}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={1000}
                    />
                    {errors.ParamName.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.ParamName.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>

                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Param Type*</Form.Label>
                    <select
                      name="ParamType"
                      className="form-control"
                      value={fields.ParamType}
                      onChange={(e) => this.handleOnChange(e)}
                    >
                      <option value={""}>{"Select Type"}</option>
                      {paramTypeList.map((item, key) => (
                        <option key={key} value={item.Name}>{item.Name}</option>
                      ))}
                    </select>
                    {/* <Form.Control
                      type="text"
                      name="ParamType"
                      value={fields.ParamType}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={1000}
                    /> */}
                    {errors.ParamType.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.ParamType.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                {lookUp &&
                  <div className="list-row">
                    <Form.Group>
                      <Form.Label>LookUP Name</Form.Label>
                      <AutoComplete
                        freeSolo
                        id="Name"
                        disableClearable
                        options={lookUpList.items}
                        loading={autoloading}
                        getOptionLabel={option => option.Name}
                        onChange={this.handleAutoChange}
                        inputValue={lookUpValue}
                        value={selectedLookup}
                        onInputChange={this.handleInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Search Name"}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              )
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </div>
                }

                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="Description"
                      value={fields.Description}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={1000}
                    />
                    {errors.Description.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.Description.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Start Date*</Form.Label>
                    <DatePicker
                      name="StartDate"
                      value={fields.StartDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "StartDate")
                      }
                      format="dd/MM/yyyy"
                    />
                    {errors.StartDate.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.StartDate.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>&nbsp; </Form.Label>
                    <Checkbox
                      name="RequiredParam"
                      className="filter-checkbox"
                      checked={isChecked(fields.RequiredParam)}
                      label="Required"
                      labelStyle={{ color: "#2d2d2d" }}
                      labelPosition="left"
                      onClick={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                </div>


                <div className="list-row">
                  <Form.Group>
                    <Form.Label>&nbsp; </Form.Label>
                    <Checkbox
                      name="Active"
                      className="filter-checkbox"
                      checked={isChecked(fields.Active)}
                      label="Active"
                      labelStyle={{ color: "#2d2d2d" }}
                      labelPosition="left"
                      onClick={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
            {isLoadingDay && (
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Loader />
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions className="popup-footer justify-content-start">
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  onClick={this.onSubmit}
                  type="submit"
                >
                  {commonResource.ReportParamId === 0 ? "Add" : "Update"}
                </Button>
                <Button
                  className="cancelBtn"
                  onClick={this.closeaddReportParamModal}
                  type="button"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Dialog>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default ReportLists;
