import React, { createRef } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EmailEditor from "react-email-editor";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// Import Internal components
import AccessDenied from "_components/_accessdenied/AccessDenied";
import Select from "react-select";
// includes
import { crm } from "_utils";
import Loader from "_components/_loader/Loader";
const emailEditorRef = createRef();

class AddTemplate extends React.Component {
	constructor(props) {
		super(props);
		const {
			match: { params },
		} = this.props;
		this.state = {
			params,
			userPermissions: common.getPermission(this.props, "configure:template"),
			fields: {
				TemplateCode: "",
				TemplateName: "",
				Subject: "",
				HtmlFormat: "",
				DesignFormat: "",
				TextFormat: "",
				Active: "Y",
				Category: "",
				ContentTable: "",
				TenantId: crm.userInfo().pTenantId,
				OrgId: crm.userInfo().pOrgId,
				TemplateType: ""
			},
			isLoaded: true,
			commonResource: {
				EmailTemplateId: 0,
				EserviceCode: "",
			},
			MergeFieldModule: [
				{
					label: "campaign",
					value: "CholaCrmCampaigns",
					api: "CRM_CAMPAIGNS",
				},
				{
					label: "enquiry",
					api: "CRM_LISTS",
					value: "CholaCrmLists",
				},
				{
					label: "lead",
					api: "CRM_LEADS",
					value: "CholaCrmLeads",
				},
				{
					label: "deal",
					api: "CRM_OPPORTUNITY",
					value: "CholaCrmOpportunity",
				},
			],
			MergeFieldList: [],
			ModuleList: [],
			MergeField: "",
		};
	}

	/**
	 * Component Hooks
	 */
	componentDidMount = () => {
		const { commonResource, params, fields } = this.state;

		if (params.id != undefined) {
			commonResource.EmailTemplateId = params.id;

			this.setState(
				{
					commonResource,
					isLoaded: false,
				},
				() => {
					this.getTemplateDetail();
				}
			);
		}
		if (params.type) {
			fields.TemplateType = params.type === "email" ? "EMAIL" : "TEXT";
			this.setState({
				commonResource,
				fields
			});
		}
	};

	/**
	 * Get Template detail by Id
	 */
	getTemplateDetail() {
		const { commonResource, fields, MergeFieldModule } = this.state;
		let url = encodeURI(
			envConfig.BASE_API +
				Services.CHOLA_EMAIL_TEMPLATE +
				"/" +
				commonResource.EmailTemplateId
		);

		restServices.getRequest(
			url,
			(response) => {
				if (response) {
					fields.HtmlFormat = response.HtmlFormat ? response.HtmlFormat : "";
					fields.DesignFormat = response.DesignFormat
						? response.DesignFormat
						: "";
					fields.TextFormat = response.TextFormat ? response.TextFormat : "";
					fields.Subject = response.Subject ? response.Subject : "";
					fields.TemplateCode = response.TemplateCode
						? response.TemplateCode
						: "";
					fields.TemplateName = response.TemplateName
						? response.TemplateName
						: "";
					fields.Category = response.Category;
					fields.ContentTable = response.ContentTable;
					let EserviceCode = MergeFieldModule.find(
						(o) => o.value === response.ContentTable
					);
					commonResource.EserviceCode = EserviceCode.api;
					fields.TemplateType = response.TemplateType ? response.TemplateType : (response.TextFormat ? "TEXT" : "EMAIL")
					this.setState(
						{
							fields,
							commonResource,
						},
						() => {
							this.getColumnsData();
						}
					);
				}
			},
			(error) => {
				common.snack("E", error);
				this.setState({ isLoaded: true });
			}
		);
	}

	/**
	 * Input onchange event handling
	 */
	selectHandleOnChange = (event, name) => {
		const { label, value } = event;
		const { fields, commonResource, MergeFieldModule } = this.state;
		fields.Category = label;
		fields.ContentTable = value;
		let EserviceCode = MergeFieldModule.find((o) => o.value === value);
		commonResource.EserviceCode = EserviceCode.api;
		this.setState(
			{
				MergeField: value,
				fields,
				commonResource,
			},
			() => {
				if (fields.TemplateType == "EMAIL") {
					this.onLoad();
				}
				this.getColumnsData();
			}
		);
	};

	getColumnsData = async () => {
		// this.setState({
		//   isLoaded: false,
		// });
		const { commonResource, fields } = this.state;
		let filter = `?q=EserviceCode=${commonResource.EserviceCode}`;
		let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter;
		restServices.getRequest(
			url,
			(response) => {
				if (response) {
					let formFields = JSON.parse(response.items[0].FormFields);
					let list = formFields.form.fields.sort((a, b) =>
						a.label.toUpperCase() > b.label.toUpperCase()
							? 1
							: b.label.toUpperCase() > a.label.toUpperCase()
							? -1
							: 0
					);
					this.setState({
						MergeFieldList: list,
						isLoaded: true,
					});
					if (fields.TextFormat) {
						let textFormat = common.evaluateEmailTemplates(
							list,
							fields.TextFormat,
							"key",
							"label"
						);
						let res;
						textFormat.then(function (result) {
							res = result;
							console.log("fields.TextFormat :>> ", result);
						});
						setTimeout(() => {
							this.setState({
								fields,
								fields: {
									...this.state.fields,
									TextFormat: res,
								},
							});
						}, 1000);
					}
				}
			},
			(error) => {
				this.setState({
					isLoaded: true,
				});
			}
		);
	};

	/**
	 * Handle submit action
	 */
	handleSubmit() {
		const { commonResource, fields } = this.state;
		var isValid = true;

		if (fields.Category == "") {
			common.snack("E", "Please Select Module");
			isValid = false;
		} else if (fields.Subject.length == 0) {
			common.snack("E", "Please Enter Template Subject");
			isValid = false;
		}

		if (isValid) {
			if (commonResource.EmailTemplateId > 0) {
				this.update();
			} else {
				this.create();
			}
		}
	}

	/**
	 * Update template
	 */
	update = () => {
		const { fields, commonResource } = this.state;

		let url = encodeURI(
			envConfig.BASE_API +
				Services.CHOLA_EMAIL_TEMPLATE +
				"/" +
				commonResource.EmailTemplateId
		);
		restServices.patchRequest(
			url,
			fields,
			(response) => {
				if (response != null) {
					this.props.history.push("/setup/configure/template");
				}
			},
			(error) => {
				common.snack("E", error);
			}
		);
	};

	/**
	 * create template
	 */
	create = () => {
		const { fields } = this.state;

		let url = encodeURI(envConfig.BASE_API + Services.CHOLA_EMAIL_TEMPLATE);
		restServices.postRequest(
			url,
			fields,
			(response) => {
				if (response != null) {
					this.props.history.push("/setup/configure/template");
				}
			},
			(error) => {
				common.snack("E", error);
			}
		);
	};

	/**
	 * Input onchange event handling
	 */
	handleOnChange = (event) => {
		const { fields } = this.state;
		const { name, value } = event.target;

		if (fields.hasOwnProperty(name)) {
			fields[name] = value;
			if (name == "Subject") {
				fields.TemplateName = value;
				fields.TemplateCode = value;
			}
		}

		this.setState({
			fields: fields,
		});
	};

	exportHtmlAndSave = async () => {
		const { fields, MergeFieldList, commonResource } = this.state;
		if (fields.TemplateType == "EMAIL") {
			emailEditorRef.current.editor.exportHtml(async (data) => {
				const { design, html } = data;

				//fields.HtmlFormat = JSON.stringify({ design: design, html: html });
				// fields.HtmlFormat = html;
				if (fields.Category) {
					fields.HtmlFormat = await common.evaluateEmailTemplates(
						MergeFieldList,
						html,
						"label",
						"key"
					);
					let designFormat = await common.evaluateEmailTemplates(
						MergeFieldList,
						JSON.stringify({ design: design }),
						"label",
						"key"
					);
					fields.DesignFormat = designFormat;
					fields.TextFormat = null;
				}
				this.setState(
					{
						fields,
					},
					() => {
						this.handleSubmit();
					}
				);
			});
		} else {
			fields.HtmlFormat = null;
			fields.DesignFormat = null;
			fields.TextFormat = await common.evaluateEmailTemplates(
				MergeFieldList,
				fields.TextFormat,
				"label",
				"key"
			);

			this.setState(
				{
					fields,
				},
				() => {
					this.handleSubmit();
				}
			);
		}
	};

	onLoad = async () => {
		const { fields, MergeFieldList } = this.state;
		if (fields.DesignFormat.length > 0) {
			let designFormat = fields.DesignFormat;
			if (fields.Category !== "") {
				designFormat = await common.evaluateEmailTemplates(
					MergeFieldList,
					designFormat,
					"key",
					"label"
				);
			}
			let templateData = JSON.parse(designFormat);
			const templateJson = templateData.design;
			setTimeout(() => {
				emailEditorRef.current.editor.loadDesign(templateJson);
			}, 1000);
		}
	};

	mapKey = (key) => {
		const { MergeFieldList } = this.state;
		if (key) {
			const filters = MergeFieldList.filter((i) =>
				i.label.toUpperCase().includes(key.toUpperCase())
			);
			return filters ? filters : MergeFieldList;
		} else {
			return MergeFieldList;
		}
	};

	render() {
		const {
			fields,
			commonResource,
			isLoaded,
			userPermissions,
			MergeFieldModule,
			MergeFieldList,
		} = this.state;

		const Item = ({ entity: { label, key } }) => <div>{`${label}`}</div>;
		const Loading = ({ data }) => <div>Loading</div>;

		return (
			<div className="lead-wraper">
				{!userPermissions.read ||
				(commonResource.EmailTemplateId == 0 && !userPermissions.add) ||
				(commonResource.EmailTemplateId > 0 && !userPermissions.update) ? (
					<AccessDenied />
				) : (
					isLoaded && (
						<Form onSubmit={this.exportHtmlAndSave}>
							<div className="formWhiteBg">
								<div className="secondary-head">
									<h1>
										{commonResource.EmailTemplateId == 0 ? "Add" : "Edit"}{" "}
										Template
									</h1>
								</div>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Label>Select Module</Form.Label>
											<Select
												name="Category"
												value={MergeFieldModule.find(
													(o) => o.label === fields.Category
												)}
												onChange={(e) =>
													this.selectHandleOnChange(e, "Category")
												}
												options={MergeFieldModule}
												isDisabled={commonResource.EmailTemplateId > 0}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<Form.Group>
											<Form.Control
												as="textarea"
												rows="3"
												placeholder=""
												name="Subject"
												onChange={(e) => this.handleOnChange(e)}
												value={fields.Subject}
												placeholder="Enter Template Subject"
											/>
										</Form.Group>
									</Col>
								</Row>
								{fields.TemplateType == "EMAIL" && (
									<Row>
										<Col sm={12}>
											<Form.Group>
												<EmailEditor
													ref={emailEditorRef}
													onLoad={this.onLoad}
												/>
											</Form.Group>
										</Col>
									</Row>
								)}
								<Row>
									<Col sm={12}>
										{fields.TemplateType == "TEXT" && (
											<ReactTextareaAutocomplete
												placeholder="Enter text message"
												disabled={MergeFieldList.length == 0}
												className="form-control"
												loadingComponent={Loading}
												name="TextFormat"
												value={fields.TextFormat}
												onChange={(e) => this.handleOnChange(e)}
												style={{
													fontSize: "15px",
													lineHeight: "20px",
													height: "150px",
													padding: 5,
												}}
												containerStyle={{
													marginTop: 20,
													height: 150,
													margin: "20px auto",
												}}
												minChar={0}
												trigger={{
													"#": {
														dataProvider: (token) => {
															return this.mapKey(token)
																.slice(0, 10)
																.map(({ label, key }) => ({ label, key }));
														},
														component: Item,
														output: (item, trigger) => "${" + item.label + "}$",
													},
												}}
											/>
										)}
										{fields.TemplateType == "TEXT" && (
											<Form.Group>
												<div className="hint">
													Type # to insert merge field *
												</div>
											</Form.Group>
										)}
									</Col>
								</Row>
								<Row className="bottomBtn">
									<Col sm={12}>
										<Button
											className="saveBtn"
											type="button"
											onClick={this.exportHtmlAndSave}
										>
											{commonResource.EmailTemplateId > 0 ? "Update" : "Save"}
										</Button>
										<Link
											to={"/setup/configure/template"}
											className="btn btn-link cancelBtn"
										>
											Cancel
										</Link>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										{fields.TemplateType == "EMAIL" && (
											<Form.Group>
												<div className="hint">
													Hint - To Insert merge field, Copy and paste the
													required fields*
													<br />
													Example:{" "}
													<span className="text-bold">
														{" "}
														$&#123;CAMPAIGN NAME&#125;$
													</span>
												</div>
												<ul className="row">
													{MergeFieldList &&
														MergeFieldList.map((obj, ind) => {
															return (
																<li className="col-md-3" key={ind}>
																	<div className="activity-details">
																		{obj.label}
																	</div>
																</li>
															);
														})}
												</ul>
											</Form.Group>
										)}										
									</Col>
								</Row>
							</div>
						</Form>
					)
				)}
				{!isLoaded && (
					<Loader />
				)}
			</div>
		);
	}
}
export { AddTemplate };
