import React from "react";
import { Link } from "react-router-dom";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
import { crm } from '_utils';
import OsmoMaterialTable from "crm/OsmoMaterialTable";
class ContactLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: common.getParam("view") == "kanban" ? false : true,
      params: this.props,
    };
  }

  onAction = (data) => {
    if (data.type == "link" && data.selected.length > 0) {
      this.saveContact(data.selected);
    } else if (data.type == "edit" || data.type == "view") {
      history.push("/crm/contact/edit/" + data.id + "?view=list");
    } else if (data.type == "add") {
      history.push("/crm/contact/add");
    }
  };
  changeTabView = (e, status) => {
    e.preventDefault();
    this.setState({
      isListView: status,
    });
  };

  saveContact = (selected) => {
    let inputData = [];

    selected.map((id, index) => {
      let params = {
        ContactId: id,
        OrgId: crm.userInfo().pOrgId,
        TenantId: crm.userInfo().pTenantId,
        ObjectId: this.props.objectId,
        ObjectType: this.props.objectType,
      };

      inputData.push({
        id: "part" + (index + 1),
        path: "/" + Services.CONTACTS_LINKS,
        operation: "create",
        payload: params,
      });
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(url, formAttr,
      (response) => {
        if (response != null) {
          this.setState({
            isListView: true,
          });
          common.snack("S", "Contact linked successfully");
        }
      },
      (error) => {
        common.snack("E", "Error occurred");
      });
  };

  render() {
    const { params } = this.state;
    return (
      <div className="campaign-wraper">
        <div className="crm-data-wrap">
          <div className="float-left">
            <ul className="tab-list">
              <li className={this.state.isListView ? "active" : ""}>
                <Link to="#" onClick={(e) => this.changeTabView(e, true)}>
                  Linked Contacts
                </Link>
              </li>
              <li className={!this.state.isListView ? "active" : ""}>
                <Link to="#" onClick={(e) => this.changeTabView(e, false)}>
                  Contacts
                </Link>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
          {this.state.isListView && (
            <OsmoMaterialTable
              module="contactsLink"
              query={{
                ObjectId: params.objectId,
                ObjectType: params.objectType,
              }}
            />
          )}
          {!this.state.isListView && (
            <OsmoMaterialTable
              module="contacts"
              link="Link to contacts"
              // // add="Add Master Contacts"
              sort="CreatedOn:desc"
              onAction={(data) => this.onAction(data)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ContactLinks;
