// Import default packages
import React, { createRef } from "react";
// Import redux packages
import { connect } from "react-redux";
import { commonActions } from "_actions/common.actions";
import { Link } from "react-router-dom";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// Import internal components
import CrmMenu from "_components/_crmMenu/CrmMenu";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import ListView from "pages/_components/ListView/ListView";
import KanbanBoard from "pages/_components/KanbanView/KanbanBoard";
import FileExport from "pages/_components/FileExport/FileExport";
// css
import "./css/Quotes.css";
// icon
import SearchIcon from "img/search-icn.png";
const child = createRef();
const userInfo = common.authInfo();
class QuoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      tlConfig: {
        columnId: "CustQuotId",
        headCells: [
          {
            id: "CustQuotId",
            value: "CustQuotId",
            label: "QUOTE ID",
            type: "link",
          },
          {
            id: "QuotationNo",
            value: "QuotationNo",
            label: "QUOTATION NO",
          },
          {
            id: "QuotationDate",
            value: "QuotationDate",
            label: "QUOTATION DATE",
            type: "date",
          },
          {
            id: "ApprStatus",
            value: "ApprStatus",
            label: "APPROVAL STATUS",
            type: "function",
          },
          { id: "CustomerName", value: "CustomerName", label: "CUSTOMER NAME" },
          {
            id: "CustomerEnqDate",
            value: "CustomerEnqDate",
            label: "CUSTOMER ENQ DATE",
            type: "date",
          },
          {
            id: "QuotationEnqNo",
            value: "QuotationEnqNo",
            label: "QUOTATION ENQ NO",
          },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "QuotationNo",
        selected: [],
        uniqueKey: "QuoteListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
      filterName: "",
      crmStatusList: [],
      userPermissions: common.getPermission(this.props, "crm:quote"),
      headers: [
        { label: "ID", key: "CustQuotId" },
        { label: "Quote Rev No", key: "QuoteRevNo" },
        { label: "Quotation Date", key: "QuotationDate" },
        { label: "Appr Status", key: "ApprStatus" },
        { label: "Customer Name", key: "CustomerName" },
        { label: "Customer Enq Date", key: "CustomerEnqDate" },
        { label: "Quotation Enq No", key: "QuotationEnqNo" },
      ],
      urls: "",
      totalResults: "",
      keyArray: [
        "CustQuotId",
        "QuoteRevNo",
        "QuotationDate_date",
        "ApprStatus",
        "CustomerName",
        "CustomerEnqDate_date",
        "QuotationEnqNo",
      ],
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getCRMStatusList();
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading, isListView } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading && isListView) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.CustQuotId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (e, status) => {
    e.preventDefault();
    this.setState({
      isListView: status,
    });
  };

  /**
   * Get Current Status Position
   */
  getStatusPosition = (status) => {
    const { crmStatusList } = this.state;
    if (status != "" && status != null) {
      var position = crmStatusList.findIndex((x) => x.StatusCode == status);
      return position > 0 ? position : 0;
    }
  };

  /**
   * Handle Quote Status
   */
  renderQuoteStatus = (status) => {
    const { crmStatusList } = this.state;
    return (
      <ul className="status-wraper">
        {crmStatusList.map((data, idx) => (
          <li
            key={idx}
            className={`${
              this.getStatusPosition(status) >= idx ? "active" : ""
            }`}
          ></li>
        ))}
      </ul>
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterName,
      userPermissions,
    } = this.state;

    if (currentPage > 0) {
      this.setState({ isLoading: true });
      let filter = `?q=TenantId=${userInfo.TenantId} AND OrgId=${
        userInfo.DefaultOrgId
      } AND UPPER(QuotationNo) LIKE '*${filterName}*'&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${
        (currentPage - 1) * pageLimit
      }`;
      if (filterName == "") {
        filter = `?q=TenantId=${userInfo.TenantId};OrgId=${
          userInfo.DefaultOrgId
        }&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${
          (currentPage - 1) * pageLimit
        }`;
      }

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }

      let url = encodeURI(envConfig.BASE_API + Services.CRM_QUOTES + filter);

      restServices.getRequest(
        url,
        (response) => {
          let deletePermission = userPermissions.delete;
          let temp = {};

          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.map((item) => {
              item.delete = deletePermission;
              list.push(item);
            });
            temp = response;
            temp.items = list;
          } else {
            response.items.map((item) => {
              item.delete = deletePermission;
            });
            temp = response;
          }
          let isRecordAvailable =
            temp && temp.items && temp.items.length ? true : false;
          tlConfig.enableMultipleDelete =
            userPermissions.delete && isRecordAvailable ? true : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete
            ? temp.items.length
            : 0;

          this.setState(
            {
              listRes: temp,
              isLoading: false,
              tlConfig: tlConfig,
              currentPage: response.hasMore ? currentPage + 1 : 0,
              urls: envConfig.BASE_API + Services.CRM_QUOTES,
              totalResults: response.totalResults,
            },
            () => {
              window.addEventListener("scroll", this.onScroll);
              if (child.current) {
                child.current.getCsvData();
              }
            }
          );
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
          });
        }
      );
    }
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let dealTypeId = common.getTransactionTypeId(envConfig.QUOTE_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMQuoteStatus(dealTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmstatus } = props;
    var crmStatusData = state.crmStatusList;
    if (crmstatus && crmstatus.list && crmstatus.list.success) {
      crmStatusData = crmstatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      crmStatusList,
      userPermissions,
      headers,
      urls,
      totalResults,
      keyArray,
      OrderBy,
    } = this.state;

    const isHided = (CustQuotId) =>
      tlConfig.columnsSelected.indexOf(CustQuotId) !== -1;
    const isSelected = (CustQuotId) =>
      tlConfig.selected.indexOf(CustQuotId) !== -1;

    const handleClick = (event, CustQuotId) => {
      const selectedIndex = tlConfig.selected.indexOf(CustQuotId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, CustQuotId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?q=TenantId=${userInfo.TenantId} AND OrgId=${userInfo.DefaultOrgId} AND UPPER(QuotationNo) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&limit=${totalResults}&offset=0`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return (
      <div className="lead-wraper">
        <CrmMenu />
        {/* end of Secondary Nav */}
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-4">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">Quotes</h2>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-8">
                  {this.state.isListView && (
                    <div className="form-row justify-content-end">
                      <div className="col-lg-4">
                        <label
                          className="sr-only"
                          htmlFor="inlineFormInputGroup"
                        >
                          Filter and Search
                        </label>
                        <div className="input-group search-filter-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            placeholder="+ Filter QuoteNo"
                            name="filterName"
                            value={filterName}
                            onChange={this.handleFieldChange}
                          />
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <img
                                src={SearchIcon}
                                alt="Search"
                                onClick={this.filterData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <EnhancedTableColumns
                          tlConfig={tlConfig}
                          updateTLConfig={this.updateTLConfig}
                        />
                      </div>
                      {userPermissions.export && (
                        <div className="col-auto">
                          <FileExport
                            ref={child}
                            headers={headers}
                            filename={"Quote_details.xls"}
                            urls={urls}
                            keyArray={keyArray}
                            filter={filter}
                          />
                        </div>
                      )}
                      {userPermissions.add && (
                        <div className="col-auto">
                          <Link to="/crm/quote/add">
                            <button type="button" className="btn btn-add">
                              Add Quote
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            <div className="crm-data-wrap">
              <div className="float-left">
                {totalResults > 0 && (
                  <p className="tot-result">Total Record: {totalResults}</p>
                )}
              </div>
              <div className="tab-section float-right">
                <ul className="tab-list">
                  <li className={this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, true)}>
                      List
                    </Link>
                  </li>
                  <li className={!this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, false)}>
                      Kanban
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="clearfix"></div>
              {this.state.isListView ? (
                <ListView
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                  isLoading={isLoading}
                  data={listRes}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  isHided={isHided}
                  renderStatus={this.renderQuoteStatus}
                  handlePageChange={this.handlePageChange}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  from="quote"
                  search={filterName}
                  filterData={this.filterData}
                  deptEmployees={this.props.deptEmployees}
                />
              ) : (
                crmStatusList != "" && (
                  <KanbanBoard
                    kanbanType="quote"
                    status={crmStatusList}
                    userPermissions={userPermissions}
                    deptEmployees={this.props.deptEmployees}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, crmstatus } = state;
  return { alert, crmstatus };
};

export default connect(mapStateToProps)(QuoteList);
