import React from "react";
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices } from "_helpers";

class CampaignReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: this.props.campaignId ? this.props.campaignId : 0,
      campaignData: this.props.campaignData ? this.props.campaignData : {},
      report: {},
    };
  }

  componentDidMount() {
    if (this.state.campaignId != 0) {
      this.getCampaignReport();
    }
  }

  /**
   * Get Campaign Reports by ID
   */
  getCampaignReport = () => {
    const { campaignId, campaignData } = this.state;
    let url = envConfig.BASE_API + Services.CAMPAIGN_REPORT + "/" + campaignId;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            report: response,
          });
        }
      },
      (error) => {
        this.setState({
          campaignData: {},
        });
      }
    );
  };

  refreshReports = () => {
    this.getCampaignReport();
  };

  render() {
    const { campaignData, report } = this.state;
    return (
      

      <div className="list-view-wrap campaign-report-wrap">
        <div className="db-section">
          <h3 className="db-section-title mb-3">
            {/* Numbers Dashboard (Targets vs Actuals) */}
          </h3>
          <div className="number-table-wrap">
            <ul className="ntw-list d-flex">
              <li className="ntw-lables">
                <h4 className="crm-type invisible">&nbsp;</h4>
                <h5 className="ntwf-col-title invisible">&nbsp;</h5>
                <ul className="month-list">
                  <li>Lead Count</li>
                  <li>Deal Count</li>
                  <li>Quote Count</li>
                  <li>Leads Revenue</li>
                  <li>Opportunity Revenue</li>
                  <li>Quote Revenue</li>
                </ul>
              </li>
              

              <li>
                <h4 className="crm-type">Report</h4>
                <div className="d-flex justify-content-between">
                  <div className="ntwf-col">
                    <h5 className="ntwf-col-title">Targets</h5>
                    <ul className="month-list">
                      <li>{campaignData.TargetLeadCount ? campaignData.TargetLeadCount : 0}</li>
                      <li>{campaignData.TargetOpportunityCount ? campaignData.TargetOpportunityCount : 0}</li>
                      <li>{campaignData.TargetQuoteCount ? campaignData.TargetQuoteCount : 0}</li>
                      <li>{campaignData.TargetLeadRevenue ? campaignData.TargetLeadRevenue : 0}</li>
                      <li>{campaignData.TargetOpportunityRevenue ? campaignData.TargetOpportunityRevenue : 0}</li>
                      <li>{campaignData.TargetQuoteRevenue ? campaignData.TargetQuoteRevenue : 0}</li>
                    </ul>
                  </div>

                  <div className="ntwf-col">
                    <h5 className="ntwf-col-title">Actuals</h5>
                    <ul className="month-list">
                      <li>{report.LeadsCount ? report.LeadsCount : 0}</li>
                      <li>{report.OpportunityCount ? report.OpportunityCount : 0}</li>
                      <li>{report.QuoteCount ? report.QuoteCount : 0}</li>
                      <li>{report.LeadsRevenue ? report.LeadsRevenue : 0}</li>
                      <li>{report.OpportunityRevenue ? report.OpportunityRevenue : 0}</li>
                      <li>{report.QuoteRevenue ? report.QuoteRevenue : 0}</li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default CampaignReport;
