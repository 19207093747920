import React from "react";
import { Link } from "react-router-dom";
import {
	Navbar,
	Nav,
	Carousel,
	Tab,
	Row,
	Col,
	InputGroup,
	FormControl,
	Button,
} from "react-bootstrap";
//Import CSS
import "./Landing.css";
import LandingHeader from "../../_components/_landingHeader/landingHeader";
import { common } from "../../_helpers";
// Slick Slider
import Slider from "react-slick";

let settings = {
	dots: true,
	infinite: true,
	autoplay: true,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
};
class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="content-wraper">
				<div className="landing-top-wrap">
					{/* Header Layout */}
					<LandingHeader page="landing"/>
					<div className="banner-wrap">
						<div className="container">
							<Carousel>
								<Carousel.Item interval={2000}>
									<div className="row">
										<div className="col-md-4 banner-content align-self-center">
											<div className="mb-5">
												<img src={common.loadImg("crm-logo.png")} alt="Logo" />
											</div>
											<h1 className="banner-title">
												A simple and smart tool that will help grow your
												business
											</h1>
											<p>
												OSMO CRM helps to automates you daily workflow,
												coordinates your team and saves you loads of time.
											</p>
											{/* <Link to="/signup" className="btn btn-signup">
												Signup
											</Link> */}
										</div>
										<div className="col-md-5 banner-img align-self-center">
											<img
												src={common.loadImg("banner-img.png")}
												alt="Banner Image"
												className="img-fluid"
											/>
										</div>
									</div>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</div>

				<div className="feature-wrap">
					<div className="container">
						<div className="row feature-row feature-row-bg">
							<div className="col-md-1"></div>
							<div className="col-md-4 feature-inner-wrap">
								<h2 className="lp-title">We offer Powerfull features</h2>
								<p className="feature-first-p">
									We provide unique features and quality services to manage your
									business end to end
								</p>
								<p className="feature-first-p">
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Companies Manage Partners</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>People Collect Contacts</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Estimates For potential clients</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
						</div>
						<div className="row feature-row">
							<div className="col-md-1"></div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Scheduling Meetings</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Projects Arrange Your Work</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Tasks Assign to Colleagues</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Invoices Easily Create and Export</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
							<div className="col-md-2 feature-inner-wrap">
								<h3>Expenses Track Your expenses</h3>
								<p>
									Lessens your burden by automating your complete office in an
									effective chaina
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="feature-bg-container">
					<img
						src={common.loadImg("bg_image.jpg")}
						alt="Service intro image"
						className="img-fluid"
					/>
				</div>

				<div className="boost-your-business">
					<div className="row">
						<div className="col-md-2"></div>
						<div className="col-md-8 description">
							<p>
								Boost your business and share important contact & project
								information between your colleagues.
							</p>
							<Link to="/signup" className="btn btn-signup header-btn-signup">
								Signup
							</Link>
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>

				<div className="why-choose-us-wrap">
					<div className="container">
						<div className="title-section mb-5 text-center">
							<h2 className="">Why Choose Us</h2>
							<p>
								Close more deals than ever, automatice lead captures,
								<br />
								in-built phone,smart alerts with push notifcations.
							</p>
						</div>

						<div className="wcu-content-wrap">
							<Tab.Container id="left-tabs-example" defaultActiveKey="first">
								<Row>
									<Col sm={2}></Col>
									<Col sm={2} className="left-tabs-container">
										<Nav variant="pills" className="flex-column">
											<Nav.Item>
												<Nav.Link eventKey="first">
													Finds the
													<br /> Best Sales Leads
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="second">
													Have Relevant
													<br /> Conversation
												</Nav.Link>
											</Nav.Item>

											<Nav.Item>
												<Nav.Link eventKey="third">
													Manage Sales
													<br /> Pipeline Better
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</Col>
									<Col sm={6} className="right-tabs-container">
										<Tab.Content>
											<Tab.Pane eventKey="first">
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
												</p>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry
												</p>
											</Tab.Pane>
											<Tab.Pane eventKey="second">
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
												</p>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry
												</p>
											</Tab.Pane>
											<Tab.Pane eventKey="third">
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
												</p>
												<p>
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry. Lorem Ipsum has been the
													industry's standard dummy text ever since the 1500s,
													Lorem Ipsum is simply dummy text of the printing and
													typesetting industry
												</p>
											</Tab.Pane>
										</Tab.Content>
									</Col>
									<Col sm={2}></Col>
								</Row>
							</Tab.Container>
						</div>
					</div>
				</div>

				<div className="testimonials-wrap">
					<div className="container">
						<Slider {...settings} className="testimonial-slider-wrap">
							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Robert Mark</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>

							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Dani Root</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>

							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Mick Roach</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>

							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Robert Mark</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>

							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Dani Root</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>

							<div className="tcontent-box">
								<div className="testimonial-content text-center">
									<img
										src={common.loadImg("testimonial_quote.png")}
										alt="Quote icon"
									/>
									<p>
										Lorem Ipsum dolor sit amet. Consecteture adipiscing elit,
										sed diam nonummy nibh euismod tincidunt ut laoreet dolore
										magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
										quis nostrud exerci tation ullamcorper
									</p>
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<img src={common.loadImg("star.png")} alt="ratings" />
									<h4 className="client-name">Mick Roach</h4>
									<p className="client-info">Director, Mark Ro Pvt.Ltd</p>
								</div>
							</div>
						</Slider>
					</div>
				</div>

				<footer className="lp-footer">
					<div className="lp-footer-main">
						<div className="container">
							<div className="row">
								<div className="col-md-5 lp-footer-col abt-company">
									<img
										src={common.loadImg("footer_logo.png")}
										alt="Footer Logo"
									/>
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s…
									</p>
									<div className="d-flex social-icns">
										<Link to="#">
											<img
												src={common.loadImg("facebook.png")}
												alt="Facebook"
											/>
										</Link>
										<Link to="#">
											<img src={common.loadImg("twitter.png")} alt="Twitter" />
										</Link>
										<Link to="#">
											<img
												src={common.loadImg("instagram.png")}
												alt="Instagram"
											/>
										</Link>
										<Link to="#">
											<img src={common.loadImg("youtube.png")} alt="Youtube" />
										</Link>
									</div>
								</div>
								<div className="col-md-2 lp-footer-col">
									<h5 className="lp-footer-title">Quick Links</h5>
									<ul className="footer-nav">
										<li>
											<Link to="">Features</Link>
										</li>
										<li>
											<Link to="/pricing">Pricing</Link>
										</li>
										<li>
											<Link to="">Security</Link>
										</li>
										<li>
											<Link to="">Explore</Link>
										</li>
									</ul>
								</div>
								<div className="col-md-2 lp-footer-col">
									<h5 className="lp-footer-title">Contact Us</h5>
									<ul className="footer-nav">
										<li>
											<Link to="">Blogs</Link>
										</li>
										<li>
											<Link to="">Updates</Link>
										</li>
										<li>
											<Link to="">Terms of Use</Link>
										</li>
										<li>
											<Link to="">Privacy Policy</Link>
										</li>
									</ul>
								</div>
								<div className="col-md-3 lp-footer-col">
									<div className="newsletter-subscribe-box">
										<img src={common.loadImg("send.png")} alt="send mail" />
										<h5 className="mb-4">Subscribe to our Newsletter</h5>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
											Duis finibus mi id elit gravida, quis tincidunt purus
											fringilla.
										</p>
										<InputGroup className="mb-2">
											<FormControl
												placeholder="Enter your email"
												aria-label="Email"
												aria-describedby="basic-addon2"
												className="footer-new-letter-input"
											/>
											<InputGroup.Append>
												<Button
													variant="outline-secondary"
													className="footer-new-letter-btn"
												>
													<img
														src={common.loadImg("send1.png")}
														alt="send mail"
													/>
												</Button>
											</InputGroup.Append>
										</InputGroup>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
export default Landing;
