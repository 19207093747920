// Import default packages
import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
// Import table packages
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// Import internal components
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tableConfig, restServices } from "_helpers";

// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// Import confirmAlert utils
import "react-confirm-alert/src/react-confirm-alert.css";

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import editActionIcon from "img/edit.svg";
import deleteActionIcon from "img/delete.svg";
// Import Images
import SearchIcon from "img/search-icn.png";
// includes
import { crm } from '_utils';
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class LeaveDayList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      isLoadingDay: false,
      tlConfig: {
        headCells: [
          { id: "LeaveType", value: "LeaveType", label: "Leave Type" },
          { id: "Description", value: "Description", label: "Description" },
          { id: "LeaveDate", value: "LeaveDate", label: "Leave Date" },
          { id: "DayName", value: "DayName", label: "Day Name" },
          { id: "ACTION", value: "ACTION", label: "ACTION" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "CreatedOn",
        selected: [],
        uniqueKey: "LeaveCalDtlsListcolumns",
      },
      filterName: "",
      open: false,
      formSubmit: false,
      fields: {
        LeaveType: "",
        Description: "",
        LeaveDate: "",
        DayName: "",
        TenantId: crm.userInfo().pTenantId,
        LeaveCalId: "",
      },
      errors: {
        LeaveType: {
          error: "",
          isReq: "Please select type",
        },
        Description: {
          error: "",
          isReq: "Description is required!",
        },
        LeaveDate: {
          error: "",
          isReq: "Leave date is required!",
        },
        DayName: {
          error: "",
          isReq: "Day name is required!",
        },
      },
      masterData: {
        LeaveType: [],
      },
      commonResource: {
        LeaveCaldtlId: 0,
      },
      valStartDate: "",
      valEndDate: "",
      userPermissions: this.props.userPermissions,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { fields } = this.state;
    if (this.props.objectId != undefined) {
      fields.LeaveCalId = this.props.objectId;
      this.setState(
        {
          fields: fields,
          valStartDate: this.props.selectedLeave.StartDate,
          valEndDate: this.props.selectedLeave.EndDate,
        },
        () => {
          this.getList();
        }
      );
    }
    this.getLookUpData("CAL_LEAVE_TYPE", "LeaveType");
  }

  // functions
  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] =
            response.items && response.items.length ? response.items : [];
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Get details by id & set data in fields
   */
  getDetailById = (id) => {
    const { fields, errors } = this.state;
    this.setState({
      isLoadingDay: true,
    });

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_LEAVE_CAL_DTLS + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                if (key === "LeaveDate") {
                  fields[key] = response[key] ? new Date(response[key]) : "";
                } else {
                  fields[key] = response[key];
                }

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  if (key == "LeaveDate") {
                    let valStDate = new Date(this.state.valStartDate).setHours(
                      0, 0, 0, 0
                    );
                    let valEndDate = new Date(this.state.valEndDate).setHours(
                      0, 0, 0, 0
                    );
                    let currentDate = new Date(fields.LeaveDate).setHours(
                      0, 0, 1, 0
                    );

                    if (currentDate >= valStDate && currentDate <= valEndDate) {
                      errors[key].error = "";
                    } else {
                      errors[key].error =
                        "Please select date range between the calendar year";
                    }
                  } else {
                    errors[key].error = "";
                  }
                }
              }
            }
          }
          this.setState({
            fields: fields,
            errors: errors,
            isLoadingDay: false,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig } = this.state;

    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  addLeaveModal = (LeaveCaldtlId) => {
    const { commonResource } = this.state;
    commonResource.LeaveCaldtlId = LeaveCaldtlId;
    this.setState({
      open: true,
      commonResource: commonResource,
    });
    if (LeaveCaldtlId > 0) {
      this.getDetailById(LeaveCaldtlId);
    }
  };

  // validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        if (key == "LeaveDate") {
          let valStDate = new Date(this.state.valStartDate).setHours(
            0, 0, 0, 0
          );
          let valEndDate = new Date(this.state.valEndDate).setHours(0, 0, 0, 0);
          let currentDate = new Date(fields.LeaveDate);

          if (currentDate >= valStDate && currentDate <= valEndDate) {
            errors[key].error = "";
          } else {
            errors[key].error =
              "Please select date range between the calendar year";
          }
        } else {
          errors[key].error = "";
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
      if (key == "LeaveDate") {
        let valStDate = new Date(this.state.valStartDate).setHours(0, 0, 0, 0);
        let valEndDate = new Date(this.state.valEndDate).setHours(0, 0, 0, 0);
        let currentDate = new Date(fields.LeaveDate);

        if (currentDate >= valStDate && currentDate <= valEndDate) {
          errors[key].error = "";
        } else {
          errors[key].error =
            "Please select date range between the calendar year";
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, type) => {
    const { label, value } = event;
    const { fields } = this.state;
    if (fields.hasOwnProperty(type)) {
      fields[type] = value;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(type, value);
      }
    );
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }
    fields.DayName = new Date(date).toLocaleString("en-us", {
      weekday: "long",
    });

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };
  /**
   * Close Modal Popup
   */
  closeAddLeaveModal = () => {
    this.resetFields(this.state.listRes);
  };

  resetFields = (listRes) => {
    const { fields, errors } = this.state;
    fields.LeaveType = "";
    fields.Description = "";
    fields.LeaveDate = "";
    fields.DayName = "";
    // Remove Default Errors
    for (var key of Object.keys(errors)) {
      if (errors.hasOwnProperty(key)) {
        errors[key].error = "";
      }
    }
    this.setState({
      fields: fields,
      open: false,
      isLoadingDay: false,
      listRes: listRes,
      formSubmit: false,
      errors: errors,
    });
  };

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;

    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      if (commonResource.LeaveCaldtlId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, listRes } = this.state;

    let url = encodeURI(
      envConfig.BASE_API +
      Services.HRMS_LEAVE_CAL_DTLS +
      "/" +
      commonResource.LeaveCaldtlId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = listRes.items;

          items.forEach(function (item, index) {
            if (item.LeaveCaldtlId === commonResource.LeaveCaldtlId) {
              items[index] = response;
              return;
            }
          });

          listRes.items = items;
          this.resetFields(listRes);
          this.getList();
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, listRes } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_LEAVE_CAL_DTLS);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = listRes.items;
          items.push(response);
          listRes.items = items;
          this.resetFields(listRes);
          this.getList();
        }
      },
      (error) => { }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const { currentPage, tlConfig, pageLimit, filterName, fields } = this.state;
    this.setState({ isLoading: true });

    let filter =
      `?q=TenantId=${crm.userInfo().pTenantId} AND LeaveCalId=${fields.LeaveCalId} AND UPPER(Description) LIKE '*${filterName}%*`;

    if (tlConfig.OrderBy) {
      filter += "&orderBy=" + tlConfig.OrderBy;
    }

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_LEAVE_CAL_DTLS + filter
    );

    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          listRes: response,
          isLoading: false,
        });
      },
      (error) => {
        this.setState({
          listRes: {},
          isLoading: false,
        });
      }
    );
  };

  /**
 * Trigger Calendar Leave List Popup
 * @param {*} id 
 */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete(id);
    });
  }

  handleClickDelete = (id) => {
    const { listRes } = this.state;
    //this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.HRMS_LEAVE_CAL_DTLS + "/" + id;
    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Leave deleted successfully.");

        let updateData = listRes.items.filter((item) => {
          return id !== item.LeaveCaldtlId;
        });

        listRes.items = updateData;
        this.setState({ listRes: listRes });
      },
      (error) => { }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      fields,
      errors,
      formSubmit,
      open,
      masterData,
      commonResource,
      isLoadingDay,
      userPermissions,
    } = this.state;
    const isHided = (columnName) =>
      tlConfig.columnsSelected.indexOf(columnName) !== -1;

    const leaveValues = masterData.LeaveType.map((leaveValues) => {
      let obj = {
        label: leaveValues.Name,
        value: leaveValues.Code,
      };
      return obj;
    });

    return (
      <div className="territory-wraper">
        {/* end of Secondary Nav */}
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="form-row justify-content-end">
                <div className="col-lg-4">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Filter and Search
                  </label>
                  <div className="input-group search-filter-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="+ Filter Description"
                      name="filterName"
                      value={filterName}
                      onChange={this.handleFieldChange}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getList}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  {userPermissions.add && (
                    <Link to="#" onClick={(e) => this.addLeaveModal(0)}>
                      <button type="button" className="btn btn-add">
                        Add
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        listRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.LeaveCaldtlId}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                !isHided("LeaveType") ? "hideCell" : ""
                              }
                            >
                              <Link to="#">
                                {row.LeaveType ? row.LeaveType : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("Description") ? "hideCell" : ""
                              }
                            >
                              {row.Description ? row.Description : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("LeaveDate") ? "hideCell" : ""
                              }
                            >
                              {row.LeaveDate == null
                                ? "-"
                                : common.formatDate(
                                  row.LeaveDate,
                                  "DD/MM/YYYY"
                                )}
                            </TableCell>
                            <TableCell
                              className={!isHided("DayName") ? "hideCell" : ""}
                            >
                              {row.DayName ? row.DayName : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("ACTION") ? "hideCell" : ""}
                            >
                              {userPermissions.update && (
                                <Link to="#"
                                  style={{ padding: "0 5px" }}
                                  onClick={(e) =>
                                    this.addLeaveModal(row.LeaveCaldtlId)
                                  }
                                >
                                  <img src={editActionIcon} alt="Edit" />
                                </Link>
                              )}
                              {userPermissions.delete && (
                                <Link to="#"
                                  style={{ padding: "0 5px" }}
                                  name={row.LeaveCaldtlId}
                                  onClick={() =>
                                    this.deleteSelectedItem(row.LeaveCaldtlId)
                                  }
                                >
                                  <img src={deleteActionIcon} alt="Delete" />
                                </Link>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading == false && listRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  {tableConfig.showEntries(currentPage, pageLimit, listRes)}
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={listRes.limit}
                    totalItemsCount={listRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Dialog
          open={open}
          onClose={this.closeAddLeaveModal}
          aria-labelledby="responsive-dialog-title"
          className="leave-dialog"
        >
          <DialogTitle id="responsive-dialog-title" className="popup-title">
            {"Add Leave"}
          </DialogTitle>
          <DialogContent>
            {!isLoadingDay && (
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Leave Type*</Form.Label>
                    <Select
                      value={leaveValues.find(
                        (o) => o.value === fields.LeaveType
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "LeaveType")
                      }
                      options={leaveValues}
                    />
                    {errors.LeaveType.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.LeaveType.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="Description"
                      value={fields.Description}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={1000}
                    />
                    {errors.Description.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.Description.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Leave Date*</Form.Label>
                    <DatePicker
                      name="LeaveDate"
                      value={fields.LeaveDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "LeaveDate")
                      }
                      format="dd/MM/yyyy"
                    />
                    {errors.LeaveDate.error !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.LeaveDate.error}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Day Name </Form.Label>
                    <Form.Control
                      type="text"
                      name="DayName"
                      disabled
                      onChange={(e) => this.handleOnChange(e)}
                      value={fields.DayName}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
            {isLoadingDay && (
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Loader />
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions className="popup-footer justify-content-start">
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  onClick={this.onSubmit}
                  type="submit"
                >
                  {commonResource.LeaveCaldtlId === 0 ? "Add" : "Update"}
                </Button>
                <Button
                  className="cancelBtn"
                  onClick={this.closeAddLeaveModal}
                  type="button"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Dialog>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default LeaveDayList;
