import React from "react";
import { Link } from 'react-router-dom';
// includes
import { history } from "_helpers";
//Import CSS
import "./CrmMenu.css";

class CrmMenu extends React.Component {
  render() {
    return (
      <div className="secondary-nav">
        <ul className="secondary-nav-list">
          <li className={history.location.pathname.match("crm/campaign") ? "active" : ""}>
            <Link to="/crm/campaign">Campaigns</Link>
          </li>
          <li className={history.location.pathname.match("crm/enquiry") ? "active" : ""}>
            <Link to="/crm/enquiry">Enquiry</Link>
          </li>
          <li className={history.location.pathname.match("crm/lead") ? "active" : ""}>
            <Link to="/crm/lead">Leads</Link>
          </li>
          <li className={history.location.pathname.match("crm/deal") ? "active" : ""}>
            <Link to="/crm/deal">Deals</Link>
          </li>
          <li className={history.location.pathname.match("crm/quote") ? "active" : ""}>
            <Link to="/crm/quote">Quotes</Link>
          </li>
          <li className={history.location.pathname.match("crm/sales-order") ? "active" : ""}>
            <Link to="/crm/sales-order">Sales Order</Link>
          </li>
          <li className={history.location.pathname.match("crm/invoice") ? "active" : ""}>
            <Link to="/crm/invoice">Invoices</Link>
          </li>
          <li className={history.location.pathname.match("crm/contact") ? "active" : ""}>
            <Link to="/crm/contact">Contacts</Link>
          </li>
        </ul>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default CrmMenu;