import React from "react";
import Badge from "@material-ui/core/Badge";
import { Tabs, Tab } from "react-bootstrap";
import RaisedButton from "material-ui/RaisedButton";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
// import AddDealGeneral from "./Tabs/AddDealGeneral";
import DynamicForm from "crm/DynamicForm";
import Comments from "../../../_components/Comments/Comments";
import SalesTeam from "../../../_components/SalesTeam/SalesTeam";
import ProductServices from "../../../_components/ProductServices/ProductServices";
import CallLogsList from "../../../_components/CallLogs/CallLogsList/CallLogsList";
import Attachments from "../../../_components/Attachments/Attachments";
import Meeting from "../../../_components/Meeting/Meeting";
import History from "../../../_components/History/pages/History";
import ContactLinks from "../../../_components/Contacts/ContactLinks";
import Loader from "_components/_loader/Loader";
//Import CSS
import "../css/Deal.css";

import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common, history, tools } from "_helpers";

import { connect } from "react-redux";
// Actions
import { commonActions } from "_actions/common.actions";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import EduTechDeal from "crm/EduTechDeal";
const authInfo = common.authInfo();
class AddDeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      crmDealStatusList: [],
      dealData: {},
      dealId: 0,
      isLoading: true,
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.deal
      ),
      open: false,
    };
    this.state.currentTab = common.getParam("tab");
    let status = common.getParam("status");
    this.state.dealData["ApprStatus"] = status ? status : null;
  }
  componentDidMount() {
    const { params } = this.state;
    this.getCRMStatusList();
    if (params.dealId) {
      this.setState(
        {
          dealId: params.dealId,
        },
        () => {
          this.getDealDetail(params.dealId);
        }
      );
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  /**
   * Get Deal Details by ID
   */
  getDealDetail = (id) => {
    let url = envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            dealData: response,
            isLoading: false,
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    let dealTypeId = common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMDealStatus(dealTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmDealStatus } = props;

    var crmStatusData = state.crmDealStatusList;

    if (crmDealStatus && crmDealStatus.list && crmDealStatus.list.success) {
      crmStatusData = crmDealStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }
    if (crmStatusData && crmStatusData !== state.crmDealStatusList) {
      return {
        crmDealStatusList: crmStatusData,
      };
    } else {
      return {};
    }
  }

  getStatusPosition = () => {
    const { dealData, crmDealStatusList } = this.state;
    if (dealData.ApprStatus != "" && dealData.ApprStatus != null) {
      var position = crmDealStatusList.findIndex(
        (x) => x.StatusCode == dealData.ApprStatus
      );
      return position > 0 ? position : 0;
    }
    return 0;
  };

  saveStatus = (status) => {
    const { dealData } = this.state;
    dealData.ApprStatus = status;
    this.setState({
      dealData,
    });
  };

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  convertToQuote = (e) => {
    e.preventDefault();
    const { dealId, dealData } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    if (dealData.QuoteConverted == null || dealData.QuoteConverted == "N") {
      var createParams = {
        name: "convertOpportunityToQuote",
        parameters: [
          { P_OPPORTUNITY_ID: dealId },
          {
            P_TERRITORY_ID: dealData.TerritoryId
              ? dealData.TerritoryId
              : userData.territory[0],
          },
          { P_CAMPAIGN_ID: dealData.CampaignId ? dealData.CampaignId : "" },
        ],
      };

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
      );
      restServices.postRequest(url, createParams, (response) => {
        if (response.status >= 200 && response.status <= 300) {
          common.snack("S", "Successfully Converted as Quote");
          this.setState({
            open: false,
          });
        }
      });
    } else {
      common.snack("E", "Deal already converted as Quote");
    }
  };
  /**
   * Tab onclick event
   */
  handleSelect(key) {
    let url = window.location.pathname;
    let queryParam = window.location.search;
    if (queryParam.includes("tab=")) {
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab=" + tab, "tab=" + key);
    }
    history.push(url + queryParam);
  }

  saveCallback = (res) => {
    console.log("seve res :>> ", res);
    history.push(
      "/crm/deal/edit/" + res.OpportunityId + common.getCrmReturnQuery()
    );
    this.setState({
      dealId: res.OpportunityId,
      dealData: res,
    });
  };

  cancelCallBack = () => {
    console.log("cancel");
    history.push("/crm/deal");
  };

  render() {
    const {
      crmDealStatusList,
      currentTab,
      dealId,
      dealData,
      isLoading,
      userPermissions,
    } = this.state;
    const authData = () => {
      return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() };
    };
    //validate permissions
    let access =
      dealId == 0
        ? userPermissions.add != "2"
          ? true
          : false
        : common.evalPermissions(
            userPermissions.update,
            dealData.TerritoryId,
            dealData.SalesPersonId
          );
    // console.log('deal access', access, userPermissions)
    const isEduTech = envConfig.EDUTECH_TENANT.includes(authInfo.TenantId);
    return (
      <div>
        {!isLoading && !access ? (
          <AccessDenied />
        ) : (
          !isLoading && (
              <div className={isEduTech ? "lead-wraper footer-fixed" : "lead-wraper"}>
              <div className="secondary-head">
                <h1>
                  {dealData.OpportunityName
                    ? dealData.OpportunityName
                    : "Add Deal"}
                </h1>
              </div>

              <div className="formTab">
                <ul>
                  {crmDealStatusList.map((data, idx) => (
                    <li
                      key={idx}
                      onClick={() => this.saveStatus(data.StatusCode)}
                      className={`tabShape whiteTab ${
                        this.getStatusPosition() >= idx ? "active" : ""
                      }`}
                    >
                      {" "}
                      <span className="tri-right"></span>{" "}
                      <Badge badgeContent={++idx} color="primary">
                        {data.StatusName}
                      </Badge>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="formTabSection position-r">
                <Tabs
                  defaultActiveKey={currentTab ? currentTab : "general"}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="general" title="General">
                    {isEduTech ? (
                      <EduTechDeal data={{
                        value1: dealId > 0 ? dealId : "",
                        dealData: dealData,
                      }} onSave={this.saveCallback}/>
                    ) : (
                      <DynamicForm
                        module="deal"
                        data={{
                          value1: dealId > 0 ? dealId : "",
                        }}
                        default={{
                          SalesPersonId: authData(),
                        }}
                        userPermissions={userPermissions}
                        // default={{
                        //   "SalesPersonId": authData(),
                        //   //"TerritoryId":{ label: authInfo.terList[0].label, value: authInfo.terList[0].value }
                        // }}
                        onSave={this.saveCallback}
                        onCancel={this.cancelCallBack}
                      />
                    )}
                    {/* <AddDealGeneral
                      dealId={this.props.match.params.dealId}
                      accessPermission={userPermissions}
                      status={dealData.ApprStatus}
                      deptEmployees={this.props.deptEmployees}
                    /> */}
                  </Tab>
                  {/* <Tab
                  eventKey="salesteam"
                  title="Sales Team"
                  disabled={dealId == 0 ? "disabled" : ""}
                >
                  {!isLoading && dealId > 0 && (
                    <SalesTeam
                      objectId={this.props.match.params.dealId}
                      objectType="deal"
                    />
                  )}
                </Tab> */}
                  <Tab
                    eventKey="products"
                    title="Products"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <ProductServices
                        objectId={this.props.match.params.dealId}
                        objectType="deal"
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="comments"
                    title="Notes"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <Comments
                        objectId={this.props.match.params.dealId}
                        objectType="CRM_OPPORTUNITY"
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="call"
                    title="Call Logs"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <CallLogsList
                        objectId={this.props.match.params.dealId}
                        objectType="deal"
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="meetings"
                    title="Meetings"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <Meeting
                        objectId={this.props.match.params.dealId}
                        objectType="deal"
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="attachments"
                    title="Attachments"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <Attachments
                        objectType="deal"
                        objectId={this.props.match.params.dealId}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="Contacts"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <ContactLinks
                        objectId={this.props.match.params.dealId}
                        objectType={common.getObjectNameByPage("deal")}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="History"
                    disabled={dealId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && dealId > 0 && (
                      <History campaign={dealData} />
                    )}
                  </Tab>
                </Tabs>
                <div className="action-btn-wrap position-a action-btn-position">
                  <RaisedButton
                    disabled={dealId === 0}
                    onClick={this.handleClick}
                    label="Action"
                  />
                  <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    targetOrigin={{ horizontal: "left", vertical: "top" }}
                    onRequestClose={this.handleRequestClose}
                  >
                    <Menu autoFocusItem={true}>
                      <MenuItem
                        primaryText="Convert to Quote"
                        onClick={(e) => this.convertToQuote(e)}
                      />
                    </Menu>
                  </Popover>
                </div>
              </div>
            </div>
          )
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { crmDealStatus } = state;
  return { crmDealStatus };
};
export default connect(mapStateToProps)(AddDeal);
