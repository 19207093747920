import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

const FieldLookup = (props) => {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isRender, setIsRender] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  const finderList = {
    "Lookup": {
      url: `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=Code,Name&finder=FindLookupVals;pLookupCode=${props.type}`,
      query: ``,
      limitOffset: `&limit=10000&offset=0&totalResults=true`,
      filter: ["Name", "Code"]
    }
  }
  // init
  useEffect(() => {
    if (isRender) {
      let find = props.lookup ? "Lookup" : props.type;
      let finder = finderList[find];
      let url = `${finder.url}${finder.limitOffset}`;
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            setResultList(common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1]))
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      )
    }
  }, [isRender]);

  // handler
  const onSelect = (data) => {
    props.onSelect(data, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <Select
        isClearable={true}
        options={resultList}
        value={props.value}
        onChange={(data) => onSelect(data)}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
      />
    )
  )
}
export default FieldLookup