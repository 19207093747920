import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

// Import Images
import SearchIcon from "img/search-icn.png";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import PayElementDetail from "./LeaveOpeningDetail";
// includes
import { crm } from '_utils';
class LeaveOpeningList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: {},
      customerData: [],
      selectedCustomer: {},
      selectedCustomerId: 0,
      paging: {
        page: 1,
        size: 2,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    //this.getList();
    let customerData = [
      { CustomerName: "James Martin", CustomerCode: "C:2 E:1 S:1", CustomerId: "" },
      { CustomerName: "Steve Jack", CustomerCode: "C:2 E:1 S:2" },
      { CustomerName: "Mike", CustomerCode: "C:2 E:2 S:1" }
    ]
    this.setState({ searchList: { "items": customerData } });
  }
  /**
   * Methods
   */
  handleClickList = (customer) => {
    this.setState(
      {
        selectedCustomer: customer,
        selectedCustomerId: customer.CustomerId,
        isLoading: true,
      },
      (_) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  /**
   * Service Request
   */
  getList(term = "") {
    this.setState({
      isSearchLoad: true,
      searchList: [],
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(CustomerName) LIKE '*${term}*' &limit=10&offset=0&totalResults=true&orderBy=CreatedOn:desc`;

    let url = envConfig.BASE_API + Services.CRM_CUSTOMER + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        common.snack("S", "Successfully fetched data");
        if (response && response.items && response.items.length > 0) {
          this.setState(
            {
              searchList: response,
              isSearchLoad: false,
              customerData:
                response && response.items.length > 0 ? response.items : [],
            },
            (_) => {
              const { customerData } = this.state;
              this.handleClickList(customerData[0]);
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
          });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedCustomer: selectedCustomer,
      selectedCustomerId,
      isLoading,
    } = this.state;

    return (
      <div className="crm-list-wrap">
        <Row>
          <Col lg={3} sm={4}>
            <div className="marketing-list-wrap">
              <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                <h2 className="crm-type-title">Leave Opening</h2>
                <Link to={"/hrms/leaveopening/add"} className="btn btn-add">
                  Add
                </Link>
              </div>

              <Paper>
                <div className="input-group search-ml-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img
                        src={SearchIcon}
                        alt="Search"
                      //onClick={this.getList}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="filterName"
                    onChange={(e) => this.handleSearchChange(e)}
                    placeholder="Filter and search"
                    name="filterName"
                  />
                </div>
                <ul className="marketing-list">
                  {isSearchLoad == false &&
                    searchList.items &&
                    searchList.items.length > 0 &&
                    searchList.items.map((row, index) => {
                      return (
                        <li
                          onClick={(e) => this.handleClickList(row)}
                          key={index}
                          className={`d-flex justify-content-between align-items-center ${row.CustomerCode == selectedCustomer.CustomerCode
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="mlLeft mr-3">
                            <h4 className="ml-name">{row.CustomerName}</h4>
                            <p className="ml-type">{row.CustomerCode}</p>
                          </div>
                          <div className="mlRight">
                            {/* <Link to={"/hrms/leaveopening/edit/" + row.CustomerId}> */}
                            <Link to="#">
                              Change
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                  {isSearchLoad == true && <Loader />}
                  {isSearchLoad == false &&
                    searchList.items &&
                    searchList.items.length === 0 && <NoRecordFound />}
                </ul>
              </Paper>
            </div>
          </Col>
          <Col lg={9} sm={8}>
            <div className="formTabSection position-r">
              {isLoading ? (
                <Loader />
              ) : (
                <PayElementDetail objectId={selectedCustomerId} data={selectedCustomer} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export { LeaveOpeningList as LeaveOpeningList };
