// Import default packages
import React from "react";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
import { history } from "_helpers";

function ChildCampaignList(props) {
  const onAction = (data) => {
    if (data.type == "edit" || data.type == "view") {
      history.push("/crm/campaign/edit/" + data.id + "?view=list");
    }
  }

  return (
    <div className="lead-wraper">
      {props.campaignId > 0 && (
        <OsmoMaterialTable
          module="campaign"
          sort="CreatedOn:desc"
          onAction={(data) => onAction(data)}
          query={{ ParentCampaignId: props.campaignId }}
        />
      )}
    </div>
  );
}

export default ChildCampaignList;
