import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
  TerritoryCode: {
    isReq: "Department code is required!",
  },
  TerritoryName: {
    isReq: "Department name is required!",
  },
  TerritoryType: {
    isReq: "Department type is required!",
  },
  TerritoryStatus: {
    isReq: "Department status is required!",
  },
};
const userInfo = common.authInfo();

class AddDivision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        TerritoryCode: "",
        TenantId: userInfo.TenantId,
        OrgId: userInfo.DefaultOrgId,
        TerritoryName: "",
        TerritoryType: "",
        Description: "",
        ParentId: null,
        TerritoryOwnerId: null,
        TerritoryStatus: "",
        OwnerId: userInfo.EmpId
      },
      commonResource: {
        OwnerFirstName: userInfo.FirstName,
        OwnerLastName: userInfo.LastName,
      },
      updateId: 0,
      autoFieds: {
        ParentIdSearchTerm: "",
      },
      masterData: {
        territoryTypes: [],
        territoryStatus: [],
        parentTerritory: [],
      },
      errors: {},
      userPermissions: common.getPermission(this.props, "hrms:department"),
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    this.defineInitErrorMsg();
    this.getLookUpData("TERRITORY_TYPE", "territoryTypes");
    this.getLookUpData("TERRITORY_STATUS", "territoryStatus");

    if (this.props.match.params.parentId != undefined) {
      fields.ParentId = this.props.match.params.parentId;
      this.setState({
        fields
      },
        () => {
          if (fields.ParentId != null) {
            this.getTerritoryDetailsById();
          }
        });
    }

    if (this.props.match.params.divId != undefined) {
      let updateId = this.props.match.params.divId
        ? this.props.match.params.divId
        : 0;
      this.setState(
        {
          updateId: updateId,
        },
        () => {
          const { updateId } = this.state;
          if (updateId > 0) {
            this.getDetailById(updateId);
          }
        }
      );
    }
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to territory list page
   */
  onCancel = () => {
    history.push("/setup/hrms/department");
  }

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { fields, autoFieds } = this.state;
    var splitId = id.split("-");
    fields[splitId[0]] = selectedValue.TerritoryId;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(splitId[0], selectedValue.TerritoryId);
      }
    );
    // this.props.handleChange(event, splitId[0], selectedValue[config.get], selectedValue);
  };

  toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState({ fields: fields }, () => {
      this.validateForm(name, value);
    });
  }

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { masterData, autoFieds, fields } = this.state;
    masterData.parentTerritory = [];

    const { id } = event.target;
    var splitId = id.split("-");

    autoFieds[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }
    this.setState({
      autoloading: true,
      masterData: masterData,
      autoFieds: autoFieds,
      fields
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(TerritoryName) LIKE '*${terms}*' &limit=10&offset=0&totalResults=true&orderBy=TerritoryName:asc`;
    let url = envConfig.BASE_API + Services.CRM_TERRITORIES + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          masterData.parentTerritory =
            response.items && response.items.length ? response.items : [];
          this.setState({
            autoloading: false,
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_TERRITORIES + "/" + id);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState(
            {
              fields: fields,
              errors: errors,
            },
            () => {
              if (fields.ParentId != null) {
                this.getTerritoryDetailsById();
              }
            }
          );
        } else { }
      },
      (error) => { }
    );
  };

  getTerritoryDetailsById = () => {
    const { fields, autoFieds } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_TERRITORIES + "/" + fields.ParentId
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          autoFieds["ParentIdSearchTerm"] = response.TerritoryName;
          this.setState({
            autoFieds: autoFieds,
          });
        }
      },
      (error) => { }
    );
  };

  /*
   * Get all territory types details from DB 
   */
  getAllTerritoryTypes = () => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=TERRITORY_TYPE,pTenantId=${crm.userInfo().pTenantId}&totalResults=true`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData.territoryTypes =
            response.items && response.items.length ? response.items : [];
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  /*
   * Get all territory status details from DB 
   */
  getAllTerritoryStatus = () => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=TERRITORY_STATUS,pTenantId=${crm.userInfo().pTenantId}&totalResults=true`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData.territoryStatus =
            response.items && response.items.length ? response.items : [];
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };


  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] = common.getOptionsData(response.items, "Name", "Code");
          masterData[source].map((data) => {
            data.label = data.label.replace("Territory", "Department");
          });
          this.setState({
            masterData: masterData
          });
        }
      },
      (error) => { }
    )
  };

  save = (event) => {
    event.preventDefault();

    const { errors, updateId } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (updateId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, updateId } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_TERRITORIES + "/" + updateId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/setup/hrms/department");
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_TERRITORIES);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/setup/hrms/department");
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      fields,
      commonResource,
      masterData,
      loading,
      autoloading,
      errors,
      formSubmit,
      autoFieds,
      updateId,
      userPermissions,
    } = this.state;

    return (
      <div className="lead-wraper">
        {!userPermissions.read || ((updateId == 0 && !userPermissions.add) || (updateId > 0 && !userPermissions.update)) ? (
          <AccessDenied />
        ) : (
          <Form onSubmit={this.save}>
            <div className="formWhiteBg">
              <div className="formHead">
                <h2>Department Information</h2>
              </div>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Department Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="TerritoryCode"
                      value={fields.TerritoryCode}
                      onInput={e => this.toInputUppercase(e)}
                      onChange={(e) => this.handleOnChange(e)}
                      disabled={updateId !== 0}
                    />
                    {errors.TerritoryCode !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.TerritoryCode}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Department Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="TerritoryName"
                      value={fields.TerritoryName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.TerritoryName !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.TerritoryName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Department Type*</Form.Label>
                    <div className="select-control">
                      <Select
                        value={masterData.territoryTypes.find(o => o.value === fields.TerritoryType)}
                        onChange={(e) => this.selectHandleOnChange(e, 'TerritoryType')}
                        options={masterData.territoryTypes}
                      />
                    </div>
                    {errors.TerritoryType !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.TerritoryType}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Department Status*</Form.Label>
                    <div className="select-control">
                      <Select
                        value={masterData.territoryStatus.find(o => o.value === fields.TerritoryStatus)}
                        onChange={(e) => this.selectHandleOnChange(e, 'TerritoryStatus')}
                        options={masterData.territoryStatus}
                      />
                    </div>
                    {errors.TerritoryStatus !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.TerritoryStatus}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Parent Department</Form.Label>
                    <AutoComplete
                      freeSolo
                      id="ParentId"
                      disabled
                      disableClearable
                      options={masterData.parentTerritory}
                      loading={autoloading}
                      getOptionLabel={(option) => option.TerritoryName}
                      onChange={this.handleAutoChange}
                      inputValue={autoFieds.ParentIdSearchTerm}
                      onInputChange={this.handleInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Search Parent Department"}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                              <React.Fragment>
                                {autoloading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Owner Name</Form.Label>
                    <Form.Control
                      readOnly
                      type="text"
                      value={commonResource.OwnerFirstName + " " + commonResource.OwnerLastName}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="Description"
                      value={fields.Description}
                      onChange={(e) => this.handleOnChange(e)}
                      maxLength={4000}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  {updateId > 0 ? "Update" : "Save"}
                </Button>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}

export { AddDivision };
