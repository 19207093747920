import React, { createRef } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";

import Switch from "@material-ui/core/Switch";
// Image Drop
import Dropzone from "react-dropzone";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices, tools } from "_helpers";

//Import CSS
import "../css/Employee.css";
import "react-datepicker/dist/react-datepicker.css";

// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// Import Images
import EmployeeImg from "img/employee-img.png";
import ProfileUpload from "img/profile_upload.png";

const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// Defined Errors
const defErrs = {
  EmployeeCode: {
    isReq: "Employee code is required!",
  },
  DateOfJoin: {
    isReq: "Hire date is required!",
  },
  FirstName: {
    isReq: "Firstname is required!",
  },
  LastName: {
    isReq: "Lastname is required!",
  },
  Email: {
    isReq: "Email address is required!",
    isValidEmail: "Please enter valid email address!",
  },
  Password: {
    isReq: "Password is required!",
  },
  UserName: {
    isReq: "Username is required!",
  },
  EmployementStatus: {
    isReq: "Employee type is required!",
  },
  ResignationDate: {
    isReq: "Resignation Date is required!",
  },
};

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

const userInfo = common.authInfo();
class AddEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserId: userInfo.UserId,
      selectedImage: "",
      fields: {
        Title: "",
        MiddleName: "",
        Gender: 0,
        MobileNo: "",

        UserName: "",

        MaritalStatus: "",
        FatherName: "",
        SpouseName: "",
        NationalityId: "",
        PersonType: "EMPLOYEE",
        EmployementStatus: "DIRECT", // Custom-key
        IdCardNo: "",
        SwapCardNo: "", // Defined for access card no
        EmployeeGroup: "",

        DateOfBirth: "",
        DateOfJoin: "",
        DateOfConfirm: "",
        DateOfNotice: "",

        SupervisorId: "",

        PanCard: "",
        AadhaarNo: "",
        SalaryAccountNo: "",
        HourlyRate: "",
        HourlyRateCurr: "",
        PfDeduction: "",

        PfNo: "",
        PfUanNo: "",
        PfEnrollmentDate: "",
        PfRemarks: "",

        EsiCovered: "",
        EsiNo: "",
        HealthInsNo: "",

        ExtraDutyDeduction: "",
        DayBataDeduction: "",

        EmployeeCode: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        DeptId: "",
        WorkingStatus: "",
        ResignationDate: "",
        ProfileFileName: "",
        ProfileFileType: "",
        ProfilePicPath: "",
        UserPicPath: "",
        UserFileName: "",
        UserFileType: "",
      },
      imageUploadFields: {
        ObjectId: userInfo.UserId,
        ObjectType: "user_pics",
        FileName: "",
        FileType: "",
        FileSize: "",
        TenantId: userInfo.TenantId,
        StartDate: null,
        EndDate: null,
        OrgId: userInfo.DefaultOrgId,
      },
      excludeFields: [
        "EmployeeCode",
        "FirstName",
        "LastName",
        "Email",
        "Password",
        "DeptId",
      ],
      errors: {},
      formSubmit: false,
      divisionData: [],
      subDivisionData: [],
      sectionData: [],
      designationData: [],
      departmentData: [],
      empTypeData: [],
      genderData: [],
      currencyData: [],
      salutationData: [],
      territoryData: [],
      nationalityData: [{ label: "Select", value: "" }],
      shiftData: [{ label: "Select", value: "" }],
      workCalendarData: [{ label: "Select", value: "" }],
      employeeGroupData: [{ label: "Select", value: "" }],
      SupervisorData: [{ label: "Select", value: "" }],
      PersonTypeData: [{ label: "EMPLOYEE", value: "EMPLOYEE" }],
      empId: 0,
      empCode: "",
      returnURL: "",
      filterName: "",
      page: 1,
      email: "",
      autoloading: false,
      autoFields: {
        SupervisorIdSearchTerm: "",
      },
      oldTerritories: [],
      territorySelected: [],
      supervisorData: [],
      userPermissions: common.getPermission(this.props, "hrms:employee"),
      sData: {
        workStatus: [],
      },
      defaultRoleGroupId: 0,
      isProgress: false
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { fields } = this.state;
    this.getTerritoryList();
    this.defineInitErrorMsg();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let empCode = params.get("empCode");
    let email = params.get("email");
    let returnURL = params.get("return");
    let filterName = params.get("filterName");
    let page = params.get("page");

    if (this.props.match.params.empId) {
      this.setState(
        {
          empId: this.props.match.params.empId,
          empCode,
          email,
          returnURL,
          filterName,
          page,
        },
        () => {
          this.getEmpTerritoryFromSalesTeam();
          this.getEmpByEmpId();
          this.removeKeyWhileUpdate();
        }
      );
    }

    this.getMasterData("empTypeData");
    this.getMasterData("genderData");
    this.getMasterData("currencyData");
    this.getMasterData("salutationData");
    //this.getMasterData("subDivisionData");
    this.validateForm("EmployementStatus", fields.EmployementStatus);

    this.getLookUpData("WORK_STATUS", "workStatus");
    this.getDefaultGroupId();
  }

  getTerritoryList = () => {
    let filter = `?q=TenantId=${userInfo.TenantId}&limit=10000&offset=0&totalResults=true&orderBy=TerritoryName:asc`;
    let url = envConfig.BASE_API + Services.CRM_TERRITORIES + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          this.setState({
            territoryData:
              response.items && response.items.length ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Get default role id for create employee
   */
  getDefaultGroupId = () => {
    let filter = `?q=GroupDesc=${envConfig.DEFAULT_ROLE_GROUP};TenantId=${userInfo.TenantId}&fields=GroupId,GroupDesc`;
    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          this.setState({
            defaultRoleGroupId: response.items[0].GroupId,
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Save files using Drop method
   */
  onDrop = (files) => {
    const { imageUploadFields } = this.state;
    imageUploadFields.FileName = files[0].name;
    imageUploadFields.FileType = files[0].type;
    imageUploadFields.FileSize = files[0].size;
    this.setState({
      imageUploadFields: imageUploadFields,
    });
    if (files.size != 0) {
      common.getBase64(files[0], (result) => {
        this.setState(
          {
            fileBase64Content: result,
            selectedImage: URL.createObjectURL(files[0]),
          },
          () => {
            // this.UploadFile();
          }
        );
      });
    }
  };

  // api
  getLookUpData = (lookupValue, source) => {
    const { sData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData[source] = common.getOptionsData(response.items, "Name", "Code");
          this.setState({
            sData,
          });
          this.count++;
          // this.apiStatus();
        }
      },
      (error) => {}
    );
  };

  getUserByEmailId = () => {
    const { fields } = this.state;
    let filter = `?q=TenantId=${userInfo.TenantId} AND UPPER(UserName) LIKE '*${fields.Email}%*`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          fields.UserName = response.items[0].UserName;
          fields.Password = response.items[0].Password;
          this.setState(
            {
              fields,
            },
            () => {
              this.validateForm("UserName", fields.UserName);
            }
          );
        } else {
        }
      },
      (error) => {}
    );
  };

  removeKeyWhileUpdate = () => {
    const { errors } = this.state;
    delete errors.Password;
    this.setState({
      errors: errors,
    });
  };

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "Email":
          if (!emailPattern.test(value)) {
            errors[name] = defErrs.Email.isValidEmail;
          } else {
            errors[name] = "";
          }
          break;
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  loadDependentResources = (name, value) => {
    const { fields } = this.state;

    if (name == "DivisionId") {
      fields.SubDivision = "";
      fields.DepartmentId = "";
      fields.SectionId = "";
      fields.Designation = "";
      this.setState(
        {
          departmentData: [{ label: "Select", value: "" }],
          subDivisionData: [{ label: "Select", value: "" }],
          designationData: [{ label: "Select", value: "" }],
          sectionData: [{ label: "Select", value: "" }],
          fields: fields,
        },
        () => {
          if (value) {
            this.getMasterData("subDivisionData");
          }
          this.validateForm("DepartmentId", "");
          this.validateForm("SubDivision", "");
          this.validateForm("Designation", "");
          this.validateForm("SectionId", "");
        }
      );
    }

    if (name == "SubDivision") {
      fields.DepartmentId = "";
      fields.SectionId = "";
      fields.Designation = "";
      this.setState(
        {
          fields: fields,
          departmentData: [{ label: "Select", value: "" }],
          designationData: [{ label: "Select", value: "" }],
          sectionData: [{ label: "Select", value: "" }],
        },
        () => {
          if (value) {
            this.getMasterData("departmentData");
          }
          this.validateForm("DepartmentId", "");
          this.validateForm("Designation", "");
          this.validateForm("SectionId", "");
        }
      );
    }

    if (name == "DepartmentId") {
      fields.SectionId = "";
      fields.Designation = "";
      this.setState(
        {
          fields: fields,
          designationData: [{ label: "Select", value: "" }],
          sectionData: [{ label: "Select", value: "" }],
        },
        () => {
          if (value) {
            this.getMasterData("designationData");
            this.getMasterData("sectionData");
          }
          this.validateForm("Designation", "");
          this.validateForm("SectionId", "");
        }
      );
    }
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }
    if (name === "Email") {
      fields.UserName = value;
      this.validateForm("UserName", inputValue);
    }
    this.setState(
      {
        fields: fields,
      },
      () => {
        //this.loadDependentResources(name, value);
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, type) => {
    const { label, value } = event;
    const { fields } = this.state;
    if (fields.hasOwnProperty(type)) {
      fields[type] = value;
    }

    this.setState({ fields: fields }, () => {
      this.loadDependentResources(type, value);
      this.validateForm(type, value);
    });
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };

  /**
   * Upload files to server
   */
  UploadFile = () => {
    const { imageUploadFields, fileBase64Content, fields } = this.state;
    let token = userInfo.token;
    // let headers = {
    //   "Content-Type": "application/vnd.oracle.adf.resourceitem+json",
    //   Authorization: "Bearer " + token,
    // };
    let url = envConfig.BASE_API + Services.CRM_FILES;

    restServices.postRequest(
      url,
      imageUploadFields,
      (response) => {
        if (response) {
          if (response.AttachmentFileId != null && fileBase64Content != null) {
            fields.Nattr1 = response.AttachmentFileId;
            this.setState(
              {
                fields,
              },
              (_) => {
                this.getImageUrl();
              }
            );
          }
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  getImageUrl = () => {
    const { fields, fileBase64Content } = this.state;
    let updateUrl =
      envConfig.BASE_API + Services.CRM_FILES + "/" + fields.Nattr1;
    let updateData = {
      FileImageBlob: fileBase64Content,
    };
    restServices.patchRequest(
      updateUrl,
      updateData,
      (response) => {
        if (response) {
          // For user update
          fields.UserFileName = response.FileName;
          fields.UserFileType = response.FileType;
          fields.UserPicPath = response.FilePath;

          // For employee update
          fields.ProfileFileName = response.FileName;
          fields.ProfileFileType = response.FileType;
          fields.ProfilePicPath = response.FilePath;
          this.setState(
            {
              fields,
            },
            (_) => {
              this.onSubmit();
            }
          );
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  save = (event) => {
    event.preventDefault();
    const { errors, selectedImage, fields } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      if (key == "ResignationDate") {
        isValid = fields.WorkingStatus == "RESIGNED" ? !errors[key] : true;
      } else {
        isValid = !errors[key];
      }
    }
    if (isValid) {
      if (selectedImage !== "") {
        if (
          fields.Nattr1 == "" ||
          fields.Nattr1 == null ||
          fields.Nattr1 == undefined
        ) {
          this.UploadFile();
        } else {
          this.getImageUrl();
        }
      } else {
        this.onSubmit();
      }
    }
  };

  onSubmit = (_) => {
    const { empId, fields, territorySelected } = this.state;
    if (territorySelected.length > 0) {      
      this.setState({
        isProgress: true
      });
      if (empId > 0) {
        this.addUpdateTerritoryToEmp();
        this.updateEmp();
        // Get user by UserName and then update user details
        this.getUserByEmailIdAndUpdate();
      } else {
        this.createEmp();
      }
    } else {
      common.snack("E", "Department is required!");
    }
  };

  updateEmp = () => {
    const { fields, empId } = this.state;
    var formAttr = Object.assign({}, fields);

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "DateOfBirth":
        case "DateOfJoin":
        case "DateOfConfirm":
        case "DateOfNotice":
        case "PfEnrollmentDate":
        case "ResignationDate":
          formAttr[key] = fields[key]
            ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ")
            : null;
          break;
        default:
          formAttr[key] = fields[key] ? fields[key] : null;
          break;
      }
    }
    delete formAttr.Email;
    delete formAttr.Password;
    delete formAttr.UserName;
    delete formAttr.DeptId;
    delete formAttr.Nattr1;
    delete formAttr.UserFileName;
    delete formAttr.UserFileType;
    delete formAttr.UserPicPath;

    if (fields.ProfilePicPath) {
      formAttr.ProfilePicPath = fields.ProfilePicPath;
      formAttr.ProfileFileName = fields.ProfileFileName;
      formAttr.ProfileFileType = fields.ProfileFileType;
    }

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + empId
    );
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        //window.location.href = "/setup/hrms/employee";
        //history.push("/setup/hrms/employee");
      },
      (error) => {}
    );
  };

  updateProfileSession(userId) {
    const { fields } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    if (userData != null) {
      userData["EmployeeName"] = fields.FirstName + " " + fields.LastName;
      userData["FirstName"] = fields.FirstName;
      userData["LastName"] = fields.LastName;
      userData["Gender"] = fields.Gender;
      userData["ProfileImg"] =
        envConfig.IMG_SERVLET_URL + "?imageId=" + userId + "&imageType=USER";
    }
    localStorage.setItem("userData", tools.crypt(userData));
  }

  createEmp = () => {
    const { fields } = this.state;

    var createParams = {
      name: "createEmployeeUser",
      parameters: [
        { EmployeeCode: fields.EmployeeCode },
        { FirstName: fields.FirstName },
        { LastName: fields.LastName },
        { EmailAddress: fields.Email },
        { Password: fields.Password },
        { TenantId: userInfo.TenantId },
        { GroupId: userInfo.GroupId },
        { OrgId: userInfo.DefaultOrgId },
        { WorkLocId: userInfo.WorkLocId },
        { CreatedBy: userInfo.UserName },
        { DefaultGroup: "employee-mobile-user" },
      ],
    };

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.CRM_CREATE_USER);
    restServices.postRequest(
      url,
      createParams,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          this.getEmpByEmailId();
        } else {
          let errorText = "";
          response.text().then(function (text) {
            if (text) {
              if (text.includes(" unique constraint")) {
                errorText = "Enter unique Employee code or Email Address";
              } else {
                errorText = text;
              }
            } else {
              errorText = "Something went wrong!";
            }
          });

          setTimeout(() => {
            if (errorText) {
              common.snack("E", errorText);
            }
          }, 2000);
        }
      },
      (error) => {}
    );
  };

  updateUser = (UserId) => {
    const { fields } = this.state;

    var updateParams = {
      Password: fields.Password,
      FirstName: fields.FirstName,
      LastName: fields.LastName,
      Gender: fields.Gender,
      Active: fields.WorkingStatus == "RESIGNED" ? "N" : "Y"
    };
    //if (fields.UserPicPath) {
    updateParams.Nattr1 = fields.Nattr1;
    updateParams.UserPicPath = fields.UserPicPath;
    updateParams.UserFileName = fields.UserFileName;
    updateParams.UserFileType = fields.UserFileType;
    //}
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + "/" + UserId);
    restServices.patchRequest(
      url,
      updateParams,
      (response) => {
        if (UserId == userInfo.UserId) {
          this.updateProfileSession(UserId);
        }
        window.location.href = "/setup/hrms/employee";
      },
      (error) => {}
    );
  };

  getEmpByEmpId = () => {
    const { empId, fields, errors } = this.state;

    fields.EmployementStatus = "";

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + empId
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                switch (key) {
                  case "DateOfBirth":
                  case "DateOfJoin":
                  case "DateOfConfirm":
                  case "DateOfNotice":
                  case "PfEnrollmentDate":
                  case "ResignationDate":
                    fields[key] = response[key]
                      ? new Date(response[key])
                      : null;
                    break;
                  default:
                    fields[key] = response[key];
                }

                // Remove Default Errors
                if (
                  response[key] !== null &&
                  response[key] !== "" &&
                  errors.hasOwnProperty(key)
                ) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState(
            {
              fields: fields,
              errors: errors,
            },
            (_) => {
              const { fields } = this.state;
              this.getSupervisorById(fields.SupervisorId);
              //Get user details
              this.getUserByEmailId();
            }
          );
        } else {
        }
      },
      (error) => {}
    );
  };

  /**
   * Get Supervisor data by ID
   * @param {*} id
   */
  getSupervisorById = (id) => {
    const { autoFields } = this.state;
    if (id) {
      let url = encodeURI(
        envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + id
      );
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            autoFields["SupervisorIdSearchTerm"] = response.EmployeeName;
            this.setState({
              autoFields,
            });
          }
        },
        (error) => {}
      );
    }
  };

  getEmpByEmailId = () => {
    const { fields } = this.state;
    let filter = `?q=TenantId=${userInfo.TenantId} AND UPPER(Email) LIKE '*${fields.Email}%*`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + filter
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          //add employee to selected territory team
          this.addUpdateTerritoryToEmp(response.items[0].EmployeeId);          

          this.updateEmpById(response.items[0].EmployeeId);
          //create default role to employee
          let userFilter = `?q=TenantId=${userInfo.TenantId} AND UPPER(UserName) LIKE '*${fields.Email}%*`;
          let userURL = encodeURI(
            envConfig.BASE_API + Services.CRM_USERS + userFilter
          );
          restServices.getRequest(
            userURL,
            (response) => {
              if (response && response.items && response.items.length > 0) {
                this.getPermissions(response.items[0].UserId);
              } else {
              }
            },
            (error) => {
              common.snack("E", "Something went wrong!");
            }
          );
        } else {
        }
      },
      (error) => {}
    );
  };

  getEmpTerritoryFromSalesTeam() {
    const { empId } = this.state;
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_TERRITORY_TEAM +
        "?q=ResourceId=" +
        empId
    );
    let territorySelected = [];

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response.items.length > 0) {
            response.items.map((obj) => {
              territorySelected.push({
                TerritoryId: obj.TerritoryId,
                TerritoryName: obj.TerritoryName,
              });
            });
            this.setState({
              territorySelected,
              oldTerritories: response.items,
            });
          }
        }
      },
      (error) => {}
    );
  }

  addUpdateTerritoryToEmp = (eId) => {
    const { oldTerritories, territorySelected, empId } = this.state;
    if (empId > 0) {
      let inputData = [];
      var props = ["TerritoryId", "TerritoryName"];

      let delOnes = common.excludeFromArray(oldTerritories, territorySelected, [
        "TerritoryId",
        "TerritoryName",
        "TerritoryTeamId",
      ]);
      let newOnes = common.excludeFromArray(
        territorySelected,
        oldTerritories,
        props
      );
      // console.log("delOnes :>> ", delOnes);
      // console.log("newOnes :>> ", newOnes);      
      if (newOnes.length > 0) {
        newOnes.map((obj) => {
          let fields = {
            ResourceType: "SALESPERSON",
            ResourceId: empId,
            TenantId: userInfo.TenantId,
            OrgId: userInfo.DefaultOrgId,
            Owner: "N",
            TerritoryId: obj.TerritoryId,
          };

          inputData.push({
            id: "part" + (inputData.length + 1),
            path: "/" + Services.CRM_TERRITORY_TEAM,
            operation: "create",
            payload: fields,
          });
        });
      }
      if (delOnes.length > 0) {
        delOnes.map((obj) => {
          
          inputData.push({
            id: "part" + (inputData.length + 1),
            path: "/" + Services.CRM_TERRITORY_TEAM + "/" + obj.TerritoryTeamId,
            operation: "delete",
            payload: {},
          });
        });
      }

      var formAttr = { parts: inputData };
      let url = encodeURI(envConfig.BASE_API);
      if (inputData.length > 0) {
        restServices.batchRequest(url, formAttr, (response) => {
         
        },
        (error) => {
          console.log('error :>> ', error);
        });
      }
    } else {
      //create new
      let inputData = [];
      territorySelected.map((obj) => {
        let fields = {
          ResourceType: "SALESPERSON",
          ResourceId: empId > 0 ? empId : eId,
          TenantId: userInfo.TenantId,
          OrgId: userInfo.DefaultOrgId,
          Owner: "N",
          TerritoryId: obj.TerritoryId,
        };

        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + Services.CRM_TERRITORY_TEAM,
          operation: "create",
          payload: fields,
        });
      });

      var formAttr = { parts: inputData };
      let url = encodeURI(envConfig.BASE_API);
      if (inputData.length > 0) {
        restServices.batchRequest(url, formAttr, (response) => {
        },
        (error) => {
          console.log('error :>> ', error);
        });
      }
    }
  };

  getPermissions = async (userId) => {
    const permissions = await this.assignDefaultRole(userId);
  };

  assignDefaultRole = (userId) => {
    const { defaultRoleGroupId } = this.state;
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + defaultRoleGroupId
    );

    restServices.getRequest(url, (response) => {
      if (response) {
        if (response) {
          let fieldAttr = {
            UserId: userId,
            GroupId: response.GroupId,
            GroupActive: "Y",
            LdapSync: "Y",
            TenantId: userInfo.TenantId,
            UserAccess: response.UserAccess
          };
          let urlCreate = encodeURI(
            envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS
          );
          restServices.postRequest(
            urlCreate,
            fieldAttr,
            (result) => {
              if (result) {
                //Get child level
                //this.getChildPermissions(userId, response.GroupId);
              }
            },
            (error) => {}
          );
        }
      }
    });
  };

  getChildPermissions = (userId, groupParentId) => {
    // const { permissionArray } =this.state;

    let filter = `?q=GroupParentId=${groupParentId};TenantId=${userInfo.TenantId}&limit=1000&offset=0&orderBy=CreatedOn:desc`;
    let childLevel = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + filter
    );

    restServices.getRequest(childLevel, (response) => {
      if (response) {
        if (response && response.items) {
          let parent = response.items;
          parent.map((parentObj) => {
            let fieldAttr = {
              UserId: userId,
              GroupId: parentObj.GroupId,
              GroupActive:
                parentObj.Active === "Y"
                  ? envConfig.allPermissions[0].value
                  : envConfig.allPermissions[1].value,
              LdapSync: "Y",
              TenantId: userInfo.TenantId,
            };

            let url = encodeURI(
              envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS
            );
            restServices.postRequest(
              url,
              fieldAttr,
              (response) => {
                if (response) {
                  this.getChildPermissions(userId, parentObj.GroupId);
                }
              },
              (error) => {}
            );
          });
        }
      }
    });
  };

  getUserByEmailIdAndUpdate = () => {
    const { fields } = this.state;
    let filter = `?q=TenantId=${userInfo.TenantId} AND UPPER(UserName) LIKE '*${fields.Email}%*`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          //this.updateEmpById(response.items[0].UserId);
          this.updateUser(response.items[0].UserId);
        } else {
        }
      },
      (error) => {}
    );
  };

  updateEmpById = (empId) => {
    const { fields, excludeFields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      if (!excludeFields.includes(key)) {
        switch (key) {
          case "DateOfBirth":
          case "DateOfJoin":
          case "DateOfConfirm":
          case "DateOfNotice":
          case "PfEnrollmentDate":
          case "ResignationDate":
            formAttr[key] = fields[key]
              ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ")
              : null;
            break;
          default:
            if (fields[key] == "" || fields[key] == null) {
              delete formAttr[key];
            } else {
              formAttr[key] = fields[key];
            }
            break;
        }
      }
    }

    delete formAttr.Password;
    delete formAttr.UserName;
    delete formAttr.Nattr1;
    delete formAttr.UserFileName;
    delete formAttr.UserFileType;
    delete formAttr.UserPicPath;

    if (fields.ProfilePicPath) {
      formAttr.ProfilePicPath = fields.ProfilePicPath;
      formAttr.ProfileFileName = fields.ProfileFileName;
      formAttr.ProfileFileType = fields.ProfileFileType;
    }

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + empId
    );
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        //window.location.href = "/setup/hrms/employee";
        history.push("/setup/hrms/employee");
      },
      (error) => {
        //window.location.href = "/setup/hrms/employee";
        history.push("/setup/hrms/employee");
      }
    );
  };

  getURLByType = (type) => {
    const { fields } = this.state;
    switch (type) {
      case "divisionData":
        return encodeURI(envConfig.BASE_API + Services.HRMS_DIVISION);
      case "subDivisionData":
        return encodeURI(
          envConfig.BASE_API +
            Services.HRMS_SUB_DIVISION +
            "/?q=DivisionId=" +
            fields.DivisionId +
            "&limit=50"
        );
      case "sectionData":
        return encodeURI(
          envConfig.BASE_API +
            Services.HRMS_SECTIONS +
            "/?q=DeptId=" +
            fields.DepartmentId +
            "&limit=50"
        );
      case "designationData":
        return encodeURI(
          envConfig.BASE_API +
            Services.HRMS_DESIGNATIONS +
            "/?q=DepartmentId=" +
            fields.DepartmentId +
            "&limit=50"
        );
      case "departmentData":
        return encodeURI(
          envConfig.BASE_API +
            Services.HRMS_DEPARTMENTS +
            "/?q=SubDivId=" +
            fields.SubDivision +
            "&limit=50"
        );
      case "empTypeData":
        return encodeURI(
          envConfig.BASE_API +
            Services.LOOKUPS_LOV +
            `?finder=FindLookupVals;pLookupCode=EMP_STATUS,pTenantId=${userInfo.TenantId}`
        );
      case "genderData":
        return encodeURI(
          envConfig.BASE_API +
            Services.LOOKUPS_LOV +
            `?finder=FindLookupVals;pLookupCode=GENDER,pTenantId=${userInfo.TenantId}`
        );
      case "currencyData":
        return encodeURI(
          envConfig.BASE_API +
            Services.LOOKUPS_LOV +
            `?finder=FindLookupVals;pLookupCode=CURRENCIES,pTenantId=${userInfo.TenantId}`
        );
      case "salutationData":
        return encodeURI(
          envConfig.BASE_API +
            Services.LOOKUPS_LOV +
            `?finder=FindLookupVals;pLookupCode=SALUTATION,pTenantId=${userInfo.TenantId}`
        );
      default:
        return "";
    }
  };

  getMasterData = (type) => {
    restServices.getRequest(
      this.getURLByType(type),
      (response) => {
        let masterData = [];
        switch (type) {
          case "salutationData":
            masterData = common.getOptionsData(response.items, "Code", "Code");
            break;
          case "empTypeData":
          case "currencyData":
            masterData = common.getOptionsData(response.items, "Name", "Code");
            break;
          case "departmentData":
            masterData = common.getOptionsData(
              response.items,
              "DepartmentName",
              "DeptId"
            );
            break;
          case "designationData":
            masterData = common.getOptionsData(
              response.items,
              "DesignationName",
              "DesignationId"
            );
            break;
          case "sectionData":
            masterData = common.getOptionsData(
              response.items,
              "SectionName",
              "SectionId"
            );
            break;
          case "subDivisionData":
            masterData = common.getOptionsData(
              response.items,
              "SubDivisionName",
              "SubDivisionId"
            );
            break;
          case "divisionData":
            masterData = common.getOptionsData(
              response.items,
              "DivisionName",
              "DivisionId"
            );
            break;
          case "genderData":
            masterData = response.items.length > 0 ? response.items : [];
            break;
        }
        this.setState({
          [type]: masterData,
        });
      },
      (error) => {}
    );
  };

  onCancel = () => {
    const { empId, empCode, email, returnURL, filterName, page } = this.state;
    history.push(
      "/setup/hrms/employee?tab=employeedetails" +
        "&empId=" +
        empId +
        "&empCode=" +
        empCode +
        "&email=" +
        email +
        "&filterName=" +
        filterName +
        "&page=" +
        page
    );
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { autoFields, fields } = this.state;
    const { id } = event.target;
    var splitId = id.split("-");
    autoFields[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }

    this.setState({
      autoloading: true,
      territoryData: [],
      autoFields: autoFields,
      fields,
    });

    let filter = `?q=TenantId=${userInfo.TenantId} AND UPPER(TerritoryName) LIKE '*${terms}*' &limit=10000&offset=0&totalResults=true&orderBy=TerritoryName:asc`;
    let url = envConfig.BASE_API + Services.CRM_TERRITORIES + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          this.setState({
            autoloading: false,
            territoryData:
              response.items && response.items.length ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    this.setState({
      territorySelected: selectedValue,
    });
  };

  /**
   * Get Supervisors Data based on the term entered
   * @param {*} event
   * @param {*} terms
   */
  handleSupervisorInputChange = (event, terms = "") => {
    const { autoFields, empId, fields } = this.state;

    const { id } = event.target;
    var splitId = id.split("-");

    autoFields[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }

    this.setState({
      autoloading: true,
      supervisorData: [],
      autoFields: autoFields,
      fields,
    });

    let filter = `?q=TenantId=${userInfo.TenantId} AND UPPER(EmployeeName) LIKE '*${terms}*' AND WorkingStatus='${envConfig.WorkingStatus}'&limit=10&offset=0&totalResults=true&orderBy=EmployeeName:asc`;
    let url = envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          if (empId) {
            let sdata = response.items.filter(
              (item) => item.EmployeeId != empId
            );
            this.setState({
              autoloading: false,
              supervisorData: sdata,
            });
          } else {
            this.setState({
              autoloading: false,
              supervisorData:
                response.items && response.items.length ? response.items : [],
            });
          }
        }
      },
      (error) => {}
    );
  };

  handleSupervisorAutoChange = (event, selectedValue) => {
    const { fields } = this.state;
    fields.SupervisorId = selectedValue.EmployeeId;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm("SupervisorId", selectedValue.EmployeeId);
      }
    );
  };

  render() {
    const {
      fields,
      errors,
      formSubmit,
      territorySelected,
      salutationData,
      empTypeData,
      genderData,
      currencyData,
      nationalityData,
      shiftData,
      empId,
      workCalendarData,
      employeeGroupData,
      PersonTypeData,
      territoryData,
      autoloading,
      autoFields,
      supervisorData,
      userPermissions,
      sData,
      selectedImage,
      isProgress
    } = this.state;
    const isChecked = (value) => value == "Y";

    return (
      <div className="lead-wraper">
        {!userPermissions.read ||
        (empId == 0 && !userPermissions.add) ||
        (empId > 0 && !userPermissions.update) ? (
          <AccessDenied />
        ) : (
          <Form onSubmit={this.save}>
            <div className="formWhiteBg">
              <div className="formHead">
                <h2>EMPLOYEE INFORMATION</h2>
              </div>
              <Row>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Select
                      value={salutationData.find(
                        (o) => o.value === fields.Title
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "Title")}
                      options={salutationData}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="FirstName"
                      value={fields.FirstName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.FirstName !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.FirstName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="MiddleName"
                      value={fields.MiddleName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="LastName"
                      value={fields.LastName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.LastName !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.LastName}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <div className="employee-img-wrap d-none">
                    <img src={EmployeeImg} alt="" />
                    <Link to="#" className="edit-emp-img"></Link>
                  </div>
                  <Form.Group>
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      type="text"
                      name="Email"
                      value={fields.Email}
                      onChange={(e) => this.handleOnChange(e)}
                      disabled={empId > 0 ? "disabled" : ""}
                    />
                    {errors.Email !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.Email}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Employee Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="EmployeeCode"
                      value={fields.EmployeeCode}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.EmployeeCode !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.EmployeeCode}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Work Status</Form.Label>
                    <Select
                      value={sData.workStatus.find(
                        (o) => o.value === fields.WorkingStatus
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "WorkingStatus")
                      }
                      options={sData.workStatus}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <div>
                      <RadioGroup
                        aria-label="gender"
                        name="Gender"
                        value={fields.Gender}
                        onChange={(e) => this.handleOnChange(e)}
                        className="inline-form-element"
                      >
                        {genderData.map((data, index) => (
                          <FormControlLabel
                            key={index}
                            value={data.Code}
                            control={<Radio />}
                            label={data.Name}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Profile Image</Form.Label>
                    <Dropzone
                      ref={dropzoneRef}
                      noClick
                      noKeyboard
                      onDrop={this.onDrop}
                      maxFiles={1}
                      multiple={false}
                      accept="image/png, image/jpeg, application/pdf"
                    >
                      {({ getRootProps, getInputProps, acceptedFiles }) => {
                        return (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="user-img">
                              {selectedImage !== "" ? (
                                <img
                                  src={selectedImage}
                                  alt="Profile Image"
                                  className="selected-image"
                                />
                              ) : (
                                <img
                                  src={
                                    envConfig.IMG_SERVLET_URL +
                                    "?imageId=" +
                                    empId +
                                    "&imageType=EMPLOYEE"
                                  }
                                  alt="Profile Image"
                                  className="selected-image"
                                />
                              )}
                              <Link
                                to="#"
                                className="upload-icon"
                                onClick={openDialog}
                              >
                                <img src={ProfileUpload} alt="Profile Image" />
                              </Link>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                      type="text"
                      name="MobileNo"
                      value={fields.MobileNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    </Form.Group>
                </Col>
                {fields.WorkingStatus == "RESIGNED" && (
                  <Col sm={3}>
                    <Form.Group>
                      <Form.Label>Resignation Date*</Form.Label>
                      <DatePicker
                        name="ResignationDate"
                        value={fields.ResignationDate}
                        onChange={(date) =>
                          this.handleDateOnChange(date, "ResignationDate")
                        }
                        format="dd/MM/yyyy"
                      />
                      {errors.ResignationDate !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.ResignationDate}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>USERNAME AND PASSWORD</h2>
              </div>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Username*</Form.Label>
                    <Form.Control
                      type="text"
                      name="UserName"
                      value={fields.UserName}
                      onChange={(e) => this.handleOnChange(e)}
                      // disabled={empId > 0 ? "disabled" : ""}
                      readOnly={true}
                    />
                    {errors.UserName !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.UserName}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Password*</Form.Label>
                    <Form.Control
                      type="password"
                      name="Password"
                      value={fields.Password}
                      onChange={(e) => this.handleOnChange(e)}
                      // disabled={empId > 0 ? "disabled": ""}
                    />
                    {errors.Password !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.Password}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>DATES</h2>
              </div>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Date Of Birth</Form.Label>
                    <DatePicker
                      name="DateOfBirth"
                      value={fields.DateOfBirth}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "DateOfBirth")
                      }
                      format="dd/MM/yyyy"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Date Of Confirmation</Form.Label>
                    <DatePicker
                      name="DateOfConfirm"
                      value={fields.DateOfConfirm}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "DateOfConfirm")
                      }
                      format="dd/MM/yyyy"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Hire Date*</Form.Label>
                    <DatePicker
                      name="DateOfJoin"
                      value={fields.DateOfJoin}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "DateOfJoin")
                      }
                      format="dd/MM/yyyy"
                    />
                    {errors.DateOfJoin !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.DateOfJoin}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Date Of Notice</Form.Label>
                    <DatePicker
                      name="DateOfNotice"
                      value={fields.DateOfNotice}
                      onChange={(date) =>
                        this.handleDateOnChange(date, "DateOfNotice")
                      }
                      format="dd/MM/yyyy"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>GENERAL DETAILS</h2>
              </div>

              <Row>
                <Col sm={6}>
                  <Form.Group className="d-flex justify-content-between align-items-center">
                    <Form.Label>Marital Status</Form.Label>
                    <RadioGroup
                      aria-label="MaritalStatus"
                      name="MaritalStatus"
                      value={fields.MaritalStatus}
                      onChange={(e) => this.handleOnChange(e)}
                      className="inline-form-element"
                    >
                      <FormControlLabel
                        value="MARRIED"
                        control={<Radio />}
                        label="Married"
                      />
                      <FormControlLabel
                        value="SINGLE"
                        control={<Radio />}
                        label="single"
                      />
                    </RadioGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Name Of Spouse</Form.Label>
                    <Form.Control
                      type="text"
                      name="SpouseName"
                      value={fields.SpouseName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Person Type</Form.Label>
                    <div className="select-control">
                      <Select
                        className="select_item"
                        value={PersonTypeData.find(
                          (o) => o.value === fields.PersonType
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "PersonType")
                        }
                        options={PersonTypeData}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Access Card No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="SwapCardNo"
                      value={fields.SwapCardNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Workday Shift</Form.Label>
                    <Select
                      value={shiftData.find((o) => o.value === fields.ShiftId)}
                      onChange={(e) => this.selectHandleOnChange(e, "ShiftId")}
                      options={shiftData}
                    />
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Father's Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="FatherName"
                      value={fields.FatherName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Nationality</Form.Label>
                    <Select
                      value={nationalityData.find(
                        (o) => o.value === fields.NationalityId
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "NationalityId")
                      }
                      options={nationalityData}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Employee Type*</Form.Label>
                    <Select
                      value={empTypeData.find(
                        (o) => o.value === fields.EmployementStatus
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "EmployementStatus")
                      }
                      options={empTypeData}
                    />
                    {errors.EmployementStatus !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.EmployementStatus}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>ID Card No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="IdCardNo"
                      value={fields.IdCardNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Workday Calender</Form.Label>
                    <Select
                      value={workCalendarData.find(
                        (o) => o.value === fields.WdCalId
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "WdCalId")}
                      options={workCalendarData}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Employee Group</Form.Label>
                    <Select
                      value={employeeGroupData.find(
                        (o) => o.value === fields.EmployeeGroup
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "EmployeeGroup")
                      }
                      options={employeeGroupData}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>ASSIGNMENTS</h2>
              </div>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Department Name*</Form.Label>
                    <AutoComplete
                      freeSolo
                      id="DeptId"
                      multiple
                      disableClearable
                      options={territoryData}
                      loading={autoloading}
                      getOptionLabel={(option) => option.TerritoryName}
                      getOptionSelected={(option, value) =>
                        option.TerritoryId === value.TerritoryId
                      }
                      onChange={this.handleAutoChange}
                      value={territorySelected}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Search Department"}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                              <React.Fragment>
                                {autoloading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Supervisor</Form.Label>
                    <AutoComplete
                      freeSolo
                      id="SupervisorId"
                      disableClearable
                      options={supervisorData}
                      loading={autoloading}
                      getOptionLabel={(option) => option.EmployeeName}
                      onChange={this.handleSupervisorAutoChange}
                      inputValue={autoFields.SupervisorIdSearchTerm}
                      onInputChange={this.handleSupervisorInputChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Search Supervisor"}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                              <React.Fragment>
                                {autoloading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>BENIFITS</h2>
              </div>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Pan Card</Form.Label>
                    <Form.Control
                      type="text"
                      name="PanCard"
                      value={fields.PanCard}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Salary Account No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="SalaryAccountNo"
                      value={fields.SalaryAccountNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <FormControlLabel
                      className="d-flex justify-content-between"
                      control={
                        <Switch
                          checked={isChecked(fields.PfDeduction)}
                          onChange={(e) => this.handleOnChange(e)}
                          name="PfDeduction"
                          color="primary"
                        />
                      }
                      label="PF Deduction"
                      labelPlacement="start"
                    />
                  </Form.Group>
                  <Form.Group
                    className={isChecked(fields.EsiCovered) ? "" : "d-none"}
                  >
                    <Form.Label>ESI No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="EsiNo"
                      value={fields.EsiNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Health Insurance No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="HealthInsNo"
                      value={fields.HealthInsNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Aadhaar No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="AadhaarNo"
                      value={fields.AadhaarNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                  <Row>
                    <Col lg={8} sm={6}>
                      <Form.Group>
                        <Form.Label>Hourly Rate</Form.Label>
                        <Form.Control
                          type="text"
                          name="HourlyRate"
                          value={fields.HourlyRate}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4} sm={6}>
                      <Form.Group>
                        <Form.Label>Currency</Form.Label>
                        <Select
                          value={currencyData.find(
                            (o) => o.value === fields.HourlyRateCurr
                          )}
                          onChange={(e) =>
                            this.selectHandleOnChange(e, "HourlyRateCurr")
                          }
                          options={currencyData}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row
                    className={isChecked(fields.PfDeduction) ? "" : "d-none"}
                  >
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>PF No</Form.Label>
                        <Form.Control
                          type="text"
                          name="PfNo"
                          value={fields.PfNo}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>UAN No</Form.Label>
                        <Form.Control
                          type="text"
                          name="PfUanNo"
                          value={fields.PfUanNo}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row
                    className={isChecked(fields.PfDeduction) ? "" : "d-none"}
                  >
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>PF Enrollment Date </Form.Label>
                        <DatePicker
                          name="PfEnrollmentDate"
                          value={fields.PfEnrollmentDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, "PfEnrollmentDate")
                          }
                          format="dd/MM/yyyy"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>PF Remarks</Form.Label>
                        <Form.Control
                          type="text"
                          name="PfRemarks"
                          value={fields.PfRemarks}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <FormControlLabel
                      className="d-flex justify-content-between"
                      control={
                        <Switch
                          checked={isChecked(fields.EsiCovered)}
                          onChange={(e) => this.handleOnChange(e)}
                          name="EsiCovered"
                          color="primary"
                        />
                      }
                      label="ESI Covered"
                      labelPlacement="start"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="formWhiteBg fmt-30">
              <div className="formHead">
                <h2>DEDUCTIONS</h2>
              </div>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <FormControlLabel
                      className="d-flex justify-content-between"
                      control={
                        <Switch
                          checked={isChecked(fields.ExtraDutyDeduction)}
                          onChange={(e) => this.handleOnChange(e)}
                          name="ExtraDutyDeduction"
                          color="primary"
                        />
                      }
                      label="Extra Duty Deduction"
                      labelPlacement="start"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <FormControlLabel
                      className="d-flex justify-content-between"
                      control={
                        <Switch
                          checked={isChecked(fields.DayBataDeduction)}
                          onChange={(e) => this.handleOnChange(e)}
                          name="DayBataDeduction"
                          color="primary"
                        />
                      }
                      label="Day Beta Deduction"
                      labelPlacement="start"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit" disabled={isProgress}>
                  {!isProgress ? (empId > 0 ? "Update" : "Save") : "Processing"}
                </Button>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}

export { AddEmployee };
