import React from "react";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history, common } from "_helpers";
import OsmoMaterialTable from "crm/OsmoMaterialTable";

const localizer = momentLocalizer(moment);
class Meeting extends React.Component {
  constructor() {
    super();
    this.state = {
      isTableView: true,
      currentPage: 1,
      pageLimit: 20,
      crmStatusList: [],
      objectId: 0,
      objectType: "",
      calEventList: [],
    };
  }

  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.setState(
        {
          objectId: this.props.objectId,
          objectType: this.props.objectType,
        },
        () => {
          this.getAllMeetings();
        }
      );
    }
  }

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (_) => {
    this.setState({
      isTableView: !this.state.isTableView,
    });
  };

  /**
   * Get all meetings from DB
   */
  getAllMeetings = () => {
    const { currentPage, OrderBy, pageLimit, objectId, calEventList } =
      this.state;

    let filter = `?q=ObjectId=${objectId}&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    let url = encodeURI(envConfig.BASE_API + Services.CRM_MEETINGS + filter);
    restServices.getRequest(url, (response) => {
      if (response) {
        if (response.items != null && response.items.length > 0) {
          for (const item of response.items) {
            calEventList.push({
              title: item.title,
              start: moment.utc(item.startTime).toDate(),
              end: moment.utc(item.endTime).toDate(),
            });
          }
          this.setState({
            calEventList: calEventList,
          });
        }
      }
    });
  };

  onAction = (data) => {
    const { objectId, objectType } = this.state;
    if (data.type == "edit" || data.type == "view") {
      history.push(
        "/addmeeting/" + objectType + "/" + objectId + "/" + data.id
      );
    } else if (data.type == "add") {
      history.push("/addmeeting/" + objectType + "/" + objectId);
    }
  };

  render() {
    const { calEventList, objectId, objectType } = this.state;

    return (
      <div className="lead-wraper">
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-4">
              <div className="form-row">
                <div className="col-7 float-left">
                  <div className="tab-section">
                    <ul className="tab-list">
                      <li className={this.state.isTableView ? "active" : ""}>
                        <Link to="#" onClick={(e) => this.changeTabView(e)}>
                          Table
                        </Link>
                      </li>
                      <li className={!this.state.isTableView ? "active" : ""}>
                        <Link to="#" onClick={(e) => this.changeTabView(e)}>
                          Calendar
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        {objectId > 0 && (
          <div className="crm-data-wrap">
            <div className="clearfix"></div>

            <div className={this.state.isTableView ? "crm-show" : "crm-hide"}>
              <OsmoMaterialTable
                module="meeting"
                add="Add Meeting"
                update="Mass Update"
                query={{
                  ObjectId: objectId,
                  ObjectType: common.getObjectNameByPage(objectType),
                }}
                sort="CreatedOn:desc"
                onAction={(data) => this.onAction(data)}
              />
            </div>
            <div className={!this.state.isTableView ? "crm-show" : "crm-hide"}>
              <div className="calender-view">
                <Calendar
                  localizer={localizer}
                  events={calEventList}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Meeting;
