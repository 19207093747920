import React, { createRef } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices, tools } from "_helpers";

// Import Images
import ProfileUpload from "img/profile_upload.png";
import Dropzone from "react-dropzone";

// Defined Errors
const defErrs = {
  FirstName: {
    isReq: "First name is required!",
  },
  LastName: {
    isReq: "Last name is required!",
  },
  Gender: {
    isReq: "Please Select Gender",
  },
  DefaultTimezone: {
    isReq: "Please Select Timezone",
  },
  // AlternativeEmailAddress1: {
  //   isValidEmail: "Please enter valid email address!",
  // },
  // AlternativeEmailAddress2: {
  //   isValidEmail: "Please enter valid email address!",
  // },
};

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};
const userData = common.authInfo();

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      isAdding: false,
      UserId: userData.UserId,
      UserName: userData.UserName,
      EmployeeName: userData.EmployeeName,
      EmployeeId: userData.EmpId,
      fields: {
        FirstName: "",
        LastName: "",
        MobileNo: "",
        Gender: "",
        DefaultLocation: "",
        DefaultLanguage: "",
        DefaultTimezone: "",
        UserFileType: "",
        UserFileName: "",
        UserPicPath: "",
        Nattr1: userData.Nattr1,
        // AlternativeEmailAddress1: "",
        // AlternativeEmailAddress2: "",
      },
      imageUploadFields: {
        ObjectId: userData.UserId,
        ObjectType: "user_pics",
        FileName: "",
        FileType: "",
        FileSize: "",
        TenantId: userData.TenantId,
        StartDate: null,
        EndDate: null,
        OrgId: userData.DefaultOrgId,
      },
      masterData: {
        genderData: [],
        LocationData: [],
        LanguageData: [],
        TimezoneData: [],
      },
      fileBase64Content: "",
      selectedImage: "",
      errors: {},
    };
  }

  componentDidMount() {
    this.defineInitErrorMsg();
    this.getLookUpData("GENDER", "genderData");
    this.getLookUpData("COUNTRY_CODE", "LocationData");
    this.getLookUpData("LANGUAGES", "LanguageData");
    this.getLookUpData("TIMEZONES", "TimezoneData");

    const { UserId } = this.state;
    this.setState(
      {
        UserId: UserId,
      },
      () => {
        if (UserId > 0) {
          this.getDetailById(UserId);
        }
      }
    );
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + "/" + id);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState({
            fields: fields,
            errors: errors,
          });
        } else {
        }
      },
      (error) => {}
    );
  };

  /**
   * Redirect to work page
   */
  onCancel = () => {
    history.push("/work");
  };

  validateForm = (name, value) => {
    const { errors, fields } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "AlternativeEmailAddress1":
        case "AlternativeEmailAddress2":
          if (fields[name] && !tools.ValidateEmail(fields[name])) {
            errors[name] = defErrs[name].isValidEmail;
          } else {
            errors[name] = "";
          }
          break;
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=200&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] = common.getOptionsData(
            response.items,
            "Name",
            "Code"
          );
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => {}
    );
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    var inputValue = value;

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, value);
      }
    );
  };

  update = (event) => {
    event.preventDefault();
    const { errors, UserId, fields, selectedImage, imageUploadFields } =
      this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      this.setState({ isAdding: true });
      if (selectedImage !== "") {
        if (
          fields.Nattr1 == "" ||
          fields.Nattr1 == null ||
          fields.Nattr1 == undefined
        ) {
          this.UploadFile();
        } else {
          this.getImageUrl();
        }
      } else {
        this.profileUpdate();
      }
    }
  };

  // Profille update
  profileUpdate = () => {
    const { fields, UserId } = this.state;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + "/" + UserId);
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          if (userData != null) {
            userData["EmployeeName"] =
              response.FirstName + " " + response.LastName;
            userData["FirstName"] = response.FirstName;
            userData["LastName"] = response.LastName;
            userData["DefaultTimezone"] = response.DefaultTimezone;
            // userData["AlternativeEmailAddress1"] = response.AlternativeEmailAddress1;
            // userData["AlternativeEmailAddress2"] = response.AlternativeEmailAddress2;
            userData["ProfileImg"] =
              envConfig.IMG_SERVLET_URL +
              "?imageId=" +
              response.UserId +
              "&imageType=USER";
          }
          localStorage.setItem("userData", tools.crypt(userData));
          common.snack("S", "Your Profile Successfully Updated.");
          this.employeeProfileUpdate();
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({ isAdding: false });
      }
    );
  };

  // Employee update
  employeeProfileUpdate = () => {
    const { fields, EmployeeId } = this.state;
    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + EmployeeId
    );
    let data = {
      ProfilePicPath: fields.UserPicPath,
      ProfileFileType: fields.UserFileType,
      ProfileFileName: fields.UserFileName,
      FirstName: fields.FirstName,
      LastName: fields.LastName,
      MobileNo: fields.MobileNo,
      Gender: fields.Gender,
      Country: fields.Country,
    };
    restServices.patchRequest(
      url,
      data,
      (response) => {
        window.location.href = "";
      },
      (error) => {
        this.setState({ isAdding: false });
      }
    );
  };

  /**
   * Save files using Drop method
   */
  onDrop = (files) => {
    const { imageUploadFields } = this.state;
    imageUploadFields.FileName = files[0].name;
    imageUploadFields.FileType = files[0].type;
    imageUploadFields.FileSize = files[0].size;
    this.setState({
      imageUploadFields: imageUploadFields,
    });
    if (files.size != 0) {
      common.getBase64(files[0], (result) => {
        this.setState(
          {
            fileBase64Content: result,
            selectedImage: URL.createObjectURL(files[0]),
          },
          () => {
            // this.UploadFile();
          }
        );
      });
    }
  };

  /**
   * Upload files to server
   */
  UploadFile = () => {
    const { imageUploadFields, fileBase64Content, fields } = this.state;
    //let token = userData.token;
    // let headers = {
    //   "Content-Type": "application/vnd.oracle.adf.resourceitem+json",
    //   Authorization: "Bearer " + token,
    // };
    let url = envConfig.BASE_API + Services.CRM_FILES;

    restServices.postRequest(
      url,
      imageUploadFields,
      (response) => {
        if (response) {
          if (response.AttachmentFileId != null && fileBase64Content != null) {
            fields.Nattr1 = response.AttachmentFileId;
            this.setState(
              {
                fields,
              },
              (_) => {
                this.getImageUrl();
              }
            );
          }
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  getImageUrl = () => {
    const { fields, fileBase64Content } = this.state;
    let updateUrl =
      envConfig.BASE_API + Services.CRM_FILES + "/" + fields.Nattr1;
    let updateData = {
      FileImageBlob: fileBase64Content,
    };
    restServices.patchRequest(
      updateUrl,
      updateData,
      (response) => {
        if (response) {
          fields.UserFileName = response.FileName;
          fields.UserFileType = response.FileType;
          fields.UserPicPath = response.FilePath;
          this.setState(
            {
              fields,
            },
            (_) => {
              this.profileUpdate();
            }
          );
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  render() {
    const {
      fields,
      UserId,
      masterData,
      errors,
      formSubmit,
      Gender,
      currentPage,
      selectedImage,
      isAdding,
    } = this.state;
    return (
      <div className="lead-wraper">
        <Form onSubmit={this.update}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Profile INFORMATION</h2>
            </div>

            <Row>
              <Col sm={6}>
                <div className="user-block d-flex flex-wrap align-items-center mb-4">
                  <Dropzone
                    ref={dropzoneRef}
                    noClick
                    noKeyboard
                    onDrop={this.onDrop}
                    maxFiles={1}
                    multiple={false}
                    accept="image/png, image/jpeg, application/pdf"
                  >
                    {({ getRootProps, getInputProps, acceptedFiles }) => {
                      return (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="user-img mr-3">
                            {/* {selectedImage == "" && fields.UserPicPath == null &&
                                  <img src={ProfileImg} alt="Profile Image" />
                                } */}
                            {selectedImage !== "" ? (
                              <img
                                src={selectedImage}
                                alt="Profile Image"
                                className="selected-image"
                              />
                            ) : (
                              <img
                                src={common.getUserProfileImg(UserId)}
                                alt="Profile Image"
                                className="selected-image"
                              />
                            )}
                            <Link
                              to="#"
                              className="upload-icon"
                              onClick={openDialog}
                            >
                              <img src={ProfileUpload} alt="Profile Image" />
                            </Link>
                          </div>
                        </div>
                      );
                    }}
                  </Dropzone>
                  <div className="user-info">
                    <h4>{this.state.EmployeeName}</h4>
                    <p className="mb-0">{this.state.UserName}</p>
                  </div>
                </div>
              </Col>
              <Col sm={6} className="text-right">
                <Link to="/proile/password-change" className="btn btn-add mb-3">
                  Change Password
                </Link>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="FirstName"
                    value={fields.FirstName}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.FirstName !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.FirstName}</Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="LastName"
                    value={fields.LastName}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.LastName !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.LastName}</Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Gender*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.genderData.find(
                        (o) => o.value == fields.Gender
                      )}
                      onChange={(e) => this.selectHandleOnChange(e, "Gender")}
                      options={masterData.genderData}
                    />
                  </div>
                  {errors.Gender !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.Gender}</Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Country/Region</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.LocationData.find(
                        (o) => o.value == fields.DefaultLocation
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "DefaultLocation")
                      }
                      options={masterData.LocationData}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="MobileNo"
                    value={fields.MobileNo}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Language</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.LanguageData.find(
                        (o) => o.value == fields.DefaultLanguage
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "DefaultLanguage")
                      }
                      options={masterData.LanguageData}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Time Zone</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.TimezoneData.find(
                        (o) => o.value == fields.DefaultTimezone
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "DefaultTimezone")
                      }
                      options={masterData.TimezoneData}
                    />
                  </div>
                  {errors.DefaultTimezone !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.DefaultTimezone}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              {/* <Col sm={6}>
                <Form.Group>
                  <Form.Label>Alternate Email One</Form.Label>
                  <Form.Control
                    type="text"
                    name="AlternativeEmailAddress1"
                    value={fields.AlternativeEmailAddress1}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.AlternativeEmailAddress1 !== "" &&
                    formSubmit === true && (
                      <Form.Text className="error">
                        {errors.AlternativeEmailAddress1}
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Alternate Email Two</Form.Label>
                  <Form.Control
                    type="text"
                    name="AlternativeEmailAddress2"
                    value={fields.AlternativeEmailAddress2}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.AlternativeEmailAddress2 !== "" &&
                    formSubmit === true && (
                      <Form.Text className="error">
                        {errors.AlternativeEmailAddress2}
                      </Form.Text>
                    )}
                </Form.Group>
              </Col> */}
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit" disabled={isAdding}>
                {isAdding ? "Processing..." : "Update"}
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
                disabled={isAdding}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Profile;
