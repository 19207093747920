import React, { useEffect, useState, Fragment } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
// material
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// api
import * as Services from "_config/api";
import { envConfig, eduTechConfig } from "_config/config";
import { common, restServices, eduTechSerivces, history } from "_helpers";
import { FormFieldAuto } from "pages/_components/FormFields";
// pages
import Loader from "_components/_loader/Loader";
// css
import "./includes/DynamicForm.css";
import DeleteIcon from "img/delete.svg";

const selectDefault = { label: "", value: "" };
const reviewStatus = [
  { value: "missing", label: "Missing" },
  { value: "under_review", label: "Under Review" },
  { value: "reviewed", label: "Reviewed" },
];
const canApprStatus = [
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];
const directPayVerify = [
  { value: "paid", label: "Verified" },
  { value: "invalid", label: "Invalid" },
];

function EduTechDeal(props) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [status, setStatus] = useState("process");
  const [imgPreview, setImgPreview] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [fields, setFields] = useState({}); //crm deal response
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [fileC, setFileC] = useState([]);
  const [dealStatus, setDealStatus] = useState(eduTechConfig.DealStatus);
  const [mSel, setMSel] = useState([]);
  const initialMFields = {
    field: "",
    desc: "",
    status: "missing",
  };
  const [mFields, setMFields] = useState([initialMFields]);

  // effect
  useEffect(() => {
    if (props.data.value1 == "") {
      history.push("/crm/deal");
    } else {
      setFields(props.data.dealData);
      setCRMDealData(props.data.dealData);
    }
  }, []);

  const setCRMDealData = (data) => {
    let newFields = common.reParse(data);

    newFields.SalesPersonId = {
      label: data.SalesPersonIdDesc,
      value: data.SalesPersonId,
    }; //sales person
    setDealStatus(common.enableDealStatus(data.ApprStatus));
    console.log(data.Cattr39, data.Cattr40);
    newFields.Cattr39 = data.Cattr39 || "N";
    newFields.Cattr40 = data.Cattr40 || "N";
    newFields.ApprStatus = { label: data.ApprStatus, value: data.ApprStatus }; //deal status
    newFields.Cattr16 = { label: data.Cattr16, value: data.Cattr16 }; //direct payemnt status
    newFields.Cattr20 = { label: data.Cattr20, value: data.Cattr20 }; //canRequest acceptance status
    if (data.Clattr6 && data.Cattr6) {
      setMFields(JSON.parse(data.Clattr6));
      setMSel(data.Cattr6.split(","));
    }
    setFields(newFields);
    if (data.Cattr23 == "Direct" && data.ApprStatus == "Ready for Payment") {
      let dStatus = eduTechConfig.DealStatus;
      dStatus.map((obj) => {
        if (obj.value == "Fees Paid") {
          obj.disabled = false;
        }
      });
      setDealStatus(dStatus);
    }
    setStatus("completed");
  };

  // const getResposiblePersonEmail = (empId) => {
  //   let url = encodeURI(
  //     envConfig.BASE_API +
  //       Services.CRM_PAY_EMPLOYEE +
  //       "/" +
  //       empId +
  //       "?fields=Email"
  //   );
  //   restServices.getRequest(
  //     url,
  //     (response) => {
  //       if (response) {
  //         // setResPersonEMail(response.Email);
  //         let newFields = common.reParse(fields);
  //         newFields.responsiblePersonEmail = response.Email;
  //         setFields(newFields);
  //       }
  //     },
  //     (error) => {}
  //   );
  // };

  // Form field auto handler
  const onSelect = (value, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = value || selectDefault;
    newFields.isUpdate = true;
    setFields(newFields);
    // if (name == "SalesPersonId" && value) {
    //   getResposiblePersonEmail(value.value);
    // }
    if (value && value.value == "Conditionally Accepted") {
    } else if (value && value.value == "Unconditionally Accepted") {
    }
  };
  // Missing Select handler
  const onMissingSelect = (data, name, idx) => {
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData[idx][name] = name == "desc" ? data : data.value;
      return newData;
    });
    if (name == "field" && data.value && !mSel.includes(data.label)) {
      let selected = mFields.map((a) => a.field);
      selected.push(data.value);
      setMSel(selected);
    }
  };

  const addMRow = (e) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      newData.push({
        field: "",
        desc: "",
        status: "missing",
      });
      return newData;
    });
  };

  const removeMRow = (e, idx) => {
    e.preventDefault();
    setMFields((oldData) => {
      let newData = common.reParse(oldData);
      let removeLabel = newData[idx].field;
      newData.splice(idx, 1);
      return newData;
    });
  };

  const onFieldChange = (e, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = e.target.checked ? "Y" : "N";
    setFields(newFields);
  };

  // Select handler
  const onSelectHandler = (data, name) => {
    let newFields = common.reParse(fields);
    newFields[name] = data;
    setFields(newFields);
  };

  //textarea, text, , datepicker handler
  const onChange = (e) => {
    let newFields = common.reParse(fields);
    if (
      e &&
      e.target &&
      (e.target.type == "text" || e.target.type == "textarea")
    ) {
      const { name, value } = e.target;
      newFields[name] = value;
    } else {
      newFields["Dattr1"] = e;
    }
    newFields.isUpdate = true;
    setFields(newFields);
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const onFileChangeC = (e) => {
    const files = e.target.files;
    setFileC((prev) => {
      let nData = [...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }

      return result;
    });
  };

  const delImage = (e, src) => {
    e.preventDefault();
    let newFields = common.reParse(fields);
    if (src == "fileA") {
      setFileA([]);
      newFields.Cattr8 = "";
    } else if (src == "fileB") {
      setFileB([]);
      newFields.Cattr12 = "";
    } else if (src == "fileC") {
      setFileC([]);
      newFields.Cattr21 = "";
    }
    setFields(newFields);
  };

  const uploadFile = async (idx) => {
    var FormDataObj = new FormData();
    if (idx == "fileA") {
      fileA.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileB") {
      fileB.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    } else if (idx == "fileC") {
      fileC.forEach((i, k) => {
        FormDataObj.append("file", i);
      });
    }

    let data = {
      url: "common_upload_direct",
      method: "POST",
      auth: "config",
      body: FormDataObj,
      ssr: true,
      cType: 6,
    };
    let resultData = await eduTechSerivces.call(data);
    return resultData;
  };

  const previewImage = (e, file) => {
    e.preventDefault();
    setImgPreview(true);
    setImgPreviewSrc(file);
  };

  const handleClose = () => {
    setImgPreview(false);
    setImgPreviewSrc("");
  };

  //go back to list page
  const onCancel = () => {
    history.push("/crm/deal");
  };

  const update = async () => {
    setIsSubmit(true);
    let aResult = {};
    let bResult = {};
    let cResult = {};
    let error = "";
    if (fields.ApprStatus.value == "Conditionally Accepted") {
      // upload 1
      if (fileA?.length) {
        aResult = await uploadFile("fileA");
        if (aResult.status !== 200) {
          error = aResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Unconditionally Accepted") {
      // upload 2
      if (!error && fileB?.length) {
        bResult = await uploadFile("fileB");
        if (bResult.status !== 200) {
          error = bResult.data.error;
        }
      }
    } else if (fields.ApprStatus.value == "Acceptance Statement") {
      // upload 3
      if (!error && fileC?.length) {
        cResult = await uploadFile("fileC");
        if (cResult.status !== 200) {
          error = cResult.data.error;
        }
      }
    }
    if (error) {
      common.snack("E", error);
      setIsSubmit(false);
    } else {
      let inputs = await formInputs(aResult, bResult, cResult);
      if (inputs.valid == true && inputs.dealData && inputs.appData) {
        let url =
          envConfig.BASE_API +
          Services.CRM_DEAL_OPPORTUNITY +
          "/" +
          props.data.value1;

        restServices.patchRequest(
          url,
          inputs.dealData,
          async (response) => {
            window.scrollTo(0, 0);
            setIsSubmit(false);
            if (Object.keys(inputs.appData).length > 0) {
              await eduTechSerivces.updateApplicationInfo(
                fields.Cattr2,
                inputs.appData
              );
            }
            if (typeof props.onSave !== "undefined") {
              props.onSave(response);
              setFileA([]);
              setFileB([]);
              setFileC([]);
              setCRMDealData(response);
            }
            common.snack("S", "Successfully Updated");
            setDealStatus(common.enableDealStatus(fields.ApprStatus.value));
          },
          (error) => {
            setIsSubmit(false);
          }
        );
      } else {
        setIsSubmit(false);
      }
    }
  };

  const formInputs = async (aResult, bResult, cResult) => {
    let appData = {};
    let uFields = {};
    let valid = true;

    //Deal status
    if (fields.ApprStatus.value) uFields.ApprStatus = fields.ApprStatus.value;
    if (fields.ApprStatus.value == "G2G/Submitted") {
      appData.status = "submitted_to_university";
    }
    if (fields.ApprStatus.value == "Conditionally Accepted") {
      uFields.Cattr39 = fields.Cattr39;
      uFields.Cattr40 = fields.Cattr40;
      let validCndOffer = true;
      if (aResult.data?.link?.length || fields.Cattr8) {
        let url = aResult.data?.link[0] || fields.Cattr8;
        appData.status = "conditional_offer";
        appData.offerLetter = url; //offerletter url
        uFields.Cattr8 = url; //offerletter url
        appData.missingInfo = []; //clear missing info
      } else {
        validCndOffer = false;
        valid = false;
        common.snack("E", "Please upload conditional offer letter");
        setIsSubmit(false);
      }
      if (validCndOffer) {
        if (fields.Cattr39 == "Y" || fields.Cattr40 == "Y") {
          if (fields.Cattr39 == "Y") {
            if (fields.Cattr5 && fields.Dattr1) {
              appData.interViewLocation = fields.Cattr5; //interview loc
              appData.interviewDate = common.formatDate(
                fields.Dattr1,
                "YYYY-MM-DDTHH:mm:ssZ"
              );

              uFields.Cattr5 = fields.Cattr5;
              uFields.Dattr1 = common.formatDate(
                fields.Dattr1,
                "YYYY-MM-DDTHH:mm:ssZ"
              );
            } else {
              valid = false;
              common.snack("E", "Please fill the interview location and date");
              setIsSubmit(false);
            }
          }
          if (fields.Cattr40 == "Y") {
            if (mFields.length > 0) {
              let mData = [];
              mFields.map((obj) => {
                if (obj.field && obj.desc && obj.status) {
                  mData.push({
                    field: obj.field,
                    description: obj.desc,
                    status: obj.status,
                  });
                } else {
                  valid = false;
                }
              });
              if (valid == false) {
                valid = false;
                common.snack("E", "Plese fill the missing information");
                setIsSubmit(false);
              } else {
                appData.missingInfo = mData;

                var filtered = mSel.filter(Boolean);
                uFields.Cattr6 = filtered.join(",");
                uFields.Clattr6 = JSON.stringify(mFields);
              }
            }
          }
        } else {
          valid = false;
          common.snack("E", "Please select atleast one conditional offer");
          setIsSubmit(false);
        }
      }
    } else if (fields.ApprStatus.value == "Unconditionally Accepted") {
      if (bResult.data?.link?.length) {
        let url = bResult.data?.link[0];
        appData.status = "unconditional_offer";
        appData.offerLetter = url; //offerletter url

        uFields.Cattr12 = url;
        //clear missing fields
        uFields.Cattr6 = "";
        uFields.Clattr6 = null;
      } else {
        valid = false;
        common.snack("E", "Please upload unconditional offer letter");
        setIsSubmit(false);
      }
    } else if (
      fields.ApprStatus.value == "Ready for Payment" &&
      fields.Cattr23 == "Direct"
    ) {
      appData.status = "course_payment";
      appData.action = fields.Cattr16.value;
      uFields.Cattr16 = fields.Cattr16.value;
      if (fields.Cattr16.value == "paid") {
        fields.ApprStatus = { label: "Fees Paid", value: "Fees Paid", disabled: true };
        uFields.ApprStatus = "Fees Paid";
      }
    } else if (fields.ApprStatus.value == "Ready for Payment") {
      appData.status = "course_payment";
    } else if (fields.ApprStatus.value == "Acceptance Statement") {
      if (cResult.data?.link?.length) {
        let url = cResult.data?.link[0] || fields.Cattr21;
        appData.status = "accepted";
        appData.casDocs = url; //acceptance url

        uFields.Cattr21 = url;
      } else {
        valid = false;
        common.snack("E", "Please upload acceptance letter");
        setIsSubmit(false);
      }
    } else if (fields.ApprStatus.value == "Enrolled") {
      appData.status = "enrolled";
      appData.description = "Course Enrolled Successfully";
    } else if (fields.ApprStatus.value == "Visa Applied") {
      appData.status = "visa";
      appData.action = "applied";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Visa Refused") {
      appData.status = "visa";
      appData.action = "rejected";
      appData.description = fields.Cattr18;
      uFields.Cattr18 = fields.Cattr18;
    } else if (fields.ApprStatus.value == "Visa Missing") {
      appData.status = "visa";
      appData.action = "missing";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Visa Approved") {
      appData.status = "visa";
      appData.action = "approved";
      appData.description = "";
    } else if (fields.ApprStatus.value == "Enrolled") {
      appData.status = "enrolled";
      appData.description = "Course Enrolled Successfully";
    } else if (fields.Cattr19 && fields.Cattr20 && fields.Cattr20.value) {
      //cancelled request
      uFields.Cattr19 = fields.Cattr19;
      uFields.Cattr20 = fields.Cattr20.value;
      if (fields.Cattr20.value == "accepted") {
        uFields.ApprStatus = "Application Withdrawn";
        appData.status = "cancelled";
        appData.action = "accepted";
      } else {
        appData.status = "cancelled";
        appData.action = "rejected";
        appData.description = fields.Cattr24;
        uFields.Cattr24 = fields.Cattr24;
      }
      //appData
    } else if (fields.ApprStatus.value == "Application Rejected") {
      appData.status = "rejected";
      appData.description = fields.Cattr17;
      uFields.Cattr17 = fields.Cattr17;
    }

    appData.dealId = props.data.value1;
    //Responsible person
    if (fields.SalesPersonId.value) {
      uFields.SalesPersonId = fields.SalesPersonId.value;
      appData.salesPersonId = fields.SalesPersonId.value;
      // appData.responsiblePersonEmail = fields.responsiblePersonEmail;
    }
    return {
      valid: valid,
      dealData: uFields,
      appData: appData,
    };
  };

  const renderAttachment = (docs, name) => {
    return (
      <div className="mt-1">
        {docs &&
          docs.map((image, idx) => {
            return ["jpg", "jpeg", "png"].includes(image.split(".").pop()) ? (
              <>
                <img
                  key={idx}
                  src={common.loadEduImage(image)}
                  className="eduTechImg"
                  onClick={(e) => previewImage(e, image)}
                />
                {name && (
                  <img
                    src={DeleteIcon}
                    className="del-icon"
                    alt="Delete"
                    onClick={(e) => delImage(e, name)}
                  />
                )}
              </>
            ) : (
              <div className="attach-link" key={idx}>
                <a href={common.loadEduImage(image)} download>
                  <img src={common.loadImg("attachment.png")} />
                  {image.split("/").pop()}
                </a>
                {name && (
                  <img
                    src={DeleteIcon}
                    className="del-icon"
                    alt="Delete"
                    onClick={(e) => delImage(e, name)}
                  />
                )}
              </div>
            );
          })}
      </div>
    );
  };
  // render
  return (
    <Fragment>
      {status == "process" ? (
        <Loader />
      ) : (
        status == "completed" && (
          <Fragment>
            <div>
              <Row className="mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Deal Information</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>{" "}
                        {fields.Cattr10 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        {fields.Cattr11 || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        {fields.Email || "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Mobile</Form.Label>
                        {fields.MobileNumber || "-"}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Application ID</Form.Label>{" "}
                        {fields.Cattr2 || "-"}
                      </Form.Group>
                    </Col>

                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Agent Email</Form.Label>{" "}
                        {fields.Cattr1 && fields.Cattr4
                          ? fields.Cattr1 + " & " + fields.Cattr4
                          : "-"}
                      </Form.Group>
                    </Col>
                  </Row>
                  {fields.Eattr2 && (
                    <Row>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Course</Form.Label>
                          {fields.Eattr2 || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>University</Form.Label>
                          {fields.Eattr1 || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Intake</Form.Label>
                          {fields.Eattr5} {fields.Eattr6 || "-"}
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row className="last-row mb-3">
                <Col sm={12} className="formWhiteBg">
                  <div className="formHead">
                    <h2>Application Status</h2>
                  </div>
                  <Row>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Responsible Person</Form.Label>
                        <FormFieldAuto
                          isClearable={false}
                          isRender={true}
                          name="SalesPersonId"
                          type="FindEmployee"
                          value={fields.SalesPersonId}
                          onSelect={onSelect}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Deal Status</Form.Label>
                        <Select
                          onChange={(data) =>
                            onSelectHandler(data, "ApprStatus")
                          }
                          options={dealStatus}
                          isOptionDisabled={(option) => option.disabled}
                          defaultValue={fields["ApprStatus"]}
                        />
                      </Form.Group>
                    </Col>
                    {fields.ApprStatus.value == "Conditionally Accepted" && (
                      <Col sm={6}>
                        <Form.Label>Conditional Offer</Form.Label>
                        <Form.Group>
                          <FormControlLabel
                            value={fields.Cattr39}
                            control={<Checkbox color="primary" />}
                            label={"Interview Scheduled?"}
                            labelPlacement="end"
                            checked={fields.Cattr39 === "Y" ? true : false}
                            onChange={(e) => onFieldChange(e, "Cattr39")}
                          />
                          <FormControlLabel
                            value={fields.Cattr40}
                            control={<Checkbox color="primary" />}
                            label={"Is Missing Information?"}
                            labelPlacement="end"
                            checked={fields.Cattr40 === "Y" ? true : false}
                            onChange={(e) => onFieldChange(e, "Cattr40")}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {fields.ApprStatus.value == "Application Rejected" && (
                      <Col sm={3}>
                        <Form.Label>Application Rejected Reason</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="Cattr17"
                          rows={2}
                          value={fields.Cattr17}
                          maxLength={150}
                          onChange={(e) => onChange(e)}
                        />
                      </Col>
                    )}
                    {fields.ApprStatus.value == "Visa Refused" && (
                      <Col sm={3}>
                        <Form.Label>Visa Rejected Reason</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="Cattr18"
                          rows={2}
                          value={fields.Cattr18}
                          maxLength={150}
                          onChange={(e) => onChange(e)}
                        />
                      </Col>
                    )}
                  </Row>
                  {fields.ApprStatus.value == "Conditionally Accepted" &&
                    fields.Cattr39 == "Y" && (
                      <>
                        <Row className="mb-3">
                          <Col className="sub-head-title">
                            <h2>Interview</h2>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Interview Date</Form.Label>
                              <DatePicker
                                isClearable={true}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy hh:mm aa"
                                placeholderText="Select Date and Time"
                                selected={
                                  fields.Dattr1
                                    ? moment(fields.Dattr1).toDate()
                                    : null
                                }
                                onChange={(date) => onChange(date)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Interview Location</Form.Label>
                              <Form.Control
                                type="text"
                                name="Cattr5"
                                onChange={(e) => onChange(e)}
                                value={fields.Cattr5 || ""}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}
                  {fields.ApprStatus.value == "Conditionally Accepted" &&
                    fields.Cattr40 == "Y" && (
                      <>
                        <Row>
                          <Col className="sub-head-title">
                            <h2>Missing Information</h2>
                          </Col>
                        </Row>
                        {mFields.map((mObj, idx) => {
                          let clCount = idx;
                          return (
                            <Row key={clCount}>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Missing Field</Form.Label>
                                  <Select
                                    value={eduTechConfig.docMissingFields.find(
                                      (o) => o.value == mObj.field
                                    )}
                                    isMulti={false}
                                    onChange={(data) =>
                                      onMissingSelect(data, "field", clCount)
                                    }
                                    options={eduTechConfig.docMissingFields.filter(
                                      (obj) => !mSel.includes(obj.value)
                                    )}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Missing Field Note</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={1}
                                    value={mObj.desc}
                                    onChange={(e) =>
                                      onMissingSelect(
                                        e.target.value,
                                        "desc",
                                        clCount
                                      )
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                <Form.Group className="mb-3">
                                  <Form.Label>Review Status</Form.Label>
                                  <Select
                                    value={reviewStatus.find(
                                      (o) => o.value == mObj.status
                                    )}
                                    isMulti={false}
                                    onChange={(data) =>
                                      onMissingSelect(data, "status", idx)
                                    }
                                    options={reviewStatus}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm="3">
                                <div className="mt-4 form-group">
                                  {mFields.length === 1 && idx === 0 ? (
                                    <Link
                                      to="#"
                                      className="add-rule-link add-rule"
                                      onClick={(e) => {
                                        addMRow(e);
                                      }}
                                    >
                                      +
                                    </Link>
                                  ) : (
                                    idx === 0 && (
                                      <Link
                                        to="#"
                                        className="add-rule-link remove-rule"
                                        onClick={(e) => {
                                          removeMRow(e, clCount);
                                        }}
                                      >
                                        -
                                      </Link>
                                    )
                                  )}
                                  {idx > 0 && (
                                    <React.Fragment>
                                      <Link
                                        to="#"
                                        className="add-rule-link remove-rule"
                                        onClick={(e) => removeMRow(e, clCount)}
                                      >
                                        -
                                      </Link>
                                      {mFields.length - 1 == idx &&
                                        mFields.length < 5 && (
                                          <Link
                                            to="#"
                                            className="add-rule-link add-rule"
                                            onClick={(e) => addMRow(e)}
                                          >
                                            +
                                          </Link>
                                        )}
                                    </React.Fragment>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}{" "}
                      </>
                    )}
                  {fields.ApprStatus.value == "Conditionally Accepted" && (
                    <>
                      <Row>
                        <Col className="sub-head-title">
                          <h2>Offer Letter</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          {/* <Form.Group className="mb-3"> */}
                          {/* <Form.Label>Offer Letter</Form.Label> */}
                          {fileA.length == 0 && !fields.Cattr8 && (
                            <div className="fileUpload saveBtn btn btn-primary">
                              <label className="upload">
                                <input
                                  type="file"
                                  id="fileA"
                                  name="offerLetter1"
                                  accept=".pdf,.jpg,.png,.doc"
                                  value=""
                                  multiple={false}
                                  onChange={(e) => onFileChangeA(e)}
                                />
                                Upload
                              </label>
                            </div>
                          )}
                          <br />
                          {fileA.map((i, k) => (
                            <p className="file-list" key={k}>
                              {i.name}
                              <img
                                src={DeleteIcon}
                                className="del-icon"
                                alt="Delete"
                                onClick={(e) => delImage(e, "fileA")}
                              />
                            </p>
                          ))}
                          {fields.Cattr8 &&
                            renderAttachment([fields.Cattr8], "fileA")}
                          {/* </Form.Group> */}
                        </Col>
                      </Row>
                    </>
                  )}

                  {fields.ApprStatus.value == "Unconditionally Accepted" && (
                    <>
                      <Row className="mb-3">
                        <Col className="sub-head-title">
                          <h2>Unconditional Offer</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Offer Letter</Form.Label>
                            {fileB.length == 0 && !fields.Cattr12 && (
                              <div className="fileUpload saveBtn btn btn-primary">
                                <label className="upload">
                                  <input
                                    type="file"
                                    id="fileB"
                                    name="offerLetter2"
                                    placeholder="Upload"
                                    accept=".pdf,.jpg,.png,.doc"
                                    value=""
                                    multiple={false}
                                    onChange={(e) => onFileChangeB(e)}
                                  />
                                  Upload
                                </label>
                              </div>
                            )}

                            <br />
                            {fileB.map((i, k) => (
                              <p key={k}>
                                {i.name}
                                <img
                                  src={DeleteIcon}
                                  className="del-icon"
                                  alt="Delete"
                                  onClick={(e) => delImage(e, "fileB")}
                                />
                              </p>
                            ))}
                            {fields.Cattr12 &&
                              renderAttachment([fields.Cattr12], "fileB")}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}

                  {fields.ApprStatus.value == "Acceptance Statement" && (
                    <>
                      <Row>
                        <Col className="sub-head-title">
                          <h2>Acceptance Letter</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Acceptance Letter Upload</Form.Label>
                            {fileC.length == 0 && !fields.Cattr21 && (
                              <div className="fileUpload saveBtn btn btn-primary">
                                <label className="upload">
                                  <input
                                    type="file"
                                    id="fileC"
                                    accept=".pdf,.jpg,.png,.doc"
                                    name="acceptanceLetter"
                                    multiple={false}
                                    value=""
                                    placeholder="Upload"
                                    onChange={(e) => onFileChangeC(e)}
                                  />
                                  Upload
                                </label>
                              </div>
                            )}

                            {fileC.map((i, k) => (
                              <p key={k}>
                                {i.name}
                                <img
                                  src={DeleteIcon}
                                  className="del-icon"
                                  alt="Delete"
                                  onClick={(e) => delImage(e, "fileC")}
                                />
                              </p>
                            ))}
                            <br />
                            {fields.Cattr21 &&
                              renderAttachment([fields.Cattr21], "fileC")}
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>

              {(fields.Cattr8 || fields.Cattr12 || fields.Cattr21) &&
                ![
                  "Conditionally Accepted",
                  "Unconditionally Accepted",
                ].includes(fields.ApprStatus.value) && (
                  <Row className="last-row mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Offer</h2>
                      </div>
                      <Row>
                        {fields.Cattr8 && (
                          <Col sm={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>Conditional Offer Letter</Form.Label>
                              {fields.Cattr8 &&
                                renderAttachment([fields.Cattr8])}
                            </Form.Group>
                          </Col>
                        )}
                        {fields.Cattr12 && (
                          <Col sm={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Unconditional Offer Letter
                              </Form.Label>
                              {fields.Cattr12 &&
                                renderAttachment([fields.Cattr12])}
                            </Form.Group>
                          </Col>
                        )}
                        {fields.Cattr21 && (
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Acceptance Statement</Form.Label>
                              {fields.Cattr21 &&
                                renderAttachment([fields.Cattr21])}
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                )}

              {fields.Cattr19 && (
                <Row className="last-row mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Application Cancel Request</h2>
                    </div>
                    <Row>
                      <Col sm={9}>
                        <Form.Group className="mb-3">
                          <Form.Label>Request Message</Form.Label>{" "}
                          {fields.Cattr19 || "-"}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Request Acceptance</Form.Label>{" "}
                          <Select
                            value={fields.Cattr20}
                            isMulti={false}
                            onChange={(data) =>
                              onSelectHandler(data, "Cattr20")
                            }
                            options={canApprStatus}
                          />
                        </Form.Group>
                      </Col>
                      {fields.Cattr20 && fields.Cattr20.value == "rejected" && (
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Request Rejected Reason</Form.Label>{" "}
                            <Form.Control
                              as="textarea"
                              name="Cattr24"
                              rows={2}
                              value={fields.Cattr24}
                              maxLength={150}
                              onChange={(e) => onChange(e)}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}

              {fields.Cattr23 && (
                <Row className="last-row mb-3">
                  <Col sm={12} className="formWhiteBg">
                    <div className="formHead">
                      <h2>Fee Payment</h2>
                    </div>
                    <Row>
                      <Col sm={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Mode</Form.Label>{" "}
                          {fields.Cattr23 || "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Date</Form.Label>{" "}
                          {fields.Dattr2
                            ? common.formatDate(
                                fields.Dattr2,
                                "DD-MM-YYYY HH:MM a"
                              )
                            : "-"}
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>Payment Amount</Form.Label>
                          {fields.Cattr15 || "-"}
                        </Form.Group>
                      </Col>
                      {fields.Cattr23 == "Direct" &&
                      fields.ApprStatus.value == "Ready for Payment" ? (
                        <>
                          <Col sm={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>Payment Document</Form.Label>
                              {renderAttachment([fields.Cattr13])}
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Direct Payment Verification
                              </Form.Label>{" "}
                              {/* <FormControlLabel
                                value={fields.Cattr16}
                                control={<Checkbox color="primary" />}
                                label={"Payment Verified?"}
                                labelPlacement="end"
                                checked={fields.Cattr16 === "Y" ? true : false}
                                onChange={(e) => onFieldChange(e, "Cattr16")}
                              /> */}
                              <Select
                                value={directPayVerify.find(
                                  (o) => o.value == fields.Cattr16.value
                                )}
                                isMulti={false}
                                onChange={(data) =>
                                  onSelectHandler(data, "Cattr16")
                                }
                                options={directPayVerify}
                              />
                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        <Col sm={2}>
                          <Form.Group className="mb-3">
                            <Form.Label>Payment Transaction</Form.Label>{" "}
                            {fields.Cattr7 || "-"}
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}

              {fields.Cattr14 ||
                (fields.Cattr25 && (
                  <Row className="last-row mb-3">
                    <Col sm={12} className="formWhiteBg">
                      <div className="formHead">
                        <h2>Visa Information</h2>
                      </div>
                      <Row>
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Visa Documents</Form.Label>
                            {fields.Cattr25
                              ? renderAttachment([fields.Cattr25])
                              : "-"}
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>Visa - Connected Agent</Form.Label>{" "}
                            {fields.Cattr14 || "-"}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
            </div>
            <div className="leadfooter-btn">
              <Row className={!props.quick ? "botBtn" : ""}>
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    type="submit"
                    onClick={() => update()}
                    disabled={false}
                  >
                    {isSubmit ? "Processing..." : "Update"}
                  </Button>
                  <Button
                    className="cancelBtn"
                    type="button"
                    onClick={() => onCancel()}
                    disabled={isSubmit}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>

            {imgPreview && (
              <Dialog
                open={imgPreview}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="false"
                minWidth="false"
              >
                <DialogTitle
                  id="responsive-dialog-title"
                  className="popup-title"
                >
                  <a target="_blank"  href={common.loadEduImage(imgPreviewSrc)} download>
                    View in New Tab
                  </a>{" "}
                  {"  "}
                  <Link to="#" onClick={handleClose}>
                    Close
                  </Link>
                </DialogTitle>
                <DialogContent>
                  <div className="popup-form-wrap imgDlgContent">
                    <div className="list-row text-center">
                      <img
                        src={common.loadEduImage(imgPreviewSrc) || null}
                        alt="image"
                      />
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}

            {/* <Dialog
              open={isDialog === "delete"}
              onClose={() => deleteNo()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Confirmation"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete the file?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteNo()} color="primary">
                  No
                </Button>
                <Button onClick={() => deleteYes()} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog> */}
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export default EduTechDeal;
