import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

// Import Images
import SearchIcon from "img/search-icn.png";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import ShiftMasterDetail from "./ShiftMasterDetail";
// includes
import { crm } from '_utils';
class ShiftMasterList extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: {},
      leaveSettingData: [],
      selectedLeaveSetting: {},
      selectedLeaveSettingId: 0,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    // this.getList();    
  }
  /**
   * Methods
   */
  handleClickList = (leaveSetting) => {
    this.setState(
      {
        selectedLeaveSetting: leaveSetting,
        selectedLeaveSettingId: leaveSetting.LeaveSettingId,
        isLoading: true,
      },
      (_) => {
        const { selectedLeaveSetting: selectedLeaveSetting } = this.state;
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    this.getList(value);
  };

  /**
   * Service Request
   */
  getList(term = "") {
    this.setState({
      isSearchLoad: true,
      searchList: [],
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(LeaveType) LIKE '*${term}*' &limit=10&offset=0&totalResults=true&orderBy=CreatedOn:desc`;

    let url = envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        common.snack("S", "Successfully fetched data");
        if (response && response.items && response.items.length > 0) {
          this.setState(
            {
              searchList: response,
              isSearchLoad: false,
              leaveSettingData:
                response && response.items.length > 0 ? response.items : [],
            },
            (_) => {
              const { leaveSettingData } = this.state;
              this.handleClickList(leaveSettingData[0]);
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
          });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedLeaveSetting: selectedLeaveSetting,
      selectedLeaveSettingId,
      isLoading,
    } = this.state;

    return (
      <div className="crm-list-wrap">
        <Row>
          <Col lg={3} sm={4}>
            <div className="marketing-list-wrap">
              <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                <h2 className="crm-type-title">Shift Master</h2>
                <Link to={"/hrms/shiftmaster/add"} className="btn btn-add">
                  Add
                </Link>
              </div>

              <Paper>
                <div className="input-group search-ml-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img
                        src={SearchIcon}
                        alt="Search"
                        onClick={this.getList}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="marketListName"
                    onChange={(e) => this.handleSearchChange(e)}
                    placeholder="Filter and search"
                    name="marketListName"
                  />
                </div>
                <ul className="marketing-list">
                  {/* Dummy Data Starts Here */}
                  <li className={`d-flex justify-content-between align-items-center`}>
                    <div className="mlLeft mr-3">
                      <h4 className="ml-name">Shift 1</h4>
                      <p className="ml-type">8:00 AM - 5:00 PM</p>
                    </div>
                    <div className="mlRight">
                      <Link to={"/setup/hrms/leavesetting/edit/"} >
                        Change
                      </Link>
                    </div>
                  </li>
                  <li className={`d-flex justify-content-between align-items-center`}>
                    <div className="mlLeft mr-3">
                      <h4 className="ml-name">Shift 2</h4>
                      <p className="ml-type">1:00 PM - 9:00 PM</p>
                    </div>
                    <div className="mlRight">
                      <Link to={"/setup/hrms/leavesetting/edit/"} >
                        Change
                      </Link>
                    </div>
                  </li>
                  <li className={`d-flex justify-content-between align-items-center`}>
                    <div className="mlLeft mr-3">
                      <h4 className="ml-name">Shift 3</h4>
                      <p className="ml-type">12:00 PM - 9:00 PM</p>
                    </div>
                    <div className="mlRight">
                      <Link to={"/setup/hrms/leavesetting/edit/"} >
                        Change
                      </Link>
                    </div>
                  </li>
                  {/* Dummy Data Ends Here */}

                  {isSearchLoad == false &&
                    searchList.items &&
                    searchList.items.length > 0 &&
                    searchList.items.map((row, index) => {
                      return (
                        <li
                          onClick={(e) => this.handleClickList(row)}
                          key={index}
                          className={`d-flex justify-content-between align-items-center ${row.LeaveSettingId == selectedLeaveSetting.LeaveSettingId
                            ? "active"
                            : ""
                            }`}
                        >
                          <div className="mlLeft mr-3">
                            <h4 className="ml-name">{row.LeaveType}</h4>
                            <p className="ml-type">{row.DepartmentId}</p>
                          </div>
                          {/* <div className="mlRight">
                            <Link to={"/setup/hrms/leavesetting/edit/" + row.LeaveSettingId} >
                              Change
                            </Link>
                          </div> */}
                        </li>
                      );
                    })}
                  {isSearchLoad == true && <Loader />}
                  {isSearchLoad == false &&
                    searchList.items &&
                    searchList.items.length === 0 && <NoRecordFound />}
                </ul>
              </Paper>
            </div>
          </Col>
          <Col lg={9} sm={8}>
            <div className="formTabSection position-r">
              {isLoading ? (
                <Loader />
              ) : (
                <ShiftMasterDetail objectId={selectedLeaveSettingId} data={selectedLeaveSetting} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export { ShiftMasterList };
