import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

// Import CSS file
import "./AddSalesTeam.css";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// includes
import { crm } from '_utils';
// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";

// Defined Errors
const defErrs = {
  ResourceType: {
    isReq: "Resource type is required!",
  },
  ResourceId: {
    isReq: "Resource name is required!",
  },
};

class AddSalesTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        ResourceType: "",
        ResourceId: 0,
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        Owner: "N",
      },
      autoFieds: {
        ResourceIdSearchTerm: "",
      },
      commonResource: {
        objectType: "",
        objectId: 0,
        salesTeamId: 0,
        redirect: "",
      },
      masterData: {
        resourceTypes: [],
        resourceEmp: [],
      },
      errors: {},
    };
  }

  componentDidMount() {
    const { commonResource } = this.state;
    this.defineInitErrorMsg();
    this.getAllResourceType();

    if (this.props.match.params.objectId != undefined) {
      commonResource.objectId = this.props.match.params.objectId;
      commonResource.salesTeamId = this.props.match.params.salesTeamId
        ? this.props.match.params.salesTeamId
        : 0;
      commonResource.redirect = this.props.match.params.objectType;
      this.setState(
        {
          commonResource: commonResource,
        },
        () => {
          if (commonResource.salesTeamId > 0) {
            this.getDetailById(commonResource.salesTeamId);
          }
          commonResource.objectType = common.getObjectNameByPage(
            this.state.redirect
          );
          if (this.state.redirect != "") {
            this.setState({
              commonResource: commonResource,
            });
          }
        }
      );
    }
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to call log page
   */
  onCancel = () => {
    history.goBack();
    // const { commonResource } = this.state;
    // if (commonResource.redirect === "territory") {
    //   history.push("/territory/" + commonResource.objectId + "?tab=salesteam");
    // } else {
    //   history.push(
    //     "/" +
    //       commonResource.redirect +
    //       "/edit/" +
    //       commonResource.objectId +
    //       "?tab=salesteam"
    //   );
    // }
  };

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { fields, autoFieds } = this.state;
    var splitId = id.split("-");
    fields[splitId[0]] = selectedValue.EmployeeId;
    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(splitId[0], selectedValue.EmployeeId);
      }
    );
    // this.props.handleChange(event, splitId[0], selectedValue[config.get], selectedValue);
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState({ fields: fields }, () => {
      this.validateForm(name, value);
    });
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { masterData, autoFieds, fields } = this.state;
    masterData.resourceEmp = [];

    const { id } = event.target;
    var splitId = id.split("-");

    autoFieds[splitId[0] + "SearchTerm"] = terms;

    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }

    this.setState({
      autoloading: true,
      masterData,
      autoFieds,
      fields,
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(EmployeeName) LIKE '*${terms}*' AND WorkingStatus='${envConfig.WorkingStatus}'&limit=10&offset=0&totalResults=true&orderBy=CreatedOn:desc`;
    let url = envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          masterData.resourceEmp =
            response.items && response.items.length ? response.items : [];
          this.setState({
            autoloading: false,
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  getDetailById = (id) => {
    const { empId, fields, errors } = this.state;

    let url = encodeURI(this.getBaseURLByType() + "/" + id);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState(
            {
              fields: fields,
              errors: errors,
            },
            () => {
              this.getEmpByEmpId();
            }
          );
        } else {
        }
      },
      (error) => { }
    );
  };

  getEmpByEmpId = () => {
    const { fields, autoFieds } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + "/" + fields.ResourceId
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          autoFieds["ResourceIdSearchTerm"] = response.EmployeeName;
          this.setState({
            autoFieds: autoFieds,
          });
        }
      },
      (error) => { }
    );
  };

  getAllResourceType = () => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=CHOLA_CRM_RESOURCE_TYPE,pTenantId=${crm.userInfo().pTenantId}&totalResults=true`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData.resourceTypes = common.getOptionsData(
            response.items,
            "Name",
            "Code"
          );
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.salesTeamId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  getBaseURLByType = () => {
    const { commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        return envConfig.BASE_API + Services.CRM_DEAL_SALES_TEAM;
        break;
      case "lead":
        return envConfig.BASE_API + Services.CRM_SALES_TEAM;
        break;
      case "territory":
        return envConfig.BASE_API + Services.CRM_TERRITORY_TEAM;
        break;
      default:
        break;
    }
  };

  update = () => {
    const { fields, commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        fields["OpportunityId"] = commonResource.objectId;
        break;
      case "lead":
        fields["LeadId"] = commonResource.objectId;
        break;
      case "territory":
        fields["TerritoryId"] = commonResource.objectId;
        break;
      default:
        break;
    }

    let url = encodeURI(
      this.getBaseURLByType() + "/" + commonResource.salesTeamId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          if (commonResource.redirect === "territory") {
            history.push(
              "/territory/" + commonResource.objectId + "?tab=salesteam"
            );
          } else {
            history.push(
              "/" +
              commonResource.redirect +
              "/edit/" +
              commonResource.objectId +
              "?tab=salesteam"
            );
          }
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        fields["OpportunityId"] = commonResource.objectId;
        break;
      case "lead":
        fields["LeadId"] = commonResource.objectId;
        break;
      case "territory":
        fields["TerritoryId"] = commonResource.objectId;
        break;
      default:
        break;
    }

    let url = encodeURI(this.getBaseURLByType());
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          if (commonResource.redirect === "territory") {
            history.push(
              "/territory/" + commonResource.objectId + "?tab=salesteam"
            );
          } else {
            history.push(
              "/" +
              commonResource.redirect +
              "/edit/" +
              commonResource.objectId +
              "?tab=salesteam"
            );
          }
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      fields,
      masterData,
      loading,
      autoloading,
      commonResource,
      errors,
      formSubmit,
      autoFieds,
    } = this.state;

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>SALES TEAM INFORMATION</h2>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Resource Type*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.resourceTypes.find(
                        (o) => o.value === fields.ResourceType
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "ResourceType")
                      }
                      options={masterData.resourceTypes}
                    />
                  </div>
                  {errors.ResourceType !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.ResourceType}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Resource Name*</Form.Label>
                  <AutoComplete
                    freeSolo
                    id="ResourceId"
                    disableClearable
                    options={masterData.resourceEmp}
                    loading={autoloading}
                    getOptionLabel={(option) => option.EmployeeName}
                    onChange={this.handleAutoChange}
                    inputValue={autoFieds.ResourceIdSearchTerm}
                    onInputChange={this.handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Search Resource Name"}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.ResourceId !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.ResourceId}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {commonResource.salesTeamId > 0 ? "Update" : "Save"}
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default AddSalesTeam;
