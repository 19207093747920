import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import configureStore from "./_helpers/configureStore";
import * as serviceWorker from "./serviceWorker";

// Import Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Import Snackbar
import { SnackbarProvider } from 'notistack';
import { CommonSnackbarConfig } from "./_helpers/snackbar"

const store = configureStore();

const renderApp = () =>
  render(
    <Provider store={store}>
      <SnackbarProvider 
        maxSnack={3}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CommonSnackbarConfig />
        <App />
      </SnackbarProvider>
    </Provider>,
    document.getElementById("root")
  );

// ReactDOM.render(<App />, document.getElementById('root'));

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
