import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import Checkbox from 'material-ui/Checkbox';
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from 'react-select';
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
  AddressId: {
    isReq: 'Address Id is required!',
  },
  PartyName: {
    isReq: 'Party Name is required!',
  },
  PartyType: {
    isReq: 'Party Type is required!',
  },
  PartyCode: {
    isReq: 'Party Code is required!',
  },
  Description: {
    isReq: 'Description is required!',
  },
  StartDate: {
    isReq: 'StartDate is required!',
  },
  Active: {
    isReq: 'Active is required!',
  },
};

class AddPartyGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        PartyName: '',
        PartyType: '',
        PartyCode: '',
        Description: '',
        StartDate: new Date(),
        Active: "Y",
        EndDate: null,
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
      },
      siteFields: {
        PartyId: '',
        SiteName: '',
        SiteCode: '',
        AddressId: 0,
        PrimaryFlag: "N",
        BillTo: "N",
        ShipTo: "N",
        ServiceTo: "N",
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
      },
      commonResource: {
        PartySiteId: 0,
        PartyId: 0,
      },
      address: {
        SiteId: 0,
        SiteName: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Country: '',
        PostalCode: '',
      },
      autoFields: {
        AddressIdSearchTerm: '',
      },
      masterData: {
        addressData: [],
        typeOfParty: []
      },
      errors: {}
    };
  }

  /**
   * Hook Methods
   */
  componentDidMount() {
    this.defineInitErrorMsg();
    this.getLookUpData('CHOLA_PARTY_TYPE', 'typeOfParty');
    const { commonResource } = this.state;
    if (this.props.partyId !== undefined & this.props.partyId !== 0) {

      commonResource.PartyId = this.props.partyId;
      this.setState({
        commonResource: commonResource

      }, () => {
        this.getParty();
      });
    }
  }


  /**
   * Get lookup values by lookup code
   */
  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] = common.getOptionsData(response.items, "Name", "Code");
          this.setState({
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Get Party Details by ID
   */
  getParty = () => {
    this.setState({
      isLoading: true,
    });
    const { fields, commonResource, errors } = this.state;

    let url = envConfig.BASE_API + Services.CRM_PARTY_HDR + '/' + commonResource.PartyId;

    restServices.getRequest(url, (response) => {
      if (response) {
        for (var key of Object.keys(response)) {
          if (response[key]) {
            if (fields.hasOwnProperty(key)) {
              if (
                key === 'StartDate' ||
                key === 'EndDate'
              ) {
                fields[key] = response[key] ? new Date(response[key]) : '';
              } else {
                fields[key] = response[key];

              }
            }
            if (key === "PartyId") {
              commonResource.PartyId = response[key] ? response[key] : '';
            }

            // Remove Default Errors
            if (errors.hasOwnProperty(key)) {
              // Optional Validation Here
              if (key === "Active" && response[key] === "Y") {
                errors[key] = "";
              } else if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                errors[key] = "";
              }
            }
          }

        }
      }

      this.setState({
        fields: fields,
        commonResource: commonResource,
        isLoading: false
      });
      if (this.state.commonResource.PartyId > 0) {
        this.getPartySite();
      }
    });


  }


  /**
   * Get Party Site Details by ID
   */
  getPartySite = () => {
    this.setState({
      isLoading: true,
    });
    const { siteFields, commonResource } = this.state;

    let url = envConfig.BASE_API + Services.CRM_PARTY_SITES + '?q=PartyId=' + commonResource.PartyId;
    restServices.getRequest(url, (response) => {

      if (response && response.items && response.items.length > 0) {
        let item = response.items[0];

        for (var key of Object.keys(item)) {
          if (item[key]) {
            if (siteFields.hasOwnProperty(key)) {
              siteFields[key] = item[key];
            }
            if (key === "PartySiteId") {
              commonResource.PartySiteId = item[key] ? item[key] : '';
            }
          }
        }

        this.setState({
          siteFields: siteFields,
          commonResource: commonResource,
          isLoading: false
        });

        if (siteFields.AddressId !== 0) {
          this.getLocationDetailsById();
        }
      }
    });

  }


  /**
   * Get details by Id
   */
  getLocationDetailsById = (type) => {
    const { siteFields, autoFields, address, errors } = this.state;

    let filter = `?q=TenantId=${crm.userInfo().pTenantId};` +
      `LocationId=${siteFields.AddressId}`;
    let url = envConfig.BASE_API + Services.CRM_LOCATIONS + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {

          if (response && response.items.length > 0) {
            autoFields['AddressIdSearchTerm'] = response.items[0].LocationCode;
            siteFields.AddressId = response.items[0].LocationId;
            address.SiteId = response.items[0].LocationId;
            address.SiteName = response.items[0].LocationCode;
            address.Address1 = response.items[0].AddressLine1;
            address.Address2 = response.items[0].AddressLine2;
            address.City = response.items[0].City;
            address.State = response.items[0].State;
            address.PostalCode = response.items[0].PostalCode;
            address.Country = response.items[0].CountryCode;
            errors.AddressId = "";
          }

          this.setState({
            address: address,
            autoFields: autoFields,
            errors
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Initialize error message
   */
  defineInitErrorMsg = () => {
    const { errors, fields, siteFields } = this.state;
    for (var key of Object.keys(defErrs)) {
      switch (key) {
        case "Active":
          if (fields[key] == "N") {
            errors[key] = defErrs[key].isReq;
          } else {
            errors[key] = "";
          }
          break;
        case "AddressId":
          if (siteFields[key] == "" || siteFields[key] == 0) {
            errors[key] = defErrs[key].isReq;
          } else {
            errors[key] = "";
          }
          break;
        default:
          if (fields[key] == null || fields[key] == "") {
            errors[key] = defErrs[key].isReq;
          }
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to parties
   */
  onCancel = () => {
    history.push("/setup/vendor/party");
  }

  /**
   * Input onchange event handling
   */
  handleOnChange = event => {
    const { fields, siteFields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    if (siteFields.hasOwnProperty(name)) {
      siteFields[name] = inputValue;
    }

    this.setState({
      fields: fields,
      siteFields: siteFields,
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState({ fields: fields }, () => {
      this.validateForm(name, value);
    });
  }

  /**
   * Get selected date values 
   */
  handleDateOnChange = (date, name) => {
    const { fields, siteFields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    if (siteFields.hasOwnProperty(name)) {
      siteFields[name] = date;
    }
    this.setState({
      fields: fields,
      siteFields: siteFields,
    }, () => {
      this.validateForm(name, date);
    });
  }

  /**
   * Validate form
   */
  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
    * Handle to change input values
    */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { fields, autoFields, address, siteFields } = this.state;
    var splitId = id.split("-");
    siteFields[splitId[0]] = selectedValue.LocationId;

    address.SiteId = selectedValue.LocationId;
    address.SiteName = selectedValue.LocationCode;
    address.Address1 = selectedValue.AddressLine1;
    address.Address2 = selectedValue.AddressLine2;
    address.City = selectedValue.City;
    address.State = selectedValue.State;
    address.PostalCode = selectedValue.PostalCode;
    address.Country = selectedValue.CountryCode;
    siteFields.SiteCode = selectedValue.LocationCode;
    siteFields.SiteName = selectedValue.LocationCode;
    siteFields.ShipTo = selectedValue.IsShipToLocation ? "Y" : "N";
    siteFields.BillTo = selectedValue.IsBillToLocation ? "Y" : "N";
    siteFields.PrimaryFlag = selectedValue.IsLegalAddress ? "Y" : "N";
    this.setState(
      {
        address: address,
        siteFields: siteFields,
      },
      () => {
        this.validateForm(splitId[0], selectedValue.LocationId);
      }
    );
  };

  /**
     * Handle to get changed search terms and get list based on terms
     */
  handleInputChange = (event, terms = "") => {
    const { masterData, autoFields, fields, address, siteFields } = this.state;
    masterData.addressData = [];

    address.Address1 = "";
    address.Address2 = "";
    address.City = "";
    address.State = "";
    address.PostalCode = "";
    address.Country = "";
    siteFields.AddressId = "";
    siteFields.SiteCode = "";
    siteFields.SiteName = "";
    siteFields.ShipTo = "N";
    siteFields.BillTo = "N";
    siteFields.PrimaryFlag = "N";

    const { id } = event.target;
    var splitId = id.split("-");

    autoFields[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      siteFields[splitId[0]] = "";
      this.validateForm(splitId[0], siteFields[splitId[0]]);
    }
    this.setState({
      autoloading: true,
      masterData: masterData,
      autoFields: autoFields,
      fields,
      address: address,
      siteFields: siteFields
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LocationName) LIKE '*${terms}*' &limit=10&offset=0&totalResults=true`;
    let url = envConfig.BASE_API + Services.CRM_LOCATIONS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          masterData.addressData =
            response.items && response.items.length ? response.items : [];
          this.setState({
            autoloading: false,
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };


  /**
   * Handle form submit
   */
  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.PartyId > 0) {
        this.update()
      } else {
        this.create()
      }
    }
  };

  /**
   * Update party details
   */
  update = () => {

    const { fields, commonResource } = this.state;
    var formAttr = Object.assign({}, fields);

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default: break;
      }
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_HDR + "/" + commonResource.PartyId);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        this.partySiteAddUpdate();
        //history.push("/setup/vendor/party");
      },
      (error) => { }
    );
  }

  /**
   * Create new party
   */
  create = () => {
    const { fields, siteFields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_HDR);
    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        siteFields.PartyId = response.PartyId ? response.PartyId : 0;
        this.setState({
          siteFields: siteFields,
        });
        this.partySiteAddUpdate();
      },
      (error) => { }
    )
  }

  partySiteAddUpdate = () => {
    const { siteFields, commonResource } = this.state;
    var formAttr = {};

    if (siteFields.PartyId > 0) {
      for (var key of Object.keys(siteFields)) {
        switch (key) {
          case "StartDate":
          case "EndDate":
            formAttr[key] = siteFields[key] ? common.formatDate(siteFields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
            break;
          default:
            formAttr[key] = siteFields[key];
        }
      }
      if (commonResource.PartySiteId > 0) {
        //update
        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_SITES + "/" + commonResource.PartySiteId);
        restServices.patchRequest(
          url,
          formAttr,
          (response) => {
            history.push("/setup/vendor/party");
          },
          (error) => { });
      } else {
        //create
        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_SITES);
        restServices.postRequest(
          url,
          formAttr,
          (response) => {
            history.push("/setup/vendor/party");
          },
          (error) => { })
      }
    }
  }

  render() {
    const {
      siteFields,
      address,
      fields,
      commonResource,
      errors,
      formSubmit,
      masterData,
      autoloading,
      autoFields
    } = this.state;

    const isChecked = (value) => value == 'Y';

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div>
            {/* Party Information */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Party Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Party Code*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="PartyCode"
                        onChange={(e) => this.handleOnChange(e)}
                        value={fields.PartyCode}
                        disabled={commonResource.PartyId !== 0}
                      />
                      {errors.PartyCode !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.PartyCode}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Party Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="PartyName"
                        onChange={(e) => this.handleOnChange(e)}
                        value={fields.PartyName}
                      />
                      {errors.PartyName !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.PartyName}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Party Type*</Form.Label>
                      <div className="select-control">
                        <Select
                          value={masterData.typeOfParty.find(o => o.value === fields.PartyType)}
                          onChange={(e) => this.selectHandleOnChange(e, 'PartyType')}
                          options={masterData.typeOfParty}
                        />
                      </div>
                      {errors.PartyType !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.PartyType}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="Description"
                        onChange={(e) => this.handleOnChange(e)}
                        value={fields.Description}
                        maxLength={4000}
                      />
                      {errors.Description !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.Description}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Start Date*</Form.Label>
                      <DatePicker
                        name="StartDate"
                        selected={fields.StartDate}
                        onChange={(date) =>
                          this.handleDateOnChange(date, 'StartDate')
                        }
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        minDate={new Date()}
                      />
                      {errors.StartDate !== '' && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.StartDate}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>End Date*</Form.Label>
                      <DatePicker
                        name="EndDate"
                        selected={fields.EndDate}
                        onChange={(date) =>
                          this.handleDateOnChange(date, 'EndDate')
                        }
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        minDate={new Date()}
                      />
                      {errors.EndDate !== '' && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.EndDate}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="Active"
                        checked={isChecked(fields.Active)}
                        label="Active"
                        labelStyle={{ color: '#2d2d2d' }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                      {errors.Active !== 'Y' && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.Active}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Primary Site Information */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Party Site Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Address*</Form.Label>
                      <AutoComplete
                        freeSolo
                        id="AddressId"
                        disableClearable
                        options={masterData.addressData}
                        loading={autoloading}
                        getOptionLabel={(option) => option.LocationName}
                        onChange={this.handleAutoChange}
                        inputValue={autoFields.AddressIdSearchTerm}
                        onInputChange={this.handleInputChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={'Search Address'}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              endAdornment: (
                                <React.Fragment>
                                  {autoloading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                      {errors.AddressId !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.AddressId}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Site code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="SiteCode"
                        onChange={(e) => this.handleOnChange(e)}
                        value={siteFields.SiteCode}
                      />
                      {errors.SiteCode !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.SiteCode}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Site Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="SiteName"
                        onChange={(e) => this.handleOnChange(e)}
                        value={siteFields.SiteName}
                      />
                      {errors.SiteName !== "" && formSubmit === true && (
                        <Form.Text className="error">{errors.SiteName}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="PrimaryFlag"
                        checked={isChecked(siteFields.PrimaryFlag)}
                        label="Primary"
                        labelStyle={{ color: '#2d2d2d' }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="BillTo"
                        checked={isChecked(siteFields.BillTo)}
                        label="Bill To"
                        labelStyle={{ color: '#2d2d2d' }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="ServiceTo"
                        checked={isChecked(siteFields.ServiceTo)}
                        label="Service To"
                        labelStyle={{ color: '#2d2d2d' }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="ShipTo"
                        checked={isChecked(siteFields.ShipTo)}
                        label="Ship To"
                        labelStyle={{ color: '#2d2d2d' }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Address Information */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Address Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Address 1</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.Address1}
                        name="Address1" />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Address 2</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.Address2}
                        name="Address2" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.Country}
                        name="Country" />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.State}
                        name="State" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.City}
                        name="City" />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={address.PostalCode}
                        name="State" />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Action section */}
            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  {commonResource.PartyId > 0 ? "Update" : "Save"}
                </Button>
                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

export default AddPartyGeneral;
