import React from "react";
import DynamicForm from "crm/DynamicForm";
import Loader from "_components/_loader/Loader";
import { history, common } from "_helpers";
class MeetingCrud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      eventId: 0,
      objectId: 0,
      objectType: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    const { params } = this.state;
    console.log("params :>> ", params);
    if (params.objectId != undefined) {
      this.setState({
        objectId: params.objectId,
        eventId: params.eventId,
        objectType: params.objectType,
        isLoading: false,
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  saveCallback = (res) => {
    const { params } = this.state;
    history.push(
      "/crm/" + params.objectType + "/edit/" + params.objectId + "?tab=meetings"
    );
  };

  cancelCallBack = () => {
    const { params } = this.state;
    history.push(
      "/crm/" + params.objectType + "/edit/" + params.objectId + "?tab=meetings"
    );
  };

  render() {
    const { eventId, objectId, isLoading, objectType } = this.state;
    const objName = common.getObjectNameByPage(objectType)
    return (
      <div className="lead-wraper">
        {!isLoading && (
          <DynamicForm
            module="meeting"
            data={{
              value1: (eventId > 0 ? eventId : ""),
              value2: objectId,
              value3: objName
            }}
            onSave={this.saveCallback}
            onCancel={this.cancelCallBack}
          />
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default MeetingCrud;
