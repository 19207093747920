import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history } from "_helpers";
import { FormLayoutRoute, DashboardLayoutRoute, LandingLayoutRoute } from "./routeLayout";
// crm pages
import Login from "pages/Login/Login";
import AddLead from "pages/Crm/Lead/pages/AddLead";
import LeadImport from "pages/Crm/Lead/pages/LeadImport";
import BulkImport from "pages/_components/BulkImport/BulkImport";
import LeadList from "pages/Crm/Lead/pages/LeadList";
import LeadDetails from "pages/Crm/Lead/pages/LeadDetails";
import AddDeal from "pages/Crm/Deal/pages/AddDeal";
import DealList from "pages/Crm/Deal/pages/DealList";
import DealDetails from "pages/Crm/Deal/pages/DealDetails";
import Configure from "pages/Setup/Configure/Configure";
import LeadFormSetting from "pages/Setup/FormSetting/LeadFormSetting";
import AddCampaign from "pages/Crm/Campaign/pages/AddCampaign";
import CampaignList from "pages/Crm/Campaign/pages/CampaignList";
import CampaignDetails from "pages/Crm/Campaign/pages/CampaignDetails";
import AddCallLogs from "pages/_components/CallLogs/AddCallLogs/AddCallLogs";
import AddAnnexures from "pages/_components/Annexures/AddAnnexures/AddAnnexures";
import MeetingCrud from "pages/_components/Meeting/MeetingCrud";
import MeetingDetails from "pages/_components/Meeting/MeetingDetails";
import AddSalesTeam from "pages/_components/SalesTeam/AddSalesTeam/AddSalesTeam";
import AddProductServices from "pages/_components/ProductServices/AddProductServices/AddProductServices";
// Contacts
import ContactList from "pages/_components/Contacts/Contacts";
import AddContact from "pages/_components/Contacts/AddContact/AddContact";
import Vendor from "pages/Setup/Vendor/Vendor";
import Hrms from "pages/Setup/Hrms/Hrms";
// Enquiry
import EnquiryList from "pages/Crm/Enquiry/pages/EnquiryList";
import EnquiryDetails from "pages/Crm/Enquiry/pages/EnquiryDetails";
import AddEnquiry from "pages/Crm/Enquiry/pages/AddEnquiry";
import AddEnquiryMember from "pages/Crm/Enquiry/pages/Tabs/AddEnquiryMember";
import QuickAdd from "pages/Crm/Enquiry/pages/QuickAdd";
import { Dashboard } from "pages/Dashboard";
import { StatusTransitionList, TransactionStatusList, TransactionStatusAdd } from "pages/Setup/FormTransaction";
import { StatusTransitionAdd } from "pages/Setup/FormTransaction/StatusTransition/pages/StatusTransitionAdd.js";
// Quotes Pages
import QuoteList from "pages/Crm/Quotes/QuoteList";
import Quote from "pages/Crm/Quotes/Quote";
// SalesOrder Pages
import SalesOrderList from "pages/Crm/SalesOrder/SalesOrderList";
import SalesOrder from "pages/Crm/SalesOrder/SalesOrder";
// Invoice Pages
import InvoiceList from "pages/Crm/Invoice/InvoiceList";
import Invoice from "pages/Crm/Invoice/Invoice";
//Party Pages
import {
	PartyList,
	AddParty,
	AddPartySite,
	AddClassification,
	CustomerList,
	AddCustomerInfo,
	AddCustomerSite,
	LocationList,
	AddLocations,
	AddProduct,
	ProductsList,
} from "pages/Vendor";
import MyLeadList from "pages/Crm/MyLead/pages/MyLeadList";
import MyDealList from "pages/Crm/MyDeal/pages/MyDealList";
import { AddMarketingList, AddMember } from "pages/_components/MarketingLists";
// Roles And Responsibilities
import RolesResponsibleList from "pages/Hrms/RolesResponsibility/RolesResponsibleList";
// ### HRMS ###
import {
	Division,
	AddDivision,
	EmployeeList,
	AddEmployee,
	AddEditRole,
	LeaveCalendarList,
	LeaveSettingsAdd,
	LeaveSettingsList,
} from "pages/Hrms";
import Work from "pages/Work/Work";
import { RolesList } from "pages/Setup/Roles";
import { RoleList } from "pages/Setup/Role";
// task
import TaskCrud from "pages/Task/TaskCrud";
import TaskList from "pages/Task/TaskList";
import { Target } from "pages/Target";
import { TemplatesList, AddTemplate } from "pages/Setup/Template";
import { SubEmail } from "pages/Setup/SubEmail";
import Profile from "pages/Setup/Profile/Profile";
import ChangePassword from "pages/Setup/Profile/ChangePassword";
import { ManageReportsList } from "pages/Setup/ManageReports";
import ReportsList from "pages/Reports/ReportsList";
import Landing from "pages/Landing/Landing";
import Signup from "pages/Signup/Signup";
import Pricing from "pages/Pricing/Pricing";
import ConversionMapping from "pages/Setup/Conversion/ConversionMapping";
// automation
import WorkflowList from "pages/Setup/Automation/Workflow/WorkflowList";
import WorkflowCrud from "pages/Setup/Automation/Workflow/WorkflowCrud";
// form settings
import FormSettings from "pages/Setup/FormSettings/FormSettings";
// demo
import DemoDataTable from "pages/Demo/DemoDataTable";
import DemoMaterialTable from "pages/Demo/DemoMaterialTable";
import DemoFormSettings from "pages/Demo/DemoFormSettings";
import DemoDynamicForm from "pages/Demo/DemoDynamicForm";
import DemoSelect from "pages/Demo/DemoSelect";
import DemoInfo from "pages/Demo/DemoInfo";
import DemoFormLocalUpdate from "pages/Demo/DemoFormLocalUpdate";
import DemoFormLiveUpdate from "pages/Demo/DemoFormLiveUpdate";
import DemoFormLiveDownload from "pages/Demo/DemoFormLiveDownload";
import DemoRoles from "pages/Demo/DemoRoles";
import DemoUserRoles from "pages/Demo/DemoUserRoles";

class RouterComponent extends React.Component {
	constructor(props) {
		super(props);
		history.listen((location, action) => { });
	}
	render() {
		return (
			<Router history={history}>
				<Switch>
					{/*---- front layer ----*/}
					<LandingLayoutRoute exact path="/" component={Landing} auth={false} />
					<LandingLayoutRoute
						exact
						path="/login"
						component={Login}
						auth={false}
					/>
					<LandingLayoutRoute
						exact
						path="/signup"
						component={Signup}
						auth={false}
					/>
					<LandingLayoutRoute
						exact
						path="/pricing"
						component={Pricing}
						auth={false}
					/>
					{/*---- admin layer ----*/}
					{/* profile */}
					<DashboardLayoutRoute
						exact
						path="/profile"
						component={Profile}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/proile/password-change"
						component={ChangePassword}
						auth={true}
					/>

					{/* dashboard */}
					<DashboardLayoutRoute
						exact
						path="/dashboard"
						component={Dashboard}
						auth={true}
					/>

					{/* reports */}
					<DashboardLayoutRoute
						exact
						path="/reports"
						component={ReportsList}
						auth={true}
					/>

					{/* work */}
					<DashboardLayoutRoute
						exact
						path="/work"
						component={Work}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/work/mylead"
						component={MyLeadList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/work/mylead/edit/:leadId"
						component={AddLead}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/work/mylead/:leadId"
						component={LeadDetails}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/work/mydeal"
						component={MyDealList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/work/mydeal/edit/:dealId"
						component={AddDeal}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/work/mydeal/:dealId"
						component={DealDetails}
						auth={true}
					/>

					{/* tasks */}
					<DashboardLayoutRoute
						exact
						path="/task"
						component={TaskList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/task/add"
						component={TaskCrud}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/task/edit/:taskId"
						component={TaskCrud}
						auth={true}
					/>

					{/* target */}
					<DashboardLayoutRoute
						exact
						path="/target"
						component={Target}
						auth={true}
					/>

					{/* crm */}
					<DashboardLayoutRoute
						exact
						path="/crm/campaign"
						component={CampaignList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/campaign/add"
						component={AddCampaign}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/campaign/edit/:campaignId"
						component={AddCampaign}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/campaign/:campaignId"
						component={CampaignDetails}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/enquiry"
						component={EnquiryList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/enquiry/add"
						component={AddEnquiry}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/enquiry/edit/:enquiryId"
						component={AddEnquiry}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/enquiry/:enquiryId"
						component={EnquiryDetails}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/enquiry/addmember/:enquiryId/:memberId?"
						component={AddEnquiryMember}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/lead"
						component={LeadList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/lead/add"
						component={AddLead}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/lead/import"
						component={LeadImport}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/bulk/import/:type/:listId?"
						component={BulkImport}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/lead/edit/:leadId"
						component={AddLead}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/lead/:leadId"
						component={LeadDetails}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/deal"
						component={DealList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/deal/add"
						component={AddDeal}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/deal/edit/:dealId"
						component={AddDeal}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/deal/:dealId"
						component={DealDetails}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/quote"
						component={QuoteList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/quote/add"
						component={Quote}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/quote/edit/:quoteId"
						component={Quote}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/sales-order"
						component={SalesOrderList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/sales-order/add"
						component={SalesOrder}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/sales-order/edit/:salesOrderId"
						component={SalesOrder}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/invoice"
						component={InvoiceList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/invoice/add"
						component={Invoice}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/invoice/edit/:invoiceId"
						component={Invoice}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/crm/contact"
						component={ContactList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/contact/add"
						component={AddContact}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/contact/add/:objectId"
						component={AddContact}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/crm/contact/edit/:contactId"
						component={AddContact}
						auth={true}
					/>

					{/* -- setup -- */}
					{/* hrms */}
					<DashboardLayoutRoute
						exact
						path="/setup/hrms"
						component={Hrms}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/hrms/department"
						component={Division}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/department/add/:parentId?"
						component={AddDivision}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/department/edit/:divId"
						component={AddDivision}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/hrms/employee"
						component={EmployeeList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/employee/add"
						component={AddEmployee}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/employee/edit/:empId"
						component={AddEmployee}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/employee/roles/:userId/:id?"
						component={AddEditRole}
						auth={true}
					/>


					<DashboardLayoutRoute
						exact
						path="/setup/hrms/leavecalendar"
						component={LeaveCalendarList}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/hrms/leavesetting"
						component={LeaveSettingsList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/leavesetting/add"
						component={LeaveSettingsAdd}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/hrms/leavesetting/edit/:id"
						component={LeaveSettingsAdd}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/hrms/roles/responsible"
						component={RolesResponsibleList}
						auth={true}
					/>

					{/* configure */}
					<DashboardLayoutRoute
						exact
						path="/setup/configure"
						component={Configure}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/configure/form-transaction"
						component={TransactionStatusList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/form-transaction/:transactionTypeId/:transactionID?"
						component={TransactionStatusAdd}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/form-transition"
						component={StatusTransitionList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/form-transition/:transitionTypeId/:transitionID?"
						component={StatusTransitionAdd}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/configure/form-settings"
						component={LeadFormSetting}
						auth={true}
					/>

					<DashboardLayoutRoute exact path="/setup/configure/form/settings" component={FormSettings} auth={true} />
					<DashboardLayoutRoute exact path="/setup/configure/conversion/mappings" component={ConversionMapping} auth={true} />

					<DashboardLayoutRoute
						exact
						path="/setup/configure/roles"
						component={RolesList}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/configure/role"
						component={RoleList}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/configure/template"
						component={TemplatesList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/template/add/:type"
						component={AddTemplate}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/template/edit/:id?"
						component={AddTemplate}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/sub-email"
						component={SubEmail}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/configure/automation/workflow/"
						component={WorkflowList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/automation/workflow/:type/:ruleId?"
						component={WorkflowCrud}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/configure/manage-report"
						component={ManageReportsList}
						auth={true}
					/>
					{/* vendor */}
					<DashboardLayoutRoute
						exact
						path="/setup/vendor"
						component={Vendor}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/vendor/location"
						component={LocationList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/location/add"
						component={AddLocations}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/location/edit:locationId"
						component={AddLocations}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/vendor/party"
						component={PartyList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/party/add"
						component={AddParty}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/party/edit/:partyId"
						component={AddParty}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/vendor/party-site/add/:partyId/:partySiteId?"
						component={AddPartySite}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/party-site/:partySiteId?"
						component={AddPartySite}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/classification/add/:partyId/:partyClassId?"
						component={AddClassification}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/classification/:partyClassId?"
						component={AddClassification}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/vendor/customer"
						component={CustomerList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/customer/add"
						component={AddCustomerInfo}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/customer/edit/:customerId"
						component={AddCustomerInfo}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/site/add/:customerId/:siteId?"
						component={AddCustomerSite}
						auth={true}
					/>

					<DashboardLayoutRoute
						exact
						path="/setup/vendor/product"
						component={ProductsList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/product/add"
						component={AddProduct}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/setup/vendor/product/:productId"
						component={AddProduct}
						auth={true}
					/>

					{/* others tabs */}
					<DashboardLayoutRoute
						exact
						path="/addmarketinglist/:campaignId/:listId?"
						component={AddMarketingList}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addcalllog/:objectType/:objectId/:callLogId?"
						component={AddCallLogs}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addsalesteam/:objectType/:objectId/:salesTeamId?"
						component={AddSalesTeam}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addproduct/:objectType/:objectId/:productId?"
						component={AddProductServices}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addannexure/:objectType/:objectId/:annexureId?"
						component={AddAnnexures}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addmeeting/:objectType/:objectId/:eventId?"
						component={MeetingCrud}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addmember"
						component={AddMember}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/addmember/:listID/:campaignId"
						component={AddMember}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/updatemember/:listID/:campaignId/:detailId"
						component={AddMember}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/meeting/:id"
						component={MeetingDetails}
						auth={true}
					/>
					<DashboardLayoutRoute
						exact
						path="/:module/member/quickadd/:enquiryId/:campaignId?"
						component={QuickAdd}
						auth={true}
					/>
					{/* demo */}
					<FormLayoutRoute exact path="/demo/dataTable" component={DemoDataTable} />
					<FormLayoutRoute exact path="/demo/materialTable" component={DemoMaterialTable} />
					<FormLayoutRoute exact path="/demo/formSettings" component={DemoFormSettings} />
					<FormLayoutRoute exact path="/demo/dynamicForm" component={DemoDynamicForm} />
					<FormLayoutRoute exact path="/demo/select" component={DemoSelect} />
					<FormLayoutRoute exact path="/demo/info" component={DemoInfo} />
					<FormLayoutRoute exact path="/demo/form/local/update/:module/:entity" component={DemoFormLocalUpdate} />
					<FormLayoutRoute exact path="/demo/form/live/update/:module" component={DemoFormLiveUpdate} />
					<FormLayoutRoute exact path="/demo/form/live/download/:module" component={DemoFormLiveDownload} />
					<FormLayoutRoute exact path="/demo/roles" component={DemoRoles} />
					<FormLayoutRoute exact path="/demo/user/roles" component={DemoUserRoles} />
				</Switch>
			</Router>
		);
	}
}
export default RouterComponent;
