// Import default packages
import React from "react";
import { Link } from "react-router-dom";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history, tools } from "_helpers";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Col, Button, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import moment from "moment";
import Select from "react-select";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// _import component
import CrmMenu from "_components/_crmMenu/CrmMenu";
import KanbanBoard from "../../../_components/KanbanView/KanbanBoard";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
import FieldSelect from "crm/FieldSelect";
// css
import "../css/Lead.css";
const userInfo = common.authInfo();
class LeadList extends React.Component {
  loggedEmpId = userInfo.EmpId;
  constructor(props) {
    super(props);
    this.state = {
      isListView: common.getParam("view") == "kanban" ? false : true,
      crmStatusList: [],
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.lead
      ),
      isModal: false,
      formSubmit: false,
      conversionProcess: false,
      convertData: {
        OpportunityName: "",
        ClosingDate: "",
        ExpectedRevenue: "",
        CampaignId: "",
        AccountId: "",
      },
      isValid: false,
      fields: [],
      selectedLeadId: [],
      reload: new Date(),
      errors: {
        // ExpectedRevenue: {
        //   error: "",
        //   isReq: "Amount is required",
        // },
        OpportunityName: {
          error: "",
          isReq: "Deal name is required",
        },
        // ClosingDate: {
        //   error: "",
        //   isReq: "Please select the Closing Date",
        // },
        // CampaignId: {
        //   error: "",
        //   isReq: "Please select the Campaign",
        // },
        AccountId: {
          error: "",
          isReq: "Please select the Customer Account",
        },
      },
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.read != 2) {
      this.getCRMStatusList();
      this.getFormJSON();
    }
  }

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    let leadTypeId = common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID);

    let params =
      `?q=TransactionTypeId=${leadTypeId};OrgId=` +
      `${userInfo.DefaultOrgId};TenantId=` +
      `${userInfo.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (e, status) => {
    e.preventDefault();
    this.setState({
      isListView: status,
    });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      isModal: false,
      convertData: {
        OpportunityName: "",
        ClosingDate: "",
        ExpectedRevenue: "",
        CampaignId: "",
      },
      formSubmit: false,
    });
  };

  onAction = (data) => {
    if (data.type == "convert") {
      //Verify Lead is converted or not
      let cRecords = Object.keys(data.selectedData).filter((obj) => {
        return data.selectedData[obj].OpportunityConverted == "Y";
      });
      let ncRecords = Object.keys(data.selectedData).filter((obj) => {
        return (
          data.selectedData[obj].OpportunityConverted == "N" ||
          data.selectedData[obj].OpportunityConverted == null
        );
      });

      if (cRecords.length == 0) {
        this.setState({
          isModal: true,
          selectedLeadId: data.selected,
        });
      } else {
        let cLeads = "";
        cRecords.map((obj) => {
          cLeads = cLeads + " " + data.selectedData[obj].LeadName + ",";
        });
        let ncLeads = "";
        ncRecords.map((obj) => {
          ncLeads = ncLeads + " " + data.selectedData[obj].LeadName + ",";
        });
        if (ncRecords.length > 0) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>Conversion Confirmation</h1>
                  <p>The following Leads are already converted as Deal</p>
                  <p>{cLeads}</p>
                  <p>
                    Are you sure you want to convert the remaining selected
                    Leads?
                  </p>
                  <p>{ncLeads}</p>
                  <button className="noBtn" onClick={onClose}>
                    No
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        selectedLeadId: ncRecords,
                        isModal: true,
                      });
                      onClose();
                    }}
                    className="yesBtn"
                  >
                    Yes
                  </button>
                </div>
              );
            },
          });
        } else {
          common.snack("E", "Already selected leads are converted as Deal");
        }
      }
    } else if (data.type == "edit" || data.type == "view") {
      history.push("/crm/lead/edit/" + data.id + "?view=list");
    } else if (data.type == "add") {
      history.push("/crm/lead/add");
    } else if (data.type == "import") {
      history.push("/crm/bulk/import/lead");
    }
  };

  // * Get form json data from API
  // */
  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_LEAD_CODE;

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let data = JSON.parse(response.items[0].FormFields);
          let options = [];
          data.form.fields.map((item) => {
            options.push({ value: item.key, label: item.label });
          });

          this.setState(
            {
              fields: options,
            },
            () => {}
          );
        }
      },
      (error) => {
        this.setState({
          fields: [],
        });
      }
    );
  };

  handleOnChange = (event) => {
    const { convertData } = this.state;
    const { name, value } = event.target;
    if (convertData.hasOwnProperty(name)) {
      convertData[name] = value;
    }

    this.setState({
      convertData,
    });
    this.validateAll();
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { convertData } = this.state;
    if (convertData.hasOwnProperty(name)) {
      convertData[name] = value;
    }

    this.setState({
      convertData,
    });
    this.validateAll();
  };

  onSelect = (data, name) => {
    const { convertData } = this.state;
    convertData[name] = data;
    this.setState({ convertData });
    this.validateAll();
  };

  onDateChange = (date, name) => {
    const { convertData } = this.state;
    convertData[name] = date;
    this.setState({ convertData }, () => {
      this.validateAll();
    });
  };

  validateAll() {
    const { convertData, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (convertData[key] == "" || convertData[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  convertToDeal = (e) => {
    e.preventDefault();
    let isValid = this.validateAll();
    this.setState({
      formSubmit: true,
    });
    const { convertData, selectedLeadId } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    if (isValid) {
      this.setState({
        formSubmit: false,
        conversionProcess: true,
      });
      var createParams = {
        name: "convertLeadToOpportunity",
        parameters: [
          { P_LEAD_ID: selectedLeadId.join(",") },
          { P_OPPORTUNITY_NAME: "${" + convertData.OpportunityName + "}$" },
          { P_OPPORTUNITY_TYPE: "" },
          { P_PRIORITY: "" },
          { P_SALES_PERSON_ID: userInfo.EmpId },
          { P_PHONE_NUMBER: "" },
          { P_MOBILE_NUMBER: "" },
          { P_OPPORTUNITY_DESC: "" },
          { P_EMAIL: "" },
          { P_APPR_STATUS: "NEW" },
          { P_TERRITORY_ID: userData.territory[0] },
          {
            P_CAMPAIGN_ID: convertData.CampaignId
              ? convertData.CampaignId.value > 0
                ? parseInt(convertData.CampaignId.value)
                : ""
              : "",
          },
          // {
          //   P_CLOSING_DATE: convertData.ClosingDate
          //     ? common.formatDate(
          //         convertData.ClosingDate,
          //         "YYYY-MM-DDTHH:mm:ssZ"
          //       )
          //     : "",
          // },
          {
            P_EXPECTED_REVENUE: convertData.ExpectedRevenue
              ? convertData.ExpectedRevenue
              : "",
          },
          {
            P_CUSTOMER_ID: convertData.AccountId
              ? parseInt(convertData.AccountId.value)
              : "",
          },
        ],
      };

      // if(convertData.PhoneNumber){
      //   createParams.parameters.push({ P_PHONE_NUMBER: convertData.PhoneNumber });
      // }

      // if(convertData.MobileNumber){
      //   createParams.parameters.push({ P_MOBILE_NUMBER: convertData.MobileNumber });
      // }

      if(convertData.ClosingDate){
        createParams.parameters.push({ P_CLOSING_DATE: common.formatDate(convertData.ClosingDate, "YYYY-MM-DDTHH:mm:ssZ") });
      }

      // if(convertData.CampaignId && convertData.CampaignId.value > 0){
      //   createParams.parameters.push({ P_CAMPAIGN_ID: parseInt(convertData.CampaignId.value) });
      // }

      // if(convertData.ExpectedRevenue){
      //   createParams.parameters.push({ P_EXPECTED_REVENUE: convertData.ExpectedRevenue });
      // }

      // if(convertData.AccountId && convertData.AccountId.value > 0){
      //   createParams.parameters.push({ P_CUSTOMER_ID: parseInt(convertData.AccountId.value) });
      // }

      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
      );
      restServices.postRequest(
        url,
        createParams,
        (response) => {
          if (response.status >= 200 && response.status <= 300) {
            common.snack("S", "Successfully Converted as Deal");
            this.setState({
              reload: new Date(),
            });
            this.setState({
              isModal: false,
              convertData: {
                OpportunityName: "",
                ClosingDate: "",
                ExpectedRevenue: "",
                CampaignId: "",
              },
              formSubmit: false,
              conversionProcess: false
            });
          }
        },
        (error) => {
          common.snack("E", error);
          this.setState({
            isModal: false,
            convertData: false
          });
        }
      );
    }
  };

  render() {
    const {
      userPermissions,
      crmStatusList,
      fields,
      isModal,
      formSubmit,
      convertData,
      reload,
      errors,
      conversionProcess
    } = this.state;

    return (
      <div className="lead-wraper">
        <CrmMenu />
        {userPermissions.read == 2 ? (
          <AccessDenied />
        ) : (
          <React.Fragment>
            <div className="crm-data-wrap">
              <div className="tab-section float-left">
                <ul className="tab-list">
                  <li className={this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, true)}>
                      List
                    </Link>
                  </li>
                  <li className={!this.state.isListView ? "active" : ""}>
                    <Link to="#" onClick={(e) => this.changeTabView(e, false)}>
                      Kanban
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="clearfix"></div>
              <div className={this.state.isListView ? "crm-show" : "crm-hide"}>
                <OsmoMaterialTable
                  userPermissions={userPermissions}
                  module="lead"
                  add="Add New"
                  import="Mass Import"
                  update="Mass Update"
                  convert="Mass Convert"
                  reload={reload}
                  sort="CreatedOn:desc"
                  data="OpportunityConverted,LeadName"
                  onAction={(data) => this.onAction(data)}
                />
              </div>
              {!this.state.isListView && crmStatusList != "" && (
                <KanbanBoard
                  kanbanType="lead"
                  status={crmStatusList}
                  userPermissions={userPermissions}
                  deptEmployees={this.props.deptEmployees}
                />
              )}
              {/* Dialog for Mass Convert  */}
              <Dialog
                open={isModal}
                aria-labelledby="responsive-dialog-title"
                className="leave-dialog"
              >
                <DialogTitle
                  id="responsive-dialog-title"
                  className="popup-title"
                >
                  {"Mass Convert"}
                </DialogTitle>
                <DialogContent>
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Row>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                              type="number"
                              name="ExpectedRevenue"
                              value={convertData.ExpectedRevenue}
                              onChange={(e) => this.handleOnChange(e)}
                            ></Form.Control>
                            {/* {errors.ExpectedRevenue.error !== "" &&
                              formSubmit === true && (
                                <Form.Text className="error">
                                  {errors.ExpectedRevenue.error}
                                </Form.Text>
                              )} */}
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Deal Name *</Form.Label>
                            <div className="select-control">
                              <Select
                                value={fields.find(
                                  (o) => o.value === convertData.OpportunityName
                                )}
                                onChange={(e) =>
                                  this.selectHandleOnChange(
                                    e,
                                    "OpportunityName"
                                  )
                                }
                                options={fields}
                              />
                            </div>
                            {errors.OpportunityName.error !== "" &&
                              formSubmit === true && (
                                <Form.Text className="error">
                                  {errors.OpportunityName.error}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Closing Date</Form.Label>
                            <DatePicker
                              name="ClosingDate"
                              value={
                                convertData.ClosingDate
                                  ? moment(convertData.ClosingDate).toDate()
                                  : null
                              }
                              minDate={moment().toDate()}
                              onChange={(date) =>
                                this.onDateChange(date, "ClosingDate")
                              }
                              format="dd/MM/yyyy"
                              autoComplete="off"
                            />
                            {/* {errors.ClosingDate.error !== "" &&
                              formSubmit === true && (
                                <Form.Text className="error">
                                  {errors.ClosingDate.error}
                                </Form.Text>
                              )} */}
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Campaign</Form.Label>
                            <FieldSelect
                              api="CRM_CAMPAIGNS"
                              get="CampaignName"
                              set="CampaignId"
                              value={convertData.CampaignId}
                              isMulti={false}
                              isRender={true}
                              onSelect={(data) =>
                                this.onSelect(data, "CampaignId")
                              }
                            />
                            {/* {errors.CampaignId.error !== "" &&
                              formSubmit === true && (
                                <Form.Text className="error">
                                  {errors.CampaignId.error}
                                </Form.Text>
                              )} */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group>
                            <Form.Label>Customer Account</Form.Label>
                            <FieldSelect
                              api="CRM_CUSTOMER"
                              get="CustomerName"
                              set="CustomerId"
                              value={convertData.AccountId}
                              isMulti={false}
                              isRender={true}
                              onSelect={(data) =>
                                this.onSelect(data, "AccountId")
                              }
                            />
                            {errors.AccountId.error !== "" &&
                              formSubmit === true && (
                                <Form.Text className="error">
                                  {errors.AccountId.error}
                                </Form.Text>
                              )}
                          </Form.Group>
                        </Col>
                        <Col sm={6}></Col>
                      </Row>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions className="popup-footer justify-content-start">
                  <Row className="botBtn">
                    <Col sm={12}>
                      <Button
                        variant="primary"
                        className="saveBtn"
                        onClick={(e) => this.convertToDeal(e)}
                        disabled={conversionProcess}
                      >
                        {/* Convert */}
                        {conversionProcess ? "Processing..." : "Convert"}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={(e) => this.closeModal(e)}
                        className="cancelBtn btn btn-primary"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </DialogActions>
              </Dialog>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default LeadList;
