import { common } from "_helpers";
import moment from "moment";

export const crm = {
  // local storage
  userInfo() {
    let userInfo = common.authInfo()
    let data = {
      pOrgId: userInfo.DefaultOrgId,
      pTenantId: userInfo.TenantId
    }
    return data;
  },

  selectVal() {
    return {
      label: "Select",
      value: ""
    }
  },

  numArray(num) {
    let array = [];
    for (let i = 0, j = 1; i < num; i++, j++) {
      array.push(j);
    }
    return array;
  },

  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  assignItem(data) {
    let result = [];
    for (const [key, value] of Object.entries(data)) {
      result[key] = value;
    }
    return result;
  },

  uniqueKey() {
    return Math.floor(Math.pow(10, 10 - 1) + Math.random() * 9 * Math.pow(10, 10 - 1));
  },

  // input
  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  validateDate(date) {
    if (date == null || date == "") {
      return null;
    } else {
      return moment(date).toDate();
    }
  }
}