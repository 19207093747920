import React from "react";
import { history, common } from "_helpers";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
//Import CSS
import "./CallLogsList.css";
class CallLogsList extends React.Component {
  constructor() {
    super();
    this.state = {
      objectId: 0,
      objectType: "",
    };
  }

  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.setState(
        {
          objectId: this.props.objectId,
          objectType: this.props.objectType,
        },
        () => { }
      );
    }
  }

  onAction = (data) => {
    const { objectId, objectType } = this.state;
    if (data.type == "edit" || data.type == "view") {
      history.push(
        "/addcalllog/" + objectType + "/" + objectId + "/" + data.id
      );
    } else if (data.type == "add") {
      history.push("/addcalllog/" + objectType + "/" + objectId);
    }
  };

  render() {
    const { objectId, objectType } = this.state;

    return (
      <div className="lead-wraper">
        <div className="crm-data-wrap">
          {objectId > 0 && (
            <OsmoMaterialTable
              module="callLogs"
              add="Add CallLog"
              update="Mass Update"
              query={{
                ObjectId: objectId,
                ObjectType: common.getObjectNameByPage(objectType),
              }}
              sort="CreatedOn:desc"
              onAction={(data) => this.onAction(data)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default CallLogsList;
