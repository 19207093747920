import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from "react-csv";
// external
import moment from "moment";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
// material ui
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// form
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
// input
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
// dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllSharp from '@material-ui/icons/DoneAllSharp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/Cached';
import CachedTwoTone from '@material-ui/icons/CachedTwoTone';
import AddLinkIcon from '@material-ui/icons/LinkSharp';
// internal
import { crm } from "_utils"
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools } from "_helpers";
import AutomationSelect from "crm/AutomationSelect";
// files
import serviceList from "crm/files/serviceList.json";
import formFields from "crm/files/formFields.json";
// css
import './includes/OsmoTable.css';
import { Fragment } from 'react';

function OsmoMaterialTable(props) {
    let filterData = common.getFilter(props.module);
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    const csvRef = useRef(null);
    const service = serviceList[props.module];
    const userInfo = crm.userInfo();
    const isEduTech = envConfig.EDUTECH_TENANT.includes(userInfo.pTenantId) && (props.module == "lead" || props.module == "deal");
    const userPermissions = props.userPermissions ? props.userPermissions : {
        read: true,
        add: true,
        update: true,
        delete: true,
        import: true,
        export: true,
        access: true,
      };
    // states
    const [isInit, setInit] = useState(false);
    const [status, setStatus] = useState("process");
    const [isLoader, setIsLoader] = useState("");
    const [isFilter, setIsFilter] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [anchorEl, setAnchorEl] = useState({ rows: null, columns: null, filter: null, export: null });
    const [isDialog, setDialog] = useState("");
    const [isAlert, setAlert] = useState({
        type: "",
        message: ""
    });

    const [result, setResult] = useState({
        columns: [],
        data: [],
        totalResults: 0,
        totalRecords: 0
    });

    const [filter, setFilter] = useState(filterData || [{
        logic: "AND",
        column: "",
        operator: "",
        value: ""
    }]);

    const [update, setUpdate] = useState([{
        column: "",
        value: ""
    }]);

    const [deleteId, setDeleteId] = useState("");
    const [hidedColumns, setHidedColumns] = useState([]);
    const exportType = ["Export Visible Items", "Export Filtered Items", "Export All Items"];
    const [selected, setSelected] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [sort, setSort] = useState({
        field: props.sort ? props.sort.split(":")[0] : "",
        order: props.sort ? props.sort.split(":")[1] : "",
        default: "asc"
    });
    const [paging, setPaging] = useState({ page: 1, limit: 10 });
    const [csvData, setCsvData] = useState([]);
    const [action, setAction] = useState({
        column: false
    });

    // const
    const propsData = props.data ? props.data.split(",") : [];
    const moduleLabel = {
        campaign: "Campaign List",
        enquiry: "Enquiry List",
        lead: "Lead List",
        deal: "Deal List",
        enquiryMember: "Enquiry Members",
        contacts: "Contacts",
        contactsLink: "Linked Contacts",
        meeting: "Meetings",
        callLogs: "Call Logs"
    };

    const filterOperator = {
        string: {
            "=": "Equal to",
            "!=": "Not Equal to",
            "*": "Like"
        },
        number: {
            "=": "Equal to",
            "!=": "Not Equal to",
            ">": "Greater than",
            "<": "Less than",
            ">=": "Greater than or equal to",
            "<=": "Less than or equal to",
        },
        date: {
            "=": "Equal to",
            "!=": "Not Equal to",
            ">": "Greater than",
            "<": "Less than",
            ">=": "Greater than or equal to",
            "<=": "Less than or equal to",
        },
        api: {
            "=": "Equal to",
            "!=": "Not Equal to"
        }
    };

    const fieldTypes = {
        "default": ["singleLine", "multiLine", "email", "phone", "number", "decimal", "currency", "url"],
        "bool": ["checkbox"],
        "date": ["date", "dateTime"],
        "singleSelect": ["pickList", "lookup"],
        "multipleSelect": ["multiSelect", "multiLookup"],
        "file": ["imageUpload", "fileUpload"],
    };

    const [reportData, setReportData] = useState([]);
    const [showReports, setShowReports] = useState(false);
    const [editReports, setEditReports] = useState(false);
    const [reportFilterName, setReportFilterName] = useState("");
    const [filterId, setFilterId] = useState(0);
    const [initialAPICall, setInitialAPIcall] = useState(false);
    const reportLimit = 10;
    // effect
    useEffect(() => {
        getColumnsData();
    }, [])

    useEffect(() => {
        if(initialAPICall){
            loadData();
        }
    },[initialAPICall]);

    useEffect(() => {
        if (isInit && isReload) {
            loadData();
        }
    }, [isReload])

    useEffect(() => {
        if (isInit && props.reload) {
            setSelected([]);
            setSelectedData({});
            loadData();
        }
    }, [props.reload])

    useEffect(() => {
        if (isInit && deleteId) {
            setDialog("delete")
        }
    }, [deleteId])

    // api    
    const getColumnsData = () => {
        let filter = `?q=EserviceCode=${service.code}`;
        let url = envConfig.BASE_API + Services.CRM_ESERVICE_HDR + filter
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    let formFields = JSON.parse(response.items[0].FormFields);
                    setResult(prev => ({
                        ...prev,
                        columns: columnValidate(formFields.form.fields)
                    }));
                    getHidedColumns();
                } else {
                    setStatus("error");
                }
            },
            (error) => {
                setStatus("error");
            }
        )
    };

    const getHidedColumns = () => {
        let filterUserData = userData["UserCustomFilter"] && userData["UserCustomFilter"][service.filter] ? userData["UserCustomFilter"][service.filter].split(",") : [];
        //setHidedColumns(['Cattr11']);
        setHidedColumns(filterUserData);
        setInitialAPIcall(true);
        //loadData();
        // setInit(true);
        // setStatus("success");
    }

    const callReportAction = () => {
        setShowReports(true);
        setEditReports(false);
    }
    const addNewRepFilter = (filterId, obj) => {
        setEditReports(true);
        setShowReports(false);
        if(filterId == 0){
            setReportFilterName("");
            setFilterId(0);
            setFilter([{
                logic: "AND",
                column: "",
                operator: "",
                value: ""
            }]);
        }else if(obj != null) {
            setReportFilterName(obj.ReportName);
            obj.Criteria == null ?  setFilter([{
                logic: "AND",
                column: "",
                operator: "",
                value: ""
            }]): setFilter(JSON.parse(obj.Criteria))
            
            setFilterId(filterId);
        }
    }

    const downloadFilterReport = (item) => {
        if(item.Criteria == null){
            showAlert("e","Report filter criteria not found");
        }else{
            setFilter(JSON.parse(item.Criteria));
            exportCsv(1);
        }
    }

    const getReportList = () => {
        let filter = `?q=Module=${props.module};OrgId=${userInfo.pOrgId};TenantId=${userInfo.pTenantId}&totalResults=true&limit=10&offset=0`;
        let url = envConfig.BASE_API + Services.CHOLA_USER_REPORTS + filter;
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    setReportData(response.items);
                    setShowReports(true);
                } else {
                    setStatus("error");
                }
                setInit(true);
            },
            (error) => {
                setStatus("error");
                setInit(true);
            }
        );
    }

    const reportClick = () => {    
        setInit(false);    
        getReportList();
    }

    const filterClick = () => {
        setIsFilter(true); 
        // Commentted for reseting filter issue - 29 Sep 2022 Vimaladevi
        // if(!isFilter){
        //     setFilter([{
        //         logic: "AND",
        //         column: "",
        //         operator: "",
        //         value: ""
        //     }]);
        // }
    }

    const loadData = () => {
        if(props.module == "lead"){
            loadLeadData();
        } else if(props.module == "deal"){
            loadDealData();
        } else{
            loadOtherData();
        }
    }
    
    const loadLeadData = () => {
        let userData = tools.deCrypt(localStorage.getItem("userData"));
        let loggedEmpId = userData.EmpId;

        let serviceAPI = Services[service.api];

        if(userPermissions.read == "5"){
            serviceAPI = Services.CRM_MYLEADS;
        }
        if (isInit) {
            setStatus("process");
            setIsReload(false);
        }
        let query = `?q=${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        if (userPermissions.read == "3") {
            query = `?q=SalespersonId=${loggedEmpId
                } AND ${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        } else if(userPermissions.read == "4"){
            query = `?q=TerritoryId IN(${userData.territory.join(",")
                }) AND ${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        } 
        else if(userPermissions.read == "5"){
            let qParam = `${propsQuery()}${filterQuery()}`;
            if(qParam){
                query = `?finder=FindMyLeads;p_resource_id=${loggedEmpId}&q=${propsQuery()}${filterQuery()}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
            } else {
                query = `?finder=FindMyLeads;p_resource_id=${loggedEmpId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
            }
        }
        let orderBy = ``;
        if (sort.field) {
            orderBy = `&orderBy=${sort.field}:${sort.order}`;
        }

        let url = `${envConfig.BASE_API}${serviceAPI}${query}${orderBy}`;
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    setResult(prev => ({
                        ...prev,
                        data: response.items,
                        totalResults: response.totalResults,
                        totalRecords: isInit ? prev.totalRecords : response.totalResults
                    }));
                    setStatus("success");
                } else {
                    setStatus("error");
                }
                setInit(true);
            },
            (error) => {
                setStatus("error");
                setInit(true);
            }
        )
    }

    const loadDealData = () => {
        let userData = tools.deCrypt(localStorage.getItem("userData"));
        let loggedEmpId = userData.EmpId;

        if (isInit) {
            setStatus("process");
            setIsReload(false);
        }
        let query = `?q=${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
       
        if (userPermissions.read == "3") {
            query = `?q=SalesPersonId=${loggedEmpId
                } AND ${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        } else if(userPermissions.read == "4"){
            query = `?q=TerritoryId IN(${userData.territory.join(",")
                }) AND ${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        } 
        else if(userPermissions.read == "5"){
            let qParam = `${propsQuery()}${filterQuery()}`;
            if(qParam){
                query = `?finder=FindMyOppts;pResourceId=${loggedEmpId}&q=${propsQuery()}${filterQuery()}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
            } else {
                query = `?finder=FindMyOppts;pResourceId=${loggedEmpId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
            }
        }

        let orderBy = ``;
        if (sort.field) {
            orderBy = `&orderBy=${sort.field}:${sort.order}`;
        }
        let serviceAPI = Services[service.api];

        if(userPermissions.read == "5"){
            serviceAPI = Services.CRM_MYDEALS;
        }
        let url = `${envConfig.BASE_API}${serviceAPI}${query}${orderBy}`;
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    setResult(prev => ({
                        ...prev,
                        data: response.items,
                        totalResults: response.totalResults,
                        totalRecords: isInit ? prev.totalRecords : response.totalResults
                    }));
                    setStatus("success");
                } else {
                    setStatus("error");
                }
                setInit(true);
            },
            (error) => {
                setStatus("error");
                setInit(true);
            }
        )
    }

    const loadOtherData = () => {
        let userData = tools.deCrypt(localStorage.getItem("userData"));
        let loggedEmpId = userData.EmpId;

        if (isInit) {
            setStatus("process");
            setIsReload(false);
        }
        let query = `?q=${propsQuery()}${filterQuery()}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=${paging.limit}&offset=${getPaging("offset")}`;
        let orderBy = ``;
        if (sort.field) {
            orderBy = `&orderBy=${sort.field}:${sort.order}`;
        }
        let url = `${envConfig.BASE_API}${Services[service.api]}${query}${orderBy}`;
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    setResult(prev => ({
                        ...prev,
                        data: response.items,
                        totalResults: response.totalResults,
                        totalRecords: isInit ? prev.totalRecords : response.totalResults
                    }));
                    setStatus("success");
                } else {
                    setStatus("error");
                }
                setInit(true);
            },
            (error) => {
                setStatus("error");
                setInit(true);
            }
        )
    }

    // props
    const propsQuery = () => {
        let res = "";
        if (props.query) {
            Object.entries(props.query).map(([key, item]) => {
                res += `${key}='${item}' AND `;
            })
        }
        return res;
    }

    // filter
    const filterAdd = (e) => {
        e.preventDefault();
        setFilter(pData => {
            let nData = reParse(pData);
            nData.push({
                logic: nData[0].logic,
                column: "",
                operator: "",
                value: ""
            })
            return nData;
        });
    }

    const filterRemove = (e, key) => {
        e.preventDefault();
        setFilter(pData => {
            let nData = reParse(pData);
            nData.splice(key, 1);
            return nData;
        });
    }

    const filterLogical = () => {
        setFilter(pData => {
            let nData = reParse(pData);
            let logic = (nData[0].logic === "AND" ? "OR" : "AND");
            nData = nData.map(i => { i.logic = logic; return i });
            return nData;
        });
    }

    const filterChange = (e, key) => {
        const { name, value } = e.target
        setFilter(pData => {
            let nData = reParse(pData);
            nData[key][name] = value;
            if (name === "column") {
                nData[key].operator = "";
                nData[key].value = "";
            }
            return nData;
        });
    }

    const filterValueChange = (key, value) => {
        setFilter(pData => {
            let nData = reParse(pData);
            nData[key].value = value;
            return nData;
        });
    }

    const filterSubmit = (type) => {
        let err = [];
        filter.map((i, k) => {
            if (i.column === "" || i.operator === "" || i.value === "" || i.value === null) {
                err.push(`Please fill all the values in row ${++k}`);
            }
        })
        if (err.length) {
            showAlert("e", err[0]);
        } else {
            if(type == "filter"){
                setSelected([]);
                setSelectedData({});
                setPaging({ page: 1, limit: 10 });
                setIsFilter(false);
                setIsReload(true);
                //set local stroage filter
                common.setStorageFilter(props.module, filter);
            }else if(type == "report"){
                if(reportFilterName == undefined || reportFilterName == ""){
                    showAlert("e","Report filter name required to save");
                }else {
                    if(filterId == 0){
                        //save new filter here
                        let params = {
                            UserId: userData.UserId,
                            TenantId: userData.TenantId,
                            OrgId: userData.DefaultOrgId,
                            Module: props.module,
                            ReportName : reportFilterName,
                            Criteria: JSON.stringify(filter)
                        };
                        let url = envConfig.BASE_API + Services.CHOLA_USER_REPORTS;

                        restServices.postRequest(url, params, (response) => {
                            if (response) {
                                updateReport(response);
                                common.snack("S", "Report filter saved successfully");
                                //Reset & show list of reports
                                setReportFilterName("");
                                setFilter([{
                                    logic: "AND",
                                    column: "",
                                    operator: "",
                                    value: ""
                                }]);
                                setShowReports(true);
                                setEditReports(false);
                            }
                        }, (error) => {
                            common.snack("E", error);
                            setIsLoader("");
                        });
                    }else if(filterId > 0){
                        //save new filter here
                        let params = {
                            UserId: userData.UserId,
                            TenantId: userData.TenantId,
                            OrgId: userData.DefaultOrgId,
                            Module: props.module,
                            ReportName : reportFilterName,
                            Criteria: JSON.stringify(filter)
                        };
                        let url = envConfig.BASE_API + Services.CHOLA_USER_REPORTS + "/" + filterId;

                        restServices.patchRequest(url, params, (response) => {
                            if (response) {
                                updateReport(response);
                                common.snack("S", "Report filter updated successfully");
                                //Reset & show list of reports
                                setReportFilterName("")
                                // Commentted for reseting filter issue - 29 Sep 2022 Vimaladevi
                                // setFilter([{
                                //     logic: "AND",
                                //     column: "",
                                //     operator: "",
                                //     value: ""
                                // }]);
                                setShowReports(true);
                                setEditReports(false);
                            }
                        }, (error) => {
                            common.snack("E", error);
                            setIsLoader("");
                        });
                    }
                }
            }
        }
    }

    const updateReport = (item) => {        
        var foundIndex = reportData.findIndex(x => x.UserReportId == filterId);
        if(foundIndex == -1){
            reportData.push(item);
            setReportData(reportData);
        }else {
            reportData[foundIndex] = item;
            setReportData(reportData);
        }
        
    }

    const filterReset = () => {
        setFilter([{
            logic: "AND",
            column: "",
            operator: "",
            value: ""
        }])
        setSort({
            field: "",
            order: "",
            default: "asc"
        });
        setPaging({ page: 1, limit: 10 });
        setSelected([]);
        setSelectedData({});
        setIsFilter(false);
        setIsReload(true);
        //clear local stroage filter
        common.clearFilter(props.module, filter);
    }

    const filterQuery = () => {
        let res = "";
        filter.map((i, k) => {
            if (i.column !== "") {
                let column = columnInfo(i.column);
                let value = i.value;
                if (i.operator === "*") {
                    res += `UPPER(${column.key}) LIKE '*${value}*'`;
                } else {
                    if (column.type === "multiLookup") {
                        value = value.map(function(elem){
                            return elem.value;
                        }).join(",");
                    } if (column.type === "pickList" || column.type === "lookup") {
                        value = value.value;
                    } else if (column.type === "date" || column.type === "dateTime") {
                        value = moment(value).format("YYYY-MM-DD");
                    }
                    res += `${column.key}${i.operator}'${value}'`;
                }
                res += ` ${i.logic} `;
            }
        })
        return res;
    }

    // update
    const updateAdd = (e) => {
        e.preventDefault();
        setUpdate(pData => {
            let nData = reParse(pData);
            nData.push({
                column: "",
                value: ""
            })
            return nData;
        });
    }

    const updateRemove = (e, key) => {
        e.preventDefault();
        setUpdate(pData => {
            let nData = reParse(pData);
            nData.splice(key, 1);
            return nData;
        });
    }

    const updateChange = (e, key) => {
        const { name, value } = e.target
        setUpdate(pData => {
            let nData = reParse(pData);
            nData[key][name] = value;
            if (name === "column") {
                nData[key].value = "";
            }
            return nData;
        });
    }

    const updateValueChange = (key, value) => {
        setUpdate(pData => {
            let nData = reParse(pData);
            nData[key].value = value;
            return nData;
        });
    }

    const updateClick = () => {
        if (selected.length === 0) {
            showAlert("e", "Please select atleast one item");
        } else {
            setIsUpdate(true);
        }
    }

    const updateSubmit = () => {
        let err = [];
        let params = {
            "name": "massAttributesUpdate",
            "parameters": [
                { "AttributeName": "" },
                { "ObjectName": Services[service.api] },
                { "Key": service.id },
                { "KeyValue": selected.join(",") },
                { "NewValue": "" },
                { "AttributeName1": "" }, { "NewValue1": "" },
                { "AttributeName2": "" }, { "NewValue2": "" },
                { "AttributeName3": "" }, { "NewValue3": "" },
                { "AttributeName4": "" }, { "NewValue4": "" },
                { "AttributeName5": "" }, { "NewValue5": "" },
                { "TenantId": +userInfo.pTenantId },
                { "OrgId": +userInfo.pOrgId }
            ]
        }
        let m = 5;
        let n = 0;
        update.map((i, k) => {
            ++k;
            let item = columnInfo(i.column);
            if (i.column === "") {
                err.push(`Please select the column in row ${k}`);
            } else if (i.value === "" || i.value === null) {
                err.push(`Please fill the value in row ${k}`);
            } else {
                let value = i.value;
                if (fieldTypes.singleSelect.includes(item.type)) {
                    value = value ? value.value : "";
                } else if (fieldTypes.multipleSelect.includes(item.type)) {
                    value = value ? value.map(i => i.value).join(",") : "";
                } else if (fieldTypes.date.includes(item.type)) {
                    value = value ? common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ") : null;
                } else if (item.type === "currency") {
                    value = String(value).replace(/,/g, "");
                }
                if (n === 0) {
                    params.parameters[0][`AttributeName`] = item.key;
                    params.parameters[4][`NewValue`] = value;
                } else {
                    params.parameters[m][`AttributeName${n}`] = item.key;
                    ++m;
                    params.parameters[m][`NewValue${n}`] = value;
                    ++m;
                }
                ++n;
            }
        })
        if (err.length) {
            showAlert("e", err[0]);
        } else {
            // api call
            setIsLoader("update");
            setIsUpdate(false);
            let url = envConfig.BASE_API + Services.CUSTOM_ATTR;
            restServices.postRequest(url, params, (response) => {
                if (response) {
                    common.snack("S", "Item has been updated successfully");
                    setSelected([]);
                    setSelectedData({});
                    setIsLoader("");
                    setUpdate([{
                        column: "",
                        value: ""
                    }])
                    setIsReload(true);
                }
            }, (error) => {
                common.snack("E", error);
                setIsLoader("");
            })
        }
    }

    // select
    const selectChange = (e, item) => {
        const { checked, value } = e.target;
        setSelected(pData => {
            let nData = reParse(pData);
            if (checked) {
                nData.push(+value);
            } else {
                nData.splice(nData.indexOf(+value), 1);
            }
            return nData;
        });
        // selected data
        setSelectedData(pData => {
            let nData = reParse(pData);
            if (checked) {
                let res = {};
                propsData.map(i => { res[i] = item[i] })
                nData[+value] = res;
            } else {
                delete nData[+value];
            }
            return nData;
        });
    }

    const getPermission = (access, recordTerId, item) => {
        if(props.module == "lead" || props.module == "deal"){
            return common.evalPermissions(access, recordTerId, props.module == "lead" ? item["SalespersonId"] : item["SalesPersonId"]);
        }
        return access;        
    }

    const getAccess = (access, type = null) => {
        let userData = tools.deCrypt(localStorage.getItem("userData"));
        let isAdmin = userData["roleId"] === 0;
        if (isAdmin) {
            return true;
        } else if(props.module == "lead" || props.module == "deal"){
            if(type == "mass" && access == "1"){
                return true;
            } else if(type == "mass" && access != "1"){
                return false;
            } else if(access == "2") {
                return false;
            } else { return true; }
        }
        return access;        
    }

    const selectAll = (e) => {
        const { checked } = e.target;
        setSelected(pData => {
            let nData = reParse(pData);
            if (checked) {
                result.data.map(i => {
                    if (!nData.includes(i[service.id])) {
                        nData.push(i[service.id]);
                    }
                });
            } else {
                result.data.map(i => {
                    nData.splice(nData.indexOf(i[service.id]), 1);
                });
            }
            return nData;
        });

        // selected data
        setSelectedData(pData => {
            let nData = reParse(pData);
            if (checked) {
                result.data.map(i => {
                    let res = {};
                    propsData.map(j => { res[j] = i[j] })
                    nData[i[service.id]] = res;
                })
            } else {
                result.data.map(i => {
                    delete nData[i[service.id]];
                })
            }
            return nData;
        });
    }

    const selectAllCheck = () => {
        let i = 0;
        result.data.map(item => {
            if (selected.includes(item[service.id])) {
                ++i;
            }
        })
        return (i > 0 && result.data.length === i);
    }

    // delete
    const deleteData = () => {
        if (selected.length === 0) {
            showAlert("e", "Please select atleast one item");
        } else {
            setDialog("delete");
        }
    }
    const deleteNo = () => {
        setDialog("");
        setDeleteId("");
    }

    const deleteYes = () => {
        setDialog("");
        setIsLoader("delete");
        setDeleteId("");
        let params = {
            "name": "massDelete",
            "parameters": [
                {
                    "ObjectName": Services[service.api]
                },
                {
                    "Key": service.id
                },
                {
                    "KeyValue": deleteId ? deleteId : selected.join(",")
                },
                {
                    "TenantId": +userInfo.pTenantId
                },
                {
                    "OrgId": +userInfo.pOrgId
                }
            ]
        }
        // api call
        let url = envConfig.BASE_API + Services.CUSTOM_ATTR;
        restServices.postRequest(url, params, (response) => {
            if (response) {
                common.snack("S", "Item has been deleted successfully");
                setIsReload(true);
            }
            setIsLoader("");
            setSelected([]);
            setSelectedData({});
        }, (error) => {
            common.snack("E", error);
            setIsLoader("");
        })
    }

    // export
    const exportCsv = (key) => {
        onClosePopOver("export");
        let exportData = [];
        let filterColumns = result.columns.filter(i => (!hidedColumns.includes(i.key)));
        if (key === 0) {
            result.data.map(item => {
                let resultData = {};
                filterColumns.map(cItem => {
                    resultData[cItem.label] = item[`${cItem.key}Desc`] ?? item[cItem.key];
                });
                exportData.push(resultData);
            })
            exportFile(exportData);
        } else {
            if (key === 1 && filter[0].column === "") {
                showAlert("w", "You haven't applied any filter yet");
            } else {
                setIsLoader("export");
                let query = `?q=${key === 1 ? filterQuery() : ''}OrgId=${userInfo.pOrgId} AND TenantId=${userInfo.pTenantId}&totalResults=true&limit=10000&offset=0`;
                let orderBy = ``;
                if (sort.field) {
                    orderBy = `&orderBy=${sort.field}:${sort.order}`;
                }

                let url = `${envConfig.BASE_API}${Services[service.api]}${query}${orderBy}`;
                restServices.getRequest(
                    url,
                    (response) => {
                        if (response) {
                            response.items.map(item => {
                                let resultData = {};
                                filterColumns.map(cItem => {
                                    resultData[cItem.label] = item[`${cItem.key}Desc`] ?? item[cItem.key];
                                });
                                exportData.push(resultData);
                            })
                            setIsLoader("");
                            exportFile(exportData);
                        } else {
                            setIsLoader("");
                            showAlert("e", "Sorry no records found");
                        }
                    },
                    (error) => {
                        showAlert("e", "CSV Download error");
                    }
                );
            }
        }
    }

    const exportFile = (eData) => {
        setCsvData(eData);
        setTimeout(() => {
            csvRef.current.link.click()
            showAlert("s", "Export has been completed");
        }, 1000);
    }

    // handler
    const handlePageChange = (pageNumber) => {
        if (paging.page != pageNumber) {
            setPaging({ ...paging, page: pageNumber })
            setIsReload(true);
        }
    }

    const handleLimitChange = (limit) => {
        if (paging.limit != limit) {
            setPaging({ page: 1, limit })
            setIsReload(true);
        }
    }

    const handleSortChange = (field) => {
        setSort({
            field,
            order: (sort.field === field) ? (sort.order === "asc" ? "desc" : "asc") : sort.default,
            default: sort.default
        })
        setIsReload(true);
    }

    const handleColumnChange = (e) => {
        const { checked, value } = e.target;
        setHidedColumns(pData => {
            let nData = reParse(pData);
            if (checked && nData.includes(value)) {
                nData.splice(nData.indexOf(value), 1);
            } else if (!checked) {
                nData.push(value);
            }
            return nData;
        });
        setAction(prev => ({ ...prev, column: true }));
    }

    const toggleColumn = (type) => {
        let columns = [];
        if (type === "hide") {
            columns = result.columns.map(i => i.key);
        }
        setHidedColumns(columns);
        setAction(prev => ({ ...prev, column: true }));
    }

    // callback
    const callBack = (e, res) => {
        e.preventDefault();
        if (typeof props.onAction === "undefined") {
            showAlert("e", "onAction Callback not defined");
        } else {
            if (["convert", "link"].includes(res.type)) {
                if (selected.length === 0) {
                    showAlert("e", "Please select the items first");
                } else {
                    props.onAction({ ...res, selected, selectedData });
                }
            } else {
                props.onAction(res);
            }
        }
    }

    // columns
    const columnValidate = (fields) => {
        let res = fields.filter(i => (i.status === "use" && i.visibility));
        return res;
    }

    const columnInfo = (key) => {
        let res = result.columns.filter(i => i.key === key);
        return res[0];
    }

    // table functions
    const onClickPopOver = (e, type) => {
        setAnchorEl(prev => ({ ...prev, [type]: e.currentTarget }));
    };

    const onClosePopOver = (type) => {
        setAnchorEl(prev => ({ ...prev, [type]: null }));
    };

    const showAlert = (type, message) => {
        let alertType = {
            "s": "success",
            "w": "warning",
            "i": "info",
            "e": "error"
        }
        setAlert({
            type: alertType[type],
            message
        })
    }

    const hideAlert = () => {
        setAlert({
            type: "",
            message: ""
        })
    }

    // support
    const getPaging = (type) => {
        if (type == "offset") {
            return ((paging.page - 1) * paging.limit);
        } else if (type == "serial") {
            return ((paging.page - 1) * paging.limit);
        }
    }

    const checkAllow = () => {
        let allow = {
            rows: true,
            columns: true,
            filter: true,
            bulkUpdate: true,
            delete: true,
            export: true,
            pagination: true,
            tableHead: true,
            tableBody: true,
            columnsAlert: false,
            noRecordAlert: false,
            reportFilter: true
        }
        // rows
        if (status === "process") {
            allow.pagination = false;
        } else if (hidedColumns.length === result.columns.length) {
            Object.keys(allow).map(i => allow[i] = false);
            allow.columns = true;
            allow.columnsAlert = true;
        } else if (showReports) {
            Object.keys(allow).map(i => allow[i] = false);
            allow.tableHead = false;
            allow.reportFilter = false;
        } else if (editReports) {
            Object.keys(allow).map(i => allow[i] = false);
            allow.tableHead = false;
            allow.reportFilter = false;
        } else if (isLoader || isFilter || isUpdate) {
            Object.keys(allow).map(i => allow[i] = false);
        } else if (result.totalResults === 0) {
            Object.keys(allow).map(i => allow[i] = false);
            allow.columns = true;
            allow.filter = true;
            allow.tableHead = true;
            allow.noRecordAlert = true;
        }
        return allow;
    }

    const reParse = (data) => {
        return JSON.parse(JSON.stringify(data))
    }

    const isObject = (val) => {
        return (typeof (val) === "object" && val !== null);
    }

    const labelValue = (data) => {
        if (isObject(data)) {
            return data ? data.map(item => ({ label: item, value: item })) : "";
        } else {
            return data ? { label: data, value: data } : "";
        }
    }

    const saveFilter = (item) => {
        let uCustomFilter = userData["UserCustomFilter"];
        let params = { 
            [service.filter]: hidedColumns.length > 0 ? hidedColumns.toString() : null,
            UserId: userData.UserId,
            TenantId: userData.TenantId,
            OrgId: userData.DefaultOrgId,
        }
        if(uCustomFilter && Object.keys(uCustomFilter).length > 0){
            // api call to update
            let url = envConfig.BASE_API + Services.CHOLA_CUSTOM_FILTER + "/" + uCustomFilter.UserFilterId;
            restServices.patchRequest(url, params, (response) => {
                if (response) {
                    common.snack("S", "Filter updated successfully");
                        userData["UserCustomFilter"] = response;
                        localStorage.setItem("userData", tools.crypt(userData));
                }
            
            }, (error) => {
                common.snack("E", error);
            })
        }else{
            // api call to create
            let url = envConfig.BASE_API + Services.CHOLA_CUSTOM_FILTER;
            restServices.postRequest(url, params, (response) => {
                if (response) {
                    common.snack("S", "Filter saved successfully");
                        userData["UserCustomFilter"] = response;
                        localStorage.setItem("userData", tools.crypt(userData));
                }
            
            }, (error) => {
                common.snack("E", error);
            })
        }
        
    }
    // render
    return (
        <div className="amlMain">
            {!isInit ? (
                <div className="amlInit">
                    <p>Initializing data please wait...</p>
                    <img src={common.loadImg("crm-loader.gif")} alt="Loading..." />
                </div>
            ) : status === "error" ? (
                <div className="amlWentWrong">
                    <p>Something went wrong please click reload button or refresh your page.</p>
                    <img src={common.loadImg("wentWrong.png")} alt="Something Wrong" />
                    <Button color="secondary" variant="outlined" size="medium" onClick={() => window.location.reload()}>Reload</Button>
                </div>
            ) : ([0].map(i => {
                let allow = checkAllow();
                let updateColumns = update.map(i => (i.column ? i.column : null));
                return (
                    <div className={`${status === "process" ? "amlNone" : "amlExist"}`} key={i}>
                        <div className="amlModuleHeader">
                            <p>{moduleLabel[props.module]}</p>
                            <div>
                                {typeof (props.add) !== "undefined" && getAccess(userPermissions.add) && !isEduTech && (
                                    <Button className={1 ? "amlExist" : "amlNone"} size="small" startIcon={<AddCircleIcon />} aria-describedby="columnPopover" onClick={(e) => { callBack(e, { type: "add" }) }}>{props.add}</Button>
                                )}
                                {typeof (props.import) !== "undefined" && getAccess(userPermissions.import, "mass") && !isEduTech && ( //ToDo - mass import personal, p+d, p+d+cd
                                    <Button className={1 ? "amlExist" : "amlNone"} size="small" startIcon={<CloudUpload />} aria-describedby="filterPopover" onClick={(e) => { callBack(e, { type: "import" }) }}>{props.import}</Button>
                                )}
                                {typeof (props.convert) !== "undefined" && getAccess(userPermissions.update, "mass") && !isEduTech && (
                                    <Button className={1 ? "amlExist" : "amlNone"} size="small" startIcon={<CachedTwoTone />} aria-describedby="filterPopover" onClick={(e) => { callBack(e, { type: "convert" }) }}>{props.convert}</Button>
                                )}
                                {typeof (props.link) !== "undefined" && getAccess(userPermissions.add)  && (
                                    <Button className={1 ? "amlExist" : "amlNone"} size="small" startIcon={<AddLinkIcon />} aria-describedby="filterPopover" onClick={(e) => { callBack(e, { type: "link" }) }}>{props.link}</Button>
                                )}
                                {typeof (props.quick) !== "undefined" && getAccess(userPermissions.add) && (
                                    <Button className={1 ? "amlExist" : "amlNone"} size="small" startIcon={<AddCircleIcon />} aria-describedby="filterPopover" onClick={(e) => { callBack(e, { type: "quick" }) }}>{props.quick}</Button>
                                )}
                            </div>
                        </div>
                        <div className="amlHeader">
                            <div>
                                <Button className={allow.rows ? "amlExist" : "amlNone"} size="small" startIcon={<FormatListNumberedIcon />} aria-describedby="rowsPopover" onClick={(e) => onClickPopOver(e, "rows")}> {paging.limit} Rows </Button>
                                {selected.length > 0 && (
                                    <Button size="small" startIcon={<DoneAllSharp />} aria-describedby="filterPopover" onClick={(e) => onClickPopOver(e, "filter")}> {selected.length} Row{selected.length > 1 ? `s` : ``} Selected </Button>
                                )}
                            </div>
                            <div>
                                <Button className={allow.columns ? "amlExist" : "amlNone"} size="small" startIcon={<ViewColumnIcon />} aria-describedby="columnPopover" onClick={(e) => onClickPopOver(e, "columns")}> Columns </Button>
                                <Button className={allow.filter ? "amlExist" : "amlNone"} size="small" startIcon={<FilterListIcon className={filterData ? "filterActive" : ""}/>} aria-describedby="filterPopover" onClick={(e) => filterClick()}> Filter </Button>
                                {getAccess(userPermissions.update, "mass") && typeof (props.update) !== "undefined" && (
                                    <Button className={allow.bulkUpdate ? "amlExist" : "amlNone"} size="small" startIcon={<FilterListIcon />} aria-describedby="filterPopover" onClick={(e) => updateClick()}>{props.update}</Button>
                                )}
                                { getAccess(userPermissions.delete, "mass") && !isEduTech && <Button className={allow.delete ? "amlExist" : "amlNone"} size="small" startIcon={<DeleteIcon />} onClick={() => deleteData(true)}> Delete </Button>}
                                { getAccess(userPermissions.export) && <Button className={allow.export ? "amlExist" : "amlNone"} size="small" startIcon={<FilterListIcon />} aria-describedby="exportPopover" onClick={(e) => onClickPopOver(e, "export")}> Export </Button>}
                                { true && <Button className={allow.reportFilter ? "amlExist" : "amlNone"} size="small" startIcon={<FilterListIcon />} aria-describedby="exportPopover" onClick={(e) => reportClick()}> Report </Button>}
                            </div>
                        </div>
                        <TableContainer className="amlTable">
                            <Table stickyHeader className="amlLoader" aria-label="Data Table">
                                {allow.tableHead && (
                                    <TableHead className={`${allow.noRecordAlert ? "amlNone" : "amlExist"}`}>
                                        <TableRow>
                                            {(getAccess(userPermissions.update, "mass") ||  getAccess(userPermissions.delete, "mass")) && <TableCell padding="checkbox">
                                                    <Tooltip title="Select All" placement="left">
                                                        <Checkbox
                                                            checked={selectAllCheck()}
                                                            inputProps={{ 'aria-label': 'Select' }}
                                                            onChange={(e) => selectAll(e)}
                                                            disabled={allow.noRecordAlert}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            }
                                            <TableCell align="left">Action</TableCell>
                                            {result.columns.filter(i => (!hidedColumns.includes(i.key))).map((item, key) => (
                                                <TableCell align="left" key={key} onClick={() => handleSortChange(item.key)}>
                                                    <TableSortLabel active={(sort.field === item.key) ? true : false} direction={(sort.field === item.key) ? sort.order : sort.default}>
                                                        <Tooltip title={item.label}>
                                                            <span className="tblHeadingLbl">
                                                                {item.label}
                                                            </span>
                                                        </Tooltip>
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                )}
                                {(status === "success" && allow.tableBody) && (
                                    <TableBody>
                                        {result.data.map((item, key) => (
                                            <TableRow key={key}>
                                                {(getAccess(userPermissions.update, "mass") ||  getAccess(userPermissions.delete, "mass")) && <TableCell padding="checkbox">
                                                        <Checkbox
                                                            value={item[service.id]}
                                                            checked={selected.includes(item[service.id]) ? true : false}
                                                            inputProps={{ 'aria-labelledby': "" }}
                                                            onChange={(e) => selectChange(e, item)}
                                                        />
                                                    </TableCell>
                                                }
                                                <TableCell align="left">
                                                    <div className="d-flex">
                                                        {getPermission(userPermissions.update, item["TerritoryId"], item) && (
                                                            <Link href="#" onClick={(e) => { callBack(e, { id: item[service.id], type: "edit" }) }}><EditIcon fontSize="small" /></Link>
                                                        )}
                                                        {getPermission(userPermissions.delete, item["TerritoryId"], item) && !isEduTech && (
                                                            <Link href="#" onClick={(e) => { e.preventDefault(); setDeleteId(item[service.id]) }}> <DeleteIcon fontSize="small" /></Link>
                                                        )}
                                                        {/* <Link href="#" onClick={(e) => { callBack(e, { id: item[service.id], type: "view" }) }}><VisibilityIcon fontSize="small" /></Link> */}
                                                    </div>
                                                </TableCell>
                                                {result.columns.filter(i => (!hidedColumns.includes(i.key))).map((cItem, cKey) => {
                                                    return (
                                                        <TableCell align="left" key={cKey}>
                                                            {cItem.attr.lookup === "transactionStatus" ? (
                                                                <span>{item[cItem.key]}</span>
                                                            ) : (cItem.type === "date" || cItem.type === "dateTime") ? (
                                                                (item[cItem.key] ? moment(item[cItem.key]).format("DD/MMM/YYYY") : "-")
                                                            ) : (cItem.type === "checkbox") ? (
                                                                (item[cItem.key] === "Y" ? "Yes" : "No")
                                                            ) : (cItem.type === "lookup") ? (
                                                                (item[`${cItem.key}Desc`] ?? item[cItem.key])
                                                            ) : (cItem.type === "multiLookup") ? (
                                                                (item[cItem.display.name] ?? item[cItem.display.name])
                                                            ) : (item[cItem.key] ?? "-")}
                                                        </TableCell>
                                                    )
                                                })}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                        {/* Filter List */}
                        <div className={(showReports) ? "amlShow" : "amlHide"}>
                            <div className="row mb-4">
                                <div className="col-md-4">
                                    {reportData.length < reportLimit && <Button variant="outlined" color="primary" size="small" onClick={(e) => addNewRepFilter(0, null)}>Add</Button>}
                                </div>
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-1">
                                    <Button variant="outlined" color="secondary" size="small" onClick={(e) => setShowReports(false)}>Go Back</Button>
                                </div>
                                <div className="col-md-6"></div>
                            </div>
                            {reportData.map((item, key) => (
                                <div className="row mb-1" key={key}>
                                    <div className="col-md-4">{item.ReportName}</div>
                                    <div className="col-md-1">
                                        <Link onClick={(e) => addNewRepFilter(item.UserReportId, item)}>Edit</Link>
                                    </div>
                                    <div className="col-md-1">
                                        <Link onClick={(e) => downloadFilterReport(item)}>Download</Link>
                                    </div>
                                    <div className="col-md-6"></div>
                                </div>
                            ))}
                            {reportData.length == 0 && <span>Sorry no records found</span>}
                        </div>
                        {/* Filter data */}
                        <div className={isFilter || editReports ? "amlShow" : "amlHide"}>
                            <p className="amlFilterTitle">{editReports ? "Save Your Report Filter" : "Filter your data"}</p>
                            {editReports && <div className="row  mb-4">
                                <div className="col-md-4"></div>
                                <div className="col-md-4 align-self-center text-center">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Report Name"
                                        maxLength="50"
                                        value={reportFilterName}
                                        onChange={(e) => setReportFilterName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4"></div>
                            </div>}
                            <ul className="conditions-list">
                                {filter.map((item, key) => {
                                    let clCount = key;
                                    let field = item.column ? columnInfo(item.column) : "";
                                    return (
                                        <li className="mb-3" key={clCount}>
                                            <div className="row">
                                                <div className="col-md-2"></div>
                                                <div className="col-1 align-self-center text-center">
                                                    <span className="step-count">{++clCount}</span>
                                                    {filter.length != clCount && (
                                                        <Link to="#" className="step-logical" onClick={(e) => filterLogical(key)}>{item.logic}</Link>
                                                    )}
                                                </div>
                                                <div className="col">
                                                    <select
                                                        className="form-control"
                                                        name="column"
                                                        value={item.column}
                                                        onChange={(e) => filterChange(e, key)}
                                                    >
                                                        <option value="">Select Column</option>
                                                        {result.columns.map((item, key) => (
                                                            <option value={item.key} key={key}>{item.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {field !== "" && (
                                                    <div className="col">
                                                        <select
                                                            className="form-control"
                                                            name="operator"
                                                            value={item.operator}
                                                            onChange={(e) => filterChange(e, key)}
                                                        >
                                                            <option value="">Select Operator</option>
                                                            {Object.entries(filterOperator[formFields[field.type].type]).map(([key, item]) => (
                                                                <option value={key} key={key}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                {field !== "" && (
                                                    field.type == "pickList" ? (
                                                        <div className="col">
                                                            <ReactSelect
                                                                value={item.value}
                                                                isClearable={true}
                                                                options={labelValue(field.attr.option)}
                                                                onChange={(data) => filterValueChange(key, data)}
                                                            />
                                                        </div>
                                                    ) : field.type == "date" ? (
                                                        <div className="col">
                                                            <DatePicker
                                                                isClearable={true}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="MMMM d, yyyy"
                                                                placeholderText="Select Date"
                                                                selected={item.value ? moment(item.value).toDate() : null}
                                                                onChange={(date) => filterValueChange(key, date)}
                                                            />
                                                        </div>
                                                    ) : field.type == "dateTime" ? (
                                                        <div className="col">
                                                            <DatePicker
                                                                isClearable={true}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                // showTimeSelect
                                                                timeIntervals={1}
                                                                timeCaption="Time"
                                                                dateFormat="MMMM d, yyyy"
                                                                placeholderText="Select Date and Time"
                                                                selected={item.value ? moment(item.value).toDate() : null}
                                                                onChange={(date) => filterValueChange(key, date)}
                                                            />
                                                        </div>
                                                    ) : field.type == "checkbox" ? (
                                                        <div className="col">
                                                            <select
                                                                className="form-control"
                                                                value={item.value}
                                                                onChange={(e) => filterValueChange(key, e.target.value)}
                                                            >
                                                                <option value="">None</option>
                                                                <option value="Y">Yes</option>
                                                                <option value="N">No</option>
                                                            </select>
                                                        </div>
                                                    ) : field.type == "lookup" ? (
                                                        <div className="col">
                                                            <AutomationSelect
                                                                isRender={true}
                                                                field={field}
                                                                value={item.value}
                                                                onSelect={(data) => filterValueChange(key, data)}
                                                            />
                                                        </div>
                                                    ) : field.type == "multiLookup" ? (
                                                            <div className="col">
                                                                <AutomationSelect
                                                                    isMulti={true}
                                                                    isRender={true}
                                                                    field={field}
                                                                    value={item.value}
                                                                    onSelect={(data) => filterValueChange(key, data)}
                                                                />
                                                            </div>
                                                        ) : (
                                                        <div className="col">
                                                            <input
                                                                type={field.type === "number" ? "number" : "text"}
                                                                className="form-control"
                                                                placeholder={`Enter ${field.label}`}
                                                                maxLength={field.attr.maxChar}
                                                                value={item.value}
                                                                onChange={(e) => filterValueChange(key, e.target.value)}
                                                            />
                                                        </div>
                                                    ))}

                                                <div className="col">
                                                    <div className="col align-items-center">
                                                        {filter.length === 1 && key === 0 ? (
                                                            <Link to="#" className="amlAddRuleLink amlAddRule" onClick={(e) => filterAdd(e)}> + </Link>
                                                        ) : key === 0 && (
                                                            <Link to="#" className="amlAddRuleLink amlRemoveRule" onClick={(e) => filterRemove(e, key)}> - </Link>
                                                        )}
                                                        {key > 0 && (
                                                            <React.Fragment>
                                                                <Link to="#" className="amlAddRuleLink amlRemoveRule" onClick={(e) => filterRemove(e, key)}> - </Link>
                                                                {filter.length == clCount && (
                                                                    <Link to="#" className="amlAddRuleLink amlAddRule" onClick={(e) => filterAdd(e)}> + </Link>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className="amlFilterButtons">
                                <Button variant="outlined" size="small" onClick={() => filterSubmit(isFilter ? "filter" : "report")}>
                                    {isFilter ? "Filter" : "Save"}
                                </Button>
                                { isFilter && <Button variant="outlined" size="small" onClick={() => filterReset()}>Reset</Button> }
                                <Button variant="outlined" color="secondary" size="small" onClick={() => (isFilter ? setIsFilter(false) : callReportAction() )}>Cancel</Button>
                            </div>
                        </div>

                        {/* Bulk update */}
                        <div className={isUpdate ? "amlShow" : "amlHide"}>
                            <p className="amlFilterTitle">Bulk Update</p>
                            <p className="amlFilterSelected">{selected.length} item(s) selected</p>
                            <ul className="conditions-list">
                                {update.map((item, key) => {
                                    let clCount = key;
                                    let field = item.column ? columnInfo(item.column) : "";
                                    return (
                                        <li className="mb-3" key={clCount}>
                                            <div className="row">
                                                <div className="col-md-2"></div>
                                                <div className="col-1 align-self-center text-center">
                                                    <span className="step-count">{++clCount}</span>
                                                </div>
                                                <div className="col">
                                                    <select
                                                        className="form-control"
                                                        name="column"
                                                        value={item.column}
                                                        onChange={(e) => updateChange(e, key)}
                                                    >
                                                        <option value="">None</option>
                                                        {result.columns.filter(i => i.fieldUpdate).map((item, key) => (
                                                            <option value={item.key} key={key} disabled={updateColumns.includes(item.key)}>{item.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {field !== "" && (
                                                    field.type == "pickList" ? (
                                                        <div className="col">
                                                            <ReactSelect
                                                                value={item.value}
                                                                isClearable={true}
                                                                options={labelValue(field.attr.option)}
                                                                onChange={(data) => updateValueChange(key, data)}
                                                            />
                                                        </div>
                                                    ) : field.type == "date" ? (
                                                        <div className="col">
                                                            <DatePicker
                                                                isClearable={true}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="MMMM d, yyyy"
                                                                placeholderText="Select Date"
                                                                selected={item.value ? moment(item.value).toDate() : null}
                                                                onChange={(date) => updateValueChange(key, date)}
                                                            />
                                                        </div>
                                                    ) : field.type == "dateTime" ? (
                                                        <div className="col">
                                                            <DatePicker
                                                                isClearable={true}
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                // showTimeSelect
                                                                timeIntervals={1}
                                                                timeCaption="Time"
                                                                dateFormat="MMMM d, yyyy"
                                                                placeholderText="Select Date and Time"
                                                                selected={item.value ? moment(item.value).toDate() : null}
                                                                onChange={(date) => updateValueChange(key, date)}
                                                            />
                                                        </div>
                                                    ) : field.type == "checkbox" ? (
                                                        <div className="col">
                                                            <select
                                                                className="form-control"
                                                                value={item.value}
                                                                onChange={(e) => updateValueChange(key, e.target.value)}
                                                            >
                                                                <option value="">None</option>
                                                                <option value="Y">Yes</option>
                                                                <option value="N">No</option>
                                                            </select>
                                                        </div>
                                                    ) : field.type == "lookup" ? (
                                                        <div className="col">
                                                            <AutomationSelect
                                                                isRender={true}
                                                                field={field}
                                                                value={item.value}
                                                                onSelect={(data) => updateValueChange(key, data)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="col">
                                                            <input
                                                                type={field.type === "number" ? "number" : "text"}
                                                                className="form-control"
                                                                placeholder={`Enter ${field.label}`}
                                                                maxLength={field.attr.maxChar}
                                                                value={item.value}
                                                                onChange={(e) => updateValueChange(key, e.target.value)}
                                                            />
                                                        </div>
                                                    ))}

                                                <div className="col">
                                                    <div className="col align-items-center">
                                                        {update.length === 1 && key === 0 ? (
                                                            <Link to="#" className="amlAddRuleLink amlAddRule" onClick={(e) => updateAdd(e)}> + </Link>
                                                        ) : key === 0 && (
                                                            <Link to="#" className="amlAddRuleLink amlRemoveRule" onClick={(e) => updateRemove(e, key)}> - </Link>
                                                        )}
                                                        {key > 0 && (
                                                            <React.Fragment>
                                                                <Link to="#" className="amlAddRuleLink amlRemoveRule" onClick={(e) => updateRemove(e, key)}> - </Link>
                                                                {(update.length == clCount && update.length !== 5) && (
                                                                    <Link to="#" className="amlAddRuleLink amlAddRule" onClick={(e) => updateAdd(e)}> + </Link>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className="amlFilterButtons">
                                <Button variant="outlined" size="small" onClick={() => updateSubmit()}>Update</Button>
                                <Button variant="outlined" color="secondary" size="small" onClick={() => setIsUpdate(false)}>Cancel</Button>
                            </div>
                        </div>

                        {status === "process" ? (
                            <div className="amlInit">
                                <p>Loading data please wait...</p>
                                <img src={common.loadImg("crm-loader.gif")} alt="Loading..." />
                            </div>
                        ) : allow.columnsAlert ? (
                            <div className="amlWentWrong">
                                <p>You hide all the columns please select your columns</p>
                                <img src={common.loadImg("list.svg")} alt="Hide" />
                            </div>
                        ) : allow.noRecordAlert ? (
                            <div className="amlWentWrong">
                                <p>Sorry no records found, please refine your search.</p>
                                <img src={common.loadImg("noRecordFound.svg")} alt="Something Wrong" />
                            </div>
                        ) : isLoader !== "" && (
                            <div className="amlInit">
                                {isLoader === "delete" ? (
                                    <p>Deleting data please wait...</p>
                                ) : isLoader === "update" ? (
                                    <p>Updating data please wait...</p>
                                ) : (
                                    <p>Exporting data please wait...</p>
                                )}
                                <img src={common.loadImg("crm-loader.gif")} alt="Loading..." />
                            </div>
                        )}
                        <div className="amlFooter">
                            {(allow.pagination) && (
                                <Fragment>
                                    <p>Total records: <span>{result.totalRecords}</span>{filter[0].column !== "" && (<Fragment> - Filtered: <span>{result.totalResults}</span></Fragment>)}</p>
                                    <Pagination
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        activePage={paging.page}
                                        itemsCountPerPage={paging.limit}
                                        totalItemsCount={result.totalResults}
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange}
                                    />
                                </Fragment>
                            )}
                        </div>
                        <Popover
                            id="rowsPopover"
                            open={Boolean(anchorEl.rows)}
                            anchorEl={anchorEl.rows}
                            onClose={() => onClosePopOver("rows")}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {[10, 25, 50, 100].map((i, k) => (
                                <MenuItem onClick={() => { handleLimitChange(i); onClosePopOver("rows") }} key={k}>{i} Rows</MenuItem>
                            ))}
                        </Popover>
                        {/* Columns Popper */}
                        <Popover
                            id="columnPopover"
                            open={Boolean(anchorEl.columns)}
                            anchorEl={anchorEl.columns}
                            onClose={() => onClosePopOver("columns")}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className="amlColPopOver">
                                <div className="amlColEnd">
                                    <Button color="primary" onClick={() => toggleColumn("show")}>Show All</Button>
                                    <Button color="primary" onClick={() => toggleColumn("hide")}>Hide All</Button>
                                </div>
                                <Divider />
                                <div className="amlColSaveChanges">
                                    <Button color="primary" disabled={!action.column} onClick={() => saveFilter(action.column)}>Save your changes</Button>
                                </div>
                                <Divider />
                                <div className="amlColList">
                                    {result.columns.map((item, key) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    size="small"
                                                    checked={hidedColumns.includes(item.key) ? false : true}
                                                    value={item.key}
                                                    onChange={(e) => handleColumnChange(e)}
                                                />
                                            }
                                            label={item.label}
                                            key={key}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Popover>

                        {/* Export Popper */}
                        <Popover
                            id="exportPopover"
                            open={Boolean(anchorEl.export)}
                            anchorEl={anchorEl.export}
                            onClose={() => onClosePopOver("export")}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {exportType.map((item, key) => (
                                <MenuItem onClick={() => exportCsv(key)} key={key}>{item}</MenuItem>
                            ))}
                        </Popover>

                        {/* Delete Multiple Dialog */}
                        <Dialog
                            open={(isDialog === "delete")}
                            onClose={() => deleteNo()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete this record(s)?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => deleteNo()} color="primary">
                                    No
                                </Button>
                                <Button onClick={() => deleteYes()} color="primary" autoFocus>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {/* Alert */}
                        {isAlert.type !== "" && (
                            <Snackbar open={true} autoHideDuration={3000} onClose={() => hideAlert()} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                                <MuiAlert elevation={3} variant="filled" onClose={() => hideAlert("")} severity={isAlert.type}>
                                    {isAlert.message}
                                </MuiAlert>
                            </Snackbar>
                        )}
                    </div>
                )
            }))}
            {/* csv */}
            <CSVLink filename={`${props.module}_list.csv`} data={csvData} ref={csvRef} />
        </div >
    )
}

export default OsmoMaterialTable;