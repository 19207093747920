import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
class MyRole extends React.Component {
  constructor() {
    super();
    this.state = {
      isSearchLoad: false,
      searchList: {},
      employeeData: [],
      selectedRole: {},
      divisionName: "",
      subDivisionName: "",
      sectionName: "",
      departmentName: "",
      designationName: "",
      responsibilitiesDetails: [],
      userData: common.authInfo()
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    
          this.getRoles();
      
  }

  getRoles = (name) => {
    const { userData } = this.state;
    let role = userData.roleId || 0;
    let filter = `?expand=all&totalResults=true&orderBy=CreatedOn:desc&q=TenantId=${userData.TenantId};GroupId=${role}`;
    if (name) {
      filter = `?expand=all&totalResults=true&orderBy=CreatedOn:desc&q=TenantId=${userData.TenantId} AND GroupId=${role}`;
      filter += ` AND UPPER(GroupDesc) LIKE '*${name}%`;
    }
    let url = encodeURI(envConfig.BASE_API + Services.CRM_ROLES_HDR + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          this.setState(
            { searchList: response, selectedRole: response.items[0] },
            (_) => {
              this.getResponsiblities();
            }
          );
        }
      },
      (error) => {}
    );
  };

  getResponsiblities = () => {
    const { selectedRole } = this.state;
    let filter = `?q=RoleHdrId=${selectedRole.RoleHdrId}`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_ROLES_DTL + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          this.setState({ responsibilitiesDetails: response.items });
        }
      },
      (error) => {}
    );
  };

  render() {
    const { isSearchLoad, searchList, selectedRole } = this.state;
    return (
      <div className="crm-list-wrap">
        <Row>
          <Col sm={12}>
            <div className="marketing-list-wrap">
              <div className="my-roles-list-wrap">
                {isSearchLoad == false &&
                searchList.items &&
                searchList.items.length > 0 ? (
                  searchList.items.map((row, index) => {
                    return (
                      <div key={index} className={`my-roles-wrap`}>
                        <div>
                          <h1 className="role-group-name mb-3">
                            {row.GroupDesc}
                          </h1>
                          <h2 className="mb-3">Responsibilities:</h2>
                          {row.CholaRolesdtlView &&
                          row.CholaRolesdtlView.length > 0 ? (
                            row.CholaRolesdtlView.map((rdtl, dIdx) => (
                              <Paper
                                className="my-roles-details mb-4"
                                key={dIdx}
                              >
                                <h4 className="my-role-name">
                                  {rdtl.Responsibilities}
                                </h4>
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item">
                                    <strong>Area: </strong>
                                    {rdtl.Area}
                                  </li>
                                  <li className="list-group-item">
                                    <strong>Carry Forward: </strong>
                                    {rdtl.CarryForward === "Y" ? "Yes" : "No"}
                                  </li>
                                  <li className="list-group-item">
                                    <strong>Shift: </strong>
                                    {rdtl.Shift}
                                  </li>
                                  <li className="list-group-item">
                                    <strong>Reporting Frequency: </strong>
                                    {rdtl.ReportingFreq}
                                  </li>
                                </ul>
                              </Paper>
                            ))
                          ) : (
                            <NoRecordFound />
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="formTabSection position-r">
                    <NoRecordFound />
                  </div>
                )}

                {isSearchLoad == true && <Loader />}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export { MyRole };
