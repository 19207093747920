import React from "react";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
import moment from "moment";
import Pagination from "react-js-pagination";

import NoRecordFoundImg from "../../../../img/no-records-found.png";
import Loader from "../../../../_components/_loader/Loader";
class MyTargetList extends React.Component {
  userData = common.authInfo();
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageLimit: 100,
      targetResponse: {},
      filterTargetName: "",
      userId: this.userData.EmpId,
      isLoading: true
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.getList();
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState({
        currentPage: pageNumber,
      });
    }
  }

  /**
   * Get all list
   */
  getList = () => {
    const { filterTargetName, userId, currentPage, pageLimit } = this.state;
    let currentMonth = moment().format("MMM").toUpperCase();
    let previousMonth = moment()
      .subtract(1, "months")
      .format("MMM")
      .toUpperCase();
    let nextMonth = moment().add(1, "months").format("MMM").toUpperCase();

    let currentYear = moment().format("YYYY");
    this.setState({ isLoading: true });

    let filter = `?limit=${pageLimit}&offset=${
      (currentPage - 1) * pageLimit
    }&q=EmpId=${userId};TargetRscMonth=${previousMonth} OR ${currentMonth} OR ${nextMonth};TargetRscYear=${currentYear};&totalResults=true`;

    let url = encodeURI(envConfig.BASE_API + Services.EMPLOYEE_TARGET + filter);

    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          targetResponse: response,
          isLoading: false
        });
      },
      (error) => {
        this.setState({
          targetResponse: {},
          isLoading: false
        });
      }
    );
  };

  render() {
    const { targetResponse, userId, currentPage, pageLimit, isLoading } = this.state;
    return (
      <div className="task-list">
        {isLoading && <Loader />}
        {!isLoading && targetResponse &&
        targetResponse.items &&
        targetResponse.items.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                <th>
                  <strong>Target Type</strong>
                </th>
                <th>
                  <strong>Target Month</strong>
                </th>
                <th>
                  <strong>Actual Value</strong>
                </th>
                <th>
                  <strong>Target Value</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {targetResponse.items.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row.TargetRscType.replaceAll("_", " ")}</td>
                    <td>{row.TargetRscMonth}</td>
                    <td>{row.ActualValue}</td>
                    <td>{row.TargetRscValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="no-records-found-wrap text-center">
            <img
              src={NoRecordFoundImg}
              alt="No Records Found"
              className="mb-3"
            />
            <h5>No Records Found</h5>
          </div>
        )}

        {targetResponse && targetResponse.totalResults > 0 && (
          <div className="d-flex justify-content-center align-items-center p-5 bg-white">
            <div className="mr-auto">
              Showing {(currentPage - 1) * pageLimit + 1} to{" "}
              {(currentPage - 1) * pageLimit + targetResponse.count} of{" "}
              {targetResponse.totalResults} entries
            </div>
            <div className="pagination-wrap">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={currentPage}
                itemsCountPerPage={targetResponse.limit}
                totalItemsCount={targetResponse.totalResults}
                pageRangeDisplayed={7}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MyTargetList;
