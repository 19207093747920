import React from "react";
import Badge from "@material-ui/core/Badge";
import { Tabs, Tab } from "react-bootstrap";

// Import internal components
import DynamicForm from "crm/DynamicForm";
import CampaignLeadList from "./Tabs/CampaignLeadList";
import ChildCampaignList from "./Tabs/ChildCampaignList";
import MarketingLists from "../../../_components/MarketingLists/pages/MarketingLists";
import Comments from "../../../_components/Comments/Comments";
import CallLogsList from "../../../_components/CallLogs/CallLogsList/CallLogsList";
import Attachments from "../../../_components/Attachments/Attachments";
import Meeting from "../../../_components/Meeting/Meeting";
import History from "../../../_components/History/pages/History";
import ContactLinks from "../../../_components/Contacts/ContactLinks";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import CampaignReport from "./Tabs/CampaignReport";
import Loader from "_components/_loader/Loader";
//Import CSS
import "../css/Campaign.css";

// Import config and helpers
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common, history } from "_helpers";

const authInfo = common.authInfo();
class AddCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.repChild = React.createRef();
    this.state = {
      params: this.props.match.params,
      crmCampaignStatusList: [],
      campaignData: {},
      campaignId: 0,
      isLoading: true,
      userPermissions: common.getPermission(this.props, "crm:campaigns"),
      reload: new Date(),
    };
    this.state.currentTab = common.getParam("tab");
    let status = common.getParam("status");
    this.state.campaignData["CampaignStatus"] = status ? status : null;
  }
  componentDidMount() {
    const { params } = this.state;
    this.getCRMStatusList();
    if (params.campaignId) {
      this.setState(
        {
          campaignId: params.campaignId,
        },
        () => {
          this.getCampaignDetail(params.campaignId);
        }
      );
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  /**
   * Get Campaign Details by ID
   */
  getCampaignDetail = (id) => {
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            campaignData: response,
            isLoading: false,
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    let campaignTypeId = common.getTransactionTypeId(
      envConfig.CAMPAIGN_TRANS_TYPE_ID
    );
    let params =
      `?q=TransactionTypeId=${campaignTypeId};OrgId=` +
      `${authInfo.DefaultOrgId};TenantId=` +
      `${authInfo.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  getStatusPosition = () => {
    const { campaignData, crmCampaignStatusList } = this.state;
    if (
      campaignData.CampaignStatus != "" &&
      campaignData.CampaignStatus != null
    ) {
      var position = crmCampaignStatusList.findIndex(
        (x) => x.StatusCode == campaignData.CampaignStatus
      );
      return position > 0 ? position : 0;
    }
    return 0;
  };

  saveStatus = (status) => {
    const { campaignData } = this.state;
    campaignData.CampaignStatus = status;
    this.setState({
      campaignData,
    });
  };
  /**
   * Tab onclick event
   */
  handleSelect(key) {
    let url = window.location.pathname;
    let queryParam = window.location.search;
    if (queryParam.includes("tab=")) {
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab=" + tab, "tab=" + key);
    }
    history.push(url + queryParam);
  }

  saveCallback = (res) => {
    console.log("seve res :>> ", res);
    history.push(
      "/crm/campaign/edit/" + res.CampaignId + common.getCrmReturnQuery()
    );
    this.setState({
      campaignId: res.CampaignId,
      campaignData: res,
    });
  };

  cancelCallBack = () => {
    history.push("/crm/campaign");
  };

  reload = () => {
    this.child.refreshChildLeads();
    this.repChild.refreshReports();
  };

  render() {
    const {
      crmCampaignStatusList,
      currentTab,
      campaignId,
      campaignData,
      isLoading,
      userPermissions,
      reload,
    } = this.state;
    const authData = () => {
      return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() };
    };
    return (
      <div>
        {!userPermissions.read ||
        (campaignId == 0 && !userPermissions.add) ||
        (campaignId > 0 && !userPermissions.update) ? (
          <AccessDenied />
        ) : (
          !isLoading && (
            <div className="lead-wraper">
              <div className="secondary-head">
                <h1>
                  {campaignData.CampaignName
                    ? campaignData.CampaignName
                    : "Add Campaign"}
                </h1>
              </div>

              <div className="formTab">
                <ul>
                  {crmCampaignStatusList.map((data, idx) => (
                    <li
                      key={idx}
                      onClick={() => this.saveStatus(data.StatusCode)}
                      className={`tabShape whiteTab ${
                        this.getStatusPosition() >= idx ? "active" : ""
                      }`}
                    >
                      {" "}
                      <span className="tri-right"></span>{" "}
                      <Badge badgeContent={++idx} color="primary">
                        {data.StatusName}
                      </Badge>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="formTabSection">
                <Tabs
                  defaultActiveKey={currentTab ? currentTab : "general"}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="general" title="General">
                    <DynamicForm
                      module="campaign"
                      data={{
                        value1: campaignId > 0 ? campaignId : "",
                      }}
                      default={{
                        CampaignOwnerId: authData(),
                      }}
                      onSave={this.saveCallback}
                      onCancel={this.cancelCallBack}
                    />
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="Contacts"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <ContactLinks
                        objectId={campaignId}
                        objectType={common.getObjectNameByPage("campaign")}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="comments"
                    title="Notes"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <Comments
                        objectId={campaignId}
                        objectType="CRM_CAMPAIGNS"
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="call"
                    title="Call Logs"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <CallLogsList
                        objectId={campaignId}
                        objectType="campaign"
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="meetings"
                    title="Meetings"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <Meeting objectId={campaignId} objectType="campaign" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="attachments"
                    title="Attachments"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <Attachments
                        objectType="campaign"
                        objectId={campaignId}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="marketinglist"
                    title="Enquiry List"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <MarketingLists
                        campaignId={campaignId}
                        reload={this.reload}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="childcampaigns"
                    title="Child Campaigns"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <ChildCampaignList campaignId={campaignId} />
                    )}
                  </Tab>
                  <Tab
                    eventKey="leadlist"
                    title="Lead List"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <CampaignLeadList
                        ref={(instance) => {
                          this.child = instance;
                        }}
                        campaignId={campaignId}
                        reload={reload}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="report"
                    title="Report"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <CampaignReport
                        campaignId={campaignId}
                        ref={(instance) => {
                          this.repChild = instance;
                        }}
                        campaignData={campaignData}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="History"
                    disabled={campaignId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && campaignId > 0 && (
                      <History
                        campaign={campaignData}
                        campaignData={campaignData}
                      />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          )
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default AddCampaign;
