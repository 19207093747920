import { commonConstants } from "_constants";

export function crmstatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case commonConstants.CRM_STATUS_GET_ALL_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };
    case commonConstants.CRM_STATUS_GET_ALL_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmCampaignStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmEnquiryStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_ENQUIRY_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmDealStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_DEAL_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_DEAL_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_DEAL_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmQuoteStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_QUOTE_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_QUOTE_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_QUOTE_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmOrderStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_ORDER_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_ORDER_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_ORDER_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmInvoiceStatus(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_STATUS_GET_ALL_INVOICE_REQUEST:
      return {
        ...state,
        list: {
          success: false,
          loading: true,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_INVOICE_SUCCESS:
      return {
        ...state,
        list: {
          details: action,
          success: true,
          loading: false,
          error: false,
        },
      };

    case commonConstants.CRM_STATUS_GET_ALL_INVOICE_ERROR:
      return {
        ...state,
        list: {
          details: action,
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
}

export function crmRolesPermissions(state = { list: {} }, action) {
  switch (action.type) {
    case commonConstants.CRM_ROLES_PERMISSIONS_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };

    case commonConstants.CRM_ROLES_PERMISSIONS_SUCCESS:
      return {
        ...state,
        details: action.message,
        success: true,
        loading: false,
        error: false,
      };

    case commonConstants.CRM_STATUS_GET_ALL_CAMPAIGN_ERROR:
      return {
        ...state,
        details: action,
        success: false,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
