// Import default packages
import React from "react";
import { Link } from "react-router-dom";
// Import redux packages
import { connect } from "react-redux";
import { commonActions } from "_actions/common.actions";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "../../../_components/ListView/ListView";
import KanbanBoard from "../../../_components/KanbanView/KanbanBoard";
import AccessDenied from "_components/_accessdenied/AccessDenied";

// icons
import SearchIcon from "img/search-icn.png";
const userInfo = common.authInfo();
class MyDealList extends React.Component {
  loggedEmpId = userInfo.EmpId;
  constructor(props) {
    super(props);
    this.state = {
      isListView: common.getParam("view") == "kanban" ? false : true,
      isLoading: false,
      listRes: {},
      listStatus: "process",
      currentPage: 1,
      pageLimit: 10,
      totalRecords: {
        allDeals: 0,
        personalDeal: 0,
        myDeals: 0,
      },
      recordsLoaded: {
        allDeals: 0,
        personalDeal: 0,
        myDeals: 0,
      },
      pageLoaded: {
        allDeals: 0,
        personalDeal: 0,
        myDeals: 0,
      },
      tlConfig: {
        columnId: "OpportunityId",
        headCells: [
          {
            id: "OpportunityName",
            value: "OpportunityName",
            label: "DEAL NAME",
            type: "link",
          },
          {
            id: "ProbabiltyPercentage",
            value: "ProbabiltyPercentage",
            label: "PROBABILITY%",
          },
          {
            id: "ApprStatus",
            value: "ApprStatus",
            label: "DEAL STATUS",
            type: "function",
          },
          {
            id: "OpportunityType",
            value: "OpportunityType",
            label: "OPPORTUNITY TYPE",
          },
          {
            id: "NextActionDate",
            value: "NextActionDate",
            label: "NEXT ACTION DATE",
            type: "date",
          },
          { id: "Priority", value: "Priority", label: "PRIORITY" },
          {
            id: "SalesPersonName",
            value: "SalesPersonName",
            label: "RESPONSIBLE",
          },
          {
            id: "CreationDate",
            value: "CreationDate",
            label: "CREATED ON",
            type: "date",
          },
        ],
        columnsSelected: [],
        order: "desc",
        orderBy: "CreationDate",
        selected: [],
        uniqueKey: "MyDealListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
      filterName: "",
      crmStatusList: [],
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.deal
      ),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.read != 2) {
      this.getCRMStatusList();
      this.getList();
      this.getFormJSON();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading, isListView } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading && isListView) {
      this.getList();
    }
  };

  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    const { tlConfig } = this.state;
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_DEAL_CODE;
    this.setState({
      isLoading: true,
    });
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          let userData = common.authInfo();
          let role = userData.roleId || 0;
          let access = "";
          let layout = JSON.parse(result.FormFields);
          if (layout.columns && layout.columns.length > 0) {
            layout.columns.map((lColumn, lIndex) =>
              lColumn.groups.map((group, gidx) =>
                group.rows.map((row, ridx) =>
                  row.columns.map((rColumn, rcidx) =>
                    rColumn.permission.map((cPermission, cpidx) => {
                      if (cPermission.role == role) {
                        access = cPermission.access;
                        if (access === "DontShow") {
                          let headCell = tlConfig.headCells;
                          headCell.map((head, idx) => {
                            if (
                              head.id === rColumn.key ||
                              (head.id === "SalesPersonName" &&
                                rColumn.key === "SalesPersonId")
                            ) {
                              headCell.splice(idx, 1);
                            }
                          });
                          let colCell = tlConfig.columnsSelected;
                          colCell.map((head, idx) => {
                            if (
                              head === rColumn.key ||
                              (head === "SalesPersonName" &&
                                rColumn.key === "SalesPersonId")
                            ) {
                              colCell.splice(idx, 1);
                            }
                          });
                        }
                      }
                    })
                  )
                )
              )
            );
          }
        }
      },
      (error) => {
        // this.setState({
        //   layoutFormat: {},
        // });
      }
    );
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = [];
          listRes.items.map((n) => {
            if (n.delete) {
              newSelecteds.push(n.OpportunityId);
            }
          });
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (e, status) => {
    e.preventDefault();
    this.setState({
      isListView: status,
    });
  };

  /**
   * Get Current Status Position
   */
  getStatusPosition = (status) => {
    const { crmStatusList } = this.state;
    if (status != "" && status != null) {
      var position = crmStatusList.findIndex((x) => x.StatusCode == status);
      return position > 0 ? position : 0;
    }
  };

  /**
   * Handle Deal Status
   */
  renderDealStatus = (status) => {
    const { crmStatusList } = this.state;
    return (
      <ul className="status-wraper">
        {crmStatusList.map((data, idx) => (
          <li
            key={idx}
            className={`${this.getStatusPosition(status) >= idx ? "active" : ""
              }`}
          ></li>
        ))}
      </ul>
    );
  };

  /**
   * Get all list
   */
  getList = async () => {
    const {
      OrderBy,
      filterName,
      totalRecords,
      recordsLoaded,
      pageLoaded,
      listStatus,
      userPermissions,
      listRes,
      tlConfig,
    } = this.state;
    const { deptEmployees } = this.props;

    let pageLimit = this.state.pageLimit;
    let status = listStatus;
    this.setState({ isLoading: true });
    if (!listRes.items) {
      listRes.items = [];
    }

    // personal leads
    if (listStatus == "process") {
      let personalDealsFilter = `?q=SalesPersonId=${this.loggedEmpId
        } AND UPPER(OpportunityName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreationDate:desc&limit=${pageLimit}&offset=${pageLoaded.personalDeal * pageLimit
        }`;

      if (OrderBy) {
        personalDealsFilter += "&orderBy=" + OrderBy;
      }

      let personalDealsUrl = encodeURI(
        envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + personalDealsFilter
      );

      let result = await new Promise((resolve, reject) => {
        restServices.getRequest(personalDealsUrl, (response) => {
          resolve(response);
        });
      });

      if (result.items) {
        result.items.map((item) => {
          item.delete = common.validPermission(
            item.SalesPersonId,
            "delete",
            userPermissions,
            deptEmployees,
            item.CreatedBy
          );
          if (item.delete) {
            tlConfig.delItemCount++;
          }
          listRes.items.push(item);
        });
      }
      totalRecords.personalDeal = result.totalResults;
      recordsLoaded.personalDeal += result.count;
      pageLoaded.personalDeal += 1;
      if (totalRecords.personalDeal == recordsLoaded.personalDeal) {
        status = "completed";
      }
    }

    let isRecordAvailable =
      listRes && listRes.items && listRes.items.length ? true : false;
    tlConfig.enableMultipleDelete =
      userPermissions.delete != envConfig.allPermissions[1].value &&
        isRecordAvailable
        ? true
        : false;
    this.setState(
      {
        isLoading: false,
        listStatus: status,
        //data
        listRes,
        tlConfig,
        totalRecords,
        recordsLoaded,
        pageLoaded,
      },
      () => {
        window.addEventListener("scroll", this.onScroll);
      }
    );
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let dealTypeId = common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMDealStatus(dealTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmDealStatus } = props;

    var crmStatusData = state.crmStatusList;

    if (crmDealStatus && crmDealStatus.list && crmDealStatus.list.success) {
      crmStatusData = crmDealStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
      };
    } else {
      return {};
    }
  }

  // search
  filterData() {
    this.setState(
      {
        listRes: {},
        listStatus: "process",
        isLoading: true,
        totalRecords: {
          allDeals: 0,
          personalDeal: 0,
          myDeals: 0,
        },
        recordsLoaded: {
          allDeals: 0,
          personalDeal: 0,
          myDeals: 0,
        },
        pageLoaded: {
          allDeals: 0,
          personalDeal: 0,
          myDeals: 0,
        },
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      crmStatusList,
    } = this.state;

    const isHided = (OpportunityId) =>
      tlConfig.columnsSelected.indexOf(OpportunityId) !== -1;
    const isSelected = (OpportunityId) =>
      tlConfig.selected.indexOf(OpportunityId) !== -1;

    const handleClick = (event, OpportunityId) => {
      const selectedIndex = tlConfig.selected.indexOf(OpportunityId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, OpportunityId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    return (
      <div className="deal-wraper">
        {userPermissions.read == 2 ? (
          <AccessDenied />
        ) : (
            <React.Fragment>
              <div className="filter-wrap">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-4">
                    <div className="form-row">
                      <div className="col">
                        <h2 className="crm-type-title">My Deals</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    {this.state.isListView && (
                      <div className="form-row justify-content-end">
                        <div className="col-lg-4">
                          <label
                            className="sr-only"
                            htmlFor="inlineFormInputGroup"
                          >
                            Filter and Search
                        </label>
                          <div className="input-group search-filter-group mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder="+ Filter Deal Name"
                              name="filterName"
                              value={filterName}
                              onChange={this.handleFieldChange}
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <img
                                  src={SearchIcon}
                                  alt="Search"
                                  onClick={() => this.filterData()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <EnhancedTableColumns
                            tlConfig={tlConfig}
                            updateTLConfig={this.updateTLConfig}
                          />
                        </div>

                        {/* <div className="col-auto">
                      {userPermissions.export != 2 && (
                        <button type="button" className="btn btn-export">
                          Export
                        </button>
                      )}
                      </div>
                      <div className="col-auto">
                        {userPermissions.add != 2 && (
                          <Link to="/crm/deal/add/">
                            <button type="button" className="btn btn-add">
                              Add Deal
                            </button>
                          </Link>
                        )}
                      </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
              {/* end of Filter Wrap */}
              <div className="crm-data-wrap">
                <div className="tab-section float-right">
                  <ul className="tab-list">
                    <li className={this.state.isListView ? "active" : ""}>
                      <Link to="#" onClick={(e) => this.changeTabView(e, true)}>
                        List
                    </Link>
                    </li>
                    <li className={!this.state.isListView ? "active" : ""}>
                      <Link to="#" onClick={(e) => this.changeTabView(e, false)}>
                        Kanban
                    </Link>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
                {this.state.isListView ? (
                  <ListView
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                    isLoading={isLoading}
                    data={listRes}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    isHided={isHided}
                    renderStatus={this.renderDealStatus}
                    handlePageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageLimit={pageLimit}
                    from="mydeal"
                    search={filterName}
                    filterData={this.filterData}
                    deptEmployees={this.props.deptEmployees}
                  />
                ) : (
                    crmStatusList != "" && (
                      <KanbanBoard
                        kanbanType="mydeal"
                        status={crmStatusList}
                        userPermissions={userPermissions}
                        deptEmployees={this.props.deptEmployees}
                      />
                    )
                  )}
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, crmDealStatus } = state;
  return { alert, crmDealStatus };
};

export default connect(mapStateToProps)(MyDealList);
