import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// includes
import { crm } from '_utils';
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";

// Defined Errors
const defErrs = {
    PartyClassCode: {
        isReq: 'Party Class Code is required!',
    },
    StartDate: {
        isReq: 'StartDate is required!',
    },
    Active: {
        isReq: 'Active is required!',
    },
};

class AddClassification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            formSubmit: false,
            fields: {
                TenantId: crm.userInfo().pTenantId,
                OrgId: crm.userInfo().pOrgId,
                PartyClassCode: "",
                PartyId: 0,
                StartDate: new Date(),
                Active: "Y",
                EndDate: null,
            },
            commonResource: {
                PartyClassId: 0,
            },
            masterData: {
                typeOfParty: []
            },
            errors: {},
        };
    }

    componentDidMount() {
        const { commonResource, fields } = this.state;

        this.defineInitErrorMsg();
        this.getLookUpData('CHOLA_PARTY_CLASS_CODE', 'typeOfParty');

        if (this.props.match.params.partyId != undefined) {
            fields.PartyId = this.props.match.params.partyId
                ? this.props.match.params.partyId
                : 0;
            this.setState({
                fields,
            });
        }

        if (this.props.match.params.partyClassId != undefined) {
            commonResource.PartyClassId = this.props.match.params.partyClassId
                ? this.props.match.params.partyClassId
                : 0;
            this.setState(
                {
                    commonResource: commonResource,
                },
                () => {
                    const { commonResource } = this.state;
                    if (commonResource.PartyClassId > 0) {
                        this.getDetailById(commonResource.PartyClassId);
                    }
                }
            );
        }
    }

    /**
     * Initialize error message
     */
    defineInitErrorMsg = () => {
        const { errors, fields } = this.state;
        for (var key of Object.keys(defErrs)) {
            switch (key) {
                case "Active":
                    if (fields[key] == "N") {
                        errors[key] = defErrs[key].isReq;
                    } else {
                        errors[key] = "";
                    }
                    break;
                default:
                    if (fields[key] == null || fields[key] == "") {
                        errors[key] = defErrs[key].isReq;
                    }
            }
        }
        this.setState({
            errors: errors,
        });
    };

    /**
     * Redirect to territory list page
     */
    onCancel = () => {
        history.push("/setup/vendor/party/edit/" + this.state.fields.PartyId + "?tab=classification");
    }

    /**
     * Get lookup values by lookup code
     */
    getLookUpData = (lookupValue, source) => {
        const { masterData } = this.state;

        let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
        let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    masterData[source] = common.getOptionsData(response.items, "Name", "Code");
                    this.setState({
                        masterData: masterData,
                    });
                }
            },
            (error) => { }
        );
    };


    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(name, inputValue);
            }
        );
    };

    selectHandleOnChange = (event, name) => {
        const { value } = event;
        const { fields } = this.state;

        if (fields.hasOwnProperty(name)) {
            fields[name] = value;
        }
        this.setState({ fields: fields }, () => {
            this.validateForm(name, value);
        });
    }

    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (defErrs.hasOwnProperty(name)) {
                errors[name] = defErrs[name].isReq;
            }
        } else {
            // Optional Validation Here
            switch (name) {
                case "Active":
                    if (value == "N") {
                        errors[name] = defErrs[name].isReq;
                    } else {
                        errors[name] = "";
                    }
                    break;
                default:
                    errors[name] = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    getDetailById = (id) => {

        const { fields, errors, commonResource } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_CLASSIFICATION + "/" + id);
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    for (var key of Object.keys(response)) {
                        if (fields.hasOwnProperty(key)) {
                            if (response[key]) {
                                if (
                                    key === 'StartDate' ||
                                    key === 'EndDate'
                                ) {
                                    fields[key] = response[key] ? new Date(response[key]) : '';
                                } else {
                                    fields[key] = response[key];

                                }

                                // Remove Default Errors
                                if (errors.hasOwnProperty(key)) {
                                    // Optional Validation Here
                                    if (key === "Active") {
                                        if (response[key] === "Y") {
                                            errors[key] = "";
                                        } else {
                                            errors[key] = defErrs[key].isReq;
                                        }
                                    } else if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                                        errors[key] = "";
                                    }
                                }
                            }
                        }
                    }

                    this.setState(
                        {
                            fields: fields,
                            errors: errors,
                        },
                        () => {
                        }
                    );
                } else { }
            },
            (error) => { }
        );
    };

    save = (event) => {
        event.preventDefault();

        const { errors, commonResource } = this.state;
        var isValid = true;

        this.setState({
            formSubmit: true,
        });

        for (var key of Object.keys(errors)) {
            if (!isValid) {
                break;
            }
            isValid = !errors[key];
        }

        if (isValid) {
            if (commonResource.PartyClassId > 0) {
                this.update();
            } else {
                this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.CRM_PARTY_CLASSIFICATION + "/" + commonResource.PartyClassId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/party/edit/" + fields.PartyId + "?tab=classification");
                }
            },
            (error) => { }
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_CLASSIFICATION);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/party/edit/" + fields.PartyId + "?tab=classification");
                }
            },
            (error) => { }
        );
    };

    render() {
        const {
            fields,
            masterData,
            loading,
            errors,
            formSubmit,
            commonResource,
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            <div className="lead-wraper">
                <Form onSubmit={this.save}>
                    <div className="formWhiteBg">
                        <div className="formHead">
                            <h2>SITE Information</h2>
                        </div>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Party Class Code*</Form.Label>
                                    <div className="select-control">
                                        <Select
                                            value={masterData.typeOfParty.find(o => o.value === fields.PartyClassCode)}
                                            onChange={(e) => this.selectHandleOnChange(e, 'PartyClassCode')}
                                            options={masterData.typeOfParty}
                                        />
                                    </div>
                                    {errors.PartyClassCode !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.PartyClassCode}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>&nbsp;</Form.Label>
                                    <Checkbox
                                        name="Active"
                                        checked={isChecked(fields.Active)}
                                        label="Active"
                                        labelStyle={{ color: '#2d2d2d' }}
                                        labelPosition="left"
                                        onClick={(e) => this.handleOnChange(e)}
                                    />
                                    {errors.Active !== 'Y' && formSubmit === true && (
                                        <Form.Text className="error">
                                            {errors.Active}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Start Date*</Form.Label>
                                    <DatePicker
                                        name="StartDate"
                                        value={fields.StartDate}
                                        onChange={(date) =>
                                            this.handleDateOnChange(date, 'StartDate')
                                        }
                                        format="dd/MM/yyyy"
                                        autoComplete="off"
                                        minDate={new Date()}
                                    />
                                    {errors.StartDate !== '' && formSubmit === true && (
                                        <Form.Text className="error">
                                            {errors.StartDate}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker
                                        name="EndDate"
                                        value={fields.EndDate}
                                        onChange={(date) =>
                                            this.handleDateOnChange(date, 'EndDate')
                                        }
                                        format="dd/MM/yyyy"
                                        autoComplete="off"
                                        minDate={new Date()}
                                    />
                                    {errors.EndDate !== '' && formSubmit === true && (
                                        <Form.Text className="error">
                                            {errors.EndDate}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <Row className="botBtn">
                        <Col sm={12}>
                            <Button className="saveBtn" type="submit">
                                {commonResource.PartyClassId > 0 ? "Update" : "Save"}
                            </Button>
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export { AddClassification };
