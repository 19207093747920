// Import default packages
import React, { createRef } from "react";
import { Link } from "react-router-dom";

//Import CSS
import "../css/Party.css";

// Import table packages
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

// Import Icons
import SearchIcon from "img/search-icn.png";

// Import internal components
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tableConfig, restServices } from "_helpers";

// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import FileExport from "../../../_components/FileExport/FileExport";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

const child = createRef();
class PartyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isListView: true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      tlConfig: {
        headCells: [
          { id: "PartyCode", value: "PartyCode", label: "Party Code" },
          { id: "PartyName", value: "PartyName", label: "Party Name" },
          { id: "PartyType", value: "PartyType", label: "Party Type" },
          { id: "Description", value: "Description", label: "Description" },
          { id: "StartDate", value: "StartDate", label: "Start Date" },
          { id: "EndDate", value: "EndDate", label: "End Date" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "PartyName",
        selected: [],
        uniqueKey: "PartyListcolumns",
      },
      filterName: "",
      // permission
      userPermissions: common.getPermission(this.props, "vendor:parties"),
      headers: [
        { label: "ID", key: "PartyId" },
        { label: "Party Code", key: "PartyCode" },
        { label: "Party Name", key: "PartyName" },
        { label: "Party Type", key: "PartyType" },
        { label: "Description", key: "Description" },
        { label: "Start Date", key: "StartDate" },
        { label: "End Date", key: "EndDate" },
      ],
      urls: "",
      totalResults: "",
      keyArray: [
        "PartyId",
        "PartyCode",
        "PartyName",
        "PartyType",
        "Description",
        "StartDate_date",
        "EndDate_date",
      ],
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig } = this.state;

    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  // /**
  //  * Handle tab controll to switch between list and kanban view
  //  */
  // changeTabView = (_) => {
  //   this.setState(
  //     {
  //       isListView: !this.state.isListView,
  //     },
  //     (_) => {}
  //   );
  // };

  /**
   * Get all list
   */
  getList = () => {
    const { currentPage, OrderBy, pageLimit, filterName } = this.state;
    this.setState({ isLoading: true });
    let filter = `?totalResults=true&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }`;
    if (filterName != "") {
      filter = `?q=UPPER(PartyName) LIKE '*${filterName}%*&totalResults=true&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
        }`;
    }

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_HDR + filter);

    restServices.getRequest(
      url,
      (response) => {
        this.setState(
          {
            listRes: response,
            isLoading: false,
            urls: envConfig.BASE_API + Services.CRM_PARTY_HDR,
            totalResults: response.totalResults,
          },
          (_) => {
            if (child.current) {
              child.current.getCsvData();
            }
          }
        );
      },
      (error) => {
        this.setState({
          listRes: {},
          isLoading: false,
        });
      }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      OrderBy,
      headers,
      urls,
      totalResults,
      keyArray,
    } = this.state;

    const isHided = (PartyId) =>
      tlConfig.columnsSelected.indexOf(PartyId) !== -1;

    let filter = `?totalResults=true&limit=${totalResults}&offset=0`;
    if (filterName != "") {
      filter = `?q=UPPER(PartyName) LIKE '*${filterName}%*&totalResults=true&limit=${totalResults}&offset=0`;
    }

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return !userPermissions.access ? (
      <AccessDenied />
    ) : (
        <div className="lead-wraper">
          {/* end of Secondary Nav */}
          <div className="filter-wrap">
            <div className="row justify-content-between">
              <div className="col-12 col-lg-3">
                <div className="form-row">
                  <div className="col">
                    <h2 className="crm-type-title">Parties</h2>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="form-row justify-content-end">
                  <div className="col-lg-4">
                    <label className="sr-only" htmlFor="inlineFormInputGroup">
                      Filter and Search
                  </label>
                    <div className="input-group search-filter-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="inlineFormInputGroup"
                        placeholder="+ Filter Party Name"
                        name="filterName"
                        value={filterName}
                        onChange={this.handleFieldChange}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                            onClick={this.getList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <EnhancedTableColumns
                      tlConfig={tlConfig}
                      updateTLConfig={this.updateTLConfig}
                    />
                  </div>
                  {userPermissions.export && (
                    <div className="col-auto">
                      <FileExport
                        ref={child}
                        headers={headers}
                        filename={"Parties_details.xls"}
                        urls={urls}
                        keyArray={keyArray}
                        filter={filter}
                      />
                    </div>
                  )}
                  {userPermissions.add && (
                    <div className="col-auto">
                      <Link to="/setup/vendor/party/add">
                        <button type="button" className="btn btn-add">
                          Add Party
                      </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>{" "}
          {/* end of Filter Wrap */}
          <div className="crm-data-wrap">
            <div className="clearfix"></div>

            <div className="list-view-wrap">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <EnhancedTableHead
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                  <TableBody>
                    {isLoading == false &&
                      listRes.items &&
                      listRes.items.length > 0 &&
                      tableConfig
                        .stableSort(
                          listRes.items,
                          tableConfig.getComparator(
                            tlConfig.order,
                            tlConfig.orderBy
                          )
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.PartyId}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={
                                  !isHided("PartyCode") ? "hideCell" : ""
                                }
                              >
                                {userPermissions.update ? (
                                  <Link to={"/setup/vendor/party/edit/" + row.PartyId + "?tab=general"}>
                                    {row.PartyCode ? row.PartyCode : "-"}
                                  </Link>
                                ) : (
                                    row.PartyCode ? row.PartyCode : "-"
                                  )}
                              </TableCell>

                              <TableCell
                                className={
                                  !isHided("PartyName") ? "hideCell" : ""
                                }
                              >
                                {row.PartyName ? row.PartyName : "-"}
                              </TableCell>
                              <TableCell
                                className={
                                  !isHided("PartyType") ? "hideCell" : ""
                                }
                              >
                                {row.PartyType ? row.PartyType : "-"}
                              </TableCell>
                              <TableCell
                                className={
                                  !isHided("Description") ? "hideCell" : ""
                                }
                              >
                                {row.Description ? row.Description : "-"}
                              </TableCell>

                              <TableCell
                                className={
                                  !isHided("StartDate") ? "hideCell" : ""
                                }
                              >
                                {row.StartDate
                                  ? common.formatDate(row.StartDate, "DD/MM/YYYY")
                                  : "-"}
                              </TableCell>
                              <TableCell
                                className={!isHided("EndDate") ? "hideCell" : ""}
                              >
                                {row.EndDate
                                  ? common.formatDate(row.EndDate, "DD/MM/YYYY")
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    {listRes.items &&
                      listRes.items.length == 0 &&
                      isLoading == false && (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <NoRecordFound />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                    {isLoading == true && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Loader />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {isLoading == false && listRes.totalResults > 0 && (
                <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                  <div className="mr-auto">
                    {tableConfig.showEntries(currentPage, pageLimit, listRes)}
                  </div>
                  <div className="pagination-wrap">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={currentPage}
                      itemsCountPerPage={listRes.limit}
                      totalItemsCount={listRes.totalResults}
                      pageRangeDisplayed={7}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end of Switch View Control */}
        </div>
      );
  }
}

export { PartyList };
