import React, { Fragment } from 'react';
import { common } from "_helpers";
import DynamicForm from "crm/DynamicForm";

function DemoDynamicForm(props) {
  const authInfo = common.authInfo();
  const authData = () => {
    return { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() }
  }
  return (
    <Fragment>
      <DynamicForm
        module="meeting"
        data={{
          value1: "",
          value2: ""
        }}
      />
      {/* <DynamicForm
        module="campaign"
        data={{
          value1: "",
        }}
        default={{
          "CampaignOwnerId": authData()
        }}
      />
      <DynamicForm
        module="enquiry"
        data={{
          value1: "",
          value2: ""
        }}
        default={{
          "CampaignId": {
            "label": "Book Exhibition",
            "value": "1746"
          }
        }}
        disabled={{ "CampaignId": true }}
      />
      <DynamicForm
        module="lead"
        data={{
          value1: "",
          value2: ""
        }}
        default={{
          "SalespersonId": authData()
        }}
      />
      <DynamicForm
        module="deal"
        data={{
          value1: "",
          value2: ""
        }}
        default={{
          "SalesPersonId": authData()
        }}
      />
      <DynamicForm
        module="enquiryMember"
        data={{
          value1: "",
          value2: ""
        }}
      />
      <DynamicForm
        module="contacts"
        data={{
          value1: "",
          value2: ""
        }}
      />
      <DynamicForm
        module="meeting"
        data={{
          value1: "",
          value2: ""
        }}
      />
      <DynamicForm
        module="callLogs"
        data={{
          value1: "",
          value2: ""
        }}
      /> */}
    </Fragment>
  )
}

export default DemoDynamicForm;