import React, { useState, useEffect } from "react";
// Import permission json file
import jsonPermission from "json/permission.json";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Form, Col, Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { common, restServices } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
// Import Loader
import Loader from "_components/_loader/Loader";
// includes
import { crm } from "_utils";
// //Import Images
import expandIcon from "img/expand.png";
import collapseIcon from "img/collapse.png";
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});
function AddEditRole(props) {
   const [accPermissions, setAccPermissions] = useState([
    "r",
    "a",
    "u",
    "d",
    "i",
    "e",
  ]);
  const [accPermissionsName, setAccPermissionsName] = useState([
    "read",
    "add",
    "update",
    "delete",
    "import",
    "export",
  ]);
  const [expandList, setExpandList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [permission,setPermission] = useState(jsonPermission);
  const [roleData, setRoleData] = useState([]);
  const [groupID, setGroupID] = useState(0);
  const collapse = (data) => {
    let _expandList = expandList;
    if (_expandList.indexOf(data) !== -1) {
      _expandList = _expandList.filter((item) => item !== data);
      setExpandList(_expandList);
    } else {
      _expandList.push(data);
      setExpandList(_expandList);
    }
  };



  useEffect(() => {
    setLoading(true);
    getAllParentRoles();
    getUserActiveRole(props.match.params.userId, props.match.params.id, 0, 0);
  }, []);
  const getUserActiveRole = (userID, userGroupID, flag, group_id) => {
    let url = encodeURI(
      envConfig.AUTH_REST_URL +
        Services.USER_ROLE_GROUPS +
        "?q=UserId=" +
        userID +
        ";UserGroupId=" +
        userGroupID
    );
    restServices.getRequest(url, (response) => {
      //setLoading
      if (group_id !== 0) {
        if (response.items[0].GroupId !== group_id) {
          setGroupID(group_id);
          _get_groupid_permission(group_id);
        } else {
          if (response.items[0].UserAccess == null) {
            setGroupID(response.items[0].GroupId);
            _get_groupid_permission(response.items[0].GroupId);
          } else {
            setGroupID(group_id);
            let parseStringify = JSON.parse(response.items[0].UserAccess);
            setPermission(parseStringify)
            setLoading(false);
          }
        }
      } else {
        if (response.items[0].UserAccess == null) {
          setGroupID(response.items[0].GroupId);
          _get_groupid_permission(response.items[0].GroupId);
        } else {
          setGroupID(response.items[0].GroupId);
          let parseStringify = JSON.parse(response.items[0].UserAccess);
          setPermission(parseStringify)
          setLoading(false);
        }
      }
     
    });
  };
  const _get_groupid_permission = (GroupId) => {
    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + GroupId
    );
    setLoading(true);
    restServices.getRequest(url, async (response) => {
      if (response) {
        if (response.UserAccess !== null) {
          let parseStringify = JSON.parse(response.UserAccess);
          setPermission(parseStringify)
          setLoading(false);
        } else {
          setPermission(jsonPermission)
          setLoading(false);
        }
      }
    });
  };
  const selectHandleOnChange = (e) => {
    setGroupID(e.value);
    getUserActiveRole(
      props.match.params.userId,
      props.match.params.id,
      1,
      e.value
    );
  };
  const getAllParentRoles = () => {
    let filter = `?q=GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${
      Services.CRM_DEFAULT_GROUPS_2
    }','${Services.CRM_DEFAULT_GROUPS_3}','${
      Services.CRM_DEFAULT_GROUPS_4
    }') AND TenantId=${
      crm.userInfo().pTenantId
    }&fields=GroupId,GroupDesc,TenantId&limit=1000&offset=0&finder=FindParentGroups&orderBy=CreatedOn:desc`;
    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);
    restServices.getRequest(
      url,
      (response) => {
        let role_process = [];
        if (response && response.items && response.items.length > 0) {
          for (var i = 0; i < response.items.length; i++) {
            role_process.push({
              label: response.items[i].GroupDesc,
              value: response.items[i].GroupId,
            });
          }
          setRoleData(role_process);
        }
      },
      (error) => {
        common.snack("E", error);
        setRoleData([]);
      }
    );
  };

  const handleChange = (
    event,
    isparent,
    parentname,
    access,
    _permission,
    child
  ) => {
    let { value } = event;
    let tempList = JSON.parse(JSON.stringify(permission));
    if (isparent) {
      tempList[parentname][access] = _permission;
      setPermission(tempList);
    } else {
      tempList[parentname][child][access] = _permission;
      setPermission(tempList);
    }
  };
  const updatePermission = () => {
    setUpdateLoader(true);
    let url = encodeURI(
      envConfig.AUTH_REST_URL +
        Services.USER_ROLE_GROUPS +
        "/" +
        props.match.params.id
    );
    let getGroupDesc = roleData.filter((data) => data.value == groupID);
    let parms = {
      GroupId: groupID,
      GroupId1: groupID,
      GroupDesc: getGroupDesc[0].label,
      UserAccess: JSON.stringify(permission),
    };
    restServices.patchRequest(url, parms, (response) => {
      let parse = JSON.parse(response.UserAccess)
      setPermission(parse);
      setUpdateLoader(false);
      common.snack("S", "Permission updated successfully")
    });
  };


  return (
    <div>
      <Row className="whiteBgRow">
        <Col sm={12} className="formWhiteBg">
          <div className="formHead">
            <h2>Role Information</h2>
          </div>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Role*</Form.Label>
                <div className="select-control filter-selectbox">
                  <Select
                    value={roleData.find((o) => o.value === groupID)}
                    onChange={(e) => selectHandleOnChange(e)}
                    options={roleData}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Active</Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="whiteBgRow" style={{ marginTop: 28 }}>
        <Col sm={12} className="formWhiteBg">
          <div className="list-view-wrap bottom-set">
            {loading ? (
              <Loader />
            ) : (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Module</TableCell>
                      {accPermissionsName.map((parent, parentIndex) => {
                        return (
                          <TableCell key={parentIndex}>{parent}</TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  {Object.keys(permission).length ? (
                    <TableBody key="tb_body">
                      {Object.keys(permission).map((module, mIdx) => {
                        let child = Object.keys(permission[module]);
                        const output = child.filter(function (obj) {
                          return accPermissions.indexOf(obj) !== -1;
                        });
                        let isChild = output.length > 0 ? false : true;
                        return !isChild ? (
                          <TableRow>
                            <TableCell key={mIdx}>
                              <span className="td-set">{module}</span>
                            </TableCell>
                            {Object.keys(permission[module]).map(
                              (access, aIndex) => {
                                return (
                                  <TableCell key={aIndex}>
                                    <Form.Group>
                                      <Select
                                        key={module + "_" + access}
                                        options={envConfig.permissions}
                                        value={envConfig.permissions.find(
                                          (o) =>
                                            o.value ==
                                            permission[module][access]
                                        )}
                                        onChange={(e) =>
                                          handleChange(
                                            e,
                                            1,
                                            module,
                                            access,
                                            e.value,
                                            0
                                          )
                                        }
                                        name={module + "_" + access}
                                      />
                                    </Form.Group>
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        ) : (
                          <React.Fragment>
                            <TableRow>
                              <TableCell className="module-name">
                                <Link
                                  to="#"
                                  style={{
                                    marginRight: 15,
                                    position: "relative",
                                    top: -1,
                                  }}
                                  onClick={() => collapse(module)}
                                >
                                  <img
                                    src={
                                      expandList.indexOf(module) !== -1
                                        ? collapseIcon
                                        : expandIcon
                                    }
                                    alt="expand"
                                  />
                                </Link>
                                <span className="module-name">{module}</span>
                              </TableCell>
                            </TableRow>
                            {Object.keys(permission[module]).map(
                              (child, cIdx) => {
                                return (
                                  <TableRow
                                    id={module}
                                    className={
                                      expandList.indexOf(module) !== -1
                                        ? ""
                                        : "hideCell"
                                    }
                                  >
                                    <TableCell
                                      key={cIdx}
                                      className="module-name "
                                    >
                                      <span className="td-set">{child}</span>
                                    </TableCell>
                                    {Object.keys(permission[module][child]).map(
                                      (access, aIndex) => {
                                        return (
                                          <TableCell key={aIndex}>
                                            <Form.Group>
                                              <Select
                                                key={
                                                  module +
                                                  "_" +
                                                  child +
                                                  "_" +
                                                  access
                                                }
                                                options={
                                                  child == "lead" ||
                                                  child == "deal"
                                                    ? envConfig.allPermissions
                                                    : envConfig.permissions
                                                }
                                                value={envConfig.allPermissions.find(
                                                  (o) =>
                                                    o.value ==
                                                    permission[module][child][
                                                      access
                                                    ]
                                                )}
                                                onChange={(e) =>
                                                  handleChange(
                                                    e,
                                                    0,
                                                    module,
                                                    access,
                                                    e.value,
                                                    child
                                                  )
                                                }
                                                name={
                                                  module +
                                                  "_" +
                                                  child +
                                                  "_" +
                                                  access
                                                }
                                              />
                                            </Form.Group>
                                          </TableCell>
                                        );
                                      }
                                    )}
                                  </TableRow>
                                );
                              }
                            )}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            )}
            {loading ? (
              <Loader />
            ) : (
              <Row className="bottomBtn">
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    type="button"
                    onClick={updatePermission}
                  >
                    {!updateLoader ? "Update" : "Updating..."}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export { AddEditRole };