import { common } from "_helpers";

/**
 * Get Rest API Request Headers
 */
function getHeaders(URL, method) {
    let token = localStorage.getItem("userToken");
    var headers = {};

    //headers["Access-Control-Allow-Origin"] = "*/*";
    headers['Access-Control-Allow-Origin'] = '*';
    headers['Access-Control-Allow-Headers'] = 'Content-Type';
    headers['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';

    if (method == "POST" && (URL.includes("CholaCreateUser") || URL.includes("CholaCustomAttributes")) || URL.includes("CholaCustomConversion")) {
        headers["Content-Type"] = "application/vnd.oracle.adf.action+json";
    } else {

        headers["Content-Type"] = "application/vnd.oracle.adf.resourceitem+json";
    }
    if (method == "BATCH") {
        headers["Content-Type"] = "application/vnd.oracle.adf.batch+json";
    }
    if (URL.includes("cholaauthservices") && token) {
        let userData = common.authInfo();
        let basic_auth = userData.basicToken.replace(/['"]+/g, '')
        headers.Authorization = 'Basic ' + basic_auth;
    }
    else if (token) {
        headers.Authorization = "Bearer " + token
    }
    if (method == "GET" && URL.includes("UPPER(") || URL.includes("IN(") || URL.includes("CholaTransactionTypes") || URL.includes("FindParentGroups") || URL.includes(" AND ")) {
        headers["REST-Framework-Version"] = 2;
    }
    return headers;
}

export const restServices = {

    /**
     * Get Request with valid values and callbacks to get responses
     * @param {*} URL 
     * @param {*} successBlock 
     * @param {*} failBlock 
     */
    getRequest(
        URL,
        successBlock,
        failBlock
    ) {

        let requestOptions = {
            method: "GET",
            headers: getHeaders(URL, "GET")
        };

        fetch(URL, requestOptions)
            .then(response => {
                return common.checkValidJSONReponse(response);
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    if (failBlock) failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                if (failBlock) failBlock(error);
            });
    },

    /**
     * Post request with valid values and callbacks to get responses
     * @param {*} URL 
     * @param {*} data 
     * @param {*} successBlock 
     * @param {*} failBlock 
     */
    postRequest(
        URL,
        data,
        successBlock,
        failBlock
    ) {

        let requestOptions = {
            method: "POST",
            headers: getHeaders(URL, "POST"),
            body: JSON.stringify(data)
        };

        fetch(URL, requestOptions)
            .then(response => {
                if (URL.includes("CholaCreateUser") || URL.includes("CholaCustomConversion")) {
                    return response;
                } else {
                    return common.checkValidJSONReponse(response);
                }
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                failBlock(error);
            });

    },

    /**
    * Batch request with valid values and callbacks to get responses
    * @param {*} URL 
    * @param {*} data 
    * @param {*} successBlock 
    * @param {*} failBlock 
    */
    batchRequest(
        URL,
        data,
        successBlock,
        failBlock
    ) {

        let requestOptions = {
            method: "POST",
            headers: getHeaders(URL, "BATCH"),
            body: JSON.stringify(data)
        };

        fetch(URL, requestOptions)
            .then(response => {
                return response.status === 200 ? response : null;
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                failBlock(error);
            });

    },
    /**
     * Patch request with valid values and callbacks to get responses
     * @param {*} URL 
     * @param {*} data 
     * @param {*} successBlock 
     * @param {*} failBlock 
     */
    patchRequest(
        URL,
        data,
        successBlock,
        failBlock
    ) {

        let requestOptions = {
            method: "PATCH",
            headers: getHeaders(URL, "PATCH"),
            body: JSON.stringify(data)
        };

        fetch(URL, requestOptions)
            .then(response => {
                return common.checkValidJSONReponse(response);
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                failBlock(error);
            });

    },

    /**
     * Delete request with valid values and callbacks to get responses
     * @param {*} URL 
     * @param {*} successBlock 
     * @param {*} failBlock 
     */
    deleteRequest(
        URL,
        successBlock,
        failBlock
    ) {
        let requestOptions = {
            method: "DELETE",
            headers: getHeaders(URL, "DELETE")
        };

        fetch(URL, requestOptions)
            .then(response => {
                return (response.status === 200 || response.status === 204) ? response : null;
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                failBlock(error);
            });

    },

    /**
    * Post request with valid values and callbacks to get responses
    * @param {*} URL 
    * @param {*} data 
    * @param {*} successBlock 
    * @param {*} failBlock 
    */
    filePostRequest(
        URL,
        data,
        successBlock,
        failBlock
    ) {

        let requestOptions = {
            method: "POST",
            headers: getHeaders(URL, "POST"),
        };

        if (Object.keys(data).length > 0) {
            requestOptions["body"] = JSON.stringify(data);
        }

        fetch(URL, requestOptions)
            .then(response => {
                // Convert the data into 'blob'
                return response.blob();
            })
            .then(result => {
                if (result) {
                    successBlock(result);
                } else {
                    failBlock("Something Went Wrong");
                }
            })
            .catch(error => {
                failBlock(error);
            });

    },

    /**
     * Get request with valid values and callbacks to get responses
     * @param {*} URL
     * @param {*} data
     * @param {*} successBlock
     * @param {*} failBlock
     */
    fileGetRequest(URL, successBlock, failBlock) {
        let requestOptions = {
        method: "GET",
        headers: getHeaders(URL, "GET"),
        };

        fetch(URL, requestOptions)
        .then(function (response) {
            return response.blob();
        })
        .then(function (blob) {
            // here the image is a blob
            if (blob) {
            successBlock(blob);
            } else {
            failBlock("Something Went Wrong");
            }
        })

        .catch((error) => {
            failBlock(error);
        });
    },

};