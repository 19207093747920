import React, { Fragment, useEffect, useState } from 'react';
import { Form, Col, Row, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import DynamicForm from "crm/DynamicForm";
import { common } from "_helpers";
import './includes/QuickForm.css';

function QuickForm(props) {
  const [isModal, setIsModal] = useState(false);
  const [module, setModule] = useState("");
  // const
  const authInfo = common.authInfo();
  const authData = { label: authInfo.EmployeeName, value: authInfo.EmpId.toString() }
  const moduleList = [
    {
      code: "campaign",
      name: "Campaign",
      propsData: { data: { value1: "" }, default: { "CampaignOwnerId": authData } }
    },
    {
      code: "enquiry",
      name: "Enquiry",
      propsData: { data: { value1: "", value2: "" } }
    },
    {
      code: "lead",
      name: "Lead",
      propsData: { data: { value1: "", value2: "" }, default: { "SalespersonId": authData } }
    },
    {
      code: "deal",
      name: "Deal",
      propsData: { data: { value1: "", value2: "" }, default: { "SalesPersonId": authData } }
    },
    {
      code: "contacts",
      name: "Contacts",
      propsData: { data: { value1: "" }, default: { "CampaignOwnerId": authData } }
    },
  ]

  // effect
  useEffect(() => {
    setIsModal(props.show);
  }, [props.show])

  // function
  const onAction = () => {
    setIsModal(false);
    setModule("");
    if (typeof (props.onComplete) !== "undefined") {
      props.onComplete(false);
    }
  }

  return (
    <Fragment>
      <Modal size="lg" show={isModal} backdrop="static">
        <div className="quickForm">
          <h2>Quick Create</h2>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Control
                  as="select"
                  name="LeadType"
                  onChange={e => setModule(e.target.value)}
                >
                  <option value="">Select</option>
                  {moduleList.map((data, key) => (
                    <option key={key} value={key}>{data.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Modal.Body>
          {module === "" ? (
            <div className="quickSelect">
              <p>Please select the module above and create a quick one</p>
              <img src={common.loadImg("selected.png")} alt="Select" />
              <Button color="secondary" variant="outlined" size="small" className="mt-4" onClick={() => onAction()}>Cancel</Button>
            </div>
          ) : (
            <DynamicForm
              module={moduleList[module].code}
              quick={true}
              {...moduleList[module].propsData}
              onSave={() => onAction()}
              onCancel={() => onAction()}
            />
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default QuickForm;