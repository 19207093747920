import React, { useEffect, useState } from 'react';
import moment from "moment";
import DatePicker from "react-datepicker";
import AmlHashForm from "aml-hash-form";
import { Form, Button } from "react-bootstrap";
// material
import Checkbox from "material-ui/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// confirm
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { tools } from "_helpers";
// pages
import FieldSelect from "crm/FieldSelect";
import FieldLookup from "crm/FieldLookup";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function TaskModal(props) {
  const [status, setStatus] = useState("process");
  // form
  const formFields = {
    actionName: { validate: ["req"] },
    actionSubject: { validate: ["req"] },
    description: { validate: [] },
    toUser: { validate: ["objValReq#Please select the field"], obj: "value" },
    taskStatus: { validate: ["objValReq#Please select the field"], obj: "value" },
    priority: { validate: ["objValReq#Please select the field"], obj: "value" },
    startDate: { validate: ["req"] },
    endDate: { validate: ["req"] },
    scheduled: { validate: [] },
    scheduledNo: { validate: ["req", "gte:0"], parent: { field: "scheduled", value: "Y" } },
    scheduledType: { validate: ["req"], parent: { field: "scheduled", value: "Y" } },
  }
  const { fields, errors, setValue, setMultiValue, handleChange, handleSubmit } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    setMultiValue(props.data);
    setStatus("completed");
  }, [])

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      props.onSuccess(tools.reParse(fields));
    }
  }

  return (
    <div className="target-wrap" style={{ minHeight: "800px" }}>
      {status === "completed" && (
        <div>
          <Dialog
            open={true}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
            fullWidth={true}
            className="target-modal-wrap"
            disableBackdropClick={true}
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">Task</DialogTitle>
            <DialogContent>
              <div>
                <div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label>Action Name</label>
                      <input
                        type="text"
                        name="actionName"
                        className="form-control"
                        placeholder="Enter the action name"
                        value={fields.actionName}
                        onChange={handleChange}
                      />
                      <Form.Text className="error">{errors.actionName}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Action Subject</label>
                      <input
                        type="text"
                        name="actionSubject"
                        className="form-control"
                        placeholder="Enter the action name"
                        value={fields.actionSubject}
                        onChange={handleChange}
                      />
                      <Form.Text className="error">{errors.actionSubject}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <label>Description</label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        maxLength={150}
                        placeholder="Enter the description"
                        name="description"
                        value={fields.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <label>From User</label>
                      <input type="text" className="form-control" value={`${fields.fromUser?.label} (${fields.fromUser?.value})`} disabled={true} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <label>To User(s)</label>
                      <FieldSelect
                        api="CRM_EMPLOYEES"
                        get="EmployeeName"
                        set="Email"
                        value={fields.toUser}
                        isMulti={true}
                        isRender={true}
                        onSelect={(data) => setValue("toUser", data)}
                      />
                      <Form.Text className="error">{errors.toUser}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label>Task Status</label>
                      <FieldLookup
                        isRender={true}
                        name="taskStatus"
                        type="TASK_STATUS"
                        lookup={true}
                        value={fields.taskStatus}
                        onSelect={(data) => setValue("taskStatus", data)}
                      />
                      <Form.Text className="error">{errors.taskStatus}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Task Priority</label>
                      <FieldLookup
                        isRender={true}
                        name="priority"
                        type="TASK_PRIORITY"
                        lookup={true}
                        value={fields.priority}
                        onSelect={(data) => setValue("priority", data)}
                      />
                      <Form.Text className="error">{errors.priority}</Form.Text>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label>Start Date</label>
                      <DatePicker
                        isClearable={true}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        selected={fields.startDate ? moment(fields.startDate).toDate() : null}
                        onChange={(date) => setValue("startDate", date ?? "")}
                      />
                      <Form.Text className="error">{errors.startDate}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>End Date</label>
                      <DatePicker
                        isClearable={true}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        selected={fields.endDate ? moment(fields.endDate).toDate() : null}
                        onChange={(date) => setValue("endDate", date ?? "")}
                      />
                      <Form.Text className="error">{errors.endDate}</Form.Text>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <FormControlLabel
                        name="scheduled"
                        className="m-0"
                        value="Y#N"
                        control={<Checkbox color="primary" />}
                        label={"Scheduled"}
                        labelPlacement="end"
                        checked={fields.scheduled === "Y" ? true : false}
                        onClick={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label>Scheduled Value</label>
                      <input
                        name="scheduledNo"
                        type="number"
                        className="form-control"
                        value={fields.scheduledNo}
                        onChange={handleChange}
                      />
                      <Form.Text className="error">{errors.scheduledNo}</Form.Text>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label>Scheduled Type</label>
                      <select
                        name="scheduledType"
                        className="form-control"
                        value={fields.scheduledType}
                        onChange={handleChange}
                      >
                        <option value="">None</option>
                        <option value="MIA">Minute(s)</option>
                        <option value="HA">Hour(s)</option>
                        <option value="DA">Day(s)</option>
                      </select>
                      <Form.Text className="error">{errors.scheduledType}</Form.Text>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="popup-footer justify-content-start">
              <div className="botBtn">
                <div>
                  <Button
                    className="saveBtn"
                    onClick={onSubmit}
                    type="submit"
                  >Save</Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => props.onCancel()}
                    type="button"
                  >Cancel</Button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default TaskModal;