import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

export function FormFieldSelect(props) {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isRender, setIsRender] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  const finderList = {
    "Lookup": {
      url: `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=Code,Name&finder=FindLookupVals;pLookupCode=${props.type}`,
      query: ``,
      limitOffset: `&limit=100&offset=0&totalResults=true`,
      filter: ["Name", "Code"]
    }
  }
  // init
  useEffect(() => {
    if (isRender) {
      let find = props.lookup ? "Lookup" : props.type;
      let finder = finderList[find];
      let url = `${finder.url}${finder.limitOffset}`;
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            setResultList(common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1]))
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      )
    }
  }, [isRender]);

  // handler
  const onSelect = (data) => {
    props.onSelect(data, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <Select
        isClearable={props.isClearable || true}
        options={resultList}
        value={props.value}
        onChange={(data) => onSelect(data)}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
      />
    )
  )
}

export function FormFieldAuto(props) {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  var delayTimer;
  // init
  const finderList = {
    "FindCampaigns": {
      url: `${envConfig.BASE_API}${Services.CRM_CAMPAIGNS}?fields=CampaignId,CampaignName&q=TenantId=${userInfo.pTenantId}`,
      query: ` AND UPPER(CampaignName) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["CampaignName", "CampaignId"]
    },
    "FindCustomers": {
      url: `${envConfig.BASE_API}${Services.CRM_CUSTOMER}?fields=CustomerId,CustomerName&finder=FindCustomers;pOrgId=${userInfo.pOrgId},pTenantId=${userInfo.pTenantId}`,
      query: `&q=UPPER(CustomerName) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["CustomerName", "CustomerId"]
    },
    "FindEmployee": {
      url: `${envConfig.BASE_API}${Services.CRM_PAY_EMPLOYEE}?fields=EmployeeId,EmployeeName&q=TenantId=${userInfo.pTenantId}`,
      query: ` AND UPPER(EmployeeName) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["EmployeeName", "EmployeeId"]
    },
    "FindSites": {
      url: `${envConfig.BASE_API}${Services.CRM_CUSTOMER_SITE}?fields=SiteId,SiteName&q=TenantId=${userInfo.pTenantId}`,
      query: ` AND UPPER(SiteName) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["SiteName", "SiteId"]
    },
    "FindItems": {
      url: `${envConfig.BASE_API}${Services.ITEM_MATSER}?fields=ItemId,ItemName&q=TenantId=${userInfo.pTenantId} AND OrgId=${userInfo.pOrgId} AND UPPER(ItemName) LIKE '*{SEARCH}*'`,
      query: ``,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["ItemName", "ItemId"]
    },
    "FindTransactionStatus": {
      url: `${envConfig.BASE_API}${Services.TRANSACTION_STATUS}?q=TransactionTypeId=${props.eType ? common.getTransactionTypeId(envConfig[`${props.eType}_TRANS_TYPE_ID`]) : ""};OrgId=${userInfo.pOrgId};TenantId=${userInfo.pTenantId};Active=Y&limit=100&offset=0&orderBy=OrderNo:asc`,
      query: ``,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["StatusName", "StatusCode"]
    },
    "FindQuote": {
      url: `${envConfig.BASE_API}${Services.CRM_QUOTES}?fields=CustQuotId,QuotationNo&q=TenantId=${userInfo.pTenantId};OrgId=${userInfo.pOrgId}`,
      query: ``,
      limitOffset: `&limit=10000&offset=0&totalResults=true`,
      filter: ["QuotationNo", "CustQuotId"]
    },
    "FindSalesOrder": {
      url: `${envConfig.BASE_API}${Services.CRM_SALES_ORDER}?fields=CustOrderId,WoNo&q=TenantId=${userInfo.pTenantId};OrgId=${userInfo.pOrgId}`,
      query: ``,
      limitOffset: `&limit=10000&offset=0&totalResults=true`,
      filter: ["WoNo", "CustOrderId"]
    },
    "FindTerritory": {
      url: `${envConfig.BASE_API}${Services.CRM_TERRITORY_TEAM}?fields=TerritoryId,TerritoryName&q=TenantId=${userInfo.pTenantId};ResourceId=${props.SalespersonId}`,
      query: ` AND UPPER(TerritoryName) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: ["TerritoryName", "TerritoryId"]
    }
  }

  useEffect(() => {
    if (isRender) {
      let finder = finderList[props.type];
      let url = `${finder.url.replace("{SEARCH}", "")}${finder.limitOffset}`;
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            let data = common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1])
            setResultList(data);
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      )
    }
  }, [isRender]);

  // load
  const loadOptions = async (inputValue) => {
    let finder = finderList[props.type];
    var url = `${finder.url.replace("{SEARCH}", inputValue)}${finder.query.replace("{SEARCH}", inputValue)}`;
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1])
              resolve(data);
            } else {
              resolve([])
            }
          },
          (error) => {
            resolve([]);
          }
        )
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = value => {
    props.onSelect(value, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }
  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <AsyncSelect
        isClearable={props.isClearable || true}
        value={props.value}
        defaultOptions={resultList}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
      />
    )
  );
}

// Automation
export function FormFieldAmSelect(props) {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isRender, setIsRender] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  const finderList = {
    "lookup": {
      url: `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=${props.api.get},${props.api.set}&finder=FindLookupVals;pLookupCode=${props.api.name}`,
      query: ``,
      limitOffset: `&limit=1000&offset=0&totalResults=true`,
      filter: [props.api.get, props.api.set]
    },
    "api": {
      url: `${envConfig.BASE_API}${Services[props.api.name]}?fields=${props.api.get},${props.api.set}&q=TenantId=${userInfo.pTenantId};OrgId=${userInfo.pOrgId}`,
      query: ``,
      limitOffset: `&limit=1000&offset=0&totalResults=true`,
      filter: [props.api.get, props.api.set]
    }
  }
  // init
  useEffect(() => {
    if (isRender) {
      let finder = finderList[props.api.type];
      let url = `${finder.url}${finder.limitOffset}`;
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            setResultList(common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1]))
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      )
    }
  }, [isRender]);

  // handler
  const onSelect = (data) => {
    props.onSelect(data, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <Select
        isClearable={props.isClearable || true}
        options={resultList}
        value={props.value}
        onChange={(data) => onSelect(data)}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
      />
    )
  )
}

export function FormFieldAmAuto(props) {
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [userInfo] = useState(crm.userInfo());
  // const transactionTypeId = common.getTransactionTypeId(props.module);
  // console.log(props.module);
  var delayTimer;
  // init
  const finderList = {
    "lookup": {
      url: `${envConfig.BASE_API}${Services.LOOKUPS_LOV}?fields=${props.api.get},${props.api.set}&finder=FindLookupVals;pLookupCode=${props.api.name}`,
      query: ``,
      limitOffset: `&limit=1000&offset=0&totalResults=true`,
      filter: [props.api.get, props.api.set]
    },
    "api": {
      url: `${envConfig.BASE_API}${Services[props.api.name]}?fields=${props.api.get},${props.api.set}&q=${props.api.name == "TRANSACTION_STATUS" ? `TransactionTypeId=${common.getTransactionTypeId(props.module)} AND Active='Y' AND ` : ""}${props.api.name == "CRM_EMPLOYEES" ? "VtenantId" : "TenantId"}=${userInfo.pTenantId}`, // AND OrgId=${userInfo.pOrgId}
      query: ` AND UPPER(${props.api.get}) LIKE '*{SEARCH}*'`,
      limitOffset: `&limit=10&offset=0&totalResults=true`,
      filter: [props.api.get, props.api.set]
    },
  }

  useEffect(() => {
    if (isRender) {
      let finder = finderList[props.api.type];
      let url = `${(finder.url + finder.query + finder.limitOffset).replace("{SEARCH}", "")}`;
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            let data = common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1], true)
            setResultList(data);
            if (!props.isRender) {
              setIsMenuOpen(true);
              setIsFocus(true);
            }
          }
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        }
      )
    }
  }, [isRender]);

  // load
  const loadOptions = async (inputValue) => {
    let finder = finderList[props.api.type];
    var url = `${finder.url.replace("{SEARCH}", inputValue)}${finder.query.replace("{SEARCH}", inputValue)}`;
    let result = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              let data = common.getOptionsOnlyData(response.items, finder.filter[0], finder.filter[1], true)
              resolve(data);
            } else {
              resolve([])
            }
          },
          (error) => {
            resolve([]);
          }
        )
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = value => {
    props.onSelect(value, props.name);
  }

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  }

  return (
    isLoading ? (
      <Form.Control
        type="text"
        defaultValue={"Loading..."}
        readOnly={true}
      />
    ) : (
      <AsyncSelect
        isClearable={true}
        value={props.value}
        defaultOptions={resultList}
        loadOptions={loadOptions}
        onChange={onChange}
        onFocus={onFocus}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        autoFocus={isFocus}
      />
    )
  );
}