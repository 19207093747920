import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices, tools } from "_helpers";

// Defined Errors
const defErrs = {
  CurrentPassword: {
    isReq: "Current Password is required!",
    minLength: 5,
    isMinLengthReq: "Password must be at least 5 characters",
    matchPassword: true,
    matchPasswordReq: "Current Password is not correct",
  },
  Password: {
    isReq: "New Password is required!",
    minLength: 5,
    isMinLengthReq: "Password must be at least 5 characters",
    notMatchReq: "Current password & New password are same.Please enter new password"
  },
  ConfirmPassword: {
    isReq: "Confirm Password is required!",
    minLength: 5,
    isMinLengthReq: "Password must be at least 5 characters",
    matchPassword: true,
    matchPasswordReq: "New Password & Confirm Password need to be match",
  },
};
const userInfo = common.authInfo();
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      UserId: userInfo.UserId,
      fields: {
        CurrentPassword: "",
        Password: "",
        ConfirmPassword: "",
        length: "",
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.defineInitErrorMsg();

    const { UserId } = this.state;
    this.setState(
      {
        UserId: UserId,
      },
      () => {
        if (UserId > 0) {
          this.getDetailById(UserId);
        }
      }
    );
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  getDetailById = (UserId) => {
    const { fields, errors } = this.state;
   let getPassword = { Password: fields.Password };    
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + "/" + UserId);
    restServices.getRequest(
      url,
      getPassword, 
      (response) => {
        if (response) {    
          this.setState({
            fields: fields,
            errors: errors,
          });
        } else {
        }
      },
      (error) => {}
    );
  };

  /**
   * Redirect to territory list page
   */
  onCancel = () => {
    history.push("/profile");
  };

  validateForm = (name, value) => {
    const { errors, fields } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "CurrentPassword":
          if (fields[name].length < 5) {
            errors[name] = defErrs.CurrentPassword.isMinLengthReq;
          } 
          else if (userData.password !== fields.CurrentPassword) {
            errors[name] = defErrs[name].matchPasswordReq;     
          }
         else {
            errors[name] = "";
          }
          break;
        case "Password":
          if (fields[name].length < 5) {
            errors[name] = defErrs.Password.isMinLengthReq;
          } else if(fields.CurrentPassword === fields.Password){
            errors[name] = defErrs.Password.notMatchReq;
          }
            else {
            errors[name] = "";
          }
          break;
        case "ConfirmPassword":
          if (fields[name].length < 5) {
            errors[name] = defErrs.ConfirmPassword.isMinLengthReq;
          } else if (fields["Password"] !== fields["ConfirmPassword"]) {
              errors[name] = defErrs[name].matchPasswordReq;     
          } else {
            errors[name] = "";
          }
          break;
        default:
          errors[name] = "";
      }
    }

    this.setState({
      errors: errors,
    });
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    var inputValue = value;

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  update = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      this.passwordUpdate();
    } else {
    }
  };

  passwordUpdate = () => {
    const { fields, UserId } = this.state;
    let formAttr = { Password: fields.Password };
    delete fields.CurrentPassword;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + "/" + UserId);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        if (response) {
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          userData.password = fields.Password;
          localStorage.setItem("userData", tools.crypt(userData));
          history.push("/profile");
          common.snack("S", "Password Successfully Updated.");
        }
      },
      (error) => {}
    );
  }; 

  render() {
    const { fields, errors, formSubmit } = this.state;
    return (
      <div className="lead-wraper">
        <Form onSubmit={this.update}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Change Password</h2>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Current Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="CurrentPassword"
                    value={fields.CurrentPassword}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.CurrentPassword !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.CurrentPassword}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>New Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="Password"
                    value={fields.Password}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.Password !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.Password}</Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Confirm Password*</Form.Label>
                  <Form.Control
                    type="password"
                    name="ConfirmPassword"
                    value={fields.ConfirmPassword}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.ConfirmPassword !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.ConfirmPassword}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                Change Password
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default ChangePassword;
