import React from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// component
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

// API Services
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// img
import deleteIcon from "img/delete.svg";
import loaderIcon from "img/loader.gif";
const userInfo = common.authInfo();
export default class KanbanBoard extends React.Component {

  loggedInEmpId = userInfo.EmpId;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: [],
      isDelete: [],
      kanbanType: "",
      kanbanList: [],
      kanbanData: [],
      pagination: [],
      paging: [],
      pagelimit: 5,
      // leads and deals
      listStatus: [],
      totalRecords: {
        all: [],
        personal: [],
        my: [],
      },
      recordsLoaded: {
        all: [],
        personal: [],
        my: [],
      },
      pageLoaded: {
        all: [],
        personal: [],
        my: [],
      },
      sData: {
        taskPriority: []
      },
      userId: userInfo.EmpId,
      userName: userInfo.UserName
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    if (this.props.kanbanType == "task") {
      this.getLookUpData("TASK_PRIORITY", "taskPriority");
    } else {
      this.initData();
    }
  }

  initData() {
    const { pagelimit, userId, userName } = this.state;
    let kanbanType = this.props.kanbanType;
    let status = this.props.status;

    let userPermissions = this.props.userPermissions;
    let kanbanList = [];
    let kanbanData = [];
    let pagination = [];
    let limit = [];
    // leads & deals
    const { listStatus, totalRecords, recordsLoaded, pageLoaded } = this.state;
    // paging param
    let paging = common.getParam("paging");
    if (paging) {
      paging = paging.split(",");
    }
    // dynamic status
    status.map((item, key) => {
      // data
      kanbanList.push(item);
      kanbanData.push([]);
      // pagination
      if (paging[key] && Number(paging[key]) > 0) {
        pagination.push([false, Number(paging[key])]);
        limit.push((Number(paging[key]) + 1) * pagelimit);
      } else {
        pagination.push([false, 0]);
        limit.push(pagelimit);
      }
      // modify data
      if (kanbanType == "task") {
        item["StatusCode"] = item.Code;
        item["StatusName"] = item.Name;
      }
      // leads & deals
      listStatus.push("process");
      totalRecords.all.push(0);
      totalRecords.personal.push(0);
      totalRecords.my.push(0);

      recordsLoaded.all.push(0);
      recordsLoaded.personal.push(0);
      recordsLoaded.my.push(0);

      pageLoaded.all.push(0);
      pageLoaded.personal.push(0);
      pageLoaded.my.push(0);
    });

    this.setState(
      {
        kanbanType,
        kanbanList,
        kanbanData,
        pagination,
        paging,
        userPermissions,
      },
      () => {
        kanbanList.map((item, key) => {
          let url = "";
          let filter = "";
          if (kanbanType == "campaign") {
            filter = `?q=CampaignStatus=${item.StatusCode}&orderBy=CreatedOn:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + filter;
            this.getList(key, url);
          } else if (kanbanType == "enquiry") {
            filter = `?q=ListStatus=${item.StatusCode}&orderBy=CreatedOn:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.CRM_LISTS + filter;
            this.getList(key, url);
          } else if (kanbanType == "task") {
            filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=,pStatus=${item.StatusCode}&orderBy=Status:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.TASKS_VIEW + filter;
            this.getList(key, url);
          } else if (kanbanType == "lead") {
            this.getCombinedList(key, item.StatusCode, "load");
          } else if (kanbanType == "deal") {
            this.getCombinedList(key, item.StatusCode, "load");
          } else if (kanbanType == "mylead") {
            filter = `?q=SalespersonId=${this.loggedInEmpId};LeadStatus=${item.StatusCode}&orderBy=CreatedOn:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.CRM_LEADS + filter;
            this.getList(key, url);
          } else if (kanbanType == "mydeal") {
            filter = `?q=SalesPersonId=${this.loggedInEmpId};ApprStatus=${item.StatusCode}&orderBy=CreationDate:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + filter;
            this.getList(key, url);
          } else if (kanbanType == "quote") {
            filter = `?q=ApprStatus=${item.StatusCode}&orderBy=CreatedOn:desc&limit=${limit[key]}&offset=0`;
            url = envConfig.BASE_API + Services.CRM_QUOTES + filter;
            this.getList(key, url);
          }
        });
      }
    );
  }

  // API Call
  getLookUpData = (lookupValue, name) => {
    const { sData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData[name] = response.items;
          this.setState({
            sData
          }, () => {
            this.initData();
          });
        }
      },
      (error) => { }
    )
  };

  getList = (key, url) => {
    const { kanbanType, kanbanData, pagination, userPermissions } = this.state;
    let loader = key + "load";
    this.setLoading(loader, true);

    restServices.getRequest(
      url,
      (response) => {
        // data
        if (response.items) {
          response.items.map((item) => {
            let data = {};
            if (kanbanType == "campaign") {
              data = {
                id: item.CampaignId,
                name: item.CampaignName,
                date: item.ActualStartDate,
                description: item.Description,
                code: item.CampaignCode,
                delete: userPermissions.delete,
              };
            } else if (kanbanType == "enquiry") {
              data = {
                id: item.ListId,
                name: item.ListName,
                date: null,
                description: item.Description,
                code: item.ListType,
                delete: userPermissions.delete,
              };
            } else if (kanbanType == "lead" || kanbanType == "mylead") {
              data = {
                id: item.LeadId,
                name: item.LeadName,
                date: null,
                description: "",
                code: item.LeadSource,
              };
            } else if (kanbanType == "deal" || kanbanType == "mydeal") {
              data = {
                id: item.OpportunityId,
                name: item.OpportunityName,
                date: null,
                description: item.Description,
                code: item.OpportunityType,
              };
            } else if (kanbanType == "task") {
              data = {
                id: item.TaskId,
                name: item.TaskName,
                date: null,
                description: item.Description,
                code: this.getPriority(item.Priority),
                delete: userPermissions.delete,
              };
            } else if (kanbanType == "quote") {
              data = {
                id: item.CustQuotId,
                name: item.Remarks,
                date: item.ExpOrderDate,
                description: item.PaymentTerms,
                code: item.ApprStatus,
                delete: userPermissions.delete,
              };
            }
            kanbanData[key].push(data);
          });
        }

        // pagination
        if (response.hasMore) {
          pagination[key][0] = true;
          pagination[key][1] += 1;
        }

        this.setState({
          kanbanData,
          pagination,
        });

        this.setLoading(loader, false);
      },
      (error) => {
        this.setLoading(loader, false);
      }
    );
  };

  loadMore = (key, statusCode) => {
    const {
      kanbanType,
      kanbanData,
      pagination,
      pagelimit,
      userPermissions,
      userName,
      userId
    } = this.state;

    if (pagination[key][0]) {
      let url = "";
      let filter = "";
      let loader = key + "more";

      if (kanbanType == "campaign") {
        filter = `?q=CampaignStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit
          }`;
        url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + filter;
      } else if (kanbanType == "lead") {
        filter = `?q=LeadStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit
          }`;
        url = envConfig.BASE_API + Services.CRM_LEADS + filter;
      } else if (kanbanType == "enquiry") {
        filter = `?q=ListStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit
          }`;
        url = envConfig.BASE_API + Services.CRM_LISTS + filter;
      } else if (kanbanType == "deal") {
        filter = `?q=ApprStatus=${statusCode}&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit
          }`;
        url = envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + filter;
      } else if (kanbanType == "task") {
        filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=,pStatus=${statusCode}&orderBy=Status:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit}`;
        url = envConfig.BASE_API + Services.TASKS_VIEW + filter;
      } else if (kanbanType == "mylead") {
        filter = `?q=SalespersonId=${this.loggedInEmpId};LeadStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit}`;
        url = envConfig.BASE_API + Services.CRM_LEADS + filter;
      } else if (kanbanType == "mydeal") {
        filter = `?q=SalesPersonId=${this.loggedInEmpId};ApprStatus=${statusCode}&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit}`;
        url = envConfig.BASE_API + Services.CRM_LEADS + filter;
      } else if (kanbanType == "quote") {
        filter = `?q=ApprStatus=${statusCode}&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pagination[key][1] * pagelimit
          }`;
        url = envConfig.BASE_API + Services.CRM_QUOTES + filter;
      }

      this.setLoading(loader, true);
      restServices.getRequest(
        url,
        (response) => {
          // data
          if (response.items) {
            response.items.map((item) => {
              let data = {};
              if (kanbanType == "campaign") {
                data = {
                  id: item.CampaignId,
                  name: item.CampaignName,
                  date: item.ActualStartDate,
                  description: item.Description,
                  code: item.CampaignCode,
                  delete: userPermissions.delete,
                };
              } else if (kanbanType == "enquiry") {
                data = {
                  id: item.ListId,
                  name: item.ListName,
                  date: null,
                  description: item.Description,
                  code: item.ListType,
                  delete: userPermissions.delete,
                };
              } else if (kanbanType == "lead" || kanbanType == "mylead") {
                data = {
                  id: item.LeadId,
                  name: item.LeadName,
                  date: null,
                  description: "",
                  code: item.LeadSource,
                  createdBy: item.CreatedBy,
                };
              } else if (kanbanType == "deal" || kanbanType == "mydeal") {
                data = {
                  id: item.OpportunityId,
                  name: item.OpportunityName,
                  date: null,
                  description: item.Description,
                  code: item.OpportunityType,
                  createdBy: item.CreatedBy,
                };
              } else if (kanbanType == "task") {
                data = {
                  id: item.TaskId,
                  name: item.TaskName,
                  date: null,
                  description: item.Description,
                  code: item.Status,
                  delete: userPermissions.delete,
                };
              } else if (kanbanType == "quote") {
                data = {
                  id: item.CustQuotId,
                  name: item.Remarks,
                  date: item.ExpOrderDate,
                  description: item.PaymentTerms,
                  code: item.ApprStatus,
                  delete: userPermissions.delete,
                };
              }
              kanbanData[key].push(data);
            });
          }

          // pagination
          if (response.hasMore) {
            pagination[key][0] = true;
            pagination[key][1] += 1;
          } else {
            pagination[key][0] = false;
          }

          this.setState({
            kanbanData,
            pagination,
          });
          this.setLoading(loader, false);
        },
        (error) => {
          this.setLoading(loader, false);
        }
      );
    }
  };

  // Leads
  getCombinedList = async (key, statusCode, type) => {
    const {
      listStatus,
      totalRecords,
      recordsLoaded,
      pageLoaded,
      userPermissions,
      kanbanType,
      kanbanData,
      pagination,
      paging,
    } = this.state;
    const { deptEmployees } = this.props;

    let status = listStatus[key];
    this.setLoading(key + type, true);
    // pagelimit
    let pagelimit = this.state.pagelimit;
    if (type == "load") {
      if (paging[key] && Number(paging[key]) > 0) {
        pagelimit = (pagination[key][1] + 1) * pagelimit;
      }
    }

    if (userPermissions.read == 1) {
      // all leads
      if (listStatus[key] == "process") {
        let allUrl = "";
        let allFilter = "";
        if (kanbanType == "lead") {
          allFilter = `?q=LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pageLoaded.all[key] * pagelimit
            }`;

          allUrl = encodeURI(
            envConfig.BASE_API + Services.CRM_LEADS + allFilter
          );
        } else if (kanbanType == "deal") {
          allFilter = `?q=ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${pageLoaded.all[key] * pagelimit
            }`;

          allUrl = encodeURI(
            envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + allFilter
          );
        }

        let result = await new Promise((resolve, reject) => {
          restServices.getRequest(allUrl, (response) => {
            resolve(response);
          });
        });

        if (result.items) {
          result.items.map((item) => {
            var data = {};
            if (kanbanType == "lead") {
              data = {
                id: item.LeadId,
                name: item.LeadName,
                date: item.CreatedOn,
                description: item.SalesPersonName,
                code: item.LeadSource,
                accessId: item.SalespersonId,
                createdBy: item.CreatedBy,
                delete: common.validPermission(
                  item.SalespersonId,
                  "delete",
                  userPermissions,
                  deptEmployees,
                  item.CreatedBy
                ),
              };
            } else if (kanbanType == "deal") {
              data = {
                id: item.OpportunityId,
                name: item.OpportunityName,
                date: item.CreationDate,
                description: item.SalesPersonName,
                code: item.OpportunityType,
                accessId: item.SalesPersonId,
                createdBy: item.CreatedBy,
                delete: common.validPermission(
                  item.SalesPersonId,
                  "delete",
                  userPermissions,
                  deptEmployees,
                  item.CreatedBy
                ),
              };
            }
            kanbanData[key].push(data);
          });
        }

        // check status
        totalRecords.all[key] = result.totalResults;
        recordsLoaded.all[key] += result.count;
        pageLoaded.all[key] += 1;
        if (totalRecords.all[key] == recordsLoaded.all[key]) {
          status = "completed";
        } else {
          pagination[key][1] += 1;
        }
      }
    } else if (userPermissions.read == 3) {
      // personal leads
      if (listStatus[key] == "process") {
        let personalFilter = "";
        let personalUrl = "";

        if (kanbanType == "lead") {
          personalFilter = `?q=SalespersonId=${this.loggedInEmpId};LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pageLoaded.personal[key] * pagelimit
            }`;
          personalUrl = encodeURI(
            envConfig.BASE_API + Services.CRM_LEADS + personalFilter
          );
        } else if (kanbanType == "deal") {
          personalFilter = `?q=SalesPersonId=${this.loggedInEmpId};ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${pageLoaded.personal[key] * pagelimit
            }`;
          personalUrl = encodeURI(
            envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + personalFilter
          );
        }

        let result = await new Promise((resolve, reject) => {
          restServices.getRequest(personalUrl, (response) => {
            resolve(response);
          });
        });

        if (result.items) {
          result.items.map((item) => {
            var data = {};
            if (kanbanType == "lead") {
              data = {
                id: item.LeadId,
                name: item.LeadName,
                date: item.CreatedOn,
                description: item.SalesPersonName,
                code: item.LeadSource,
                accessId: item.SalespersonId,
                createdBy: item.CreatedBy,
                delete: common.validPermission(
                  item.SalespersonId,
                  "delete",
                  userPermissions,
                  deptEmployees,
                  item.CreatedBy
                ),
              };
            } else if (kanbanType == "deal") {
              data = {
                id: item.OpportunityId,
                name: item.OpportunityName,
                date: item.CreationDate,
                description: item.SalesPersonName,
                code: item.OpportunityType,
                accessId: item.SalesPersonId,
                createdBy: item.CreatedBy,
                delete: common.validPermission(
                  item.SalesPersonId,
                  "delete",
                  userPermissions,
                  deptEmployees,
                  item.CreatedBy
                ),
              };
            }
            kanbanData[key].push(data);
          });
        }
        totalRecords.personal[key] = result.totalResults;
        recordsLoaded.personal[key] += result.count;
        pageLoaded.personal[key] += 1;
        if (totalRecords.personal[key] == recordsLoaded.personal[key]) {
          status = "completed";
        } else {
          pagination[key][1] += 1;
        }
      }
    } else if (userPermissions.read == 4 || userPermissions.read == 5) {
      // personal + department leads
      if (listStatus[key] == "process") {
        let personaLeads = false;
        let my = false;
        if (
          recordsLoaded.personal[key] == 0 ||
          totalRecords.personal[key] != recordsLoaded.personal[key]
        ) {
          personaLeads = true;
        }
        if (personaLeads) {
          let personalFilter = "";
          let personalUrl = "";
          if (kanbanType == "lead") {
            personalFilter = `?q=SalespersonId=${this.loggedInEmpId};LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${pageLoaded.personal[key] * pagelimit
              }`;
            personalUrl = encodeURI(
              envConfig.BASE_API + Services.CRM_LEADS + personalFilter
            );
          } else if (kanbanType == "deal") {
            personalFilter = `?q=SalesPersonId=${this.loggedInEmpId};ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${pageLoaded.personal[key] * pagelimit
              }`;
            personalUrl = encodeURI(
              envConfig.BASE_API +
              Services.CRM_DEAL_OPPORTUNITY +
              personalFilter
            );
          }

          let result = await new Promise((resolve, reject) => {
            restServices.getRequest(personalUrl, (response) => {
              resolve(response);
            });
          });

          if (result.items) {
            result.items.map((item) => {
              var data = {};
              if (kanbanType == "lead") {
                data = {
                  id: item.LeadId,
                  name: item.LeadName,
                  date: item.CreatedOn,
                  description: item.SalesPersonName,
                  code: item.LeadSource,
                  accessId: item.SalespersonId,
                  createdBy: item.CreatedBy,
                  delete: common.validPermission(
                    item.SalespersonId,
                    "delete",
                    userPermissions,
                    deptEmployees,
                    item.CreatedBy
                  ),
                };
              } else if (kanbanType == "deal") {
                data = {
                  id: item.OpportunityId,
                  name: item.OpportunityName,
                  date: item.CreationDate,
                  description: item.SalesPersonName,
                  code: item.OpportunityType,
                  accessId: item.SalesPersonId,
                  createdBy: item.CreatedBy,
                  delete: common.validPermission(
                    item.SalesPersonId,
                    "delete",
                    userPermissions,
                    deptEmployees,
                    item.CreatedBy
                  ),
                };
              }
              kanbanData[key].push(data);
            });
          }
          totalRecords.personal[key] = result.totalResults;
          recordsLoaded.personal[key] += result.count;
          pageLoaded.personal[key] += 1;
        }

        let personalCarry =
          pageLoaded.personal[key] * pagelimit - totalRecords.personal[key];

        // my
        if (
          totalRecords.my[key] != recordsLoaded.my[key] ||
          recordsLoaded.my[key] == 0
        ) {
          if (personalCarry > 0) {
            my = true;
          } else if (recordsLoaded.my[key] == 0 && !personaLeads) {
            my = true;
          }
        }
        // load next item
        if (my) {
          // check previous total
          let myOffset = pageLoaded.my[key] * pagelimit;
          if (personalCarry > 0 && recordsLoaded.my[key] == 0) {
            pagelimit = personalCarry;
          }
          if (personalCarry > 0 && recordsLoaded.my[key] > 0) {
            myOffset = (pageLoaded.my[key] - 1) * pagelimit;
            myOffset += personalCarry;
          }

          let myFilter = "";
          let myUrl = "";
          if (kanbanType == "lead") {
            myFilter = `?finder=FindMyLeads;p_resource_id=${this.loggedInEmpId}&q=SalespersonId!=${this.loggedInEmpId};LeadStatus=${statusCode}&totalResults=true&orderBy=CreatedOn:desc&limit=${pagelimit}&offset=${myOffset}`;

            myUrl = encodeURI(
              envConfig.BASE_API + Services.CRM_MYLEADS + myFilter
            );
          } else if (kanbanType == "deal") {
            myFilter = `?finder=FindMyOppts;pResourceId=${this.loggedInEmpId}&q=SalesPersonId!=${this.loggedInEmpId};ApprStatus=${statusCode}&totalResults=true&orderBy=CreationDate:desc&limit=${pagelimit}&offset=${myOffset}`;

            myUrl = encodeURI(
              envConfig.BASE_API + Services.CRM_MYDEALS + myFilter
            );
          }
          let result = await new Promise((resolve, reject) => {
            restServices.getRequest(myUrl, (response) => {
              resolve(response);
            });
          });

          if (result.items) {
            result.items.map((item) => {
              var data = {};
              if (kanbanType == "lead") {
                data = {
                  id: item.LeadId,
                  name: item.LeadName,
                  date: item.CreatedOn,
                  description: item.EmployeeName,
                  code: item.LeadSource,
                  accessId: item.SalespersonId,
                  createdBy: item.CreatedBy,
                  delete: common.validPermission(
                    item.SalespersonId,
                    "delete",
                    userPermissions,
                    deptEmployees,
                    item.CreatedBy
                  ),
                };
              } else if (kanbanType == "deal") {
                data = {
                  id: item.OpportunityId,
                  name: item.OpportunityName,
                  date: item.CreationDate,
                  description: item.EmployeeName,
                  code: item.OpportunityType,
                  accessId: item.SalesPersonId,
                  createdBy: item.CreatedBy,
                  delete: common.validPermission(
                    item.SalesPersonId,
                    "delete",
                    userPermissions,
                    deptEmployees,
                    item.CreatedBy
                  ),
                };
              }
              kanbanData[key].push(data);
            });
          }

          totalRecords.my[key] = result.totalResults;
          recordsLoaded.my[key] += result.count;
          pageLoaded.my[key] += 1;
        }

        // check status
        let total = totalRecords.personal[key] + totalRecords.my[key];
        let loaded = recordsLoaded.personal[key] + recordsLoaded.my[key];
        if (total == loaded) {
          status = "completed";
        } else {
          pagination[key][1] += 1;
        }
      }
    }

    listStatus[key] = status;
    this.setLoading(key + type, false);

    this.setState({
      listStatus,
      //data
      kanbanData,
      totalRecords,
      recordsLoaded,
      pageLoaded,
      pagination,
    });
  };

  // drag & drop
  onDragEnd(result) {
    const { userPermissions } = this.state;
    const { deptEmployees } = this.props;
    if (!result.destination) {
      return;
    }
    if (result.source.droppableId == result.destination.droppableId) {
      return;
    }
    // state
    const { kanbanType } = this.state;
    let kanbanData = this.state.kanbanData;
    // drag
    let id = result.draggableId;
    let source = result.source.droppableId.split("_");
    let destination = result.destination.droppableId.split("_");
    let sourceData = kanbanData[source[0]][result.source.index];
    // check access
    if (kanbanType == "lead" || kanbanType == "deal") {
      //TODO - Give all access to super admin      
      if (
        !common.validPermission(
          sourceData.accessId,
          "update",
          userPermissions,
          deptEmployees,
          sourceData.createdBy
        )) {
        common.snack("E", "Sorry! You dont have access to update status.");
        return;
      }
    } else if (kanbanType == "campaign" || kanbanType == "enquiry" || kanbanType == "task") {
      if (!userPermissions.update) {
        common.snack("E", "Sorry! You dont have access to update status.");
        return;
      }
    }
    //TODO: now work/mydeal & work/mylead allowed to delete
    // set data
    kanbanData[source[0]].splice(result.source.index, 1);
    kanbanData[destination[0]].splice(result.destination.index, 0, sourceData);

    // update
    this.setState(
      {
        kanbanData,
      },
      () => {
        // status update
        let url = "";
        var formAttr = {};
        if (kanbanType == "campaign") {
          url = encodeURI(
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id
          );
          formAttr = { CampaignStatus: destination[1] };
        } else if (kanbanType == "enquiry") {
          url = encodeURI(envConfig.BASE_API + Services.CRM_LISTS + "/" + id);
          formAttr = { ListStatus: destination[1] };
        } else if (kanbanType == "lead" || kanbanType == "mylead") {
          url = encodeURI(envConfig.BASE_API + Services.CRM_LEADS + "/" + id);
          formAttr = { LeadStatus: destination[1] };
        } else if (kanbanType == "deal" || kanbanType == "mydeal") {
          url = encodeURI(
            envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + "/" + id
          );
          formAttr = { ApprStatus: destination[1] };
        } else if (kanbanType == "task") {
          url = encodeURI(envConfig.BASE_API + Services.TASKS + "/" + id);
          formAttr = { Status: destination[1] };
        } else if (kanbanType == "quote") {
          url = encodeURI(envConfig.BASE_API + Services.CRM_QUOTES + "/" + id);
          formAttr = { ApprStatus: destination[1] };
        }
        restServices.patchRequest(
          url,
          formAttr,
          (response) => {
            if (response != null) {
              common.snack("S", "Successfully updated!");
            }
          },
          (error) => { }
        );
      }
    );
  }

  deleteData(e, key, id) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to delete this item?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                this.deleteRecord(key, id);
                onClose();
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  deleteRecord(key, id) {
    const { kanbanType } = this.state;
    let kanbanData = this.state.kanbanData;
    this.setDelete(id, true);
    var url = "";

    if (kanbanType == "campaign") {
      url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id;
    } else if (kanbanType == "enquiry") {
      url = envConfig.BASE_API + Services.CRM_LISTS + "/" + id;
    } else if (kanbanType == "lead" || kanbanType == "mylead") {
      url = envConfig.BASE_API + Services.CRM_LEADS + "/" + id;
    } else if (kanbanType == "deal" || kanbanType == "mydeal") {
      url = envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + "/" + id;
    } else if (kanbanType == "task") {
      url = envConfig.BASE_API + Services.TASKS + "/" + id;
    } else if (kanbanType == "quote") {
      url = envConfig.BASE_API + Services.CRM_QUOTES + "/" + id;
    }

    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Deleted Successfully");
        kanbanData[key] = kanbanData[key].filter((item) => item.id != id);
        this.setState({
          kanbanData,
        });
        this.setLoading(id, false);
      },
      (error) => {
        this.setLoading(id, false);
      }
    );
  }

  // others
  setLoading(key, status) {
    let isLoading = this.state.isLoading;
    if (status) {
      isLoading.push(key);
    } else {
      isLoading = common.removeItem(isLoading, key);
    }
    this.setState({ isLoading });
  }

  setDelete(key, status) {
    let isDelete = this.state.isDelete;
    if (status) {
      isDelete.push(key);
    } else {
      isDelete = common.removeItem(isDelete, key);
    }
    this.setState({ isDelete });
  }

  kanbanScroll(e, key, StatusCode) {
    const { kanbanType } = this.state;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (kanbanType == "lead" || kanbanType == "deal") {
        this.getCombinedList(key, StatusCode, "more");
      } else {
        this.loadMore(key, StatusCode);
      }
    }
  }

  currentPaging() {
    const { pagination } = this.state;
    let paging = [];
    pagination.map((item, key) => {
      if (item[0]) {
        paging.push(Number(item[1]) - 1);
      } else {
        paging.push(item[1]);
      }
    });
    return paging.join(",");
  }

  kanbanAction(e, type, id, status) {
    e.preventDefault();
    const { kanbanType } = this.state;
    let url = "";
    let addLink = "";
    let detailLink = "";

    // check type
    if (kanbanType == "campaign") {
      addLink = "/crm/campaign/add";
      detailLink = "/crm/campaign/" + id;
    } else if (kanbanType == "lead") {
      addLink = "/crm/lead/add";
      detailLink = "/crm/lead/" + id;
    } else if (kanbanType == "enquiry") {
      addLink = "/crm/enquiry/add";
      detailLink = "/crm/enquiry/" + id;
    } else if (kanbanType == "deal") {
      addLink = "/crm/deal/add";
      detailLink = "/crm/deal/" + id;
    } else if (kanbanType == "mylead") {
      addLink = "/work/mylead/add";
      detailLink = "/work/mylead/" + id;
    } else if (kanbanType == "mydeal") {
      addLink = "/work/mydeal/add";
      detailLink = "/work/mydeal/" + id;
    }

    // add or edit
    if (type === "add") {
      url =
        addLink +
        "?status=" +
        status +
        "&view=kanban&paging=" +
        this.currentPaging();
    } else {
      url = detailLink + "?view=kanban&paging=" + this.currentPaging();
    }
    history.push(url);
  }

  // support
  getPriority(code) {
    const { sData } = this.state;
    let priority = "";
    sData.taskPriority.map(item => {
      if (item.Code == code) {
        priority = item.Name;
      }
    })
    return priority;
  }

  render() {
    const { kanbanType, kanbanList, kanbanData, isLoading, isDelete, userPermissions } = this.state;
    return (
      <div>
        <div className="kanban-view-wrap">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="d-flex kanban-row">
              {kanbanList.map((item, key) => (
                <div className="kanban-col" key={"div_" + key}>
                  <Droppable
                    droppableId={`${key + "_" + item.StatusCode}`}
                    key={key}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div
                          className={
                            `kanban-box cat-` + item.StatusCode.toLowerCase()
                          }
                          style={{ background: item.StatusColor }}
                        >
                          <div className="kanban-title-box">
                            <h3
                              className="kanban-title"
                              style={{ color: item.StatusColor }}
                            >
                              <span style={{ background: item.StatusColor }}>
                                &nbsp;
                              </span>
                              {item.StatusName.toUpperCase()}
                            </h3>
                          </div>
                          <div
                            className={`kanban-content-box kanban-scroll-box ${isLoading.includes(key + "more")
                              ? "kanban-none"
                              : ""
                              }`}
                            onScroll={(e) =>
                              this.kanbanScroll(e, key, item.StatusCode)
                            }
                          >
                            {!isLoading.includes(key + "load") && (
                              kanbanType == "task" && (kanbanType != "mylead" && kanbanType != "mydeal") && userPermissions.add ? (
                                <Link to="#"
                                  onClick={(e) => { e.preventDefault(); history.push("/task/add") }}
                                  className="add-new-card"
                                >
                                  + Add New
                                </Link>
                              ) : ((kanbanType != "mylead" && kanbanType != "mydeal" && userPermissions.add) &&
                                <Link to="#"
                                  onClick={
                                    (e) =>
                                      this.kanbanAction(
                                        e,
                                        "add",
                                        null,
                                        item.StatusCode
                                      )
                                  }
                                  className="add-new-card"
                                >
                                  + Add New
                                </Link>
                              )
                            )}
                            {!isLoading.includes(key + "load") &&
                              kanbanData[key].length == 0 && <NoRecordFound />}
                            {isLoading.includes(key + "load") && <Loader />}
                            <ul className="kanban-list">
                              {kanbanData[key].map((dItem, dKey) => {
                                return (
                                  <Draggable
                                    key={dItem.id}
                                    draggableId={dItem.id.toString()}
                                    index={dKey}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <li key={"li_" + dItem.id}>
                                          <div className="card-data-wrap">
                                            <h3>
                                              <Link to="#"
                                                onClick={(e) =>
                                                  this.kanbanAction(
                                                    e,
                                                    "edit",
                                                    dItem.id
                                                  )
                                                }
                                              >
                                                {dItem.name ? dItem.name : "-"}
                                              </Link>
                                            </h3>
                                            <p className="card-time">
                                              {dItem.date == null
                                                ? "-"
                                                : common.formatDate(
                                                  dItem.date,
                                                  "DD MMMM"
                                                )}
                                            </p>
                                            <p className="card-price">
                                              {dItem.description
                                                ? dItem.description
                                                : "-"}
                                            </p>
                                          </div>
                                          <div className="card-actions justify-content-between">
                                            <p>
                                              {dItem.code ? dItem.code : "-"}
                                            </p>
                                            <ul className="card-activity-opt-list">
                                              <li>
                                                {dItem.delete && (
                                                  <Link to="#">
                                                    {!isDelete.includes(
                                                      dItem.id
                                                    ) ? (
                                                      <img
                                                        src={deleteIcon}
                                                        alt="Delete"
                                                        onClick={(e) =>
                                                          this.deleteData(
                                                            e,
                                                            key,
                                                            dItem.id
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <img
                                                        src={loaderIcon}
                                                        alt="Process"
                                                        className="kanban-delete"
                                                      />
                                                    )}
                                                  </Link>
                                                )}
                                              </li>
                                              {/* <li>
                                                <Link to="#">
                                                  <img
                                                    src={CallIcon}
                                                    alt="Call"
                                                  />
                                                </Link>
                                              </li>
                                              <li>
                                                <Link to="#">
                                                  <img
                                                    src={ChatIcon}
                                                    alt="Chat"
                                                  />
                                                </Link>
                                              </li>
                                              <li>
                                                <Link to="#"
                                                  onClick={(e) =>
                                                    this.kanbanAction(
                                                      e,
                                                      "add",
                                                      ""
                                                    )
                                                  }
                                                  className="add-link"
                                                  style={{
                                                    background:
                                                      item.StatusColor,
                                                  }}
                                                >
                                                  +
                                                </Link>
                                              </li> */}
                                            </ul>
                                          </div>
                                        </li>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </ul>
                          </div>
                          {isLoading.includes(key + "more") && (
                            <p className="kanban-loader">
                              Loading please wait...
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
            </div>
          </DragDropContext>
        </div>
      </div >
    );
  }
}
