import React from "react";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
//Import CSS
import "./Configure.css";

// Import Images
import Roles from "img/roles.svg";
import SettingImg from "img/hrms/leave-setting.svg";
import LeaveOpeningImg from "img/hrms/leave-opening.svg";
import Template from "img/hrms/template-configure.png";
import Automation from "img/hrms/automation.png";
import ManageReport from "img/hrms/managereport.png";
import MappingImg from "img/hrms/element-calculation.svg";
import { common } from "_helpers";

class Configure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {}
    };
  }

  componentDidMount = () => {
    let userData = common.authInfo();
    this.setState({
      userData: userData
    })
  }

  render() {
    const { userData } = this.state;
    return (
      <div className="lead-wraper">
        <div className="secondary-nav">
          <ul className="secondary-nav-list">
            <li>
              <Link to="/setup/hrms">HRMS</Link>
            </li>
            <li className="active">
              <Link to="/setup/configure">Configure</Link>
            </li>
            <li>
              <Link to="/setup/vendor">Vendor</Link>
            </li>
          </ul>
        </div>
        {/* Secondary Nav end */}
        <h2 className="crm-type-title mb-20">Configure</h2>
        <div className="crm-data-wrap">
          <ul className="row configure-list hrms-list">
            {/* Transaction Status */}
            <li className="col-3">
              <Link to="/setup/configure/form-transaction">
                <div className="card-layout text-center">
                  <img
                    src={LeaveOpeningImg}
                    alt="Lists"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Form Transactions</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/configure/form/settings">
                <div className="card-layout text-center">
                  <img
                    src={SettingImg}
                    alt="Leave Settings"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Form Settings</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/configure/conversion/mappings">
                <div className="card-layout text-center">
                  <img
                    src={MappingImg}
                    alt="Leave Settings"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Conversion Mapping</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/configure/role">
                <div className="card-layout text-center">
                  <img
                    src={Roles}
                    alt="Roles"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Roles</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/configure/template">
                <div className="card-layout text-center">
                  <img
                    src={Template}
                    alt="Template"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Templates</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>

            <li className="col-3">
              <Link to="/setup/configure/automation/workflow">
                <div className="card-layout text-center">
                  <img
                    src={Automation}
                    alt="Automation"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Automation</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            {userData.role === "adminuser" &&
              <li className="col-3">
                <Link to="/setup/configure/manage-report">
                  <div className="card-layout text-center">
                    <img
                      src={ManageReport}
                      alt="ManageReport"
                      className="img-fluid"
                    />
                    <h5 className="setting-name">Manage Reports</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </Link>
              </li>
            }

            <li className="col-3">
              <Link to="/setup/configure/sub-email">
                <div className="card-layout text-center">
                  <img
                    src={SettingImg}
                    alt="ManageSubEmail"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Sub Email</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Link>
            </li>
            {/* <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={Campaigns}
                    alt="Campaigns"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Campaigns</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="/transactionstatus/:campaign" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li>
              <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={Lists}
                    alt="Lists"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Enquiries</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="/transactionstatus/:list" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li>
              <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={Leads}
                    alt="Leads"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Leads</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="/transactionstatus/:lead" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li>
              <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={Deals}
                    alt="Deals"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Deals</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="/transactionstatus/:deal" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li> */}
            {/* <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={Territories}
                    alt="Territories"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Territories</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="#" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li>
              <li className="col-3">
                <div className="card-layout text-center">
                  <img
                    src={UserManagement}
                    alt="User Management"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">User Management</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                  <Link to="#" className="btn btn-configure">
                    Configure
                  </Link>
                </div>
              </li> */}

          </ul>
        </div>
      </div>
    );
  }
}

const styles = {
  menuSelectbox: {
    borderBottom: "none"
  }
};

const mapStateToProps = state => {
  const { alert } = state;
  return { alert };
};
export default connect(mapStateToProps)(Configure);

  // export { LeadList };
