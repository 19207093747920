import React from "react";
import { Route } from "react-router-dom";
//Import Layouts
import LoginLayout from "_layouts/loginLayout/LoginLayout";
import DashboardLayout from "_layouts/dashboardLayout/DashboardLayout";
import LandingLayout from "_layouts/landingLayout/LandingLayout";
import FormLayout from "_layouts/formLayout/FormLayout";


//Admin Layout
export const DashboardLayoutRoute = ({
  auth,
  component: Component,
  ...rest
}) => {
  return (
    !checkAuth(auth) && (
      // checkPermission() && (
      <Route
        {...rest}
        render={(matchProps) => (
          <DashboardLayout><Component {...matchProps} /></DashboardLayout>
        )}
      />
    )
  );
};

//Login Layout
export const LoginLayoutRoute = ({ auth, component: Component, ...rest }) => {
  return (
    !checkAuth(auth) && (
      <Route
        {...rest}
        render={(matchProps) => (
          <LoginLayout>
            <Component {...matchProps} />
          </LoginLayout>
        )}
      />
    )
  );
};

// Landing Layout
export const LandingLayoutRoute = ({ auth, component: Component, ...rest }) => {
  return (
    !checkAuth(auth) && (
      <Route
        {...rest}
        render={(matchProps) => (
          <LandingLayout>
            <Component {...matchProps} />
          </LandingLayout>
        )}
      />
    )
  )
};

export const FormLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <FormLayout>
          <Component {...matchProps} />
        </FormLayout>
      )}
    />
  )
};

function checkAuth(auth) {
  let token = localStorage.getItem("userToken") ? localStorage.getItem("userToken") : null;
  let redirect = false;

  if (auth === true && token === null) {
    setTimeout(() => {
      localStorage.clear();
      window.location.href = "/login";
    }, 1000);
    redirect = true;
  } else if (!auth && token != null) {
    setTimeout(() => {
      window.location.href = "/work";
    }, 1000);
    redirect = false;
  }

  return redirect;
}
