import React from "react";
import Badge from "@material-ui/core/Badge";
import { Tabs, Tab } from "react-bootstrap";

// Import internal components
import DynamicForm from "crm/DynamicForm";
import EnquiryMemberList from "./Tabs/EnquiryMemberList";
import Comments from "../../../_components/Comments/Comments";
import CallLogsList from "../../../_components/CallLogs/CallLogsList/CallLogsList";
import Meeting from "../../../_components/Meeting/Meeting";
import Attachments from "../../../_components/Attachments/Attachments";
import History from "../../../_components/History/pages/History";
import AccessDenied from "_components/_accessdenied/AccessDenied";

// Import config and helpers
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common, history } from "_helpers";
import Loader from "_components/_loader/Loader";
const userInfo = common.authInfo();
class AddEnquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      crmEnquiryList: [],
      open: false,
      enquiryData: {},
      enquiryId: 0,
      isLoading: true,
      userPermissions: common.getPermission(this.props, "crm:enquiry"),
    };
    this.state.currentTab = common.getParam("tab");
    let status = common.getParam("status");
    this.state.enquiryData["ListStatus"] = status ? status : null;
  }

  componentDidMount() {
    const { params } = this.state;
    this.getCRMEnquiryStatusList();
    if (params.enquiryId) {
      this.setState(
        {
          enquiryId: params.enquiryId,
        },
        () => {
          this.getEnquiryDetail(params.enquiryId);
        }
      );
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  /**
   * Get Enquiry Details by ID
   */
  getEnquiryDetail = (id) => {
    let url = envConfig.BASE_API + Services.CRM_LISTS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            enquiryData: response,
            isLoading: false,
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Get CRM list from API
   */
  getCRMEnquiryStatusList = () => {
    let listTypeId = common.getTransactionTypeId(envConfig.LIST_TRANS_TYPE_ID);
    let params =
      `?q=TransactionTypeId=${listTypeId};OrgId=` +
      `${userInfo.DefaultOrgId};TenantId=` +
      `${userInfo.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  getStatusPosition = () => {
    const { enquiryData, crmEnquiryList } = this.state;
    if (enquiryData.ListStatus != "" && enquiryData.ListStatus != null) {
      var position = crmEnquiryList.findIndex(
        (x) => x.StatusCode == enquiryData.ListStatus
      );
      return position > 0 ? position : 0;
    }
    return 0;
  };

  saveStatus = (status) => {
    const { enquiryData } = this.state;
    enquiryData.ListStatus = status;
    this.setState({
      enquiryData,
    });
  };

  /**
   * Tab onclick event
   */
  handleSelect(key) {
    let url = window.location.pathname;
    let queryParam = window.location.search;
    if (queryParam.includes("tab=")) {
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab=" + tab, "tab=" + key);
    }
    history.push(url + queryParam);
  }

  saveCallback = (res) => {
    history.push(
      "/crm/enquiry/edit/" + res.ListId + common.getCrmReturnQuery()
    );
    this.setState({
      enquiryId: res.ListId,
      enquiryData: res,
    });
  };

  cancelCallBack = () => {
    history.push("/crm/enquiry");
  };

  render() {
    const {
      crmEnquiryList,
      currentTab,
      enquiryId,
      isLoading,
      enquiryData,
      userPermissions,
    } = this.state;

    return (
      <div>
        {!userPermissions.read ||
        (enquiryId == 0 && !userPermissions.add) ||
        (enquiryId > 0 && !userPermissions.update) ? (
          <AccessDenied />
        ) : (
          !isLoading && (
            <div className="lead-wraper">
              <div className="secondary-head">
                <h1>
                  {enquiryData.ListName ? enquiryData.ListName : "Add Enquiry"}
                </h1>
              </div>

              <div className="formTab">
                <ul>
                  {crmEnquiryList.map((data, idx) => (
                    <li
                      key={idx}
                      onClick={() => this.saveStatus(data.StatusCode)}
                      className={`tabShape whiteTab ${
                        this.getStatusPosition() >= idx ? "active" : ""
                      }`}
                    >
                      {" "}
                      <span className="tri-right"></span>{" "}
                      <Badge badgeContent={++idx} color="primary">
                        {data.StatusName}
                      </Badge>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="formTabSection position-r">
                <Tabs
                  defaultActiveKey={currentTab ? currentTab : "general"}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="general" title="General">
                    <DynamicForm
                      module="enquiry"
                      data={{
                        value1: enquiryId > 0 ? enquiryId : "",
                      }}
                      onSave={this.saveCallback}
                      onCancel={this.cancelCallBack}
                    />
                  </Tab>

                  <Tab
                    eventKey="details"
                    title="Members"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <EnquiryMemberList
                        enquiryId={enquiryId}
                        enquiryData={enquiryData}
                      />
                    )}
                  </Tab>

                  <Tab
                    eventKey="comments"
                    title="Notes"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <Comments objectId={enquiryId} objectType="CRM_LISTS" />
                    )}
                  </Tab>

                  <Tab
                    eventKey="call"
                    title="Call Logs"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <CallLogsList objectId={enquiryId} objectType="enquiry" />
                    )}
                  </Tab>

                  <Tab
                    eventKey="meetings"
                    title="Meetings"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <Meeting objectId={enquiryId} objectType="enquiry" />
                    )}
                  </Tab>

                  <Tab
                    eventKey="attachments"
                    title="Attachments"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <Attachments objectId={enquiryId} objectType="enquiry" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="History"
                    disabled={enquiryId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && enquiryId > 0 && (
                      <History campaign={enquiryData} />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          )
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default AddEnquiry;
