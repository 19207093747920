import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Checkbox from "material-ui/Checkbox";

//Import CSS
import "../css/Location.css";

//API SERVICES
import { common } from "_helpers";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

class LocationDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { selectedLoc, userPermissions } = this.props;
    const isValidProp = (key) =>
      selectedLoc[key] ? selectedLoc[key] : "-";
    const isChecked = (key) => selectedLoc[key] && selectedLoc[key] == "Y" ? true : false;

    return (
      <div>
        {selectedLoc.LocationId == undefined && (
          <div className="formTabSection position-r">
            <Paper style={styles.boxLayout}>
              <NoRecordFound />
            </Paper>
          </div>
        )}
        {selectedLoc.LocationId > 0 && (<div>
          <Paper style={styles.boxLayout}>
            <div className="box-title-wrap justify-content-between">
              <h3 className="card-title">LOCATION INFORMATION</h3>
              {userPermissions.update && (
                <Link className="card-link" to={"/setup/vendor/location/edit" + selectedLoc.LocationId + "?filterName=" +
                  this.props.filterName +
                  "&page=" +
                  this.props.page}>
                  Change
                </Link>
              )}
            </div>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Location Code</label>
                  <p className="form-value">{isValidProp("LocationCode")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Location Name</label>
                  <p className="form-value">{isValidProp("LocationName")}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Start Date</label>
                  <p className="form-value">
                    {selectedLoc.DateOfJoin
                      ? common.formatDate(isValidProp("StartDate"), "DD/MM/YYYY")
                      : "-"}
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">End Date</label>
                  <p className="form-value">
                    {selectedLoc.DateOfJoin
                      ? common.formatDate(isValidProp("EndDate"), "DD/MM/YYYY")
                      : "-"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Description</label>
                  <p className="form-value">{isValidProp("Description")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  {/* <label className="form-label">Active</label> */}
                  <Form.Group>
                    <Checkbox
                      disabled
                      checked={isChecked("Active")}
                      label="Active"
                      labelStyle={{ color: "#A7AFC2" }}
                      labelPosition="left"
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>

          </Paper>

          <Paper style={styles.boxLayout}>
            <div className="box-title-wrap justify-content-between">
              <h3 className="card-title">Address Details</h3>
              {userPermissions.update && (
                <Link className="card-link" to={"/setup/vendor/location/edit" + selectedLoc.LocationId + "?filterName=" +
                  this.props.filterName +
                  "&page=" +
                  this.props.page}>
                  Change
                </Link>
              )}
            </div>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Address 1</label>
                  <p className="form-value">{isValidProp("AddressLine1")}</p>
                </div>
              </Col>
              <Col sm={6}>

                <div className="list-row">
                  <label className="form-label">Address 2</label>
                  <p className="form-value">{isValidProp("AddressLine2")}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Address 3</label>
                  <p className="form-value">{isValidProp("AddressLine3")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Country Code</label>
                  <p className="form-value">{isValidProp("CountryCode")}</p>
                </div>
              </Col>
            </Row>




            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">State</label>
                  <p className="form-value">{isValidProp("State")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">City</label>
                  <p className="form-value">{isValidProp("City")}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Region Code</label>
                  <p className="form-value">{isValidProp("RegionCode")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Postal Code</label>
                  <p className="form-value">{isValidProp("PostalCode")}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Telephone No</label>
                  <p className="form-value">{isValidProp("TelNumber1")}</p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="list-row">
                  <label className="form-label">Alternative Telephone No</label>
                  <p className="form-value">{isValidProp("TelNumber2")}</p>
                </div>
              </Col>
            </Row>
          </Paper>
        </div>)}

      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default LocationDetail;
