import React, { Component } from 'react';
import './LandingLayout.css';

/**
 * Page Layout For Login Page
 */
class LandingLayout extends Component {
    render() {
        return (
            <div className="landing-page">
                {this.props.children}
            </div>
        )
    }
}
export default LandingLayout;