import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";

// Import config and helpers
import { common, tableConfig, restServices } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";

// Import internal components
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});
class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig, filterData, from } = this.props;
    let inputData = [];
    let pathURL;

    switch (from) {
      case "lead":
      case "mylead":
        pathURL = "/" + Services.CRM_LEADS + "/";
        break;
      case "deal":
      case "mydeal":
        pathURL = "/" + Services.CRM_DEAL_OPPORTUNITY + "/";
        break;
      case "campaign":
        pathURL = "/" + Services.CRM_CAMPAIGNS + "/";
        break;
      case "enquiry":
        pathURL = "/" + Services.CRM_LISTS + "/";
        break;
      case "invoice":
        pathURL = "/" + Services.CRM_INVOICE + "/";
        break;
      case "quote":
        pathURL = "/" + Services.CRM_QUOTES + "/";
        break;
      case "order":
        pathURL = "/" + Services.CRM_SALES_ORDER + "/";
        break;
      case "task":
        pathURL = "/" + Services.TASKS + "/";
        break;
      case "rule":
        pathURL = "/" + Services.CHOLA_AUTOMATION + "/";
        break;
      case "product":
        pathURL = "/" + Services.ITEM_MATSER + "/";
        break;
      case "partysite":
        pathURL = "/" + Services.CRM_PARTY_SITES + "/";
        break;
      case "classification":
        pathURL = "/" + Services.CRM_PARTY_CLASSIFICATION + "/";
        break;
      case "template":
        pathURL = "/" + Services.CHOLA_EMAIL_TEMPLATE + "/";
        break;
    }

    tlConfig.selected.map((id, index) => {
      inputData.push({
        id: "part" + (index + 1),
        path: pathURL + id,
        operation: "delete",
        payload: {},
      });
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          filterData();
          this.props.updateTLConfig("delItemCount", 0, null);
          this.props.updateTLConfig("selected", [], null);
          this.props.updateTLConfig("checkedAll", false, null);
          common.snack("S", "Selected items has been deleted successfully.");
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  render() {
    const {
      deleteSelected,
      tlConfig,
      updateTLConfig,
      isLoading,
      data,
      handleClick,
      renderStatus,
      currentPage,
      search,
      from,
    } = this.props;

    const isHided = (column) => tlConfig.columnsSelected.indexOf(column) !== -1;
    const isSelected = (column) => tlConfig.selected.indexOf(column) !== -1;

    let detailPage = from;
    switch (from) {
      case "campaign":
        detailPage = "crm/campaign/edit";
        break;
      case "enquiry":
        detailPage = "crm/enquiry/edit";
        break;
      case "lead":
        detailPage = "crm/lead/edit";
        break;
      case "deal":
        detailPage = "crm/deal/edit";
        break;
      case "quote":
        detailPage = "crm/quote/edit";
        break;
      case "order":
        detailPage = "crm/sales-order/edit";
        break;
      case "invoice":
        detailPage = "crm/invoice/edit";
        break;
      case "contact":
        detailPage = "crm/contact/edit";
        break;
      case "task":
        detailPage = "task/edit";
        break;
      case "rule":
        detailPage = "setup/configure/automation/workflow/edit";
        break;
      case "partysite":
        detailPage = "setup/vendor/party-site";
        break;
      case "classification":
        detailPage = "setup/vendor/classification";
        break;
      case "product":
        detailPage = "setup/vendor/product";
        break;
      case "mylead":
        detailPage = "work/mylead";
        break;
      case "mydeal":
        detailPage = "work/mydeal";
        break;
      case "template":
        detailPage = "setup/configure/template/edit";
        break;
      default:
        detailPage = from;
        break;
    }
    return (
      <div className="list-view-wrap">
        <EnhancedTableToolbar
          tlConfig={tlConfig}
          deleteSelected={this.deleteSelected}
        />

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              tlConfig={tlConfig}
              updateTLConfig={updateTLConfig}
              from={from == "reports" ? "reports" : ""}
            />
            <TableBody>
              {data.items &&
                data.items.length > 0 &&
                tableConfig
                  .stableSort(
                    data.items,
                    tableConfig.getComparator(tlConfig.order, tlConfig.orderBy)
                  )
                  .map((row, index) => {
                    const isItemSelected = isSelected(row[tlConfig.columnId]);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row[tlConfig.columnId])
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        key={"tr_" + index}
                      >
                        {tlConfig.enableMultipleDelete &&
                          tlConfig.delItemCount > 0 && from !== "reports" && (
                            <TableCell padding="checkbox">
                              {(row.delete != undefined
                                ? tlConfig.enableMultipleDelete && row.delete
                                : tlConfig.enableMultipleDelete) && (
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                )}
                            </TableCell>
                          )}
                        {tlConfig.headCells.map((column, idx) => {
                          return (
                            <TableCell
                              key={idx}
                              className={!isHided(column.id) ? "hideCell" : ""}
                            >
                              {column.type == "date" ? (
                                row[column.id] ? (
                                  common.formatDate(row[column.id], "DD MMMM")
                                ) : (
                                  "-"
                                )
                              ) : column.type == "function" ? (
                                <div className="update-status">
                                  {renderStatus(row[column.id])}
                                  <p>{row[column.id]}</p>
                                </div>
                              ) : column.type == "link" ? (
                                from == "reports" ? <Link
                                  to="#"
                                  onClick={(e) => this.props.modalOpen(row[tlConfig.columnId], row[tlConfig.columnName], row[tlConfig.columnCode])}
                                >{row[column.id]}</Link> :
                                  <Link
                                    to={
                                      "/" +
                                      detailPage +
                                      "/" +
                                      row[tlConfig.columnId] +
                                      "?view=list&search=" +
                                      search +
                                      "&paging=" +
                                      (currentPage == 0
                                        ? currentPage
                                        : currentPage - 1)
                                    }
                                  >
                                    {row[column.id]}
                                  </Link>
                              ) : row[column.id] ? (
                                row[column.id]
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

              {isLoading == false && data.items && data.items.length == 0 && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <NoRecordFound />{" "}
                  </TableCell>
                </TableRow>
              )}

              {isLoading == true && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Loader />{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default ListView;
