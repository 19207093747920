import React, { createRef } from "react";
import { Link } from "react-router-dom";
// Import redux packages
import { connect } from "react-redux";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "../_components/ListView/ListView";
import KanbanBoard from "../_components/KanbanView/KanbanBoard";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import FileExport from "../_components/FileExport/FileExport";
// css
import "./css/tasks.css";
// icons
import SearchIcon from "img/search-icn.png";
const child = createRef();
const userInfo = common.authInfo();
class TaskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 10,
      tlConfig: {
        columnId: "TaskId",
        headCells: [
          // {
          //   id: "TaskNumber",
          //   value: "TaskNumber",
          //   label: "TASK NO",
          //   type: "",
          // },
          {
            id: "TaskName",
            value: "TaskName",
            label: "NAME",
            type: "link",
          },
          {
            id: "Description",
            value: "Description",
            label: "DESCRIPTION",
            type: "",
          },
          {
            id: "PriorityName",
            value: "PriorityName",
            label: "PRIORITY",
            type: "",
          },
          {
            id: "EmployeeName",
            value: "EmployeeName",
            label: "RESPONSIBLE PERSON",
            type: "",
          },
          {
            id: "StatusName",
            value: "StatusName",
            label: "STATUS",
            type: "",
          },
          {
            id: "StartDate",
            value: "StartDate",
            label: "START DATE",
            type: "date",
          },
          {
            id: "EndDate",
            value: "EndDate",
            label: "END DATE",
            type: "date",
          },
          {
            id: "CreatedBy",
            value: "CreatedBy",
            label: "CREATED BY",
            type: "",
          },
          {
            id: "CreatedOn",
            value: "CreatedOn",
            label: "CREATED On",
            type: "date",
          },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "StartDate",
        selected: [],
        uniqueKey: "TaskListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0
      },
      filterTaskName: "",
      crmStatusList: [],
      userPermissions: common.getPermission(this.props, "task"),
      headers: [
        { label: 'ID', key: 'TaskId' },
        { label: 'Task Name', key: 'TaskName' },
        { label: 'Description', key: 'Description' },
        { label: 'Priority', key: 'Priority' },
        { label: 'Responsible Person', key: 'EmployeeName' },
        { label: 'Status', key: 'Status' },
        { label: 'Start Date', key: 'StartDate' },
        { label: 'End Date', key: 'EndDate' },
        { label: 'Created By', key: 'CreatedBy' }
      ],
      urls: "",
      totalResults: "",
      keyArray: ["TaskId", "TaskName", "Description", "Priority", "EmployeeName", "Status", "StartDate_date", "EndDate_date", "CreatedBy"],
      userId: userInfo.EmpId,
      userName: userInfo.UserName
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getLookUpData("TASK_STATUS");
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.TaskId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Handle tab controll to switch between list and kanban view
   */
  changeTabView = (e) => {
    e.preventDefault();
    this.setState(
      {
        isListView: !this.state.isListView,
      },
      (_) => {
        if (this.state.isListView) {
          window.addEventListener("scroll", this.onScroll);
        } else {
          window.removeEventListener("scroll", this.onScroll, false);
        }
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterTaskName,
      userPermissions,
    } = this.state;

    if (currentPage > 0) {
      this.setState({ isLoading: true });
      let userId = userInfo.EmpId;
      let userName = userInfo.UserName;

      let filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=${filterTaskName}&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit}&totalResults=true`;

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }

      let url = encodeURI(envConfig.BASE_API + Services.TASKS_VIEW + filter);

      restServices.getRequest(
        url,
        (response) => {
          let deletePermission = userPermissions.delete;
          tlConfig.enableMultipleDelete =
            deletePermission &&
              response &&
              response.items &&
              response.items.length > 0
              ? true
              : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete
            ? response.items.length
            : 0;

          let temp = {};
          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.map((item) => {
              list.push(item);
            });
            temp.items = list;
          } else {
            temp = response;
          }

          this.setState({
            listRes: temp,
            isLoading: false,
            tlConfig: tlConfig,
            currentPage: response.hasMore ? currentPage + 1 : 0,
            urls: envConfig.BASE_API + Services.TASKS_VIEW,
            totalResults: response.totalResults
          }, () => {
            window.addEventListener("scroll", this.onScroll);
            if (child.current) {
              child.current.getCsvData();
            }
          });
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
          });
        }
      );
    }
  };

  getLookUpData = (lookupValue) => {
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let crmStatusList = response.items;
          this.setState({
            crmStatusList
          });
        }
      },
      (error) => { }
    )
  };


  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmDealStatus } = props;

    var crmStatusData = state.crmStatusList;

    if (crmDealStatus && crmDealStatus.list && crmDealStatus.list.success) {
      crmStatusData = crmDealStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      isLoading,
      filterTaskName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      crmStatusList,
      userPermissions,
      headers,
      urls,
      totalResults,
      keyArray,
      userId,
      userName
    } = this.state;

    const isHided = (TaskId) =>
      tlConfig.columnsSelected.indexOf(TaskId) !== -1;
    const isSelected = (TaskId) =>
      tlConfig.selected.indexOf(TaskId) !== -1;

    const handleClick = (event, TaskId) => {
      const selectedIndex = tlConfig.selected.indexOf(TaskId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, TaskId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=${filterTaskName}&totalResults=true&orderBy=CreatedOn:desc&limit=${totalResults}&offset=0&totalResults=true`

    return (
      <div className="deal-wraper">
        {userPermissions && !userPermissions.access ? (
          <AccessDenied />
        ) : (
            <div>
              {/* end of Secondary Nav */}
              <div className="filter-wrap">
                <div className="row justify-content-between">
                  <div className="col-12 col-lg-4">
                    <div className="form-row">
                      <div className="col">
                        <h2 className="crm-type-title">Tasks</h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8">
                    {this.state.isListView && (
                      <div className="form-row justify-content-end">
                        <div className="col-lg-5">
                          <label className="sr-only" htmlFor="inlineFormInputGroup">
                            Filter and Search
                      </label>
                          <div className="input-group search-filter-group mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder="+ Filter Task Name"
                              name="filterTaskName"
                              value={filterTaskName}
                              onChange={this.handleFieldChange}
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <img
                                  src={SearchIcon}
                                  alt="Search"
                                  onClick={this.filterData}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <EnhancedTableColumns
                            tlConfig={tlConfig}
                            updateTLConfig={this.updateTLConfig}
                          />
                        </div>
                        {userPermissions.export && (
                          <div className="col-auto">
                            <FileExport
                              ref={child}
                              headers={headers}
                              filename={"Task_details.xls"}
                              urls={urls}
                              keyArray={keyArray}
                              filter={filter}
                            />
                          </div>
                        )}
                        {userPermissions.add && (
                          <div className="col-auto">
                            <Link to="/task/add">
                              <button type="button" className="btn btn-add">
                                Add Task
                          </button>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
              {/* end of Filter Wrap */}
              <div className="crm-data-wrap">
                <div className="tab-section float-right">
                  <ul className="tab-list">
                    <li className={this.state.isListView ? "active" : ""}>
                      <Link to="#" onClick={(e) => this.changeTabView(e)}>
                        List
                    </Link>
                    </li>
                    <li className={!this.state.isListView ? "active" : ""}>
                      <Link to="#" onClick={(e) => this.changeTabView(e)}>
                        Kanban
                    </Link>
                    </li>
                  </ul>
                </div>

                <div className="clearfix"></div>

                {this.state.isListView ? (
                  <ListView
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                    isLoading={isLoading}
                    data={listRes}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    isHided={isHided}
                    renderStatus={this.renderStatus}
                    handlePageChange={this.handlePageChange}
                    currentPage={currentPage}
                    pageLimit={pageLimit}
                    search={filterTaskName}
                    from="task"
                    filterData={this.filterData}
                    deptEmployees={this.props.deptEmployees}
                  />
                ) : (
                    crmStatusList != "" && (
                      <KanbanBoard
                        kanbanType="task"
                        status={crmStatusList}
                        userPermissions={userPermissions}
                        deptEmployees={this.props.deptEmployees}
                      />
                    )
                  )}

              </div>
              {/* end of Switch View Control */}
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, crmCampaignStatus } = state;
  return { alert, crmCampaignStatus };
};

export default connect(mapStateToProps)(TaskList);