import React from "react";
import SortableTree from "react-sortable-tree";
import "react-sortable-tree/style.css";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// includes
import { crm } from "_utils";
// Import CSS
import "../css/Division.css";

// Import sortable renderer
import NodeRendererCustom from "../_components/node-renderer-custom";
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
class Division extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listRes: {},
      isLoading: true,
      treeData: [],
      userPermissions: common.getPermission(this.props, "hrms:department"),
    };
  }
  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  /**
   * Get all list
   */
  getList = async (_) => {
    const data = await this.getParentList();
    this.setState({
      treeData: data,
      isLoading: false,
    });
  };

  /**
   * Get Parent list
   */
  getParentList = () => {
    return new Promise((resolve, reject) => {
      let filter = `?q=TenantId=${crm.userInfo().pTenantId};OrgId=${crm.userInfo().pOrgId
        };&totalResults=true&orderBy=ParentId:desc&limit=1000&offset=0`;

      let url = encodeURI(
        envConfig.BASE_API + Services.CRM_TERRITORIES + filter
      );
      restServices.getRequest(url, async (response) => {
        let items = [];
        if (response && response.items && response.items.length) {
          //items = response.items;
          response.items.map((item) => {
            if (item.ParentId == null) {
              let data = {
                title: item.TerritoryName,
                type: "Department",
                date: item.CreatedOn,
                TerritoryId: item.TerritoryId,
                TerritoryType: item.TerritoryType,
                ParentId: item.ParentId
              };
              items.push(data);
            }
          });
        }
        Promise.all(
          items.map((item) => this.getChildList(item, item.TerritoryId)),
          items.map((item) => this.getEmpTerritoryFromSalesTeam(item))
        ).then((result) => resolve(items));
      });
    });
  };

  /**
   * Get Child List
   */
  getChildList(item, id) {
    return new Promise((resolve, reject) => {
      let filter = `?q=ParentId=${id}&limit=100&offset=0`;
      let children = item.children ? item.children : [];

      let url = encodeURI(
        envConfig.BASE_API + Services.CRM_TERRITORIES + filter
      );
      restServices.getRequest(url, async (response) => {
        if (response && response.items && response.items.length) {
          //          treeData.map((data) => {
          if (item.TerritoryId == id) {
            response.items.map((obj) => {
              let childData = {
                title: obj.TerritoryName,
                type: "Child",
                date: obj.CreatedOn,
                TerritoryId: obj.TerritoryId,
              };
              children.push(childData);
            });
            item["children"] = children;
          }
          //});
        }
        Promise.all(
          children.map((obj) => this.getChildList(obj, obj.TerritoryId)),
          children.map((item) => this.getEmpTerritoryFromSalesTeam(item))
        ).then((result) => resolve(children));
      });
    });
  }

  getEmpTerritoryFromSalesTeam(item) {
    return new Promise((resolve, reject) => {
      let url = encodeURI(
        envConfig.BASE_API +
        Services.CRM_TERRITORY_TEAM +
        "?q=TerritoryId=" +
        item.TerritoryId
      );
      restServices.getRequest(url, async (response) => {
        item.empCount = response.count;
        item.employees = response.items;
        resolve(item);
      });
    });
  }

  onChangeNode = (modifiedTree) => {
    this.setState({
      treeData: modifiedTree,
    });
  };

  canDropNode = (nodeData) => {
    return nodeData.nextPath.length == nodeData.prevPath.length;
  };

  render() {
    const { isLoading, treeData, userPermissions } = this.state;
    return (
      <div className="division-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <div className="header-title-wrap d-flex justify-content-between align-self-center">
              <div className="title-left">
                <h2 className="crm-type-title">Department</h2>
              </div>
              {/* {userPermissions.add && (
                <div className="title-right">
                  <Link to="/setup/hrms/department/add" className="btn btn-add">
                    Add Department
                  </Link>
                </div>
              )} */}
            </div>
            {!isLoading && treeData.length > 0 && (
              <div style={{ height: 600 }}>
                <SortableTree
                  treeData={treeData}
                  onChange={this.onChangeNode}
                  nodeContentRenderer={NodeRendererCustom}
                  rowHeight={160}
                  canDrop={this.canDropNode}
                />
              </div>
            )}
            {isLoading && <Loader />}
            {!isLoading && treeData.length == 0 && <NoRecordFound />}
          </div>
        )}
      </div>
    );
  }
}

export { Division };
