import React, { useState } from "react";
import { Link } from "react-router-dom";
import { common, history } from "_helpers"
import { crm } from "_utils"
import { envConfig } from "_config/config";
import QuickForm from "crm/QuickForm";
// css
import "./LeftSideMenu.css";

function LeftSideMenu(props) {
    const [isModal, setIsModal] = useState(props.show);
    const userInfo = crm.userInfo();
    const isEduTech = envConfig.EDUTECH_TENANT.includes(userInfo.pTenantId);
    const redirect = (e, url) => {
        e.preventDefault();
        if (url) {
            history.push("/" + url);
        } else {
            history.push("/work");
        }
    }
    console.log('props.menuControl', props.menuControl)
    return (
        <div className={props.menuControl == "true" ? "leftmenu toggled-menu" : "leftmenu"}>
            <Link to="#" onClick={(e) => redirect(e, "")} title="Home">
                { isEduTech  ? <img src={props.menuControl == "true" ? "https://edutech22.s3.amazonaws.com/media/logo.svg" : "https://edutech22.s3.amazonaws.com/media/us_medium.png"} className={props.menuControl == "true" ? "et-logo" : "logo"} />
                : <img src={props.menuControl == "true" ? common.loadImg("crm-logo.png") : common.loadImg("logo1.png")} className="logo" />
                }
            </Link>
            <ul className="sidebar-nav">
                <li className={history.location.pathname.match(/^\/dashboard/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "dashboard")} title="Dashboard">
                        <i className="dashboard-icon"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/reports/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "reports")} title="Reports">
                        <i className="report-icon"></i>
                        <span>Reports</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/work/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "work")} title="Work">
                        <i className="work-icon"></i>
                        <span>Work</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/task/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "task")} title="Task">
                        <i className="task-icon"></i>
                        <span>Task</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/target/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "target")} title="Target">
                        <i className="target-icon"></i>
                        <span>Target</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/crm/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "crm/campaign")} title="CRM">
                        <i className="crm-icon"></i>
                        <span>CRM</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/setup/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "setup/hrms")} title="Setup">
                        <i className="config-icon"></i>
                        <span>Setup</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/tools/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "")} title="Tools">
                        <i className="tools-icon"></i>
                        <span>Tools</span>
                    </Link>
                </li>
                <li className={history.location.pathname.match(/^\/mail/) ? "active" : ""}>
                    <Link to="#" onClick={(e) => redirect(e, "")} title="Mail">
                        <i className="mail-icon"></i>
                        <span>Mail</span>
                    </Link>
                </li>
                <li>
                    <Link to="#" onClick={(e) => setIsModal(true)} title="Quick Form">
                        <i className="config-icon"></i>
                        <span>Quick Create</span>
                    </Link>
                </li>
            </ul>
            <QuickForm show={isModal} onComplete={(e) => setIsModal(e)} />
        </div>
    );
}

export default LeftSideMenu;