import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Button, Row, Form } from "react-bootstrap";
import Select from "react-select";
//Import CSS
import "./FormSetting.css";

// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";

import { envConfig } from "_config/config";

import { history, formFieldTitles } from "_helpers";
// Import Images
import SettingsIcon from "img/setting-form-setting.png";

const options = [
  { value: "", label: "Select Field Type" },
  { value: "text_input", label: "Text Input" },
  { value: "select", label: "Select Box" },
  { value: "check_box", label: "Check Box" },
  { value: "auto_suggest", label: "Auto Suggestion" },
];

class FormSetting extends React.Component {
  constructor(props) {
    super(props);
    let transitionTypeId = localStorage.getItem("ServiceCode");
    this.state = {
      open: false,
      isRequired: false,
      isShow: false,
      fieldname: "",
      selectOptions: [{ name: "" }],
      inputype: "",
      showAlways: true,
      errors: {
        inputtype: "Please select input type",
        selectOptions: "Please add options",
        fieldname: "Please enter field name",
      },
      popupSubmit: false,
      rowIndex: -1,
      rColumnIndex: -1,
      formList: [
        { label: "Campaign", value: envConfig.ESERVICE_CAMPAIGN_CODE },
        { label: "Enquiry", value: envConfig.ESERVICE_LIST_CODE },
        { label: "Lead", value: envConfig.ESERVICE_LEAD_CODE },
        { label: "Deal", value: envConfig.ESERVICE_DEAL_CODE },
      ],
      transactionTypeId:
        transitionTypeId !== undefined && transitionTypeId > 0
          ? parseInt(transitionTypeId)
          : envConfig.ESERVICE_CAMPAIGN_CODE,
      permissionOpen: false,
      permissions: [
        { label: "READ AND WRITE", value: "ReadWrite" },
        { label: "READ ONLY", value: "ReadOnly" },
        { label: "DON'T SHOW", value: "DontShow" },
      ],
      fieldPermissions: [],
      permissionField: "",
      userPermissions: this.props.userPermissions,
    };
  }

  handleClickOpen = (cidx, gidx, ridx, rcidx) => {
    this.setState(
      {
        open: true,
        columnIndex: cidx,
        groupIndex: gidx,
        rowIndex: ridx > -1 ? ridx : -1,
        rColumnIndex: rcidx > -1 ? rcidx : -1,
      },
      (_) => { }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      columnIndex: -1,
      groupIndex: -1,
      rowIndex: -1,
      rColumnIndex: -1,
      popupSubmit: false,
      selectOptions: [],
      fieldname: "",
      inputtype: "",
      isRequired: false,
      showAlways: true,
      errors: {
        inputtype: "Please select input type",
        selectOptions: "Please add options",
        fieldname: "Please enter field name",
      },
    });
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;

    this.setState({ [name]: value }, () => {
      localStorage.setItem("ServiceCode", value);
      this.props.updateId(value);
    });
  };

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  fieldTypeChange = (event) => {
    const { value } = event;
    this.setState({ inputtype: value });
  };

  handleOptionChange = (e, idx) => {
    const { selectOptions } = this.state;
    selectOptions[idx].name = e.target.value;
    this.setState({
      selectOptions,
    });
  };

  removeOption = (idx) => {
    const { selectOptions } = this.state;
    selectOptions.splice(idx, 1);
    this.setState({
      selectOptions,
    });
  };

  addOptions = (_) => {
    const { selectOptions } = this.state;
    selectOptions.push({ name: "" });
    this.setState({
      selectOptions,
    });
  };
  saveFieldData = (_) => {
    const {
      columnIndex,
      groupIndex,
      rowIndex,
      rColumnIndex,
      fieldname,
      selectOptions,
      isRequired,
      showAlways,
      inputtype,
      errors,
      popupSubmit,
    } = this.state;
    const { layoutFormat } = this.props;

    let isValid = true;

    //Field validation
    if (
      inputtype == "text_input" ||
      inputtype == "select" ||
      inputtype == "check_box" ||
      inputtype == "auto_suggest"
    ) {
      errors["inputtype"] = "";
    } else {
      isValid = false;
    }

    if (inputtype == "select" || inputtype == "auto_suggest") {
      if (
        selectOptions.length == 0 ||
        (selectOptions.length > 0 && selectOptions[0].name == "")
      ) {
        errors["selectOptions"] = "Please add atleast one option";
        isValid = false;
      } else {
        errors["selectOptions"] = "";
      }
    } else {
      errors["selectOptions"] = "";
    }

    if (fieldname == "") {
      isValid = false;
    } else {
      errors["fieldname"] = "";
    }
    this.setState(
      {
        popupSubmit: true,
        errors,
      },
      (_) => {
        //add field to json if validation true
        if (isValid === true) {
          if (columnIndex > -1 && groupIndex > -1) {
            let selectedRow =
              layoutFormat.columns[columnIndex].groups[groupIndex].rows;
            if (rowIndex > -1 && rColumnIndex > -1) {
              selectedRow[rowIndex].columns[rColumnIndex].name = fieldname;
              selectedRow[rowIndex].columns[rColumnIndex].show = showAlways;
              selectedRow[rowIndex].columns[
                rColumnIndex
              ].mandatory = isRequired;
              selectedRow[rowIndex].columns[
                rColumnIndex
              ].options = selectOptions;
            } else {
              let cattrlength =
                layoutFormat.attributes && layoutFormat.attributes.c
                  ? layoutFormat.attributes.c.length + 1
                  : 1;
              selectedRow.push({
                noOfColumns: 1,
                columns: [
                  {
                    key: "Cattr" + cattrlength,
                    name: fieldname,
                    type: "string",
                    mandatory: isRequired,
                    displayOrder: 1,
                    input: inputtype,
                    value: "",
                    editable: true,
                    delete: true,
                    show: showAlways,
                    variable: "user",
                    options: selectOptions,
                  },
                ],
              });
            }
            if (!layoutFormat.attributes) layoutFormat.attributes = { c: [] };
            layoutFormat.attributes.c.push(true);
          }
          //close popup & set false to popupSubmit
          this.setState(
            {
              open: false,
              popupSubmit: false,
              selectOptions: [],
              fieldname: "",
              inputtype: "",
              isRequired: false,
              showAlways: true,
              errors: {
                inputtype: "Please select input type",
                selectOptions: "Please add options",
                fieldname: "Please enter field name",
              },
            },
            (_) => {
              this.handleClose();
            }
          );
        }
      }
    );
  };

  openEditField = (pidx, gidx, ridx, rcidx) => {
    const { layoutFormat } = this.props;
    const FieldDetails =
      layoutFormat.columns[pidx].groups[gidx].rows[ridx].columns[rcidx];
    console.log("FieldDetails ", FieldDetails);
    this.setState(
      {
        fieldname: FieldDetails.name,
        showAlways: FieldDetails.show,
        isRequired: FieldDetails.mandatory,
        inputtype: FieldDetails.input,
        selectOptions: FieldDetails.options
      },
      (_) => {
        this.handleClickOpen(pidx, gidx, ridx, rcidx);
      }
    );
  };

  /**
   * Handle permissions dialog
   */
  openEditPermissions = (pidx, gidx, ridx, rcidx) => {
    const { layoutFormat, roleData } = this.props;
    const { permissions } = this.state;

    const FieldDetails = layoutFormat.columns[pidx].groups[gidx].rows[ridx].columns[rcidx];

    let fieldPermissions = FieldDetails.permission ? FieldDetails.permission : [];
    
    //if no data found, set permissions read & write to all role
    roleData.map((roleObj) => {
      if (roleObj.value != "") {
        let obj = { role: roleObj.value, access: permissions[0].value }
        let isExists = fieldPermissions.find(o => o.role == roleObj.value);
        if (!isExists) {
          fieldPermissions.push(obj);
        }        
      }
    });
    
    this.setState(
      {
        fieldPermissions: fieldPermissions ? fieldPermissions : [],
        permissionField: FieldDetails.name,
      },
      () => {
        this.handleClickPermissionOpen(pidx, gidx, ridx, rcidx);
      }
    );
  };

  handleClickPermissionOpen = (cidx, gidx, ridx, rcidx) => {
    this.setState(
      {
        permissionOpen: true,
        columnIndex: cidx,
        groupIndex: gidx,
        rowIndex: ridx > -1 ? ridx : -1,
        rColumnIndex: rcidx > -1 ? rcidx : -1,
      },
      (_) => { }
    );
  };

  onCheckboxChange = (event, idx) => {
    const { fieldPermissions } = this.state;
    fieldPermissions[idx].access = event.target.value;
    this.setState({
      fieldPermissions
    });
  };

  /**
   * ADD/Update field permissions
   */
  addEditPermissions = (event) => {
    event.preventDefault();
    const {
      fieldPermissions,
      columnIndex,
      groupIndex,
      rowIndex,
      rColumnIndex,
    } = this.state;
    const { layoutFormat } = this.props;

    if (columnIndex > -1 && groupIndex > -1) {
      let selectedRow = layoutFormat.columns[columnIndex].groups[groupIndex].rows;
      if (rowIndex > -1 && rColumnIndex > -1) {
        let data = [];
        data = fieldPermissions;
        selectedRow[rowIndex].columns[rColumnIndex]["permission"] = data;        
        this.setState({
          permissionOpen: false,
          columnIndex: -1,
          groupIndex: -1,
          rowIndex: -1,
          rColumnIndex: -1,
        })
      }
    }
  }

handlePermissionClose = () => {
  this.setState({
    permissionOpen: false,
    columnIndex: -1,
    groupIndex: -1,
    rowIndex: -1,
    rColumnIndex: -1,
  });
};

deleteField = (pidx, gidx, ridx, rcidx) => {
  const { layoutFormat } = this.props;
  layoutFormat.columns[pidx].groups[gidx].rows[ridx].columns.splice(rcidx, 1);
  this.setState(
    {
      layoutFormat,
    },
    (_) => { }
  );
};

toggleField = (pidx, gidx, ridx, rcidx) => {
  const { layoutFormat } = this.props;
  layoutFormat.columns[pidx].groups[gidx].rows[ridx].columns[
    rcidx
  ].show = !layoutFormat.columns[pidx].groups[gidx].rows[ridx].columns[rcidx]
    .show;

  this.setState(
    {
      layoutFormat,
    },
    (_) => { }
  );
};

updateFormData = (_) => {
  this.props.updateFormData(this.props.layoutFormat);
};

getRoleName = (id) => {
  const { roleData } = this.props;
  return (      
    roleData.map((role, idx) => (
      parseInt(role.value) == parseInt(id)) && (
      <span key={role.label}>{role.label}</span>
      )          
    )
  );
};

render() {
  const { layoutFormat, roleData } = this.props;
  const {
    open,
    isRequired,
    showAlways,
    fieldname,
    selectOptions,
    inputtype,
    rowIndex,
    rColumnIndex,
    errors,
    popupSubmit,
    formList,
    transactionTypeId,
    permissionOpen,
    permissions,
    fieldPermissions,
    permissionField,
    userPermissions
  } = this.state;
  if (layoutFormat != null) {
    return (
      <div className="lead-wraper">
        {/* <ConfigureMenu moduleType={moduleType} /> */}
        {/* <div className="secondary-nav">
            <ul className="secondary-nav-list">
              <li>
                <Link to="/transactionstatus/:lead">Transaction Status</Link>
              </li>
              <li>
                <Link to="/StatusTransition/:lead">Status Transition</Link>
              </li>
              <li className="active">
                <Link to="/formSetting/:lead">Form Settings</Link>
              </li>
            </ul>
          </div> */}
        {/* Secondary Nav end */}
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-sm-3">
              <div className="form-row">
                <div className="col">
                  <h2 className="crm-type-title">Form Settings</h2>
                </div>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="form-row justify-content-end">
                <div className="col-sm-4">
                  <div className="select-control">
                    <Select
                      value={formList.find(
                        (o) => o.value === transactionTypeId
                      )}
                      onChange={(e) =>
                        this.selectHandleOnChange(e, "transactionTypeId")
                      }
                      options={formList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row className="form-content-wrap">
          {layoutFormat.columns &&
            layoutFormat.columns.length > 0 &&
            layoutFormat.columns.map((pcoloum, pindex) => (
              <Col key={pindex} sm={12 / layoutFormat.noOfColumns}>
                {pcoloum.groups.map((group, gindex) => (
                  <Col
                    sm={12}
                    className={`form-section mb-30 formWhiteBg ${
                      gindex > 0 ? "fmt-30" : ""
                      }`}
                    key={gindex}
                  >
                    <div className="section-title-wrap formHead">
                      <h2>
                        {group.title}
                        <Link to="#" className="form-edit-icn">
                          {/* <img src={EditIcon} alt="Edit section" /> */}
                        </Link>
                      </h2>
                    </div>
                    {group.rows.map((row, rindex) => (
                      <Row key={rindex}>
                        {row.columns.map((rcoloum, rcindex) => (
                          <Col key={rcindex} sm={12 / row.noOfColumns}>
                            <div
                              draggable
                              className="dragable-element form-field-wrap d-flex justify-content-between align-items-center"
                            >
                              <div className="de-left">
                                <label className="field-type">
                                  {Object.keys(formFieldTitles).indexOf(
                                    rcoloum.input
                                  ) > -1
                                    ? formFieldTitles[rcoloum.input]
                                    : rcoloum.input}
                                </label>
                                <p className="field-name">{rcoloum.name}</p>
                              </div>
                              {/* {rcoloum.editable == true && ( */}
                              <div className="de-right input-settings-cntrl">
                              {userPermissions.update && (
                                <Link to="#" className="field-settings">
                                  <img
                                    src={SettingsIcon}
                                    alt="Edit section"
                                  />
                                </Link>
                              )}
                                <div className="input-settings-menu-wrap">
                                  <ul className="input-settings-menu-list">
                                    {rcoloum.editable == true && (
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(_) =>
                                            this.toggleField(
                                              pindex,
                                              gindex,
                                              rindex,
                                              rcindex
                                            )
                                          }
                                        >
                                          {rcoloum.show == false
                                            ? "Show"
                                            : "Hide"}
                                        </Link>
                                      </li>
                                    )}
                                    {rcoloum.editable == true && (
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(_) =>
                                            this.openEditField(
                                              pindex,
                                              gindex,
                                              rindex,
                                              rcindex
                                            )
                                          }
                                        >
                                          Edit Settings
                                          </Link>
                                      </li>
                                    )}
                                    {rcoloum.delete == true && (
                                      <li>
                                        <Link
                                          to="#"
                                          onClick={(_) =>
                                            this.deleteField(
                                              pindex,
                                              gindex,
                                              rindex,
                                              rcindex
                                            )
                                          }
                                        >
                                          Delete
                                          </Link>
                                      </li>
                                    )}
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={(_) =>
                                          this.openEditPermissions(
                                            pindex,
                                            gindex,
                                            rindex,
                                            rcindex
                                          )
                                        }
                                      >
                                        Permission
                                        </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/*  )} */}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ))}
                    {userPermissions.update && (
                      <div className="section-footer d-flex justify-content-between">
                        <Link
                          to="#"
                          onClick={(_) => this.handleClickOpen(pindex, gindex)}
                          className="add-field-link"
                        >
                          Add Field
                          </Link>
                        <Link to="#" className="delete-section">
                          Delete Section
                          </Link>
                      </div>
                    )}
                  </Col>
                ))}
              </Col>
            ))}
        </Row>
        {userPermissions.update && (
          <Row className="botBtn btn-fixed">
            <Col sm={12}>
              <Button
                onClick={this.updateFormData}
                className="saveBtn"
                type="submit"
              >
                Update
                </Button>
              <Button
                className="cancelBtn"
                onClick={() => {
                  history.push("/setup/configure");
                }}
                type="button"
              >
                Cancel
                </Button>
            </Col>
          </Row>
        )}

        {/*  Edit Field Popup Start */}
        <div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">
              {"Add Field"}
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap">
                <div className="list-row">
                  <Form.Group>
                    <Form.Label>Field Type</Form.Label>
                    <div>
                      <Select
                        value={options.find((o) => o.value === inputtype)}
                        onChange={this.fieldTypeChange}
                        options={options}
                        isDisabled={rowIndex > -1 && rColumnIndex > -1}
                      />
                    </div>
                  </Form.Group>
                  {errors.inputtype !== "" && popupSubmit === true && (
                    <Form.Text className="error">
                      {errors.inputtype}
                    </Form.Text>
                  )}
                </div>

                <div className="list-row">
                  <label>Field Name</label>
                  <input
                    type="text"
                    name="Field Name"
                    id="leadname"
                    className="form-control"
                    value={fieldname}
                    onChange={(event) =>
                      this.handleChange(event, "fieldname")
                    }
                  />
                  {errors.fieldname !== "" && popupSubmit === true && (
                    <Form.Text className="error">
                      {errors.fieldname}
                    </Form.Text>
                  )}
                </div>
                {(inputtype == "select" || inputtype == "auto_suggest") && (
                  <div className="list-row">
                    {selectOptions.map((option, idx) => (
                      <div key={idx}>
                        <label>Option {idx + 1}</label>
                        <div className="input-group">
                          <input
                            type="text"
                            name="option"
                            id="option"
                            className="form-control"
                            value={option.name}
                            onChange={(val) =>
                              this.handleOptionChange(val, idx)
                            }
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-remove-option"
                              onClick={(_) => {
                                this.removeOption(idx);
                              }}
                              type="button"
                            >
                              X
                              </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {errors.selectOptions !== "" && popupSubmit === true && (
                      <Form.Text className="error">
                        {errors.selectOptions}
                      </Form.Text>
                    )}
                    <Link
                      to="#"
                      onClick={(_) => {
                        this.addOptions();
                      }}
                      className="add-field-link"
                    >
                      Add Option
                      </Link>
                  </div>
                )}
                <div className="list-row">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Required?</label>
                    <Switch
                      checked={isRequired}
                      color="primary"
                      onChange={(val) => {
                        this.setState({ isRequired: !isRequired });
                      }}
                      name="isRequired"
                    // inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>
                <div className="list-row">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>Show Always?</label>
                    <Switch
                      checked={showAlways}
                      color="secondary"
                      onChange={() => {
                        this.setState({ showAlways: !showAlways });
                      }}
                      name="isShow"
                    // inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions className="popup-footer justify-content-start">
              <Row className="botBtn">
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    onClick={this.saveFieldData}
                    type="submit"
                  >
                    Add
                    </Button>
                  <Button
                    className="cancelBtn"
                    onClick={this.handleClose}
                    type="button"
                  >
                    Cancel
                    </Button>
                </Col>
              </Row>
            </DialogActions>
          </Dialog>
        </div>

        {/*  Edit Permission Popup Start */}
        <div>
          <Dialog
            open={permissionOpen}
            onClose={this.handlePermissionClose}
            aria-labelledby="responsive-dialog-permissions"
          >
            <DialogTitle
              id="responsive-dialog-permissions"
              className="popup-title"
            >
              {permissionField} - {"Field Level Permissions"}
            </DialogTitle>
            <DialogContent>
              <div className="popup-form-wrap">
                {fieldPermissions && fieldPermissions.length && (
                  <div className="permission-list">
                    <table className="table table-striped field-permission-list">
                      <thead className="thead-dark">
                        <tr>
                          <th>Role</th>
                          <th>{permissions[0].label}</th>
                          <th>{permissions[1].label}</th>
                          <th>{permissions[2].label}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fieldPermissions.map((permission, idx) => (
                          <tr key={idx}>
                            <td>{this.getRoleName(permission.role)}</td>
                            <td>
                              <input
                                type="radio"
                                value={permissions[0].value}
                                name={"permisssion_" + idx}
                                checked={permission.access === permissions[0].value}
                                onChange={(e) => this.onCheckboxChange(e, idx)}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                value={permissions[1].value}
                                name={"permisssion_" + idx}
                                checked={permission.access === permissions[1].value}
                                onChange={(e) => this.onCheckboxChange(e, idx)}
                              />
                            </td>
                            <td>
                              <input
                                type="radio"
                                value={permissions[2].value}
                                name={"permisssion_" + idx}
                                checked={permission.access === permissions[2].value}
                                onChange={(e) => this.onCheckboxChange(e, idx)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions className="popup-footer justify-content-start">
              <Row className="botBtn">
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    onClick={this.addEditPermissions}
                    type="submit"
                  >
                    Save
                    </Button>
                  <Button
                    className="cancelBtn"
                    onClick={this.handlePermissionClose}
                    type="button"
                  >
                    Cancel
                    </Button>
                </Col>
              </Row>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
}
//export default FormSetting;
const mapStateToProps = (state) => {
  const { alert } = state;
  return { alert };
};
export default connect(mapStateToProps)(FormSetting);
