import React from "react";
import { Alert } from "react-bootstrap";

import { Link } from "react-router-dom";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tableConfig } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

import { connect } from "react-redux";
//Import CSS
import "./AnnexuresList.css";

import SearchIcon from "img/search-icn.png";
import Pagination from "react-js-pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from '@material-ui/core/Checkbox';
import Paper from "@material-ui/core/Paper";

import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class AnnexuresList extends React.Component {
  constructor() {
    super();
    this.state = {
      pageEntries: 0,
      isListView: true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      filterSummary: "",
      crmStatusList: [],
      objectId: 0,
      objectType: '',
      tlConfig: {
        headCells: [
          { id: "DisplayOrder", value: "DisplayOrder", label: "Display Order" },
          { id: "AnnexType", value: "AnnexType", label: "Annexure Type" },
          { id: "AnnexContent", value: "AnnexContent", label: "Annexure Content" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "DisplayOrder",
        selected: [],
        uniqueKey: "AnnexureColumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0
      },
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getAnnexureList();
        }
      );
    }
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMultileSelectChange = (selected) => {
    this.setState({
      columnsSelected: selected,
    });
    this.storecolumnsSelected(selected);
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getAnnexureList();
      }
    );
  };

  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.setState({
        objectId: this.props.objectId,
        objectType: this.props.objectType,
      }, () => {
        this.getAnnexureList();
      });
    }
  }

  handlePageEntries = (e, val) => {
    this.setState({
      pageEntries: val,
    });
  };

  /**
   * Get all annexure lists from DB
   */
  getAnnexureList = () => {
    this.setState({ isLoading: true });

    const { currentPage, OrderBy, pageLimit, filterSummary, objectId, tlConfig } = this.state;

    let filter = `?q=CustQuoteId=${objectId} AND UPPER(AnnexType) LIKE '*${filterSummary}*' &totalResults=true&limit=${pageLimit}&offset=${((currentPage - 1) * pageLimit)
      }`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    let url = encodeURI(envConfig.BASE_API + Services.CRM_ANNEXURES + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          tlConfig.enableMultipleDelete = (response && response.items && response.items.length) ? true : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete ? response.items.length : 0;

          this.setState({
            listRes: response,
            isLoading: false,
            tlConfig: tlConfig
          });
        }
      }
    );
  };


  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.CustQuoteAnnxId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig } = this.state;
    let inputData = [];
    tlConfig.selected.map((id, index) => {
      inputData.push({
        "id": "part" + (index + 1),
        "path": "/" + Services.CRM_ANNEXURES + "/" + id,
        "operation": "delete",
        "payload": {}
      });
    })

    var formAttr = { "parts": inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getAnnexureList();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig
          })
          common.snack("S", "Selected items has been deleted successfully.")
        }
      },
      (error) => {
        common.snack("E", error);
      }
    )
  }

  render() {
    const {
      isLoading,
      listRes,
      currentPage,
      pageLimit,
      tlConfig,
      filterSummary,
      objectId,
      objectType,
    } = this.state;


    const isHided = (CustQuoteAnnxId) => tlConfig.columnsSelected.indexOf(CustQuoteAnnxId) !== -1;
    const isSelected = (CustQuoteAnnxId) => tlConfig.selected.indexOf(CustQuoteAnnxId) !== -1;

    const handleClick = (event, CustQuoteAnnxId) => {
      const selectedIndex = tlConfig.selected.indexOf(CustQuoteAnnxId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, CustQuoteAnnxId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1),
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig
      });
    };

    return (
      <div className="lead-wraper">
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-4">
              <div className="form-row">
                <div className="col">
                  <h2 className="crm-type-title">Annexures</h2>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="form-row justify-content-end">
                <div className="col-lg-3">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Filter and Search
                  </label>
                  <div className="input-group search-filter-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="+ Filter and Search"
                      name="filterSummary"
                      value={filterSummary}
                      onChange={this.handleFieldChange}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getAnnexureList}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  <Link to={"/addannexure/" + objectType + "/" + objectId} className="btn btn-add">
                    Add
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">

          <div className="clearfix"></div>


          <div className="list-view-wrap">
            <EnhancedTableToolbar tlConfig={tlConfig} deleteSelected={this.deleteSelected} />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false && listRes.items &&
                    listRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        listRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.CustQuoteAnnxId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.CustQuoteAnnxId)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.CustQuoteAnnxId}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                !isHided("DisplayOrder") ? "hideCell" : ""
                              }
                            >
                              <Link to={"/addannexure/" + objectType + "/" + objectId + "/" + row.CustQuoteAnnxId}>
                                {row.DisplayOrder ? row.DisplayOrder : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("AnnexType") ? "hideCell" : ""
                              }
                            >
                              <div className="update-status">
                                <p>{row.AnnexType ? row.AnnexType : "-"}</p>
                              </div>
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("AnnexContent") ? "hideCell" : ""
                              }
                            >
                              {row.AnnexContent ? row.AnnexContent : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}><Loader /> </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false && listRes.items &&
                    listRes.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}><NoRecordFound /> </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* )} */}

            {isLoading == false && listRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  Showing {(currentPage - 1) * pageLimit + 1} to{" "}
                  {(currentPage - 1) * pageLimit + listRes.count} of{" "}
                  {listRes.totalResults} entries
                  </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={listRes.limit}
                    totalItemsCount={listRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
          {/* )} */}

        </div>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, crmstatus } = state;
  return { alert, crmstatus };
};
export default connect(mapStateToProps)(AnnexuresList);
