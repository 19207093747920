import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Select from 'react-select';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices } from "_helpers";

//Import CSS
import "../css/Designation.css";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "material-ui/Checkbox";

// Defined Errors
const defErrs = {
  DesignationName: {
    isReq: "Designation name is required!",
  },
  DesignationCode: {
    isReq: "Designation code is required!",
  },
  DivisionId: {
    isReq: "Division name is required!",
  },
  SubDivId: {
    isReq: "Sub division name is required!",
  },
  DepartmentId: {
    isReq: "Department name is required!",
  },
  Active: {
    isReq: "Active is required",
  }
};
const userInfo = common.authInfo();
class AddDesignation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        DesignationName: "",
        DesignationCode: "",
        Active: "N",
        StartDate: new Date(),
        EndDate: null,
        DivisionId: "",
        SubDivId: "",
        DepartmentId: "",
        PadiEligibility: null,

        TenantId: userInfo.TenantId,
        BgId: userInfo.GroupId,
      },
      excludeFields: [
        "DesignationCode",
        "FirstName",
        "LastName",
        "Email",
        "Password",
      ],
      errors: {},
      formSubmit: false,
      divisionData: [{ label: "Select", value: "" }],
      subDivisionData: [{ label: "Select", value: "" }],
      departmentData: [{ label: "Select", value: "" }],
      updateId: 0,
      filterName: "",
      page: 1,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.defineInitErrorMsg();

    let search = window.location.search;
      let params = new URLSearchParams(search);
      let filterName = params.get("filterName");
      let page = params.get("page");

    if (this.props.match.params.designationId) {
      this.setState(
        {
          updateId: parseInt(this.props.match.params.designationId),
          filterName,
          page
        },
        () => {
          this.getDetailById();
        }
      );
    }

    this.getMasterData("divisionData");    
  }

  /**
   * Initialize error message
   */
  defineInitErrorMsg = () => {
    const { errors, fields } = this.state;
    for (var key of Object.keys(defErrs)) {
      switch (key) {
        case "Active":
          if (fields[key] == "N") {
            errors[key] = defErrs[key].isReq;
          } else {
            errors[key] = "";
          }
          break;
        default:
          if(fields[key] == null || fields[key] == ""){
            errors[key] = defErrs[key].isReq;
          }          
      }
    }
    this.setState({
      errors: errors,
    });
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      if (name === "Active" && value === "N") {
        errors[name] = defErrs[name].isReq;
       }
      else {
        errors[name] = "";
      }
    }
    
    this.setState({
      errors: errors,
    });
  };

  loadDependentResources = (name, value) => {
    const { fields } = this.state;
    if (name == "DivisionId") {
      fields.SubDivId = "";
      fields.DepartmentId = "";
      this.setState(
        {
          
          fields: fields,
          departmentData: [{ label: "Select", value: "" }],
          subDivisionData: [{ label: "Select", value: "" }],          
        },
        () => {            
          this.validateForm("DepartmentId", "");
          this.validateForm("SubDivId", "");
        });

      if (!value) {
        fields.SubDivId = "";
        fields.DepartmentId = "";
        this.setState(
          {
            subDivisionData: [{ label: "Select", value: "" }],            
            fields: fields,
          },
          () => {            
            this.validateForm("DepartmentId", "");
            this.validateForm("SubDivId", "");
          }
        );
      } else {
        this.getMasterData("subDivisionData");
      }
    }

    if (name == "SubDivId") {
      if (!value) {
        fields.DepartmentId = "";
        this.setState(
          {
            departmentData: [{ label: "Select", value: "" }],
            fields: fields,
          },
          () => {
            this.validateForm("DepartmentId", "");
          }
        );
      } else {
        this.getMasterData("departmentData");
      }
    }
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.loadDependentResources(name, value);
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, type) => {
    const { label, value } = event;
    const { fields } = this.state;
    if (fields.hasOwnProperty(type)) {
      fields[type] = value;
    }

    this.setState({ fields: fields },
      () => {
        this.loadDependentResources(type, value);
        this.validateForm(type, value);
      }
    );
  }

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };

  save = (event) => {
    event.preventDefault();

    const { errors, updateId } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      
      isValid = !errors[key];
    }

    if (isValid) {
      if (updateId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, updateId, filterName, page } = this.state;
    var formAttr = Object.assign({}, fields);

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key]
            ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ")
            : null;
          break;
        default:
          break;
      }
    }

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DESIGNATIONS + "/" + updateId
    );
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        history.push("/hrms/designations?desgId=" + updateId + "&filterName=" +
        filterName +
        "&page=" +
        page);
      },
      (error) => {}
    );
  };

  create = () => {
    const { fields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key]
            ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ")
            : null;
          break;
        default:
          formAttr[key] = fields[key];
      }
    }

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_DESIGNATIONS);
    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        history.push("/hrms/designations");
      },
      (error) => {}
    );
  };

  getDetailById = () => {
    const { updateId, fields, errors } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DESIGNATIONS + "/" + updateId
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                switch (key) {
                  case "StartDate":
                  case "EndDate":
                    fields[key] = response[key]
                      ? new Date(response[key])
                      : null;
                    break;
                  default:
                    fields[key] = response[key];
                }

                this.loadDependentResources(key, response[key]);

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  // Optional Validation Here
                  if (key === "Active" && response[key] === "Y") {
                    errors[key] = "";
                  } else if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                    errors[key] = "";
                  }
                }
              }
            }
          }

          this.setState({
            fields: fields,
            errors: errors,
          });
        } else {}
      },
      (error) => {}
    );
  };

  getURLByType = (type) => {
    const { fields } = this.state;
    switch (type) {
      case "divisionData":
        return encodeURI(envConfig.BASE_API + Services.HRMS_DIVISION);
      case "subDivisionData":
        return encodeURI(
          envConfig.BASE_API +
            Services.HRMS_SUB_DIVISION +
            "/?q=DivisionId=" +
            fields.DivisionId +
            "&limit=50"
        );
      case "departmentData":
        return encodeURI(envConfig.BASE_API + Services.HRMS_DEPARTMENTS +
          "/?q=SubDivId=" +
            fields.SubDivId +
            "&limit=50");
      default:
        return "";
    }
  };

  getMasterData = (type) => {
    restServices.getRequest(
      this.getURLByType(type),
      (response) => {
        let masterData = [];
        switch (type) {
          case "divisionData": 
            masterData = common.getOptionsData(response.items, "DivisionName", "DivisionId");
            break;
          case "subDivisionData": 
            masterData = common.getOptionsData(response.items, "SubDivisionName", "SubDivisionId");
            break;
          case "departmentData": 
            masterData = common.getOptionsData(response.items, "DepartmentName", "DeptId");
            break;
        }
        this.setState({
          // [type]: response.items.length > 0 ? response.items : [],
          [type]: masterData,
        });
      },
      (error) => {}
    );
  };

  onCancel = () => {
    const { updateId, filterName, page } = this.state;

    if(updateId > 0) {
      history.push("/hrms/designations?desgId=" + updateId + "&filterName=" +
        filterName +
        "&page=" +
        page);
    } else {
      history.push("/hrms/designations");
    }
  };

  render() {
    const {
      fields,
      errors,
      formSubmit,
      divisionData,
      subDivisionData,
      departmentData,
      updateId,
    } = this.state;

    const isChecked = (value) => value == "Y";

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Designation Information</h2>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Designation Code*</Form.Label>
                  <Form.Control
                    type="text"
                    name="DesignationCode"
                    value={fields.DesignationCode}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.DesignationCode !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.DesignationCode}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Division Name*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={divisionData.find(o => o.value === fields.DivisionId)}
                      onChange={(e) => this.selectHandleOnChange(e, 'DivisionId')}
                      options={divisionData}
                    />
                  </div>
                  {errors.DivisionId !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.DivisionId}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Sub Division Name*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={subDivisionData.find(o => o.value === fields.SubDivId)}
                      onChange={(e) => this.selectHandleOnChange(e, 'SubDivId')}
                      options={subDivisionData}
                    />
                  </div>
                  {errors.SubDivId !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.SubDivId}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Department Name*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={departmentData.find(o => o.value === fields.DepartmentId)}
                      onChange={(e) => this.selectHandleOnChange(e, 'DepartmentId')}
                      options={departmentData}
                    />
                  </div>
                  {errors.DepartmentId !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.DepartmentId}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>&nbsp;</Form.Label>
                  <Checkbox
                    name="PadiEligibility"
                    className="filter-checkbox"
                    checked={isChecked(fields.PadiEligibility)}
                    label="Padi Eligible"
                    labelStyle={{ color: "#A7AFC2" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Designation Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="DesignationName"
                    value={fields.DesignationName}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                  {errors.DesignationName !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.DesignationName}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker
                    readOnly={true}
                    name="StartDate"
                    value={fields.StartDate}
                    onChange={(date) =>
                      this.handleDateOnChange(date, "StartDate")
                    }
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    name="EndDate"
                    value={fields.EndDate}
                    onChange={(date) =>
                      this.handleDateOnChange(date, "EndDate")
                    }
                    format="dd/MM/yyyy"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>&nbsp;</Form.Label>
                  <Checkbox
                    name="Active"
                    checked={isChecked(fields.Active)}
                    label="Active"
                    labelStyle={{ color: "#A7AFC2" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                  {errors.Active !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.Active}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn mt-5">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {updateId > 0 ? "Update" : "Save"}
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export { AddDesignation };
