import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// includes
import { crm } from '_utils';
// Import Images
import SearchIcon from "img/search-icn.png";
// Import Internal components
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import TemplateDetail from "./TemplateDetail";
// Import css
import "../css/Template.css";
class TemplateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPermissions: common.getPermission(this.props, "configure:template"),
      filterName: "",
      isSearchLoad: false,
      searchList: [],
      selectedTemplate: null,
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  /**
   * Methods
   */

  handleClickList = async (template) => {
    const selectedTemplate = template;
    selectedTemplate.HtmlFormat = await common.evaluateEmailTemplates(template.HtmlFormat, template.Category, "key", "name");
    this.setState(
      {
        selectedTemplate,
      },
      (_) => {
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  /**
   * Service Request
   */
  getList() {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item)
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(Subject) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    let url = encodeURI(envConfig.BASE_API + Services.CHOLA_EMAIL_TEMPLATE + filter);

    restServices.getRequest(
      url,
      (response) => {
        let selectedTemplate = this.state.selectedTemplate;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          response.items.map((item, key) => {
            tempList.push(item);
          });

          if (paging.page === 1) {
            selectedTemplate = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              if (paging.page === 1) {
                this.handleClickList(selectedTemplate);
              }
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
            selectedTemplate: null
          });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  /**
   * Trigger Delete Email Template Popup
   * @param {*} templateId
   */
  deleteTemplate = (templateId) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteTemplate(templateId);
    });
  }

  /**
   * Delete Template by ID
   * @param {*} templateId
   */
  handleClickDeleteTemplate = (templateId) => {
    const { paging } = this.state;
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CHOLA_EMAIL_TEMPLATE + "/" + templateId;
    /** TODO - User wants to delete */
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;
        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
        common.snack("S", "Template deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  }

  render() {
    const {
      userPermissions,
      selectedTemplate,
      isSearchLoad,
      searchList,
      paging,
      totalRecords
    } = this.state;
    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <Row>
              <Col sm={3}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Template</h2>
                    {userPermissions.add && (
                      <Link
                        to={"/setup/configure/template/add"}
                        className="btn btn-add"
                      >
                        Add
                      </Link>
                    )}
                  </div>

                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                            onClick={this.getList}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="filterName"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search"
                        name="filterName"
                      />
                    </div>
                    <ul className="marketing-list">
                      {selectedTemplate &&
                        isSearchLoad == false &&
                        searchList.length > 0 &&
                        searchList.map((row, index) => {
                          return (
                            <li
                              onClick={(e) => this.handleClickList(row)}
                              key={row.EmailTemplateId}
                              className={`d-flex justify-content-between align-items-center ${row.EmailTemplateId == selectedTemplate.EmailTemplateId
                                ? "active"
                                : ""
                                }`}
                            >
                              <h4 className="ml-name">{row.Subject}</h4>
                            </li>
                          );
                        })}
                      {isSearchLoad == true && <Loader />}
                      {isSearchLoad == false && searchList.length === 0 && (
                        <li>
                          <NoRecordFound />
                        </li>
                      )}
                    </ul>
                    {/* Pagination */}
                    {searchList.length > 0 && (<div className="load-more-wrap">
                      {paging.page != paging.total && searchList.length > 0 && (
                        <Link to="#" onClick={(e) => this.pagination(e)} className="load-more"> Load More </Link>
                      )}
                      <div className="paging-text">1 to {searchList.length} of {totalRecords} entries</div>
                    </div>)}
                  </Paper>
                </div>
              </Col>
              <Col sm={9}>
                {isSearchLoad == false &&
                  selectedTemplate &&
                  searchList &&
                  searchList.length > 0 && (
                    <div className="formTabSection position-r">
                      <TemplateDetail selectedTemplate={selectedTemplate} category={searchList.Category} userPermissions={userPermissions} deleteTemplate={this.deleteTemplate} />
                    </div>
                  )}
                {isSearchLoad == false && !selectedTemplate && (
                  <div className="formTabSection position-r">
                    <Paper style={styles.boxLayout}>
                      <NoRecordFound />
                    </Paper>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export { TemplateList };
