import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//Import CKEditor files
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic/";
import Paper from "@material-ui/core/Paper";

//Import CSS
import "./Comments.css";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// includes
import { crm } from '_utils';
import DeleteIcon from 'img/delete.svg';

class Comments extends React.Component {
  constructor() {
    super();
    this.state = {
      formSubmit: false,
      isLoading: false,
      currentPage: 1,
      pageLimit: 100,
      OrderBy: "",
      commentReponse: {},
      fields: {
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        ObjectId: 0,
        ObjectType: "",
        CommentType: "GENERAL",
        CommentContent: "",
      },
      errors: {
        CommentContent: "",
      },
      selectedItem: 0
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { fields } = this.state
    if (this.props.objectId != undefined) {
      fields["ObjectId"] = this.props.objectId;
      fields["ObjectType"] = this.props.objectType;

      this.setState({
        fields: fields,
      }, () => {
        this.getComments();
      });
    }
  }


  /**
   * Get all comments from DB
   */
  getComments = () => {
    this.setState({ isLoading: true });

    const { commentReponse, currentPage, OrderBy, pageLimit, fields } = this.state;

    let filter = `?q=ObjectId=${fields["ObjectId"]};&totalResults=true&orderBy=CreatedOn:asc&limit=${pageLimit}&offset=${((currentPage - 1) * pageLimit)
      }`;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_COMMENTS + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            commentReponse: response,
            isLoading: false,
          });
        }
      }
    );
  };

  /**
   * Create comment method
   */
  CreateComment = () => {
    const { commentReponse, fields, errors } = this.state;
    let url = envConfig.BASE_API + Services.CRM_COMMENTS;

    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          commentReponse.items.push(response);
          fields.CommentContent = "";
          errors.CommentContent = "";
          this.setState({
            commentReponse: commentReponse,
            fields: fields,
            errors: errors,
            formSubmit: false
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Form Submit Handler
   */
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      formSubmit: true,
    });

    const { fields } = this.state;

    let newData = fields.CommentContent;
    newData = newData.replace("<p>", "");
    newData = newData.replace("</p>", "");
    fields["CommentContent"] = newData;

    this.setState({ fields: fields });

    if (fields.CommentContent != null && fields.CommentContent != "") {
      this.CreateComment();
    } else {
      let errors = this.state.errors;
      errors.CommentContent = "Please enter note";
      this.setState({ errors: errors });
    }
  };

  /**
   * Delete comment
   */
  deleteItem = (id) => {
    this.setState({ selectedItem: id });
    common.confirmDelete(true, (response) => {
      this.handleDeleteBlock();
    });
  }

  handleDeleteBlock = () => {
    const { selectedItem } = this.state;

    let url = envConfig.BASE_API + Services.CRM_COMMENTS + "/" + selectedItem;

    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Selected item deleted successfully.")

        this.setState({
          isLoading: true,
        });

        let responseList = this.state.commentReponse;
        let updateData = [];
        if (responseList != null && responseList.items != null) {
          updateData = responseList.items.filter((comment) => {
            return selectedItem !== comment.CommentId;
          });
          responseList["items"] = updateData;
        }

        this.setState({
          commentReponse: responseList,
          isLoading: false,
        });
      },
      (error) => { });
  }

  render() {
    const { commentReponse, isLoading, fields, errors, formSubmit } = this.state;
    return (
      <div className="comments-wrap">
        <Form onSubmit={this.handleSubmit}>
          <CKEditor
            editor={ClassicEditor}
            // config={ autoParagraph: false }
            data={fields.CommentContent}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              fields["CommentContent"] = data;

              if (data == null || data == "") {
                errors.CommentContent = "Please enter comment";
              } else {
                errors.CommentContent = "";
              }

              this.setState({
                fields: fields,
                errors: errors,
              });
            }}
            onBlur={(event, editor) => {
            }}
            onFocus={(event, editor) => {
            }}
          />
          {errors.CommentContent !== "" && formSubmit === true && (
            <div className="error">{errors.CommentContent}</div>
          )}
          <Button className="saveBtn mt-4" type="submit">
            Add Note
          </Button>
        </Form>
        <h3 className="mt-4 mb-3">Activity</h3>
        <ul className="activity-list">
          {isLoading == false && (commentReponse.items &&
            commentReponse.items.length > 0) &&
            commentReponse.items.map((row, index) => {
              return (
                <li className="media" key={"commentList" + index}>
                  <img className="mr-3 align-self-center comment-user-img" src="..."
                    src={envConfig.IMG_SERVLET_URL + "?imageId=" + row.UserId + "&imageType=USER"}
                    alt="User Profile" />
                  {/* <span className="mr-3 align-self-center comment-user-img"></span> */}
                  <div className="media-body">
                    <h4 className="comment-user-name">{row.FirstName + " " + row.LastName} <span>{common.formatDateAMPM(row.CreatedOn, "MMM DD", true)}</span></h4>
                    <p dangerouslySetInnerHTML={{ __html: row.CommentContent }}></p>
                  </div>
                  <Link to="#">
                    <img src={DeleteIcon} onClick={() => this.deleteItem(row.CommentId)} />
                  </Link>
                </li>
              )
            })}
        </ul>
        {isLoading == true && <Loader />}
        {isLoading == false && commentReponse.items &&
          commentReponse.items.length == 0 && (
            <div className="formTabSection position-r">
              <Paper style={styles.boxLayout}>
                <NoRecordFound />
              </Paper>
            </div>
          )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default Comments;
