import React from "react";
import { Link } from "react-router-dom";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
import Loader from "../../../../_components/_loader/Loader";
import NoRecordFound from "../../../../_components/_noRecordFound/NoRecordFound";
import Paper from "@material-ui/core/Paper";

class TaskList extends React.Component {
  userData = common.authInfo();
  constructor(props) {
    super(props);
    this.state = {
      taskListReponse: {},
      filterTaskName: "",
      taskRefresh: false,
      userId: this.userData.EmpId,
      userName: this.userData.UserName,
      isLoading: true
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevState) {
    if (this.state.taskRefresh != this.props.taskRefresh) {
      //this.setState({ taskRefresh: false });
      this.getList();
    }
  }

  /**
   * Get all list
   */
  getList = () => {
    const { filterTaskName, userId, userName } = this.state;
    let currentPage = 1;
    let OrderBy = "StartDate";
    let pageLimit = 4;
    this.setState({ isLoading: true });

    let filter = `?finder=FindTaskByReporter;pAssignee=${userId},pCreatedBy=${userName},pSearchCriteria=${filterTaskName}&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }&totalResults=true`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    let url = encodeURI(envConfig.BASE_API + Services.TASKS_VIEW + filter);

    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          taskListReponse: response,
          isLoading: false
        });
      },
      (error) => {
        this.setState({
          taskListReponse: {},
          isLoading: false
        });
      }
    );
  };

  render() {
    const { taskListReponse, userId, userName, isLoading } = this.state;
    return (
      <div>
        <div className="row task-list">
          {!isLoading && taskListReponse &&
            taskListReponse.items &&
            taskListReponse.items.length > 0 &&
            taskListReponse.items.map((row, index) => {
              return (
                <Link
                  to={`crm/${row.TaskTableExtattr2}/${row.TaskPk}`}
                  className="col-md-6 mb-4 task"
                  key={index}
                >
                  <div className="activity-details work-task-details">
                    <p className="work-task-name text-bold">
                      {row.TaskName}{" "}
                      {row.EmployeeId == null && row.CreatedBy == userName && (
                        <span>was created by you</span>
                      )}
                      {row.EmployeeId &&
                        row.EmployeeId != userId &&
                        row.CreatedBy == userName && (
                          <span>
                            was assigned to{" "}
                            <span className="text-bold">
                              {row.EmployeeName}
                            </span>{" "}
                          </span>
                        )}
                      {row.EmployeeId == userId && row.CreatedBy != userName && (
                        <span>
                          was assigned to you{" "}
                          {row.CreatedBy && (
                            <span>
                              by{" "}
                              <span className="text-bold">{row.CreatedBy}</span>
                            </span>
                          )}
                        </span>
                      )}
                    </p>
                    <div className="row">
                      {row.EndDate && (
                        <div className="col">
                          <p>
                            Due date <br />
                            <span className="text-bold">
                              {common.formatDate(
                                row.EndDate,
                                "DD-MM-YYYY - hh:mm a"
                              )}
                            </span>
                          </p>
                        </div>
                      )}

                      <div className="col">
                        <h5>
                          Priority: <br />
                          <span className="text-bold">
                            {row.PriorityName}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
        {isLoading && <Loader />}
        {!isLoading && taskListReponse.totalResults == 0 &&
          <div className="formTabSection position-r">
            <Paper style={styles.boxLayout}>
              <NoRecordFound />
            </Paper>
          </div>
        }
        {taskListReponse && taskListReponse.totalResults > 4 && (
          <div className="text-center mb-4">
            <Link to="/task" className="saveBtn btn btn-primary">
              View All Tasks
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default TaskList;
