import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";
import Select from 'react-select';
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
    PartyId: {
        isReq: "Party is required!",
    },
    CustomerName: {
        isReq: "Customer Name is required!",
    },
    CustomerCode: {
        isReq: "Customer Code is required!",
    },
    CustomerType: {
        isReq: "Customer Type is required!",
    },
    StartDate: {
        isReq: "StartDate is required!",
    },
};

class AddCustomerInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            autoloading: false,
            formSubmit: false,
            fields: {
                TenantId: crm.userInfo().pTenantId,
                OrgId: crm.userInfo().pOrgId,
                PartyId: 0,
                StartDate: new Date(),
                Active: "Y",
                EndDate: null,
                CustomerName: '',
                CustomerCode: '',
                CustomerRating: 0,
                CustomerType: ""
            },
            commonResource: {
                CustomerId: 0,
            },
            autoFields: {
                PartyIdSearchTerm: "",
            },
            masterData: {
                partyData: [],
                typeOfCustomer: [],
            },
            errors: {},
            userPermissions: common.getPermission(this.props, "vendor:customers"),
        };
    }

    componentDidMount() {
        const { commonResource } = this.state;

        this.defineInitErrorMsg();
        this.getLookUpData('CUSTOMER_TYPE', 'typeOfCustomer');

        if (this.props.match.params.customerId != undefined) {

            commonResource.CustomerId = this.props.match.params.customerId ? this.props.match.params.customerId : 0;

            this.setState(
                {
                    commonResource: commonResource,
                },
                () => {
                    const { commonResource } = this.state;
                    if (commonResource.CustomerId > 0) {
                        this.getDetailById(commonResource.CustomerId);
                    }
                }
            );
        }
    }

    /**
     * Initialize error message
     */
    defineInitErrorMsg = () => {
        const { errors, fields } = this.state;
        for (var key of Object.keys(defErrs)) {
            switch (key) {
                case "Active":
                    if (fields[key] == "N") {
                        errors[key] = defErrs[key].isReq;
                    } else {
                        errors[key] = "";
                    }
                    break;
                default:
                    if (fields[key] == null || fields[key] == "") {
                        errors[key] = defErrs[key].isReq;
                    }
            }
        }
        this.setState({
            errors: errors,
        });
    };


    /**
     * Get lookup values by lookup code
     */
    getLookUpData = (lookupValue, source) => {
        const { masterData } = this.state;

        let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
        let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    masterData[source] = common.getOptionsData(response.items, "Name", "Code");
                    this.setState({
                        masterData: masterData,
                    });
                }
            },
            (error) => { }
        );
    };


    /**
     * Redirect to customer list page
     */
    onCancel = () => {
        history.push("/setup/vendor/customer");
    }

    /**
     * Handle to change input values
     */
    handleAutoChange = (event, selectedValue) => {
        const { id } = event.target;
        const { fields } = this.state;
        var splitId = id.split("-");

        fields[splitId[0]] = selectedValue.PartyId;
        //fields.SiteName = selectedValue.PartyName;
        //fields.SiteCode = selectedValue.PartyCode;

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(splitId[0], selectedValue.PartyId);
            }
        );
    };

    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(name, inputValue);
            }
        );
    };

    selectHandleOnChange = (event, name) => {
        const { value } = event;
        const { fields } = this.state;

        if (fields.hasOwnProperty(name)) {
            fields[name] = value;
        }
        this.setState({ fields: fields }, () => {
            this.validateForm(name, value);
        });
    }


    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (defErrs.hasOwnProperty(name)) {
                errors[name] = defErrs[name].isReq;
            }
        } else {
            // Optional Validation Here
            switch (name) {
                default:
                    errors[name] = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    /**
   * Handle to get changed search terms and get list based on terms
   */
    handleInputChange = (event, terms = "") => {
        const { masterData, autoFields, fields } = this.state;
        masterData.partyData = [];

        const { id } = event.target;
        var splitId = id.split("-");

        autoFields[splitId[0] + "SearchTerm"] = terms;
        if (terms.length === 0) {
            fields[splitId[0]] = "";
            this.validateForm(splitId[0], fields[splitId[0]]);
        }

        this.setState({
            autoloading: true,
            masterData: masterData,
            autoFields: autoFields,
            fields
        });

        let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(PartyName) LIKE '*${terms}*' &limit=10&offset=0&totalResults=true`;
        let url = envConfig.BASE_API + Services.CRM_PARTY_HDR + filter;

        restServices.getRequest(
            encodeURI(url),
            (response) => {
                if (response) {
                    let data = [];
                    response.items && response.items.length > 0 && response.items.map((item) => {
                        if (item.PartyName) {
                            data.push(item);
                        }
                    })
                    masterData.partyData =
                        (response.items && response.items.length > 0) ? data : [];
                    this.setState({
                        autoloading: false,
                        masterData: masterData,
                    });
                }
            },
            (error) => { }
        );
    };

    getDetailById = (id) => {
        const { fields, errors } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + id);
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    for (var key of Object.keys(response)) {
                        if (fields.hasOwnProperty(key)) {
                            if (response[key]) {
                                if (
                                    key === 'StartDate' ||
                                    key === 'EndDate'
                                ) {
                                    fields[key] = response[key] ? new Date(response[key]) : '';
                                } else {
                                    fields[key] = response[key];

                                }

                                // Remove Default Errors
                                if (errors.hasOwnProperty(key)) {
                                    // Optional Validation Here
                                    if (key === "Active") {
                                        if (response[key] === "Y") {
                                            errors[key] = "";
                                        } else {
                                            errors[key] = defErrs[key].isReq;
                                        }
                                    } else if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                                        errors[key] = "";
                                    }
                                }
                            }
                        }
                    }

                    this.setState(
                        {
                            fields: fields,
                            errors: errors,
                        },
                        () => {
                            if (fields.PartyId != null) {
                                this.getPartyDetailsById();
                            }
                        }
                    );
                } else { }
            },
            (error) => { }
        );
    };

    /**
     * Get details by Id
     */
    getPartyDetailsById = (type) => {
        const { fields, autoFields } = this.state;

        let filter = `?q=TenantId=${crm.userInfo().pTenantId};` +
            `PartyId=${fields.PartyId}`;
        let url = envConfig.BASE_API + Services.CRM_PARTY_HDR + filter;

        restServices.getRequest(
            url,
            (response) => {
                if (response) {

                    if (response && response.items.length > 0) {
                        autoFields['PartyIdSearchTerm'] = response.items[0].PartyCode;
                    }

                    this.setState({
                        autoFields: autoFields,
                    });
                }
            },
            (error) => { }
        );
    };

    handleRating = (rate) => {
        const { fields } = this.state;
        fields.CustomerRating = rate
        this.setState({
            fields: fields
        });
    }

    save = (event) => {
        event.preventDefault();

        const { errors, commonResource } = this.state;
        var isValid = true;

        this.setState({
            formSubmit: true,
        });

        for (var key of Object.keys(errors)) {
            if (!isValid) {
                break;
            }
            isValid = !errors[key];
        }

        if (isValid) {
            if (commonResource.CustomerId > 0) {
                this.update();
            } else {
                this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + commonResource.CustomerId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/customer");
                }
            },
            (error) => { }
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/customer");
                }
            },
            (error) => { }
        );
    };

    render() {
        const {
            fields,
            masterData,
            loading,
            autoloading,
            errors,
            formSubmit,
            autoFields,
            commonResource,
            userPermissions
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            !userPermissions.read || ((commonResource.CustomerId == 0 && !userPermissions.add) || (commonResource.CustomerId > 0 && !userPermissions.update)) ? (
                <AccessDenied />
            ) : (
                <div className="lead-wraper">
                    <Form onSubmit={this.save}>
                        <div className="formWhiteBg">
                            <div className="formHead">
                                <h2>Add Customer</h2>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Party Name*</Form.Label>
                                        <AutoComplete
                                            freeSolo
                                            id="PartyId"
                                            disableClearable
                                            options={masterData.partyData}
                                            loading={autoloading}
                                            getOptionLabel={(option) => option.PartyName}
                                            onChange={this.handleAutoChange}
                                            inputValue={autoFields.PartyIdSearchTerm}
                                            onInputChange={this.handleInputChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={"Search Party Name"}
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: "search",
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {autoloading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.PartyId !== "" && formSubmit === true && (
                                            <Form.Text className="error">
                                                {errors.PartyId}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Customer code*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            name="CustomerCode"
                                            onChange={(e) => this.handleOnChange(e)}
                                            value={fields.CustomerCode}
                                        />
                                        {errors.CustomerCode !== "" && formSubmit === true && (
                                            <Form.Text className="error">{errors.CustomerCode}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Customer Name*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            name="CustomerName"
                                            onChange={(e) => this.handleOnChange(e)}
                                            value={fields.CustomerName}
                                        />
                                        {errors.CustomerName !== "" && formSubmit === true && (
                                            <Form.Text className="error">{errors.CustomerName}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Start Date*</Form.Label>
                                        <DatePicker
                                            name="StartDate"
                                            value={fields.StartDate}
                                            onChange={(date) =>
                                                this.handleDateOnChange(date, 'StartDate')
                                            }
                                            format="dd/MM/yyyy"
                                            autoComplete="off"
                                            minDate={new Date()}
                                        />
                                        {errors.StartDate !== '' && formSubmit === true && (
                                            <Form.Text className="error">
                                                {errors.StartDate}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>End Date</Form.Label>
                                        <DatePicker
                                            name="EndDate"
                                            value={fields.EndDate}
                                            onChange={(date) =>
                                                this.handleDateOnChange(date, 'EndDate')
                                            }
                                            format="dd/MM/yyyy"
                                            autoComplete="off"
                                            minDate={new Date()}
                                        />
                                        {errors.EndDate !== '' && formSubmit === true && (
                                            <Form.Text className="error">
                                                {errors.EndDate}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>&nbsp; </Form.Label>
                                        <Checkbox
                                            name="Active"
                                            checked={isChecked(fields.Active)}
                                            label="Active"
                                            labelStyle={{ color: '#2d2d2d' }}
                                            labelPosition="left"
                                            onClick={(e) => this.handleOnChange(e)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Rating </Form.Label>
                                        <ul className="ratingList">
                                            <li className={fields.CustomerRating == 1 ? "active" : ""}>
                                                <Link to="#;" onClick={() => this.handleRating(1)}>1.0</Link>
                                            </li>
                                            <li className={fields.CustomerRating == 2 ? "active" : ""}>
                                                <Link to="#;" onClick={() => this.handleRating(2)}>2.0</Link>
                                            </li>
                                            <li className={fields.CustomerRating == 3 ? "active" : ""}>
                                                <Link to="#;" onClick={() => this.handleRating(3)}>3.0</Link>
                                            </li>
                                            <li className={fields.CustomerRating == 4 ? "active" : ""}>
                                                <Link to="#;" onClick={() => this.handleRating(4)}>4.0</Link>
                                            </li>
                                            <li className={fields.CustomerRating == 5 ? "active" : ""}>
                                                <Link to="#;" onClick={() => this.handleRating(5)}>5.0</Link>
                                            </li>
                                        </ul>
                                    </Form.Group>
                                </Col>

                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>Customer Type*</Form.Label>
                                        <div className="select-control">
                                            <Select
                                                value={masterData.typeOfCustomer.find(o => o.value === fields.CustomerType)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'CustomerType')}
                                                options={masterData.typeOfCustomer}
                                            />
                                        </div>

                                        {errors.CustomerType !== "" && formSubmit === true && (
                                            <Form.Text className="error">{errors.CustomerType}</Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>

                        <Row className="botBtn">
                            <Col sm={12}>
                                <Button className="saveBtn" type="submit">
                                    {commonResource.CustomerId > 0 ? "Update" : "Save"}
                                </Button>
                                <Button
                                    className="cancelBtn"
                                    type="button"
                                    onClick={this.onCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )
        );
    }
}


export { AddCustomerInfo };
