import { combineReducers } from "redux";
import Setup from './setup.reducers';
import { alert } from "./alert.reducers";
import {
  crmstatus,
  crmCampaignStatus,
  crmEnquiryStatus,
  crmDealStatus,
  crmQuoteStatus,
  crmOrderStatus,
  crmInvoiceStatus,
  crmRolesPermissions,
} from "./common.reducers";

const appReducer = combineReducers({
  Setup,
  alert,
  crmstatus,
  crmCampaignStatus,
  crmEnquiryStatus,
  crmDealStatus,
  crmQuoteStatus,
  crmOrderStatus,
  crmInvoiceStatus,
  crmRolesPermissions,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
