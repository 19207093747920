import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//Import CSS
import "./Vendor.css";
// Import Images
import LocationsImg from "img/hrms/locations.svg";
import PartyImg from "img/vendor/parties.svg";
import CustomerImg from "img/vendor/customers.svg";
import ProductImg from "img/vendor/products.png";

class Vendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="lead-wraper">
        <div className="secondary-nav">
          <ul className="secondary-nav-list">
            <li>
              <Link to="/setup/hrms">HRMS</Link>
            </li>
            <li>
              <Link to="/setup/configure">Configure</Link>
            </li>
            <li className="active">
              <Link to="/setup/vendor">Vendor</Link>
            </li>
          </ul>
        </div>
        {/* Secondary Nav end */}
        <h2 className="crm-type-title mb-20">Vendor</h2>
        <div className="crm-data-wrap">
          <ul className="row configure-list hrms-list">
            <li className="col-3">
              <Link to="/setup/vendor/location">
                <div className="card-layout text-center">
                  <img
                    src={LocationsImg}
                    alt="Locations"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Locations</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/vendor/party">
                <div className="card-layout text-center">
                  <img src={PartyImg} alt="Divisions" className="img-fluid" />
                  <h5 className="setting-name">Parties</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/vendor/customer?tab=sites">
                <div className="card-layout text-center">
                  <img
                    src={CustomerImg}
                    alt="Designations"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Customers</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
              </Link>
            </li>
            <li className="col-3">
              <Link to="/setup/vendor/product">
                <div className="card-layout text-center">
                  <img
                    src={ProductImg}
                    alt="Designations"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Product Master</h5>
                  <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const styles = {
  menuSelectbox: {
    borderBottom: "none",
  },
};

const mapStateToProps = (state) => {
  const { alert } = state;
  return { alert };
};
export default connect(mapStateToProps)(Vendor);
