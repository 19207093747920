import React from "react";
import { Link } from "react-router-dom";
import {
	Navbar,
	Nav,
	Form,
	Row,
	Col,
	ListGroup,
	InputGroup,
	FormControl,
	Button,
} from "react-bootstrap";

//Import CSS
import "../Signup/Signup.css";
import "./Pricing.css";
import LandingHeader from "../../_components/_landingHeader/landingHeader";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices } from "_helpers";

class Pricing extends React.Component {
	render() {
		return (
			<div className="content-wraper">
				<div className="signup-top-wrap">
					<LandingHeader />

					<div className="signup-wrap">
						<div className="container">
							<div className="row">
								<div className="col-md-3 pricing-left">
									<div className="title-section mb-5 text-center">
										<h2 className="lp-title">Pricing</h2>
										<p className="lp-description">
											Our CRM free trial uses our Professional plan with the
											Marketing tool activated, so you can best see how the CRM
											could work for you.
										</p>
									</div>
								</div>
								<div className="signup-form-wrap col-md-9">
									<div className="row">
										<div className="col-md-3 plan-container">
											<div className="plan-header free-plan">
												<h2 className="plan-title">Free</h2>
												<p className="price">(Up to 3 User)</p>
											</div>
											<div className="plan-features">
												<ListGroup variant="flush">
													<ListGroup.Item>$0 Up to 3 Users</ListGroup.Item>
													<ListGroup.Item>Leads Management</ListGroup.Item>
													<ListGroup.Item>Can add up 5 Fields</ListGroup.Item>
													<ListGroup.Item>Centralised Emails</ListGroup.Item>
													<ListGroup.Item>
														Basic Dashboards and Reports
													</ListGroup.Item>
													<ListGroup.Item>
														5 Automations for All Modules
													</ListGroup.Item>
												</ListGroup>
											</div>
										</div>

										<div className="col-md-3 plan-container">
											<div className="plan-header essential-plan">
												<h2 className="plan-title">Essentials</h2>
												<p className="price">($10/ user up to 10 Users)</p>
											</div>
											<div className="plan-features">
												<ListGroup variant="flush">
													<ListGroup.Item>100 Emails/ day</ListGroup.Item>
													<ListGroup.Item>15 Fields/ module</ListGroup.Item>
													<ListGroup.Item>
														10 Automations per Module
													</ListGroup.Item>
													<ListGroup.Item>Customisable</ListGroup.Item>
													<ListGroup.Item>
														3 User Level Permissions (Up to 3 Levels)
													</ListGroup.Item>
													<ListGroup.Item>
														10 Customisable Dashboards
													</ListGroup.Item>
													<ListGroup.Item>
														10 Customisable Reports
													</ListGroup.Item>
													<ListGroup.Item>
														14 days Customer Support to Set up
													</ListGroup.Item>
												</ListGroup>
											</div>
										</div>

										<div className="col-md-3 plan-container">
											<div className="plan-header intermediate-plan">
												<h2 className="plan-title">Intermediate</h2>
												<p className="price">($15/ User)</p>
											</div>
											<div className="plan-features">
												<ListGroup variant="flush">
													<ListGroup.Item>500 Emails/ day</ListGroup.Item>
													<ListGroup.Item>
														Addons and Integrations
													</ListGroup.Item>
													<ListGroup.Item>25 Custom Modules</ListGroup.Item>
													<ListGroup.Item>
														20 Automations/ Module
													</ListGroup.Item>
													<ListGroup.Item>
														Unlimited User Level Permissions
													</ListGroup.Item>
													<ListGroup.Item>
														Unlimited Customisable Reports
													</ListGroup.Item>
													<ListGroup.Item>
														28 days Customer Support
													</ListGroup.Item>
												</ListGroup>
											</div>
										</div>
										<div className="col-md-3 plan-container">
											<div className="plan-header professional-plan">
												<h2 className="plan-title">Professional</h2>
												<p className="price">($25 per User)</p>
											</div>
											<div className="plan-features">
												<ListGroup variant="flush">
													<ListGroup.Item>1,000 Emails/ day</ListGroup.Item>
													<ListGroup.Item>50 Custom Modules</ListGroup.Item>
													<ListGroup.Item>Calendar Management</ListGroup.Item>
													<ListGroup.Item>
														Advanced Leads Management
													</ListGroup.Item>
													<ListGroup.Item>
														Field Level Permissions
													</ListGroup.Item>
													<ListGroup.Item>Dynamic Dashboards</ListGroup.Item>
													<ListGroup.Item>
														50 Workflow Automations
													</ListGroup.Item>
													<ListGroup.Item>Task Management</ListGroup.Item>
													<ListGroup.Item>24/5 Customer Support</ListGroup.Item>
												</ListGroup>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Pricing;
