import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history } from "_helpers";

import '../css/PayElement.css';

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// AutoComplete
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";

class PayElementAdd extends React.Component {
    constructor(props) {
        super(props);
        this.params = this.props.params;
        this.state = {
            isLoading: false,
            formSubmit: false,
            fields: {
                Year: "",
                StartDate: new Date(),
                EndDate: null,
                Active: "Y",
                Category: '',
                ExpenseCategory: '',
                DepandOn: '',
                EarningDeduction: '',
                LinkedPerformance: ''
            },
            errors: {
                Year: {
                    error: "",
                    isReq: "Year is required!",
                },
                StartDate: {
                    error: "",
                    isReq: "Start Date is required!",
                },
                EndDate: {
                    error: "",
                    isReq: "End Date is required!",
                }
            },
            masterData: {
            },
            categoryData: [{label: "Select", value: ""}],
            expCategoryData: [{label: "Select", value: ""}],
            depandOnData: [{label: "Select", value: ""}],
            earningDeductionData: [{label: "Select", value: ""}],
            linkedPerformanceData: [{label: "Select", value: ""}],
            commonResource: {
                CustomerId: 0,
            },
        };
    }

    // life cycle
    componentDidMount() {
    }

    selectHandleOnChange = (event, type) => {
        const { label, value } = event;
        const { fields } = this.state;
        if (fields.hasOwnProperty(type)) {
          fields[type] = value;
        }
    
        this.setState({
            fields: fields,
        }, () => {
            this.validateForm(type, value);
        });
    }

    // functions

    // validation
    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (errors.hasOwnProperty(name)) {
                errors[name].error = errors[name].isReq;
            }
        } else {
            switch (name) {
                default:
                    errors[name].error = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    validateAll() {
        const { fields, errors } = this.state;
        let isValid = true;
        for (var key of Object.keys(errors)) {
            if (fields[key] == "" || fields[key] == null) {
                errors[key].error = errors[key].isReq;
                isValid = false;
            }
        }
        this.setState({
            errors
        });

        return isValid;
    }

    // event
    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(name, inputValue);
            }
        );
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    // rest services
    onSubmit = (event) => {
        event.preventDefault();
        const { commonResource } = this.state;

        this.setState({
            formSubmit: true,
        });

        let isValid = this.validateAll();

        if (isValid) {
            if (commonResource.CustomerId > 0) {
                // this.update();
            } else {
                // this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + commonResource.CustomerId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/hrms/payelement");
                }
            },
            (error) => {}
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/hrms/payelement");
                }
            },
            (error) => {}
        );
    };

    // support
    yearList() {
        let yearList = [];
        for (let i = 1988; i <= new Date().getFullYear(); i++) {
            yearList.push(i);
        }
        return yearList;
    };

    onCancel = () => {
        history.push("/hrms/payelement");
    }

    render() {
        const {
            fields,
            errors,
            formSubmit,
            categoryData,
            expCategoryData,
            depandOnData,
            earningDeductionData,
            linkedPerformanceData,
            // data
            masterData,
            commonResource,
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            <div className="lead-wraper">
                <h2>Add Pay Element</h2>
                <Form onSubmit={this.save}>
                    <div>
                        {/* Party Information */}
                        <Row className="whiteBgRow" style={{ marginTop: 28 }}>
                            <Col sm={12} className="formWhiteBg">
                                <div className="formHead">
                                    <h2>Pay Element Information</h2>
                                </div>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Element Code*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Description*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                    <Form.Group>
                                            <Form.Label>Account Code*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                        </Col>
                                        <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Display Order*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                        </Col>
                                        </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Category*</Form.Label>
                                            <Select
                                                value={categoryData.find(o => o.value === fields.Category)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'Category')}
                                                options={categoryData}
                                            />
                                            {errors.Category !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.Category}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Expense Category*</Form.Label>
                                            <Select
                                                value={expCategoryData.find(o => o.value === fields.ExpenseCategory)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'ExpenseCategory')}
                                                options={expCategoryData}
                                            />
                                            {errors.PartyName !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyName}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Depand On*</Form.Label>
                                            <Select
                                                value={depandOnData.find(o => o.value === fields.DepandOn)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'DepandOn')}
                                                options={depandOnData}
                                            />
                                            {errors.DepandOn !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.DepandOn}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                            <Checkbox
                                                name="Active"
                                                checked={isChecked(fields.Active)}
                                                label="Paid Annualy"
                                                labelStyle={{ color: '#2d2d2d' }}
                                                labelPosition="left"
                                                onClick={(e) => this.handleOnChange(e)}
                                            />
                                            {errors.Active !== 'Y' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.Active}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Earning Deduction*</Form.Label>
                                            <Select
                                                value={earningDeductionData.find(o => o.value === fields.EarningDeduction)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'EarningDeduction')}
                                                options={earningDeductionData}
                                            />
                                            {errors.EarningDeduction !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.EarningDeduction}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Linked To Performance</Form.Label>
                                            <Select
                                                value={linkedPerformanceData.find(o => o.value === fields.LinkedPerformance)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'LinkedPerformance')}
                                                options={linkedPerformanceData}
                                            />
                                            {errors.LinkedPerformance !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.LinkedPerformance}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>                                    
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Start Date*</Form.Label>
                                            <DatePicker
                                                name="StartDate"
                                                value={fields.StartDate}
                                                onChange={(date) =>
                                                    this.handleDateOnChange(date, 'StartDate')
                                                }
                                                format="dd/MM/yyyy"
                                                autoComplete="off"
                                                minDate={new Date()}
                                            />
                                            {errors.StartDate !== '' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.StartDate}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>End Date*</Form.Label>
                                            <DatePicker
                                                name="EndDate"
                                                value={fields.EndDate}
                                                onChange={(date) =>
                                                    this.handleDateOnChange(date, 'EndDate')
                                                }
                                                format="dd/MM/yyyy"
                                                autoComplete="off"
                                                minDate={new Date()}
                                            />
                                            {errors.EndDate !== '' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.EndDate}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>                                                                        
                                    <Col sm={6}>
                                        <Form.Group>
                                        <Form.Label>&nbsp;</Form.Label>
                                            <Checkbox
                                                name="Active"
                                                checked={isChecked(fields.Active)}
                                                label="Active"
                                                labelStyle={{ color: '#2d2d2d' }}
                                                labelPosition="left"
                                                onClick={(e) => this.handleOnChange(e)}
                                            />
                                            {errors.Active !== 'Y' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.Active}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>                                
                            </Col>
                        </Row>

                       {/* Action section */}
                        <Row className="botBtn">
                            <Col sm={12}>
                                <Button className="saveBtn" type="submit">
                                    {/* {commonResource.PartyId > 0 ? "Update" : "Save"} */}
                  Save
                </Button>
                                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

export { PayElementAdd as PayElementAdd };
