import React from "react";
import Badge from "@material-ui/core/Badge";
import { Tabs, Tab } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-date-picker";
import moment from "moment";
import DynamicForm from "crm/DynamicForm";
import EduTechLead from "crm/EduTechLead";
import SalesTeam from "pages/_components/SalesTeam/SalesTeam";
import ProductServices from "pages/_components/ProductServices/ProductServices";
import Comments from "pages/_components/Comments/Comments";
import CallLogsList from "pages/_components/CallLogs/CallLogsList/CallLogsList";
import Meeting from "pages/_components/Meeting/Meeting";
import Attachments from "pages/_components/Attachments/Attachments";
import History from "../../../_components/History/pages/History";
import ContactLinks from "../../../_components/Contacts/ContactLinks";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import Loader from "_components/_loader/Loader";
import FieldSelect from "crm/FieldSelect";
import { Form, Col, Button, Row } from "react-bootstrap";
import RaisedButton from "material-ui/RaisedButton";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { common, restServices, eduTechSerivces, history } from "_helpers";

//Import CSS
import "../css/Lead.css";

const userInfo = common.authInfo();
class AddLead extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      crmStatusList: [],
      leadId: params.leadId ? params.leadId : 0,
      open: false,
      leadData: {},
      menuFocus: true,
      isModal: false,
      autoloading: false,
      isLoading: true,
      autoFields: {
        SalesPersonIdSearchTerm: "",
      },
      masterData: {
        OpportunityType: [],
        Priority: [],
        SalesPersonData: [
          {
            EmployeeId: userInfo.EmpId,
            EmployeeName: userInfo.FirstName + " " + userInfo.LastName,
          },
        ],
      },
      oppFields: {
        LeadId: 0,
        OpportunityName: "",
        OpportunityType: "",
        Priority: "",
        SalesPersonId: userInfo.EmpId,
        PhoneNumber: "",
        MobileNumber: "",
        OpportunityDescription: "",
        Email: "",
        TenantId: userInfo.TenantId,
        OrgId: userInfo.DefaultOrgId,
        ApprStatus: envConfig.EDUTECH_TENANT.includes(userInfo.TenantId) ? "New Application" : "NEW",
        TerritoryId: userInfo && userInfo.territory[0],
        CampaignId: null,
        ClosingDate: "",
        ExpectedRevenue: "",
        AccountId: "",
      },
      errors: {
        OpportunityName: {
          error: "",
          isReq: "Deal name is required",
        },
        OpportunityType: {
          error: "",
          isReq: "Please select the type",
        },
        Priority: {
          error: "",
          isReq: "Please select the priority",
        },
        // ExpectedRevenue: {
        //   error: "",
        //   isReq: "Amount is required",
        // },
        // ClosingDate: {
        //   error: "",
        //   isReq: "Please select the Closing Date",
        // },
        // CampaignId: {
        //   error: "",
        //   isReq: "Please select the Campaign",
        // },
        AccountId: {
          error: "",
          isReq: "Please select the Customer Account",
        },
      },
      formSubmit: false,
      conversionProcess: false,
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.lead
      ),
      deletePermission: false,
      statusCode: "NEW",
      formFields: [],
      nextLead: 0,
    };
    this.state.currentTab = common.getParam("tab");
    let status = common.getParam("status");
    this.state.leadData["LeadStatus"] = status ? status : null;
  }

  componentDidMount() {
    this.getFormJSON();
    if (this.props.match.params.leadId) {
      this.setState(
        {
          leadId: this.props.match.params.leadId,
        },
        () => {
          this.getTransactionStatus();
          this.getLeadDetail(this.state.leadId);
        }
      );
    } else {
      this.setState({ isLoading: false });
    }
    this.getCRMStatusList();
  }

  /**
   * Get Lead Details by ID
   */
  getLeadDetail = (id) => {
    const { userPermissions } = this.state;
    let url = envConfig.BASE_API + Services.CRM_LEADS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let access = false;
          if (
            (userPermissions.update === envConfig.allPermissions[2].value &&
              response.SalespersonId == userInfo.EmpId) ||
            (userPermissions.update !== envConfig.allPermissions[1].value &&
              userPermissions.update !== envConfig.allPermissions[2].value)
          ) {
            access = true;
          }
          this.setState({
            deletePermission: access,
            leadData: response,
            isLoading: false,
          });
        }
      },
      (error) => {}
    );
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    let leadTypeId = common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID);

    let params =
      `?q=TransactionTypeId=${leadTypeId};OrgId=` +
      `${userInfo.DefaultOrgId};TenantId=` +
      `${userInfo.TenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items ? response.items : [],
          });
        }
      },
      (error) => {}
    );
  };

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  getStatusPosition = () => {
    const { leadData, crmStatusList } = this.state;
    if (leadData.LeadStatus != "" && leadData.LeadStatus != null) {
      var position = crmStatusList.findIndex(
        (x) => x.StatusCode === leadData.LeadStatus
      );
      return position > 0 ? position : 0;
    }
    return 0;
  };

  onDelete = () => {
    this.handleRequestClose();
    common.confirmDelete(false, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { leadId } = this.state;
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_LEADS + "/" + leadId;
    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Lead deleted successfully.");
        history.push("/crm/lead");
      },
      (error) => {}
    );
  };

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { oppFields, autoFields: autoFieds } = this.state;
    var splitId = id.split("-");
    oppFields[splitId[0]] = selectedValue.EmployeeId;
    this.setState(
      {
        oppFields: oppFields,
      },
      () => {
        this.validateForm(splitId[0], selectedValue.EmployeeId);
      }
    );
  };

  handleOnChange = (event) => {
    const { oppFields } = this.state;
    const { name, value } = event.target;

    if (oppFields.hasOwnProperty(name)) {
      oppFields[name] = value;
    }

    this.setState(
      {
        oppFields: oppFields,
      },
      () => {
        this.validateForm(name, value);
      }
    );
  };

  onSelect = (data, name) => {
    const { oppFields } = this.state;
    oppFields[name] = data;
    this.setState({ oppFields });
    this.validateForm(name, data);
  };

  onDateChange = (date, name) => {
    const { oppFields } = this.state;
    oppFields[name] = date;
    this.setState({ oppFields }, () => {
      this.validateForm(name, date);
    });
  };

  selectHandleOnChange = (event, type) => {
    const { label, value } = event;
    const { oppFields } = this.state;
    if (oppFields.hasOwnProperty(type)) {
      oppFields[type] = value;
    }

    this.setState(
      {
        oppFields: oppFields,
      },
      () => {
        this.validateForm(type, value);
      }
    );
  };


  closeModal() {
    this.setState({
      isModal: false,
    });
  }

  // convert to deal
  showDeal = async () => {
    this.handleRequestClose();
    const { leadId } = this.state;
    if (envConfig.EDUTECH_TENANT.includes(userInfo.TenantId)) {
      let url = envConfig.BASE_API + Services.CRM_LEADS + "/" + leadId;
      restServices.getRequest(
        url,
        (response) => {
          if (
            response.OpportunityConverted == null ||
            response.OpportunityConverted == "N"
          ) {
            if (response.LeadStatus == "Complete Lead") {
              this.showDealInfo();
            } else {
              common.snack("E", "Complete Lead only convertable to Deal");
            }
          } else {
            common.snack("E", "Lead already converted as Deal");
          }
        },
        (error) => {}
      );
    } else {
      this.showDealInfo();
    }
  };

  showDealInfo = () => {
    const { oppFields, leadData, statusCode, formFields } = this.state;
    if (
      leadData.OpportunityConverted == null ||
      leadData.OpportunityConverted == "N"
    ) {
      let opTypeData = formFields.fields.filter((data, idx) => {
        return data.key == "OpportunityType";
      });

      let priorityData = formFields.fields.filter((data) => {
        return data.key == "Priority";
      });

      let opType = opTypeData[0].attr && opTypeData[0].attr.option;
      let priority = priorityData[0].attr && priorityData[0].attr.option;
      let OpportunityType = [];
      let Priority = [];
      opType.map((data) => {
        OpportunityType.push({ label: data, value: data });
      });
      priority.map((data) => {
        Priority.push({ label: data, value: data });
      });

      oppFields.TerritoryId = leadData.TerritoryId ? leadData.TerritoryId : 0;
      oppFields.CampaignId = leadData.CampaignId ? leadData.CampaignId : 0;
      oppFields.OpportunityName = leadData.Cattr1;
      oppFields.OpportunityDescription = leadData.Description
        ? leadData.Description
        : "";
      oppFields.MobileNumber = leadData.Mobile ? leadData.Mobile : "";
      oppFields.PhoneNumber = leadData.Phone ? leadData.Phone : "";
      oppFields.Email = leadData.Email ? leadData.Email : "";
      console.log("userInfo.TenantId", userInfo.TenantId);
      oppFields.ApprStatus =
      envConfig.EDUTECH_TENANT.includes(userInfo.TenantId) ? "New Application" : statusCode;
      this.setState({
        isModal: true,
        oppFields,
        masterData: {
          ...this.state.masterData,
          OpportunityType: OpportunityType,
          Priority: Priority,
        },
      });
    } else {
      common.snack("E", "Lead already converted as Deal");
    }
  };

  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_DEAL_CODE;

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = JSON.parse(response.items[0].FormFields);
          let fields = result.form;
          this.setState({
            formFields: fields,
          });
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  getTransactionStatus() {
    let TransitionTypeId = common.getTransactionTypeId(
      envConfig.DEAL_TRANS_TYPE_ID
    );
    let OrgId = userInfo.DefaultOrgId;
    let TenantId = userInfo.TenantId;

    let params =
      `?q=TransactionTypeId=${TransitionTypeId};OrgId=` +
      `${OrgId};TenantId=` +
      `${TenantId};Active=Y` +
      `&limit=1&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(url, (response) => {
      if (response) {
        this.setState({
          statusCode: response.items[0].StatusCode,
        });
      }
    });
  }

  convertToDeal = (e) => {
    e.preventDefault();
    this.setState({ formSubmit: true });
    let isValid = this.validateAll();
    const { oppFields, leadData, leadId } = this.state;
    if (isValid) {
      this.setState({
        formSubmit: false,
        conversionProcess: true,
      });
      var createParams = {
        name: "convertLeadToOpportunity",
        parameters: [
          { P_LEAD_ID: leadId.toString() },
          { P_OPPORTUNITY_NAME: oppFields.OpportunityName },
          { P_OPPORTUNITY_TYPE: oppFields.OpportunityType },
          { P_PRIORITY: oppFields.Priority },
          {
            P_SALES_PERSON_ID: leadData.SalespersonId
              ? leadData.SalespersonId
              : oppFields.SalesPersonId,
          },
          { P_PHONE_NUMBER: oppFields.PhoneNumber },
          { P_MOBILE_NUMBER: oppFields.MobileNumber },
          { P_OPPORTUNITY_DESC: oppFields.OpportunityDescription },
          { P_EMAIL: oppFields.Email },
          { P_APPR_STATUS: oppFields.ApprStatus },
          {
            P_TERRITORY_ID: leadData.TerritoryId
              ? leadData.TerritoryId
              : oppFields.TerritoryId,
          },
          {
            P_CAMPAIGN_ID: oppFields.CampaignId
              ? oppFields.CampaignId.value > 0
                ? parseInt(oppFields.CampaignId.value)
                : ""
              : "",
          },
          // {
          //   P_CLOSING_DATE: oppFields.ClosingDate
          //     ? common.formatDate(oppFields.ClosingDate, "YYYY-MM-DDTHH:mm:ssZ")
          //     : null,
          // },
          {
            P_EXPECTED_REVENUE: oppFields.ExpectedRevenue
              ? oppFields.ExpectedRevenue
              : "",
          },
          {
            P_CUSTOMER_ID: oppFields.AccountId
              ? parseInt(oppFields.AccountId.value)
              : "",
          },
        ],
      };

      // if(oppFields.PhoneNumber){
      //   createParams.parameters.push({ P_PHONE_NUMBER: oppFields.PhoneNumber });
      // }

      // if(oppFields.MobileNumber){
      //   createParams.parameters.push({ P_MOBILE_NUMBER: oppFields.MobileNumber });
      // }

      if (oppFields.ClosingDate) {
        createParams.parameters.push({
          P_CLOSING_DATE: common.formatDate(
            oppFields.ClosingDate,
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
        });
      }

      // if(oppFields.CampaignId && oppFields.CampaignId.value > 0){
      //   createParams.parameters.push({ P_CAMPAIGN_ID: parseInt(oppFields.CampaignId.value) });
      // }

      // if(oppFields.ExpectedRevenue){
      //   createParams.parameters.push({ P_EXPECTED_REVENUE: oppFields.ExpectedRevenue });
      // }

      // if(oppFields.AccountId && oppFields.AccountId.value > 0){
      //   createParams.parameters.push({ P_CUSTOMER_ID: parseInt(oppFields.AccountId.value) });
      // }
      console.log("createParams", createParams);
      let url = encodeURI(
        envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
      );
      restServices.postRequest(
        url,
        createParams,
        (response) => {
          if (response.status >= 200 && response.status <= 300) {
            if(envConfig.EDUTECH_TENANT.includes(userInfo.TenantId)){
              let url =
              envConfig.BASE_API +
              Services.CRM_DEAL_OPPORTUNITY +
              "?q=LeadId=" +
              leadId;
              restServices.getRequest(
                url,
                (response) => {
                  if (response.items.length > 0) {
                    let dealId = response.items[0].OpportunityId;
                    eduTechSerivces.updateApplicationInfo(
                      leadData.Cattr1,
                      {dealId : dealId}
                    );
                  }
                },
                (error) => {}
              );
            }
            common.snack("S", "Successfully Converted as Deal");
            leadData.OpportunityConverted = "Y";
            this.setState({
              isModal: false,
              leadData,
              conversionProcess: false,
            });
          }
        },
        (error) => {
          common.snack("E", error);
          this.setState({
            isModal: false,
            conversionProcess: false,
          });
        }
      );
    }
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (errors.hasOwnProperty(name)) {
        errors[name].error = errors[name].isReq;
      }
    } else {
      if (errors.hasOwnProperty(name)) {
        errors[name].error = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  validateAll() {
    const { oppFields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      console.log("oppFields[key] :>> ", oppFields[key]);
      if (oppFields[key] == "" || oppFields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  saveStatus = (status) => {
    const { leadData } = this.state;
    leadData.LeadStatus = status;
    this.setState({
      leadData,
    });
  };

  /**
   * Tab onclick event
   */
  handleSelect(key) {
    let url = window.location.pathname;
    let queryParam = window.location.search;
    if (queryParam.includes("tab=")) {
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab=" + tab, "tab=" + key);
    }
    history.push(url + queryParam);
  }

  saveCallback = (res) => {
    history.push("/crm/lead/edit/" + res.LeadId + common.getCrmReturnQuery());
    this.setState({
      leadId: res.LeadId,
      leadData: res,
    });
  };

  cancelCallBack = () => {
    history.push("/crm/lead");
  };

  gotoLead = (leadId) => {
    history.push("/crm/lead/edit/" + leadId + common.getCrmReturnQuery());
    this.setState({ isLoading: true });
    if (leadId) {
      this.setState(
        {
          leadId,
        },
        () => {
          this.getTransactionStatus();
          this.getLeadDetail(leadId);
        }
      );
    }
  };

  render() {
    const {
      crmStatusList,
      currentTab,
      menuFocus,
      masterData,
      oppFields,
      leadData,
      isModal,
      errors,
      formSubmit,
      isLoading,
      leadId,
      userPermissions,
      conversionProcess,
      deletePermission,
    } = this.state;
    const authData = () => {
      return { label: userInfo.EmployeeName, value: userInfo.EmpId.toString() };
    };
    //validate permissions
    let access =
      leadId == 0
        ? userPermissions.add != "2"
          ? true
          : false
        : common.evalPermissions(
            userPermissions.update,
            leadData.TerritoryId,
            leadData.SalespersonId
          );
    const isEduTech = envConfig.EDUTECH_TENANT.includes(userInfo.TenantId);
    return (
      <div>
        {!isLoading && !access ? (
          <AccessDenied />
        ) : (
          !isLoading && (
            <div
              className={isEduTech ? "lead-wraper footer-fixed" : "lead-wraper"}
            >
              <div className="secondary-head">
                <h1>{leadData.LeadName ? leadData.LeadName : "Add Lead"}</h1>
              </div>

              <div className="formTab">
                <ul>
                  {crmStatusList.map((data, idx) => (
                    <li
                      key={idx}
                      onClick={() => this.saveStatus(data.StatusCode)}
                      className={`tabShape whiteTab 
                      ${this.getStatusPosition() >= idx ? "active" : ""}`}
                    >
                      {" "}
                      <span className="tri-right"></span>
                      <Badge badgeContent={++idx} color="primary">
                        {data.StatusName}
                      </Badge>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="formTabSection position-r">
                <Tabs
                  defaultActiveKey={currentTab ? currentTab : "general"}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey="general" title="General">
                    {isEduTech ? (
                      <EduTechLead
                        data={{
                          value1: leadId > 0 ? leadId : "",
                          leadData: leadData,
                        }}
                        onSave={this.saveCallback}
                      />
                    ) : (
                      <DynamicForm
                        module="lead"
                        data={{
                          value1: leadId > 0 ? leadId : "",
                        }}
                        default={{
                          SalespersonId: authData(),
                        }}
                        userPermissions={userPermissions}
                        onSave={this.saveCallback}
                        onCancel={this.cancelCallBack}
                      />
                    )}
                    {/* <AddSampleLead
                    leadID={leadId}
                    accessPermission={userPermissions}
                    status={leadData.LeadStatus}
                    deptEmployees={this.props.deptEmployees} /> */}
                  </Tab>
                  <Tab
                    eventKey="contact"
                    title="Contacts"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <ContactLinks
                        objectId={leadId}
                        objectType={common.getObjectNameByPage("lead")}
                      />
                    )}
                  </Tab>
                  {/* <Tab
                    eventKey="salesteam"
                    title="Sales Team"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <SalesTeam objectId={leadId} objectType="lead" />
                    )}
                  </Tab> */}
                  <Tab
                    eventKey="products"
                    title="Products"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <ProductServices objectId={leadId} objectType="lead" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="comments"
                    title="Notes"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <Comments objectId={leadId} objectType="CRM_LEADS" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="call"
                    title="Call Logs"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <CallLogsList objectId={leadId} objectType="lead" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="meetings"
                    title="Meetings"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <Meeting objectId={leadId} objectType="lead" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="attachments"
                    title="Attachments"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <Attachments objectId={leadId} objectType="lead" />
                    )}
                  </Tab>
                  <Tab
                    eventKey="history"
                    title="History"
                    disabled={leadId == 0 ? "disabled" : ""}
                  >
                    {!isLoading && leadId > 0 && (
                      <History campaign={leadData} />
                    )}
                  </Tab>
                </Tabs>
                <div className="action-btn-wrap position-a action-btn-position">
                  <RaisedButton
                    disabled={leadId === 0}
                    onClick={this.handleClick}
                    label="Action"
                  />
                  <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    targetOrigin={{ horizontal: "left", vertical: "top" }}
                    onRequestClose={this.handleRequestClose}
                  >
                    <Menu autoFocusItem={menuFocus}>
                      <MenuItem
                        primaryText="Convert to Deal"
                        onClick={() => this.showDeal()}
                      />
                      {deletePermission && (
                        <MenuItem
                          primaryText="Delete"
                          onClick={() => this.onDelete()}
                        />
                      )}
                    </Menu>
                  </Popover>

                  <Dialog
                    open={isModal}
                    aria-labelledby="responsive-dialog-title"
                    className="leave-dialog"
                  >
                    <DialogTitle
                      id="responsive-dialog-title"
                      className="popup-title"
                    >
                      {"Convert Deal"}
                    </DialogTitle>
                    <DialogContent>
                      <div className="popup-form-wrap">
                        <div className="list-row">
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Lead Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="LeadId"
                                  disabled
                                  value={leadData.LeadName}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="OpportunityDescription"
                                  value={oppFields.OpportunityDescription}
                                  onChange={(e) => this.handleOnChange(e)}
                                  maxLength={4000}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Priority*</Form.Label>
                                <div className="select-control">
                                  <Select
                                    value={masterData.Priority.find(
                                      (o) => o.value === oppFields.Priority
                                    )}
                                    onChange={(e) =>
                                      this.selectHandleOnChange(e, "Priority")
                                    }
                                    options={masterData.Priority}
                                  />
                                </div>
                                {errors.Priority.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.Priority.error}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="MobileNumber"
                                  value={oppFields.MobileNumber}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Deal Type*</Form.Label>
                                <div className="select-control">
                                  <Select
                                    value={masterData.OpportunityType.find(
                                      (o) =>
                                        o.value === oppFields.OpportunityType
                                    )}
                                    onChange={(e) =>
                                      this.selectHandleOnChange(
                                        e,
                                        "OpportunityType"
                                      )
                                    }
                                    options={masterData.OpportunityType}
                                  />
                                </div>
                                {errors.OpportunityType.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.OpportunityType.error}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="PhoneNumber"
                                  value={oppFields.PhoneNumber}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Deal Name*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="OpportunityName"
                                  value={oppFields.OpportunityName}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                                {errors.OpportunityName.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.OpportunityName.error}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="Email"
                                  value={oppFields.Email}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Closing Date</Form.Label>
                                <DatePicker
                                  name="ClosingDate"
                                  value={
                                    oppFields.ClosingDate
                                      ? moment(oppFields.ClosingDate).toDate()
                                      : null
                                  }
                                  minDate={moment().toDate()}
                                  onChange={(date) =>
                                    this.onDateChange(date, "ClosingDate")
                                  }
                                  format="dd/MM/yyyy"
                                  autoComplete="off"
                                />
                                {/* {errors.ClosingDate.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.ClosingDate.error}
                                    </Form.Text>
                                  )} */}
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                  type="number"
                                  name="ExpectedRevenue"
                                  value={oppFields.ExpectedRevenue}
                                  onChange={(e) => this.handleOnChange(e)}
                                ></Form.Control>
                                {/* {errors.ExpectedRevenue.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.ExpectedRevenue.error}
                                    </Form.Text>
                                  )} */}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Campaign</Form.Label>
                                <FieldSelect
                                  api="CRM_CAMPAIGNS"
                                  get="CampaignName"
                                  set="CampaignId"
                                  value={oppFields.CampaignId}
                                  isMulti={false}
                                  isRender={true}
                                  onSelect={(data) =>
                                    this.onSelect(data, "CampaignId")
                                  }
                                />
                                {/* {errors.CampaignId.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.CampaignId.error}
                                    </Form.Text>
                                  )} */}
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group>
                                <Form.Label>Customer Account*</Form.Label>
                                <FieldSelect
                                  api="CRM_CUSTOMER"
                                  get="CustomerName"
                                  set="CustomerId"
                                  value={oppFields.AccountId}
                                  isMulti={false}
                                  isRender={true}
                                  onSelect={(data) =>
                                    this.onSelect(data, "AccountId")
                                  }
                                />
                                {errors.AccountId.error !== "" &&
                                  formSubmit === true && (
                                    <Form.Text className="error">
                                      {errors.AccountId.error}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions className="popup-footer justify-content-start">
                      <Row className="botBtn">
                        <Col sm={12}>
                          <Button
                            variant="primary"
                            className="saveBtn"
                            onClick={(e) => this.convertToDeal(e)}
                            disabled={conversionProcess}
                          >
                            {/* Convert */}
                            {conversionProcess ? "Processing..." : "Convert"}
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => this.closeModal(false)}
                            className="cancelBtn btn btn-primary"
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          )
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default AddLead;
