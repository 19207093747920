import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';
// Import CSS file
import "./AddProductServices.css";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// includes
import { crm } from '_utils';
// AutoComplete
import AutoComplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

// Defined Errors
const defErrs = {
  ProductType: {
    isReq: "Line type is required!",
  },
  ProductId: {
    isReq: "Item number is required!",
  }
};

class AddProductServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        ProductId: 0,
        ProductType: "",
        ExpRevenue: "",
        Uom: "",
        Currency: "",
        Qty: "",
        Description: "",
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId
      },
      autoFieds: {
        ProductIdSearchTerm: ""
      },
      commonResource: {
        ItemName: "",
        ItemNumber: "",
        objectType: "",
        objectId: 0,
        redirect: "",
      },
      masterData: {
        productTypes: [],
        itemData: [],
        uomData: [],
        currencyData: [],
      },
      errors: {}
    };
  }

  componentDidMount() {
    const { commonResource } = this.state;
    this.defineInitErrorMsg();
    //get lookup value
    this.getLookUpData("CHOLA_LINE_TYPE", "productTypes");
    this.getLookUpData("CURRENCIES", "currencyData");
    this.getLookUpData("UOM", "uomData");

    if (this.props.match.params.objectId != undefined) {
      commonResource.objectId = this.props.match.params.objectId;
      commonResource.productId = this.props.match.params.productId ? this.props.match.params.productId : 0;
      commonResource.redirect = this.props.match.params.objectType;
      this.setState({
        commonResource: commonResource
      }, () => {
        if (commonResource.productId > 0) {
          this.getDetailById(commonResource.productId)
        }
        commonResource.objectType = common.getObjectNameByPage(this.state.redirect);
        if (this.state.redirect != "") {
          this.setState({
            commonResource: commonResource,
          })
        }
      });

    }
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to parent page
   */
  onCancel = () => {
    // const { commonResource } = this.state;
    // history.push("/" + commonResource.redirect + "/edit/" + commonResource.objectId + "?tab=products");
    history.goBack();
  }

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { fields, autoFieds, commonResource } = this.state;
    var splitId = id.split("-");

    //get selected item details and assign to fields, commonResource
    fields[splitId[0]] = selectedValue.ItemId;
    commonResource.ItemNumber = selectedValue.ItemNumber;
    commonResource.ItemName = selectedValue.ItemName;
    fields.Uom = selectedValue.PrimaryUom;
    fields.Description = selectedValue.Description;
    fields.Currency = selectedValue.Currency;

    this.setState({
      fields: fields,
      commonResource: commonResource
    }, () => {
      this.validateForm(splitId[0], selectedValue.ItemId);
    });

  };

  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState({ fields: fields }, () => {
      this.validateForm(name, value);
    });
  }

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { masterData, autoFieds, fields } = this.state;
    masterData.itemData = [];

    const { id } = event.target;
    var splitId = id.split("-");

    autoFieds[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }

    this.setState({
      autoloading: true,
      masterData,
      autoFieds,
      fields
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(ItemName) LIKE '*${terms}*' &limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.ITEM_MATSER + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          masterData.itemData = response.items && response.items.length ? response.items : [];
          this.setState({
            autoloading: false,
            masterData: masterData
          });
        }
      },
      (error) => { }
    );
  }

  getDetailById = (id) => {
    const { commonResource, fields, errors } = this.state

    let url = encodeURI(this.getBaseURLByType() + "/" + id);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];
                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
              if (key === "LeadProId" || key === "OppProId") {
                fields.ProductId = response[key];
              }
            }
            if (key === "ItemName") {
              commonResource.ItemName = response[key];
            }
          }
          this.setState({
            fields: fields,
            commonResource: commonResource,
            errors: errors
          }, () => {
            this.getItemDetailsById()
          });

        } else { }
      },
      (error) => { }
    );
  }

  getItemDetailsById = () => {
    const { fields, autoFieds } = this.state

    let url = encodeURI(envConfig.BASE_API + Services.ITEM_MATSER + "/" + fields.ProductId);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          autoFieds["ProductIdSearchTerm"] = response.ItemNumber;
          this.setState({
            autoFieds: autoFieds
          })
        }
      },
      (error) => { }
    );
  }

  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;
    this.setState({ isLoading: true });

    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          masterData[source] = common.getOptionsData(response.items, "Name", "Code");
          this.setState({
            masterData: masterData
          });
        }
      },
      (error) => { }
    )
  };

  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.productId > 0) {
        this.update();
      } else {
        this.create();
      }
    }

  };

  getBaseURLByType = () => {
    const { commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        return envConfig.BASE_API + Services.CRM_DEALS_PRODUCTS
        break;
      case "lead":
        return envConfig.BASE_API + Services.CRM_LEADS_PRODUCTS
        break;
      default:
        break;
    }
  }

  update = () => {
    const { fields, commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        fields["OpportunityId"] = commonResource.objectId;
        break;
      case "lead":
        fields["LeadId"] = commonResource.objectId;
        break;
      default:
        break;
    }
    let url = encodeURI(this.getBaseURLByType() + "/" + commonResource.productId);
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/crm/" + commonResource.redirect + "/edit/" + commonResource.objectId + "?tab=products");
        }
      },
      (error) => { }
    )
  }

  create = () => {
    const { fields, commonResource } = this.state;

    switch (commonResource.redirect) {
      case "deal":
        fields["OpportunityId"] = commonResource.objectId;
        break;
      case "lead":
        fields["LeadId"] = commonResource.objectId;
        break;
      default:
        break;
    }
    let url = encodeURI(this.getBaseURLByType());
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push("/crm/" + commonResource.redirect + "/edit/" + commonResource.objectId + "?tab=products");
        }
      },
      (error) => { }
    )
  }

  render() {
    const {
      fields,
      masterData,
      loading,
      autoloading,
      commonResource,
      errors,
      formSubmit,
      autoFieds
    } = this.state;

    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>PRODUCT INFORMATION</h2>
            </div>

            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Line Type*</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.productTypes.find(o => o.value === fields.ProductType)}
                      onChange={(e) => this.selectHandleOnChange(e, 'ProductType')}
                      options={masterData.productTypes}
                    />
                  </div>
                  {errors.ProductType !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.ProductType}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Item Number*</Form.Label>
                  <AutoComplete
                    freeSolo
                    id="ProductId"
                    disableClearable
                    options={masterData.itemData}
                    loading={autoloading}
                    getOptionLabel={option => option.ItemName}
                    onChange={this.handleAutoChange}
                    inputValue={autoFieds.ProductIdSearchTerm}
                    onInputChange={this.handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Search Item Number"}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                  {errors.ProductId !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.ProductId}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Item Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="ItemName"
                    value={commonResource.ItemName}
                    onChange={(e) => this.handleOnChange(e)}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="Description"
                    value={fields.Description}
                    onChange={(e) => this.handleOnChange(e)}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>UOM</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.uomData.find(o => o.value === fields.Uom)}
                      onChange={(e) => this.selectHandleOnChange(e, 'Uom')}
                      options={masterData.uomData}
                    />
                  </div>
                  {errors.Uom !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.Uom}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type="text"
                    name="Qty"
                    value={fields.Qty}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <div className="select-control">
                    <Select
                      value={masterData.currencyData.find(o => o.value === fields.Currency)}
                      onChange={(e) => this.selectHandleOnChange(e, 'Currency')}
                      options={masterData.currencyData}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Expected Revenue</Form.Label>
                  <Form.Control
                    type="text"
                    name="ExpRevenue"
                    value={fields.ExpRevenue}
                    onChange={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {commonResource.productId > 0 ? "Update" : "Save"}
              </Button>
              <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default AddProductServices;
