import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Checkbox from "material-ui/Checkbox";
import '../css/PayElement.css';

class PayElementDetail extends React.Component {
    render() {

        return (
            <div>
                <Paper style={styles.boxLayout}>
                    <div className="box-title-wrap justify-content-between">
                        <h3 className="card-title">PAY ELEMENT INFORMATION</h3>
                        <Link className="card-link" to="#">
                            Change
                      </Link>
                    </div>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Element Code</label>
                                <div className="row set-default-row">
                                    <p className="form-value">Basic</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Description</label>
                                <p className="form-value">-</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Account Code</label>
                                <p className="form-value">-</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Display Order</label>
                                <p className="form-value">Marketing</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Category</label>
                                <p className="form-value">Basic</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Expense Category</label>
                                <p className="form-value">-</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Dependent On</label>
                                <p className="form-value">-</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                {/* <label className="form-label">Active</label> */}
                                <Checkbox
                                    name="Active"
                                    checked={true}
                                    label="Paid Annualy"
                                    labelStyle={{ color: '#2d2d2d' }}
                                    labelPosition="left"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Earning Deduction</label>
                                <p className="form-value">
                                    Earnings
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                {/* <label className="form-label">Active</label> */}
                                <Checkbox
                                    name="Active"
                                    checked={true}
                                    label="Linked To Perfomance"
                                    labelStyle={{ color: '#2d2d2d' }}
                                    labelPosition="left"
                                />
                            </div>
                        </Col>
                        <Col sm={6}>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                            <label className="form-label">Account Name</label>
                                <p className="form-value">
                                    -
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">Start Date</label>
                                <p className="form-value">
                                    25/04/2020
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <div className="list-row">
                                <label className="form-label">End Date</label>
                                <p className="form-value">
                                    -
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="list-row">
                                {/* <label className="form-label">Active</label> */}
                                <Checkbox
                                    name="Active"
                                    checked={true}
                                    label="Active"
                                    labelStyle={{ color: '#2d2d2d' }}
                                    labelPosition="left"
                                />
                            </div>
                        </Col>
                        <Col sm={6}>

                        </Col>
                    </Row>
                </Paper>

            </div>
        );
    }
}


const styles = {
    boxLayout: {
        width: "100%",
        padding: 20,
        marginBottom: 30,
    },
};

export default PayElementDetail;
