import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";

// Tabs Packages
import EmployeeDetail from "./Tabs/EmployeeDetail";
import EmployeeRoleList from "./Tabs/EmployeeRoleList";

// Import Images
import SearchIcon from "img/search-icn.png";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools } from "_helpers";

// Import Internal Component
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class EmployeeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      isDetailLoad: false,
      searchList: [],
      selectedEmp: {},
      divisionName: "",
      subDivisionName: "",
      sectionName: "",
      departmentName: "",
      designationName: "",
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      isQuery: false,
      userPermissions: common.getPermission(this.props, "hrms:employee"),
    };
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentTab = params.get("tab");
    this.state.currentTab = currentTab;
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let empId = parseInt(params.get("empId"));
      let empCode = params.get("empCode");
      let email = params.get("email");
      let filterName = params.get("filterName");
      let page = params.get("page");
      if (empId) {
        let selectedEmp = this.state.selectedEmp;
        let paging = this.state.paging;
        paging.page = parseInt(page);
        paging.size = 5;
        paging.total = 5;
        selectedEmp.EmployeeCode = empCode;
        selectedEmp.EmployeeId = empId;
        selectedEmp.Email = email;
        this.setState(
          {
            selectedEmp,
            filterName,
            paging,
            isQuery: true,
            isSearchLoad: true
          },
          (_) => {
            this.getList(true);
            this.handleClickList(this.state.selectedEmp);
          }
        );
      } else {
        this.getList();
      }
    }
  }

  /**
   * Methods
   */

  handleClickList = (emp) => {
    this.setState(
      {
        selectedEmp: emp,
      },
      (_) => {
        // const { selectedEmp } = this.state;
        // if (selectedEmp.DivisionId) {
        //   this.getDivisionData(selectedEmp.DivisionId);
        // }
        // if (selectedEmp.SubDivision) {
        //   this.getSubDivisionData(selectedEmp.SubDivision);
        // }
        // if (selectedEmp.Designation) {
        //   this.getDesignationData(selectedEmp.Designation);
        // }
        // if (selectedEmp.DepartmentId) {
        //   this.getDepartmentData(selectedEmp.DepartmentId);
        // }
        // this.getSectionData(selectedEmp.SectionId);
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState(
      {
        filterName: value,
      },
      () => {
        //reset page number to 1
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
      }
    );
  };

  /**
   * Get Division Data by ID
   */
  getDivisionData = (id) => {
    let divisionID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DIVISION + `/${divisionID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          divisionName: response ? response.DivisionName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Get Sub Division Data by ID
   */
  getSubDivisionData = (id) => {
    let subDivisionID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_SUB_DIVISION + `/${subDivisionID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          subDivisionName: response ? response.SubDivisionName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Get Section Data by ID
   */
  // getSectionData = (id) => {
  //   let sectionID = id ? id : 0;
  //   let url = encodeURI(
  //     envConfig.BASE_API + Services.HRMS_SECTIONS + `/${sectionID}`
  //   );
  //   restServices.getRequest(
  //     url,
  //     (response) => {
  //       this.setState({
  //         sectionName: response > 0 ? response.sectionName : [],
  //       });
  //     },
  //     (error) => {}
  //   );
  // };

  /**
   * Get Designation Data by ID
   */
  getDesignationData = (id) => {
    let designationID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DESIGNATIONS + `/${designationID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          designationName: response ? response.DesignationName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Get Designation Data by ID
   */
  getDepartmentData = (id) => {
    let departmentID = id ? id : 0;
    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_DEPARTMENTS + `/${departmentID}`
    );
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          departmentName: response ? response.DepartmentName : "",
        });
      },
      (error) => { }
    );
  };

  /**
   * Trigger Delete Employee List Popup
   * @param {*} id
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  };

  /**
   * Delete Employee by ID
   * @param {*} employeeId
   */
  handleClickDeleteList = (employeeId) => {
    const { paging } = this.state;

    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_EMPLOYEES + "/" + employeeId;
    /** TODO - User wants to delete */
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getList();
          }
        );
        common.snack("S", "Employee deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Service Request
   */
  getList(queryParam = false) {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (!queryParam && paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item);
      });
    }
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    let filter = '';
    if (userData && userData.role !== Services.CRM_DEFAULT_GROUPS_2) {
      filter = `?q=CreatedBy NOT IN ('CHOLAN') AND UPPER(EmployeeName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
        }&limit=${paging.size}`;

      if (queryParam) {
        filter = `?q=CreatedBy NOT IN ('CHOLAN') AND UPPER(EmployeeName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${paging.page * paging.size
          }`;
      }
    } else {
      filter = `?q=UPPER(EmployeeName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
        }&limit=${paging.size}`;

      if (queryParam) {
        filter = `?q=UPPER(EmployeeName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${paging.page * paging.size
          }`;
      }
    }

    let url = envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedEmp = this.state.selectedEmp;

        //common.snack("S", "Successfully fetched data");
        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let empId = params.get("empId");

          response.items.map((item, key) => {
            tempList.push(item);
            if (empId != undefined) {
              if (item.EmployeeId == parseInt(empId)) {
                selectedEmp = item;
              }
            }
          });

          if (!queryParam && paging.page === 1) {
            selectedEmp = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedEmp);
            }
          );
        } else {
          this.setState(
            {
              isSearchLoad: false,
              searchList: [],
              currentTab: "employeedetails",
              selectedEmp: {},
            },
            (_) => {
              //this.handleClickList(0);
            }
          );
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  updateQuery = () => {
    this.setState({
      isQuery: false,
    });
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      currentTab,
      searchList,
      selectedEmp,
      divisionName,
      subDivisionName,
      // sectionName,
      designationName,
      departmentName,
      paging,
      totalRecords,
      filterName,
      userPermissions,
    } = this.state;
    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
            <Row>
              <Col lg={3} sm={4}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Employee List</h2>
                    {userPermissions.add && (
                      <Link to={"/setup/hrms/employee/add"} className="btn btn-add">
                        Add
                      </Link>
                    )}
                  </div>

                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                            onClick={this.getList}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="filterName"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search"
                        name="filterName"
                        value={filterName}
                      />
                    </div>
                    <ul className="marketing-list list-border">
                      {isSearchLoad == false &&
                        searchList.length > 0 &&
                        searchList.map((row, index) => {
                          return (
                            <li
                              onClick={(e) => this.handleClickList(row)}
                              key={index}
                              className={`d-flex align-items-center justify-content-between ${row.EmployeeCode == selectedEmp.EmployeeCode
                                ? "active"
                                : ""
                                }`}
                            >
                              <div className="mlLeft d-flex align-items-center justify-content-between mr-3">
                                <div className="mr-3">
                                  <img
                                    src={
                                      envConfig.IMG_SERVLET_URL +
                                      "?imageId=" +
                                      row.EmployeeId +
                                      "&imageType=EMPLOYEE"
                                    }
                                    className="profile-icon"
                                  />
                                  {/* {row.Gender == "Female" ? (
                                <img src={FemaleProIcon} />
                              ) : (
                                <img src={MaleProIcon} />
                              )} */}
                                </div>
                                <div>
                                  <h4 className="ml-name">{row.EmployeeName}</h4>
                                  <p className="ml-type">{row.EmployeeCode}</p>
                                </div>
                              </div>
                              <div className="mlRight">
                                <p
                                  className={`emp-work-status ${row.WorkingStatus == "RESIGNED"
                                    ? "ews-resigned"
                                    : "ews-active"
                                    }`}
                                >
                                  {row.WorkingStatus}
                                </p>
                              </div>
                              {/* <div className="mlRight">
                            <Link to="#"
                              style={{ marginLeft: 10 }}
                              onClick={() =>
                                this.deleteSelectedItem(row.EmployeeId)
                              }
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </Link>
                          </div> */}
                            </li>
                          );
                        })}
                      {isSearchLoad == true && (
                        <li>
                          <Loader />
                        </li>
                      )}
                      {isSearchLoad == false && searchList.length === 0 && (
                        <li>
                          <NoRecordFound />
                        </li>
                      )}
                    </ul>
                    {/* Pagination */}
                    {searchList.length > 0 && (
                      <div className="load-more-wrap">
                        {paging.page != paging.total && searchList.length > 0 && (
                          <Link
                            to="#"
                            onClick={(e) => this.pagination(e)}
                            className="load-more"
                          >
                            {" "}
                          Load More{" "}
                          </Link>
                        )}
                        <div className="paging-text">
                          1 to {searchList.length} of {totalRecords} entries
                      </div>
                      </div>
                    )}
                  </Paper>
                </div>
              </Col>
              <Col lg={9} sm={8}>
                <div className="formTabSection position-r">
                  <Tabs
                    defaultActiveKey={currentTab ? currentTab : "employeedetails"}
                  >
                    <Tab eventKey="employeedetails" title="Employee Details">
                      <EmployeeDetail
                        selectedEmp={selectedEmp ? selectedEmp : {}}
                        empAssignment={{
                          divisionName: divisionName,
                          subDivisionName: subDivisionName,
                          // sectionName: sectionName,
                          designationName: designationName,
                          departmentName: departmentName,
                        }}
                        empId={selectedEmp.EmployeeId}
                        empCode={selectedEmp.EmployeeCode}
                        email={selectedEmp.Email}
                        return="hrms/employees?tab=employeedetails"
                        filterName={this.state.filterName}
                        page={paging.page}
                        isQuery={this.state.isQuery}
                        userPermissions={userPermissions}
                      />
                    </Tab>
                    <Tab eventKey="roles" title="Permission">
                      <EmployeeRoleList
                        selectedEmp={selectedEmp ? selectedEmp : {}}
                        empId={selectedEmp.EmployeeId}
                        empCode={selectedEmp.EmployeeCode}
                        email={selectedEmp.Email}
                        return="hrms/employees?tab=roles"
                        filterName={this.state.filterName}
                        page={paging.page}
                        isQuery={this.state.isQuery}
                        updateQuery={this.updateQuery}
                        userPermissions={userPermissions}
                      />
                    </Tab>
                    {/* <Tab eventKey="performance" title="Performance" disabled>
                      Performance
                  </Tab>
                    <Tab eventKey="Financials" title="Financials" disabled>
                      Financials
                  </Tab>
                    <Tab
                      eventKey="addtionaldetails"
                      title="Addtional Details"
                      disabled
                    >
                      Addtional Details
                  </Tab>
                    <Tab eventKey="documents" title="Documents" disabled>
                      Documents
                  </Tab>
                    <Tab eventKey="assets" title="Assets" disabled>
                      Assets
                  </Tab> */}
                    {/* <Tab eventKey="integration" title="Integration" disabled>
                  integration
                </Tab> */}
                  </Tabs>
                </div>
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

export { EmployeeList };
