import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import "./node-renderer-custom.css";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import { Col, Button, Row } from "react-bootstrap";

// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Import Images
import EditIcn from "img/edit.svg";
import DeleteIcn from "img/delete.svg";
import UserIcn from "img/user_img.svg";
import AddIcn from "img/add.svg";

// Loader
import Loader from "_components/_loader/Loader";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== "function" &&
    older.children.some(
      (child) => child === younger || isDescendant(child, younger)
    )
  );
}

// eslint-disable-next-line react/prefer-stateless-function
class CustomThemeNodeContentRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddNode: false,
      empModal: false,
      empStatus: "completed",
      deptData: {
        id: "",
        title: "",
      },
      employeeData: [],
      //userPermissions: common.getPermission(this.props, "hrms:department"),
    };
  }

  employeeModal(item) {
    if (item.empCount == 0) {
      common.snack("I", "No employees added in this department");
    } else {
      this.setModal(true);
      var deptData = {
        id: item.TerritoryId,
        title: item.title,
      };
      this.setState({
        deptData,
        employeeData: item.employees,
      });
    }
  }

  setModal(status) {
    this.setState({
      empModal: status,
      //empStatus: "process"
    });
  }

  handleClickDelete = (e, currentNode, parentNode) => {
    e.preventDefault();
    this.deleteTerritory(currentNode, parentNode);
  };

  //Move the employees to parent territory
  deleteTerritory = (cNode, pNode) => {
    let removeIds = [];
    let updateIds = [];
    cNode.employees.map((emp) => {
      if (pNode.employees.length > 0) {
        let isExists = pNode.employees.some(function (el) {
          return el.ResourceId === emp.ResourceId;
        });

        if (!isExists) {
          updateIds.push(emp);
        }

        let delEmpId = pNode.employees.some((data) => {
          return data.ResourceId === emp.ResourceId;
        });

        if (delEmpId) {
          removeIds.push(emp);
        }
      } else {
        updateIds.push(...cNode.employees);
      }
    });

    let inputData = [];
    if (removeIds.length > 0) {
      removeIds.map((obj) => {
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + Services.CRM_TERRITORY_TEAM + "/" + obj.TerritoryTeamId,
          operation: "delete",
          payload: {},
        });
      });
    }
    if (updateIds.length > 0) {
      updateIds.map((obj) => {
        let fields = {
          TerritoryId: pNode.TerritoryId,
        };
        inputData.push({
          id: "part" + (inputData.length + 1),
          path: "/" + Services.CRM_TERRITORY_TEAM + "/" + obj.TerritoryTeamId,
          operation: "update",
          payload: fields,
        });
      });
    }
    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);
    if (inputData.length > 0) {
      restServices.batchRequest(
        url,
        formAttr,
        (response) => {
          let url =
            envConfig.BASE_API +
            Services.CRM_TERRITORIES +
            "/" +
            cNode.TerritoryId;
          restServices.deleteRequest(
            url,
            (response) => {
              common.snack("S", "Department deleted successfully.");
              window.location.href = "/setup/hrms/department";
            },
            (error) => {}
          );
        },
        (error) => {
          common.snack("E", error);
        }
      );
    } else {
      let url =
        envConfig.BASE_API + Services.CRM_TERRITORIES + "/" + cNode.TerritoryId;
      restServices.deleteRequest(
        url,
        (response) => {
          common.snack("S", "Department deleted successfully.");
          window.location.href = "/setup/hrms/department";
        },
        (error) => {}
      );
    }
  };

  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      icons,
      buttons,
      className,
      style,
      didDrop,
      lowerSiblingCounts,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      treeId, // Not needed, but preserved for other renderers
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      ...otherProps
    } = this.props;
    const { isAddNode, empModal } = this.state;
    const nodeTitle = title || node.title;
    const nodeSubtitle = subtitle || node.subtitle;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    // department & employee
    const { deptData, employeeData, empStatus } = this.state;
    const nodeContent = connectDragPreview(
      <div
        className={
          ("row",
          isLandingPadActive ? "rowLandingPad" : "",
          isLandingPadActive && !canDrop ? "rowCancelPad" : "",
          isSearchMatch ? "rowSearchMatch" : "",
          isSearchFocus ? "rowSearchFocus" : "",
          className ? "className" : "")
        }
        style={{
          opacity: isDraggedDescendant ? 0.5 : 1,
          ...style,
        }}
      >
        <div
          className={("rowContents", !canDrag ? "rowContentsDragDisabled" : "")}
        >
          <div className="rowLabel">
            <div className="division-node">
              <span className="node-type">{node.type}</span>
              <Card>
                <CardContent>
                  <div className="row">
                    <div className="col">
                      <h3 className="node-title">{node.title}</h3>
                      <p className="created-date">
                        {moment(node.date).local().format("d MMM | hh:mm A")}
                      </p>
                    </div>
                    {/* {userPermissions.update && ( */}
                    {node.TerritoryType != "ADMIN_TERRITORY" && (
                      <div className="col title-actions-wrap text-right">
                        <Link
                          to={"/setup/hrms/department/edit/" + node.TerritoryId}
                        >
                          <img src={EditIcn} alt="" />
                        </Link>
                        <Link
                          to="#"
                          onClick={(e) =>
                            this.handleClickDelete(e, node, parentNode)
                          }
                        >
                          <img src={DeleteIcn} alt="" />
                        </Link>
                      </div>
                    )}
                    {/* )} */}
                  </div>
                </CardContent>
                <CardActions>
                  <ul className="node-user-list">
                    <li>
                      <Link
                        to="#"
                        onClick={() => {
                          this.employeeModal(node);
                        }}
                      >
                        <img src={UserIcn} alt="" />
                        <small>{node.empCount > 0 ? node.empCount : 0}</small>
                      </Link>
                    </li>
                  </ul>
                  <div className="node-footer-action-wrap">
                    {/*  */}
                    <Link
                      to="#"
                      className="add-options"
                      onClick={() => {
                        this.setState({ isAddNode: !isAddNode });
                      }}
                    >
                      <img src={AddIcn} alt="" />
                    </Link>
                    {/* {userPermissions.add && ( */}
                    <ul
                      className={
                        isAddNode == true
                          ? "action-opt-list action-opt-active"
                          : "action-opt-list"
                      }
                    >
                      <li>
                        <Link
                          to={"/setup/hrms/department/add/" + node.TerritoryId}
                        >
                          Add Child
                        </Link>
                      </li>
                    </ul>
                    {/* )} */}
                  </div>
                </CardActions>
              </Card>
            </div>
          </div>

          <div className="rowToolbar">
            {buttons.map((btn, index) => (
              <div
                key={index} // eslint-disable-line react/no-array-index-key
                className="toolbarButton"
              >
                {btn}
              </div>
            ))}
          </div>
        </div>

        <Dialog
          open={empModal}
          onClose={() => {
            this.setModal(false);
          }}
          aria-labelledby="responsive-dialog-title"
          className="leave-dialog division-employees"
        >
          <DialogTitle id="responsive-dialog-title" className="popup-title">
            {deptData.title + " Employee List"}
          </DialogTitle>
          <DialogContent>
            {empStatus == "process" ? (
              <Loader />
            ) : (
              <ul className="marketing-list list-border">
                {employeeData.map((item, key) => {
                  return (
                    <li
                      className="d-flex align-items-center justify-content-between"
                      key={key}
                    >
                      <div className="mlLeft d-flex align-items-center justify-content-between mr-3">
                        <div className="mr-3">
                          <img src={UserIcn} className="Employee" />
                        </div>
                        <div>
                          <h4 className="ml-name">{item.EmployeeName}</h4>
                          <p className="ml-type">{item.EmployeeCode}</p>
                        </div>
                      </div>
                      <div className="mlRight">
                        <Link
                          to={
                            "/setup/hrms/employee/edit/" +
                            item.EmployeeId +
                            "?empCode=" +
                            item.EmployeeCode +
                            "&email=" +
                            item.Email +
                            "&return=hrms/department?tab=employeedetails&filterName=&page=1"
                          }
                          style={{ marginLeft: 10 }}
                        >
                          <img src={EditIcn} alt="Edit" />
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </DialogContent>
          <DialogActions className="popup-footer justify-content-start">
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="cancelBtn"
                  onClick={() => {
                    this.setModal(false);
                  }}
                  type="button"
                >
                  Close
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Dialog>
      </div>
    );

    return (
      <div style={{ height: "100%" }} {...otherProps}>
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === "function") && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? "Collapse" : "Expand"}
                className={node.expanded ? "collapseButton" : "expandButton"}
                style={{ left: -0.5 * scaffoldBlockPxWidth }}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
              />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className="lineChildren"
                />
              )}
            </div>
          )}

        <div
          className={("rowWrapper", !canDrag ? "rowWrapperDragDisabled" : "")}
        >
          {canDrag
            ? connectDragSource(nodeContent, { dropEffect: "copy" })
            : nodeContent}
        </div>
      </div>
    );
  }
}

CustomThemeNodeContentRenderer.defaultProps = {
  buttons: [],
  canDrag: false,
  canDrop: false,
  className: "",
  draggedNode: null,
  icons: [],
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  subtitle: null,
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,
  toggleChildrenVisibility: null,
};

CustomThemeNodeContentRenderer.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.node),
  isSearchFocus: PropTypes.bool,
  isSearchMatch: PropTypes.bool,
  listIndex: PropTypes.number.isRequired,
  lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  node: PropTypes.shape({}).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  swapDepth: PropTypes.number,
  swapFrom: PropTypes.number,
  swapLength: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toggleChildrenVisibility: PropTypes.func,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  isDragging: PropTypes.bool.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  // Drop target
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool.isRequired,
};

export default CustomThemeNodeContentRenderer;
