import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import AddPartyGeneral from "./Tabs/AddPartyGeneral";
import PartySiteList from "./Tabs/PartySiteList";
import ClassificationList from "./Tabs/ClassificationList";
import ContactLinks from "../../../_components/Contacts/ContactLinks";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common, history } from "_helpers";

//Import CSS
import "../css/Party.css";

class AddParty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      partyData: {},
      partyId: 0,
      userPermissions: common.getPermission(this.props, "vendor:parties"),
    };
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let currentTab = params.get("tab");
    this.state.currentTab = currentTab;
  }

  componentDidMount() {
    if (this.props.match.params.partyId !== undefined) {
      //this.getPartyDetail(this.props.match.params.partyId)
      let partyId = this.props.match.params.partyId;
      this.setState({ partyId: partyId });
     
    }
  }

  /**
   * Get Party Details by ID
   */
  getPartyDetail = (id) => {
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_PARTY_HDR + "?q=PartyId=" + id;
    restServices.getRequest(url, (response) => {
      if (response) {
        this.setState({
          partyData: response,
        });
      }
    });
  };

    /**
   * Tab onclick event
   */
  handleSelect (key) {
    let url = window.location.pathname;
    let  queryParam = window.location.search;
    if(queryParam.includes("tab=")){
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab="+tab, "tab="+key);
    }
    history.push(url + queryParam);
  }

  render() {
    const { currentTab, partyId, userPermissions } = this.state;
    return !userPermissions.read ||
      (partyId == 0 && !userPermissions.add) ||
      (partyId > 0 && !userPermissions.update) ? (
        <AccessDenied />
      ) : (
        <div className="lead-wraper">
          <div className="secondary-head">
            <h1>Party</h1>
          </div>

          <div className="formTabSection position-r">
            <Tabs defaultActiveKey={currentTab ? currentTab : "general"}
            onSelect={this.handleSelect}>
              <Tab eventKey="general" title="Primary Site">
                <AddPartyGeneral partyId={this.props.match.params.partyId} />
              </Tab>
              <Tab
                eventKey="allsites"
                title="All Sites"
                disabled={partyId == 0 ? "disabled" : ""}
              >
                <PartySiteList
                  objectId={this.props.match.params.partyId}
                  permissions={this.props.permissions}
                />
              </Tab>
              <Tab
                eventKey="classification"
                title="Classification"
                disabled={partyId == 0 ? "disabled" : ""}
              >
                <ClassificationList
                  objectId={this.props.match.params.partyId}
                  permissions={this.props.permissions} />
              </Tab>
              <Tab
                eventKey="contact"
                title="Contacts"
                disabled={partyId == 0 ? "disabled" : ""}
              >
                <ContactLinks
                    objectId={this.props.match.params.partyId}
                    objectType={common.getObjectNameByPage("party")}
                  />
              </Tab>
            </Tabs>
          </div>
        </div>
      );
  }
}

export { AddParty };
