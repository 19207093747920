import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import delIc from 'img/crm_listdelete.png';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
    ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
    : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
        },
    title: {
        flex: "1 1 100%",
    },
}));

function EnhancedTableToolbar(props) {

    const classes = useToolbarStyles();

    const 
      {      
        toolbarHeading,
        selected,
      } = props.tlConfig;
      
    let numSelected = selected.length;

    const deleteSelected = (event) => {
      event.preventDefault();
      props.deleteSelected();
    };
    return (
      <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
        <div className="del-section">
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {toolbarHeading}
            </Typography>
          )}
    
          {numSelected > 0 && (
            <Tooltip title="Delete">
              {/* <IconButton aria-label="delete">
                <DeleteIcon className="deleteIcon" onClick={deleteSelected}/>
              </IconButton> */}
              <img src={delIc} className="iconDelete" onClick={deleteSelected} />
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
};

export default EnhancedTableToolbar;