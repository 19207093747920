import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

// Import Images
import SearchIcon from "img/search-icn.png";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import LeaveSettingsDetail from "./LeaveSettingsDetail";
// includes
import { crm } from '_utils';
class LeaveSettingsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSearchLoad: false,
      searchList: [],
      leaveSettingData: [],
      selectedLeaveSetting: {},
      selectedLeaveSettingId: 0,
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      isQuery: false,
      userPermissions: common.getPermission(this.props, "hrms:leave settings"),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let leaveSettingId = parseInt(params.get("leaveSettingId"));
      let filterName = params.get("filterName");
      let page = params.get("page");

      if (leaveSettingId) {
        let paging = this.state.paging;
        paging.page = parseInt(page);
        paging.size = 5;
        paging.total = 5;

        this.setState(
          {
            filterName,
            paging,
            isQuery: true,
          },
          (_) => {
            this.getList(this.state.isQuery);
          }
        );
      } else {
        this.getList(this.state.isQuery);
      }
    }
  }
  /**
   * Methods
   */
  handleClickList = (leaveSetting) => {
    this.setState(
      {
        selectedLeaveSetting: leaveSetting ? leaveSetting : {},
        selectedLeaveSettingId: leaveSetting ? leaveSetting.LeaveSettingId : 0,
        isLoading: true,
      },
      (_) => {
        //const { selectedLeaveSetting: selectedLeaveSetting } = this.state;
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  /**
   * Trigger Leave Settings List Popup
   * @param {*} id 
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  }

  /**
   * Delete Leave Settings by ID
   * @param {*} leaveId 
   */
  handleClickDeleteList = (leaveId) => {
    const { paging } = this.state;

    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + "/" + leaveId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState({
          paging: paging
        }, () => {
          this.getList();
        });

        common.snack("S", "Leave Settings deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      });
  }

  /**
   * Service Request
   */
  getList(queryParam) {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (!queryParam && paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LeaveType) LIKE '*${filterName}*'&totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LeaveType) LIKE '*${filterName}*'&totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${(paging.page) * paging.size}`;
    }

    let url = envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedLeaveSetting = this.state.selectedLeaveSetting;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          let search = window.location.search;
          let params = new URLSearchParams(search);
          let leaveSettingId = params.get("leaveSettingId");

          response.items.map((item, key) => {
            tempList.push(item);
            if (leaveSettingId != undefined) {
              if (item.LeaveSettingId == parseInt(leaveSettingId)) {
                selectedLeaveSetting = item
              }
            }
          });

          if (!queryParam && paging.page === 1) {
            selectedLeaveSetting = tempList[0];
          }
          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedLeaveSetting);
            }
          );
        } else {
          this.setState(
            {
              isSearchLoad: false,
              searchList: tempList,
            },
            (_) => {
              this.handleClickList();
            }
          );
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedLeaveSetting: selectedLeaveSetting,
      selectedLeaveSettingId,
      isLoading,
      paging,
      totalRecords,
      userPermissions
    } = this.state;

    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <Row>
            <Col lg={3} sm={4}>
              <div className="marketing-list-wrap">
                <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                  <h2 className="crm-type-title">Leave Settings</h2>
                  {userPermissions.add && (
                    <Link to={"/setup/hrms/leavesetting/add"} className="btn btn-add">
                      Add
                    </Link>
                  )}
                </div>

                <Paper>
                  <div className="input-group search-ml-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getList}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      id="filterName"
                      onChange={(e) => this.handleSearchChange(e)}
                      placeholder="Filter and search"
                      name="filterName"
                    />
                  </div>
                  <ul className="marketing-list">
                    {isSearchLoad == false &&
                      searchList.length > 0 &&
                      searchList.map((row, index) => {
                        return (
                          <li
                            onClick={(e) => this.handleClickList(row)}
                            key={index}
                            className={`d-flex justify-content-between align-items-center ${row.LeaveSettingId ==
                              selectedLeaveSetting.LeaveSettingId
                              ? "active"
                              : ""
                              }`}
                          >
                            <div className="mlLeft mr-3">
                              <h4 className="ml-name">{row.LeaveType}</h4>
                              <p className="ml-type">{row.DepartmentId}</p>
                            </div>
                            <div className="mlRight">
                              {/* <Link to={"/setup/hrms/leavesetting/edit/" + row.LeaveSettingId} >
                              Change
                            </Link> */}
                              <Link to="#"
                                style={{ marginLeft: 10 }}
                                onClick={() =>
                                  this.deleteSelectedItem(row.LeaveSettingId)
                                }
                              >
                                <img src={DeleteIcon} alt="Delete" />
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    {isSearchLoad == true && (
                      <li>
                        <Loader />
                      </li>
                    )}
                    {isSearchLoad == false && searchList.length === 0 && (
                      <li>
                        <NoRecordFound />
                      </li>
                    )}
                  </ul>
                  {/* Pagination */}
                  {searchList.length > 0 && (
                    <div className="load-more-wrap">
                      {paging.page != paging.total && searchList.length > 0 && (
                        <Link
                          to="#"
                          onClick={(e) => this.pagination(e)}
                          className="load-more"
                        >
                          {" "}
                          Load More{" "}
                        </Link>
                      )}
                      <div className="paging-text">
                        1 to {searchList.length} of {totalRecords} entries
                      </div>
                    </div>
                  )}
                </Paper>
              </div>
            </Col>
            <Col lg={9} sm={8}>
              <div className="formTabSection position-r">
                {isLoading ? (
                  <Loader />
                ) : (
                  <LeaveSettingsDetail
                    objectId={selectedLeaveSettingId}
                    data={selectedLeaveSetting}
                    filterName={this.state.filterName}
                    page={paging.page}
                    isQuery={this.state.isQuery}
                    userPermissions={userPermissions}
                  />
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export { LeaveSettingsList };
