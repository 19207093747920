import React from "react";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
import Select from 'react-select';
// includes
import { crm } from "_utils";
import { common, restServices } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
// pages
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// icon
import SearchIcon from "img/search-icn.png";
import MaleProIcon from "img/male-employee.png";

export default class ModuleModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isLoading: false,
      isAttrLoading: false,
      fields: {
        module: "",
        moduleItem: ["", "", "", ""],
        moduleSearch: "",
        moduleFields: []
      },
      sData: {
        moduleList: [],
        moduleData: [],
        moduleAttrList: []
      },
      // employee
      currentPage: 1,
      pageLimit: 10,
      userInfo: crm.userInfo(),
    }
  }

  componentDidMount() {
    this.getModuleList();
  }

  // props
  parentAction(module, moduleItem) {
    this.setModal(true);
    this.setState({
      fields: {
        module: module,
        moduleItem: moduleItem,
        moduleSearch: "",
        moduleFields: []
      },
    }, () => {
      if (module) {
        this.getAttrList();
      } else {
        this.getModuleList();
      }
    })
  }

  onModuleAction = (e) => {
    const { fields } = this.state;
    if (!fields.module.value) {
      common.snack("E", "Please select the module");
    } else if (fields.moduleItem[0] == "") {
      common.snack("E", "Please select the item in module");
    } else {
      this.props.onModuleAction(fields);
      this.setModal(false);
    }
  }

  // api
  getModuleList() {
    const { userInfo, sData } = this.state;
    this.setLoading(true);
    let filter = `?finder=FindLookupVals;pLookupCode=CHOLA_AUTOMATION_RULE_OBJECT,pTenantId=${userInfo.pTenantId}&totalResults=true&limit=100&offset=0`;
    let url = encodeURI(envConfig.BASE_API + Services.LOOKUPS_LOV + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response.totalResults > 0) {
            let result = [];
            response.items.map((item, key) => {
              result[item.Code] = item;
            })
            sData.moduleList = result;
            sData.moduleData = common.getOptionsOnlyData(response.items, "Name", "Code");
            sData.moduleAttrList = [];
            this.setState({
              sData
            });
          }
          this.setLoading(false);
        }
      },
      (error) => {
        this.setLoading(false);
      }
    );
  }

  getAttrList() {
    const { fields, sData, currentPage, pageLimit } = this.state;
    sData.moduleAttrList = [];
    if (fields.module == "") {
      this.setState({
        fields,
        sData,
      });
    } else {
      this.setAttrLoading(true);
      let module = fields.module.value;
      let moduleUrl = sData.moduleList[module].Extattr1;
      let moduleFields = (sData.moduleList[module].DependentOn).split("#");
      let filter = `?q=${moduleFields[1]} LIKE *${fields.moduleSearch}%*&totalResults=true&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit}`;
      let url = encodeURI(envConfig.BASE_API + moduleUrl + filter);
      restServices.getRequest(
        url,
        (response) => {
          if (response) {
            if (response.totalResults > 0) {
              sData.moduleAttrList = response;
              fields.moduleFields = moduleFields;
            }
          }
          this.setState({
            fields,
            sData
          });
          this.setAttrLoading(false);
        },
        (error) => {
          this.setAttrLoading(false);
        }
      );
    }
  }

  // support
  setModal(status) {
    this.setState({
      modal: status
    })
  }

  // handler
  onChange = (e) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields },
      _ => {
        this.getAttrList()
      })
  }

  onSelect = (data, name) => {
    const { fields } = this.state;
    fields[name] = data;
    fields.moduleSearch = "";
    fields.moduleItem = ["", "", "", ""];
    fields.moduleFields = [];
    this.setState({ fields }, () => {
      this.handleFilter();
    })
  }

  onAttrSelect = (item) => {
    const { fields } = this.state;
    fields.moduleFields.map((cItem, key) => {
      if (cItem != "null") {
        fields.moduleItem[key] = item[cItem];
      }
    })
    this.setState({ fields })
  }

  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getAttrList();
        }
      );
    }
  };

  handleFilter() {
    this.setState({
      currentPage: 1,
      pageLimit: 10,
    }, () => {
      this.getAttrList();
    })
  }

  // common
  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  setAttrLoading(status) {
    this.setState({ isAttrLoading: status });
  }

  render() {
    const {
      modal,
      fields,
      sData,
      isLoading,
      isAttrLoading,
      currentPage,
      pageLimit
    } = this.state;
    return (
      <div>
        <Dialog
          open={modal}
          onClose={() => this.setModal(false)}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"md"}
          fullWidth={true}
          className="target-modal-wrap"
        >
          <DialogTitle id="responsive-dialog-title" className="popup-title">
            Module
          </DialogTitle>
          <DialogContent>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <div className="mb-3">
                  <label>Select Module</label>
                  <Select
                    value={fields.module}
                    options={sData.moduleData}
                    onChange={(e) => this.onSelect(e, 'module')}
                  />
                </div>
                <div className="module-result">
                  <div className="mb-3">
                    <div className="input-group search-filter-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        name="moduleSearch"
                        value={fields.moduleSearch}
                        onChange={this.onChange}
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                            onClick={() => this.handleFilter()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="module-list">
                    {isAttrLoading ? (
                      <Loader />
                    ) : sData.moduleAttrList == "" ? (
                      <NoRecordFound />
                    ) : (
                      <div>
                        <ul className="result-list row">
                          {sData.moduleAttrList.items.map((item, key) => {
                            return (<li className={`col-md-6 ${item[fields.moduleFields[0]] == fields.moduleItem[0] ? "item-select-active" : ""}`} key={key} onClick={() => this.onAttrSelect(item)}>
                              <div className="result-content d-flex">
                                <div className="module-img">
                                  <img src={MaleProIcon} alt="" />
                                </div>
                                <div className="module-details">
                                  <h3>{`${item[fields.moduleFields[1]]}${fields.moduleFields[2] != "null" ? item[fields.moduleFields[2]] || "" : ""}`}</h3>
                                  <p>{fields.moduleFields[4] != "null" ? item[fields.moduleFields[4]] : "-"}</p>
                                </div>
                              </div>
                            </li>)
                          })}
                        </ul>
                        <div className="d-flex bg-white">
                          <div className="pagination-wrap">
                            <Pagination
                              itemClass="page-item"
                              linkClass="page-link"
                              activePage={currentPage}
                              itemsCountPerPage={pageLimit}
                              totalItemsCount={sData.moduleAttrList.totalResults}
                              pageRangeDisplayed={7}
                              onChange={this.handlePageChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          {!isLoading && (
            <DialogActions className="popup-footer justify-content-start">
              <div className="botBtn">
                <div>
                  <Button
                    className="saveBtn"
                    onClick={() => this.onModuleAction()}
                    type="submit"
                  >Save</Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => this.setModal(false)}
                    type="button"
                  >Cancel</Button>
                </div>
              </div>
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}