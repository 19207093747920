import React, { useState, useEffect } from "react";

// Import Component
import Header from "_components/_header/Header";
import LeftSideMenu from "_components/_leftsideMenu/LeftSideMenu";
import Loader from "_components/_loader/Loader";

//Import CSS
import "./DashboardLayout.css";
import adminPermission from "json/admin.json";
import jsonPermission from "json/permission.json";

// Import roles json file
import { common, restServices, tools } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { PermissionContext } from "_helpers/permissionContext";
import ScrollButton from "../../pages/_components/ScrollButton/ScrollButton";

export default function DashboardLayout(props) {
  const [deptEmployees, setDeptEmployees] = useState({});
  const [permission, setPermission] = useState({});
  const [menu, setMenu] = useState(localStorage.getItem("myMenu"));

  useEffect(() => {
    getAllPermissions();
    getTransTypeIds();
    getEmpDepartments();
    getUserDetail();
    getTeamMembersByEmpId();
    getUserCustomFilter();
  }, []);

  const getUserDetail = () => {
    let userInfo = common.authInfo();
    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_USERS + `/${userInfo.UserId}`
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let userData = tools.deCrypt(localStorage.getItem("userData"));

          userData["AlternativeEmailAddress1"] =
            response.AlternativeEmailAddress1;
          userData["AlternativeEmailAddress2"] =
            response.AlternativeEmailAddress2;
          localStorage.setItem("userData", tools.crypt(userData));
        }
      },
      (error) => {}
    );
  };

  const getUserCustomFilter = () => {
    let userInfo = common.authInfo();
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_CUSTOM_FILTER + `?q=UserId=${userInfo.UserId}`
    );
    restServices.getRequest(
      url,
      (response) => {
        let fData = null;
        if (response.items.length > 0) {
          fData = response.items[0];
        }
        let userData = tools.deCrypt(localStorage.getItem("userData"));

        userData["UserCustomFilter"] = fData;
        localStorage.setItem("userData", tools.crypt(userData));
      },
      (error) => {}
    );
  };

  // Get unique Transaction IDs by code for tenant
  const getTransTypeIds = () => {
    let filter =
      `?offset=0&q=TransactionTypeCode='${envConfig.LIST_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.LEAD_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.CAMPAIGN_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.DEAL_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.QUOTE_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.SALESORDER_TRANS_TYPE_ID}' OR ` +
      `TransactionTypeCode='${envConfig.INVOICE_TRANS_TYPE_ID}'&limit=10`;
    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_TRANS_TYPE + filter
    );

    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          let tData = [];
          response.items.map((item) => {
            tData.push({
              code: item.TransactionTypeCode,
              id: item.TransactionTypeId,
            });
          });
          let encTData = tools.crypt(tData);
          localStorage.setItem("tData", encTData);
        }
      },
      (error) => {}
    );
  };

  // Get user territories by user ID
  const getEmpDepartments = () => {
    let userInfo = common.authInfo();
    let territories = [];
    let terList = [];

    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_TERRITORY_TEAM +
        `?q=ResourceId=${userInfo.EmpId}&limit=1000&offset=0`
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          response.items.map((item) => {
            territories.push(item.TerritoryId);
            terList.push({
              label: item.TerritoryName,
              value: item.TerritoryId,
            });
          });
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          // console.log("my territories :>> ", territories);
          userData["territory"] = territories;
          userData["terList"] = terList;
          localStorage.setItem("userData", tools.crypt(userData));
        }
      },
      (error) => {}
    );
  };

  /**
   * Get all employees ID by login user ID
   */
  const getTeamMembersByEmpId = () => {
    let userInfo = common.authInfo();
    let employeeList = {
      active: [],
      sub: [],
    };
    /** Steps to get dept employees  */
    let url = encodeURI(
      envConfig.BASE_API +
        Services.CRM_TERRITORY_EMPLOYEES +
        "?finder=FindEmployees;pResource=" +
        userInfo.EmpId +
        "&limit=100&offset=0"
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items && response.items.length > 0) {
          response.items.map((item) => {
            if (item.Bindtype == "PARENT") {
              employeeList.active.push(item.ResourceId);
            } else if (item.Bindtype == "CHILD") {
              employeeList.sub.push(item.ResourceId);
            }
          });
          // console.log("my team List :>> ", employeeList);
          let userData = tools.deCrypt(localStorage.getItem("userData"));

          userData["employeeList"] = employeeList;
          localStorage.setItem("userData", tools.crypt(userData));

          setDeptEmployees(employeeList);
        }
      },
      (error) => {}
    );
  };

  const getAllPermissions = async (_) => {
    let userInfo = common.authInfo();
    //adminuser
    let filter = `?q=UserId=${userInfo.UserId} AND GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${userInfo.TenantId} AND UPPER(GroupDesc) LIKE '**'&finder=OnlyParentGroups&limit=1000&offset=0&orderBy=CreatedOn:desc&totalResults=true`;

    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + filter
    );
    restServices.getRequest(
      url,
      async (response) => {
        if (response) {
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          if (response) {
            if (response.items.length > 0) {
              let AdminUser = response.items.find(
                (o) => o.GroupDesc === Services.CRM_DEFAULT_GROUPS_2
              );
              if (AdminUser) {
                userData["roleId"] = 0;
                userData["role"] = Services.CRM_DEFAULT_GROUPS_2;
                localStorage.setItem("userData", tools.crypt(userData));
                setPermission(adminPermission);
              } else {
                userData["roleId"] = response.items[0].GroupId; //TODO - will update for field level permissions
                userData["role"] = "user";
                localStorage.setItem("userData", tools.crypt(userData));
                setPermission(
                  response.items[0].UserAccess
                    ? JSON.parse(response.items[0].UserAccess)
                    : jsonPermission
                );
              }
            } else {
              localStorage.clear();
              window.location.href = "/";
              //no roles assigned - logout
            }
          }
        }
      },
      (error) => {}
    );
  };

  const onMenuChange = () => {
    let menu = localStorage.getItem("myMenu");
    setMenu(menu);
  };
  return (
    <div>
      <div className="admin-layout">
        <LeftSideMenu menuControl={menu} />
        <div
          className={
            menu == "true"
              ? "container-wrapper wrapper-widthSet"
              : "container-wrapper"
          }
        >
          <Header onMenuChange={() => onMenuChange()} />
          <ScrollButton />
          {permission && Object.keys(permission).length > 0 && (
            <PermissionContext.Provider value={{ permission }}>
              {React.cloneElement(props.children, {
                permissions: permission,
              })}
            </PermissionContext.Provider>
          )}
          {Object.keys(permission).length === 0 && <Loader />}
        </div>
      </div>
    </div>
  );
}
