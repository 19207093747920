import React from "react";
import { Link } from "react-router-dom";
import { Form, Col, Button, Row } from "react-bootstrap";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from 'material-ui/Checkbox';
import Paper from "@material-ui/core/Paper";
// Import Images
import SearchIcon from "img/search-icn.png";
import editIcon from "img/edit.svg";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tools } from "_helpers";
// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// Import css
import '../css/Roles.css';
//Import AddRolePermissions page
import AddRolePermissions from "./AddRolePermissions";
// includes
import { crm } from '_utils';
class RolesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchLoad: false,
      isLoadingAdd: false,
      delSelectedItem: 0,
      searchList: [],
      selectedRole: null,
      commonResource: {
        GroupId: 0
      },
      open: false,
      formSubmit: false,
      fields: {
        GroupName: tools.randomString(),
        GroupDesc: "",
        GroupParentId: "",
        LdapSync: "Y",
        TenantId: crm.userInfo().pTenantId,
      },
      errors: {
        // GroupName: {
        //   error: "",
        //   isReq: "Role name is required",
        // },
        GroupDesc: {
          error: "",
          isReq: "Role name is required!",
        },
      },
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      userPermissions: common.getPermission(this.props, "configure:roles"),
    }
  }
  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  /**
   * Methods
   */

  handleClickList = (role) => {
    this.setState(
      {
        selectedRole: role,
      },
      (_) => {
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  /**
   * Service Request
   */
  getList() {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item)
      });
    }

    let filter = `?q=GroupDesc NOT IN ('${Services.CRM_DEFAULT_GROUPS_1}','${Services.CRM_DEFAULT_GROUPS_2
      }','${Services.CRM_DEFAULT_GROUPS_3}','${Services.CRM_DEFAULT_GROUPS_4}') AND TenantId=${crm.userInfo().pTenantId} AND UPPER(GroupDesc) LIKE '*${filterName}*' &limit=${paging.size}&offset=${(paging.page - 1) * paging.size
      }&finder=FindParentGroups&orderBy=CreatedOn:desc&totalResults=true`;

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + filter);

    restServices.getRequest(
      url,
      (response) => {
        let selectedRole = this.state.selectedRole;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          response.items.map((item, key) => {
            tempList.push(item);
          });

          if (paging.page === 1) {
            selectedRole = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              paging: paging,
            },
            (_) => {
              if (paging.page === 1) {
                this.handleClickList(selectedRole);
              }
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
            selectedRole: null
          });
        }
      },
      (error) => {
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  // validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors
    });

    return isValid;
  }

  /**
  * Add Roles modal popup
  */
  addRoleModal = (event, groupId) => {
    event.preventDefault();
    const { commonResource } = this.state;
    commonResource.GroupId = groupId;
    this.setState({
      open: true,
      commonResource: commonResource,
    })
    if (groupId > 0) {
      this.getDetailById(groupId);
    }
  }

  /**
   * Get details by id & set data in fields
   */
  getDetailById = (id) => {
    const { fields, errors } = this.state;
    this.setState({
      isLoadingAdd: true
    });

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES + "/" + id);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {

                fields[key] = response[key];

                if (errors.hasOwnProperty(key)) {
                  errors[key].error = "";
                }
              }
            }
          }

          this.setState(
            {
              fields: fields,
              errors: errors,
              isLoadingAdd: false
            });
        }
      },
      (error) => { }
    );
  };

  /**
  * Close Roles Modal Popup
  */
  closeRoleModal = () => {
    this.resetFields(this.state.searchList);
  }

  resetFields = (searchList) => {
    const { fields, errors } = this.state;
    fields.GroupName = tools.randomString();
    fields.GroupDesc = '';
    fields.LdapSync = 'Y';
    // Remove Default Errors
    for (var key of Object.keys(errors)) {
      if (errors.hasOwnProperty(key)) {
        errors[key].error = "";
      }
    }
    this.setState({
      fields: fields,
      open: false,
      isLoadingAdd: false,
      //searchList: searchList,
      formSubmit: false,
      errors: errors,
      selectedRole: searchList[0]
    });
  }

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;

    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      if (commonResource.GroupId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, searchList } = this.state;

    let url = encodeURI(
      envConfig.AUTH_REST_URL + Services.ROLES + "/" + commonResource.GroupId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = searchList;

          items.forEach(function (item, index) {
            if (item.GroupId === commonResource.GroupId) {
              items[index] = response;
              return;
            }
          });

          //searchList = items;
          this.resetFields(items);
          this.getList();
        }
      },
      (error) => { common.snack("E", error); }
    );
  };

  create = () => {
    const { fields, searchList } = this.state;

    let url = encodeURI(envConfig.AUTH_REST_URL + Services.ROLES);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          let items = searchList;
          items.unshift(response);
          //searchList = items;
          this.resetFields(searchList);
          this.getList();
        }
      },
      (error) => { common.snack("E", error); }
    );
  };


  /**
   * Delete comment
   */
  deleteItem = (id) => {
    this.setState({ delSelectedItem: id });
    common.confirmDelete(true, (response) => {
      this.handleDeleteBlock();
    });
  }

  /**
   * Delete roles by id
   */
  handleDeleteBlock = () => {
    const { delSelectedItem } = this.state;

    let url = envConfig.AUTH_REST_URL + Services.ROLES + "/" + delSelectedItem;

    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Selected item deleted successfully.")

        this.setState({
          isSearchLoad: true,
        });

        let responseList = this.state.searchList;
        let updateData = [];
        if (responseList != null && responseList.items != null) {
          updateData = responseList.items.filter((obj) => {
            return delSelectedItem !== obj.GroupId;
          });
          responseList["items"] = updateData;
        }

        this.setState({
          searchList: responseList,
          isSearchLoad: false,
          selectedRole: responseList["items"][0]
        });
      },
      (error) => { });
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedRole,
      open,
      isLoadingAdd,
      fields,
      errors,
      formSubmit,
      commonResource,
      paging,
      totalRecords,
      userPermissions
    } = this.state;
    const isChecked = (value) => value == 'Y';
    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <Row>
              <Col sm={3}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Roles</h2>
                    {userPermissions.add && (
                      <Link to="#" className="btn btn-add"
                        onClick={(e) => this.addRoleModal(e, 0)}>
                        Add
                      </Link>
                    )}
                  </div>

                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                            onClick={this.getList}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="filterName"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search"
                        name="filterName"
                      />
                    </div>
                    <ul className="marketing-list">
                      {selectedRole && isSearchLoad == false &&
                        searchList.length > 0 &&
                        searchList.map((row, index) => {
                          return (
                            <li
                              onClick={(e) => this.handleClickList(row)}
                              key={row.GroupId}
                              className={`d-flex justify-content-between align-items-center ${row.GroupId == selectedRole.GroupId
                                ? "active"
                                : ""
                                }`}
                            >
                              <Row className="row-setup">
                                <Col sm={10}>
                                  <h4 className="ml-name">{row.GroupDesc}</h4>
                                  <p className="ml-type">{row.GroupDesc}</p>
                                </Col>
                                <Col sm={2}>
                                  {userPermissions.update && row.GroupDesc != "Osmo Default" && (
                                    <Link to="#"
                                      style={{ padding: "0 5px" }}
                                      onClick={(e) => this.addRoleModal(e, row.GroupId)}
                                    >
                                      <img src={editIcon} alt="Edit" />
                                    </Link>
                                  )}
                                  {/* <Link to="#"
                                name={row.StatusId}
                                onClick={(e) => this.deleteItem(row.GroupId)}
                                style={{ padding: "0 5px" }}
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </Link> */}
                                </Col>
                              </Row>
                            </li>
                          );
                        })}
                      {isSearchLoad == true && <Loader />}
                      {isSearchLoad == false && searchList.length === 0 && (
                        <li>
                          <NoRecordFound />
                        </li>
                      )}
                    </ul>
                    {/* Pagination */}
                    {searchList.length > 0 && (
                      <div className="load-more-wrap">
                        {paging.page != paging.total && searchList.length > 0 && (
                          <Link
                            to="#"
                            onClick={(e) => this.pagination(e)}
                            className="load-more"
                          >
                            {" "}
                            Load More{" "}
                          </Link>
                        )}
                        <div className="paging-text">
                          1 to {searchList.length} of {totalRecords} entries
                        </div>
                      </div>
                    )}
                  </Paper>
                </div>
              </Col>
              <Col sm={9}>
                {isSearchLoad == false && selectedRole && searchList &&
                  searchList.length > 0 && (
                    <div className="formTabSection position-r">
                      <AddRolePermissions
                        selectedRole={selectedRole}
                        userPermissions={userPermissions} />
                    </div>
                  )}
                {isSearchLoad == false && !selectedRole && (
                  <div className="formTabSection position-r">
                    <Paper style={styles.boxLayout}>
                      <NoRecordFound />
                    </Paper>
                  </div>
                )}
              </Col>
            </Row>

            {/* Modal Popup */}
            <Dialog
              open={open}
              onClose={this.closeRoleModal}
              aria-labelledby="responsive-dialog-title"
              className="leave-dialog"
            >
              <DialogTitle id="responsive-dialog-title" className="popup-title">
                {"ADD ROLE"}
              </DialogTitle>
              <DialogContent>
                {!isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Role Name*</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Role Name"
                          name="GroupDesc"
                          value={fields.GroupDesc}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.GroupDesc.error !== "" && formSubmit === true && (
                          <Form.Text className="error">{errors.GroupDesc.error}</Form.Text>
                        )}
                      </Form.Group>
                    </div>
                    {/* <div className="list-row">
                        <Form.Group>
                          <Form.Label>Description*</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            name="GroupDesc"
                            value={fields.GroupDesc}
                            onChange={(e) => this.handleOnChange(e)}
                            maxLength={500}
                          />
                          {errors.GroupDesc.error !== '' && formSubmit === true && (
                            <Form.Text className="error">
                              {errors.GroupDesc.error}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </div> */}
                    <div className="list-row">
                      <Form.Group>
                        <Checkbox
                          name="LdapSync"
                          checked={isChecked(fields.LdapSync)}
                          label="LDAP Sync"
                          labelStyle={{ color: '#A7AFC2' }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Loader />
                    </div>
                  </div>
                )}
              </DialogContent>
              {!isLoadingAdd && (
                <DialogActions className="popup-footer justify-content-start">
                  <Row className="botBtn">
                    <Col sm={12}>
                      <Button
                        className="saveBtn"
                        onClick={this.onSubmit}
                        type="submit"
                      >
                        {commonResource.GroupId === 0 ? "Add" : "Update"}
                      </Button>
                      <Button
                        className="cancelBtn"
                        onClick={this.closeRoleModal}
                        type="button"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </DialogActions>
              )}
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};


export { RolesList };
