import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

import { Form } from "react-bootstrap";
import Checkbox from "material-ui/Checkbox";

//API SERVICES
import { common } from "_helpers";

class DesignationDetail extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { selectedDesignation, empAssignment } = this.props;
    const isValidProp = (key) => selectedDesignation[key] ? selectedDesignation[key] : "-";
    const isChecked = (key) => selectedDesignation[key] && selectedDesignation[key] == "Y" ? true : false;
    const isValidAssignment = (key) => empAssignment[key] ? empAssignment[key] : "-";

    return (
      <div>
        <Paper style={styles.boxLayout}>
          <div className="box-title-wrap justify-content-between">
            <h3 className="card-title">DESIGNATION INFORMATION</h3>
            <Link className="card-link" to={"/hrms/designation/edit/" + selectedDesignation.DesignationId + "?filterName=" +
                    this.props.filterName +
                    "&page=" +
                    this.props.page}>
              Change
            </Link>
          </div>
          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Designation Code</label>
                <p className="form-value">{isValidProp("DesignationCode")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Division Name</label>
                <p className="form-value">{isValidAssignment("divisionName")}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Sub Division Name</label>
                <p className="form-value">{isValidAssignment("subDivisionName")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Department Name</label>
                <p className="form-value">{isValidAssignment("departmentName")}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className="list-row">
                <Form.Group>
                    <Checkbox
                    checked={isChecked("PadiEligibility")}
                    label="Pagi Eligible"
                    labelStyle={{ color: "#A7AFC2" }}
                    labelPosition="left"
                    />
                </Form.Group>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Designation Name</label>
                <p className="form-value">{isValidProp("DesignationName")}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Start Date</label>
                <p className="form-value">
                  {selectedDesignation.StartDate
                    ? common.formatDate(
                        isValidProp("StartDate"),
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">End Date</label>
                <p className="form-value">
                  {selectedDesignation.EndDate
                    ? common.formatDate(
                        isValidProp("EndDate"),
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <div className="list-row">
                <Form.Group>
                    <Checkbox
                    checked={isChecked("Active")}
                    label="Active"
                    labelStyle={{ color: "#A7AFC2" }}
                    labelPosition="left"
                    />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Paper>
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default DesignationDetail;
