import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "react-select";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// icon
import SearchIcon from "img/search-icn.png";
import DeleteIcon from "img/delete.svg";
// includes
import { crm } from '_utils';
export default class TargetModal extends React.Component {
  constructor(props) {
    super(props);
    const selectVal = { label: "Select", value: "" };
    this.state = {
      modal: true,
      configType: "config",
      isLoading: true,
      isTargetLoading: false,
      isEmpLoading: false,
      isAdding: false,
      isAllow: false,
      selectVal,
      fields: {
        targetPeriod: selectVal,
        targetMonth: selectVal,
        targetQuarter: selectVal,
        targetYear: selectVal,
        targetType: selectVal,
      },
      configure: {
        type: [
          { label: "Month", value: "month" },
          { label: "Quarter", value: "quarter" },
          { label: "Year", value: "year" },
        ],
        quarter: [
          { label: "Q1", value: 1 },
          { label: "Q2", value: 2 },
          { label: "Q3", value: 3 },
          { label: "Q4", value: 4 },
        ],
        month: common.getMonthList(),
        year: common.getYearList(3),
        targetTypes: [],
      },
      // target
      targetEmp: {
        added: [],
        new: [],
      },
      // employee
      currentPage: 1,
      pageLimit: 10,
      filter: {
        searchTerm: "",
      },
      employeeList: [],
    };
  }

  /* Component Hooks */
  componentDidMount() {
    this.getTargetTypes();
    this.getEmpList();
  }

  // api get
  getTargetTypes = () => {
    const { configure } = this.state;

    let filter = `?finder=FindLookupVals;pLookupCode=CHOLA_CRM_TARGET_TYPES&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          configure.targetTypes = common.getOptionsOnlyData(
            response.items,
            "Name",
            "Code"
          );
          this.setState({
            configure,
          });
        }
        this.setLoading(false);
      },
      (error) => {
        this.setLoading(false);
      }
    );
  };

  getTargetList = async () => {
    let allow = this.validateFields("change");
    if (allow) {
      const { targetEmp } = this.state;
      this.setTargetLoading(true);
      targetEmp.added = [];
      const configResult = await this.getConfigureTarget();
      if (configResult.count > 0) {
        let id = configResult.items[0].CrmTargetId;
        let query = `?q=CrmTargetId=${id}&orderBy=CreatedOn:desc&totalResults=true&limit=100&offset=0`;
        let url = envConfig.BASE_API + Services.EMPLOYEE_TARGET + query;

        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              if (response.totalResults > 0) {
                response.items.map((item) => {
                  targetEmp.added.push({
                    id: item.CrmRscTargetId,
                    empId: item.EmpId,
                    name: item.CrmRscTargetName,
                    value: item.TargetRscValue,
                    change: false,
                  });
                });
              }
            }
            this.setState({
              targetEmp,
              isTargetLoading: false,
            });
          },
          (error) => {
            this.setState({
              targetEmp,
              isTargetLoading: false,
            });
          }
        );
      } else {
        this.setState({
          targetEmp,
          isTargetLoading: false,
        });
      }
    }
  };

  getEmpList = () => {
    const { filter, currentPage, pageLimit } = this.state;
    this.setEmpLoading(true);
    let query = `?q=TenantId=${crm.userInfo().pTenantId} AND WorkingStatus='${envConfig.WorkingStatus}' AND UPPER(EmployeeName) LIKE '*${filter.searchTerm
      }*'&totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }`;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + query);

    restServices.getRequest(
      url,
      (response) => {
        let employeeList = [];
        if (response) {
          if (response.totalResults > 0) {
            employeeList = response;
          }
        }
        this.setState({
          employeeList,
          isEmpLoading: false,
        });
      },
      (error) => {
        this.setState({
          employeeList: [],
          isEmpLoading: false,
        });
      }
    );
  };

  getConfigureTarget = async () => {
    const { fields } = this.state;
    // check target
    let filterQry = `TargetUom=${fields.targetPeriod.value};TargetType=${fields.targetType.value}`;
    if (fields.targetPeriod.value == "month") {
      filterQry += `;TargetMonth=${fields.targetMonth.value};TargetYear=${fields.targetYear.value}`;
    } else if (fields.targetPeriod.value == "quarter") {
      filterQry += `;TargetQuarter=${fields.targetQuarter.value}`;
    }
    if (fields.targetPeriod.value == "year") {
      filterQry += `;targetYear=${fields.targetYear.value}`;
    }

    let query = `?q=${filterQry}&totalResults=true&limit=1&offset=0`;

    let url = encodeURI(envConfig.BASE_API + Services.CONFIGURE_TARGET + query);

    let result = await new Promise((resolve, reject) => {
      restServices.getRequest(url, (response) => {
        resolve(response);
      }, (error) => { });
    });

    return result;
  };

  // api post
  saveConfigure = async () => {
    const { fields } = this.state;
    let params = {
      CrmTargetName: `${fields.targetPeriod.value}-${fields.targetType.value}`,
      CrmTargetDesc: `${fields.targetPeriod.value}-${fields.targetType.value}`,
      TargetUom: fields.targetPeriod.value,
      TargetMonth: fields.targetMonth.value || "JAN",
      TargetQuarter: fields.targetQuarter.value || 1,
      TargetYear: fields.targetYear.value || 1970,
      TargetType: fields.targetType.value,
      TargetValue: "0",
      Active: "Y",
      EffStartDate: null,
      EffEndDate: null,
      OrgId: crm.userInfo().pOrgId,
      TenantId: crm.userInfo().pTenantId,
    };

    let url = encodeURI(envConfig.BASE_API + Services.CONFIGURE_TARGET);

    let result = await new Promise((resolve, reject) => {
      restServices.postRequest(url, params, (response) => {
        resolve(response.CrmTargetId);
      }, (error) => { });
    });

    return result;
  };

  saveTargetEmployee = async () => {
    let allow = this.validateFields("submit");
    if (allow) {
      const { targetEmp } = this.state;
      let added = false;
      // check already added
      targetEmp.added.map((item, key) => {
        if (item.change) {
          added = true;
        }
      });
      // check newly added
      if (!added) {
        if (targetEmp.new.length > 0) {
          added = true;
        }
      }
      if (!added) {
        allow = false;
        common.snack("W", "Please select or change employee");
      }

      if (allow) {
        this.setAddedLoading(true);
        // var
        let id = "";
        let emp = 1;
        let url = "";

        const configResult = await this.getConfigureTarget();
        if (configResult.count > 0) {
          id = configResult.items[0].CrmTargetId;
        } else {
          id = await this.saveConfigure();
        }

        // update already added
        let targetEmpParam = [];
        targetEmp.added.map((item, key) => {
          if (item.change) {
            let formData = {
              id: "employee_" + key,
              path: "/CholaCrmRscTargets/" + item.id,
              operation: "update",
              payload: this.getEmpParam(id, item),
            };
            targetEmpParam.push(formData);
          }
        });

        if (targetEmpParam != "") {
          let formAttr = { parts: targetEmpParam };
          url = encodeURI(envConfig.BASE_API);
          let result = await new Promise((resolve, reject) => {
            restServices.batchRequest(url, formAttr, (response) => {
              resolve(1);
            }, (error) => { });
          });
        }

        // new
        if (targetEmp.new.length > 0) {
          targetEmp.new.map((item, key) => {
            let params = this.getEmpParam(id, item);
            url = encodeURI(envConfig.BASE_API + Services.EMPLOYEE_TARGET);
            restServices.postRequest(url, params, (response) => {
              if (targetEmp.new.length == emp) {
                common.snack("S", "Configuration has been completed");
                this.setAddedLoading(false);
                this.setDefault();
              }
              emp++;
            }, (error) => { });
          });
        } else {
          common.snack("S", "Configuration has been completed");
          this.setAddedLoading(false);
          this.setDefault();
        }
      }
    }
  };

  deleteTargetEmployee = async (id, type) => {
    if (type == "new") {
      const { targetEmp } = this.state;
      targetEmp.new = this.removeTargetEmp(id);
      this.setState({
        targetEmp,
      });
      common.snack("S", "Deleted Successfully");
    } else {
      let url = envConfig.BASE_API + Services.EMPLOYEE_TARGET + "/" + id;
      restServices.deleteRequest(
        url,
        (response) => {
          common.snack("S", "Deleted Successfully");
          this.getTargetList();
          this.props.onComplete(true);
        },
        (error) => { }
      );
    }
  };

  // data support
  selectTargetEmp(data) {
    const { targetEmp } = this.state;
    let empId = data.EmployeeId;
    let name = data.EmployeeName;
    if (this.checkTargetEmp(empId, "added")) {
      common.snack("W", name + " is already added");
    } else {
      if (this.checkTargetEmp(empId, "new")) {
        targetEmp.new = this.removeTargetEmp(empId);
        common.snack("S", name + " has been removed successfully");
      } else {
        targetEmp.new.push({
          empId,
          name,
          value: 0,
          change: false,
        });
        common.snack("S", name + " has been added successfully");
      }
      this.setState({
        targetEmp,
      });
    }
  }

  checkTargetEmp(id, type) {
    const { targetEmp } = this.state;
    let avail = false;
    targetEmp[type].map((item) => {
      if (item.empId == id) {
        avail = true;
      }
    });
    return avail;
  }

  removeTargetEmp(id) {
    const { targetEmp } = this.state;
    let result = targetEmp.new.filter((item) => {
      return item.empId != id;
    });
    return result;
  }

  getEmpParam(id, item) {
    const { fields } = this.state;
    let params = {
      CrmTargetId: id,
      CrmRscTargetName: `${item.name}`,
      CrmRscTargetDesc: `${item.name}`,
      TargetRscUom: fields.targetPeriod.value,
      TargetRscMonth: fields.targetMonth.value || "JAN",
      TargetRscQuarter: fields.targetQuarter.value || 1,
      TargetRscYear: fields.targetYear.value || 1970,
      TargetRscType: fields.targetType.value,
      TargetRscValue: item.value,
      Active: "Y",
      EffStartDate: null,
      EffEndDate: null,
      EmpId: item.empId,
      OrgId: crm.userInfo().pOrgId,
      TenantId: crm.userInfo().pTenantId,
    };
    return params;
  }

  setDefault() {
    const { targetEmp } = this.state;
    targetEmp.added = [];
    targetEmp.new = [];
    this.setState(
      {
        targetEmp,
      },
      () => {
        this.getTargetList();
        this.props.onComplete(false);
      }
    );
  }

  deleteData(id, type) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to delete this item?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                this.deleteTargetEmployee(id, type);
                onClose();
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  // validation
  validateFields(type) {
    const { fields } = this.state;
    let error = null;
    if (fields.targetPeriod.value == "") {
      error = "Please select Target Period";
    } else if (fields.targetPeriod.value != "") {
      if (fields.targetPeriod.value == "month") {
        if (fields.targetMonth.value == "") {
          error = "Please select Target Month";
        } else if (fields.targetYear.value == "") {
          error = "Please select Target Year";
        }
      } else if (fields.targetPeriod.value == "quarter") {
        if (fields.targetQuarter.value == "") {
          error = "Please select Target Quarter";
        } else if (fields.targetYear.value == "") {
          error = "Please select Target Year";
        }
      } else if (fields.targetPeriod.value == "year") {
        if (fields.targetYear.value == "") {
          error = "Please select Target Year";
        }
      }
    }

    if (error == null && fields.targetType.value == "") {
      error = "Please select Target Type";
    }

    if (error == null) {
      this.setState({ isAllow: true });
      return true;
    } else {
      if (type == "submit") {
        common.snack("E", error);
      }
      this.setState({ isAllow: false });
      return false;
    }
  }

  // handler
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getEmpList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { filter } = this.state;
    const { name, value } = event.target;
    filter[name] = value;
    this.setState({
      filter,
    });
  };

  handleOnChange = (event, key) => {
    const { targetEmp } = this.state;
    const { name, value } = event.target;
    targetEmp[name][key].value = value;
    targetEmp[name][key].change = true;
    this.setState({
      targetEmp,
    });
  };

  handleSelectChange(data, name) {
    const { fields, selectVal } = this.state;
    fields[name] = data;
    // fields check
    if (name == "targetPeriod") {
      fields.targetQuarter = selectVal;
      fields.targetMonth = selectVal;
      fields.targetYear = selectVal;
    }
    this.setState(
      {
        fields,
      },
      () => {
        this.getTargetList();
      }
    );
  }

  // common
  setLoading(status) {
    this.setState({ isLoading: status });
  }

  setEmpLoading(status) {
    this.setState({ isEmpLoading: status });
  }

  setTargetLoading(status) {
    this.setState({ isTargetLoading: status });
  }

  setAddedLoading(status) {
    this.setState({ isAdding: status });
  }

  setModal(status) {
    this.setState({ modal: status });
    this.props.closeModal();
  }

  setConfigType(type) {
    this.setState({ configType: type });
  }

  render() {
    const {
      modal,
      configType,
      fields,
      configure,
      isLoading,
      isEmpLoading,
      isTargetLoading,
      isAdding,
      isAllow,
      currentPage,
      filter,
      employeeList,
      targetEmp,
    } = this.state;
    return (
      <div className="target-wrap" style={{ minHeight: "800px" }}>
        <div>
          <Dialog
            open={modal}
            onClose={() => this.setModal(false)}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"md"}
            style={{ minHeight: "600px" }}
            minHeight={"600px"}
            fullWidth={true}
            className="target-modal-wrap"
          >
            <DialogTitle id="responsive-dialog-title" className="popup-title">
              {configType == "config" ? "Target" : "Select Employee"}
            </DialogTitle>
            <DialogContent className="target-modal-content">
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  {configType == "config" ? (
                    <div>
                      <div className="row">
                        <div className="col-md-4 mb-4">
                          <label>Select Target Period</label>
                          <Select
                            options={configure.type}
                            value={fields.targetPeriod}
                            onChange={(data) =>
                              this.handleSelectChange(data, "targetPeriod")
                            }
                          />
                        </div>
                        {fields.targetPeriod.value == "month" && (
                          <div className="col-md-4 mb-4">
                            <label>Select Month</label>
                            <Select
                              className="select-control"
                              options={configure.month}
                              value={fields.targetMonth}
                              onChange={(data) =>
                                this.handleSelectChange(data, "targetMonth")
                              }
                            />
                          </div>
                        )}
                        {fields.targetPeriod.value == "quarter" && (
                          <div className="col-md-4 mb-4">
                            <label>Select Quarter</label>
                            <Select
                              className="select-control"
                              options={configure.quarter}
                              value={fields.targetQuarter}
                              onChange={(data) =>
                                this.handleSelectChange(data, "targetQuarter")
                              }
                            />
                          </div>
                        )}
                        {fields.targetPeriod.value != "" && (
                          <div className="col-md-4 mb-4">
                            <label>Select Year</label>
                            <Select
                              options={configure.year}
                              value={fields.targetYear}
                              onChange={(data) =>
                                this.handleSelectChange(data, "targetYear")
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label>Select Target</label>
                        <Select
                          className="select-control"
                          options={configure.targetTypes}
                          value={fields.targetType}
                          onChange={(data) =>
                            this.handleSelectChange(data, "targetType")
                          }
                        />
                      </div>

                      {isAllow && (
                        <div className="target-content-wrap mt-5 mb-2">
                          {isTargetLoading ? (
                            <Loader />
                          ) : (
                            targetEmp.added.map((item, key) => {
                              return (
                                <div
                                  className="emp-target d-flex justify-content-between align-items-center"
                                  key={key}
                                >
                                  <div className="target-user-info d-flex align-items-center">
                                    <img src={envConfig.IMG_SERVLET_URL + "?imageId=" + item.empId + "&imageType=EMPLOYEE"} />
                                    <div className="tuser-details-wrap">
                                      <h4 className="tuser-name">
                                        {item.name}
                                      </h4>
                                      <p className="tuser-designation">
                                        {item.empId}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="target-progressbar-wrap">
                                    <div className="col target-inp">
                                      <input
                                        type="number"
                                        name="added"
                                        value={item.value}
                                        onChange={(e) =>
                                          this.handleOnChange(e, key)
                                        }
                                        className="form-control"
                                      />
                                      <img
                                        src={DeleteIcon}
                                        alt="Delete"
                                        onClick={() =>
                                          this.deleteData(item.id, "added")
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                          {/* Taregt newly added */}
                          {targetEmp.new.map((item, key) => {
                            return (
                              <div
                                className="emp-target d-flex justify-content-between align-items-center"
                                key={key}
                              >
                                <div className="target-user-info d-flex align-items-center">
                                  <img src={envConfig.IMG_SERVLET_URL + "?imageId=" + item.empId + "&imageType=EMPLOYEE"} />
                                  <div className="tuser-details-wrap">
                                    <h4 className="tuser-name">{item.name}</h4>
                                    <p className="tuser-designation">
                                      {item.empId}
                                    </p>
                                  </div>
                                </div>
                                <div className="target-progressbar-wrap">
                                  <div className="col target-inp">
                                    <input
                                      type="number"
                                      name="new"
                                      min="0"
                                      value={item.value}
                                      onChange={(e) =>
                                        this.handleOnChange(e, key)
                                      }
                                      className="form-control"
                                    />
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() =>
                                        this.deleteData(item.empId, "new")
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {/* Taregt Add */}
                          {(!isTargetLoading && targetEmp.added != "") ||
                            targetEmp.new != "" ? (
                            <Link to="#"
                              className="add-target-link"
                              onClick={(e) => this.setConfigType("employee")}
                            >
                              {" "}
                              + Add More
                            </Link>
                          ) : (
                            <Link to="#"
                              className="add-target-link"
                              onClick={(e) => this.setConfigType("employee")}
                            >
                              {" "}
                              + Add Employee
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="module-result">
                        <div className="mb-3">
                          <label>Employee</label>
                          <div className="input-group search-filter-group mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="inlineFormInputGroup"
                              placeholder="Search"
                              name="searchTerm"
                              value={filter.searchTerm}
                              onChange={this.handleFieldChange}
                            />
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <img
                                  src={SearchIcon}
                                  alt="Search"
                                  onClick={this.getEmpList}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {isEmpLoading ? (
                          <Loader />
                        ) : employeeList == "" ? (
                          <NoRecordFound />
                        ) : (
                          <ul className="result-list row">
                            {employeeList.items.map((row, key) => {
                              return (
                                <li
                                  className={`col-md-6 ${this.checkTargetEmp(
                                    row.EmployeeId,
                                    "added"
                                  ) ||
                                    this.checkTargetEmp(row.EmployeeId, "new")
                                    ? "item-select-active"
                                    : ""
                                    }`}
                                  onClick={(e) => this.selectTargetEmp(row)}
                                  key={row.EmployeeId}
                                >
                                  <div className="result-content d-flex">
                                    <div className="module-img">
                                      <img src={envConfig.IMG_SERVLET_URL + "?imageId=" + row.EmployeeId + "&imageType=EMPLOYEE"} />
                                    </div>
                                    <div className="module-details">
                                      <h3>
                                        {row.EmployeeName
                                          ? row.EmployeeName
                                          : "-"}
                                      </h3>
                                      <p>
                                        {row.EmployeeId ? row.EmployeeId : "-"}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <div className="module-list">
                          {!isEmpLoading && employeeList != "" && (
                            <div className="d-flex bg-white">
                              <div className="pagination-wrap">
                                <Pagination
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  activePage={currentPage}
                                  itemsCountPerPage={employeeList.limit}
                                  totalItemsCount={employeeList.totalResults}
                                  pageRangeDisplayed={7}
                                  onChange={this.handlePageChange.bind(this)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </DialogContent>
            {!isLoading && (
              <DialogActions className="popup-footer justify-content-start">
                {configType == "config" ? (
                  <div className="botBtn">
                    <div>
                      <Button
                        className="saveBtn"
                        onClick={() => this.saveTargetEmployee()}
                        type="submit"
                        disabled={isAdding}
                      >
                        {" "}
                        {isAdding ? "Processing..." : "Save"}{" "}
                      </Button>
                      <Button
                        className="cancelBtn"
                        onClick={() => this.setModal(false)}
                        type="button"
                        disabled={isAdding}
                      >
                        {" "}
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="botBtn">
                    <div>
                      <Button
                        className="saveBtn"
                        onClick={() => this.setConfigType("config")}
                        type="submit"
                      >
                        {" "}
                        Done{" "}
                      </Button>
                    </div>
                  </div>
                )}
              </DialogActions>
            )}
          </Dialog>
        </div>
      </div>
    );
  }
}
