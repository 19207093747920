import React from "react";
import Paper from "material-ui/Paper";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// switch
import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Actions
import { commonActions } from "_actions/common.actions";
import { restServices, common, history } from "_helpers";
//Import CSS
import "../css/Lead.css";
// Import Loader
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class LeadDetails extends React.Component {
  constructor(props) {
    super(props);
    /**t
     * layoutFormat => Get form layout form API resources
     * jsonAttributes => Have stored all input type json properties
     * => USAGE: Reduce looping system have created ( jsonAttributes ) object
     * fieldConfig => Have stored all input key configuration
     */
    this.state = {
      page: "lead",
      leadID: 0,
      leadData: {},
      crmStatusList: [],
      layoutFormat: {},
      jsonAttributes: {},
      updatePermission: false,
      returnQuery: "",
      fieldConfig: {
        CampaignId: {
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        TerritoryId: {
          getOne:
            envConfig.BASE_API + Services.CRM_TERRITORIES + "/TERRITORY_NAME",
          get: "TerritoryId",
          set: "TerritoryName",
          searchKey: "TERRITORY_NAME",
        },
        ListId: {
          getOne: envConfig.BASE_API + Services.CRM_LISTS + "/LIST_NAME",
          get: "ListId",
          set: "ListName",
          searchKey: "LIST_NAME",
        },
        SalespersonId: {
          getOne:
            envConfig.BASE_API + Services.CRM_EMPLOYEES + "/EMPLOYEE_NAME",
          get: "EmployeeId",
          set: "EmployeeName",
          searchKey: "EMPLOYEE_NAME",
        },
        OrgId: {
          getOne: envConfig.BASE_API + Services.BASE_ORGANIZATION + "/ORG_NAME",
          get: "OrgId",
          set: "OrgName",
          searchKey: "ORG_NAME",
        },
        CustAccountId: {
          getOne: envConfig.BASE_API + Services.CRM_CUSTOMER + "/CUSTOMER_NAME",
          get: "CustomerId",
          set: "CustomerName",
          searchKey: "CUSTOMER_NAME",
        },
        State: {
          getOne: envConfig.BASE_API + Services.CRM_STATES + "/STATE_NAME",
          get: "StateId",
          set: "StateName",
          searchKey: "STATE_NAME",
        },
        City: {
          getOne: envConfig.BASE_API + Services.CRM_CITIES + "/CITY_NAME",
          get: "CityId",
          set: "CityName",
          searchKey: "CITY_NAME",
        },
      },
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.lead
      ),
    };
    this.state.returnQuery = common.getCrmReturnQuery();
  }

  /**
   * Hooks Methods
   */
  componentDidMount() {
    this.setState(
      {
        leadID: this.props.match.params.leadId,
        page: window.location.href.includes("mylead") ? "mylead" : "lead",
      },
      (_) => {
        this.getFormJSON();
      }
    );
    this.getCRMStatusList();
  }

  // MAR: API Methods
  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_LEAD_CODE;
    this.setState({
      isLoading: true,
    });
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          this.setState(
            {
              layoutFormat: JSON.parse(result.FormFields),
              isLoading: false,
            },
            () => {
              this.getLeadDetail(this.state.leadID);
              this.createDynamicFormFields();
            }
          );
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  /**
   * Get each dynamic field API details and bind value to json attributes
   */
  getDetail = (url, name) => {
    const { fieldConfig, jsonAttributes } = this.state;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          let config = fieldConfig[name];
          jsonAttributes[name].value = response[config.set];
          this.setState({
            jsonAttributes: jsonAttributes,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Get Lead Details by ID
   */
  getLeadDetail = (id) => {
    const { jsonAttributes, fieldConfig, userPermissions, updatePermission } = this.state;
    const { deptEmployees } = this.props;
    let url = envConfig.BASE_API + Services.CRM_LEADS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response) {
            for (var key of Object.keys(response)) {
              if (response[key]) {
                if (jsonAttributes[key] !== undefined) {
                  jsonAttributes[key].value = response[key];
                }
                if (fieldConfig[key]) {
                  if (fieldConfig[key].hasOwnProperty("getOne")) {
                    fieldConfig[key].getOne = fieldConfig[key].getOne.replace(
                      fieldConfig[key].searchKey,
                      response[key]
                    );
                    this.getDetail(fieldConfig[key].getOne, key);
                  }
                }
              }
            }
            //TODO - no salespersonid and set access true for own record   
            let userData = common.authInfo();
            let access = userData.roleId === 0 ? true : common.validPermission(response.SalespersonId, "update", userPermissions, deptEmployees, response.CreatedBy);
            this.setState({
              leadData: response,
              jsonAttributes: jsonAttributes,
              fieldConfig: fieldConfig,
              updatePermission: access
            });
          }
        }
      },
      (error) => {
        this.setState({
          leadData: {},
        });
      }
    );
  };

  /**
   * Assign default value based on input type
   * @param {*} type
   */
  getDefaultValueByType = (type) => {
    switch (type) {
      case "integer":
        return 0;
      case "string":
        return "";
      default:
        return null;
    }
  };

  /**
   * Create dynamic form through given json attributes
   */
  createDynamicFormFields = () => {
    const { layoutFormat, jsonAttributes } = this.state;

    if (
      layoutFormat &&
      layoutFormat.columns &&
      layoutFormat.columns.length > 0
    ) {
      layoutFormat.columns.forEach((column) => {
        column.groups.forEach((column) => {
          column.rows.forEach((row) => {
            row.columns.forEach((rowColum) => {
              jsonAttributes[rowColum.key] = rowColum;
            });
          });
        });
      });

      this.setState({
        jsonAttributes: jsonAttributes,
      });
    }
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let leadTypeId = common.getTransactionTypeId(envConfig.LEAD_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMStatus(leadTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmstatus } = props;

    var crmStatusData = state.crmStatusList;

    if (crmstatus && crmstatus.list && crmstatus.list.success) {
      crmStatusData = crmstatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  /**
   * Load lead status position based on filtered index
   */
  getStatusPosition = () => {
    const { leadData, crmStatusList } = this.state;
    if (
      leadData.LeadStatus &&
      leadData.LeadStatus != "" &&
      leadData.LeadStatus != null
    ) {
      var position = crmStatusList.findIndex(
        (x) => x.StatusCode == leadData.LeadStatus
      );
      return position >= 0 ? position : -1;
    }
  };

  /**
   * Load block of code or component based on the input key
   */
  renderBlock = (attr) => {
    let userData = common.authInfo();
    let role = userData.roleId || 0;
    let access = "";
    let permissions = attr.permission ? attr.permission : [];

    permissions.map((obj) => {
      if (obj.role == role) {
        access = obj.access;
      }
    });
    if (access != "DontShow") {
      switch (attr.input) {
        case "check_box":
          return (
            // <Checkbox
            //   disabled
            //   checked={attr.value == "Y" ? true : false}
            //   label={attr.name}
            //   labelStyle={{ color: "#A7AFC2" }}
            //   labelPosition="left"
            // />
            <Form.Group>
              <FormControlLabel
                className="d-flex justify-content-between margin-none"
                control={
                  <Switch
                    checked={attr.value == "Y" ? true : false}
                    name={attr.name}
                    color="primary"
                    onChange={() => { return false; }}
                  />
                }
                label={attr.name}
                labelPlacement="start"
              />
            </Form.Group>
          );
        default:
          return (
            <div>
              <label className="form-label">{attr.name}</label>
              <p className="form-value">{attr.value ? attr.value : "-"}</p>
            </div>
          );
      }
    }
  };

  render() {
    const {
      isLoading,
      leadID,
      crmStatusList,
      layoutFormat,
      page,
      leadData,
      updatePermission,
      userPermissions
    } = this.state;

    return (
      <div>
        {userPermissions.read == 2 ? (
          <AccessDenied />
        ) : (
            <div>
              {isLoading == false && (
                <div className="lead-wraper">
                  <div className="formTab">
                    <ul>
                      {crmStatusList.map((data, idx) => (
                        <li
                          key={idx}
                          className={`tabShape whiteTab ${this.getStatusPosition() >= idx ? "active" : ""
                            }`}
                        >
                          {" "}
                          {/*  style={{backgroundColor: data.StatusColor}} */}
                          <span className="tri-right"></span>{" "}
                          {/*  style={{borderBottomColor: data.StatusColor}} */}
                          <Badge badgeContent={++idx} color="primary">
                            {data.StatusName}
                          </Badge>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="row">
                    {layoutFormat.columns &&
                      layoutFormat.columns.length > 0 &&
                      layoutFormat.columns.map((pcoloum, pindex) => (
                        <div className="col" key={pindex}>
                          {pcoloum.groups.map((group, gindex) => (
                            <Paper style={styles.boxLayout} key={gindex}>
                              <div className="box-title-wrap justify-content-between">
                                <h3 className="card-title">{group.title}</h3>
                                {updatePermission && (
                                  <Link
                                    className="card-link"
                                    to={(history.location.pathname.match("crm") ? "/crm/" : "/work/") + page + "/edit/" + leadID + this.state.returnQuery}
                                  >
                                    Edit
                                  </Link>
                                )}
                              </div>
                              {group.rows.map((row, rindex) => (
                                <div
                                  className={
                                    row.noOfColumns == 1 ? "list-row" : "row"
                                  }
                                  key={rindex}
                                >
                                  {row.columns.map((rcoloum, rcindex) => (
                                    common.checkFieldPermission(rcoloum) && (
                                      <div
                                        className={
                                          1 == row.noOfColumns
                                            ? ``
                                            : `col-${12 / row.noOfColumns}`
                                        }
                                        key={rcindex}
                                      >
                                        <div
                                          className={
                                            1 == row.noOfColumns ? `` : `list-row`
                                          }
                                        >
                                          {this.renderBlock(rcoloum)}
                                        </div>
                                      </div>
                                    )
                                  ))}
                                </div>
                              ))}
                            </Paper>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {isLoading == true && <Loader />}
            </div>
          )}
      </div>
    );

  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

const mapStateToProps = (state) => {
  const { crmstatus } = state;
  return { crmstatus };
};
export default connect(mapStateToProps)(LeadDetails);
