import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// Defined Errors
const defErrs = {
    
};

class TaxIdentification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            autoloading: false,
            formSubmit: false,
            fields: {     
                GstNo: "",
                CstNo: "",
                FaxNo: "",
                TinNo: "",
                EccNo: "",
                SicCode: "",
                CustCategory: ""
            },
            commonResource: {
                CustomerId: 0,
            },
            masterData: {
                typeOfCategory: []
            },
            errors: {}
        };
    }

    componentDidMount() {
        const { commonResource } = this.state;
        this.defineInitErrorMsg();
        //get lookup value
        this.getLookUpData("CUSTOMER_TYPE", "typeOfCategory");

        if (this.props.objectId != undefined) {

            commonResource.CustomerId = this.props.objectId ? this.props.objectId : 0;

            this.setState({
                commonResource: commonResource,
            }, () => {
                if (this.state.commonResource.objectId > 0) {
                    this.getDetailById(commonResource.objectId)
                }
            });

        }
    }

    defineInitErrorMsg = () => {
        const { errors } = this.state;
        for (var key of Object.keys(defErrs)) {
            errors[key] = defErrs[key].isReq;
        }
        this.setState({
            errors: errors,
        });
    };

    getLookUpData = (lookupValue, source) => {
        const { masterData } = this.state;
        this.setState({ isLoading: true });

        let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
        let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    masterData[source] = common.getOptionsData(response.items, "Name", "Code");
                    this.setState({
                        masterData: masterData
                    });
                }
            },
            (error) => { }
        )
    };

    /**
     * Redirect to territory coverage tab
     */
    onCancel = () => {
        const { fields } = this.state;
        history.push("/territory/" + fields.TerritoryId + "?tab=coverage");
    }

    handleOnChange = event => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = (checked ? "Y" : "N");
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, inputValue);
        });
    };

    selectHandleOnChange = (event, name) => {
        const { value } = event;
        const {  fields } = this.state;
    
        if (fields.hasOwnProperty(name)) {
          fields[name] = value;
        }
        this.setState({ fields: fields }, () => {
          this.validateForm(name, value);
        });
      }
    

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (defErrs.hasOwnProperty(name)) {
                errors[name] = defErrs[name].isReq;
            }
        } else {
            // Optional Validation Here
            switch (name) {
                default:
                    errors[name] = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    getDetailById = (id) => {
        const { fields, errors } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + id);


        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    for (var key of Object.keys(response)) {
                        if (fields.hasOwnProperty(key)) {
                            if (response[key]) {
                                if (key === "StartDate" || key === "EndDate") {
                                    fields[key] = response[key] ? new Date(response[key]) : ""
                                } else {
                                    fields[key] = response[key];
                                }
                                // Remove Default Errors
                                if (errors.hasOwnProperty(key)) {
                                    errors[key] = "";
                                }
                            }
                        }
                    }

                    this.setState({
                        fields: fields,
                        errors: errors
                    }, () => {
                    });

                } else {}
            },
            (error) => {}
        );
    }

    save = (event) => {
        event.preventDefault();

        const { errors, commonResource } = this.state;
        var isValid = true;

        this.setState({
            formSubmit: true,
        });

        for (var key of Object.keys(errors)) {
            if (!isValid) {
                break;
            }
            isValid = !errors[key];
        }

        if (isValid) {
            if (commonResource.CustomerId > 0) {
                this.update();
            }
        }

    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + commonResource.CustomerId;
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    common.snack("S", "Successfully Updated");
                }
            },
            (error) => {}
        )
    }

    render() {
        const {
            fields,
            masterData,
            commonResource,
            errors,
            formSubmit,
        } = this.state;

        return (
            <div className="lead-wraper">
                <Form onSubmit={this.save}>
                    <div className="formWhiteBg">
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Excise No(ECC)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="EccNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.EccNo}
                                    />
                                    {errors.EccNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.EccNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Fax No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="FaxNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.FaxNo}
                                    />
                                    {errors.FaxNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.FaxNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>CST No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="CstNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.CstNo}
                                    />
                                    {errors.CstNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.CstNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>SIC Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="SicCode"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.SicCode}
                                    />
                                    {errors.SicCode !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.SicCode}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>TIN No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="TinNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.TinNo}
                                    />
                                    {errors.TinNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.TinNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>GST No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="GstNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.GstNo}
                                    />
                                    {errors.GstNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.GstNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Pan No</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="PanNo"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.PanNo}
                                    />
                                    {errors.PanNo !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.PanNo}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Category</Form.Label>
                                    <div className="select-control">
                                        <Select
                                            value={masterData.typeOfCategory.find(o => o.value === fields.CustCategory)}
                                            onChange={(e) => this.selectHandleOnChange(e, 'CustCategory')}
                                            options={masterData.typeOfCategory}
                                        />
                                    </div>
                                    {errors.CustCategory !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.CustCategory}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="botBtn">
                            <Col sm={12}>
                                <Button className="saveBtn" type="submit">
                                    {commonResource.CustomerId > 0 ? "Update" : "Save"}
                                </Button>
                                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

export default TaxIdentification;
