import { envConfig } from '_config/config';
import * as Service from '_config/api';
import { common } from "_helpers";

export const commonService = {
    getCRMStatus,
}

/**
 * Get CRM status from API
 */
function getCRMStatus(statusType) {
    let userData = common.authInfo();
    let token = localStorage.getItem("userToken");
    let params = `?q=TransactionTypeId=${statusType};OrgId=` + 
    `${userData.DefaultOrgId};TenantId=` + 
    `${userData.TenantId};Active=Y` +
    `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Service.TRANSACTION_STATUS + params;

    const requestOptions = {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + token
        },
    };

    return fetch(url, requestOptions)
    .then(response => {
        return response.json().then(json => {
            return json;
        })
    })
}
