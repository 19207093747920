import React from "react";
import { Link } from "react-router-dom";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tableConfig } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";

//Import CSS
import "./ProductServices.css";

import SearchIcon from "img/search-icn.png";
import Pagination from "react-js-pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});
class ProductServices extends React.Component {
  constructor() {
    super();
    this.state = {
      pageEntries: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      filterSummary: "",
      objectId: 0,
      objectType: "",
      tlConfig: {
        headCells: [
          { id: "ProductType", value: "ProductType", label: "Line Type" },
          { id: "ItemNumber", value: "ItemNumber", label: "Item Number" },
          { id: "ItemName", value: "ItemName", label: "Item Name" },
          { id: "Qty", value: "Qty", label: "QTY" },
          { id: "Uom", value: "Uom", label: "UOM" },
          { id: "ExpRevenue", value: "ExpRevenue", label: "Expected Revenue" },
          { id: "Currency", value: "Currency", label: "Currency" },
        ],
        columnsSelected: [],
        order: "desc",
        orderBy: "CreatedOn",
        selected: [],
        uniqueKey: "ProductListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getProductService();
        }
      );
    }
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleMultileSelectChange = (selected) => {
    this.setState({
      columnsSelected: selected,
    });
    this.storecolumnsSelected(selected);
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getProductService();
      }
    );
  };

  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.setState(
        {
          objectId: this.props.objectId,
          objectType: this.props.objectType,
        },
        () => {
          this.getProductService();
        }
      );
    }
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes, objectType } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          let newSelecteds;
          if (objectType == "lead") {
            newSelecteds = listRes.items.map((n) => n.LeadProId);
          } else {
            newSelecteds = listRes.items.map((n) => n.OppProId);
          }

          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig, objectType } = this.state;
    let inputData = [];

    var filterByType = "";

    switch (objectType) {
      case "lead":
        filterByType = Services.CRM_LEADS_PRODUCTS;
        break;
      case "deal":
        filterByType = Services.CRM_DEALS_PRODUCTS;
        break;
      default:
        break;
    }

    tlConfig.selected.map((id, index) => {
      inputData.push({
        id: "part" + (index + 1),
        path: "/" + filterByType + "/" + id,
        operation: "delete",
        payload: {},
      });
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getProductService();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig,
          });
          common.snack("S", "Selected items has been deleted successfully.");
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  handlePageEntries = (e, val) => {
    this.setState({
      pageEntries: val,
    });
  };

  getRequestURLByType = () => {
    const {
      objectType,
      objectId,
      currentPage,
      OrderBy,
      pageLimit,
      filterSummary,
    } = this.state;

    var filterByType = "";

    switch (objectType) {
      case "lead":
        filterByType = Services.CRM_LEADS_PRODUCTS + `?q=LeadId=${objectId} AND `;
        break;
      case "deal":
        filterByType =
          Services.CRM_DEALS_PRODUCTS + `?q=OpportunityId=${objectId} AND `;
        break;
      default:
        break;
    }

    let filter =
      filterByType +
      `UPPER(ItemName) LIKE '*${filterSummary}*' &orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${
        (currentPage - 1) * pageLimit
      }`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return encodeURI(envConfig.BASE_API + filter);
  };

  /**
   * Get all Products from DB
   */
  getProductService = () => {
    const { tlConfig } = this.state;
    this.setState({ isLoading: true });

    let url = this.getRequestURLByType();
    restServices.getRequest(url, (response) => {
      if (response) {
        tlConfig.enableMultipleDelete =
          response && response.items && response.items.length ? true : false;
        tlConfig.delItemCount = tlConfig.enableMultipleDelete
          ? response.items.length
          : 0;
        this.setState({
          listRes: response,
          isLoading: false,
          tlConfig: tlConfig,
        });
      }
    });
  };

  render() {
    const {
      isLoading,
      listRes,
      currentPage,
      pageLimit,
      filterSummary,
      objectId,
      objectType,
      tlConfig,
    } = this.state;

    const isHided = (ProId) => tlConfig.columnsSelected.indexOf(ProId) !== -1;
    const isSelected = (ProId) => tlConfig.selected.indexOf(ProId) !== -1;

    const handleClick = (event, ProId) => {
      const selectedIndex = tlConfig.selected.indexOf(ProId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, ProId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    const getProductServiceIdByType = (row) => {
      switch (objectType) {
        case "lead":
          return row.LeadProId;
          break;
        case "deal":
          return row.OppProId;
          break;
        default:
          return 0;
      }
    };

    return (
      <div className="lead-wraper">
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-4">
              <div className="form-row">
                <div className="col">
                  <h2 className="crm-type-title">Products/Services</h2>
                </div>
                <div className="col-auto"></div>
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="form-row justify-content-end">
                <div className="col-lg-4">
                  <label className="sr-only" htmlFor="inlineFormInputGroup">
                    Filter and Search
                  </label>
                  <div className="input-group search-filter-group mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="inlineFormInputGroup"
                      placeholder="+ Filter and Search"
                      name="filterSummary"
                      value={filterSummary}
                      onChange={this.handleFieldChange}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <img
                          src={SearchIcon}
                          alt="Search"
                          onClick={this.getProductService}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  <button type="button" className="btn btn-export">
                    Export
                  </button>
                </div>

                <div className="col-auto">
                  <Link
                    to={"/addproduct/" + objectType + "/" + objectId}
                    className="btn btn-add"
                  >
                    Add
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <EnhancedTableToolbar
              tlConfig={tlConfig}
              deleteSelected={this.deleteSelected}
            />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        listRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(
                          getProductServiceIdByType(row)
                        );
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, getProductServiceIdByType(row))
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.ResourceId}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className={
                                !isHided("ProductType") ? "hideCell" : ""
                              }
                            >
                              <Link
                                to={
                                  "/addproduct/" +
                                  objectType +
                                  "/" +
                                  objectId +
                                  "/" +
                                  getProductServiceIdByType(row)
                                }
                              >
                                {row.ProductType ? row.ProductType : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("ItemNumber") ? "hideCell" : ""
                              }
                            >
                              {row.ItemNumber ? row.ItemNumber : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("ItemName") ? "hideCell" : ""}
                            >
                              {row.ItemName ? row.ItemName : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("Qty") ? "hideCell" : ""}
                            >
                              {row.Qty ? row.Qty : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("Uom") ? "hideCell" : ""}
                            >
                              {row.Uom ? row.Uom : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("ExpRevenue") ? "hideCell" : ""
                              }
                            >
                              {row.ExpRevenue ? row.ExpRevenue : "-"}
                            </TableCell>
                            <TableCell
                              className={!isHided("Currency") ? "hideCell" : ""}
                            >
                              {row.Currency ? row.Currency : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* )} */}

            {isLoading == false && listRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  Showing {(currentPage - 1) * pageLimit + 1} to{" "}
                  {(currentPage - 1) * pageLimit + listRes.count} of{" "}
                  {listRes.totalResults} entries
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={listRes.limit}
                    totalItemsCount={listRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
          {/* )} */}
        </div>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default ProductServices;
