import React from "react";
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { crm } from "_utils";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history, tools } from "_helpers";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

// Import component CSS file
import "../css/MarketingLists.css";

// Import Images
import SearchIcon from "img/search-icn.png";
import EditIcon from "img/edit.svg";
import DeleteIcon from "img/delete.svg";

const userInfo = common.authInfo();
class MarketingLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: 0,
      listResponse: [],
      memberListResponse: {},
      isLoading: false,
      isListResponseLoad: false,
      isMemberListResponseLoad: false,
      currentPage: 1,
      pageLimit: 5,
      listId: 0,
      filterListName: "",
      filterName: "",
      paging: {
        page: 1,
        size: 5,
        total: 1,
      },
      totalRecords: 0,
      isQuery: false,
      reload: new Date(),
      listName: "",
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState({
        currentPage: pageNumber,
      });
    }
  }

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleClickMList = (event, row) => {
    event.preventDefault();
    this.setState({
      listId: row.ListId,
      listName: row.ListName,
      reload: new Date(),
    });
  };

  handleUpdateListId = (event, row) => {
    event.preventDefault();
    this.setState(
      {
        listId: row.ListId,
      },
      () => {
        history.push(
          "/addmarketinglist/" +
          this.state.campaignId +
          "/" +
          row.ListId +
          "?filterListName=" +
          this.state.filterListName +
          "&listPage=" +
          this.state.paging.page +
          "&filterName=" +
          this.state.filterName +
          "&page=" +
          this.state.currentPage
        );
      }
    );
  };

  handleMLChange = (event) => {
    const { paging } = this.state;
    const { name, value } = event.target;
    this.setState(
      {
        filterListName: value,
      },
      () => {
        //reset page number to 1
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getAllMarketingLists();
          }
        );
      }
    );
  };

  handleMultileSelectChange = (selected) => {
    this.setState({
      columnsSelected: selected,
    });
    this.storecolumnsSelected(selected);
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let filterName = params.get("filterName");
    let page = params.get("page");
    let filterListName = params.get("filterListName");
    let listPage = params.get("listPage");
    let listId = params.get("listId");

    if (listId) {
      let paging = this.state.paging;
      paging.page = parseInt(listPage);
      paging.total = 5;

      this.setState(
        {
          filterName,
          filterListName,
          currentPage: page,
          paging,
          isQuery: true,
          listId,
          isMemberListResponseLoad: true,
        },
        (_) => { }
      );
    }
    if (this.props.campaignId != undefined) {
      this.setState(
        {
          campaignId: this.props.campaignId,
        },
        () => {
          this.hooksDidMountMethod();
        }
      );
    }
  }

  /**
   * Trigger Delete Marketing List Popup
   * @param {*} id
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  };

  /**
   * Delete Marketing List by ID
   * @param {*} listId
   */
  handleClickDeleteList = (listId) => {
    const { paging } = this.state;
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_LISTS + "/" + listId;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState(
          {
            paging: paging,
          },
          () => {
            this.getAllMarketingLists();
          }
        );
        common.snack("S", "Enquiry List deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  hooksDidMountMethod = () => {
    this.getAllMarketingLists(this.state.isQuery);
  };

  /**
   * Get all Marketing Lists from DB
   */
  getAllMarketingLists = (queryParam = false) => {
    const { campaignId, paging, listResponse, filterListName } = this.state;

    let tempList = [];

    if (!queryParam && paging.page == 1) {
      this.setState({
        isMemberListResponseLoad: true,
        listResponse: [],
        memberListResponse: { items: [], totalResults: 0 },
      });
    } else {
      listResponse.map((item) => {
        tempList.push(item);
      });
    }

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId
      } AND CampaignId=${campaignId ? campaignId : ""
      } AND UPPER(ListName) LIKE '*${filterListName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    if (queryParam) {
      filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId
        } AND CampaignId=${campaignId ? campaignId : ""
        } AND UPPER(ListName) LIKE '*${filterListName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=0&limit=${paging.page * paging.size
        }`;
    }

    let url = encodeURI(envConfig.BASE_API + Services.CRM_LISTS + filter);
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let SelectedListId = this.state.listId;

          if (response && response.items && response.items.length > 0) {
            // paging
            paging.total = Math.ceil(response.totalResults / paging.size);

            response.items.map((item, key) => {
              tempList.push(item);
            });

            if (!queryParam && paging.page === 1) {
              SelectedListId = tempList[0].ListId;
            }

            this.setState({
              totalRecords: response.totalResults,
              listResponse: tempList,
              isMemberListResponseLoad: false,
              paging: paging,
              listId: SelectedListId,
              listName: tempList.length > 0 ? tempList[0].ListName : "",
            });
          } else {
            this.setState({
              isMemberListResponseLoad: false,
              listResponse: tempList,
              memberListResponse: {
                items: [],
                totalResults: 0,
              },
            });
          }
        }
      },
      (error) => { }
    );
  };

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getAllMarketingLists();
      }
    );
  }

  convertLead = (selected) => {
    const { listId, campaignId } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    var formAttr = {
      name: "convertListDtlToLead",
      parameters: [
        { P_LIST_DTL_ID: selected.join(",") },
        { P_LIST_ID: parseInt(listId) },
        { P_LEAD_NAME: "", },
        { P_LEAD_TYPE: "", },
        { P_CUSTOMER_ID: "", },
        { P_TERRITORY_ID: userData.territory[0] },
        { P_CAMPAIGN_ID: campaignId ? parseInt(campaignId) : null },
        { P_SALES_PERSON_ID: userInfo.EmpId },
      ],
    };

    let url = encodeURI(envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION);

    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        if (response.status >= 200 && response.status <= 300) {
          this.setState({
            reload: new Date(),
          });
          this.props.reload();
          common.snack(
            "S",
            "Selected Members are converted as lead successfully."
          );
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  onAction = (data) => {
    const { listId, campaignId } = this.state;
    if (data.type == "convert") {
      //Verify member is converted or not
      let cRecords = Object.keys(data.selectedData).filter((obj) => {
        return data.selectedData[obj].LeadConverted == "Y";
      });
      let ncRecords = Object.keys(data.selectedData).filter((obj) => {
        return data.selectedData[obj].LeadConverted == "N" || data.selectedData[obj].LeadConverted == null;
      });

      if (cRecords.length == 0) {
        this.convertLead(data.selected);
      } else {
        let cMembers = "";
        cRecords.map((obj) => {
          cMembers = cMembers + " " + data.selectedData[obj].FirstName + ",";
        });
        let ncMembers = "";
        ncRecords.map((obj) => {
          ncMembers = ncMembers + " " + data.selectedData[obj].FirstName + ",";
        });
        if(ncRecords.length > 0){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>Conversion Confirmation</h1>
                  <p>The following Members are already converted as Lead</p>
                  <p>{cMembers}</p>
                  <p>
                    Are you sure you want to convert the remaining selected Members?
                  </p>
                  <p>{ncMembers}</p>
                  <button className="noBtn" onClick={onClose}>
                    No
                  </button>
                  <button
                    onClick={() => {
                      this.convertLead(ncMembers);
                      onClose();
                    }}
                    className="yesBtn"
                  >
                    Yes
                  </button>
                </div>
              );
            },
          });
        }else{
          common.snack("E", "Already selected Members are converted as Lead")
        }
      }
    } else if (data.type == "quick") {
      history.push("/campaign/member/quickadd/" + listId + "/" + campaignId);
    } else if (data.type == "edit" || data.type == "view") {
      history.push(
        "/updatemember/" + listId + "/" + campaignId + "/" + data.id
      );
    } else if (data.type == "add") {
      history.push("/addmember/" + listId + "/" + campaignId);
    } else if (data.type == "import") {
      history.push("/crm/bulk/import/member/" + listId);
    }
  };

  render() {
    const {
      campaignId,
      isMemberListResponseLoad,
      listId,
      listResponse,
      paging,
      totalRecords,
      filterListName,
      reload,
      listName,
    } = this.state;

    return (
      <div className="crm-list-wrap">
        <Row>
          <Col sm={3}>
            <div className="marketing-list-wrap">
              <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                <h2 className="crm-type-title">Enquiry List</h2>
                <Link
                  to={"/addmarketinglist/" + campaignId}
                  className="btn btn-add"
                >
                  Add
                </Link>
              </div>

              <Paper>
                <div className="input-group search-ml-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <img
                        src={SearchIcon}
                        alt="Search"
                        onClick={this.getAllMarketingLists}
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="marketListName"
                    onChange={(e) => this.handleMLChange(e)}
                    placeholder="Search"
                    name="filterListName"
                    value={filterListName}
                  />
                </div>
                <ul className="marketing-list">
                  {isMemberListResponseLoad == false && listResponse.length > 0
                    ? listResponse.map((row, index) => {
                      return (
                        <li
                          onClick={(e) => this.handleClickMList(e, row)}
                          key={row.ListId}
                          className={`d-flex justify-content-between align-items-center ${row.ListId == listId ? "active" : ""
                            }`}
                        >
                          <div className="mlLeft">
                            <h4 className="ml-name">{row.ListName}</h4>
                            <p className="ml-type">{row.ListType}</p>
                          </div>
                          <div className="mlRight">
                            <Link
                              to="#"
                              onClick={(e) => this.handleUpdateListId(e, row)}
                            >
                              <img src={EditIcon} alt="Edit" />
                            </Link>
                            <Link
                              to="#"
                              style={{ marginLeft: 10 }}
                              onClick={() =>
                                this.deleteSelectedItem(row.ListId)
                              }
                            >
                              <img src={DeleteIcon} alt="Delete" />
                            </Link>
                          </div>
                        </li>
                      );
                    })
                    : isMemberListResponseLoad == false && (
                      <li>
                        <NoRecordFound />
                      </li>
                    )}
                  {isMemberListResponseLoad == true && (
                    <li>
                      <Loader />
                    </li>
                  )}
                </ul>
                {/* Pagination */}
                {listResponse.length > 0 && (
                  <div className="load-more-wrap">
                    {paging.page != paging.total && listResponse.length > 0 && (
                      <Link
                        to="#"
                        onClick={(e) => this.pagination(e)}
                        className="load-more"
                      >
                        {" "}
                        Load More{" "}
                      </Link>
                    )}
                    <div className="paging-text">
                      1 to {listResponse.length} of {totalRecords} entries
                    </div>
                  </div>
                )}
              </Paper>
            </div>
          </Col>
          <Col sm={9}>
            <h4 className="mb-6">{listName}</h4>
            {listId > 0 ? (
              <Paper>
                <OsmoMaterialTable
                  module="enquiryMember"
                  add="Add New"
                  convert="Convert To Lead"
                  quick="Add Quick"
                  update="Mass Update"
                  import="Mass Import"
                  reload={reload}
                  query={{ ListId: listId }}
                  sort="CreatedOn:desc"
                  data="LeadConverted,FirstName, LastName, CompanyName"
                  onAction={(data) => this.onAction(data)}
                />
              </Paper>
            ) : (
              <NoRecordFound />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default MarketingLists;
