import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

class EmployeeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      territoryName: '',
      userPermissions: this.props.userPermissions,
    }
  }

  /**
   * Component Hooks
   */
  componentDidUpdate(prevProps) {
    const { selectedEmp } = this.props;
    if (selectedEmp.EmployeeId && selectedEmp.EmployeeId != prevProps.selectedEmp.EmployeeId) {
      this.getEmpTerritoryFromSalesTeam();
    }
  }

  getEmpTerritoryFromSalesTeam() {
    const { selectedEmp } = this.props;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_TERRITORY_TEAM + "?q=ResourceId=" + selectedEmp.EmployeeId);

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response.items.length > 0) {
            let tName = "";
            response.items.map((obj) => {
              tName = tName + obj.TerritoryName + ", ";  
            });
            if(tName.length > 2){
              tName = tName.substring(0, tName.length - 2);
            }
            console.log(tName);
            this.setState({
              territoryName: tName
            });
          }
        }
      },
      (error) => { }
    );
  }

  render() {
    const { userPermissions } = this.state;
    const { selectedEmp, empAssignment } = this.props;
    const isValidProp = (key) => (selectedEmp[key] ? selectedEmp[key] : "-");
    const isValidAssignment = (key) =>
      empAssignment[key] ? empAssignment[key] : "-";
    const mailTo = `mailto:` + selectedEmp["Email"];

    return (
      <div>
        {this.props.selectedEmp.EmployeeId != undefined && (
          <div>
            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">EMPLOYEE INFORMATION</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/employee/edit/" +
                      selectedEmp.EmployeeId +
                      "?empCode=" +
                      this.props.empCode +
                      "&email=" +
                      this.props.email +
                      "&return=" +
                      this.props.return +
                      "&filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Employee Number</label>
                    <p className="form-value">{isValidProp("EmployeeCode")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Title</label>
                    <p className="form-value">{isValidProp("Title")}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">First Name</label>
                    <p className="form-value">{isValidProp("FirstName")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Midde Name</label>
                    <p className="form-value">{isValidProp("MiddleName")}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Last Name</label>
                    <p className="form-value">{isValidProp("LastName")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Employee Name</label>
                    <p className="form-value">{isValidProp("FirstName")} {isValidProp("LastName")}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Gender</label>
                    <p className="form-value">{isValidProp("Gender")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Email Address</label>
                    <p className="form-value">
                      <Link to={mailTo}>{isValidProp("Email")}</Link>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Working Status</label>
                    <p className="form-value">{isValidProp("WorkingStatus")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Mobile</label>
                    <p className="form-value">{isValidProp("MobileNo")}</p>
                  </div>
                </Col>
              </Row>
              {selectedEmp.WorkingStatus == "RESIGNED" &&
                  <Row>
                    <Col sm={6}>
                    <div className="list-row">
                      <label className="form-label">Resignation Date</label>
                      <p className="form-value">
                        {selectedEmp.ResignationDate
                          ? common.formatDate(
                            isValidProp("ResignationDate"),
                            "DD/MM/YYYY"
                          )
                          : "-"}
                      </p>
                    </div>
                    </Col>
                  </Row>
                }
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">USERNAME AND PASSWORD</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/employee/edit/" +
                      selectedEmp.EmployeeId +
                      "?empCode=" +
                      this.props.empCode +
                      "&email=" +
                      this.props.email +
                      "&return=" +
                      this.props.return +
                      "&filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Username</label>
                    <p className="form-value">{isValidProp("Email")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Password</label>
                    <p className="form-value">************</p>
                  </div>
                </Col>
              </Row>
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">DATES</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/employee/edit/" +
                      selectedEmp.EmployeeId +
                      "?empCode=" +
                      this.props.empCode +
                      "&email=" +
                      this.props.email +
                      "&return=" +
                      this.props.return +
                      "&filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Date Of Birth</label>
                    <p className="form-value">
                      {selectedEmp.DateOfBirth
                        ? common.formatDate(
                          isValidProp("DateOfBirth"),
                          "DD/MM/YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Hire Date</label>
                    <p className="form-value">
                      {selectedEmp.DateOfJoin
                        ? common.formatDate(
                          isValidProp("DateOfJoin"),
                          "DD/MM/YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Date Of Confirmation</label>
                    <p className="form-value">
                      {selectedEmp.DateOfConfirm
                        ? common.formatDate(
                          isValidProp("DateOfConfirm"),
                          "DD/MM/YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Date Of Notice</label>
                    <p className="form-value">
                      {selectedEmp.DateOfNotice
                        ? common.formatDate(
                          isValidProp("DateOfNotice"),
                          "DD/MM/YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">GENERAL DETAILS</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/employee/edit/" +
                      selectedEmp.EmployeeId +
                      "?empCode=" +
                      this.props.empCode +
                      "&email=" +
                      this.props.email +
                      "&return=" +
                      this.props.return +
                      "&filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Martial Status</label>
                    <p className="form-value">{isValidProp("MaritalStatus")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Father's Name</label>
                    <p className="form-value">{isValidProp("FatherName")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Name Of Spouse</label>
                    <p className="form-value">{isValidProp("SpouseName")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Nationality</label>
                    <p className="form-value">{isValidProp("NationalityId")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Person Type</label>
                    <p className="form-value">{isValidProp("PersonType")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Employee Type</label>
                    <p className="form-value">
                      {isValidProp("EmployementStatus")}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Work Location</label>
                    <p className="form-value">{isValidProp("WorkLocId")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">ID Card No.</label>
                    <p className="form-value">{isValidProp("IdCardNo")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Access Card No.</label>
                    <p className="form-value">{isValidProp("SwapCardNo")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Workday Calender</label>
                    <p className="form-value">{isValidProp("WdCalId")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Workday Shift</label>
                    <p className="form-value">{isValidProp("ShiftId")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Employee Group</label>
                    <p className="form-value">{isValidProp("EmployeeGroup")}</p>
                  </div>
                </Col>
              </Row>
            </Paper>

            {/* <Paper style={styles.boxLayout}>
          <div className="box-title-wrap justify-content-between">
            <h3 className="card-title">ATTENDANCE</h3>
            <Link className="card-link" to={"/setup/hrms/employee/edit/" + selectedEmp.EmployeeId}>
              Change
            </Link>
          </div>

          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Mobile Attendance</label>
                <p className="form-value">{isValidProp("MobileAttendance")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Selfie Attendance?</label>
                <p className="form-value">{isValidProp("SelfieAttendance")}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Lattitude</label>
                <p className="form-value">{isValidProp("Latitude")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Radius</label>
                <p className="form-value">{isValidProp("Radius")}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Geo Fence</label>
                <p className="form-value">{isValidProp("GeoFence")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Location Tracking</label>
                <p className="form-value">{isValidProp("LocTracking")}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Longitude</label>
                <p className="form-value">{isValidProp("Longitude")}</p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="list-row">
                <label className="form-label">Location Tracking Soiurce</label>
                <p className="form-value">{isValidProp("LocTrackingSource")}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <div className="list-row">
                <label className="form-label">Geo Fence Co-ords</label>
                <p className="form-value">{isValidProp("GeoFence")}</p>
              </div>
            </Col>
          </Row>
        </Paper> */}

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">ASSIGNMENTS</h3>
                <Link
                  className="card-link"
                  to={
                    "/setup/hrms/employee/edit/" +
                    selectedEmp.EmployeeId +
                    "?empCode=" +
                    this.props.empCode +
                    "&email=" +
                    this.props.email +
                    "&return=" +
                    this.props.return +
                    "&filterName=" +
                    this.props.filterName +
                    "&page=" +
                    this.props.page
                  }
                >
                  Change
                </Link>
              </div>

              {/* <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Division Name</label>
                    <p className="form-value">
                      {isValidAssignment("divisionName")}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Sub Division Name</label>
                    <p className="form-value">
                      {isValidAssignment("subDivisionName")}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Department</label>
                    <p className="form-value">
                      {isValidAssignment("departmentName")}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Section</label>
                    <p className="form-value">
                      {isValidAssignment("sectionName")}
                    </p>
                  </div>
                </Col>
              </Row> */}

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Department</label>
                    <p className="form-value">
                      {this.state.territoryName}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Supervisor</label>
                    <p className="form-value">{isValidProp("SupervisorId")}</p>
                  </div>
                </Col>
              </Row>
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">BENEFITS</h3>
                <Link
                  className="card-link"
                  to={
                    "/setup/hrms/employee/edit/" +
                    selectedEmp.EmployeeId +
                    "?empCode=" +
                    this.props.empCode +
                    "&email=" +
                    this.props.email +
                    "&return=" +
                    this.props.return +
                    "&filterName=" +
                    this.props.filterName +
                    "&page=" +
                    this.props.page
                  }
                >
                  Change
                </Link>
              </div>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Pan Card</label>
                    <p className="form-value">{isValidProp("PanCard")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Aadhaar Card</label>
                    <p className="form-value">{isValidProp("AadhaarNo")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Salary Account No.</label>
                    <p className="form-value">
                      {isValidProp("SalaryAccountNo")}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Hourly Rate</label>
                    <p className="form-value">
                      {isValidProp("HourlyRate")}{" "}
                      {isValidProp("HourlyRateCurr")}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">PF Deduction</label>
                    <p className="form-value">{isValidProp("PfDeduction")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">ESI Covered</label>
                    <p className="form-value">{isValidProp("EsiCovered")}</p>
                  </div>
                </Col>
              </Row>

              <Row className={selectedEmp.PfDeduction === "Y" ? "" : "d-none"}>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">PF No</label>
                    <p className="form-value">{isValidProp("PfNo")}</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">UAN No</label>
                    <p className="form-value">{isValidProp("PfUanNo")}</p>
                  </div>
                </Col>
              </Row>

              <Row className={selectedEmp.PfDeduction === "Y" ? "" : "d-none"}>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">PF Enrollment Date</label>
                    <p className="form-value">
                      {selectedEmp.PfEnrollmentDate
                        ? common.formatDate(
                          isValidProp("PfEnrollmentDate"),
                          "DD/MM/YYYY"
                        )
                        : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Remarks</label>
                    <p className="form-value">{isValidProp("PfRemarks")}</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Health Insurance No.</label>
                    <p className="form-value">{isValidProp("HealthInsNo")}</p>
                  </div>
                </Col>
                <Col
                  sm={6}
                  className={selectedEmp.EsiCovered === "Y" ? "" : "d-none"}
                >
                  <div className="list-row">
                    <label className="form-label">ESI No.</label>
                    <p className="form-value">{isValidProp("EsiNo")}</p>
                  </div>
                </Col>
              </Row>
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">DEDUCTIONS</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/employee/edit/" +
                      selectedEmp.EmployeeId +
                      "?empCode=" +
                      this.props.empCode +
                      "&email=" +
                      this.props.email +
                      "&return=" +
                      this.props.return +
                      "&filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Extra Dudy Deduction</label>
                    <p className="form-value">
                      {isValidProp("ExtraDutyDeduction")}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Day Bata Deduction</label>
                    <p className="form-value">
                      {isValidProp("DayBataDeduction")}
                    </p>
                  </div>
                </Col>
              </Row>
            </Paper>
          </div>
        )}
        {this.props.selectedEmp.EmployeeId == undefined && <NoRecordFound />}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default EmployeeDetail;
