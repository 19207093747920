import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

import '../css/Shift.css';

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// AutoComplete
import Checkbox from "material-ui/Checkbox";
// Time Picker
import TimePicker from 'react-time-picker';
// includes
import { crm } from '_utils';
class ShiftMasterAdd extends React.Component {
    constructor(props) {
        super(props);
        this.params = this.props.params;
        this.state = {
            time: '10:00',
            isLoading: false,
            formSubmit: false,
            LeaveSettingsId: 0,
            fields: {
                LeaveType: '',
                PaidLeaves: '',
                PaidLeavesStr: '',
                MaxLeaves: '',
                MaxLeavesStr: '',
                MinService: '',
                MinServiceStr: '',
                Description: '',
                TenantId: crm.userInfo().pTenantId,
                StartDate: new Date(),
                EndDate: null,
                Active: "Y",
                DesigId: '',
                DivisionId: '',
                SubDivisionId: '',
                ShiftType: '',
                DepartmentId: '',
                CarryForward: 'Y',
                EmpCategory: '',
                MaxLvPerMnth: '',
                ExpiryDays: ''
            },
            errors: {
                LeaveType: {
                    error: "",
                    isReq: "Leave Type is required!",
                },
                StartDate: {
                    error: "",
                    isReq: "Start Date is required!",
                },
                EndDate: {
                    error: "",
                    isReq: "End Date is required!",
                }
            },
            masterData: {
                LeaveType: [],
                EmpCategory: []
            },
            commonResource: {
                LeaveSettingId: 0,
            },
            divisionData: [{ label: "Select", value: "" }],
            subDivisionData: [{ label: "Select", value: "" }],
            shiftTypeData: [{ label: "Select", value: "" }]
        };
    }

    // life cycle
    componentDidMount() {
        let id = this.props.match.params;
        if (id != '') {
            this.getDetailById(id);
        }
    }

    selectHandleOnChange = (event, type) => {
        const { label, value } = event;
        const { fields } = this.state;
        if (fields.hasOwnProperty(type)) {
            fields[type] = value;
        }

        this.setState({
            fields: fields,
        }, () => {
            this.validateForm(type, value);
        });
    }

    // Time Set
    onChange = (value) => {
        this.setState({ time: value })
    }

    // functions
    getDetailById = (id) => {
        const { fields, errors } = this.state;

        let url = envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + "/" + id;
    };

    // validation
    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (errors.hasOwnProperty(name)) {
                errors[name].error = errors[name].isReq;
            }
        } else {
            switch (name) {
                default:
                    errors[name].error = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    validateAll() {
        const { fields, errors } = this.state;
        let isValid = true;
        for (var key of Object.keys(errors)) {
            if (fields[key] == "" || fields[key] == null) {
                errors[key].error = errors[key].isReq;
                isValid = false;
            }
        }
        this.setState({
            errors
        });

        return isValid;
    }

    // event
    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                //this.validateForm(name, inputValue);
            }
        );
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    // rest services
    onSubmit = (event) => {
        event.preventDefault();
        const { commonResource } = this.state;

        this.setState({
            formSubmit: true,
        });

        let isValid = this.validateAll();

        if (isValid) {
            if (commonResource.LeaveSettingId > 0) {
                this.update();
            } else {
                this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + "/" + commonResource.LeaveSettingId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/customer");
                }
            },
            (error) => { }
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/customer");
                }
            },
            (error) => { }
        );
    };

    // support
    yearList() {
        let yearList = [];
        for (let i = 1988; i <= new Date().getFullYear(); i++) {
            yearList.push(i);
        }
        return yearList;
    };

    onCancel = () => {
        history.push("/hrms/shiftmaster");
    }

    render() {
        const {
            fields,
            errors,
            formSubmit,
            divisionData,
            subDivisionData,
            shiftTypeData,
            // data
            masterData,
            commonResource,
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            <div className="lead-wraper">
                <h1 style={{ fontSize: 22 }}>Add Shift Master</h1>
                <Form onSubmit={this.save}>
                    <div>
                        {/* Party Information */}
                        <Row className="whiteBgRow" style={{ marginTop: 28 }}>
                            <Col sm={12} className="formWhiteBg">
                                <div className="formHead">
                                    <h2>Shift Information</h2>
                                </div>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Shift Name*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyCode !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyCode}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} className="time-picker">
                                        <Form.Group>
                                            <Form.Label>Start Time*</Form.Label>
                                            <TimePicker
                                                onChange={this.onChange}
                                                value={this.state.time}
                                            />
                                            {errors.PartyName !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyName}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} className="time-picker">
                                        <Form.Group>
                                            <Form.Label>End Time*</Form.Label>
                                            <TimePicker
                                                onChange={this.onChange}
                                                value={this.state.time}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Division</Form.Label>
                                            <Select
                                                value={divisionData.find(o => o.value === fields.DivisionId)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'DivisionId')}
                                                options={divisionData}
                                            />
                                            {errors.DivisionId !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.DivisionId}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Sub Division*</Form.Label>
                                            <Select
                                                value={subDivisionData.find(o => o.value === fields.SubDivisionId)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'SubDivisionId')}
                                                options={subDivisionData}
                                            />
                                            {errors.SubDivisionId !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.SubDivisionId}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Shift Type</Form.Label>
                                            <Select
                                                value={shiftTypeData.find(o => o.value === fields.ShiftType)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'ShiftType')}
                                                options={shiftTypeData}
                                            />
                                            {errors.ShiftType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.ShiftType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>


                                <Row style={{ marginTop: 30 }}>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Checkbox
                                                name="Active"
                                                checked={isChecked(fields.Active)}
                                                label="Active"
                                                labelStyle={{ color: '#2d2d2d' }}
                                                labelPosition="left"
                                                onClick={(e) => this.handleOnChange(e)}
                                            />
                                            {errors.Active !== 'Y' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.Active}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* Action section */}
                        <Row className="botBtn">
                            <Col sm={12}>
                                <Button className="saveBtn" type="submit">
                                    {/* {commonResource.PartyId > 0 ? "Update" : "Save"} */}
                                    Save
                                </Button>
                                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

export { ShiftMasterAdd };
