import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "material-ui/Checkbox";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
  PartySiteId: {
    isReq: "Location is required!",
  },
  SiteCode: {
    isReq: "Site Code is required!",
  },
  SiteName: {
    isReq: "Site Name is required!",
  },
};

class AddCustomerSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      autoloading: false,
      formSubmit: false,
      fields: {
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        CustomerId: 0,
        PartySiteId: 0,
        PrimaryFlag: "N",
        BilltoFlag: "N",
        ShiptoFlag: "N",
        //ServiceTo: "N",
        SiteName: "",
        SiteCode: "",
      },
      address: {
        SiteId: 0,
        SiteName: "",
        Address1: "",
        Address2: "",
        City: "",
        State: "",
        Country: "",
        PostalCode: "",
      },
      commonResource: {
        SiteId: 0,
      },
      autoFields: {
        PartySiteIdSearchTerm: "",
      },
      masterData: {
        locationData: [],
      },
      errors: {},
      filterName: "",
      page: 1,
    };
  }

  componentDidMount() {
    const { commonResource, fields } = this.state;

    this.defineInitErrorMsg();

    if (this.props.match.params.customerId != undefined) {
      fields.CustomerId = this.props.match.params.customerId
        ? parseInt(this.props.match.params.customerId)
        : 0;
      commonResource.SiteId = this.props.match.params.siteId
        ? this.props.match.params.siteId
        : 0;

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let filterName = params.get("filterName");
      let page = params.get("page");

      this.setState(
        {
          commonResource: commonResource,
          fields: fields,
          filterName,
          page,
        },
        () => {
          const { commonResource } = this.state;
          if (commonResource.SiteId > 0) {
            this.getDetailById(commonResource.SiteId);
          }
        }
      );
    }
  }

  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Redirect to territory list page
   */
  onCancel = () => {
    const { fields, filterName, page, commonResource } = this.state;

    history.push(
      "/setup/vendor/customer?tab=sites&customerId=" +
      fields.CustomerId +
      "&filterName=" +
      filterName +
      "&page=" +
      page
    );
  };

  /**
   * Handle to change input values
   */
  handleAutoChange = (event, selectedValue) => {
    const { id } = event.target;
    const { fields, address } = this.state;
    var splitId = id.split("-");

    fields[splitId[0]] = selectedValue.PartySiteId;
    fields.SiteName = selectedValue.SiteName;
    fields.SiteCode = selectedValue.SiteCode;

    address.SiteId = selectedValue.PartySiteId;
    address.SiteName = selectedValue.SiteCode;
    address.Address1 = selectedValue.AddressLine1;
    address.Address2 = selectedValue.AddressLine2;
    address.City = selectedValue.City;
    address.State = selectedValue.State;
    address.PostalCode = selectedValue.PostalCode;
    address.Country = selectedValue.CountryCode;
    this.setState(
      {
        fields: fields,
        address: address,
      },
      () => {
        this.validateForm(splitId[0], selectedValue.PartySiteId);
        this.validateForm("SiteName", fields.SiteName);
        this.validateForm("SiteCode", fields.SiteCode);
      }
    );
  };

  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, inputValue);
      }
    );
  };

  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };

  /**
   * Handle to get changed search terms and get list based on terms
   */
  handleInputChange = (event, terms = "") => {
    const { masterData, autoFields, fields } = this.state;
    masterData.locationData = [];

    const { id } = event.target;
    var splitId = id.split("-");

    autoFields[splitId[0] + "SearchTerm"] = terms;
    if (terms.length === 0) {
      fields[splitId[0]] = "";
      this.validateForm(splitId[0], fields[splitId[0]]);
    }

    this.setState({
      autoloading: true,
      masterData: masterData,
      autoFields: autoFields,
      fields
    });

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(SiteName) LIKE '*${terms}*' &limit=10&offset=0&totalResults=true`;
    let url = envConfig.BASE_API + Services.CRM_PARTY_SITES + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          masterData.locationData =
            response.items && response.items.length > 0 ? response.items : [];

          this.setState({
            autoloading: false,
            masterData: masterData,
          });
        }
      },
      (error) => { }
    );
  };
  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.CRM_CUSTOMER_SITE + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                fields[key] = response[key];

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState(
            {
              fields: fields,
              errors: errors,
            },
            () => {
              if (fields.PartySiteId != null) {
                this.getPartySiteDetailsById();
              }
            }
          );
        } else {
        }
      },
      (error) => { }
    );
  };

  /**
   * Get details by Id
   */
  getPartySiteDetailsById = (type) => {
    const { fields, autoFields, address } = this.state;

    let filter =
      `?q=TenantId=${crm.userInfo().pTenantId};` +
      `PartySiteId=${fields.PartySiteId}`;
    let url = envConfig.BASE_API + Services.CRM_PARTY_SITES + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response && response.items.length > 0) {
            autoFields["PartySiteIdSearchTerm"] = response.items[0].SiteCode;

            address.SiteId = response.items[0].PartySiteId;
            address.SiteName = response.items[0].SiteCode;
            address.Address1 = response.items[0].AddressLine1;
            address.Address2 = response.items[0].AddressLine2;
            address.City = response.items[0].City;
            address.State = response.items[0].State;
            address.PostalCode = response.items[0].PostalCode;
            address.Country = response.items[0].CountryCode;
          }

          this.setState({
            address: address,
            autoFields: autoFields,
          });
        }
      },
      (error) => { }
    );
  };

  save = (event) => {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.SiteId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, filterName, page } = this.state;

    let url = encodeURI(
      envConfig.BASE_API +
      Services.CRM_CUSTOMER_SITE +
      "/" +
      commonResource.SiteId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push(
            "/setup/vendor/customer?tab=sites&customerId=" +
            fields.CustomerId +
            "&filterName=" +
            filterName +
            "&page=" +
            page
          );
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, filterName, page } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER_SITE);

    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push(
            "/setup/vendor/customer?tab=sites&customerId=" +
            fields.CustomerId +
            "&filterName=" +
            filterName +
            "&page=" +
            page
          );
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      fields,
      masterData,
      loading,
      autoloading,
      errors,
      formSubmit,
      autoFields,
      commonResource,
      address,
    } = this.state;
    const isChecked = (value) => value == "Y";
    return (
      <div className="lead-wraper">
        <Form onSubmit={this.save}>
          <div className="formWhiteBg">
            <div className="formHead">
              <h2>Add Customer SITE</h2>
            </div>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Location Name*</Form.Label>
                  <AutoComplete
                    freeSolo
                    id="PartySiteId"
                    disableClearable
                    options={masterData.locationData}
                    loading={autoloading}
                    getOptionLabel={(option) => option.SiteName}
                    onChange={this.handleAutoChange}
                    inputValue={autoFields.PartySiteIdSearchTerm}
                    onInputChange={this.handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Search Location Name"}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          endAdornment: (
                            <React.Fragment>
                              {autoloading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errors.PartySiteId !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.PartySiteId}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}></Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Site code*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="SiteCode"
                    onChange={(e) => this.handleOnChange(e)}
                    value={fields.SiteCode}
                    disabled={commonResource.SiteId !== 0}
                  />
                  {errors.SiteCode !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.SiteCode}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Site Name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="SiteName"
                    onChange={(e) => this.handleOnChange(e)}
                    value={fields.SiteName}
                  />
                  {errors.SiteName !== "" && formSubmit === true && (
                    <Form.Text className="error">{errors.SiteName}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Checkbox
                    name="PrimaryFlag"
                    checked={isChecked(fields.PrimaryFlag)}
                    label="Primary"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Checkbox
                    name="BilltoFlag"
                    checked={isChecked(fields.BilltoFlag)}
                    label="Bill To"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Checkbox
                    name="ServiceTo"
                    checked={isChecked(fields.ServiceTo)}
                    label="Service To"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Checkbox
                    name="ShiptoFlag"
                    checked={isChecked(fields.ShiptoFlag)}
                    label="Ship To"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                    onClick={(e) => this.handleOnChange(e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="Address1"
                    value={address.Address1}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="Address2"
                    value={address.Address2}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="Country"
                    value={address.Country}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="State"
                    value={address.State}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="City"
                    value={address.City}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    name="State"
                    value={address.PostalCode}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Row className="botBtn">
            <Col sm={12}>
              <Button className="saveBtn" type="submit">
                {commonResource.SiteId > 0 ? "Update" : "Save"}
              </Button>
              <Button
                className="cancelBtn"
                type="button"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export { AddCustomerSite };
