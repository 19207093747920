import React from "react";
import { Form, Col, Button, Row, InputGroup } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";
import Select from "react-select";
import moment from "moment";
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// pages
import ModuleModal from "./includes/ModuleModal";
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// css
import "./css/tasks.css";
import "react-datepicker/dist/react-datepicker.css";

class TaskAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: { taskId: "add" },
      isLoading: true,
      isEmpLoading: true,
      isAdding: false,
      formSubmit: false,
      isModal: false,
      fullWidth: true,
      maxWidth: "md",
      fields: {
        taskName: "",
        description: "",
        priority: "",
        status: "",
        assignee: "",
        module: "",
        moduleItem: ["", "", "", ""],
        startDate: null,
        endDate: null,
        active: "N",
        pkId: "",
        attrUrl: "",
        dependentOn: "",
      },
      errors: {
        taskName: {
          error: "",
          isReq: "Task name is required",
        },
        description: {
          error: "",
          isReq: "Task description is required",
        },
        priority: {
          error: "",
          isReq: "Please select the priority",
        },
        status: {
          error: "",
          isReq: "Please select the status",
        },
        module: {
          error: "",
          isReq: "Please select the module",
        },
      },
      sData: {
        statusList: [],
        priorityList: [],
        employeeList: [],
        moduleDataList: [],
        taskInfo: [],
      },
      userInfo: crm.userInfo(),
      userPermissions: this.props.userPermissions,
    };
    this.count = 0;
  }

  componentDidMount() {
    this.getLookUpData("TASK_STATUS", "statusList");
    this.getLookUpData("TASK_PRIORITY", "priorityList");
    this.getEmployeeList();
  }

  // api
  getLookUpData = (lookupValue, source) => {
    const { sData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          sData[source] = common.getOptionsData(response.items, "Name", "Code");
          this.setState({
            sData
          });
          this.apiStatus();
        }
      },
      (error) => { }
    )
  };

  getEmployeeList = () => {
    const { userInfo, sData } = this.state;
    this.setEmpLoading(true);
    let filter = `?q=TenantId=${userInfo.pTenantId};WorkingStatus=${envConfig.WorkingStatus}&limit=10000&offset=0&totalResults=true&orderBy=EmployeeName:asc`;
    let url = encodeURI(envConfig.BASE_API + Services.CRM_PAY_EMPLOYEE + filter);

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response.totalResults > 0) {
            let result = []
            response.items.map((item, key) => {
              result.push({
                label: item.EmployeeName,
                value: item.Email
              });
            })
            sData.employeeList = result;
            this.setState({
              sData
            });
          }
        }
        this.apiStatus();
        this.setEmpLoading(false);
      },
      (error) => {
        sData.employeeList = [];
        this.setState({
          employeeList: [],
        });
        this.setEmpLoading(false);
      }
    );
  };

  getTaskById() {
    const { params, fields, sData } = this.state;
    let filter = '/' + params.taskId;
    let url = envConfig.BASE_API + Services.TASKS_VIEW + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          fields.taskName = response.TaskName;
          fields.description = response.Description;
          fields.priority = response.Priority;
          fields.status = response.Status;
          fields.assignee = { label: response.EmployeeName, value: response.Assignees };
          fields.module = { label: response.TaskTableName, value: response.TaskTable };
          fields.startDate = response.StartDate;
          fields.endDate = response.EndDate;
          fields.active = response.Active;
          fields.pkId = response.TaskPk;
          fields.attrUrl = response.TaskTableExtattr1;
          fields.dependentOn = response.TaskTableDependentOn;
          this.setState({
            fields
          }, () => {
            this.apiStatus();
            this.getAttrList();
          })

        }
      },
      (error) => { }
    )
  }

  getAttrList() {
    const { fields } = this.state;
    let url = envConfig.BASE_API + fields.attrUrl + '/' + fields.pkId;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let moduleFields = fields.dependentOn.split("#");
          moduleFields.map((cItem, key) => {
            if (cItem != "null") {
              fields.moduleItem[key] = response[cItem];
            }
          })
          this.setState({
            fields
          }, () => {
            this.apiStatus();
          })
        }
      },
      (error) => {
        fields.module = "";
        this.setState({
          fields
        })
        this.apiStatus();
      }
    )
  }

  // page function
  apiStatus() {
    const { params } = this.state;
    this.count++;
    if (params.taskId == "add") {
      if (this.count == 3) {
        this.setLoading(false);
      }
    } else {
      if (this.count == 3) {
        this.getTaskById()
      } else if (this.count == 5) {
        this.setLoading(false);
      }
    }
  }

  // modal
  moduleAction() {
    const { fields } = this.state;
    const { module, moduleItem } = fields
    this.ModuleModal.parentAction(module, moduleItem);
  }

  onModuleAction(data) {
    const { fields } = this.state;
    fields.module = data.module;
    fields.moduleItem = data.moduleItem;
    this.setState({
      fields
    }, () => {
      this.validateForm();
    })
  }

  moduleRemove() {
    const { fields } = this.state;
    fields.module = "";
    fields.moduleItem = ["", "", "", ""];
    this.setState({
      fields
    }, () => {
      this.validateForm();
    })
  }

  // submit handler
  onSubmitTask() {
    this.setSubmit(true);
    const { userInfo, fields, params } = this.state;
    let isValid = this.validateForm();
    if (isValid) {
      this.setState({ isAdding: true });
      let queryParams = {
        TaskName: fields.taskName,
        Description: fields.description,
        Priority: fields.priority,
        Status: fields.status,
        StartDate: fields.startDate || null,
        EndDate: fields.endDate || null,
        TaskTable: fields.module ? fields.module.value : "",
        TaskPk: fields.moduleItem[0],
        Assignees: fields.assignee.value || "",
        Active: fields.active,
        TenantId: userInfo.pTenantId,
        OrgId: userInfo.pOrgId,
        PercentageComplete: 1
      }
      var formAttributes = Object.assign({}, queryParams);
      let url = encodeURI(envConfig.BASE_API + Services.TASKS);
      if (params.taskId == "add") {
        restServices.postRequest(
          url,
          formAttributes,
          (response) => {
            if (response) {
              common.snack("S", "Task Successfully Added");
              window.location.href = "";
            }
            this.setState({ isAdding: false });
          },
          (error) => {
            this.setState({ isAdding: false });
          }
        );
      } else {
        url += "/" + params.taskId;
        restServices.patchRequest(
          url,
          formAttributes,
          (response) => {
            if (response) {
              common.snack("S", "Task Successfully Updated");
              history.push("/task");
            }
            this.setState({ isAdding: false });
          },
          (error) => {
            this.setState({ isAdding: false });
          }
        );
      }
    }
  }

  // handler
  onChange = (e) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onCheck = (e) => {
    const { fields } = this.state;
    const { name, checked } = e.target;
    fields[name] = (checked ? "Y" : "N");
    this.setState({ fields })
  }

  onSelect = (value, name) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onDateChange = (date, name) => {
    const { fields } = this.state;
    fields[name] = date;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  // validate
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].minLength && fields[key].length < errors[key].minLength) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (errors[key].isValidEmail && !crm.ValidateEmail(fields[key])) {
        errors[key].error = errors[key].isValidEmail;
        isValid = false;
      } else if (errors[key].isValidMultiEmail) {
        let emails = fields[key].split(",");
        for (let i = 0; i < emails.length; i++) {
          if (!crm.ValidateEmail(emails[i])) {
            errors[key].error = errors[key].isValidMultiEmail;
            isValid = false;
          }
        }
      } else if (errors[key].matchPassword) {
        if (fields[key] !== fields["password"]) {
          errors[key].error = errors[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  // common
  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  setEmpLoading(status) {
    this.setState({ isEmpLoading: status });
  }

  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setModal(status) {
    this.setState({ modal: status });
  }

  render() {
    const {
      params,
      isLoading,
      isAdding,
      isEmpLoading,
      formSubmit,
      fields,
      errors,
      sData,
      userPermissions,
    } = this.state;

    return (
      !userPermissions.add ? (
        <AccessDenied />
      ) : (
        isLoading ? (
          <Loader />
        ) : (
          <div className="lead-wraper">
            <Form>
              <div className="formWhiteBg">
                <div className="formHead">
                  <h2>Task Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Task Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="taskName"
                        value={fields.taskName}
                        onChange={(e) => this.onChange(e)}
                      />
                      {errors.taskName.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.taskName.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Description*</Form.Label>
                      <Form.Control
                        type="text"
                        name="description"
                        value={fields.description}
                        onChange={(e) => this.onChange(e)}
                        maxLength={4000}
                      />
                      {errors.description.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.description.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Priority*</Form.Label>
                      <select
                        name="priority"
                        className="form-control"
                        value={fields.priority}
                        onChange={(e) => this.onChange(e)}
                      >
                        {sData.priorityList.map((item, key) => (
                          <option key={key} value={item.value}>{item.label}</option>
                        ))}
                      </select>
                      {errors.priority.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.priority.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Status*</Form.Label>
                      <select
                        name="status"
                        className="form-control"
                        value={fields.status}
                        onChange={(e) => this.onChange(e)}
                      >
                        {sData.statusList.map((item, key) => (
                          <option key={key} value={item.value}>{item.label}</option>
                        ))}
                      </select>
                      {errors.status.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.status.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Responsible Person</Form.Label>
                      <Select
                        options={sData.employeeList}
                        value={fields.assignee}
                        onChange={(data) =>
                          this.onSelect(data, "assignee")
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Select Modules</Form.Label>
                      <div>
                        <InputGroup>
                          <Form.Control
                            type="search"
                            name="module"
                            placeholder="Select module"
                            value={(fields.module ? `${fields.module.label}: ${fields.moduleItem[1]}` : "")}
                            readOnly={true}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <img
                                src={common.loadImg("add.svg")}
                                onClick={() => { this.moduleAction() }}
                              />
                            </InputGroup.Text>
                            <InputGroup.Text>
                              <img
                                src={common.loadImg("delete.svg")}
                                onClick={() => { this.moduleRemove() }}
                              />
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                        {errors.module.error !== "" && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.module.error}
                          </Form.Text>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                        name="startDate"
                        value={fields.startDate ? moment(fields.startDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "startDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                        showTimeSelect
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                        name="StartDate"
                        value={fields.endDate ? moment(fields.endDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "endDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                        showTimeSelect
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>&nbsp; </Form.Label>
                      <Checkbox
                        name="active"
                        className="filter-checkbox"
                        checked={fields.active == "Y" ? true : false}
                        label="Active"
                        labelStyle={{ color: "#2d2d2d" }}
                        labelPosition="left"
                        onClick={(e) => this.onCheck(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <Row className="taskBotBtn">
                <Col sm={12}>
                  <Button
                    className="saveBtn"
                    onClick={() => this.onSubmitTask()}
                    type="button"
                    disabled={isAdding}
                  >{isAdding ? "Processing..." : (params.taskId == "add" ? "Save" : "Update")}</Button>
                  <Button
                    className="cancelBtn"
                    onClick={() => window.location.href = ""}
                    type="button"
                    disabled={isAdding}
                  >Cancel</Button>
                </Col>
              </Row>
            </Form>

            <ModuleModal
              ref={(instance) => {
                this.ModuleModal = instance;
              }}
              onModuleAction={(data) => this.onModuleAction(data)}
            />
          </div>
        )
      )
    );
  }
}

export default TaskAdd;
