import React from "react";
import MultiSelect from "@khanacademy/react-multi-select";
import { common } from "_helpers";

class EnhancedTableColumns extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.retrieveColumsSelected()
    }

    handleChange = (selected) => {
        this.props.updateTLConfig("columnsSelected", selected, null);
        this.storeSelectedColums(selected);
    };

    retrieveColumsSelected = () => {

        const {
            headCells, uniqueKey
        } = this.props.tlConfig

        if (!localStorage.getItem(uniqueKey)) {
            var retrievedData = [];
            headCells.forEach((element) => {
                retrievedData.push(element.value);
            });
            this.props.updateTLConfig("columnsSelected", retrievedData, null);
            this.storeSelectedColums(retrievedData);
        } else {
            var retrievedData = localStorage.getItem(uniqueKey);
            this.props.updateTLConfig("columnsSelected", retrievedData ? JSON.parse(retrievedData) : [], null);
        }
    };

    storeSelectedColums = (selected) => {
        const {
            uniqueKey
        } = this.props.tlConfig
        common.setLocalStorage(uniqueKey, selected)

    };

    render() {

        const {
            headCells,
            columnsSelected
        } = this.props.tlConfig;

        return (
            <MultiSelect
                options={headCells}
                selected={columnsSelected}
                onSelectedChanged={(selected) =>
                    this.handleChange(selected)
                }
            />
        )
    }

}

export default EnhancedTableColumns;