// Import default packages
import React from "react";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "../../../../_components/ListView/ListView";
import AccessDenied from "../../../../../_components/_accessdenied/AccessDenied";
// includes
import { crm } from '_utils';
// icons
import SearchIcon from "img/search-icn.png";
import { Link } from "react-router-dom";
class PartySiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: common.getParam("view") == "kanban" ? false : true,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 10,
      tlConfig: {
        columnId: "PartySiteId",
        headCells: [
          { id: "SiteName", value: "SiteName", label: "Site Name", type: "link", },
          { id: "SiteCode", value: "SiteCode", label: "Site Code" },
          { id: "PrimaryFlag", value: "PrimaryFlag", label: "Primary" },
          { id: "ServiceTo", value: "ServiceTo", label: "Service To" },
          { id: "BillTo", value: "BillTo", label: "Bill To" },
          { id: "ShipTo", value: "ShipTo", label: "Ship To" },
          { id: "AddressId", value: "AddressId", label: "Address" },
        ],
        columnsSelected: [],
        order: "desc",
        orderBy: "CreatedOn",
        selected: [],
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        uniqueKey: "PartySiteListcolumns",
        delItemCount: 0
      },
      filterName: "",
      crmStatusList: [],
      selectedItems: [],
      userPermissions: common.getPermission(this.props, "vendor:parties"),
    };
  }

  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access && this.props.objectId > 0) {
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading, isListView } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading && isListView) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.PartySiteId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterName,
      userPermissions,
    } = this.state;

    if (currentPage > 0) {
      this.setState({ isLoading: true });

      let filter = '?q=PartyId=' + this.props.objectId;

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }

      let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_SITES + filter);

      restServices.getRequest(
        url,
        (response) => {
          let temp = {};
          let deletePermission = userPermissions.delete;

          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.map((item) => {
              item.delete = deletePermission;
              list.push(item);
            });
            temp = response;
            temp.items = list;
          } else {
            response.items.map((item) => {
              item.delete = deletePermission;
            });
            temp = response;
          }
          let isRecordAvailable =
            temp && temp.items && temp.items.length ? true : false;
          tlConfig.enableMultipleDelete =
            userPermissions.delete && isRecordAvailable ? true : false;

          tlConfig.delItemCount = tlConfig.enableMultipleDelete ? temp.items.length : 0;

          this.setState(
            {
              listRes: temp,
              isLoading: false,
              tlConfig: tlConfig,
              currentPage: response.hasMore ? currentPage + 1 : 0,
              urls: envConfig.BASE_API + Services.ITEM_MATSER,
              totalResults: response.totalResults
            },
            () => {
              window.addEventListener("scroll", this.onScroll);
            }
          );
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
            tlConfig: tlConfig
          });
        }
      );
    }
  };

  checkItem(e) {
    let selectedItems = this.state.selectedItems;
    let value = e.target.value;
    if (selectedItems.includes(value)) {
      selectedItems = this.removeItem(selectedItems, value);
    } else {
      selectedItems.push(value);
    }
    this.setState({ selectedItems });
  }

  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      totalResults,
      OrderBy
    } = this.state;

    const isHided = (PartySiteId) => tlConfig.columnsSelected.indexOf(PartySiteId) !== -1;
    const isSelected = (PartySiteId) => tlConfig.selected.indexOf(PartySiteId) !== -1;

    const handleClick = (event, PartySiteId) => {
      const selectedIndex = tlConfig.selected.indexOf(PartySiteId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, PartySiteId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(SiteName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&limit=${totalResults}&offset=0`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return (
      <div className="lead-wraper" style={{ minHeight: "800px" }}>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <React.Fragment>
            {/* end of Secondary Nav */}
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-3">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">All Sites</h2>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  {this.state.isListView && (
                    <div className="form-row justify-content-end">
                      <div className="col-lg-4">
                        <label
                          className="sr-only"
                          htmlFor="inlineFormInputGroup"
                        >
                          Filter and Search
                        </label>
                        <div className="input-group search-filter-group mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            placeholder="+ Filter and search"
                            name="filterName"
                            value={filterName}
                            onChange={this.handleFieldChange}
                          />
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <img
                                src={SearchIcon}
                                alt="Search"
                                onClick={this.filterData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <EnhancedTableColumns
                          tlConfig={tlConfig}
                          updateTLConfig={this.updateTLConfig}
                        />
                      </div>
                      {userPermissions.add && (
                        <div className="col-auto">
                          <Link to={"/setup/vendor/party-site/add/" + this.props.objectId}>
                            <button type="button" className="btn btn-add">
                              Add
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            {/* {common.checkPermission(permissions, "crm:enquiry:read") && ( */}
            <div className="crm-data-wrap">

              <div className="clearfix"></div>

              {this.state.isListView && (
                <ListView
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                  isLoading={isLoading}
                  data={listRes}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  isHided={isHided}
                  handlePageChange={this.handlePageChange}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  from="partysite"
                  search={filterName}
                  filterData={this.filterData}
                  deptEmployees={this.props.deptEmployees}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default PartySiteList;
