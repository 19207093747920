import React from "react";
import { common, history } from "_helpers";
import CrmMenu from "_components/_crmMenu/CrmMenu";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
//Import CSS
import "./Contacts.css";
class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPermissions: common.getPermission(this.props, "contact"),
    };
  }

  onAction = (data) => {
    if (data.type == "edit" || data.type == "view") {
      history.push("/crm/contact/edit/" + data.id + "?view=list");
    } else if (data.type == "add") {
      history.push("/crm/contact/add");
    }
  };

  render() {
    const { userPermissions } = this.state;

    return (
      <div className="campaign-wraper">
        <CrmMenu />

        {userPermissions.read && (
          <div className="crm-data-wrap">
            <div className="clearfix"></div>
            <OsmoMaterialTable
              userPermissions={userPermissions}
              module="contacts"
              add="Add New"
              update="Mass Update"
              sort="CreatedOn:desc"
              onAction={(data) => this.onAction(data)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ContactList;
