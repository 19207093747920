import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from '@material-ui/core/Checkbox';

const classes = makeStyles({
    table: {
        minWidth: 650,
    },
})

function EnhancedTableHead(props) {

    const {
        order,
        orderBy,
        headCells,
        columnsSelected,
        numSelected,
        rowCount,
        checkedAll,
        enableMultipleDelete,
        delItemCount
    } = props.tlConfig;

    const createSortHandler = (property) => (event) => {
        const isAsc = props.tlConfig.orderBy === property && props.tlConfig.order === "asc";
        props.updateTLConfig(null, null, {
            order: isAsc ? "desc" : "asc",
            orderBy: property
        });
    };

    const handleSelectAll = (event) => {
        props.updateTLConfig("checkedAll", event.target.checked, null);
      };
 
    const isHided = (id) => columnsSelected.indexOf(id) !== -1;

    return (
        <TableHead>            
        <TableRow>
            {enableMultipleDelete && delItemCount > 0 && props.from !== "reports" && (
                <TableCell padding="checkbox">                
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        onChange={handleSelectAll}
                        checked={checkedAll}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
            )}
            
            {headCells.map((headCell) => (
            <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                className={!isHided(headCell.id) ? "hideCell" : ""}
            >
                <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                >
                {headCell.label}
                {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                    {order === "desc" ? "" : ""}
                    </span>
                ) : null}
                </TableSortLabel>
            </TableCell>
            ))}
        </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;