import React from "react";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
//Import CSS
import "./Hrms.css";

// Import Images
import DivisionImg from "img/hrms/division.svg";
import EmployeesImg from "img/hrms/employee.svg";
// import LeaveCalendarImg from "img/hrms/leave-calender.svg";
// import LeaveSettingImg from "img/hrms/leave-setting.svg";
import RolesResponsibilityImg from "img/hrms/roles-responsiblities.svg";
class Hrms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="lead-wraper">
        <div className="secondary-nav">
          <ul className="secondary-nav-list">
            <li className="active">
              <Link to="/setup/hrms">HRMS</Link>
            </li>
            <li>
              <Link to="/setup/configure">Configure</Link>
            </li>
            <li>
              <Link to="/setup/vendor">Vendor</Link>
            </li>
          </ul>
        </div>
        {/* Secondary Nav end */}
        <h2 className="crm-type-title mb-20">HRMS</h2>
        <div className="crm-data-wrap">
          <ul className="row configure-list hrms-list">
            <li className="col-lg-3 col-sm-4">
              <Link to="/setup/hrms/department">
                <div className="card-layout text-center">
                  <img
                    src={DivisionImg}
                    alt="Divisions"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Department</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-3 col-sm-4">
              <Link to="/setup/hrms/employee">
                <div className="card-layout text-center">
                  <img src={EmployeesImg} alt="Employees" className="img-fluid" />
                  <h5 className="setting-name">Employees</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            {/* <li className="col-lg-3 col-sm-4">
              <Link to="/setup/hrms/leavecalendar">
                <div className="card-layout text-center">
                  <img src={LeaveCalendarImg} alt="Leave Calendar" className="img-fluid" />
                  <h5 className="setting-name">Leave Calendar</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-3 col-sm-4">
              <Link to="/setup/hrms/leavesetting">
                <div className="card-layout text-center">
                  <img
                    src={LeaveSettingImg}
                    alt="Leave Settings"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Leave Settings</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li> */}
            <li className="col-lg-3 col-sm-4">
              <Link to="/setup/hrms/roles/responsible">
                <div className="card-layout text-center">
                  <img src={RolesResponsibilityImg} alt="Roles and Responsibility" className="img-fluid" />
                  <h5 className="setting-name">Roles and Responsibility</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            {/* <li className="col-lg-2 col-sm-4">
              <Link to="/hrms/designations">
                <div className="card-layout text-center">
                  <img src={DesignationImg} alt="Designations" className="img-fluid" />
                  <h5 className="setting-name">Designations</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li> 
            <li className="col-lg-2 col-sm-4">
              <Link
                to="/hrms/payelement"
              >
                <div className="card-layout text-center">
                  <img src={PayElementImg} alt="Pay Elements" className="img-fluid" />
                  <h5 className="setting-name">Pay Elements</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img src={ElementCalculationImg} alt="Element Calculation" className="img-fluid" />
                  <h5 className="setting-name">Element Calculation</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>

            <li className="col-lg-2 col-sm-4">
              <Link to="/hrms/leaveopening">
                <div className="card-layout text-center">
                  <img
                    src={LeaveOpeningImg}
                    alt="Leave Opening"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Leave Opening</h5>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-2 col-sm-4">
              <Link to="/hrms/shiftmaster">
                <div className="card-layout text-center">
                  <img src={ShiftImg} alt="Shifts" className="img-fluid" />
                  <h5 className="setting-name">Shifts</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img src={PerformanceImg} alt="Performance Parameter" className="img-fluid" />
                  <h5 className="setting-name">Performance Parameter</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>            
            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img
                    src={WorkAllocationImg}
                    alt="Work Allocation"
                    className="img-fluid"
                  />
                  <h5 className="setting-name">Work Allocation</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-2 col-sm-4">
              <Link
                to="#"
              >
                <div className="card-layout text-center">
                  <img src={IncentiveMasterImg} alt="Incentives Master" className="img-fluid" />
                  <h5 className="setting-name">Incentives Master</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img src={WorkdayCalendarImg} alt="Workday Calendar" className="img-fluid" />
                  <h5 className="setting-name">Workday Calendar</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>

            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img src={EmployeeGroupImg} alt="Employee Group" className="img-fluid" />
                  <h5 className="setting-name">Employee Group</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li>
            
            <li className="col-lg-2 col-sm-4">
              <Link to="#">
                <div className="card-layout text-center">
                  <img src={BonusPeriodImg} alt="Bonus Periods" className="img-fluid" />
                  <h5 className="setting-name">Bonus Periods</h5>
                  <p>
                  Lorem Ipsum is simply dummy text of the printing.
                  </p>
                </div>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

const styles = {
  menuSelectbox: {
    borderBottom: "none",
  },
};

const mapStateToProps = (state) => {
  const { alert } = state;
  return { alert };
};
export default connect(mapStateToProps)(Hrms);

// export { LeadList };
