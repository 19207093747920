import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Checkbox from "material-ui/Checkbox";
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
// includes
import { crm } from '_utils';
//Import CSS
import "../css/TransactionStatus.css";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// Defined Errors
const defErrs = {
  // StatusCode: {
  //   isReq: "Status Code is required!",
  // },
  StatusName: {
    isReq: "Status Name is required!",
  },
  StartDate: {
    isReq: "StartDate is required!",
  },
  OrderNo: {
    isReq: "OrderNo is required!"
  },
  StatusColor: {
    isReq: "Status Color is required!"
  }
};
class TransactionStatusAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state =
    {
      fields: {
        OrderNo: "",
        StatusCode: "",
        StatusName: "",
        PerComplete: "",
        Description: "",
        StartDate: "",
        EndDate: "",
        Active: "Y",
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        TransactionTypeId: 0,
        StatusColor: ""
      },
      commonResource: {
        TransactionID: 0,
      },
      formSubmit: false,
      errors: {},
      isLoading: false,
      userPermissions: common.getPermission(this.props, "configure:form trasactions"),
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.defineInitErrorMsg();
    const { commonResource, fields } = this.state;

    if (this.props.match.params.transactionTypeId != undefined) {

      commonResource.TransactionID = this.props.match.params.transactionID === undefined ? 0 : this.props.match.params.transactionID;
      fields.TransactionTypeId = this.props.match.params.transactionTypeId === undefined ? 0 : this.props.match.params.transactionTypeId;

      this.setState(
        {
          fields: fields,
          commonResource: commonResource,
        },
        _ => {
          if (commonResource.TransactionID > 0) {
            this.getTransactionDetail(commonResource.TransactionID);
          }
        }
      );
    }
  }

  /**
 * Get selected date values 
 */
  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, date);
    });
  }

  toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  handleCheckBoxChange = event => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked
    });
  }

  /**
   * Get Transaction Details by ID
   */
  getTransactionDetail = id => {
    const { fields, errors } = this.state;
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (response[key]) {
              if (fields.hasOwnProperty(key)) {
                if (
                  key === 'StartDate' ||
                  key === 'EndDate'
                ) {
                  fields[key] = response[key] ? new Date(response[key]) : '';
                } else {
                  fields[key] = response[key];
                }
              }
              // Remove Default Errors
              if (errors.hasOwnProperty(key)) {
                // Optional Validation Here
                if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState({
            fields: fields,
            isLoading: false
          });
        }
      },
      (error) => { }
    );
  };

  /**
* Input onchange event handling
*/
  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  /**
   * Intialize error handling
   */
  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };


  /**
 * Validate form
 */
  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.TransactionID > 0) {
        this.update()
      } else {
        this.create()
      }
    }
  }


  /**
   * Update status transaction
   */
  update = () => {

    const { fields, commonResource } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }
    formAttr["StatusCode"] = fields.StatusName;

    let url = encodeURI(envConfig.BASE_API + Services.TRANSACTION_STATUS + "/" + commonResource.TransactionID);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.props.history.push("/setup/configure/form-transaction");
        }
      },
      (error) => { }
    );
  };

  /**
   * create status transaction
   */
  create = () => {
    const { fields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }
    formAttr["StatusCode"] = fields.StatusName;

    let url = encodeURI(envConfig.BASE_API + Services.TRANSACTION_STATUS);
    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.props.history.push("/setup/configure/form-transaction");
        }
      },
      (error) => { }
    )
  };

  render() {
    const {
      fields,
      errors,
      formSubmit,
      commonResource,
      userPermissions
    } = this.state;

    const isChecked = (value) => value == 'Y';

    return (
      !userPermissions.read || ((commonResource.TransactionID == 0 && !userPermissions.add) || (commonResource.TransactionID > 0 && !userPermissions.update)) ? (
        <AccessDenied />
      ) : (
        <div className="lead-wraper">
          <div className="secondary-head">
            <h1>{commonResource.TransactionID == 0 ? "Add" : "Edit"} Status</h1>
          </div>
          <Form onSubmit={this.handleSubmit}>
            <div className="formWhiteBg">
              <div className="formHead">
                <h2>Status Information</h2>
              </div>
              {/* <Row>
                
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Status Code*</Form.Label>
                    <Form.Control type="text"
                      name="StatusCode"
                      value={fields.StatusCode}
                      onInput={e => this.toInputUppercase(e)}
                      onChange={e => this.handleOnChange(e)} />
                    {errors.StatusCode !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.StatusCode}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row> */}

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Order No*</Form.Label>
                    <Form.Control type="number"
                      name="OrderNo"
                      value={fields.OrderNo}
                      onChange={e => this.handleOnChange(e)} />
                    {errors.OrderNo !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.OrderNo}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Status Name*</Form.Label>
                    <Form.Control type="text"
                      name="StatusName"
                      value={fields.StatusName}
                      onChange={e => this.handleOnChange(e)} />
                    {errors.StatusName !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.StatusName}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col sm={6}>
                  <Form.Group>
                    <Form.Label>%Completed</Form.Label>
                    <Form.Control
                      type="text"
                      name="PerComplete"
                      value={fields.PerComplete}
                      onChange={e => this.handleOnChange(e)} />
                  </Form.Group>
                </Col> */}
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="Description"
                      value={fields.Description}
                      onChange={e => this.handleOnChange(e)}
                      maxLength={500}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Start Date*</Form.Label>
                    <DatePicker
                      name="StartDate"
                      selected={fields.StartDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, 'StartDate')
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                    {errors.StartDate !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.StartDate}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <DatePicker
                      name="EndDate"
                      selected={fields.EndDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, 'EndDate')
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Status Color*</Form.Label>
                    <input
                      type="color"
                      name="StatusColor"
                      onChange={e => this.handleOnChange(e)}
                      value={fields.StatusColor}>
                    </input>
                    {errors.StatusColor !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.StatusColor}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Checkbox
                    name="Active"
                    checked={isChecked(fields.Active)}
                    onClick={(e) => this.handleOnChange(e)}
                    label="Active"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                  />
                  {errors.Active !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.Active}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </div>
            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  Save
                </Button>
                <Link to={"/setup/configure/form-transaction"} className="btn btn-link cancelBtn">Cancel</Link>
              </Col>
            </Row>
          </Form>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  const { crmstatus } = state;
  return { crmstatus };
};
export { TransactionStatusAdd };
