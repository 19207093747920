import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Checkbox from "material-ui/Checkbox";
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Select from 'react-select';
// includes
import { crm } from '_utils';
//Import CSS
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";

// Defined Errors
const defErrs = {
  FromStatusCode: {
    isReq: "FromStatusCode is required!",
  },
  ToStatusCode: {
    isReq: "ToStatusCode is required!",
  },
  StartDate: {
    isReq: "StartDate is required!",
  },
  TransitionName: {
    isReq: "TransitionName is required!",
  },
  OrderNo: {
    isReq: "OrderNo is required!"
  }
};

class StatusTransitionAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      isLoading: false,
      fields: {
        OrderNo: "",
        FromStatusCode: "",
        ToStatusCode: "",
        TransitionName: "",
        TransTag: "",
        SecurityRoles: "",
        StartDate: "",
        EndDate: "",
        Active: "Y",
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
        TransactionTypeId: 0
      },
      commonResource: {
        TransitionID: 0,
      },
      securityRolesData: [{ value: "selected", label: "Select" }],
      crmStatusList: [],
      errors: {},
      userPermissions: common.getPermission(this.props, "configure:form trasactions"),
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.defineInitErrorMsg();
    const { commonResource, fields } = this.state;

    if (this.props.match.params.transitionTypeId != undefined) {
      commonResource.TransitionID = this.props.match.params.transitionID === undefined ? 0 : this.props.match.params.transitionID;
      fields.TransactionTypeId = this.props.match.params.transitionTypeId === undefined ? 0 : this.props.match.params.transitionTypeId;

      this.setState(
        {
          commonResource: commonResource,
          fields: fields,
        },
        (_) => {
          this.getCRMStatusList(fields.TransactionTypeId);

          if (commonResource.TransitionID > 0) {
            this.getTransitionDetail(commonResource.TransitionID);
          }
        }
      );
    }
  }

  /**
* Input onchange event handling
*/
  selectHandleOnChange = (event, name) => {
    const { value } = event;
    const { fields } = this.state;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, value);
    });
  };

  /**
 * Input onchange event handling
 */
  handleOnChange = event => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = (checked ? "Y" : "N");
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, inputValue);
    });
  };

  /**
   * Intialize error handling
   */
  defineInitErrorMsg = () => {
    const { errors } = this.state;
    for (var key of Object.keys(defErrs)) {
      errors[key] = defErrs[key].isReq;
    }
    this.setState({
      errors: errors,
    });
  };

  /**
 * Get selected date values 
 */
  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState({
      fields: fields,
    }, () => {
      this.validateForm(name, date);
    });
  }

  /**
 * Validate form
 */
  validateForm = (name, value) => {
    const { errors } = this.state;
    if (!value) {
      if (defErrs.hasOwnProperty(name)) {
        errors[name] = defErrs[name].isReq;
      }
    } else {
      // Optional Validation Here
      switch (name) {
        case "ToStatusCode":
          if (this.state.fields.FromStatusCode === value) {
            errors[name] = "Please select different status";
            this.setState({
              errors,
            });
          }
          else {
            errors[name] = "";
          }
          break;
        case "FromStatusCode":
          if (this.state.fields.ToStatusCode === value) {
            errors[name] = "Please select different status";
            this.setState({
              errors,
            });
          }
          else {
            errors[name] = "";
          }
          break;
        default:
          errors[name] = "";
      }
    }
    this.setState({
      errors: errors,
    });
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = (typeId) => {

    this.setState({ isLoading: true });
    let params = `?q=TransactionTypeId=${typeId};OrgId=` +
      `${crm.userInfo().pOrgId};TenantId=` +
      `${crm.userInfo().pTenantId};Active=Y` +
      `&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + params;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            crmStatusList: response.items && response.items.length > 0 ? response.items : []
          })
        }
      });
    //let crmStatusList = commonService.getCRMStatus(typeId);


    //dispatch(commonActions.getCRMStatus(typeId));
    //crmStatusList
  };

  // /**
  //  * Get Data from API as per the request
  //  * This is the alternative life cycle method for "componentWillReceiveProps"
  //  */
  // static getDerivedStateFromProps(props, state) {
  //   const { crmstatus } = props;

  //   var crmStatusData = state.crmStatusList;

  //   if (crmstatus && crmstatus.list && crmstatus.list.success) {
  //     crmStatusData = crmstatus.list.details.message.items;

  //     crmStatusData = crmStatusData.sort(function (a, b) {
  //       return a.OrderNo > b.OrderNo ? 1 : -1;
  //     });
  //   }

  //   if (crmStatusData && crmStatusData !== state.crmStatusList) {
  //     return {
  //       crmStatusList: crmStatusData,
  //       isLoading: false,
  //     };
  //   } else {
  //     return {};
  //   }
  // }

  /**
   * Handle submit action
   */
  handleSubmit(event) {
    event.preventDefault();

    const { errors, commonResource } = this.state;
    var isValid = true;

    this.setState({
      formSubmit: true,
    });

    for (var key of Object.keys(errors)) {
      if (!isValid) {
        break;
      }
      isValid = !errors[key];
    }

    if (isValid) {
      if (commonResource.TransitionID > 0) {
        this.update()
      } else {
        this.create()
      }
    }
  }


  /**
   * Update status transition
   */
  update = () => {

    const { fields, commonResource } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }

    let url = encodeURI(envConfig.BASE_API + Services.STATUS_TRANSITION + "/" + commonResource.TransitionID);
    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.props.history.push("/setup/configure/form-transition");
        }
      },
      (error) => { }
    );
  };

  /**
   * create status transition
   */
  create = () => {
    const { fields } = this.state;
    var formAttr = {};

    for (var key of Object.keys(fields)) {
      switch (key) {
        case "StartDate":
        case "EndDate":
          formAttr[key] = fields[key] ? common.formatDate(fields[key], "YYYY-MM-DDTHH:mm:ssZ") : null
          break;
        default:
          formAttr[key] = fields[key];
      }
    }

    let url = encodeURI(envConfig.BASE_API + Services.STATUS_TRANSITION);
    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.props.history.push("/setup/configure/form-transition");
        }
      },
      (error) => { }
    )
  };

  /**
   * Get Transition Details by ID
   */
  getTransitionDetail = (id) => {
    const { fields, errors } = this.state;
    this.setState({ isLoading: true });

    let url = envConfig.BASE_API + Services.STATUS_TRANSITION + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (response[key]) {
              if (fields.hasOwnProperty(key)) {
                if (
                  key === 'StartDate' ||
                  key === 'EndDate'
                ) {
                  fields[key] = response[key] ? new Date(response[key]) : '';
                } else {
                  fields[key] = response[key];
                }
              }
              // Remove Default Errors
              if (errors.hasOwnProperty(key)) {
                // Optional Validation Here
                if (key !== "Active" && (response[key] !== null && response[key] !== "")) {
                  errors[key] = "";
                }
              }
            }
          }

          this.setState({
            fields: fields,
            isLoading: false
          });
        }
      },
      (error) => { }
    );

  };

  render() {
    const {
      crmStatusList,
      fields,
      errors,
      formSubmit,
      commonResource,
      userPermissions
    } = this.state;

    const isChecked = (value) => value == 'Y';

    const crmList = common.getOptionsData(crmStatusList, "StatusCode", "StatusCode")
    return (
      !userPermissions.read || ((commonResource.TransitionID == 0 && !userPermissions.add) || (commonResource.TransitionID > 0 && !userPermissions.update)) ? (
        <AccessDenied />
      ) : (
        <div className="lead-wraper">
          <div className="secondary-head">
            <h1>{commonResource.TransitionID == 0 ? "Add" : "Edit"} Status Transition</h1>
          </div>
          <Form onSubmit={this.handleSubmit}>
            <div className="formWhiteBg">
              <div className="formHead">
                <h2>Status Transition Information</h2>
              </div>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Order No*</Form.Label>
                    <Form.Control
                      type="text"
                      name="OrderNo"
                      value={fields.OrderNo}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.OrderNo !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.OrderNo}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Transition Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="TransitionName"
                      value={fields.TransitionName}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.TransitionName !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.TransitionName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>From Status*</Form.Label>
                    <Select
                      name="FromStatusCode"
                      value={crmList.find(o => o.value === fields.FromStatusCode)}
                      onChange={(e) => this.selectHandleOnChange(e, 'FromStatusCode')}
                      options={crmList}
                    />
                    {errors.FromStatusCode !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.FromStatusCode}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>To Status*</Form.Label>
                    <Select
                      name="ToStatusCode"
                      value={crmList.find(o => o.value === fields.ToStatusCode)}
                      onChange={(e) => this.selectHandleOnChange(e, 'ToStatusCode')}
                      options={crmList}
                    />
                    {errors.ToStatusCode !== "" && formSubmit === true && (
                      <Form.Text className="error">
                        {errors.ToStatusCode}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Tag</Form.Label>
                    <Form.Control
                      type="text"
                      name="Tag"
                      value={fields.Tag}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Security Roles</Form.Label>
                    <Select
                      name="SecurityRoles"
                      value={fields.SecurityRoles}
                      onChange={(e) => this.handleOnChange(e)}
                      options={this.state.securityRolesData}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker
                      name="StartDate"
                      value={fields.StartDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, 'StartDate')
                      }
                      format="dd/MM/yyyy"
                    />
                    {errors.StartDate !== "" && formSubmit === true && (
                      <Form.Text className="error">{errors.StartDate}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <DatePicker
                      name="EndDate"
                      value={fields.EndDate}
                      onChange={(date) =>
                        this.handleDateOnChange(date, 'EndDate')
                      }
                      format="dd/MM/yyyy"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Label>&nbsp;</Form.Label>
                  <Checkbox
                    name="Active"
                    checked={isChecked(fields.Active)}
                    onClick={(e) => this.handleOnChange(e)}
                    label="Active"
                    labelStyle={{ color: "#2d2d2d" }}
                    labelPosition="left"
                  />
                  {errors.Active !== "" && formSubmit === true && (
                    <Form.Text className="error">
                      {errors.Active}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </div>
            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  Save
                </Button>
                <Link
                  to={"/setup/configure/form-transition"}
                  className="btn btn-link cancelBtn"
                >
                  Cancel
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      )
    );
  }
}

export { StatusTransitionAdd };