import React, { createRef } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import Paper from "@material-ui/core/Paper";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
//Import CSS
import "./Attachments.css";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import PopupLoader from "_components/_loader/PopupLoader";
// includes
import { crm } from "_utils";
const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

class Attachments extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {
        ObjectId: "",
        ObjectType: "",
        FileName: "",
        FileType: "",
        FileSize: null,
        TenantId: crm.userInfo().pTenantId,
        OrgId: crm.userInfo().pOrgId,
      },
      fileBase64Content: "",
      uploadedFiles: {},
      listResponse: {},
      currentPage: 1,
      pageLimit: 100,
      OrderBy: "CreatedOn",
      isLoading: false,
      selectedItem: 0,
      uploadProgress: false,
      previewImgFile: "",
      imgPreview: false,
      imgBlob: {},
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { fields } = this.state;
    if (this.props.objectId != undefined) {
      fields["ObjectId"] = this.props.objectId;
      fields["ObjectType"] = common.getObjectNameByPage(this.props.objectType);

      this.setState(
        {
          fields: fields,
        },
        () => {
          this.getFiles();
        }
      );
    }
  }

  /**
   * Get all files from DB
   */
  getFiles = () => {
    this.setState({ isLoading: true });

    const { currentPage, OrderBy, pageLimit, fields } = this.state;

    let filter = `?q=ObjectId=${
      fields["ObjectId"]
    };&totalResults=true&orderBy=${OrderBy}:asc&limit=${pageLimit}&offset=${
      (currentPage - 1) * pageLimit
    }`;

    let url = encodeURI(envConfig.BASE_API + Services.CRM_FILES + filter);
    restServices.getRequest(url, (response) => {
      if (response) {
        this.setState({
          listResponse: response,
          isLoading: false,
        },
        () => {
          this.fetchImage();
        });
      }
    });
  };

  /**
   * Save files using Drop method
   */
  onDrop = (files) => {
    const { fields } = this.state;
    fields.FileName = files[0].name;
    fields.FileType = files[0].type;
    fields.FileSize = files[0].size;
    this.setState({
      fields: fields,
    });

    if (files[0].size > 0 && files[0].size < 1e7) {
      //1e+7 - Max 10MB file allowed
      common.getBase64(files[0], (result) => {
        this.setState(
          {
            fileBase64Content: result,
            uploadProgress: true,
          },
          () => {
            console.log("fields ", fields);
            this.UploadFile();
          }
        );
      });
    } else {
      common.snack("E", "Limited file size 10 MB allowed");
    }
  };

  /**
   * Upload files to server
   */
  UploadFile = () => {
    const { fields, fileBase64Content, listResponse } = this.state;

    let url = envConfig.BASE_API + Services.CRM_FILES;

    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          if (response.AttachmentFileId != null && fileBase64Content != null) {
            let updateUrl =
              envConfig.BASE_API +
              Services.CRM_FILES +
              "/" +
              response.AttachmentFileId;

            let updateData;

            updateData = {
              FileImageBlob: fileBase64Content,
            };

            restServices.patchRequest(
              updateUrl,
              updateData,
              (response) => {
                if (response) {
                  //Updated result to state
                  let data = listResponse.items;
                  data.unshift(response);
                  listResponse["items"] = data;
                  this.setState({
                    listResponse: listResponse,
                    uploadProgress: false,
                  },
                  () => {
                    this.fetchImage();
                  });
                }
              },
              (error) => {
                console.log("error :>> ", error);
                common.snack("E", "Error in file uploading");
                this.setState({
                  uploadProgress: false,
                });
              }
            );
          }
        }
      },
      (error) => {
        console.log("error :>> ", error);
        common.snack("E", "Error in file uploading");
        this.setState({
          uploadProgress: false,
        });
      }
    );
  };

  downloadFile(e, fileURL, fileName, fileType) {
    e.preventDefault();
    let token = localStorage.getItem("userToken");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      envConfig.DOWNLOAD_URL + fileURL + "&download=Y",
      requestOptions
    ).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }

  /**
   * Delete uploaded files
   */
  deleteFile(fileId) {
    this.setState({ selectedItem: fileId });
    common.confirmDelete(true, (response) => {
      this.handleDeleteBlock();
    });
  }

  /**
   * Delete API call
   */
  handleDeleteBlock = () => {
    const { listResponse, selectedItem } = this.state;
    let url = envConfig.BASE_API + Services.CRM_FILES + "/" + selectedItem;

    restServices.deleteRequest(
      url,
      (response) => {
        common.snack("S", "Selected item has been deleted successfully.");
        this.setState({
          isLoading: true,
        });

        let responseList = listResponse;
        let updateData = [];
        if (responseList != null && responseList.items != null) {
          updateData = responseList.items.filter((file) => {
            return selectedItem !== file.AttachmentFileId;
          });
          responseList["items"] = updateData;
        }

        this.setState({
          listReponse: responseList,
          isLoading: false,
        });
      },
      (error) => {}
    );
  };

  previewImage(e, fileId) {
    e.preventDefault();
    this.setState({
      imgPreview: true,
      previewImgFile: fileId,
    });
  }

  handleClose = () => {
    this.setState({
      imgPreview: false,
      previewImgFile: "",
    });
  };

  fetchImage = () => {
    const { imgBlob, listResponse } = this.state;

    let filteredLists = listResponse.items.filter((data) => {
      return data.FileType.includes("image");
    });

    filteredLists.map((data, index) => {
      let url = envConfig.SERVLET_URL + "?imagePath=" + data.FilePath;
      restServices.fileGetRequest(
        url,
        (response) => {
          let url = window.URL.createObjectURL(response);
          imgBlob[data.AttachmentFileId] = url;
          this.setState({
            imgBlob,
          });
        },
        (error) => {
          console.log("error :>> ", error);
        }
      );
    });

    
  };

  render() {
    const {
      listResponse,
      isLoading,
      uploadProgress,
      imgPreview,
      previewImgFile,
      imgBlob,
    } = this.state;
    return (
      <div className="dropzone-wrap">
        <Dropzone
          ref={dropzoneRef}
          noClick
          noKeyboard
          onDropRejected={(reject) =>
            common.snack("E", reject[0].errors[0].message)
          }
          onDropAccepted={this.onDrop}
          maxFiles={1}
          multiple={false}
          accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
        >
          {({ getRootProps, getInputProps, acceptedFiles }) => {
            return (
              <div className="dropzone-container">
                <div className="dropzone-box mb-5">
                  <div {...getRootProps({ className: "dropzone text-center" })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop files here or click</p>
                    <button
                      type="button"
                      onClick={openDialog}
                      className="saveBtn btn btn-primary"
                    >
                      Select a file to upload
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </Dropzone>
        {isLoading == true && <Loader />}
        {isLoading == false && (
          <div className="uploaded-files-wrap">
            <h4 className="mb-3">Uploaded Files</h4>
            <ul className="uploaded-files-list">
              {listResponse.items &&
                listResponse.items.length > 0 &&
                listResponse.items.map((row, index) => {
                  return (
                    <li key={row.AttachmentFileId}>
                      <div className="row">
                        {row.FileType.includes("image") ? (
                          <img
                            className="file-type-img col-1 align-self-center"
                            src={imgBlob[row.AttachmentFileId] || null}
                            alt="File"
                            onClick={(e) =>
                              this.previewImage(e,
                                row.AttachmentFileId
                              )
                            }
                          />
                        ) : (                      
                          <div className="file-type-img col-1 align-self-center"></div>
                        )}

                        <div className="file-info col-9 align-self-center">
                          <p className="file-name">{row.FileName}</p>
                          <p className="file-size">
                            {common.formatBytes(row.FileSize)} bytes
                          </p>
                        </div>
                        <div className="file-action col-1 align-self-center">
                          {row.FilePath != null && (
                            <Link
                              to="#"
                              onClick={(e) =>
                                this.downloadFile(
                                  e,
                                  row.FilePath,
                                  row.FileName,
                                  row.FileType
                                )
                              }
                              download
                            >
                              Download
                            </Link>
                          )}
                        </div>
                        <div className="file-action col-1 align-self-center">
                          <Link
                            to="#"
                            onClick={(e) =>
                              this.deleteFile(row.AttachmentFileId)
                            }
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
            {isLoading == false &&
              listResponse.items &&
              listResponse.items.length == 0 && (
                <div className="formTabSection position-r">
                  <Paper style={styles.boxLayout}>
                    <NoRecordFound />
                  </Paper>
                </div>
              )}
          </div>
        )}

        {imgPreview && (
          <Dialog
            open={imgPreview}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="false"
            minWidth="false"
          >
            <DialogContent>
              <div className="popup-form-wrap imgDlgContent">
                <div className="list-row text-center">
                  <img
                    src={imgBlob[previewImgFile] || null}
                    alt="image"
                  />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {uploadProgress && <PopupLoader />}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default Attachments;
