import React, { Fragment, useState } from 'react';
import OsmoMaterialTable from 'crm/OsmoMaterialTable';
import Button from '@material-ui/core/Button';

function DemoDataTable(props) {
    const [reload, setReload] = useState(new Date());
    return (
        <Fragment>
            <OsmoMaterialTable
                module="contacts"
                add="Add Contacts"
                import="Import Contacts"
                convert="Convert To Lead"
                link="Add to contacts"
                // quick="Add Quick"
                reload={reload}
                update="Mass Update"
                // query={{ ObjectId: 1845, ObjectType: "CRM_CAMPAIGNS" }}
                // query={{ ListId: 1267 }}
                sort="CreatedOn:desc"
                data="EmailAddress"
                onAction={(data) => console.log(data)}
            />

            {/* <OsmoMaterialTable module="campaign" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="enquiry" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="lead" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="deal" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="enquiryMember" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="contacts" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="meeting" onAction={(data) => console.log(data)} />
            <OsmoMaterialTable module="callLogs" onAction={(data) => console.log(data)} /> */}
            {/* <Button color="secondary" variant="outlined" size="medium" onClick={() => setReload(new Date())}>Reload</Button> */}
        </Fragment>
    )
}

export default DemoDataTable;