// Import default packages
import React, { createRef } from "react";
import { Link } from "react-router-dom";
// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

// Import internal components
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import ListView from "../../../_components/ListView/ListView";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import FileExport from "../../../_components/FileExport/FileExport";
// css
import "../css/product.css";
// includes
import { crm } from '_utils';
// icons
import SearchIcon from "img/search-icn.png";
const child = createRef();

class ProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 10,
      tlConfig: {
        columnId: "ItemId",
        headCells: [
          {
            id: "ItemNumber",
            value: "ItemNumber",
            label: "PRODUCT NO",
          },
          {
            id: "ItemName",
            value: "ItemName",
            label: "PRODUCT NAME",
            type: "link",
          },
          { id: "ItemType", value: "ItemType", label: "PRODUCT TYPE" },
          { id: "Description", value: "Description", label: "DESCRIPTION" },
          { id: "Active", value: "Active", label: "ACTIVE" },
          { id: "PrimaryUom", value: "PrimaryUom", label: "UOM" },
          {
            id: "CreatedOn",
            value: "CreatedOn",
            label: "Created On",
            type: "date",
          },
        ],
        columnsSelected: [],
        order: "desc",
        orderBy: "CreatedOn",
        selected: [],
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        uniqueKey: "ProductsListcolumns",
        delItemCount: 0
      },
      filterName: "",
      userPermissions: common.getPermission(this.props, "vendor:products"),
      headers: [
        { label: 'ID', key: 'ItemId' },
        { label: 'Product No', key: 'ItemNumber' },
        { label: 'Product Name', key: 'ItemName' },
        { label: 'Product Type', key: 'ItemType' },
        { label: 'Description', key: 'Description' },
        { label: 'UOM', key: 'PrimaryUom' },
        { label: 'Created On', key: 'CreatedOn' }
      ],
      urls: "",
      totalResults: "",
      keyArray: ["ItemId", "ItemNumber", "ItemName", "ItemType", "Description", "PrimaryUom", "CreatedOn"],
    };
  }

  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener("scroll", this.onScroll, false);
  }

  // load more scroll
  onScroll = () => {
    const { isLoading } = this.state;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (scrolled == 1 && !isLoading) {
      this.getList();
    }
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.ItemId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const {
      tlConfig,
      currentPage,
      OrderBy,
      pageLimit,
      filterName,
      userPermissions,
    } = this.state;

    if (currentPage > 0) {
      this.setState({ isLoading: true });

      let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(ItemName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
        }`;

      if (OrderBy) {
        filter += "&orderBy=" + OrderBy;
      }

      let url = encodeURI(envConfig.BASE_API + Services.ITEM_MATSER + filter);

      restServices.getRequest(
        url,
        (response) => {
          let temp = {};
          let deletePermission = userPermissions.delete;

          if (this.state.listRes.items) {
            let list = this.state.listRes.items ? this.state.listRes.items : [];
            response.items.map((item) => {
              item.delete = deletePermission;
              list.push(item);
            });
            temp = response;
            temp.items = list;
          } else {
            response.items.map((item) => {
              item.delete = deletePermission;
            });
            temp = response;
          }
          let isRecordAvailable =
            temp && temp.items && temp.items.length ? true : false;
          tlConfig.enableMultipleDelete =
            userPermissions.delete && isRecordAvailable ? true : false;

          tlConfig.delItemCount = tlConfig.enableMultipleDelete ? temp.items.length : 0;

          this.setState(
            {
              listRes: temp,
              isLoading: false,
              tlConfig: tlConfig,
              currentPage: response.hasMore ? currentPage + 1 : 0,
              urls: envConfig.BASE_API + Services.ITEM_MATSER,
              totalResults: response.totalResults
            },
            () => {
              window.addEventListener("scroll", this.onScroll);
              if (child.current) {
                child.current.getCsvData();
              }
            }
          );
        },
        (error) => {
          this.setState({
            listRes: {},
            isLoading: false,
            tlConfig: tlConfig
          });
        }
      );
    }
  };

  // search filter
  filterData = () => {
    const { tlConfig } = this.state;
    tlConfig.selected = [];
    tlConfig.checkedAll = false;
    this.setState(
      {
        currentPage: 1,
        listRes: {},
        tlConfig,
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
      userPermissions,
      headers,
      urls,
      totalResults,
      keyArray,
      OrderBy
    } = this.state;

    const isHided = (ItemId) => tlConfig.columnsSelected.indexOf(ItemId) !== -1;
    const isSelected = (ItemId) => tlConfig.selected.indexOf(ItemId) !== -1;

    const handleClick = (event, ItemId) => {
      const selectedIndex = tlConfig.selected.indexOf(ItemId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, ItemId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(ItemName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&limit=${totalResults}&offset=0`;

    if (OrderBy) {
      filter += "&orderBy=" + OrderBy;
    }

    return (
      <div className="lead-wraper" style={{ minHeight: "800px" }}>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <React.Fragment>
            {/* end of Secondary Nav */}
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-12 col-lg-3">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">Products & Services</h2>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="form-row justify-content-end">
                    <div className="col-lg-4">
                      <label
                        className="sr-only"
                        htmlFor="inlineFormInputGroup"
                      >
                        Filter and Search
                      </label>
                      <div className="input-group search-filter-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder="+ Filter Product Name"
                          name="filterName"
                          value={filterName}
                          onChange={this.handleFieldChange}
                        />
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <img
                              src={SearchIcon}
                              alt="Search"
                              onClick={this.filterData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <EnhancedTableColumns
                        tlConfig={tlConfig}
                        updateTLConfig={this.updateTLConfig}
                      />
                    </div>
                    {userPermissions.export && (
                      <div className="col-auto">
                        <FileExport
                          ref={child}
                          headers={headers}
                          filename={"Products_details.xls"}
                          urls={urls}
                          keyArray={keyArray}
                          filter={filter}
                        />
                      </div>
                    )}
                    {userPermissions.add && (
                      <div className="col-auto">
                        <Link to="/setup/vendor/product/add">
                          <button type="button" className="btn btn-add">
                            Add Product
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            <div className="crm-data-wrap">

              <div className="clearfix"></div>

              <ListView
                tlConfig={tlConfig}
                updateTLConfig={this.updateTLConfig}
                isLoading={isLoading}
                data={listRes}
                isSelected={isSelected}
                handleClick={handleClick}
                isHided={isHided}
                handlePageChange={this.handlePageChange}
                currentPage={currentPage}
                pageLimit={pageLimit}
                from="product"
                search={filterName}
                filterData={this.filterData}
                deptEmployees={this.props.deptEmployees}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export { ProductsList };
