import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

//Import CSS
import { common } from "../../_helpers";
import "./landingHeader.css";

class LandingHeader extends React.Component {
	render() {
		return (
			<header className="lp-header">
				<Navbar collapseOnSelect expand="lg">
					{this.props.page == undefined && (
						<Navbar.Brand>
							<Link to="/">
								<img src={common.loadImg("crm-logo.png")} alt="Logo" />
							</Link>
						</Navbar.Brand>
					)}
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto"></Nav>
						<div className="nav-menu">
							<Nav.Link to="/" className="header-nav-item">
								Feature
							</Nav.Link>
							<Nav.Link to="/pricing" className="header-nav-item">
								Pricing
							</Nav.Link>
							<Nav.Link to="/" className="header-nav-item">
								Customers
							</Nav.Link>
							<Nav.Link to="/" className="header-nav-item">
								Resource
							</Nav.Link>
							<Link to="/signup" className="btn btn-signup header-btn-signup">
								Signup
							</Link>
							<br />
							<Link to="/login" className="btn btn-login header-btn-login">
								Login
							</Link>
						</div>
					</Navbar.Collapse>
				</Navbar>
			</header>
		);
	}
}
export default LandingHeader;
