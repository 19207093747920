import React from "react";
// Import Component
import LeftSideMenu from "_components/_leftsideMenu/LeftSideMenu";

//Import CSS
import "./FormLayout.css";

class FormLayout extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="admin-layout">
        <LeftSideMenu menuControl={"true"} />
        <div className="container-wrapper wrapper-widthSet">
          {this.props.children}
          {/* <Footer /> */}
        </div>
      </div>
    );
  }
}
export default FormLayout;
