// Import default packages
import React from "react";
import {
  Tabs,
  Tab,
  InputGroup,
  Button,
  FormControl,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
// includes
import { common, history, restServices } from "_helpers";
// api
import { envConfig } from "_config/config";
import * as Services from "_config/api";
// pages
import TaskAdd from "../Task/TaskAdd";
import TaskList from "./pages/Task/TaskList";
import MyTargetList from "./pages/MyTarget/MyTargetList";
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
// css
import "./css/MessageNotes.css";
import "./css/Activities.css";
import "./css/MyTask.css";
import "./css/Meetings.css";
import "./css/InviteMember.css";
import "./css/LeadScore.css";
import "./css/Activities.css";
import "./css/Meetings.css";
import { MyRole } from "./pages/MyRole/MyRole";

class Work extends React.Component {
  userData = common.authInfo();
  constructor(props) {
    super(props);
    this.state = {
      user: this.userData,
      currentTab: "",
      taskRefresh: false,
      userObj: {
        ProfileImg: "",
      },
      TenantId: this.userData.TenantId,
      OrgId: this.userData.DefaultOrgId,
      taskCountList: [],
      meetingList: [],
      countLoader: true,
      meetingLoader: true,
    };
  }

  componentDidMount() {
    const { userObj } = this.state;
    if (this.userData != null) {
      userObj["ProfileImg"] =
        envConfig.IMG_SERVLET_URL +
        "?imageId=" +
        this.userData.UserId +
        "&imageType=USER";
      this.setState({
        userObj: userObj,
      });
    }
    this.getTaskCountList();
    this.getMeetingList();
  }

  getTaskCountList = () => {
    const { OrgId, TenantId } = this.state;
    let params = `?q=TenantId=${TenantId};OrgId=${OrgId};VariableType=PRIORITY;Assignees=${this.userData.EmpId}`;
    let url = envConfig.BASE_API + Services.CHOLA_TASK_COUNTS + params;

    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          taskCountList: response.items,
          countLoader: false,
        });
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          countLoader: false,
        });
      }
    );
  };

  getMeetingList = () => {
    const { TenantId } = this.state;
    let filter = `?&orderBy=startTime:asc&limit=20&offset=0&totalResults=true&finder=FindMyEvents;pTenantId=${TenantId},pCreatedBy=${this.userData.UserName},pParticipants=${this.userData.UserName}`;
    let url = envConfig.BASE_API + Services.CRM_MEETINGS + filter;
    restServices.getRequest(
      url,
      (response) => {
        this.setState({
          meetingList: response.items,
          meetingLoader: false,
        });
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          meetingLoader: false,
        });
      }
    );
  };

  myLeads = () => {
    history.push("/work/mylead");
  };

  myDeals = () => {
    history.push("/work/mydeal");
  };

  gotoProfile = (e) => {
    e.preventDefault();
    history.push("/profile");
  };

  updateTab = (data) => {
    if (data) {
      this.setState({
        currentTab: data,
        taskRefresh: data == "tasklist" ? true : false,
      });
    }
  };

  render() {
    const {
      user,
      currentTab,
      userObj,
      taskCountList,
      meetingList,
      countLoader,
      meetingLoader,
    } = this.state;
    return (
      <div>
        <Row>
          <Col lg={9} sm={8}>
            {/* Notes */}
            <div className="message-tab">
              <Tabs defaultActiveKey="createtask" className="work-menu">
                <Tab
                  eventKey="createtask"
                  title={
                    <span>
                      <i className="createtask-icon" /> Create Task
                    </span>
                  }
                >
                  <div>
                    <TaskAdd userPermissions={common.getPermission(this.props, "task")} />
                  </div>
                </Tab>
                <Tab
                  eventKey="newnote"
                  disabled
                  title={
                    <span>
                      <i className="newnote-icon" /> New Note
                    </span>
                  }
                >
                  <div>
                    <Form>
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          as="textarea"
                          rows="3"
                          placeholder="Start typing to leave a note..."
                        />
                      </Form.Group>
                    </Form>
                    <Row className="text-option-section">
                      <ul>
                        <li>
                          <i className="text-option-one" />
                        </li>
                        <li>
                          <i className="text-option-two" />
                        </li>
                        <li>
                          <i className="text-option-three" />
                        </li>
                        <li>
                          <i className="text-option-four" />
                        </li>
                        <li>
                          <i className="text-option-five" />
                        </li>
                      </ul>
                    </Row>
                  </div>
                </Tab>
                <Tab
                  eventKey="call"
                  disabled
                  title={
                    <span>
                      <i className="call-icon" /> Call
                    </span>
                  }
                >
                  <div>
                    <h1>Call</h1>
                  </div>
                </Tab>
                <Tab
                  eventKey="logactivity"
                  disabled
                  title={
                    <span>
                      <i className="logactivity-icon" /> Log Activity
                    </span>
                  }
                >
                  <div>
                    <h1>Log Activity</h1>
                  </div>
                </Tab>
                <Tab
                  eventKey="schedule"
                  disabled
                  title={
                    <span>
                      <i className="schedule-icon" /> Schedule
                    </span>
                  }
                >
                  <div>
                    <h1>Schedule</h1>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {/* Activities */}
            <div>
              <div className="activity-tab">
                <Tabs defaultActiveKey={currentTab ? currentTab : "tasklist"}>
                  <Tab eventKey="tasklist" title="Task">
                    <TaskList taskRefresh={this.state.taskRefresh} />
                  </Tab>

                  <Tab eventKey="mytarget" title="My Target">
                    <MyTargetList />
                  </Tab>

                  <Tab eventKey="myrole" title="My Role">
                    <MyRole />
                  </Tab>

                  <Tab eventKey="newnote" title="Activity" disabled>
                    <div className="row search-icon mb-3">
                      <InputGroup className="col-lg-5 col-sm-8 mb-3">
                        <FormControl placeholder="+ Filter and search" />
                        <InputGroup.Append>
                          <Button variant="outline-secondary">
                            <img src={common.loadImg("search.png")} />
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                    <div className="activity">
                      <Row>
                        <Col lg={1} sm={2} className="dot-top">
                          <span className="dot">.</span>
                        </Col>
                        <Col lg={11} sm={10}>
                          <h2>March 2020</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={1} sm={2}>
                          <img src={common.loadImg("work/timline_user.png")} />
                        </Col>
                        <Col lg={11} sm={10}>
                          <div className="activity-details">
                            <Row>
                              <Col lg={1} sm={2}>
                                <img src={common.loadImg("work/user.png")} />
                              </Col>
                              <Col lg={11} sm={10}>
                                <p>
                                  The <span className="text-bold">ABC</span>{" "}
                                  stage for{" "}
                                  <span className="text-bold">John Doe</span>{" "}
                                  was changed to{" "}
                                  <span className="text-bold">"Lead"</span>
                                </p>
                                <h5>Mar 25 at 12:20 PM</h5>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={1} sm={2}>
                          <img
                            src={common.loadImg("work/timline_meeting.png")}
                          />
                        </Col>
                        <Col lg={11} sm={10}>
                          <div className="activity-details">
                            <Row>
                              <Col lg={1} sm={2}>
                                <img src={common.loadImg("work/user.png")} />
                              </Col>
                              <Col lg={11} sm={10}>
                                <p>
                                  <span className="text-bold">Steve Jack</span>{" "}
                                  has a meeting with{" "}
                                  <span className="text-bold">John Doe</span>
                                </p>
                                <h5>Mar 25 at 12:20 PM</h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="meeting-details">
                                <h5>Company</h5>
                                <p>ABC Company Pvt Ltd</p>
                                <p>|</p>
                                <p>+123 4567890</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <Row>
                                  <Col className="meeting-details">
                                    <h5>Start Time</h5>
                                    <p>Mar 24 at 10:00 AM</p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={6}>
                                <Row>
                                  <Col className="meeting-details set-br">
                                    <h5>Duration</h5>
                                    <p>1 hour</p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={1} sm={2}>
                          <img
                            src={common.loadImg("work/timline_signup.png")}
                          />
                        </Col>
                        <Col lg={11} sm={10}>
                          <div className="activity-details">
                            <Row>
                              <Col lg={1} sm={2}>
                                <img src={common.loadImg("work/user.png")} />
                              </Col>
                              <Col lg={11} sm={10}>
                                <p>
                                  <span className="text-bold">John Doe</span>{" "}
                                  was submitted{" "}
                                  <span className="green-text text-bold">
                                    ABC Account Signup
                                  </span>{" "}
                                  on{" "}
                                  <span className="text-bold">
                                    ABC Account Signup
                                  </span>
                                </p>
                                <h5>Mar 25 at 12:20 PM</h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="meeting-details">
                                <h5 className="green-text">
                                  Show 2 Updates properties
                                </h5>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={1} sm={2}>
                          <img
                            src={common.loadImg("work/timline_create.png")}
                          />
                        </Col>
                        <Col lg={11} sm={10}>
                          <div className="activity-details">
                            <Row>
                              <Col lg={1} sm={2}>
                                <img src={common.loadImg("work/user.png")} />
                              </Col>
                              <Col lg={11} sm={10}>
                                <p>
                                  <span className="text-bold">John</span> was
                                  created from Offline Sources
                                </p>
                                <h5>Mar 25 at 12:20 PM</h5>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={1} sm={2}>
                          <img src={common.loadImg("work/timline_user.png")} />
                        </Col>
                        <Col lg={11} sm={10}>
                          <div className="activity-details">
                            <Row>
                              <Col lg={1} sm={2}>
                                <img src={common.loadImg("work/user.png")} />
                              </Col>
                              <Col lg={11} sm={10}>
                                <p>
                                  The <span className="text-bold">ABC</span>{" "}
                                  stage for{" "}
                                  <span className="text-bold">John Doe</span>{" "}
                                  was changed to{" "}
                                  <span className="text-bold">"Deal"</span>
                                </p>
                                <h5>Mar 25 at 12:20 PM</h5>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                  <Tab eventKey="call" title="Notes" disabled>
                    <p>Notes list</p>
                  </Tab>
                  <Tab eventKey="logactivity" title="Calls" disabled>
                    <div>
                      <h1>Call</h1>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col lg={3} sm={4}>
            {/* LeadScore */}
            <div className="lead-score">
              {/* <Row>
                <Col sm={4}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={{
                      path: {
                        stroke: `rgb(66, 189, 244)`,
                        strokeLinecap: "butt",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      trail: {
                        stroke: "#d6d6d6",
                      },
                      // Customize the text
                      text: {
                        fill: "#42bdf4",
                      },
                    }}
                  />
                </Col>
                <Col sm={8}>
                  <h2>My Lead Score</h2>
                  <p>
                    Lorem ipsum is simply dummy txt for printing and typing
                    test.
                  </p>
                </Col>
              </Row> */}
              {/* <Row className="mt-30"> */}
              <Row>
                <Col sm={10}>
                  <h2>{user.EmployeeName}</h2>
                  <h3>{user.UserName}</h3>
                </Col>
                <Col sm={2} className="pl-0">
                  <img
                    className="profile-icon"
                    src={userObj.ProfileImg}
                    alt="profile"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="manage-btn"
                    onClick={(e) => this.gotoProfile(e)}
                  >
                    Manage your account
                  </Button>
                </Col>
              </Row>
            </div>
            {/* InviteMember */}
            <div>
              <div className="invite-member">
                <Col>
                  <h2>Invite member</h2>
                  <p>+</p>
                </Col>
              </div>
              <div className="invite-member" onClick={() => this.myLeads()}>
                <Col>
                  <h2>My Leads</h2>
                  <h6>&#8594;</h6>
                </Col>
              </div>
              <div className="invite-member" onClick={() => this.myDeals()}>
                <Col>
                  <h2>My Deals</h2>
                  <h6>&#8594;</h6>
                </Col>
              </div>
            </div>
            {/* Meetings */}
            <div className="meetings-tab">
              <Tabs defaultActiveKey="meeting">
                <Tab eventKey="meeting" title="Meetings">
                  <div>
                    {!meetingLoader &&
                      meetingList &&
                      meetingList.length > 0 &&
                      meetingList.map((item, key) => {
                        return (
                          <Row className="mb-20" key={key}>
                            <Col sm={4}>
                              <div className="display-date">
                                <h2>
                                  {item.startTime &&
                                    common.formatDate(item.startTime, "DD")}
                                </h2>
                                <h3>
                                  {item.startTime &&
                                    common.formatDate(item.startTime, "MMM")}
                                </h3>
                              </div>
                            </Col>
                            <Col sm={8} className="proj-details">
                              <p>
                                <span className="text-bold">
                                  {item.title && item.title}
                                </span>
                              </p>
                              <h4>
                                {item.startTime &&
                                  common.formatDate(
                                    item.startTime,
                                    "MMM DD | hh:mm A"
                                  )}
                              </h4>
                              <p className="text-bold">
                                {item.EventLocation && item.EventLocation}
                              </p>
                            </Col>
                          </Row>
                        );
                      })}
                    {meetingLoader && <Loader />}
                    {!meetingLoader && meetingList && meetingList.length == 0 && (
                      <div className="formTabSection position-r">
                        <Paper style={styles.boxLayout}>
                          <NoRecordFound />
                        </Paper>
                      </div>
                    )}
                    {/* <Row className="mb-20">
                      <Col sm={4}>
                        <div className="display-date">
                          <h2>28</h2>
                          <h3>Mar</h3>
                        </div>
                      </Col>
                      <Col sm={8} className="proj-details">
                        <p>
                          <span className="text-bold">Mark Doe</span> conducted
                          a meeting with You.
                        </p>
                        <h4>Mar 28 | 11.30 AM</h4>
                        <p className="text-bold">ABC Project</p>
                      </Col>
                    </Row>

                    <Row className="mb-20">
                      <Col sm={4}>
                        <div className="display-date">
                          <h2>28</h2>
                          <h3>Mar</h3>
                        </div>
                      </Col>
                      <Col sm={8} className="proj-details">
                        <p>
                          <span className="text-bold">Mark Doe</span> conducted
                          a meeting with You.
                        </p>
                        <h4>Mar 28 | 11.30 AM</h4>
                        <p className="text-bold">ABC Project</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4}>
                        <div className="display-date">
                          <h2>28</h2>
                          <h3>Mar</h3>
                        </div>
                      </Col>
                      <Col sm={8} className="proj-details">
                        <p>
                          <span className="text-bold">Mark Doe</span> conducted
                          a meeting with You.
                        </p>
                        <h4>Mar 28 | 11.30 AM</h4>
                        <p className="text-bold">ABC Project</p>
                      </Col>
                    </Row> */}
                  </div>
                </Tab>
                {/* Don't remove */}
                {/* <Tab eventKey="appointment" title="Appointments">
                  <div>
                    <h1>appointment list</h1>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
            {/* MyTask */}
            <div className="my-task">
              <Row className="head-row">
                <h2>My Task</h2>
              </Row>
              <ul className="task-list">
                {!countLoader &&
                  taskCountList &&
                  taskCountList.length > 0 &&
                  taskCountList.map((item, key) => {
                    return (
                      <li key={key}>
                        <Row className="task-det">
                          <Col lg={9} sm={6}>
                            <p>{item.VariableName && item.VariableName}</p>
                          </Col>
                          {/* <Col lg={3} sm={4}>
                      <ul>
                        <li>
                          <img
                            src={common.loadImg("work/mytask_profile.png")}
                          />
                        </li>
                        <li>
                          <img
                            src={common.loadImg("work/mytask_profile2.png")}
                          />
                        </li>
                        <li>
                          <img
                            src={common.loadImg("work/mytask_profile3.png")}
                          />
                        </li>
                      </ul>
                    </Col> */}
                          <Col lg={3} sm={2}>
                            <h3>{item.TaskCount && item.TaskCount}</h3>
                          </Col>
                        </Row>
                      </li>
                    );
                  })}
                {countLoader && <Loader />}
                {!countLoader && taskCountList && taskCountList.length == 0 && (
                  <div className="formTabSection position-r">
                    <NoRecordFound />
                  </div>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default Work;
