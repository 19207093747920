import React, { useState, useEffect } from "react";
import Badge from "@material-ui/core/Badge";
// includes
import { crm } from '_utils';
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";

export function StatusBadge(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [statusKey, setStatusKey] = useState(1);
  const [resultList, setResultList] = useState([]);
  const [authInfo] = useState(common.authInfo());
  // init
  useEffect(() => {
    getStatusList();
  }, []);
  // api
  const getStatusList = () => {
    let transactionTypeId = common.getTransactionTypeId(envConfig[`${props.type}_TRANS_TYPE_ID`]);
    let filter = `?q=TransactionTypeId=${transactionTypeId};OrgId=${authInfo.DefaultOrgId};TenantId=${authInfo.TenantId};Active=Y&limit=100&offset=0&orderBy=OrderNo:asc`;
    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          setResultList(response);
          setStatus(response)
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    )
  }
  // support
  const setStatus = (result) => {
    if (props.status == "" || props.status == null) {
      setStatusKey(1);
      props.onSelect(result.items[0].StatusCode);
    } else {
      result.items.map((item, key) => {
        if (item.StatusCode == props.status) {
          setStatusKey(++key);
        }
      })
    }
  }

  // handler
  const changeStatus = (code, key) => {
    setStatusKey(key)
    props.onSelect(code);
  }

  return (
    isLoading ? (
      <div className="formTab">
        <ul>
          <li className={`tabShape whiteTab`}>
            <span className="tri-right"></span>{" "}
            <Badge badgeContent={0} color="primary">
              Loading...
            </Badge>
          </li>
        </ul>
      </div>
    ) : (
      resultList != "" && (
        <div className="formTab">
          <ul>
            {resultList.items.map((item, key) => {
              let sNo = key; ++sNo;
              return (
                <li className={`tabShape whiteTab ${(statusKey >= sNo) ? 'active' : ''}`} onClick={() => changeStatus(item.StatusCode, sNo)} key={key}>
                  <span className="tri-right"></span>{" "}
                  <Badge badgeContent={sNo} color="primary">
                    {item.StatusName}
                  </Badge>
                </li>
              )
            })}
          </ul>
        </div>
      )
    )
  )
}