import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { history, common, restServices, tools } from "_helpers";
import AccessDenied from "_components/_accessdenied/AccessDenied";

const userInfo = common.authInfo();
class SubEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      UserId: userInfo.UserId,
      fields: {
        AlternativeEmailAddress1: userInfo?.AlternativeEmailAddress1 || "",
        AlternativeEmailAddress2: userInfo?.AlternativeEmailAddress2 || "",
      },
      errors: {},
      userPermissions: common.getPermission(props, "configure:subemail"),
    };
  }

  componentDidMount = () => {
    this.getTenantEmailList();
  };

  getTenantEmailList = () => {
    let url =
      envConfig.BASE_API + Services.CHOLA_TENANTS + "/" + userInfo.TenantId;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            fields: {
              ...this.state.fields,
              AlternativeEmailAddress1: response.AlternativeEmailAddress1,
              AlternativeEmailAddress2: response.AlternativeEmailAddress2,
            },
          });
        }
      },
      (error) => {}
    );
  };

  onCancel = () => {
    history.push("/setup/configure");
  };

  handleOnChange = (event) => {
    const { fields, errors } = this.state;
    const { name, value } = event.target;

    if (fields.hasOwnProperty(name)) {
      fields[name] = value;
    }
    this.setState({
      fields: fields,
    });

    if (fields.AlternativeEmailAddress1) {
      if (!tools.ValidateEmail(fields.AlternativeEmailAddress1)) {
        errors.AlternativeEmailAddress1 = "Please use valid email";
      } else {
        errors.AlternativeEmailAddress1 = "";
      }
    } else {
      errors.AlternativeEmailAddress1 = "Please add email address 1";
    }

    if (fields.AlternativeEmailAddress2) {
      if (!tools.ValidateEmail(fields.AlternativeEmailAddress2)) {
        errors.AlternativeEmailAddress2 = "Please use valid email";
      } else {
        errors.AlternativeEmailAddress2 = "";
      }
    } else {
      errors.AlternativeEmailAddress2 = "Please add email address 2";
    }

    this.setState({
      errors,
    });
  };

  update = (event) => {
    event.preventDefault();
    const { errors, fields } = this.state;
    let validEmail1 = false;
    let validEmail2 = false;

    this.setState({
      formSubmit: true,
    });

    if (fields.AlternativeEmailAddress1) {
      if (!tools.ValidateEmail(fields.AlternativeEmailAddress1)) {
        errors.AlternativeEmailAddress1 = "Please use valid email";
      } else {
        errors.AlternativeEmailAddress1 = "";
        validEmail1 = true;
      }
    } else {
      errors.AlternativeEmailAddress1 = "Please add email address 1";
    }

    if (fields.AlternativeEmailAddress2) {
      if (!tools.ValidateEmail(fields.AlternativeEmailAddress2)) {
        errors.AlternativeEmailAddress2 = "Please use valid email";
      } else {
        errors.AlternativeEmailAddress2 = "";
        validEmail2 = true;
      }
    } else {
      errors.AlternativeEmailAddress2 = "Please add email address 2";
    }

    if(validEmail1 && validEmail2){
      this.emailUpdate();

    }
    this.setState({
      errors,
    });
  };

  emailUpdate = () => {
    const { fields } = this.state;
    let formAttr = {
      AlternativeEmailAddress1: fields.AlternativeEmailAddress1,
      AlternativeEmailAddress2: fields.AlternativeEmailAddress2,
    };

    let url = encodeURI(
      envConfig.BASE_API + Services.CHOLA_TENANTS + "/" + userInfo.TenantId
    );

    restServices.patchRequest(
      url,
      formAttr,
      (response) => {
        if (response) {
          let userData = tools.deCrypt(localStorage.getItem("userData"));
          userData.AlternativeEmailAddress1 = fields.AlternativeEmailAddress1;
          userData.AlternativeEmailAddress2 = fields.AlternativeEmailAddress2;
          localStorage.setItem("userData", tools.crypt(userData));

          common.snack("S", "Email Successfully Updated.");
        }
      },
      (error) => {}
    );
  };

  render() {
    const { fields, errors, formSubmit, userPermissions } = this.state;
    return (
      <div className="lead-wraper">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <Form onSubmit={this.update}>
            <div className="formWhiteBg">
              <div className="formHead">
                <h2>Configure Sub Emails</h2>
              </div>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Sub Email 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="AlternativeEmailAddress1"
                      value={fields.AlternativeEmailAddress1}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.AlternativeEmailAddress1 !== "" &&
                      formSubmit === true && (
                        <Form.Text className="error">
                          {errors.AlternativeEmailAddress1}
                        </Form.Text>
                      )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Sub Email 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="AlternativeEmailAddress2"
                      value={fields.AlternativeEmailAddress2}
                      onChange={(e) => this.handleOnChange(e)}
                    />
                    {errors.AlternativeEmailAddress2 !== "" &&
                      formSubmit === true && (
                        <Form.Text className="error">
                          {errors.AlternativeEmailAddress2}
                        </Form.Text>
                      )}
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <Row className="botBtn">
              <Col sm={12}>
                <Button className="saveBtn" type="submit">
                  Save
                </Button>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    );
  }
}

export { SubEmail };
