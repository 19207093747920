// Import default packages
import React from "react";
import OsmoMaterialTable from "crm/OsmoMaterialTable";
import { history } from "_helpers";
class CampaignLeadList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: this.props.reload,
    };
  }

  refreshChildLeads = () => {
    this.setState({
      refresh: new Date(),
    });
  };

  onAction = (data) => {
    if (data.type == "edit" || data.type == "view") {
      history.push("/crm/lead/edit/" + data.id + "?view=list");
    }
  };

  render() {
    const { refresh } = this.state;
    return (
      <div className="lead-wraper">
        {this.props.campaignId > 0 && refresh && (
          <OsmoMaterialTable
            module="lead"
            reload={refresh}
            sort="CreatedOn:desc"
            onAction={(data) => this.onAction(data)}
            query={{ CampaignId: this.props.campaignId }}
          />
        )}
      </div>
    );
  }
}

export default CampaignLeadList;
