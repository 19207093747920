import React from "react";

// Import layout design packages
import { Col, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Checkbox from "material-ui/Checkbox";
import "../css/LeaveSettings.css";
// Import config and helpers
import { common } from "_helpers";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

class LeaveSettingsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      leaveData: {},
      checked: false,
      CarryForward: false,
      leaveSettingId: "",
      userPermissions: this.props.userPermissions,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    let leaveSettingId = this.props.objectId;
    if (this.props.objectId != undefined) {
      let data = this.props.data;
      this.setState({ leaveData: data });
      if (data.Active === "Y") {
        this.setState({ checked: true });
      }
      if (data.CarryForward === "Y") {
        this.setState({ CarryForward: true });
      }
    }
    this.setState({ leaveSettingId });
  }

  render() {
    const {
      leaveData,
      checked,
      CarryForward,
      leaveSettingId,
      userPermissions,
    } = this.state;
    return (
      <div>
        {leaveSettingId > 0 && (
          <div>
            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">LEAVE SETTINGS</h3>
                {userPermissions.update && (
                  <Link
                    to={
                      "/setup/hrms/leavesetting/edit/" +
                      leaveSettingId +
                      "?filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Leave Type</label>
                    <div className="row set-default-row">
                      <p className="form-value">
                        {leaveData.LeaveType ? leaveData.LeaveType : "-"}
                      </p>
                      <span style={{ color: "#98cc4f" }}>Yearly</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Employee Category</label>
                    <p className="form-value">
                      {leaveData.EmpCategory ? leaveData.EmpCategory : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Division</label>
                    <p className="form-value">
                      {leaveData.DivisionId ? leaveData.DivisionId : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Sub Division</label>
                    <p className="form-value">
                      {leaveData.SubDivisionId ? leaveData.SubDivisionId : "-"}
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Department</label>
                    <p className="form-value">
                      {leaveData.DepartmentId ? leaveData.DepartmentId : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Designation</label>
                    <p className="form-value">
                      {leaveData.DesigId ? leaveData.DesigId : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Description</label>
                    <p className="form-value">
                      {leaveData.Description ? leaveData.Description : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}></Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Start Date</label>
                    <p className="form-value">
                      {leaveData.StartDate == null
                        ? "-"
                        : common.formatDate(leaveData.StartDate, "DD/MM/YYYY")}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">End Date</label>
                    <p className="form-value">
                      {leaveData.EndDate == null
                        ? "-"
                        : common.formatDate(leaveData.EndDate, "DD/MM/YYYY")}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    {/* <label className="form-label">Active</label> */}
                    <Checkbox
                      name="Active"
                      checked={checked}
                      label="Active"
                      labelStyle={{ color: "#2d2d2d" }}
                      labelPosition="left"
                    />
                  </div>
                </Col>
                <Col sm={6}></Col>
              </Row>
            </Paper>

            <Paper style={styles.boxLayout}>
              <div className="box-title-wrap justify-content-between">
                <h3 className="card-title">DETAILS</h3>
                {userPermissions.update && (
                  <Link
                    className="card-link"
                    to={
                      "/setup/hrms/leavesetting/edit/" +
                      leaveSettingId +
                      "?filterName=" +
                      this.props.filterName +
                      "&page=" +
                      this.props.page
                    }
                  >
                    Change
                  </Link>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Paid Leaves</label>
                    <div className="row set-default-row">
                      <p className="form-value">
                        {leaveData.PaidLeaves ? leaveData.PaidLeaves : "-"}
                      </p>
                      <span style={{ color: "#98cc4f" }}>Yearly</span>
                    </div>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Max Leaves</label>
                    <p className="form-value">
                      {leaveData.MaxLeaves ? leaveData.MaxLeaves : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Min Service</label>
                    <p className="form-value">
                      {leaveData.MinService ? leaveData.MinService : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Max Leaves per Month</label>
                    <p className="form-value">
                      {leaveData.MaxLvPerMnth ? leaveData.MaxLvPerMnth : "-"}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="list-row">
                    <label className="form-label">Leave Expiry in Days</label>
                    <p className="form-value">
                      {leaveData.ExpiryDays ? leaveData.ExpiryDays : "-"}
                    </p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="list-row">
                    {/* <label className="form-label">Carry Forward</label> */}
                    <Checkbox
                      name="Active"
                      checked={CarryForward}
                      label="Carry Forward"
                      labelStyle={{ color: "#2d2d2d" }}
                      labelPosition="left"
                    />
                  </div>
                </Col>
              </Row>
            </Paper>
          </div>
        )}
        {leaveSettingId == 0 && (
          <div className="formTabSection position-r">
            <Paper style={styles.boxLayout}>
              <NoRecordFound />
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

export default LeaveSettingsDetail;
