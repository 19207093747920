import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import RaisedButton from "material-ui/RaisedButton";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
// includes
import { envConfig } from "_config/config";
import * as Services from "_config/api";
import { restServices, common, history } from "_helpers";
// pages
import QuoteCrud from "./includes/QuoteCrud";
import Attachments from "pages/_components/Attachments/Attachments";
import AnnexuresList from "pages/_components/Annexures/AnnexuresList/AnnexuresList";
import ContactLinks from "pages/_components/Contacts/ContactLinks";
import History from "pages/_components/History/pages/History";
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import { StatusBadge } from "pages/_components/StatusBadge";

class Quote extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      isLoading: true,
      badgeStatus: "",
      quoteData: [],
      currentTab: "general",
      userPermissions: common.getPermission(this.props, "crm:quote"),
      open: false,
    };
  }

  componentDidMount() {
    const { params, userPermissions } = this.state;
    if (params.quoteId) {
      this.getQuoteDetail();
    } else {
      this.setLoading(false);
    }
  }

  getQuoteDetail = () => {
    const { params } = this.state;
    this.setLoading(true);
    let filter = '?q=CustQuotId=' + params.quoteId;
    let url = envConfig.BASE_API + Services.CRM_QUOTES + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            badgeStatus: response.items[0].ApprStatus,
            quoteData: response,
          });
        }
        this.setLoading(false);
      },
      (error) => {
        this.setLoading(false);
      }
    );
  };

  setBadgeStatus(status) {
    this.setState({ badgeStatus: status })
  }

  // common
  setLoading(status) {
    this.setState({ isLoading: status })
  }

    /**
   * Tab onclick event
   */
  handleSelect (key) {
    let url = window.location.pathname;
    let  queryParam = window.location.search;
    if(queryParam.includes("tab=")){
      let params = new URLSearchParams(queryParam);
      let tab = params.get("tab");
      queryParam = queryParam.replace("tab="+tab, "tab="+key);
    }
    history.push(url + queryParam);
  }

  handleClick = (event) => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  convertToOrder = (e) => {
    e.preventDefault();    
    const { params, quoteData } = this.state;
    if(quoteData.items[0].OrderConverted == "Y"){
      common.snack("E", "Already converted this Quote");
    }else {
      if(quoteData.items[0].CustomerId){
        var createParams = {
          name: "convertQuoteToOrder",
          parameters: [
            { P_CUST_QUOTE_ID: params.quoteId },
            { P_DELIVERY: "" },
            { P_DESCRIPTION: "order" },
            { P_ORDER_TYPE : "" },
            { P_ORDER_SOURCE : "" },
            { P_TERRITORY_ID : "" },
            { P_CAMPAIGN_ID : "" },
          ],
        };

        let url = encodeURI(
          envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION
        );
        restServices.postRequest(
          url,
          createParams,
          (response) => {
            if (response.status >= 200 && response.status <= 300) {
              common.snack("S", "Successfully Converted as Order");
              this.setState({
                open: false,
              });
            }
          },
          (error) => {
            common.snack("E", error);
          }
        );
      }else{
        common.snack("E", "Please save the Customer Account and then Convert");
      }
    }
  }

  render() {
    const {
      params,
      isLoading,
      quoteData,
      badgeStatus,
      currentTab,
      userPermissions
    } = this.state;

    return (
      <div>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div className="lead-wraper">
            {isLoading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <div className="secondary-head">
                  {quoteData != "" ? (
                    <h1>Quote No: {quoteData.items[0].QuotationNo}</h1>
                  ) : (
                    <h1>Add Quote</h1>
                  )}
                </div>
                <StatusBadge
                  type="QUOTE"
                  status={badgeStatus}
                  onSelect={(status) => this.setBadgeStatus(status)}
                />
                <div className="formTabSection position-r">
                  <Tabs
                    defaultActiveKey={currentTab ? currentTab : "general"}
                    onSelect={this.handleSelect}
                  >
                    <Tab eventKey="general" title="General">
                      <QuoteCrud
                        quoteId={params.quoteId}
                        quoteData={quoteData}
                        badgeStatus={badgeStatus}
                        permissions={this.props.permissions}
                      />
                    </Tab>
                    <Tab
                      eventKey="contact"
                      title="Contacts"
                      disabled={!params.quoteId ? "disabled" : ""}
                    >
                      <ContactLinks
                        objectId={params.quoteId}
                        objectType={common.getObjectNameByPage("quote")}
                      />
                    </Tab>
                    <Tab
                      eventKey="attachments"
                      title="Attachments"
                      disabled={!params.quoteId ? "disabled" : ""}
                    >
                      <Attachments
                        objectId={params.quoteId}
                        objectType="quote"
                      />
                    </Tab>
                    <Tab
                      eventKey="annexures"
                      title="Annexures"
                      disabled={!params.quoteId ? "disabled" : ""}
                    >
                      <AnnexuresList
                        objectId={params.quoteId}
                        objectType="quote"
                      />
                    </Tab>
                    <Tab
                      eventKey="history"
                      title="History"
                      disabled={!params.quoteId ? "disabled" : ""}
                    >
                      {!isLoading && params.quoteId && (
                        <History campaign={quoteData} />
                      )}
                    </Tab>
                  </Tabs>
                  {quoteData != "" &&
                    <div className="action-btn-wrap position-a action-btn-position">
                      <RaisedButton
                        disabled={params.quoteId === 0}
                        onClick={this.handleClick}
                        label="Action"
                      />
                      <Popover
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        targetOrigin={{ horizontal: "left", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}
                      >
                        <Menu autoFocusItem={true}>
                          <MenuItem
                            primaryText="Convert to Quote"
                            onClick={(e) => this.convertToOrder(e)}
                          />
                        </Menu>
                      </Popover>
                    </div>
                  }
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Quote;
