import React from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// pages
import TargetModal from "./TargetModal";
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// css
import "../css/Target.css";
const userInfo = common.authInfo();
class Target extends React.Component {
  constructor(props) {
    super(props);
    const selectVal = { label: "Select", value: "" };
    this.state = {
      isModal: false,
      isLoading: true,
      fields: {
        target: selectVal,
      },
      selectVal,
      sData: {
        configure: [],
        targetList: [],
      },
      empId: userInfo.EmpId,
      userPermissions: common.getPermission(this.props, "target"),
    };
  }
  // cycle
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getConfigureTarget();
    }
  }

  // api calls
  getConfigureTarget = async () => {
    let query = `?orderBy=TargetYear:desc&totalResults=true&limit=10000&offset=0`;
    let url = encodeURI(envConfig.BASE_API + Services.CONFIGURE_TARGET + query);

    restServices.getRequest(
      url,
      (response) => {
        this.mergeConfigureTaregt(response);
      },
      (error) => {
        this.setLoading(false);
      }
    );
  };

  mergeConfigureTaregt(res) {
    const { fields, sData } = this.state;
    sData.configure = [];
    if (res) {
      if (res.totalResults > 0) {
        res.items.map((item, key) => {
          let data = {
            label: "",
            value: item.CrmTargetId,
          };
          if (item.TargetUom == "month") {
            data.label = `${item.TargetYear} - ${item.TargetMonth} (${item.TargetTypeName})`;
          } else if (item.TargetUom == "quarter") {
            data.label = `${item.TargetYear} - Quarter ${item.TargetQuarter} (${item.TargetTypeName})`;
          } else if (item.TargetUom == "year") {
            data.label = `${item.TargetYear} - (${item.TargetTypeName})`;
          }
          sData.configure.push(data);
        });
        fields.target = sData.configure[0];
      }
    }

    this.setState(
      {
        fields,
        sData,
      },
      () => {
        if (fields.target.value != "") {
          this.getTargetList();
        } else {
          this.setLoading(false);
        }
      }
    );
  }

  getTargetList() {
    const { fields, sData } = this.state;
    let query = `?q=CrmTargetId=${fields.target.value}&orderBy=CreatedOn:desc&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.EMPLOYEE_TARGET + query;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response.totalResults > 0) {
            let result = [];
            response.items.map((item, key) => {
              item.targetReport = {
                completed: item.ActualValue,
                remaining: item.TargetRscValue - item.ActualValue > 0 ? item.TargetRscValue - item.ActualValue : 0,
                progress:
                  item.TargetRscValue > 0
                    ? ((item.ActualValue / item.TargetRscValue) * 100).toFixed(
                      0
                    )
                    : 0,
              };
              result.push(item);
            });
            sData.targetList = result;
            this.setState({
              sData,
              isLoading: false,
            });
          } else {
            this.setLoading(false);
          }
        }
      },
      (error) => {
        this.setLoading(false);
      }
    );
  }

  // support
  getTargetName() {
    const { fields } = this.state;
    let target = fields.target.label;
    let targetName = "Revenue";
    if (target.includes("No of")) {
      targetName = target.substring(target.indexOf("of ") + 3, target.length - 1);
    }
    return targetName;
  }

  // handler
  handleSelectChange(data, name) {
    const { fields, sData } = this.state;
    fields[name] = data;
    sData.targetList = [];
    this.setState(
      {
        fields,
        sData,
        isLoading: true,
      },
      () => {
        this.getTargetList();
      }
    );
  }

  // modal
  onComplete(isModal) {
    const { selectVal } = this.state;
    this.setState(
      {
        isLoading: true,
        isModal,
        fields: {
          target: selectVal,
        },
        sData: {
          configure: [],
          targetList: [],
        },
      },
      () => {
        this.getConfigureTarget();
      }
    );
  }

  // support
  setModel(status) {
    this.setState({ isModal: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  render() {
    const { isLoading, isModal, fields, sData, userPermissions } = this.state;
    return (
      <div className="target-wrap" style={{ minHeight: "800px" }}>
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
            <div>
              <div className="header-title-wrap target-title-wrap d-flex justify-content-between align-items-center">
                <h2 className="crm-type-title">Employee Target</h2>
                {userPermissions.update && (
                  <button
                    type="button"
                    className="btn btn-add"
                    onClick={() => this.setModel(true)}
                  >
                    Configure Target
                  </button>
                )}
              </div>
              <div>
                <div className="row justify-content-between target-deadline-info">
                  <div className="col-lg-8">
                    <div className="form-row">
                      <div className="col-md-4">
                        {/* <h4 className="general-targets">Total Target - <span>{sData.configure.length}</span> deals</h4> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-row justify-content-end">
                      <div className="col-lg-6">
                        <h4 className="taget-period">Target Type -</h4>
                      </div>
                      <div className="col-lg-6">
                        <Select
                          className="select-control"
                          options={sData.configure}
                          value={fields.target}
                          onChange={(data) =>
                            this.handleSelectChange(data, "target")
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <Loader />
                ) : sData.targetList == "" ? (
                  <NoRecordFound />
                ) : (
                      <div>
                        <Accordion defaultActiveKey="0" className="user-target-list">
                          {sData.targetList.map((item, key) => (
                            <Card key={key} className="target-details-wrap mb-4">
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey={String(key)}
                                className="progress-head-wrap d-flex justify-content-between align-items-center"
                              >
                                <div className="target-user-info d-flex align-items-center">
                                  {/* <img src={User} alt="User Icon" /> */}
                                  <img src={envConfig.IMG_SERVLET_URL + "?imageId=" + item.CrmRscTargetId + "&imageType=EMPLOYEE"} />
                                  <div className="tuser-details-wrap">
                                    <h4 className="tuser-name">
                                      {item.CrmRscTargetName}
                                    </h4>
                                    <p className="tuser-designation">
                                      {item.CrmRscTargetDesc}
                                    </p>
                                  </div>
                                </div>

                                <div className="target-progressbar-wrap">
                                  <ProgressBar
                                    now={item.targetReport.progress}
                                    label={`${item.targetReport.progress}%`}
                                  />
                                  <p className="progress-text">
                                    {item.ActualValue} {this.getTargetName()}
                                  </p>
                                </div>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={String(key)}>
                                <Card.Body>
                                  <div className="target-total-progress">
                                    <ul className="ttp-list row">
                                      <li className="col-md-4">
                                        <div className="progress-info">
                                          <p className="target-progress-status">
                                            Completed
                                    </p>
                                          <h2 className="target-progress-count">
                                            <span>{item.targetReport.completed}</span>{" "}
                                            {this.getTargetName()}
                                          </h2>
                                        </div>
                                      </li>

                                      <li className="col-md-4">
                                        <div className="progress-info">
                                          <p className="target-progress-status">
                                            Remaining
                                    </p>
                                          <h2 className="target-progress-count">
                                            <span>{item.targetReport.remaining}</span>{" "}
                                            {this.getTargetName()}
                                          </h2>
                                        </div>
                                      </li>

                                      <li className="col-md-4">
                                        <div className="progress-info">
                                          <p className="target-progress-status">
                                            Total Progress
                                    </p>
                                          <h2 className="target-progress-count">
                                            <span>{item.targetReport.progress}</span>{" "}
                                      %
                                    </h2>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          ))}
                        </Accordion>
                      </div>
                    )}
              </div>
              {isModal && (
                <TargetModal
                  closeModal={() => this.setModel(false)}
                  onComplete={(isModal) => this.onComplete(isModal)}
                />
              )}
            </div>
          )}
      </div>
    );
  }
}

export { Target };
