import React from "react";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history } from "_helpers";
// includes
import DynamicForm from "crm/DynamicForm";
import Loader from "_components/_loader/Loader";
class AddMarketingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      isLoading: false,
      campaignId: 0,
      listId: 0,
      campaignData: {},
      returnPage: "",
    };
  }
  /**
   * Component Hooks
   */
  componentDidMount() {
    const { params } = this.state;
    console.log("componentDidMount :>> ", params);
    if (params.listId != undefined) {
      this.setState(
        {
          campaignId: params.campaignId,
          listId: params.listId,
        },
        () => {
          this.getCampaignDetail(params.campaignId);
        }
      );
    } else {
      this.setState(
        {
          campaignId: params.campaignId,
        },
        () => {
          this.getCampaignDetail(params.campaignId);
        }
      );
    }

    let search = window.location.search;
    let param = new URLSearchParams(search);
    let filterName = param.get("filterName");
    let page = param.get("page");
    let filterListName = param.get("filterListName");
    let listPage = param.get("listPage");

    let returnPage = "";
    if (filterListName || page || listPage || filterName) {
      returnPage +=
        "&filterListName=" +
        filterListName +
        "&listPage=" +
        listPage +
        "&filterName=" +
        filterName +
        "&page=" +
        page +
        "&listId=" +
        params.listId;
    }

    this.setState({
      returnPage,
    });
  }
  /**
   * Get Campaign Details by ID
   */
  getCampaignDetail = (id) => {
    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          this.setState({
            campaignData: response,
            isLoading: false,
          });
        }
      },
      (error) => { }
    );
  };

  saveCallback = (res) => {
    const { returnPage, campaignId } = this.state;
    history.push(
      "/crm/campaign/edit/" + campaignId + "?tab=marketinglist" + returnPage
    );
  };

  cancelCallBack = () => {
    const { returnPage, campaignId } = this.state;
    console.log('returnPage :>> ', returnPage);
    history.push(
      "/crm/campaign/edit/" + campaignId + "?tab=marketinglist" + returnPage
    );
  };

  render() {
    const { listId, campaignId, isLoading, campaignData } = this.state;

    return (
      <div className="lead-wraper">
        {!isLoading && (
          <DynamicForm
            module="enquiry"
            data={{
              value1: listId > 0 ? listId : ""
            }}
            default={{
              CampaignId: {
                label: campaignData.CampaignName,
                value: campaignId,
              },
            }}
            disabled={{ CampaignId: true }}
            onSave={this.saveCallback}
            onCancel={this.cancelCallBack}
          />
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export { AddMarketingList };
