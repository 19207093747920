import React from "react";
import { history } from "_helpers";
// includes
import DynamicForm from "crm/DynamicForm";
import Loader from "_components/_loader/Loader";
class AddMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props.match.params,
      isLoading: true,
      listId: 0,
      campaignId: 0,
      detailId: 0,
      returnPage: "",
    };
  }

  componentDidMount() {
    const { params } = this.state;

    let search = window.location.search;
    let param = new URLSearchParams(search);
    let filterName = param.get("filterName");
    let page = param.get("page");
    let filterListName = param.get("filterListName");
    let listPage = param.get("listPage");

    if (params.detailId != undefined) {
      this.setState(
        {
          listId: params.listID,
          campaignId: params.campaignId,
          detailId: params.detailId,
          isLoading: false,

        });
    } else {
      this.setState(
        {
          listId: params.listID,
          campaignId: params.campaignId,
          isLoading: false,
        });
    }

    let returnPage = "";
    if (filterListName || page || listPage || filterName) {
      returnPage +=
        "&filterListName=" +
        filterListName +
        "&listPage=" +
        listPage +
        "&filterName=" +
        filterName +
        "&page=" +
        page +
        "&listId=" +
        params.listID;
    }
    this.setState({
      returnPage,
    });
  }

  saveCallback = (res) => {
    console.log("seve res :>> ", res);
    const { campaignId, returnPage } = this.state;
    history.push(
      "/crm/campaign/edit/" + campaignId + "?tab=marketinglist" + returnPage
    );
  };

  cancelCallBack = () => {
    const { campaignId, returnPage } = this.state;
    history.push(
      "/crm/campaign/edit/" + campaignId + "?tab=marketinglist" + returnPage
    );
  };

  render() {
    const { listId, detailId, isLoading } = this.state;
    return (
      <div className="lead-wraper">
        {!isLoading && (
          <DynamicForm
            module="enquiryMember"
            data={{
              value1: (detailId > 0 ? detailId : ""),
              value2: listId
            }}
            disabled={{ LeadConverted: true }}
            onSave={this.saveCallback}
            onCancel={this.cancelCallBack}
          />
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

export { AddMember };
