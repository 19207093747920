import React, { createRef } from "react";

// Import layout design packages
import { Form, Col, Button, Row } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
// includes
import { crm } from '_utils';
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

// Import Images
import SearchIcon from "img/search-icn.png";
import EditIcon from "img/edit.svg";
import DeleteIcon from "img/delete.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import ReportLists from "./ReportLists";
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css"; import Dropzone from "react-dropzone";
import Checkbox from "material-ui/Checkbox";

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

class ManageReportsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      open: false,
      isLoading: false,
      isSearchLoad: false,
      isLoadingAdd: false,
      searchList: [],
      selectedReport: {},
      selectedReportId: 0,
      fields: {
        ReportCode: "",
        ReportName: "",
        Description: "",
        StartDate: new Date(),
        ActiveReport: "Y",
        TenantId: crm.userInfo().pTenantId,
        TemplatePath: ""
      },
      errors: {
        ReportCode: {
          error: "",
          isReq: "Report Code is required!",
        },
        ReportName: {
          error: "",
          isReq: "Report Name is required!",
        },
        Description: {
          error: "",
          isReq: "Description is required!",
        },
        StartDate: {
          error: "",
          isReq: "Start Date is required!",
        },
        ActiveReport: {
          error: "",
          isReq: "Active is required!",
        },
      },
      commonResource: {
        ReportId: 0,
      },
      paging: {
        page: 1,
        size: 10,
        total: 1,
      },
      totalRecords: 0,
      filterName: "",
      userPermissions: common.getPermission(this.props, "reports"),
      fileBase64Content: ''
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    const { userPermissions } = this.state;
    if (userPermissions.access) {
      this.getList();
    }
    let userData = common.authInfo();
    if (userData.role !== "adminuser") {
      history.push("/setup/hrms");
    }
  }

  /**
   * Add Report modal popup
   */
  addReportModal = (event, reportId) => {
    event.preventDefault();
    const { commonResource, fields, errors } = this.state;
    commonResource.ReportId = reportId;
    this.setState({
      open: true,
      commonResource: commonResource,
    });
    if (reportId > 0) {
      this.getDetailById(reportId);
    } else {
      for (var key of Object.keys(fields)) {
        fields[key] = "";
      }
      for (var key of Object.keys(errors)) {
        errors[key].error = "";
      }
      fields["StartDate"] = new Date();
      fields["ActiveReport"] = "Y";
      this.setState({
        fields,
        errors
      })
    }
  };
  /**
   * Get details by id & set data in fields
   */
  getDetailById = (id) => {
    const { fields, errors } = this.state;
    this.setState({
      isLoadingAdd: true,
    });

    let url = encodeURI(
      envConfig.BASE_API + Services.REPORTS + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                if (key === "StartDate") {
                  fields[key] = response[key] ? new Date(response[key]) : "";
                } else {
                  fields[key] = response[key];
                }

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key].error = "";
                }
              }
            }
          }
          this.setState({
            fields: fields,
            errors: errors,
            isLoadingAdd: false,
          });
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Close Modal Popup - leave calender year
   */
  closeAddReportModal = () => {
    this.resetFields(this.state.searchList);
  };

  resetFields = (searchList) => {
    const { fields, errors } = this.state;
    for (var key of Object.keys(fields)) {
      fields[key] = "";
    }
    fields["StartDate"] = new Date();
    fields["ActiveReport"] = "Y";
    // Remove Default Errors
    for (var key of Object.keys(errors)) {
      if (errors.hasOwnProperty(key)) {
        errors[key].error = "";
      }
    }
    this.setState({
      fields: fields,
      open: false,
      isLoadingAdd: false,
      //searchList: searchList,
      formSubmit: false,
      errors: errors,
    });
  };

  /**
   * Methods
   */
  handleClickList = (report) => {
    this.setState(
      {
        selectedReport: report ? report : {},
        selectedReportId: report ? report.ReportId : 0,
        isLoading: true,
      },
      (_) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  handleSearchChange = (e) => {
    const { name, value } = e.target;
    const { paging } = this.state;

    this.setState({
      filterName: value
    }, () => {
      //reset page number to 1
      paging.page = 1;

      this.setState({
        paging: paging,
      }, () => {
        this.getList();
      })
    })
  };

  /**
   * Service Request
   */
  getList() {
    const { paging, searchList, filterName } = this.state;
    let tempList = [];

    if (paging.page == 1) {
      this.setState({
        isSearchLoad: true,
        searchList: [],
      });
    } else {
      searchList.map((item) => {
        tempList.push(item);
      });
    }

    // let filter = `?finder=FindReports;pReportCode=CHOLA_OPPORTUNITY_DETAILS`;

    let filter = `?q=ActiveReport='${"Y"}' AND UPPER(ReportName) LIKE '*${filterName}*' &totalResults=true&orderBy=CreatedOn:desc&offset=${(paging.page - 1) * paging.size
      }&limit=${paging.size}`;

    let url = envConfig.BASE_API + Services.REPORTS + filter;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        let selectedReport = this.state.selectedReport;

        if (response && response.items && response.items.length > 0) {
          // paging
          paging.total = Math.ceil(response.totalResults / paging.size);

          response.items.map((item, key) => {
            tempList.push(item);
          });

          if (paging.page === 1) {
            selectedReport = tempList[0];
          }

          this.setState(
            {
              totalRecords: response.totalResults,
              searchList: tempList,
              isSearchLoad: false,
              selectedReport,
              paging: paging,
            },
            (_) => {
              this.handleClickList(selectedReport);
            }
          );
        } else {
          this.setState({
            isSearchLoad: false,
            searchList: tempList,
          },
            (_) => {
              this.handleClickList();
            });
        }
      },
      (error) => {
        common.snack("E", error);
        this.setState({
          isSearchLoad: false,
        });
      }
    );
  }

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;

    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm();
      }
    );
  };

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;

    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();
    if (isValid) {
      if (commonResource.ReportId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, fileBase64Content } = this.state;

    let url = encodeURI(
      envConfig.BASE_API +
      Services.REPORTS +
      "/" +
      commonResource.ReportId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          if (fileBase64Content !== "") {
            this.fileUpload(commonResource.ReportId);
          } else {
            this.resetFields();
            this.getList();
          }
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  create = () => {
    const { fields, fileBase64Content } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.REPORTS);
    fields["TenantId"] = crm.userInfo().pTenantId
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          if (fileBase64Content !== "") {
            this.fileUpload(response.ReportId);
          } else {
            this.resetFields();
            this.getList();
          }
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  fileUpload = (ReportId) => {
    const { fileBase64Content, commonResource } = this.state;
    let data = {
      ReportId: ReportId,
      ReportBlobFile: fileBase64Content
    }
    let url = encodeURI(
      envConfig.BASE_API +
      Services.REPORTS +
      "/" +
      ReportId
    );
    restServices.patchRequest(
      url,
      data,
      (response) => {
        if (response) {
          this.resetFields();
          this.getList();
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  }

  /**
   * Trigger Calendar Leave List Popup
   * @param {*} id 
   */
  deleteSelectedItem = (id) => {
    common.confirmDelete(true, (response) => {
      this.handleClickDeleteList(id);
    });
  }

  /**
   * Delete Calendar Leave by ID
   * @param {*} leaveId 
   */
  handleClickDeleteList = (id) => {
    const { paging } = this.state;

    this.setState({ isLoading: true });
    let url = envConfig.BASE_API + Services.REPORTS + "/" + id;
    restServices.deleteRequest(
      url,
      (response) => {
        //page reset
        paging.page = 1;

        this.setState({
          paging: paging
        }, () => {
          this.getList();
        });
        common.snack("S", "Report deleted successfully.");
      },
      (error) => {
        common.snack("E", error);
      });
  }

  pagination(e) {
    e.preventDefault();
    const { paging } = this.state;
    if (paging.page != paging.total) {
      paging.page += 1;
    }
    this.setState(
      {
        paging,
      },
      () => {
        this.getList();
      }
    );
  }

  /**
   * Save files using Drop method
   */
  onDrop = (files) => {
    const { fields } = this.state;
    fields.TemplatePath = files[0].name;
    if (files.size != 0) {
      common.getBase64(files[0], (result) => {
        this.setState({
          fileBase64Content: result,
        });
      });
    }
  }

  render() {
    const {
      isSearchLoad,
      searchList,
      selectedReport,
      selectedReportId,
      isLoading,
      fields,
      errors,
      formSubmit,
      commonResource,
      isLoadingAdd,
      open,
      paging,
      totalRecords,
      filterName,
      userPermissions
    } = this.state;
    const isChecked = (value) => value == "Y";

    return (
      <div className="crm-list-wrap">
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            <Row>
              <Col lg={3} sm={4}>
                <div className="marketing-list-wrap">
                  <div className="crm-title-wrap d-flex justify-content-between align-items-center mb-4">
                    <h2 className="crm-type-title">Manage Reports</h2>
                    {/* {userPermissions.add && (
                      <Link
                        to="#"
                        className="btn btn-add"
                        onClick={(e) => this.addReportModal(e, 0)}
                      >
                        Add
                      </Link>
                    )} */}
                  </div>

                  <Paper>
                    <div className="input-group search-ml-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <img
                            src={SearchIcon}
                            alt="Search"
                          //onClick={this.getList}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="filterName"
                        onChange={(e) => this.handleSearchChange(e)}
                        placeholder="Search Report"
                        name="filterName"
                      />
                    </div>
                    <ul className="marketing-list">
                      {isSearchLoad == false &&
                        searchList.length > 0 &&
                        searchList.map((row, index) => {
                          return (
                            <li
                              key={row.ReportId}
                              className={`d-flex justify-content-between align-items-center ${row.ReportId == selectedReport.ReportId
                                ? "active"
                                : ""
                                }`}
                            >
                              <div className="mlLeft mr-3">
                                <h4
                                  className="ml-name"
                                  onClick={(e) => this.handleClickList(row)}
                                >
                                  {row.ReportName}
                                </h4>
                                <p className="ml-type">
                                  {row.ReportCode}
                                </p>
                              </div>
                              {/* <div className="mlRight">
                                {userPermissions.update && (
                                  <Link to="#"
                                    onClick={(e) =>
                                      this.addReportModal(e, row.ReportId)
                                    }
                                  >
                                    <img src={EditIcon} alt="Edit" />
                                  </Link>
                                )}
                                {userPermissions.delete && (
                                  <Link to="#"
                                    style={{ marginLeft: 10 }}
                                    onClick={() =>
                                      this.deleteSelectedItem(row.ReportId)
                                    }
                                  >
                                    <img src={DeleteIcon} alt="Delete" />
                                  </Link>
                                )}
                              </div> */}
                            </li>
                          );
                        })}
                      {isSearchLoad == true && (
                        <li>
                          <Loader />
                        </li>
                      )}
                      {isSearchLoad == false && searchList.length === 0 && (
                        <li>
                          <NoRecordFound />
                        </li>
                      )}
                    </ul>
                    {/* Pagination */}
                    {searchList.length > 0 && (
                      <div className="load-more-wrap">
                        {paging.page != paging.total && searchList.length > 0 && (
                          <Link
                            to="#"
                            onClick={(e) => this.pagination(e)}
                            className="load-more"
                          >
                            {" "}
                            Load More{" "}
                          </Link>
                        )}
                        <div className="paging-text">
                          1 to {searchList.length} of {totalRecords} entries
                        </div>
                      </div>
                    )}
                  </Paper>
                </div>
              </Col>
              <Col lg={9} sm={8}>
                <div className="formTabSection position-r">
                  {isLoading ? (
                    <Loader />
                  ) : (!isLoading &&
                    <ReportLists
                      objectId={selectedReportId}
                      userPermissions={userPermissions}
                      selectedReport={selectedReport} />
                  )}
                </div>
              </Col>
            </Row>
            <Dialog
              open={open}
              onClose={this.closeAddReportModal}
              aria-labelledby="responsive-dialog-title"
              className="leave-dialog"
            >
              <DialogTitle id="responsive-dialog-title" className="popup-title">
                {"Manage Reports"}
              </DialogTitle>
              <DialogContent>
                {!isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Report Code*</Form.Label>
                        <Form.Control
                          type="text"
                          name="ReportCode"
                          value={fields.ReportCode}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.ReportCode.error !== "" &&
                          formSubmit === true && (
                            <Form.Text className="error">
                              {errors.ReportCode.error}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </div>

                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Report Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="ReportName"
                          value={fields.ReportName}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.ReportName.error !== "" &&
                          formSubmit === true && (
                            <Form.Text className="error">
                              {errors.ReportName.error}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </div>

                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="Description"
                          value={fields.Description}
                          onChange={(e) => this.handleOnChange(e)}
                        />
                        {errors.Description.error !== "" &&
                          formSubmit === true && (
                            <Form.Text className="error">
                              {errors.Description.error}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </div>

                    <div className="list-row">
                      <Dropzone ref={dropzoneRef} noClick noKeyboard
                        onDropRejected={(reject) => common.snack("E", reject[0].errors[0].message)}
                        onDropAccepted={this.onDrop}
                        maxFiles={1}
                        multiple={false}
                        accept=".jasper"
                      >
                        {({ getRootProps, getInputProps, acceptedFiles }) => {
                          return (
                            <div className="dropzone-container">
                              <div className="dropzone-box mb-5">
                                <div {...getRootProps({ className: "dropzone text-center" })}>
                                  <input {...getInputProps()} />
                                  <p>Drag and drop files here or click</p>
                                  <button
                                    type="button"
                                    onClick={openDialog}
                                    className="saveBtn btn btn-primary"
                                  >
                                    Select a file to upload
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                      <p>{fields.TemplatePath && fields.TemplatePath}</p>
                    </div>

                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>Start Date*</Form.Label>
                        <DatePicker
                          name="StartDate"
                          value={fields.StartDate}
                          onChange={(date) =>
                            this.handleDateOnChange(date, "StartDate")
                          }
                          format="dd/MM/yyyy"
                          autoComplete="off"
                        //minDate={new Date()}
                        />
                        {errors.StartDate.error !== "" && formSubmit === true && (
                          <Form.Text className="error">
                            {errors.StartDate.error}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </div>


                    <div className="list-row">
                      <Form.Group>
                        <Form.Label>&nbsp; </Form.Label>
                        <Checkbox
                          name="ActiveReport"
                          className="filter-checkbox"
                          checked={isChecked(fields.ActiveReport)}
                          label="Active"
                          labelStyle={{ color: "#2d2d2d" }}
                          labelPosition="left"
                          onClick={(e) => this.handleOnChange(e)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {isLoadingAdd && (
                  <div className="popup-form-wrap">
                    <div className="list-row">
                      <Loader />
                    </div>
                  </div>
                )}
              </DialogContent>
              <DialogActions className="popup-footer justify-content-start">
                <Row className="botBtn">
                  <Col sm={12}>
                    <Button
                      className="saveBtn"
                      onClick={this.onSubmit}
                      type="submit"
                    >
                      {commonResource.ReportId === 0 ? "Add" : "Update"}
                    </Button>
                    <Button
                      className="cancelBtn"
                      onClick={this.closeAddReportModal}
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    );
  }
}

export { ManageReportsList };
