// Import default packages
import React from "react";
import { Link } from "react-router-dom";

// Import table packages
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
// Import internal components
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { tableConfig, restServices, common } from "_helpers";

// Import others
import Paper from "@material-ui/core/Paper";
import Pagination from "react-js-pagination"; // Paginations

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});

class CustomerSiteList extends React.Component {
  constructor() {
    super();
    this.state = {
      parentId: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      tlConfig: {
        headCells: [
          { id: "SiteName", value: "SiteName", label: "Site Name" },
          { id: "SiteCode", value: "SiteCode", label: "Site Code" },
          { id: "PrimaryFlag", value: "PrimaryFlag", label: "Primary" },
          { id: "ServiceTo", value: "ServiceTo", label: "Service To" },
          { id: "BilltoFlag", value: "BilltoFlag", label: "Bill To" },
          { id: "ShiptoFlag", value: "ShiptoFlag", label: "Ship To" },
          { id: "AddressId", value: "AddressId", label: "Address" },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "SiteName",
        selected: [],
        uniqueKey: "CustomerSiteListcolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
      filterName: "",
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    if (this.props.objectId != undefined) {
      this.getList();
    }
  }

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.SiteId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig } = this.state;
    let inputData = [];
    tlConfig.selected.map((id, index) => {
      inputData.push({
        id: "part" + (index + 1),
        path: "/" + Services.CRM_CUSTOMER_SITE + "/" + id,
        operation: "delete",
        payload: {},
      });
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getList();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig,
          });
          common.snack("S", "Selected items has been deleted successfully.");
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  /**
   * Handle pagination number changed
   * @param {*} pageNumber
   */
  handlePageChange = (pageNumber) => {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getList();
        }
      );
    }
  };

  handleFieldChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getList();
      }
    );
  };

  /**
   * Get all list
   */
  getList = () => {
    const { currentPage, tlConfig, pageLimit } = this.state;
    this.setState({ isLoading: true });

    let filter = "?q=CustomerId=" + this.props.objectId;

    if (tlConfig.OrderBy) {
      filter += "&orderBy=" + tlConfig.OrderBy;
    }

    let url = envConfig.BASE_API + Services.CRM_CUSTOMER_SITE + filter;

    restServices.getRequest(
      url,
      (response) => {
        tlConfig.enableMultipleDelete =
          response && response.items && response.items.length ? true : false;
        tlConfig.delItemCount = tlConfig.enableMultipleDelete ? response.items.length : 0;
        this.setState({
          listRes: response,
          isLoading: false,
          tlConfig: tlConfig,
        });
      },
      (error) => {
        this.setState({
          listRes: {},
          isLoading: false,
        });
      }
    );
  };

  render() {
    const {
      isLoading,
      filterName,
      tlConfig,
      listRes,
      currentPage,
      pageLimit,
    } = this.state;

    const isHided = (SiteId) => tlConfig.columnsSelected.indexOf(SiteId) !== -1;
    const isSelected = (SiteId) => tlConfig.selected.indexOf(SiteId) !== -1;

    const handleClick = (event, SiteId) => {
      const selectedIndex = tlConfig.selected.indexOf(SiteId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, SiteId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    return (
      <div className="territory-wraper">
        {/* end of Secondary Nav */}
        <div className="filter-wrap">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-3">
              {/* <div className="form-row">
                                <div className="col">
                                    <h2 className="crm-type-title">All Sites</h2>
                                </div>
                            </div> */}
            </div>
            <div className="col-12 col-lg-9">
              <div className="form-row justify-content-end">
                <div className="col-lg-4">
                  <EnhancedTableColumns
                    tlConfig={tlConfig}
                    updateTLConfig={this.updateTLConfig}
                  />
                </div>

                <div className="col-auto">
                  <Link to={"/setup/vendor/site/add/"
                    + this.props.objectId
                    + "?filterName=" +
                    this.props.filterName +
                    "&page=" +
                    this.props.page}>
                    <button type="button" className="btn btn-add">
                      Add
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* end of Filter Wrap */}
        <div className="crm-data-wrap">
          <div className="clearfix"></div>

          <div className="list-view-wrap">
            <EnhancedTableToolbar
              tlConfig={tlConfig}
              deleteSelected={this.deleteSelected}
            />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <EnhancedTableHead
                  tlConfig={tlConfig}
                  updateTLConfig={this.updateTLConfig}
                />
                <TableBody>
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length > 0 &&
                    tableConfig
                      .stableSort(
                        listRes.items,
                        tableConfig.getComparator(
                          tlConfig.order,
                          tlConfig.orderBy
                        )
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.SiteId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.SiteId)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.PartySiteId}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className={!isHided("SiteName") ? "hideCell" : ""}
                            >
                              <Link
                                to={
                                  "/setup/vendor/site/add/" +
                                  this.props.objectId +
                                  "/" +
                                  row.SiteId +
                                  "?filterName=" +
                                  this.props.filterName +
                                  "&page=" +
                                  this.props.page
                                }
                              >
                                {row.SiteName ? row.SiteName : "-"}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={!isHided("SiteCode") ? "hideCell" : ""}
                            >
                              {row.SiteCode ? row.SiteCode : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("PrimaryFlag") ? "hideCell" : ""
                              }
                            >
                              {row.PrimaryFlag ? row.PrimaryFlag : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("ServiceTo") ? "hideCell" : ""
                              }
                            >
                              {row.ServiceTo ? row.ServiceTo : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("BilltoFlag") ? "hideCell" : ""
                              }
                            >
                              {row.BilltoFlag ? row.BilltoFlag : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("ShiptoFlag") ? "hideCell" : ""
                              }
                            >
                              {row.ShiptoFlag ? row.ShiptoFlag : "-"}
                            </TableCell>
                            <TableCell
                              className={
                                !isHided("AddressId") ? "hideCell" : ""
                              }
                            >
                              {row.AddressId ? row.AddressId : "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {isLoading == true && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <Loader />{" "}
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading == false &&
                    listRes.items &&
                    listRes.items.length == 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NoRecordFound />{" "}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            {isLoading == false && listRes.totalResults > 0 && (
              <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                <div className="mr-auto">
                  {tableConfig.showEntries(currentPage, pageLimit, listRes)}
                </div>
                <div className="pagination-wrap">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={listRes.limit}
                    totalItemsCount={listRes.totalResults}
                    pageRangeDisplayed={7}
                    onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end of Switch View Control */}
      </div>
    );
  }
}

export default CustomerSiteList;
