import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
// includes
import { crm } from '_utils';
class QuickAdd extends React.Component {
    constructor(props) {
        super(props);
        this.params = this.props.params;
        this.state = {
            isLoading: false,
            formSubmit: false,
            enquiryId: 0,
            campaignId: 0,
            fields: {
                inputData: ''
            },
            inputMemberData: [],
            errors: {
                inputData: {
                    error: '',
                    isReq: "Please enter the data",
                }
            },
            moduleId: '',
            returnPage: ''
        };
    }

    // life cycle
    componentDidMount() {
        if (this.props.match.params.enquiryId !== undefined) {
            let moduleId = this.props.match.params.module;
            let enquiryId = this.props.match.params.enquiryId;
            let returnPage = '';
            if (moduleId === 'enquiry') {
                returnPage = "/crm/enquiry/edit/" + enquiryId + "?tab=details";
            }

            this.setState({
                enquiryId,
                moduleId,
                returnPage
            });
        }
        if (this.props.match.params.campaignId !== undefined) {
            let campaignId = this.props.match.params.campaignId;
            let moduleId = this.props.match.params.module;
            let enquiryId = this.props.match.params.enquiryId;
            let returnPage = '';

            let search = window.location.search;
            let params = new URLSearchParams(search);
            let filterName = params.get("filterName");
            let page = params.get("page");
            let filterListName = params.get("filterListName");
            let listPage = params.get("listPage");

            if (moduleId === 'campaign') {
                returnPage = "/crm/campaign/edit/" + campaignId + "?tab=marketinglist";
            }
            if (filterListName || page || listPage || filterName) {
                returnPage += "&filterListName=" +
                    filterListName +
                    "&listPage=" +
                    listPage + "&filterName=" +
                    filterName +
                    "&page=" +
                    page + "&listId=" + enquiryId
            }
            this.setState({
                campaignId,
                moduleId,
                returnPage
            });
        }

    }

    // event
    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value } = event.target;

        if (fields.hasOwnProperty(name)) {
            fields[name] = value;
        }

        this.setState({
            fields: fields
        });
    };

    /**
     * Handle submit event
     * @param {*} event 
     */
    save = (event) => {
        event.preventDefault();
        const { fields } = this.state;

        let isValid = true;

        let memberArray = fields.inputData.split(/\n/);
        memberArray.map((member, index) => {
            //split member fields by comma
            let memberData = member.split(",");
            if (memberData.length == 17) {
                this.formMermberData(memberData, index);
            } else {
                isValid = false;
                //show error message
                common.snack("E", "Invalid data format. Please enter data in correct format")
            }
        });
        if (isValid) {
            this.saveMember();
        }
    };

    formMermberData = (member, index) => {
        const { inputMemberData } = this.state;
        //CompanyName,Salutation,FirstName,LastName,Description,Phone, 
        //Mobile,Email,Address 1,Address 2,Country,State,City,
        //PostalCode,Extn,Fax,Website
        var formAttr = {};
        formAttr["CompanyName"] = member[0] !== "" ? member[0] : null;
        formAttr["Salutation"] = member[1] ? member[1] : null;
        formAttr["FirstName"] = member[2] ? member[2] : null;
        formAttr["LastName"] = member[3] ? member[3] : null;
        formAttr["Description"] = member[4] ? member[4] : null;
        formAttr["Phone"] = member[5] ? member[5] : null;
        formAttr["Mobile"] = member[6] ? member[6] : null;
        formAttr["Email"] = member[7] ? member[7] : null;
        formAttr["Address1"] = member[8] ? member[8] : null;
        formAttr["Address2"] = member[9] ? member[9] : null;

        formAttr["Country"] = member[10] ? member[10] : null;
        formAttr["State"] = member[11] ? member[11] : null;
        formAttr["City"] = member[12] ? member[12] : null;
        formAttr["PostalCode"] = member[13] ? member[13] : null;

        formAttr["Extn"] = member[14] ? member[14] : null;
        formAttr["Fax"] = member[15] ? member[15] : null;
        formAttr["Website"] = member[16] ? member[16] : null;

        formAttr["ListId"] = this.state.enquiryId;
        formAttr["TenantId"] = crm.userInfo().pTenantId;
        formAttr["OrgId"] = crm.userInfo().pOrgId;
        formAttr["Active"] = "Y";
        inputMemberData.push({
            "id": "create_member_" + index + 1,
            "path": "/CholaCrmListDtls",
            "operation": "create",
            "payload": formAttr
        });
        this.setState({ inputMemberData: inputMemberData });
    }

    /**
   * Save Members Data to DB based on the list
   */
    saveMember = () => {
        const { inputMemberData, returnPage } = this.state;
        var formAttr = { "parts": inputMemberData };
        let url = encodeURI(envConfig.BASE_API);

        restServices.batchRequest(
            url,
            formAttr,
            (response) => {
                if (response != null) {
                    history.push(returnPage);

                    common.snack("S", "Saved members successfully.")
                }
            },
            (error) => {
                common.snack("E", error);
            }
        )
    };

    onCancel = () => {
        // const { returnPage } = this.state;
        // history.push(returnPage);
        history.goBack();
    }

    render() {
        const {
            fields,
            errors,
            formSubmit,
        } = this.state;

        return (
            <div className="lead-wraper">
                <Form onSubmit={this.save}>
                    <div className="formWhiteBg">
                        <div className="formHead">
                            <h2>Quick Add</h2>
                        </div>
                        <Row>
                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Quick Add Information*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="5"
                                        name="inputData"
                                        value={fields.inputData}
                                        onChange={(e) => this.handleOnChange(e)}
                                        placeholder="Ex. CompanyName,Salutation,FirstName,LastName,Description,Phone, Mobile,Email,Address1,Address2,Country,State,City,PostalCode,Extn,Fax,Website"
                                    >
                                    </Form.Control>
                                    <Form.Text className="error">* Add comma if no data in field</Form.Text>
                                    {errors.inputData.error !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.inputData.error}</Form.Text>
                                    )}
                                    <div>Ex. CompanyName,Salutation,FirstName,LastName,Description,Phone, Mobile,Email,Address1,Address2,Country,State,City,PostalCode,Extn,Fax,Website</div>
                                </Form.Group>
                            </Col>
                        </Row>

                    </div>

                    <Row className="botBtn">
                        <Col sm={12}>
                            <Button className="saveBtn" type="submit">
                                Save
                            </Button>
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default QuickAdd;
