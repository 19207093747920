import React from "react";
import Paper from "material-ui/Paper";
import Checkbox from "material-ui/Checkbox";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";

// Import redux packages
import { connect } from "react-redux";
import { commonActions } from "_actions/common.actions";

// Import config and helpers
import { restServices, common } from "_helpers";
import * as Services from "_config/api";
import { envConfig } from "_config/config";

//Import CSS
import "../css/Enquiry.css";

// Import internal components
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";

class EnquiryDetails extends React.Component {
  constructor(props) {
    super(props);
    /**t
     * layoutFormat => Get form layout form API resources
     * jsonAttributes => Have stored all input type json properties
     * => USAGE: Reduce looping system have created ( jsonAttributes ) object
     * fieldConfig => Have stored all input key configuration
     */
    this.state = {
      enquiryID: 0,
      enquiryData: {},
      crmEnquiryList: [],
      layoutFormat: {},
      jsonAttributes: {},
      returnQuery: "",
      fieldConfig: {
        CampaignId: {
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        ListId: {
          getOne: envConfig.BASE_API + Services.CRM_LISTS + "/LIST_NAME",
          get: "ListId",
          set: "ListName",
          searchKey: "LIST_NAME",
        },
      },
      userPermissions: common.getPermission(this.props, "crm:enquiry"),
    };
    this.state.returnQuery = common.getCrmReturnQuery();
  }

  /**
   * Hooks Methods
   */
  componentDidMount() {
    this.setState(
      {
        enquiryID: this.props.match.params.enquiryId,
      },
      (_) => {
        this.getFormJSON();
      }
    );
    this.getCRMEnquiryStatusList();
  }

  // MAR: API Methods
  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_LIST_CODE;
    this.setState({
      isLoading: true,
    });
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          this.setState(
            {
              layoutFormat: JSON.parse(result.FormFields),
              isLoading: false,
            },
            () => {
              this.getEnquiryDetail(this.state.enquiryID);
              this.createDynamicFormFields();
            }
          );
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  /**
   * Get each dynamic field API details and bind value to json attributes
   */
  getDetail = (url, name) => {
    const { fieldConfig, jsonAttributes } = this.state;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          let config = fieldConfig[name];
          if (jsonAttributes[name]) {
            jsonAttributes[name].value = response[config.set];
          }
          this.setState({
            jsonAttributes: jsonAttributes,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Get Enquiry Details by ID
   */
  getEnquiryDetail = (id) => {
    const { jsonAttributes, fieldConfig } = this.state;
    let url = envConfig.BASE_API + Services.CRM_LISTS + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response) {
            for (var key of Object.keys(response)) {
              if (response[key]) {
                if (jsonAttributes[key] !== undefined) {
                  jsonAttributes[key].value = response[key];
                }
                if (fieldConfig[key]) {
                  if (fieldConfig[key].hasOwnProperty("getOne")) {
                    fieldConfig[key].getOne = fieldConfig[key].getOne.replace(
                      fieldConfig[key].searchKey,
                      response[key]
                    );
                    this.getDetail(fieldConfig[key].getOne, key);
                  }
                }
              }
            }
            this.setState({
              enquiryData: response,
              jsonAttributes: jsonAttributes,
              fieldConfig: fieldConfig,
            });
          }
        }
      },
      (error) => {
        this.setState({
          enquiryData: {},
        });
      }
    );
  };

  /**
   * Assign default value based on input type
   * @param {*} type
   */
  getDefaultValueByType = (type) => {
    switch (type) {
      case "integer":
        return 0;
      case "string":
        return "";
      default:
        return null;
    }
  };

  /**
   * Create dynamic form through given json attributes
   */
  createDynamicFormFields = () => {
    const { layoutFormat, jsonAttributes } = this.state;

    if (
      layoutFormat &&
      layoutFormat.columns &&
      layoutFormat.columns.length > 0
    ) {
      layoutFormat.columns.forEach((column) => {
        column.groups.forEach((column) => {
          column.rows.forEach((row) => {
            row.columns.forEach((rowColum) => {
              jsonAttributes[rowColum.key] = rowColum;
            });
          });
        });
      });

      this.setState({
        jsonAttributes: jsonAttributes,
      });
    }
  };

  /**
   * Get CRM list from API
   */
  getCRMEnquiryStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let listTypeId = common.getTransactionTypeId(envConfig.LIST_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMEnquiryStatus(listTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmEnquiryStatus } = props;

    var crmStatusData = state.crmEnquiryList;

    if (
      crmEnquiryStatus &&
      crmEnquiryStatus.list &&
      crmEnquiryStatus.list.success
    ) {
      crmStatusData = crmEnquiryStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmEnquiryList) {
      return {
        crmEnquiryList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  /**
   * Load enquiry status position based on filtered index
   */
  getStatusPosition = () => {
    const { enquiryData, crmEnquiryList } = this.state;
    if (
      enquiryData.EnquiryStatus &&
      enquiryData.EnquiryStatus != "" &&
      enquiryData.EnquiryStatus != null
    ) {
      var position = crmEnquiryList.findIndex(
        (x) => x.StatusCode == enquiryData.EnquiryStatus
      );
      return position >= 0 ? position : -1;
    }
  };

  /**
   * Load block of code or component based on the input key
   */
  renderBlock = (attr) => {
    let userData = common.authInfo();
    let role = userData.roleId || 0;
    let access = "";
    let permissions = attr.permission ? attr.permission : [];

    permissions.map((obj) => {
      if (obj.role == role) {
        access = obj.access;
      }
    });
    if (access != "DontShow") {
      switch (attr.input) {
        case "check_box":
          if (attr.showPage != undefined && attr.showPage == "campaign") {
            return null;
          } else {
            return (
              <Checkbox
                disabled
                checked={attr.value == "Y" ? true : false}
                label={attr.name}
                labelStyle={{ color: "#A7AFC2" }}
                labelPosition="left"
              />
            );
          }

        default:
          return (
            <div>
              <label className="form-label">{attr.name}</label>
              <p className="form-value">{attr.value ? attr.value : "-"}</p>
            </div>
          );
      }
    }
  };

  render() {
    const {
      isLoading,
      enquiryID,
      crmEnquiryList,
      layoutFormat,
      userPermissions,
    } = this.state;

    return (
      <div>
        {!userPermissions.read && <AccessDenied />}
        {userPermissions.read && isLoading == false && (
          <div className="lead-wraper">
            <div className="formTab">
              <ul>
                {crmEnquiryList.map((data, idx) => (
                  // <li className={`tabShape whiteTab ${this.getStatusPosition() >= idx ? "active" : ""}`}>
                  <li key={idx} className={`tabShape whiteTab active`}>
                    <span className="tri-right"></span>
                    <Badge badgeContent={++idx} color="primary">
                      {data.StatusName}
                    </Badge>
                  </li>
                ))}
              </ul>
            </div>

            <div className="row">
              {layoutFormat.columns &&
                layoutFormat.columns.length > 0 &&
                layoutFormat.columns.map((pcoloum, pindex) => (
                  <div className="col" key={pindex}>
                    {pcoloum.groups.map((group, gindex) => (
                      <Paper style={styles.boxLayout} key={gindex}>
                        <div className="box-title-wrap justify-content-between">
                          <h3 className="card-title">{group.title}</h3>
                          {userPermissions.update && (
                            <Link
                              className="card-link"
                              to={
                                "/crm/enquiry/edit/" +
                                enquiryID +
                                this.state.returnQuery
                              }
                            >
                              Edit
                            </Link>
                          )}
                        </div>
                        {group.rows.map((row, rindex) => (
                          <div
                            className={
                              row.noOfColumns == 1 ? "list-row" : "row"
                            }
                            key={rindex}
                          >
                            {row.columns.map(
                              (rcoloum, rcindex) =>
                                (rcoloum.showPage == undefined ||
                                  (rcoloum.showPage != undefined &&
                                    rcoloum.showPage != "campaign")) &&
                                common.checkFieldPermission(rcoloum) && (
                                  <div
                                    className={
                                      1 == row.noOfColumns
                                        ? ``
                                        : `col-${12 / row.noOfColumns}`
                                    }
                                    key={rcindex}
                                  >
                                    <div
                                      className={
                                        1 == row.noOfColumns ? `` : `list-row`
                                      }
                                    >
                                      {this.renderBlock(rcoloum)}
                                    </div>
                                  </div>
                                )
                            )}
                          </div>
                        ))}
                      </Paper>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        )}
        {userPermissions.read && isLoading == true && <Loader />}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

const mapStateToProps = (state) => {
  const { crmEnquiryStatus } = state;
  return { crmEnquiryStatus };
};
export default connect(mapStateToProps)(EnquiryDetails);
