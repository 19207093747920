import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { crm } from "_utils";
import { common } from "_helpers";

function DemoInfo(props) {

    return (
        <Fragment>
            <p>User Info</p>
            <pre>
                {JSON.stringify(crm.userInfo(), null, 4)}
            </pre>
            <p>Auth Info</p>
            <pre>
                {JSON.stringify(common.authInfo(), null, 4)}
            </pre>
        </Fragment>
    )
}

export default DemoInfo;