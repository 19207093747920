import React from "react";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";

import { history } from "_helpers";

class ConfigureMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  render() {
    return (
      <div className="secondary-nav">
        <ul className="secondary-nav-list">
          <li
            className={
              matchPath(history.location.pathname, {
                path: "/setup/configure/form-transaction",
              })
                ? "active"
                : ""
            }
          >
            <Link to={"/setup/configure/form-transaction"}>
              Transaction Status
            </Link>
          </li>
          <li
            className={
              matchPath(history.location.pathname, {
                path: "/setup/configure/form-transition",
              })
                ? "active"
                : ""
            }
          >
            <Link to={"/setup/configure/form-transition"}>
              Status Transition
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
const styles = {
  menuSelectbox: {
    borderBottom: "none",
  },
};

export default ConfigureMenu;
