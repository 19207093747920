import React, { useState, Fragment } from 'react';
import FieldSelect from "crm/FieldSelect";

function DemoSelect(props) {
  const [value, setValue] = useState(null);
  return (
    <Fragment>
      <FieldSelect
        api="CRM_COUNTRIES"
        get="CountryName"
        set="CountryId"
        value={value}
        isMulti={false}
        isRender={true}
        onSelect={(data) => setValue(data)}
      />
    </Fragment>
  )
}

export default DemoSelect;