import React from "react";
// confirm
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history, tools } from "_helpers";
import OsmoMaterialTable from "crm/OsmoMaterialTable";

const userInfo = common.authInfo();
class EnquiryMemberList extends React.Component {
  constructor() {
    super();
    this.state = {
      enquiryId: 0,
      reload: new Date(),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount() {
    if (this.props.enquiryId != undefined) {
      this.setState({
        enquiryId: this.props.enquiryId,
      });
    }
  }

  convertLead = (selected) => {
    const { enquiryId } = this.state;
    let userData = tools.deCrypt(localStorage.getItem("userData"));
    var formAttr = {
      name: "convertListDtlToLead",
      parameters: [
        { P_LIST_DTL_ID: selected.join(",") },
        { P_LIST_ID: parseInt(enquiryId) },
        { P_LEAD_NAME: "", },
        { P_LEAD_TYPE: "", },
        { P_CUSTOMER_ID: "", },
        { P_TERRITORY_ID: userData.territory[0] },
        { P_CAMPAIGN_ID: this.props.enquiryData.CampaignId, },
        { P_SALES_PERSON_ID: userInfo.EmpId },
      ],
    };

    let url = encodeURI(envConfig.BASE_API + Services.CHOLA_CUSTOM_CONVERSION);

    restServices.postRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.setState({
            reload: new Date(),
          });
          common.snack(
            "S",
            "Selected Members are converted as lead successfully."
          );
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  onAction = (data) => {
    const { enquiryId } = this.state;
    if (data.type == "convert") {
      //Verify member is converted or not
      let cRecords = Object.keys(data.selectedData).filter((obj) => {
        return data.selectedData[obj].LeadConverted == "Y";
      });
      let ncRecords = Object.keys(data.selectedData).filter((obj) => {
        return data.selectedData[obj].LeadConverted == "N" || data.selectedData[obj].LeadConverted == null;
      });

      if (cRecords.length == 0) {
        this.convertLead(data.selected);
      } else {
        let cMembers = "";
        cRecords.map((obj) => {
          cMembers = cMembers + " " + data.selectedData[obj].FirstName + ",";
        });
        let ncMembers = "";
        ncRecords.map((obj) => {
          ncMembers = ncMembers + " " + data.selectedData[obj].FirstName + ",";
        });
        if(ncRecords.length > 0){
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui">
                  <h1>Conversion Confirmation</h1>
                  <p>The following Members are already converted as Lead</p>
                  <p>{cMembers}</p>
                  <p>
                    Are you sure you want to convert the remaining selected Members?
                  </p>
                  <p>{ncMembers}</p>
                  <button className="noBtn" onClick={onClose}>
                    No
                  </button>
                  <button
                    onClick={() => {
                      this.convertLead(ncMembers);
                      onClose();
                    }}
                    className="yesBtn"
                  >
                    Yes
                  </button>
                </div>
              );
            },
          });
        }else{
          common.snack("E", "Already selected Members are converted as Lead")
        }
      }
    } else if (data.type == "quick") {
      history.push("/enquiry/member/quickadd/" + enquiryId + "/");
    } else if (data.type == "edit" || data.type == "view") {
      history.push("/crm/enquiry/addmember/" + enquiryId + "/" + data.id);
    } else if (data.type == "add") {
      history.push("/crm/enquiry/addmember/" + enquiryId);
    } else if (data.type == "import") {
      history.push("/crm/bulk/import/member/" + enquiryId);
    }
  };

  render() {
    const { enquiryId, reload } = this.state;
    return (
      <div className="lead-wraper">
        <div className="crm-data-wrap">
          <div className="clearfix"></div>
          {enquiryId > 0 && (
            <OsmoMaterialTable
              module="enquiryMember"
              query={{ ListId: enquiryId }}
              reload={reload}
              add="Add New"
              convert="Convert To Lead"
              quick="Add Quick"
              update="Mass Update"
              import="Mass Import"
              sort="CreatedOn:desc"
              data="LeadConverted,FirstName, LastName, CompanyName"
              onAction={(data) => this.onAction(data)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EnquiryMemberList;
