
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// Import Internal Component
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";

import activeIcon from "img/active.svg";
import deactiveIcon from "img/deactive.svg";
import editIcon from "img/edit.svg";

// Import config and helpers
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices } from "_helpers";
// includes
import { crm } from '_utils';
const classes = makeStyles({
    table: {
        minWidth: 650,
    },
});
let idList = [];
class EmployeeRoleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEmp: {},
            selectedUserId: 0,
            userRoleList: {},
            isLoaded: false,
            delSelectedItem: 0,
            userPermissions: this.props.userPermissions,
        }
    }

    /**
     * Component Hooks
     */
    componentDidUpdate(prevProps) {

        if (this.props.selectedEmp.EmployeeId != prevProps.selectedEmp.EmployeeId) {

            this.setState({
                selectedEmp: this.props.selectedEmp,
                isLoaded: false
            }, () => {
                this.getUserByEmailIdAndUpdate();
            });
            if (this.props.isQuery) {
                this.props.updateQuery();
            }
        }
    }

    getUserByEmailIdAndUpdate = () => {
        const { selectedEmp } = this.state;
        let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(UserName) LIKE '*${this.props.selectedEmp.Email}%*`;
        let url = encodeURI(envConfig.BASE_API + Services.CRM_USERS + filter);

        restServices.getRequest(
            url,
            (response) => {
                if (response && response.items && response.items.length > 0) {

                    let userId = response.items[0].UserId;

                    this.setState({
                        selectedUserId: userId,
                    }, () => {
                        this.getUserRole(userId);
                    })
                }
            },
            (error) => { }
        );
    }
    /**
    * Get User Role by userid
    */
    getUserRole = (id) => {

        let filter = `?q=UserId=${id};&finder=OnlyParentGroups`;
        let url = encodeURI(envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + filter);

        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    if (response && response.items) {
                        var userRoleList = response.items.filter(data => {
                            return (data.GroupDesc != Services.CRM_DEFAULT_GROUPS_1
                                && data.GroupDesc != Services.CRM_DEFAULT_GROUPS_2
                                && data.GroupDesc != Services.CRM_DEFAULT_GROUPS_3
                                && data.GroupDesc != Services.CRM_DEFAULT_GROUPS_4)
                        });
                        if (userRoleList.length > 0) {
                            this.setState({
                                userRoleList: userRoleList,
                                isLoaded: true
                            })
                        } else {
                            this.setState({
                                userRoleList: [],
                                isLoaded: true
                            })
                        }
                    } else {
                        this.setState({
                            userRoleList: [],
                            isLoaded: true
                        })
                    }
                }
            },
            (error) => {

            });
    }

    /**
     * Delete User group
     */
    deleteItem = (role) => {
        this.setState({ delSelectedItem: role });
        common.confirmDelete(true, (response) => {
            this.handleDeleteBlock();
        });
    }

    /**
   * Get details by id & set data in fields
   */
    getUserAllPermissions = async (groupId) => {
        const { selectedUserId } = this.state;
        return new Promise((resolve, reject) => {
            this.getUserRolePermission(groupId, selectedUserId, resolve, reject);
            resolve();
        });
    };

    getUserRolePermission(groupId, userId, resolve, reject) {
        return new Promise((resolve, reject) => {
            let filter = `?q=GroupParentId=${groupId};UserId=${userId}&limit=10&offset=0`;
            let url = encodeURI(
                envConfig.AUTH_REST_URL + Services.USER_ROLE_GROUPS + filter
            );
            restServices.getRequest(url, async (response) => {
                let items = [];
                if (response && response.items && response.items.length) {
                    items = response.items;
                }
                Promise.all(
                    items.map((item) => {
                        idList.push(item.GroupId);
                        this.getUserRolePermission(item.GroupId, userId, resolve, reject);
                    })
                ).then((result) => resolve(
                ));
            });
        });
    }

    /**
     * Delete roles by id
     */
    handleDeleteBlock = async () => {
        const { delSelectedItem } = this.state;
        await this.getUserAllPermissions(delSelectedItem.GroupId);
    }

    render() {
        const { selectedUserId, isLoaded, userRoleList, userPermissions } = this.state;

        return (
            <div>
                {this.props.selectedEmp.EmployeeId != undefined && (
                    <div className="list-view-wrap bottom-set">
                        <div className="col-12">
                            <div className="form-row">
                                <div className="col-5">
                                </div>
                                <div className="col-7">
                                    <div className="form-row justify-content-end">
                                        {/* {isLoaded && userRoleList && userRoleList.length === 0 && (
                                            <Link to={isLoaded ? "/setup/hrms/employee/roles/" + selectedUserId : "#"}
                                                className="btn btn-add">
                                                Add Role
                                            </Link>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <TableContainer component={Paper} style={{ marginTop: 28 }}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow key="role_heading">
                                        <TableCell key="h_1"> Role Name </TableCell>
                                        <TableCell key="h_2"> Active </TableCell>
                                        {userPermissions.update && (
                                            <TableCell key="h_3"> Action </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody key="tb_body">
                                    {isLoaded && userRoleList && userRoleList.length > 0 &&
                                        userRoleList.map((role, index) => ((
                                            <TableRow key={role.UserGroupId}>
                                                <TableCell>{role.GroupDesc}</TableCell>
                                                <TableCell>
                                                    <img src={role.GroupActive === "Y" ? activeIcon : deactiveIcon} alt="status" />
                                                    <span className="role-status">{role.GroupActive === "Y" ? "Active" : "Deactive"}</span>
                                                </TableCell>
                                                {userPermissions.update && (
                                                    <TableCell>
                                                        <Link
                                                            title="Edit Permissions"
                                                            to={
                                                                isLoaded
                                                                    ? "/setup/hrms/employee/roles/" +
                                                                    role.UserId +
                                                                    "/" +
                                                                    role.UserGroupId +
                                                                    "?empId=" +
                                                                    this.props.empId +
                                                                    "&empCode=" +
                                                                    this.props.empCode +
                                                                    "&email=" +
                                                                    this.props.email +
                                                                    "&return=" +
                                                                    this.props.return +
                                                                    "&filterName=" +
                                                                    this.props.filterName +
                                                                    "&page=" +
                                                                    this.props.page
                                                                    : "#"
                                                            }
                                                            style={{ padding: "0 5px" }}
                                                        >
                                                            <img src={editIcon} alt="Edit" />
                                                        </Link>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        )))}
                                    {isLoaded && userRoleList.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <NoRecordFound />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {(!isLoaded && (
                                        <TableRow>
                                            <TableCell colSpan={12}>
                                                <Loader />
                                            </TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
                {this.props.selectedEmp.EmployeeId == undefined && (
                    <NoRecordFound />
                )}
            </div>


        )
    }
}

export default EmployeeRoleList;