import React from "react";
import {
	Form,
	Col,
	InputGroup,
	Button,
	Row,
	Container,
	ListGroup,
} from "react-bootstrap";

//Import CSS
import "./Login.css";
import LandingHeader from "../../_components/_landingHeader/landingHeader";

// //Import Images
import HiddenEye from "img/password_hide.png";
import ShowEye from "img/password_visible.png";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tools } from "_helpers";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formSubmit: false,
			hidden: true,
			email: "",
			password: "",
			errors: {
				email: "Please enter email address",
				password: "Please enter password",
			},
			errMessage: "",
			isLoading: false,
		};
	}

	handleOnChange = (event) => {
		const { errors } = this.state;
		const { name, value } = event.target;
		let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		this.setState(
			{
				[name]: value,
			},
			(_) => {
				if (value !== "") {
					if (name === "email") {
						if (!emailPattern.test(value)) {
							errors[name] = "Please enter valid email address";
							this.setState({
								errors,
							});
						} else {
							errors[name] = "";
							this.setState({
								errors,
							});
						}
					} else {
						errors[name] = "";
						this.setState({
							errors,
						});
					}
				} else if (value === "" && name === "email") {
					errors[name] = "Please enter email address";
					this.setState({
						errors,
					});
				}
			}
		);
	};

	handleOnSubmit = (event) => {
		event.preventDefault();
		const { errors } = this.state;
		let isValid = true;
		this.setState(
			{
				formSubmit: true,
			},
			(_) => {
				Object.keys(errors).forEach(function (key) {
					if (errors[key] !== "" && isValid === true) {
						isValid = false;
					}
				});
				if (isValid === true) {
					this.handleSignInForm();
				}
			}
		);
	};

	handleSignInForm = () => {
		this.setState({ isLoading: true });
		const { email, password } = this.state;
		let url = envConfig.AUTH_API + Services.SIGN_IN;
		let authString = `${email}:${password}`;

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
		myHeaders.append("Authorization", "Basic " + btoa(authString));

		let requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({ ExpandUserInfo: true }),
		};

		fetch(url, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log('result :>> ', result);
				if (result.auth === true) {
					result["EmployeeName"] = result.FirstName + " " + result.LastName;

					localStorage.setItem("userToken", result.token);

					let usrObj = {
						DefaultOrgId: result.DefaultOrgId,
						DefaultTimezone: result.DefaultTimezone,
						EmpId: result.EmpId,
						EmployeeCode: result.EmployeeCode,
						EmployeeName: result.FirstName + " " + result.LastName,
						FirstName: result.FirstName,
						Gender: result.Gender,
						GroupId: result.GroupId,
						LastName: result.LastName,
						TenantId: result.TenantId,
						UserId: result.UserId,
						UserName: result.UserName,
						WorkLocId: result.WorkLocId,
						basicToken: btoa(authString),
						password: password,
						roleId: 0,
						role: "",
						territory: [],
						AlternativeEmailAddress1: result.AlternativeEmailAddress1 | null,
						AlternativeEmailAddress2: result.AlternativeEmailAddress2 | null,
					};
					localStorage.setItem("userData", tools.crypt(usrObj));

					setTimeout(() => {
						window.location.href = common.url("/work");
					}, 1000);
				} else {
					this.setState({ isLoading: false });
				}
			})
			.catch((error) => {
				this.setState({
					errMessage: "Invalid username or password!..",
					isLoading: false,
				});
			});
	};

	toggleShow = () => {
		this.setState({ hidden: !this.state.hidden });
	};

	render() {
		const {
			email,
			password,
			errors,
			formSubmit,
			errMessage,
			isLoading,
			hidden,
		} = this.state;
		return (
			<div className="content-wraper">
				<div className="signup-top-wrap">
					<LandingHeader />
					<div className="login-wrap">
						<div className="login-container">
							<Row>
								<Col className="left-side-container col-12 col-sm-12 col-md-12 col-lg-6">
									<Container className="left-container">
										<p className="wecome-text">Welcome Back</p>
										<ListGroup as="ul" className="welcome-list">
											<ListGroup.Item as="li" className="welcome-list-content">
												Make your business run smoothly
											</ListGroup.Item>
											<ListGroup.Item as="li" className="welcome-list-content">
												Intelligent customer management
											</ListGroup.Item>
											<ListGroup.Item as="li" className="welcome-list-content">
												Easy to send advertising campaigns
											</ListGroup.Item>
										</ListGroup>
									</Container>
								</Col>
								<Col className="login-form-wrap right-side-container col-12 col-sm-12 col-md-12 col-lg-6">
									<Container className="right-container">
										<p className="signIn-text">Sign In</p>
										<Form
											className="signIn-form"
											onSubmit={this.handleOnSubmit}
										>
											<Form.Group controlId="formBasicEmail">
												<Form.Control
													type="text"
													className="email"
													placeholder="Email Address"
													name="email"
													value={email}
													onChange={(e) => this.handleOnChange(e)}
												/>
												{errors.email !== "" && formSubmit === true && (
													<Form.Text className="error">
														{errors.email}
													</Form.Text>
												)}
											</Form.Group>

											<Form.Group controlId="formBasicPassword">
												<InputGroup>
													<Form.Control
														type={this.state.hidden ? "password" : "text"}
														className="password"
														placeholder="Password"
														name="password"
														value={password}
														onChange={(e) => this.handleOnChange(e)}
													/>
													<InputGroup.Append>
														<img
															className="hidden-icon"
															src={hidden ? HiddenEye : ShowEye}
															alt="eye"
															onClick={this.toggleShow}
														/>
													</InputGroup.Append>
												</InputGroup>
												{errors.password !== "" && formSubmit === true && (
													<Form.Text className="error">
														{errors.password}
													</Form.Text>
												)}
											</Form.Group>
											<p style={{ color: "red" }}>{errMessage}</p>

											<Button
												variant="success"
												type="submit"
												className="signIn-button"
												disabled={isLoading}
											>
												{!isLoading ? "Get Start" : "Processing..."}
											</Button>
										</Form>
									</Container>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Login;
