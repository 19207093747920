import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import Select from 'react-select';

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { restServices, history } from "_helpers";

import '../css/LeaveOpening.css';

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";

class LeaveOpeningAdd extends React.Component {
    constructor(props) {
        super(props);
        this.params = this.props.params;
        this.state = {
            isLoading: false,
            formSubmit: false,
            autoloading: false,
            fields: {
                Year: "",
                StartDate: new Date(),
                EndDate: null,
                Active: "Y",
            },
            errors: {
                Year: {
                    error: "",
                    isReq: "Year is required!",
                },
                StartDate: {
                    error: "",
                    isReq: "Start Date is required!",
                },
                EndDate: {
                    error: "",
                    isReq: "End Date is required!",
                }
            },
            masterData: {
            },
            commonResource: {
                CustomerId: 0,
            },
            leaveYear: [{value: "", label: "Select"}]
        };
    }

    // life cycle
    componentDidMount() {
    }

    // functions

    selectHandleOnChange = (event, type) => {
        const { label, value } = event;
        const { fields } = this.state;
        if (fields.hasOwnProperty(type)) {
          fields[type] = value;
        }
    
        this.setState({
            fields: fields,
        }, () => {
            this.validateForm(type, value);
        });
    }

    // validation
    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (errors.hasOwnProperty(name)) {
                errors[name].error = errors[name].isReq;
            }
        } else {
            switch (name) {
                default:
                    errors[name].error = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    validateAll() {
        const { fields, errors } = this.state;
        let isValid = true;
        for (var key of Object.keys(errors)) {
            if (fields[key] == "" || fields[key] == null) {
                errors[key].error = errors[key].isReq;
                isValid = false;
            }
        }
        this.setState({
            errors
        });

        return isValid;
    }

    // event
    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(name, inputValue);
            }
        );
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    // rest services
    onSubmit = (event) => {
        event.preventDefault();
        const { commonResource } = this.state;

        this.setState({
            formSubmit: true,
        });

        let isValid = this.validateAll();

        if (isValid) {
            if (commonResource.CustomerId > 0) {
                // this.update();
            } else {
                // this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.CRM_CUSTOMER + "/" + commonResource.CustomerId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/hrms/leaveopening");
                }
            },
            (error) => {}
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_CUSTOMER);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/hrms/leaveopening");
                }
            },
            (error) => {}
        );
    };

    // support
    yearList() {
        let yearList = [];
        for (let i = 1988; i <= new Date().getFullYear(); i++) {
            yearList.push(i);
        }
        return yearList;
    };

    onCancel = () => {
        history.push("/hrms/leaveopening");
    }

    render() {
        const {
            fields,
            errors,
            formSubmit,
            autoloading,
            masterData,
            commonResource,
            leaveYear
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            <div className="lead-wraper">
                <h2>Add Leave Opening</h2>
                <Form onSubmit={this.save}>
                    <div>
                        {/* Party Information */}
                        <Row className="whiteBgRow" style={{ marginTop: 28 }}>
                            <Col sm={12} className="formWhiteBg">
                                <div className="formHead">
                                    <h2>Leave Opening Information</h2>
                                </div>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Employee*</Form.Label>
                                            {/* <AutoComplete
                                                freeSolo
                                                id="CustomerId"
                                                disableClearable
                                                //options={masterData.customerData}
                                                loading={autoloading}
                                                //getOptionLabel={(option) => option.CustomerName}
                                                //onChange={this.handleAutoChange}
                                                //inputValue={autoFields.CustomerIdSearchTerm}
                                                //onInputChange={this.handleInputChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={'Search Employee Name'}
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autoloading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            /> */}
                                            <Form.Control
                                                type="text"
                                                placeholder="Search Employee Name"
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Designation*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                disabled
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Earn Leaves*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Casual Leaves*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyType !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyType}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Sick Leaves*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder=""
                                                name="Description"
                                                onChange={(e) => this.handleOnChange(e)}
                                                value={fields.Description}
                                            />
                                            {errors.PartyCode !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyCode}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Leave Year*</Form.Label>
                                            <Select
                                                value={leaveYear.find(o => o.value === fields.PartyType)}
                                                onChange={(e) => this.selectHandleOnChange(e, 'PartyType')}
                                                options={leaveYear}
                                            />
                                            {errors.PartyName !== "" && formSubmit === true && (
                                                <Form.Text className="error">{errors.PartyName}</Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>Start Date*</Form.Label>
                                            <DatePicker
                                                name="StartDate"
                                                value={fields.StartDate}
                                                onChange={(date) =>
                                                    this.handleDateOnChange(date, 'StartDate')
                                                }
                                                format="dd/MM/yyyy"
                                                autoComplete="off"
                                                minDate={new Date()}
                                            />
                                            {errors.StartDate !== '' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.StartDate}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>End Date*</Form.Label>
                                            <DatePicker
                                                name="EndDate"
                                                value={fields.EndDate}
                                                onChange={(date) =>
                                                    this.handleDateOnChange(date, 'EndDate')
                                                }
                                                format="dd/MM/yyyy"
                                                autoComplete="off"
                                                minDate={new Date()}
                                            />
                                            {errors.EndDate !== '' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.EndDate}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Form.Group>
                                            <Form.Label>&nbsp;</Form.Label>
                                            <Checkbox
                                                name="Active"
                                                checked={isChecked(fields.Active)}
                                                label="Active"
                                                labelStyle={{ color: '#2d2d2d' }}
                                                labelPosition="left"
                                                onClick={(e) => this.handleOnChange(e)}
                                            />
                                            {errors.Active !== 'Y' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.Active}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* Action section */}
                        <Row className="botBtn">
                            <Col sm={12}>
                                <Button className="saveBtn" type="submit">
                                    {/* {commonResource.PartyId > 0 ? "Update" : "Save"} */}
                  Save
                </Button>
                                <Button className="cancelBtn" type="button" onClick={this.onCancel}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

export { LeaveOpeningAdd as LeaveOpeningAdd };
