import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";

//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";

//Datepicker css
import "react-datepicker/dist/react-datepicker.css";
// AutoComplete
import AutoComplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "material-ui/Checkbox";
// includes
import { crm } from '_utils';
// Defined Errors
const defErrs = {
    AddressId: {
        isReq: "Location is required!",
    },
};

class AddPartySite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            autoloading: false,
            formSubmit: false,
            fields: {
                TenantId: crm.userInfo().pTenantId,
                OrgId: crm.userInfo().pOrgId,
                PartyId: null,
                AddressId: 0,
                PrimaryFlag: "N",
                BillTo: "N",
                ShipTo: "N",
                ServiceTo: "N",
                SiteName: '',
                SiteCode: '',
            },
            address: {
                SiteId: 0,
                SiteName: '',
                Address1: '',
                Address2: '',
                City: '',
                State: '',
                Country: '',
                PostalCode: '',
            },
            commonResource: {
                PartySiteId: 0,
            },
            autoFields: {
                AddressIdSearchTerm: "",
            },
            masterData: {
                locationData: [],
            },
            errors: {},
        };
    }

    componentDidMount() {
        const { commonResource, fields } = this.state;

        this.defineInitErrorMsg();

        if (this.props.match.params.partyId != undefined) {
            fields.PartyId = this.props.match.params.partyId
                ? this.props.match.params.partyId
                : 0;
            this.setState({
                fields,
            });
        }
        if (this.props.match.params.partySiteId != undefined) {
            commonResource.PartySiteId = this.props.match.params.partySiteId
                ? this.props.match.params.partySiteId
                : 0;
            this.setState(
                {
                    commonResource: commonResource,
                },
                () => {
                    const { commonResource } = this.state;
                    if (commonResource.PartySiteId > 0) {
                        this.getDetailById(commonResource.PartySiteId);
                    }
                }
            );
        }
    }

    defineInitErrorMsg = () => {
        const { errors } = this.state;
        for (var key of Object.keys(defErrs)) {
            errors[key] = defErrs[key].isReq;
        }
        this.setState({
            errors: errors,
        });
    };

    /**
     * Redirect to territory list page
     */
    onCancel = () => {
        history.push("/setup/vendor/party/edit/" + this.state.fields.PartyId + "?tab=allsites");
    }

    /**
     * Handle to change input values
     */
    handleAutoChange = (event, selectedValue) => {
        const { id } = event.target;
        const { fields, address } = this.state;
        var splitId = id.split("-");

        fields[splitId[0]] = selectedValue.LocationId;
        fields.SiteName = selectedValue.LocationName;
        fields.SiteCode = selectedValue.LocationCode;

        address.SiteId = selectedValue.LocationId;
        address.SiteName = selectedValue.LocationCode;
        address.Address1 = selectedValue.AddressLine1;
        address.Address2 = selectedValue.AddressLine2;
        address.City = selectedValue.City;
        address.State = selectedValue.State;
        address.PostalCode = selectedValue.PostalCode;
        address.Country = selectedValue.CountryCode;
        this.setState(
            {
                fields: fields,
                address: address
            },
            () => {
                this.validateForm(splitId[0], selectedValue.LocationId);
            }
        );
    };

    handleOnChange = (event) => {
        const { fields } = this.state;
        const { name, value, type, checked } = event.target;
        var inputValue = value;

        if (type == "checkbox") {
            inputValue = checked ? "Y" : "N";
        }

        if (fields.hasOwnProperty(name)) {
            fields[name] = inputValue;
        }

        this.setState(
            {
                fields: fields,
            },
            () => {
                this.validateForm(name, inputValue);
            }
        );
    };

    validateForm = (name, value) => {
        const { errors } = this.state;
        if (!value) {
            if (defErrs.hasOwnProperty(name)) {
                errors[name] = defErrs[name].isReq;
            }
        } else {
            // Optional Validation Here
            switch (name) {
                default:
                    errors[name] = "";
            }
        }
        this.setState({
            errors: errors,
        });
    };

    handleDateOnChange = (date, name) => {
        const { fields } = this.state;
        if (fields.hasOwnProperty(name)) {
            fields[name] = date;
        }

        this.setState({
            fields: fields
        }, () => {
            this.validateForm(name, date);
        });
    }

    /**
   * Handle to get changed search terms and get list based on terms
   */
    handleInputChange = (event, terms = "") => {
        const { masterData, autoFields, fields } = this.state;
        masterData.locationData = [];

        const { id } = event.target;
        var splitId = id.split("-");

        autoFields[splitId[0] + "SearchTerm"] = terms;
        if (terms.length === 0) {
            fields[splitId[0]] = "";
            this.validateForm(splitId[0], fields[splitId[0]]);
        }
        this.setState({
            autoloading: true,
            masterData: masterData,
            autoFields: autoFields,
            fields
        });

        let filter = `?q=TenantId=${crm.userInfo().pTenantId} AND UPPER(LocationName) LIKE '*${terms}*' &limit=10&offset=0&totalResults=true`;
        let url = envConfig.BASE_API + Services.CRM_LOCATIONS + filter;

        restServices.getRequest(
            encodeURI(url),
            (response) => {
                if (response) {
                    masterData.locationData =
                        (response.items && response.items.length > 0) ? response.items : [];
                    this.setState({
                        autoloading: false,
                        masterData: masterData,
                    });
                }
            },
            (error) => { }
        );
    };
    getDetailById = (id) => {
        const { fields, errors } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_SITES + "/" + id);
        restServices.getRequest(
            url,
            (response) => {
                if (response) {
                    for (var key of Object.keys(response)) {
                        if (fields.hasOwnProperty(key)) {
                            if (response[key]) {
                                fields[key] = response[key];

                                // Remove Default Errors
                                if (errors.hasOwnProperty(key)) {
                                    errors[key] = "";
                                }
                            }
                        }
                    }

                    this.setState(
                        {
                            fields: fields,
                            errors: errors,
                        },
                        () => {
                            if (fields.AddressId != null) {
                                this.getLocationDetailsById();
                            }
                        }
                    );
                } else { }
            },
            (error) => { }
        );
    };

    /**
     * Get details by Id
     */
    getLocationDetailsById = (type) => {
        const { fields, autoFields, address } = this.state;

        let filter = `?q=TenantId=${crm.userInfo().pTenantId};` +
            `LocationId=${fields.AddressId}`;
        let url = envConfig.BASE_API + Services.CRM_LOCATIONS + filter;

        restServices.getRequest(
            url,
            (response) => {
                if (response) {

                    if (response && response.items.length > 0) {
                        autoFields['AddressIdSearchTerm'] = response.items[0].LocationCode;

                        address.SiteId = response.items[0].LocationId;
                        address.SiteName = response.items[0].LocationCode;
                        address.Address1 = response.items[0].AddressLine1;
                        address.Address2 = response.items[0].AddressLine2;
                        address.City = response.items[0].City;
                        address.State = response.items[0].State;
                        address.PostalCode = response.items[0].PostalCode;
                        address.Country = response.items[0].CountryCode;
                    }

                    this.setState({
                        address: address,
                        autoFields: autoFields,
                    });
                }
            },
            (error) => { }
        );
    };

    save = (event) => {
        event.preventDefault();

        const { errors, commonResource } = this.state;
        var isValid = true;

        this.setState({
            formSubmit: true,
        });

        for (var key of Object.keys(errors)) {
            if (!isValid) {
                break;
            }
            isValid = !errors[key];
        }

        if (isValid) {
            if (commonResource.PartySiteId > 0) {
                this.update();
            } else {
                this.create();
            }
        }
    };

    update = () => {
        const { fields, commonResource } = this.state;

        let url = encodeURI(
            envConfig.BASE_API + Services.CRM_PARTY_SITES + "/" + commonResource.PartySiteId
        );
        restServices.patchRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/party/edit/" + fields.PartyId + "?tab=allsites");
                }
            },
            (error) => { }
        );
    };

    create = () => {
        const { fields } = this.state;

        let url = encodeURI(envConfig.BASE_API + Services.CRM_PARTY_SITES);
        restServices.postRequest(
            url,
            fields,
            (response) => {
                if (response) {
                    history.push("/setup/vendor/party/edit/" + fields.PartyId + "?tab=allsites");
                }
            },
            (error) => { }
        );
    };

    render() {
        const {
            fields,
            masterData,
            loading,
            autoloading,
            errors,
            formSubmit,
            autoFields,
            commonResource,
            address
        } = this.state;
        const isChecked = (value) => value == "Y";
        return (
            <div className="lead-wraper">
                <Form onSubmit={this.save}>
                    <div className="formWhiteBg">
                        <div className="formHead">
                            <h2>SITE Information</h2>
                        </div>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Location Name*</Form.Label>
                                    <AutoComplete
                                        freeSolo
                                        id="AddressId"
                                        disableClearable
                                        options={masterData.locationData}
                                        loading={autoloading}
                                        getOptionLabel={(option) => option.LocationName}
                                        onChange={this.handleAutoChange}
                                        inputValue={autoFields.AddressIdSearchTerm}
                                        onInputChange={this.handleInputChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={"Search Location Name"}
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: "search",
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {autoloading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.AddressId !== "" && formSubmit === true && (
                                        <Form.Text className="error">
                                            {errors.AddressId}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Site code*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="SiteCode"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.SiteCode}
                                    />
                                    {errors.SiteCode !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.SiteCode}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Site Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="SiteName"
                                        onChange={(e) => this.handleOnChange(e)}
                                        value={fields.SiteName}
                                    />
                                    {errors.SiteName !== "" && formSubmit === true && (
                                        <Form.Text className="error">{errors.SiteName}</Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Checkbox
                                        name="PrimaryFlag"
                                        checked={isChecked(fields.PrimaryFlag)}
                                        label="Primary"
                                        labelStyle={{ color: '#2d2d2d' }}
                                        labelPosition="left"
                                        onClick={(e) => this.handleOnChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Checkbox
                                        name="BillTo"
                                        checked={isChecked(fields.BillTo)}
                                        label="Bill To"
                                        labelStyle={{ color: '#2d2d2d' }}
                                        labelPosition="left"
                                        onClick={(e) => this.handleOnChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Checkbox
                                        name="ServiceTo"
                                        checked={isChecked(fields.ServiceTo)}
                                        label="Service To"
                                        labelStyle={{ color: '#2d2d2d' }}
                                        labelPosition="left"
                                        onClick={(e) => this.handleOnChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Checkbox
                                        name="ShipTo"
                                        checked={isChecked(fields.ShipTo)}
                                        label="Ship To"
                                        labelStyle={{ color: '#2d2d2d' }}
                                        labelPosition="left"
                                        onClick={(e) => this.handleOnChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control type="text" name="Address1" disabled value={address.Address1} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control type="text" name="Address2" disabled value={address.Address2} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" name="Country" disabled value={address.Country} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type="text" name="State" disabled value={address.State} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" name="City" disabled value={address.City} />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control type="text" name="State" disabled value={address.PostalCode} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <Row className="botBtn">
                        <Col sm={12}>
                            <Button className="saveBtn" type="submit">
                                {commonResource.PartySiteId > 0 ? "Update" : "Save"}
                            </Button>
                            <Button
                                className="cancelBtn"
                                type="button"
                                onClick={this.onCancel}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export { AddPartySite };
