import React from "react";
import Paper from "material-ui/Paper";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// switch
import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Actions
import { commonActions } from "_actions/common.actions";
import { restServices, common, history } from "_helpers";
//Import CSS
import "../css/Deal.css";
// Import Loader
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// includes
import { crm } from '_utils';
class DealDetails extends React.Component {
  constructor(props) {
    super(props);
    /**t
     * layoutFormat => Get form layout form API resources
     * jsonAttributes => Have stored all input type json properties
     * => USAGE: Reduce looping system have created ( jsonAttributes ) object
     * fieldConfig => Have stored all input key configuration
     */
    this.state = {
      page: "deal",
      dealID: 0,
      dealData: {},
      crmStatusList: [],
      layoutFormat: {},
      jsonAttributes: {},
      updatePermission: false,
      returnQuery: "",
      fieldConfig: {
        CampaignId: {
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        TerritoryId: {
          getOne:
            envConfig.BASE_API + Services.CRM_TERRITORIES + "/TERRITORY_NAME",
          get: "TerritoryId",
          set: "TerritoryName",
          searchKey: "TERRITORY_NAME",
        },
        LeadId: {
          getOne: envConfig.BASE_API + Services.CRM_LEADS + "/LEAD_NAME",
          get: "LeadId",
          set: "LeadName",
          searchKey: "LEAD_NAME",
        },
        CampaignId: {
          getList:
            envConfig.BASE_API +
            Services.CRM_CAMPAIGNS +
            `?q=TenantId=${crm.userInfo().pTenantId} AND OrgId=${crm.userInfo().pOrgId} AND UPPER(CampaignName) LIKE '*CAMPAIGN_NAME*' &limit=10&offset=0&totalResults=true`,
          getOne:
            envConfig.BASE_API + Services.CRM_CAMPAIGNS + "/CAMPAIGN_NAME",
          get: "CampaignId",
          set: "CampaignName",
          searchKey: "CAMPAIGN_NAME",
        },
        SalesPersonId: {
          getOne:
            envConfig.BASE_API + Services.CRM_EMPLOYEES + "/EMPLOYEE_NAME",
          get: "EmployeeId",
          set: "EmployeeName",
          searchKey: "EMPLOYEE_NAME",
        },
        AccountId: {
          getOne: envConfig.BASE_API + Services.CRM_CUSTOMER + "/CUSTOMER_NAME",
          get: "CustomerId",
          set: "CustomerName",
          searchKey: "CUSTOMER_NAME",
        },
        OrgId: {
          getOne: envConfig.BASE_API + Services.BASE_ORGANIZATION + "/ORG_NAME",
          get: "OrgId",
          set: "OrgName",
          searchKey: "ORG_NAME",
        },
      },
      userPermissions: common.getPermissionData(
        this.props.permissions.crm.deal
      ),
    };
    this.state.returnQuery = common.getCrmReturnQuery();
  }

  /**
   * Hooks Methods
   */
  componentDidMount() {
    this.setState(
      {
        dealID: this.props.match.params.dealId,
        page: window.location.href.includes("mydeal") ? "mydeal" : "deal",
      },
      (_) => {
        this.getFormJSON();
      }
    );
    this.getCRMStatusList();
  }

  // MAR: API Methods
  /**
   * Get form json data from API
   */
  getFormJSON = () => {
    let url =
      envConfig.BASE_API +
      Services.CRM_ESERVICE_HDR +
      "?q=EserviceCode=" +
      envConfig.ESERVICE_DEAL_CODE;
    this.setState({
      isLoading: true,
    });
    restServices.getRequest(
      url,
      (response) => {
        if (response && response.items.length > 0) {
          let result = response.items[0];
          this.setState(
            {
              layoutFormat: JSON.parse(result.FormFields),
              isLoading: false,
            },
            () => {
              this.getDealDetail(this.state.dealID);
              this.createDynamicFormFields();
            }
          );
        }
      },
      (error) => {
        this.setState({
          layoutFormat: {},
        });
      }
    );
  };

  /**
   * Get each dynamic field API details and bind value to json attributes
   */
  getDetail = (url, name) => {
    const { fieldConfig, jsonAttributes } = this.state;

    restServices.getRequest(
      encodeURI(url),
      (response) => {
        if (response) {
          let config = fieldConfig[name];
          jsonAttributes[name].value = response[config.set];
          this.setState({
            jsonAttributes: jsonAttributes,
          });
        }
      },
      (error) => { }
    );
  };

  /**
   * Get Deal Details by ID
   */
  getDealDetail = (id) => {
    const { jsonAttributes, fieldConfig, userPermissions } = this.state;
    const { deptEmployees } = this.props;
    let url = envConfig.BASE_API + Services.CRM_DEAL_OPPORTUNITY + "/" + id;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          if (response) {
            for (var key of Object.keys(response)) {
              if (response[key]) {
                if (jsonAttributes[key] !== undefined) {
                  jsonAttributes[key].value = response[key];
                }
                if (fieldConfig[key]) {
                  if (fieldConfig[key].hasOwnProperty("getOne")) {
                    fieldConfig[key].getOne = fieldConfig[key].getOne.replace(
                      fieldConfig[key].searchKey,
                      response[key]
                    );
                    this.getDetail(fieldConfig[key].getOne, key);
                  }
                }
              }
            }
            let access = common.validPermission(response.SalesPersonId, "update", userPermissions, deptEmployees, response.CreatedBy);
            this.setState({
              dealData: response,
              jsonAttributes: jsonAttributes,
              fieldConfig: fieldConfig,
              updatePermission: access,
            });
          }
        }
      },
      (error) => {
        this.setState({
          dealData: {},
        });
      }
    );
  };

  /**
   * Assign default value based on input type
   * @param {*} type
   */
  getDefaultValueByType = (type) => {
    switch (type) {
      case "integer":
        return 0;
      case "string":
        return "";
      default:
        return null;
    }
  };

  /**
   * Create dynamic form through given json attributes
   */
  createDynamicFormFields = () => {
    const { layoutFormat, jsonAttributes } = this.state;

    if (
      layoutFormat &&
      layoutFormat.columns &&
      layoutFormat.columns.length > 0
    ) {
      layoutFormat.columns.forEach((column) => {
        column.groups.forEach((column) => {
          column.rows.forEach((row) => {
            row.columns.forEach((rowColum) => {
              jsonAttributes[rowColum.key] = rowColum;
            });
          });
        });
      });

      this.setState({
        jsonAttributes: jsonAttributes,
      });
    }
  };

  /**
   * Get CRM list from API
   */
  getCRMStatusList = () => {
    const { dispatch } = this.props;
    this.setState({ isLoading: true });
    let dealTypeId = common.getTransactionTypeId(envConfig.DEAL_TRANS_TYPE_ID);
    dispatch(commonActions.getCRMDealStatus(dealTypeId));
  };

  /**
   * Get Data from API as per the request
   *
   * This is the alternative life cycle method for "componentWillReceiveProps"
   */
  static getDerivedStateFromProps(props, state) {
    const { crmDealStatus } = props;

    var crmStatusData = state.crmStatusList;

    if (crmDealStatus && crmDealStatus.list && crmDealStatus.list.success) {
      crmStatusData = crmDealStatus.list.details.message.items;

      crmStatusData = crmStatusData.sort(function (a, b) {
        return a.OrderNo > b.OrderNo ? 1 : -1;
      });
    }

    if (crmStatusData && crmStatusData !== state.crmStatusList) {
      return {
        crmStatusList: crmStatusData,
        isLoading: false,
      };
    } else {
      return {};
    }
  }

  /**
   * Load Deal status position based on filtered index
   */
  getStatusPosition = () => {
    const { dealData, crmStatusList } = this.state;
    if (
      dealData.ApprStatus &&
      dealData.ApprStatus != "" &&
      dealData.ApprStatus != null
    ) {
      var position = crmStatusList.findIndex(
        (x) => x.StatusCode == dealData.ApprStatus
      );
      return position >= 0 ? position : -1;
    }
  };

  /**
   * Load block of code or component based on the input key
   */
  renderBlock = (attr) => {
    let userData = common.authInfo();
    let role = userData.roleId || 0;
    let access = "";
    let permissions = attr.permission ? attr.permission : [];

    permissions.map((obj) => {
      if (obj.role == role) {
        access = obj.access;
      }
    });
    if (access != "DontShow") {
      switch (attr.input) {
        case "check_box":
          return (
            // <Checkbox
            //   disabled
            //   checked={attr.value == "Y" ? true : false}
            //   label={attr.name}
            //   labelStyle={{ color: "#A7AFC2" }}
            //   labelPosition="left"
            // />
            <Form.Group>
              <FormControlLabel
                className="d-flex justify-content-between margin-none"
                control={
                  <Switch
                    checked={attr.value == "Y" ? true : false}
                    name={attr.name}
                    color="primary"
                    onChange={() => { return false; }}
                  />
                }
                label={attr.name}
                labelPlacement="start"
              />
            </Form.Group>
          );
        default:
          if (
            attr.associate != undefined &&
            attr.value != null &&
            attr.value != ""
          ) {
            return (
              <div>
                <label className="form-label">{attr.name}</label>
                <p className="form-value">
                  {attr.type === "datetime"
                    ? attr.value
                      ? common.formatDate(attr.value, "DD/MM/YYYY")
                      : "-"
                    : attr.value
                      ? attr.value
                      : "-"}
                </p>
                <p>{attr.associate.value}</p>
              </div>
            );
          } else {
            return (
              <div>
                <label className="form-label">{attr.name}</label>
                <p className="form-value">
                  {attr.type === "datetime"
                    ? attr.value
                      ? common.formatDate(attr.value, "DD/MM/YYYY")
                      : "-"
                    : attr.value
                      ? attr.value
                      : "-"}
                </p>
              </div>
            );
          }
      }
    }
  };

  render() {
    const {
      isLoading,
      dealID,
      crmStatusList,
      layoutFormat,
      page,
      userPermissions,
      updatePermission,
      dealData,
    } = this.state;

    return (
      <div>
        {userPermissions.read == 2 ? (
          <AccessDenied />
        ) : (
          <div>
            {isLoading == false && (
              <div className="lead-wraper">
                <div className="formTab">
                  <ul>
                    {crmStatusList.map((data, idx) => (
                      <li
                        key={"dstatus" + idx}
                        className={`tabShape whiteTab ${this.getStatusPosition() >= idx ? "active" : ""
                          }`}
                      >
                        <span className="tri-right"></span>
                        <Badge badgeContent={++idx} color="primary">
                          {data.StatusName}
                        </Badge>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="row">
                  {layoutFormat.columns &&
                    layoutFormat.columns.length > 0 &&
                    layoutFormat.columns.map((pcoloum, pindex) => (
                      <div className="col" key={pindex}>
                        {pcoloum.groups.map((group, gindex) => (
                          <Paper style={styles.boxLayout} key={gindex}>
                            <div className="box-title-wrap justify-content-between">
                              <h3 className="card-title">{group.title}</h3>
                              {updatePermission && (
                                <Link
                                  className="card-link"
                                  to={(history.location.pathname.match("crm") ? "/crm/" : "/work/") + page + "/edit/" + dealID + this.state.returnQuery}
                                >
                                  Edit
                                </Link>
                              )}
                            </div>
                            {group.rows.map((row, rindex) => (
                              <div
                                className={
                                  row.noOfColumns == 1 ? "list-row" : "row"
                                }
                                key={rindex}
                              >
                                {row.columns.map(
                                  (rcoloum, rcindex) =>
                                    common.checkFieldPermission(rcoloum) && (
                                      <div
                                        className={
                                          1 == row.noOfColumns
                                            ? ``
                                            : `col-${12 / row.noOfColumns}`
                                        }
                                        key={rcindex}
                                      >
                                        <div
                                          className={
                                            1 == row.noOfColumns
                                              ? ``
                                              : `list-row`
                                          }
                                        >
                                          {this.renderBlock(rcoloum)}
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            ))}
                          </Paper>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            )}
            {isLoading == true && <Loader />}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  boxLayout: {
    width: "100%",
    padding: 20,
    marginBottom: 30,
  },
};

const mapStateToProps = (state) => {
  const { crmDealStatus } = state;
  return { crmDealStatus };
};
export default connect(mapStateToProps)(DealDetails);
