import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Checkbox from "material-ui/Checkbox";
import Select from "react-select";
// includes
import { crm } from '_utils';
//API SERVICES
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, history } from "_helpers";
//Datepicker css
import "react-datepicker/dist/react-datepicker.css";

import "../css/LeaveSettings.css";

const leaveList = [
  { value: "DAYS", label: "Days" },
  { value: "MONTHS", label: "Months" },
  { value: "YEARS", label: "Years" },
  { value: "HOURS", label: "Hours" },
  { value: "DAYS", label: "Weeks" },
];
class LeaveSettingsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.params = this.props.params;
    this.state = {
      isLoading: false,
      formSubmit: false,
      LeaveSettingsId: 0,
      fields: {
        LeaveType: "",
        PaidLeaves: "",
        PaidLeavesStr: "",
        MaxLeaves: "",
        MaxLeavesStr: "",
        MinService: "",
        MinServiceStr: "",
        Description: "",
        TenantId: crm.userInfo().pTenantId,
        StartDate: new Date(),
        EndDate: null,
        Active: "Y",
        DivisionId: "",
        SubDivisionId: "",
        DepartmentId: "",
        DesigId: "",
        CarryForward: "Y",
        EmpCategory: "",
        MaxLvPerMnth: "",
        ExpiryDays: "",
      },
      masterData: {
        LeaveType: [{ label: "Select", value: "" }],
        EmpCategory: [{ label: "Select", value: "" }],
        DivisionData: [{ label: "Select", value: "" }],
        SubDivisionData: [{ label: "Select", value: "" }],
        DesignationData: [{ label: "Select", value: "" }],
        DepartmentData: [{ label: "Select", value: "" }],
      },
      commonResource: {
        LeaveSettingId: 0,
      },
      errors: {
        LeaveType: {
          error: "",
          isReq: "Leave Type is required!",
        },
        EmpCategory: {
          error: "",
          isReq: "Employee Category is required!",
        },
        StartDate: {
          error: "",
          isReq: "Start Date is required!",
        },
        // EndDate: {
        //     error: "",
        //     isReq: "End Date is required!",
        // },
        DivisionId: {
          error: "",
          isReq: "Division is required!",
        },
        SubDivisionId: {
          error: "",
          isReq: "Sub Division is required!",
        },
        DepartmentId: {
          error: "",
          isReq: "Department is required!",
        },
        DesigId: {
          error: "",
          isReq: "Designation is required!",
        },
        Description: {
          error: "",
          isReq: "Description is required!",
        },
        MaxLeaves: {
          error: "",
          isReq: "Max Leaves is required!",
        },
      },
      filterName: "",
      page: 1,
    };
  }

  // life cycle
  componentDidMount() {
    const { commonResource } = this.state;

    this.getLookUpData("CHOLA_LEAVE_TYPE", "LeaveType");
    this.getLookUpData("EMP_LEAVE_CATEGORY", "EmpCategory");
    this.getMasterData("DivisionData");
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let filterName = params.get("filterName");
    let page = params.get("page");
    if (this.props.match.params.id) {
      this.getDetailById(this.props.match.params.id);
      commonResource["LeaveSettingId"] = this.props.match.params.id;
      this.setState({
        commonResource: commonResource,
        filterName,
        page,
      });
    }
  }

  /**
   * Get lookup values by lookup code
   */
  getLookUpData = (lookupValue, source) => {
    const { masterData } = this.state;
    let filter = `?finder=FindLookupVals;pLookupCode=${lookupValue}&totalResults=true&limit=100&offset=0`;
    let url = envConfig.BASE_API + Services.LOOKUPS_LOV + filter;

    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          response.items.map((items) => {
            let obj = {
              label: items.Name,
              value: items.Code,
            };
            masterData[source].push(obj);
            this.setState({
              masterData: masterData,
            });
          });
          // masterData[source] = response.items && response.items.length ? response.items : [];
          // this.setState({
          //     masterData: masterData,
          // });
        }
      },
      (error) => { }
    );
  };

  // Get leave settings details by id
  getDetailById = (id) => {
    const { fields, errors } = this.state;

    let url = encodeURI(
      envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS + "/" + id
    );
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          for (var key of Object.keys(response)) {
            if (fields.hasOwnProperty(key)) {
              if (response[key]) {
                if (key === "StartDate" || key === "EndDate") {
                  fields[key] = response[key] ? new Date(response[key]) : "";
                } else {
                  fields[key] = response[key];
                }

                this.loadDependentResources(key, response[key]);

                // Remove Default Errors
                if (errors.hasOwnProperty(key)) {
                  errors[key].error = "";
                }
              }
            }
          }
          this.setState({
            fields: fields,
            errors: errors,
            isLoadingAdd: false,
          });
        }
      },
      (error) => { }
    );
  };

  getURLByType = (type) => {
    const { fields } = this.state;
    switch (type) {
      case "DivisionData":
        return encodeURI(envConfig.BASE_API + Services.HRMS_DIVISION);
      case "SubDivisionData":
        return encodeURI(
          envConfig.BASE_API +
          Services.HRMS_SUB_DIVISION +
          "/?q=DivisionId=" +
          fields.DivisionId +
          "&limit=50"
        );
      case "DepartmentData":
        return encodeURI(
          envConfig.BASE_API +
          Services.HRMS_DEPARTMENTS +
          "/?q=SubDivId=" +
          fields.SubDivisionId +
          "&limit=50"
        );
      case "DesignationData":
        return encodeURI(
          envConfig.BASE_API +
          Services.HRMS_DESIGNATIONS +
          "/?q=DepartmentId=" +
          fields.DepartmentId +
          "&limit=50"
        );
      default:
        return "";
    }
  };

  getMasterData = (type) => {
    const { masterData } = this.state;

    restServices.getRequest(
      this.getURLByType(type),
      (response) => {
        switch (type) {
          case "DivisionData":
            masterData[type] = common.getOptionsData(
              response.items,
              "DivisionName",
              "DivisionId"
            );
            break;
          case "SubDivisionData":
            masterData[type] = common.getOptionsData(
              response.items,
              "SubDivisionName",
              "SubDivisionId"
            );
            break;
          case "DepartmentData":
            masterData[type] = common.getOptionsData(
              response.items,
              "DepartmentName",
              "DeptId"
            );
            break;
          case "DesignationData":
            masterData[type] = common.getOptionsData(
              response.items,
              "DesignationName",
              "DesignationId"
            );
            break;
        }

        this.setState({
          masterData,
        });
      },
      (error) => { }
    );
  };

  loadDependentResources = (name, value) => {
    const { fields } = this.state;
    if (name == "DivisionId") {
      fields.SubDivisionId = "";
      fields.DesigId = "";
      fields.DepartmentId = "";
      this.setState({
        fields: fields,
        DepartmentData: [{ label: "Select", value: "" }],
        SubDivisionData: [{ label: "Select", value: "" }],
        DesignationData: [{ label: "Select", value: "" }],
      });

      if (!value) {
        fields.SubDivisionId = "";
        fields.DesigId = "";
        fields.DepartmentId = "";

        this.setState(
          {
            DepartmentData: [{ label: "Select", value: "" }],
            SubDivisionData: [{ label: "Select", value: "" }],
            DesignationData: [{ label: "Select", value: "" }],
            fields: fields,
          },
          () => {
            this.validateForm("SubDivisionId", "");
            this.validateForm("DesigId", "");
            this.validateForm("DepartmentId", "");
          }
        );
      } else {
        this.getMasterData("SubDivisionData");
      }
    }

    if (name == "SubDivisionId") {
      if (!value) {
        fields.DepartmentId = "";
        fields.DesigId = "";
        this.setState(
          {
            DepartmentData: [{ label: "Select", value: "" }],
            DesignationData: [{ label: "Select", value: "" }],
            fields: fields,
          },
          () => {
            this.validateForm("DepartmentId", "");
            this.validateForm("DesigId", "");
          }
        );
      } else {
        this.getMasterData("DepartmentData");
      }
    }

    if (name == "DepartmentId") {
      if (!value) {
        fields.DesigId = "";
        this.setState(
          {
            DesignationData: [{ label: "Select", value: "" }],
            fields: fields,
          },
          () => {
            this.validateForm("DesigId", "");
          }
        );
      } else {
        this.getMasterData("DesignationData");
      }
    }
  };

  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else {
        errors[key].error = "";
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateAll() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      }
    }
    this.setState({
      errors,
    });

    return isValid;
  }

  // event
  handleOnChange = (event) => {
    const { fields } = this.state;
    const { name, value, type, checked } = event.target;
    var inputValue = value;

    if (type == "checkbox") {
      inputValue = checked ? "Y" : "N";
    }

    if (fields.hasOwnProperty(name)) {
      fields[name] = inputValue;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.loadDependentResources(name, inputValue);
        this.validateForm(name, inputValue);
      }
    );
  };

  selectHandleOnChange = (event, type) => {
    const { label, value } = event;
    const { fields } = this.state;
    if (fields.hasOwnProperty(type)) {
      fields[type] = value;
    }

    this.setState({ fields: fields }, () => {
      this.loadDependentResources(type, value);
      this.validateForm(type, value);
    });
  };

  handleDateOnChange = (date, name) => {
    const { fields } = this.state;
    if (fields.hasOwnProperty(name)) {
      fields[name] = date;
    }

    this.setState(
      {
        fields: fields,
      },
      () => {
        this.validateForm(name, date);
      }
    );
  };

  // rest services
  onSubmit = (event) => {
    event.preventDefault();
    const { commonResource } = this.state;

    this.setState({
      formSubmit: true,
    });

    let isValid = this.validateAll();

    if (isValid) {
      if (commonResource.LeaveSettingId > 0) {
        this.update();
      } else {
        this.create();
      }
    }
  };

  update = () => {
    const { fields, commonResource, filterName, page } = this.state;

    let url = encodeURI(
      envConfig.BASE_API +
      Services.HRMS_LEAVE_SETTINGS +
      "/" +
      commonResource.LeaveSettingId
    );
    restServices.patchRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push(
            "/setup/hrms/leavesetting?leaveSettingId=" +
            commonResource.LeaveSettingId +
            "&filterName=" +
            filterName +
            "&page=" +
            page
          );
        }
      },
      (error) => { }
    );
  };

  create = () => {
    const { fields, commonResource, filterName, page } = this.state;

    let url = encodeURI(envConfig.BASE_API + Services.HRMS_LEAVE_SETTINGS);
    restServices.postRequest(
      url,
      fields,
      (response) => {
        if (response) {
          history.push(
            "/setup/hrms/leavesetting?leaveSettingId=" +
            commonResource.LeaveSettingId +
            "&filterName=" +
            filterName +
            "&page=" +
            page
          );
        }
      },
      (error) => { }
    );
  };

  onCancel = () => {
    const { commonResource, filterName, page } = this.state;
    if (commonResource.LeaveSettingId > 0) {
      history.push(
        "/setup/hrms/leavesetting?leaveSettingId=" +
        commonResource.LeaveSettingId +
        "&filterName=" +
        filterName +
        "&page=" +
        page
      );
    } else {
      history.push("/setup/hrms/leavesetting");
    }
  };

  render() {
    const {
      fields,
      errors,
      formSubmit,
      masterData,
      commonResource,
    } = this.state;
    const isChecked = (value) => value == "Y";
    return (
      <div className="lead-wraper">
        <h2>Add Leave</h2>
        <Form onSubmit={this.save}>
          <div>
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Leave Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Leave Type*</Form.Label>
                      <div className="row set-default-row">
                        <Select
                          value={masterData.LeaveType.find(
                            (o) => o.value === fields.LeaveType
                          )}
                          onChange={(e) =>
                            this.selectHandleOnChange(e, "LeaveType")
                          }
                          options={masterData.LeaveType}
                        />
                        <span style={{ marginTop: 10, color: "#c0c0c0" }}>
                          Yearly
                        </span>
                      </div>
                      {errors.LeaveType.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.LeaveType.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Employee Category*</Form.Label>
                      <Select
                        value={masterData.EmpCategory.find(
                          (o) => o.value === fields.EmpCategory
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "EmpCategory")
                        }
                        options={masterData.EmpCategory}
                      />
                      {errors.EmpCategory.error !== "" &&
                        formSubmit === true && (
                          <Form.Text className="error">
                            {errors.EmpCategory.error}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Division*</Form.Label>
                      <Select
                        value={masterData.DivisionData.find(
                          (o) => o.value == fields.DivisionId
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "DivisionId")
                        }
                        options={masterData.DivisionData}
                      />
                      {errors.DivisionId.error !== "" &&
                        formSubmit === true && (
                          <Form.Text className="error">
                            {errors.DivisionId.error}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Sub Division</Form.Label>
                      <Select
                        value={masterData.SubDivisionData.find(
                          (o) => o.value == fields.SubDivisionId
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "SubDivisionId")
                        }
                        options={masterData.SubDivisionData}
                      />
                      {errors.SubDivisionId.error !== "" &&
                        formSubmit === true && (
                          <Form.Text className="error">
                            {errors.SubDivisionId.error}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Department*</Form.Label>
                      <Select
                        value={masterData.DepartmentData.find(
                          (o) => o.value == fields.DepartmentId
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "DepartmentId")
                        }
                        options={masterData.DepartmentData}
                      />
                      {errors.DepartmentId.error !== "" &&
                        formSubmit === true && (
                          <Form.Text className="error">
                            {errors.DepartmentId.error}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Designation</Form.Label>
                      <Select
                        value={masterData.DesignationData.find(
                          (o) => o.value == fields.DesigId
                        )}
                        onChange={(e) =>
                          this.selectHandleOnChange(e, "DesigId")
                        }
                        options={masterData.DesignationData}
                      />
                      {errors.DesigId.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.DesigId.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>Description*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="Description"
                        onChange={(e) => this.handleOnChange(e)}
                        value={fields.Description}
                        maxLength={150}
                      />
                      {errors.Description.error !== "" &&
                        formSubmit === true && (
                          <Form.Text className="error">
                            {errors.Description.error}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Start Date*</Form.Label>
                      <DatePicker
                        name="StartDate"
                        value={fields.StartDate}
                        onChange={(date) =>
                          this.handleDateOnChange(date, "StartDate")
                        }
                        format="dd/MM/yyyy"
                        autoComplete="off"
                        disabled={
                          commonResource.LeaveSettingId > 0 ? true : false
                        }
                      />
                      {errors.StartDate.error !== "" && formSubmit === true && (
                        <Form.Text className="error">
                          {errors.StartDate.error}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>End Date*</Form.Label>
                      <DatePicker
                        name="EndDate"
                        value={fields.EndDate}
                        onChange={(date) =>
                          this.handleDateOnChange(date, "EndDate")
                        }
                        format="dd/MM/yyyy"
                        autoComplete="off"
                        minDate={new Date()}
                      />
                      {/* {errors.EndDate.error !== '' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.EndDate.error}
                                                </Form.Text>
                                            )} */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="Active"
                        checked={isChecked(fields.Active)}
                        label="Active"
                        labelStyle={{ color: "#2d2d2d" }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                      {/* {errors.Active !== 'Y' && formSubmit === true && (
                                                <Form.Text className="error">
                                                    {errors.Active}
                                                </Form.Text>
                                            )} */}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Detail Information */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg appendInput">
                <div className="formHead">
                  <h2>Details</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Paid Leaves</Form.Label>
                      <Form.Group>
                        <div className="input-group">
                          <Form.Control
                            type="number"
                            name="PaidLeaves"
                            min="0"
                            value={fields.PaidLeaves}
                            onChange={this.handleOnChange}
                          />
                          <div className="input-group-append">
                            <Select
                              value={leaveList.find(
                                (o) => o.value === fields.PaidLeavesStr
                              )}
                              onChange={(e) =>
                                this.selectHandleOnChange(e, "PaidLeavesStr")
                              }
                              options={leaveList}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Max Leaves</Form.Label>
                      <Form.Group>
                        <div className="input-group">
                          <Form.Control
                            type="number"
                            name="MaxLeaves"
                            min="0"
                            value={fields.MaxLeaves}
                            onChange={this.handleOnChange}
                          />
                          <div className="input-group-append">
                            <Select
                              value={leaveList.find(
                                (o) => o.value === fields.PaidLeavesStr
                              )}
                              onChange={(e) =>
                                this.selectHandleOnChange(e, "PaidLeavesStr")
                              }
                              options={leaveList}
                            />
                          </div>
                        </div>
                        {errors.MaxLeaves.error !== "" &&
                          formSubmit === true && (
                            <Form.Text className="error">
                              {errors.MaxLeaves.error}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Min Service</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type="number"
                          name="MinService"
                          min="0"
                          value={fields.MinService}
                          onChange={this.handleOnChange}
                        />
                        <div className="input-group-append">
                          <Select
                            value={leaveList.find(
                              (o) => o.value === fields.PaidLeavesStr
                            )}
                            onChange={(e) =>
                              this.selectHandleOnChange(e, "PaidLeavesStr")
                            }
                            options={leaveList}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Max Leaves per Month</Form.Label>
                      <Form.Group>
                        <Form.Control
                          type="number"
                          name="MaxLvPerMnth"
                          min="0"
                          value={fields.MaxLvPerMnth}
                          onChange={this.handleOnChange}
                        />
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Leave Expiry In Days</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="ExpiryDays"
                        min="0"
                        onChange={(e) => this.handleOnChange(e)}
                        value={fields.ExpiryDays}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Checkbox
                        name="CarryForward"
                        checked={isChecked(fields.CarryForward)}
                        label="Carry Forward"
                        labelStyle={{ color: "#2d2d2d" }}
                        labelPosition="left"
                        onClick={(e) => this.handleOnChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Action section */}
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  type="submit"
                  onClick={this.onSubmit}
                >
                  {commonResource.LeaveSettingId > 0 ? "Update" : "Save"}
                </Button>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  }
}

export { LeaveSettingsAdd };
