import React, { createRef } from "react";
import { Link } from "react-router-dom";
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, restServices, tableConfig } from "_helpers";
// includes
import { crm } from "_utils";
import Select from "react-select";
//Import CSS
import "../css/TransactionStatus.css";

import ActiveIcon from "img/active.svg";
import deactiveIcon from "img/deactive.svg";
import ConfigureMenu from "_components/_configureMenu/ConfigureMenu";
import Pagination from "react-js-pagination";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

// Import Loader
import Loader from "_components/_loader/Loader";
import NoRecordFound from "_components/_noRecordFound/NoRecordFound";
import EnhancedTableToolbar from "_components/_table/EnhancedTableToolbar";
import EnhancedTableHead from "_components/_table/EnhancedTableHead";
import EnhancedTableColumns from "_components/_table/EnhancedTableColumns";
import AccessDenied from "_components/_accessdenied/AccessDenied";
import FileExport from "../../../../_components/FileExport/FileExport";
const classes = makeStyles({
  table: {
    minWidth: 650,
  },
});
const child = createRef();
class TransactionStatusList extends React.Component {
  constructor(props) {
    super(props);
    let transCode = localStorage.getItem("TransCode") || envConfig.CAMPAIGN_TRANS_TYPE_ID;
    this.state = {
      pageEntries: 0,
      listRes: {},
      isLoading: false,
      currentPage: 1,
      pageLimit: 20,
      OrderBy: "",
      transactionTypeId: common.getTransactionTypeId(transCode),
      tlConfig: {
        headCells: [
          { id: "OrderNo", value: "OrderNo", label: "ORDER NO" },
          //{ id: "StatusCode", value: "StatusCode", label: "STATUS CODE" },
          { id: "StatusName", value: "StatusName", label: "STATUS NAME" },
          { id: "Description", value: "Description", label: "DESCRIPTION" },
          { id: "StartDate", value: "StartDate", label: "START DATE" },
          { id: "EndDate", value: "EndDate", label: "END DATE" },
          { id: "Active", value: "Active", label: "ACTIVE" },
          {
            id: "VersionNumber",
            value: "VersionNumber",
            label: "VERSION NUMBER",
          },
        ],
        columnsSelected: [],
        order: "asc",
        orderBy: "OrderNo",
        selected: [],
        uniqueKey: "TransStatuscolumns",
        checkedAll: false,
        toolbarHeading: "",
        enableMultipleDelete: false,
        delItemCount: 0,
      },
      formList: [
        { label: "Campaign", value: envConfig.CAMPAIGN_TRANS_TYPE_ID },
        { label: "Enquiry", value: envConfig.LIST_TRANS_TYPE_ID },
        { label: "Lead", value: envConfig.LEAD_TRANS_TYPE_ID },
        { label: "Deal", value: envConfig.DEAL_TRANS_TYPE_ID },
      ],
      userPermissions: common.getPermission(
        this.props,
        "configure:form trasactions"
      ),
      headers: [
        { label: "ID", key: "StatusId" },
        { label: "Order No", key: "OrderNo" },
        //{ label: "Status Code", key: "StatusCode" },
        { label: "Status Name", key: "StatusName" },
        { label: "Description", key: "Description" },
        { label: "Start Date", key: "StartDate" },
        { label: "End Date", key: "EndDate" },
        { label: "Active", key: "Active" },
        { label: "Version Number", key: "VersionNumber" },
      ],
      urls: "",
      totalResults: "",
      keyArray: [
        "StatusId",
        "OrderNo",
        //"StatusCode",
        "StatusName",
        "Description",
        "StartDate_date",
        "EndDate_date",
        "Active",
        "VersionNumber",
      ],
      transCode: transCode,
    };
  }

  handlePageChange(pageNumber) {
    const { currentPage } = this.state;
    if (currentPage != pageNumber) {
      this.setState(
        {
          currentPage: pageNumber,
        },
        () => {
          this.getAllTransStatus();
        }
      );
    }
  }

  handleChange = (fieldName, e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        currentPage: 1,
      },
      (_) => {
        this.getAllTransStatus();
      }
    );
  };

  componentDidMount() {
    const { userPermissions, formList } = this.state;
    if (userPermissions.access) {
      this.getAllTransStatus();
    }
  }

  selectHandleOnChange = (event, name) => {
    const { value } = event;
    let typeId = common.getTransactionTypeId(value);

    this.setState({ transactionTypeId: typeId, transCode: value }, () => {
      localStorage.setItem("TransCode", value);
      this.getAllTransStatus();
    });
  };

  /**
   * Table list config data update method
   */
  updateTLConfig = (key, value, object) => {
    const { tlConfig, listRes } = this.state;
    if (object) {
      for (var oKey of Object.keys(object)) {
        if (tlConfig.hasOwnProperty(oKey)) {
          tlConfig[oKey] = object[oKey];
        }
      }
      this.setState({
        tlConfig: tlConfig,
      });
    } else {
      if (tlConfig.hasOwnProperty(key)) {
        tlConfig[key] = value;
        this.setState({
          tlConfig: tlConfig,
        });
      }

      if (key === "checkedAll") {
        if (value) {
          const newSelecteds = listRes.items.map((n) => n.StatusId);
          tlConfig.selected = newSelecteds;
          this.setState({
            tlConfig: tlConfig,
          });
          return;
        } else {
          tlConfig.checkedAll = false;
          tlConfig.selected = [];
          this.setState({
            tlConfig: tlConfig,
          });
        }
      }
    }
  };

  deleteSelected = () => {
    common.confirmDelete(true, (response) => {
      this.handleClickDelete();
    });
  };

  handleClickDelete = () => {
    const { tlConfig } = this.state;
    let inputData = [];
    tlConfig.selected.map((id, index) => {
      inputData.push({
        id: "part" + (index + 1),
        path: "/" + Services.TRANSACTION_STATUS + "/" + id,
        operation: "delete",
        payload: {},
      });
    });

    var formAttr = { parts: inputData };
    let url = encodeURI(envConfig.BASE_API);

    restServices.batchRequest(
      url,
      formAttr,
      (response) => {
        if (response != null) {
          this.getAllTransStatus();
          tlConfig["selected"] = [];
          tlConfig["checkedAll"] = false;
          this.setState({
            tlConfig: tlConfig,
          });
          common.snack("S", "Selected items has been deleted successfully.");
        }
      },
      (error) => {
        common.snack("E", error);
      }
    );
  };

  handlePageEntries = (e, val) => {
    this.setState({
      pageEntries: val,
    });
  };

  /**
   * Get all leads from DB
   */
  getAllTransStatus = () => {
    this.setState({ isLoading: true });

    const {
      tlConfig,
      currentPage,
      pageLimit,
      transactionTypeId,
      userPermissions,
    } = this.state;

    let filter = `?finder=FindByTransType;pTransTypeId=${transactionTypeId},pOrgId=${crm.userInfo().pOrgId
      },pTenantId=${crm.userInfo().pTenantId}&limit=${pageLimit}&offset=${(currentPage - 1) * pageLimit
      }&totalResults=true&orderBy=OrderNo:asc`;

    let url = envConfig.BASE_API + Services.TRANSACTION_STATUS + filter;
    restServices.getRequest(
      url,
      (response) => {
        if (response) {
          let deletePermission = userPermissions.delete;
          tlConfig.enableMultipleDelete =
            deletePermission &&
              response &&
              response.items &&
              response.items.length
              ? true
              : false;
          tlConfig.delItemCount = tlConfig.enableMultipleDelete
            ? response.items.length
            : 0;

          this.setState(
            {
              listRes: response,
              isLoading: false,
              tlConfig: tlConfig,
              urls: envConfig.BASE_API + Services.TRANSACTION_STATUS,
              totalResults: response.totalResults,
            },
            (_) => {
              if (child.current) {
                child.current.getCsvData();
              }
            }
          );
        }
      },
      (error) => { }
    );
  };

  render() {
    const {
      isLoading,
      listRes,
      currentPage,
      pageLimit,
      transactionTypeId,
      tlConfig,
      formList,
      userPermissions,
      totalResults,
      urls,
      headers,
      keyArray,
      transCode
    } = this.state;

    const isHided = (StatusId) =>
      tlConfig.columnsSelected.indexOf(StatusId) !== -1;
    const isSelected = (StatusId) => tlConfig.selected.indexOf(StatusId) !== -1;

    const handleClick = (event, StatusId) => {
      const selectedIndex = tlConfig.selected.indexOf(StatusId);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(tlConfig.selected, StatusId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(tlConfig.selected.slice(1));
      } else if (selectedIndex === tlConfig.selected.length - 1) {
        newSelected = newSelected.concat(tlConfig.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          tlConfig.selected.slice(0, selectedIndex),
          tlConfig.selected.slice(selectedIndex + 1)
        );
      }
      if (newSelected.length === listRes.items.length) {
        tlConfig.checkedAll = true;
      } else {
        tlConfig.checkedAll = false;
      }
      tlConfig["selected"] = newSelected;
      this.setState({
        tlConfig: tlConfig,
      });
    };

    let filter = `?finder=FindByTransType;pTransTypeId=${transactionTypeId},pOrgId=${crm.userInfo().pOrgId
      },pTenantId=${crm.userInfo().pTenantId
      }&limit=${totalResults}&offset=0&totalResults=true&orderBy=OrderNo:asc`;

    return (
      <div className="lead-wraper">
        <ConfigureMenu />
        {!userPermissions.access ? (
          <AccessDenied />
        ) : (
          <div>
            {/* Secondary Nav end */}
            <div className="filter-wrap">
              <div className="row justify-content-between">
                <div className="col-sm-5">
                  <div className="form-row">
                    <div className="col">
                      <h2 className="crm-type-title">Transaction Status</h2>
                    </div>
                  </div>
                </div>
                {/* <div className="col-2">
              Choose form
            </div> */}
                <div className="col-sm-7">
                  <div className="form-row justify-content-end">
                    <div className="col-sm-5">
                      <div className="select-control filter-selectbox">
                        <Select
                          value={formList.find(
                            (o) => o.value === transCode
                          )}
                          onChange={(e) =>
                            this.selectHandleOnChange(e, "transCode")
                          }
                          options={formList}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <EnhancedTableColumns
                        tlConfig={tlConfig}
                        updateTLConfig={this.updateTLConfig}
                      />
                    </div>
                    <div className="col-auto">
                      <FileExport
                        ref={child}
                        headers={headers}
                        filename={"Transaction_status_details.xls"}
                        urls={urls}
                        keyArray={keyArray}
                        filter={filter}
                      />
                    </div>
                    <div className="col-auto">
                      <div className="form-row justify-content-end">
                        <div className="col-auto">
                          <Link
                            to={
                              "/setup/configure/form-transaction/" + transactionTypeId
                            }
                            className="btn btn-add"
                          >
                            Add
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end of Filter Wrap */}
            <div className="crm-data-wrap">
              <div className="list-view-wrap">
                <EnhancedTableToolbar
                  tlConfig={tlConfig}
                  deleteSelected={this.deleteSelected}
                />

                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      tlConfig={tlConfig}
                      updateTLConfig={this.updateTLConfig}
                    />
                    <TableBody>
                      {isLoading == false &&
                        listRes.items &&
                        listRes.items.length > 0 &&
                        tableConfig
                          .stableSort(
                            listRes.items,
                            tableConfig.getComparator(
                              tlConfig.order,
                              tlConfig.orderBy
                            )
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.StatusId);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                onClick={(event) =>
                                  handleClick(event, row.StatusId)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.StatusId}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    !isHided("OrderNo") ? "hideCell" : ""
                                  }
                                >
                                  {row.OrderNo ? row.OrderNo : "-"}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={
                                    !isHided("StatusName") ? "hideCell" : ""
                                  }
                                >
                                  <Link
                                    to={
                                      "/setup/configure/form-transaction/" +
                                      transactionTypeId +
                                      "/" +
                                      row.StatusId
                                    }
                                  >
                                    {row.StatusName ? row.StatusName : "-"}
                                  </Link>
                                </TableCell>
                                {/* <TableCell
                                  className={
                                    !isHided("StatusName") ? "hideCell" : ""
                                  }
                                >
                                  {row.StatusName ? row.StatusName : "-"}
                                </TableCell> */}
                                <TableCell
                                  className={
                                    !isHided("Description") ? "hideCell" : ""
                                  }
                                >
                                  {row.Description ? row.Description : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("StartDate") ? "hideCell" : ""
                                  }
                                >
                                  {row.StartDate
                                    ? common.formatDate(
                                      row.StartDate,
                                      "DD/MM/YYYY"
                                    )
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("EndDate") ? "hideCell" : ""
                                  }
                                >
                                  {row.EndDate
                                    ? common.formatDate(
                                      row.EndDate,
                                      "DD/MM/YYYY"
                                    )
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("Active") ? "hideCell" : ""
                                  }
                                >
                                  {row.Active == "Y" ? (
                                    <img src={ActiveIcon} alt="Call" />
                                  ) : (
                                    <img src={deactiveIcon} alt="Call" />
                                  )}
                                  <span
                                    style={
                                      row.Active == "Y"
                                        ? { color: "#333333", paddingLeft: 3 }
                                        : { color: "#cccccc", paddingLeft: 3 }
                                    }
                                  >
                                    {row.Active == "Y" ? "Active" : "Deactive"}{" "}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className={
                                    !isHided("VersionNumber") ? "hideCell" : ""
                                  }
                                >
                                  {row.VersionNumber ? row.VersionNumber : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      {isLoading == true && (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Loader />{" "}
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading == false &&
                        listRes.items &&
                        listRes.items.length == 0 && (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <NoRecordFound />{" "}
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {isLoading == false && listRes.totalResults > 0 && (
                  <div className="d-flex justify-content-center align-items-center p-5 bg-white">
                    <div className="mr-auto">
                      Showing {(currentPage - 1) * pageLimit + 1} to{" "}
                      {(currentPage - 1) * pageLimit + listRes.count} of{" "}
                      {listRes.totalResults} entries
                    </div>
                    <div className="pagination-wrap">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={currentPage}
                        itemsCountPerPage={listRes.limit}
                        totalItemsCount={listRes.totalResults}
                        pageRangeDisplayed={7}
                        onChange={this.handlePageChange.bind(this)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end of Switch View Control */}
          </div>
        )}
      </div>
    );
  }
}

export { TransactionStatusList };
