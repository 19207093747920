import React from "react";
import { Form, Col, Button, Row, Modal } from "react-bootstrap";
// import DatePicker from "react-datepicker";
import DatePicker from 'react-date-picker';
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// includes
import { crm } from '_utils';
import { FormFieldSelect, FormFieldAuto } from "pages/_components/FormFields"
// api
import * as Services from "_config/api";
import { envConfig } from "_config/config";
import { common, tools, restServices, history } from "_helpers";
// pages
import Loader from "_components/_loader/Loader";
import AccessDenied from "_components/_accessdenied/AccessDenied";
// css
import "react-datepicker/dist/react-datepicker.css";
// const
const selectDefault = { label: "", value: "" };;

class InvoiceCrud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: this.props,
      isLoading: true,
      isAdding: false,
      isAddingItem: false,
      formSubmit: false,
      formSubmitItem: false,
      isModal: false,
      fullWidth: true,
      maxWidth: "md",
      editKey: "",
      fields: {
        CustOrderId: selectDefault,
        OrderReference: "",
        InvoiceNo: "",
        Status: "",
        InvoiceDate: null,
        InvoiceType: selectDefault,
        CustomerId: selectDefault,
        CustPoDate: null,
        CreditDays: "",
        TransactionSource: selectDefault,
        SalesPersonId: selectDefault,
        DueDate: null,
        AccountingDate: null,
        Currency: selectDefault,
        ExchangeRate: "",
        ExchangeRateType: selectDefault,
        OrderDate: null,
        GstEnabled: "N",
        Comments: "",
        BillToSiteId: selectDefault,
        ShipToSiteId: selectDefault,
        DiscType: selectDefault,
        TollFee: "",
        DiscAmtPer: "",
        TransportCharge: "",
        DespatchMode: selectDefault,
        AmtInWords: "",
        isUpdate: false
      },
      fieldsItem: {
        InvoiceDtlsId: "",
        InvoiceId: "",
        ItemId: selectDefault,
        Uom: selectDefault,
        Quantity: "",
        OrderDtlRate: "",
        DiscPer: "",
        TaxPer: "",
        ReqDeliveryDate: null,
        PromisedDelDate: null,
        SchShipDate: null,
        DtlStatus: selectDefault,
        isNew: true,
        isUpdate: false
      },
      errors: {
        InvoiceNo: {
          error: "",
          isReq: "Invoice number is required",
        },
        InvoiceType: {
          error: "",
          isReq: "Invoice type is required",
          isObject: true,
        }
      },
      errorsItem: {
        ItemId: {
          error: "",
          isReq: "Please select the item",
          isObject: true,
        },
        Quantity: {
          error: "",
          isReq: "Quantity is required",
        },
        OrderDtlRate: {
          error: "",
          isReq: "Rate is required",
        },
        ReqDeliveryDate: {
          error: "",
          isReq: "Date is required",
        },
        PromisedDelDate: {
          error: "",
          isReq: "Date is required",
        },
        SchShipDate: {
          error: "",
          isReq: "Date is required",
        },
      },
      sData: {
        itemList: [],
        itemDeleted: [],
        statusList: [],
        locationInfo: {
          BillToSiteId: {
            isLoading: "",
            Address1: "",
            Address2: "",
            Landmark: ""
          },
          ShipToSiteId: {
            isLoading: "",
            Address1: "",
            Address2: "",
            Landmark: ""
          }
        },
        amountInfo: {
          rate: 0,
          discount: 0,
          subTotal: 0,
          tax: 0,
          grandTotal: 0,
        }
      },
      userInfo: crm.userInfo(),
      userPermissions: common.getPermission(this.props, "crm:invoice"),
    };
    this.count = 0;
  }

  componentDidMount() {
    const { params } = this.state;
    if (params.invoiceId) {
      if (params.invoiceData == "") {
        common.snack("E", "Unable to load invoice details!");
        history.push("/crm/invoice");
      } else {
        this.getInvoiceDetails();
      }
    } else {
      this.setLoading(false);
    }
  }

  // api
  getInvoiceDetails() {
    const { params, sData } = this.state;
    let filter = '?q=InvoiceId=' + params.invoiceId;
    let url = envConfig.BASE_API + Services.CRM_INVOICE_DETAILS + filter;

    restServices.getRequest(
      url,
      (response) => {
        // quote details
        let invoiceInfo = params.invoiceData.items[0];
        let fields = {
          CustOrderId: { label: invoiceInfo.OrderReference, value: tools.setEmpty(invoiceInfo.CustOrderId) },
          OrderReference: tools.setEmpty(invoiceInfo.OrderReference),
          InvoiceNo: tools.setEmpty(invoiceInfo.InvoiceNo),
          Status: tools.setEmpty(invoiceInfo.Status),
          InvoiceDate: tools.setEmpty(invoiceInfo.InvoiceDate),
          InvoiceType: { label: invoiceInfo.InvoiceType, value: tools.setEmpty(invoiceInfo.InvoiceType) },
          CustomerId: { label: invoiceInfo.CustomerId, value: tools.setEmpty(invoiceInfo.CustomerId) },
          CustPoDate: tools.setEmpty(invoiceInfo.CustPoDate),
          CreditDays: tools.setEmpty(invoiceInfo.CreditDays),
          TransactionSource: { label: invoiceInfo.TransactionSource, value: tools.setEmpty(invoiceInfo.TransactionSource) },
          SalesPersonId: { label: invoiceInfo.SalesPersonId, value: tools.setEmpty(invoiceInfo.SalesPersonId) },
          DueDate: tools.setEmpty(invoiceInfo.DueDate),
          AccountingDate: tools.setEmpty(invoiceInfo.AccountingDate),
          Currency: { label: invoiceInfo.Currency, value: tools.setEmpty(invoiceInfo.Currency) },
          ExchangeRate: tools.setEmpty(invoiceInfo.ExchangeRate),
          ExchangeRateType: { label: invoiceInfo.ExchangeRateType, value: tools.setEmpty(invoiceInfo.ExchangeRateType) },
          OrderDate: tools.setEmpty(invoiceInfo.OrderDate),
          GstEnabled: tools.setEmpty(invoiceInfo.GstEnabled),
          Comments: tools.setEmpty(invoiceInfo.Comments),
          BillToSiteId: { label: invoiceInfo.BillToSiteId, value: tools.setEmpty(invoiceInfo.BillToSiteId) },
          ShipToSiteId: { label: invoiceInfo.ShipToSiteId, value: tools.setEmpty(invoiceInfo.ShipToSiteId) },
          DiscType: { label: invoiceInfo.DiscType, value: tools.setEmpty(invoiceInfo.DiscType) },
          TollFee: tools.setEmpty(invoiceInfo.TollFee),
          DiscAmtPer: tools.setEmpty(invoiceInfo.DiscAmtPer),
          TransportCharge: tools.setEmpty(invoiceInfo.TransportCharge),
          DespatchMode: { label: invoiceInfo.DespatchMode, value: tools.setEmpty(invoiceInfo.DespatchMode) },
          AmtInWords: tools.setEmpty(invoiceInfo.AmtInWords),
          isUpdate: false
        }
        if (response) {
          // invoice items
          response.items.map((item, key) => {
            sData.itemList.push({
              InvoiceDtlsId: item.InvoiceDtlsId,
              InvoiceId: item.InvoiceId,
              ItemId: { label: item.ItemName, value: item.ItemId },
              Uom: { label: item.UomName, value: item.Uom },
              Quantity: item.Quantity,
              OrderDtlRate: item.OrderDtlRate,
              DiscPer: item.DiscPer,
              TaxPer: item.TaxPer,
              ReqDeliveryDate: item.ReqDeliveryDate,
              PromisedDelDate: item.PromisedDelDate,
              SchShipDate: item.SchShipDate,
              DtlStatus: { label: item.DtlStatusName, value: parseInt(item.DtlStatus) },
              isNew: false,
              isUpdate: false
            })
          })
        }
        this.setState({
          fields,
          sData,
        }, () => {
          this.setLoading(false);
          this.calculateItem();
          this.getLocation("BillToSiteId");
          this.getLocation("ShipToSiteId");
        })
      },
      (error) => {
        common.snack("E", "Unable to load invoice items!");
        history.push("/crm/invoice");
      }
    )
  }

  getSalesOrder(name) {
    if (name == "CustOrderId") {
      const { fields, sData } = this.state;
      sData.itemList.map((item, key) => {
        if (!item.isNew) {
          sData.itemDeleted.push(item.CustOrderDtlId);
        }
      })
      if (!fields.CustOrderId.value) {
        this.setState(prev => ({
          sData: {
            ...prev.sData,
            itemList: []
          }
        }), () => {
          this.calculateItem();
        })
      } else {
        let filter = '?q=CustOrderId=' + fields.CustOrderId.value;
        let url = envConfig.BASE_API + Services.CRM_SALES_ORDER_DETAILS + filter;

        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              // invoice items
              response.items.map((item, key) => {
                sData.itemList.push({
                  InvoiceDtlsId: item.InvoiceDtlsId,
                  CustQuotId: item.CustQuotId,
                  ItemId: { label: item.ItemName, value: item.ItemId },
                  Uom: { label: item.UomName, value: item.Uom },
                  Quantity: item.Qty,
                  OrderDtlRate: item.Rate,
                  DiscPer: item.DiscPer,
                  TaxPer: item.TaxPer,
                  ReqDeliveryDate: item.ReqDeliveryDate,
                  PromisedDelDate: item.PromisedDelDate,
                  SchShipDate: item.SchShipDate,
                  DtlStatus: { label: item.DtlOrderStatusName, value: item.OrderDtlStatus },
                  isNew: false,
                  isUpdate: false
                })
              })
            }
            this.setState({
              sData,
            }, () => {
              this.calculateItem();
            })
          },
          (error) => { }
        )
      }
    }
  }

  getLocation(name) {
    if (name == "BillToSiteId" || name == "ShipToSiteId") {
      const { fields, sData } = this.state;
      sData.locationInfo[name] = tools.ObjectEmpty(sData.locationInfo[name]);
      sData.locationInfo[name].isLoading = "yes";
      this.setState({
        sData
      })
      if (fields[name]) {
        let filter = '/' + fields[name].value;
        let url = envConfig.BASE_API + Services.CRM_CUSTOMER_SITE + filter;
        restServices.getRequest(
          url,
          (response) => {
            if (response) {
              sData.locationInfo[name] = {
                isLoading: "",
                Address1: response.Address1,
                Address2: response.Address2,
                Landmark: response.Landmark
              };
            }
            sData.locationInfo[name].isLoading = "";
            this.setState({
              sData
            })
          },
          (error) => {
            sData.locationInfo[name].isLoading = "";
            this.setState({
              sData
            })
          }
        )
      }
    }
  }

  // submit handler
  onSubmitItem() {
    const { editKey, sData, fieldsItem } = this.state;
    this.setSubmitItem(true);
    let isValid = this.validateItemForm();
    if (isValid) {
      if (editKey !== "") {
        sData.itemList[editKey] = tools.ObjectAssign(fieldsItem);
      } else {
        sData.itemList.push(tools.ObjectAssign(fieldsItem));
      }
      this.setState({
        sData,
        fieldsItem: tools.ObjectAssign(this.getItem()),
        isModal: false
      }, () => {
        this.calculateItem();
        this.setSubmitItem(false);
        common.snack("S", `Item has been ${editKey !== "" ? 'updated' : 'added'}`);
      });
    }
  }

  onSubmit() {
    this.setSubmit(true);
    const { params, fields, sData } = this.state;
    let isValid = this.validateForm();
    if (isValid) {
      this.setSubmit(false);
      this.setAdding(true);
      let paramsData = this.getParam();
      if (!params.invoiceId) {
        var formAttributes = Object.assign({}, paramsData);
        let url = encodeURI(envConfig.BASE_API + Services.CRM_INVOICE);
        restServices.postRequest(
          url,
          formAttributes,
          (response) => {
            if (response) {
              // add items
              if (sData.itemList.length > 0) {
                let itemParam = [];
                sData.itemList.map((item, key) => {
                  let formData = {
                    id: "key_" + crm.uniqueKey(),
                    path: "/CholaSalesInvoiceDtls",
                    operation: "create",
                    payload: this.getItemParam(response.InvoiceId, item),
                  };
                  itemParam.push(formData);
                });
                let formAttr = { parts: itemParam };
                url = encodeURI(envConfig.BASE_API);
                restServices.batchRequest(url, formAttr, (response) => {
                  common.snack("S", "Invoice Successfully Added");
                  history.push("/crm/invoice");
                }, (error) => {
                  this.setAdding(false);
                  common.snack("E", "Failed to add invoice items");
                });
              } else {
                common.snack("S", "Invoice Successfully Added");
                history.push("/crm/invoice");
              }
            }
          }, (error) => {
            this.setAdding(false);
            common.snack("E", "Failed to add invoice");
          }
        );
      } else {
        let itemParam = [];
        let formData = {};
        // update invoice
        if (fields.isUpdate || this.props.badgeStatus !== fields.Status) {
          formData = {
            id: "key_" + crm.uniqueKey(),
            path: "/CholaSalesInvoice1/" + params.invoiceId,
            operation: "update",
            payload: paramsData,
          };
          itemParam.push(formData);
        }
        // update invoice items
        sData.itemList.map((item, key) => {
          if (item.isNew) {
            formData = {
              id: "key_" + crm.uniqueKey(),
              path: "/CholaSalesInvoiceDtls",
              operation: "create",
              payload: this.getItemParam(params.invoiceId, item),
            };
            itemParam.push(formData);
          } else if (item.isUpdate) {
            formData = {
              id: "key_" + crm.uniqueKey(),
              path: "/CholaSalesInvoiceDtls/" + item.InvoiceDtlsId,
              operation: "update",
              payload: this.getItemParam(params.invoiceId, item),
            };
            itemParam.push(formData);
          }
        });

        // delete quote item
        sData.itemDeleted.map((item, key) => {
          formData = {
            id: "key_" + crm.uniqueKey(),
            path: "/CholaSalesInvoiceDtls/" + item,
            operation: "delete",
            payload: {},
          }
          itemParam.push(formData);
        });

        if (itemParam.length === 0) {
          common.snack("W", "You have not modifed any items!");
          this.setAdding(false);
        } else {
          let formAttr = { parts: itemParam };
          let url = encodeURI(envConfig.BASE_API);
          restServices.batchRequest(url, formAttr, (response) => {
            common.snack("S", "Invoice Successfully Updated");
            history.push("/crm/invoice");
          }, (error) => {
            this.setAdding(false);
            common.snack("E", "Failed to update invoice");
          });
        }
      }
    } else {
      common.snack("E", "Please fill all the required fields.");
    }
  }

  // support
  addItem() {
    this.setState({
      editKey: "",
      isModal: true
    })
  }

  editItem(key) {
    const { sData } = this.state;
    this.setState({
      isModal: true,
      editKey: key,
      fieldsItem: tools.ObjectAssign(sData.itemList[key]),
    })
  }

  removeItem(key) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Delete Confirmation</h1>
            <p>Are you sure you want to delete this item?</p>
            <button className="noBtn" onClick={onClose}>
              No
            </button>
            <button
              onClick={() => {
                const { sData } = this.state;
                let item = sData.itemList[key];
                if (!item.isNew) {
                  sData.itemDeleted.push(item.InvoiceDtlsId);
                }
                this.setState({
                  fieldsItem: sData.itemList.splice(key, 1),
                  sData
                }, () => {
                  onClose();
                  this.calculateItem();
                })
              }}
              className="yesBtn"
            >
              Yes
            </button>
          </div>
        );
      },
    });
  }

  cancelItem() {
    this.setState({
      isModal: false,
      formSubmitItem: false,
      fieldsItem: tools.ObjectAssign(this.getItem())
    })
  }

  calculateItem() {
    const { sData } = this.state;
    let rate = 0;
    let discount = 0;
    let subTotal = 0;
    let tax = 0;
    let grandTotal = 0;
    sData.itemList.map((item, key) => {
      let discountRate = ((Number(item.OrderDtlRate) / 100) * Number(item.DiscPer))
      let subTotalRate = Number(item.OrderDtlRate) - Number(discountRate);
      rate += Number(item.OrderDtlRate);
      discount += Number(discountRate);
      subTotal += subTotalRate;
      tax += ((subTotalRate / 100) * Number(item.TaxPer));
    })
    grandTotal = (subTotal + tax);
    sData.amountInfo = {
      rate: rate.toFixed(2),
      discount: discount.toFixed(2),
      subTotal: subTotal.toFixed(2),
      tax: tax.toFixed(2),
      grandTotal: grandTotal.toFixed(2)
    };
    this.setState({
      sData
    })
  }

  getItem() {
    return {
      InvoiceDtlsId: "",
      InvoiceId: "",
      ItemId: selectDefault,
      Uom: selectDefault,
      Quantity: "",
      OrderDtlRate: "",
      DiscPer: "",
      TaxPer: "",
      ReqDeliveryDate: "",
      PromisedDelDate: "",
      SchShipDate: "",
      DtlStatus: selectDefault,
      isNew: true,
      isUpdate: false
    }
  }

  getItemParam(InvoiceId, fieldsItem) {
    const { userInfo } = this.state;
    let params = {
      InvoiceId: InvoiceId,
      ItemId: fieldsItem.ItemId.value,
      Uom: fieldsItem.Uom.value,
      Quantity: parseFloat(fieldsItem.Quantity),
      OrderDtlRate: parseFloat(fieldsItem.OrderDtlRate),
      DiscPer: parseFloat(fieldsItem.DiscPer),
      TaxPer: parseFloat(fieldsItem.TaxPer),
      ReqDeliveryDate: fieldsItem.ReqDeliveryDate || null,
      PromisedDelDate: fieldsItem.PromisedDelDate || null,
      SchShipDate: fieldsItem.SchShipDate || null,
      DtlStatus: fieldsItem.DtlStatus.value,
      TenantId: userInfo.pTenantId,
      OrgId: userInfo.pOrgId,
    };
    return params;
  }

  getParam() {
    const { fields, userInfo } = this.state;
    let params = {
      CustOrderId: fields.CustOrderId.value,
      OrderReference: fields.CustOrderId.label,
      InvoiceNo: fields.InvoiceNo,
      Status: this.props.badgeStatus,
      InvoiceDate: fields.InvoiceDate || null,
      InvoiceType: fields.InvoiceType.value,
      CustomerId: fields.CustomerId.value,
      CustPoDate: fields.CustPoDate || null,
      CreditDays: fields.CreditDays,
      TransactionSource: fields.TransactionSource.value,
      SalesPersonId: fields.SalesPersonId.value,
      DueDate: fields.DueDate || null,
      AccountingDate: fields.AccountingDate || null,
      Currency: fields.Currency.value,
      ExchangeRate: fields.ExchangeRate,
      ExchangeRateType: fields.ExchangeRateType.value,
      OrderDate: fields.OrderDate || null,
      GstEnabled: "N",
      Comments: fields.Comments,
      BillToSiteId: fields.BillToSiteId.value,
      ShipToSiteId: fields.ShipToSiteId.value,
      DiscType: fields.DiscType.value,
      TollFee: fields.TollFee,
      DiscAmtPer: fields.DiscAmtPer,
      TransportCharge: fields.TransportCharge,
      DespatchMode: fields.DespatchMode.value,
      AmtInWords: fields.AmtInWords,
      TenantId: userInfo.pTenantId,
      OrgId: userInfo.pOrgId,
    }
    return params;
  }

  // handler
  // field
  onChange = (e, type) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = tools.validateType(value, type);
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  onSelect = (value, name) => {
    const { fields } = this.state;
    fields[name] = value || selectDefault;
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
      this.getLocation(name);
      this.getSalesOrder(name);
    })
  }

  onDateChange = (date, name) => {
    const { fields } = this.state;
    fields[name] = date;
    fields.isUpdate = true;
    this.setState({ fields }, () => {
      this.validateForm();
    })
  }

  // fields item
  onItemChange = (e, type) => {
    const { fieldsItem } = this.state;
    const { name, value } = e.target;
    fieldsItem[name] = tools.validateType(value, type);
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  onItemSelect = (value, name) => {
    const { fieldsItem } = this.state;
    fieldsItem[name] = value || selectDefault;
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  onItemDateChange = (date, name) => {
    const { fieldsItem } = this.state;
    fieldsItem[name] = date;
    fieldsItem.isUpdate = true;
    this.setState({ fieldsItem }, () => {
      this.validateItemForm();
    })
  }

  // validate
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (errors[key].isObject && fields[key].value == "") {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (errors[key].minLength && fields[key].length < errors[key].minLength) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (errors[key].isValidEmail && !crm.ValidateEmail(fields[key])) {
        errors[key].error = errors[key].isValidEmail;
        isValid = false;
      } else if (errors[key].isValidMultiEmail) {
        let emails = fields[key].split(",");
        for (let i = 0; i < emails.length; i++) {
          if (!crm.ValidateEmail(emails[i])) {
            errors[key].error = errors[key].isValidMultiEmail;
            isValid = false;
          }
        }
      } else if (errors[key].matchPassword) {
        if (fields[key] !== fields["password"]) {
          errors[key].error = errors[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  validateItemForm() {
    const { fieldsItem, errorsItem } = this.state;
    let isValid = true;
    for (var key of Object.keys(errorsItem)) {
      errorsItem[key].error = "";
      if (errorsItem[key].isObject && fieldsItem[key].value == "") {
        errorsItem[key].error = errorsItem[key].isReq;
        isValid = false;
      } else if (fieldsItem[key] == "" || fieldsItem[key] == null) {
        errorsItem[key].error = errorsItem[key].isReq;
        isValid = false;
      } else if (errorsItem[key].minLength && fieldsItem[key].length < errorsItem[key].minLength) {
        errorsItem[key].error = errorsItem[key].isMinLengthReq;
        isValid = false;
      } else if (errorsItem[key].isValidEmail && !crm.ValidateEmail(fieldsItem[key])) {
        errorsItem[key].error = errorsItem[key].isValidEmail;
        isValid = false;
      } else if (errorsItem[key].isValidMultiEmail) {
        let emails = fieldsItem[key].split(",");
        for (let i = 0; i < emails.length; i++) {
          if (!crm.ValidateEmail(emails[i])) {
            errorsItem[key].error = errorsItem[key].isValidMultiEmail;
            isValid = false;
          }
        }
      } else if (errorsItem[key].matchPassword) {
        if (fieldsItem[key] !== fieldsItem["password"]) {
          errorsItem[key].error = errorsItem[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errorsItem,
    });
    return isValid;
  }

  // others
  setDate(date) {
    if (date != "" && date != null) {
      return moment(date).format("MMM DD, YYYY")
    } else {
      return "-";
    }
  }

  // common
  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  setAdding(status) {
    this.setState({ isAdding: status })
  }

  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setSubmitItem(status) {
    this.setState({ formSubmitItem: status })
  }

  setModal(status) {
    this.setState({ isModal: status });
  }

  render() {
    const {
      params,
      isLoading,
      isAdding,
      isAddingItem,
      isModal,
      fields,
      fieldsItem,
      errors,
      errorsItem,
      sData,
      formSubmit,
      formSubmitItem,
      userPermissions,
    } = this.state;
    return (
      !userPermissions.access ? (
        <AccessDenied />
      ) : (
        isLoading ? (
          <Loader />
        ) : (
          <div>
            {/* Invoice */}
            <Row className="whiteBgRow">
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Invoice Information</h2>
                </div>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Sales Order No</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="CustOrderId"
                        type="FindSalesOrder"
                        value={fields.CustOrderId}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Invoice No*</Form.Label>
                      <Form.Control
                        type="text"
                        name="InvoiceNo"
                        value={fields.InvoiceNo}
                        onChange={(e) => this.onChange(e)}
                      />
                      {errors.InvoiceNo.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.InvoiceNo.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Invoice Date</Form.Label>
                      <DatePicker
                        name="InvoiceDate"
                        value={fields.InvoiceDate ? moment(fields.InvoiceDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "InvoiceDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Invoice Type*</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="InvoiceType"
                        type="CHOLA_INVOICE_TYPE"
                        lookup={true}
                        value={fields.InvoiceType}
                        onSelect={this.onSelect}
                      />
                      {errors.InvoiceType.error !== "" && formSubmit && (
                        <Form.Text className="error">{errors.InvoiceType.error}</Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Customer</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="CustomerId"
                        type="FindCustomers"
                        value={fields.CustomerId}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Customer PO Date</Form.Label>
                      <DatePicker
                        name="CustPoDate"
                        value={fields.CustPoDate ? moment(fields.CustPoDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "CustPoDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Credit Days</Form.Label>
                      <Form.Control
                        type="text"
                        name="CreditDays"
                        value={fields.CreditDays}
                        onChange={(e) => this.onChange(e, "n")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Transaction Source</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="TransactionSource"
                        type="CHOLA_INV_TRANS_SOURCE"
                        lookup={true}
                        value={fields.TransactionSource}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Sales Person</Form.Label>
                      <FormFieldAuto
                        isRender={true}
                        name="SalesPersonId"
                        type="FindEmployee"
                        value={fields.SalesPersonId}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Due Date</Form.Label>
                      <DatePicker
                        name="DueDate"
                        value={fields.DueDate ? moment(fields.DueDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "DueDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Accounting Date</Form.Label>
                      <DatePicker
                        name="AccountingDate"
                        value={fields.AccountingDate ? moment(fields.AccountingDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "AccountingDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Invoice Currency</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="Currency"
                        type="CURRENCIES"
                        lookup={true}
                        value={fields.Currency}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Exchange Rate</Form.Label>
                      <Form.Control
                        type="text"
                        name="ExchangeRate"
                        value={fields.ExchangeRate}
                        onChange={(e) => this.onChange(e, "n")}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Exchange Rate Type</Form.Label>
                      <FormFieldSelect
                        isRender={true}
                        name="ExchangeRateType"
                        type="CHOLA_INV_EXCH_RATE_TYPE"
                        lookup={true}
                        value={fields.ExchangeRateType}
                        onSelect={this.onSelect}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} >
                    <Form.Group>
                      <Form.Label>Order Date</Form.Label>
                      <DatePicker
                        name="OrderDate"
                        value={fields.OrderDate ? moment(fields.OrderDate).toDate() : null}
                        onChange={(date) => this.onDateChange(date, "OrderDate")}
                        format="dd/MM/yyyy"
                        autoComplete="off"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <Form.Group>
                      <Form.Label>Comments</Form.Label>
                      <Form.Control
                        type="text"
                        name="Comments"
                        value={fields.Comments}
                        onChange={(e) => this.onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Product */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <div className="formHead">
                  <h2>Products On Invoice</h2>
                  <Button className="addBtn" type="button" onClick={() => this.addItem()}>Add Item</Button>
                </div>
                {/* Delivery Section */}
                <Row style={{ marginTop: 30 }}>
                  <Col sm={12}>
                    <Row className="mb-3">
                      <Col sm={12}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>HSN Code</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Discount %</TableCell>
                                <TableCell>Tax %</TableCell>
                                <TableCell>Requested Delivery Date</TableCell>
                                <TableCell>Promised Delivery Date</TableCell>
                                <TableCell>Scheduled Delivery Date</TableCell>
                                <TableCell>Approved Status</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sData.itemList.length === 0 ? (
                                <TableRow>
                                  <TableCell align="center" colSpan={11}>Sorry no items added</TableCell>
                                </TableRow>
                              ) : sData.itemList.map((item, key) => (
                                <TableRow key={key}>
                                  <TableCell>{item.ItemId.label}</TableCell>
                                  <TableCell>{item.Uom.label}</TableCell>
                                  <TableCell>{item.Quantity}</TableCell>
                                  <TableCell>{item.OrderDtlRate}</TableCell>
                                  <TableCell>{item.DiscPer}</TableCell>
                                  <TableCell>{item.TaxPer}</TableCell>
                                  <TableCell>{this.setDate(item.ReqDeliveryDate)}</TableCell>
                                  <TableCell>{this.setDate(item.PromisedDelDate)}</TableCell>
                                  <TableCell>{this.setDate(item.SchShipDate)}</TableCell>
                                  <TableCell>{item.DtlStatus.label}</TableCell>
                                  <TableCell>
                                    <Tooltip title="Edit">
                                      <img src={common.loadImg("edit.svg")} onClick={() => this.editItem(key)} />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <img src={common.loadImg("delete.svg")} className="ml-2" onClick={() => this.removeItem(key)} />
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Toll Free</Form.Label>
                              <Form.Control
                                type="text"
                                name="TollFee"
                                value={fields.TollFee}
                                onChange={(e) => this.onChange(e, "n")}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Transport Charge</Form.Label>
                              <Form.Control
                                type="text"
                                name="TransportCharge"
                                value={fields.TransportCharge}
                                onChange={(e) => this.onChange(e, "n")}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Mode Of Dispatch</Form.Label>
                              <FormFieldSelect
                                isRender={true}
                                name="DespatchMode"
                                type="SHIPPING_MODE"
                                lookup={true}
                                value={fields.DespatchMode}
                                onSelect={this.onSelect}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group>
                              <Form.Label>Amount In Words</Form.Label>
                              <Form.Control
                                type="text"
                                name="AmtInWords"
                                value={fields.AmtInWords}
                                onChange={(e) => this.onChange(e)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={6} className="right-text">
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Rate:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.rate}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Total Discount Amount:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.discount}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Sub Total:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.subTotal}</span>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>Tax:</span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>{sData.amountInfo.tax}</span>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                              <span>
                                <strong>Grand Total:</strong>
                              </span>
                            </div>
                            <div style={{ width: "20%" }}>
                              <span>
                                <strong>
                                  {sData.amountInfo.grandTotal}
                                </strong>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Address */}
            <Row className="whiteBgRow" style={{ marginTop: 28 }}>
              <Col sm={12} className="formWhiteBg">
                <Row className="mb-3">
                  {/* Bill to site section */}
                  <Col sm={6}>
                    <Col sm={12} className="boxBorder">
                      <Row className="bgBillSite">
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Label>BILL TO SITE</Form.Label>
                            <FormFieldAuto
                              isRender={true}
                              name="BillToSiteId"
                              type="FindSites"
                              value={fields.BillToSiteId}
                              onSelect={this.onSelect}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {sData.locationInfo.BillToSiteId.isLoading === "yes" ? (
                        <Row><Col sm={12}><Loader /></Col></Row>
                      ) : (
                        <React.Fragment>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillToSiteId.Address1}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillToSiteId.Address2}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Land Mark</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.BillToSiteId.Landmark}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Col>
                  {/* Ship to site section */}
                  <Col sm={6}>
                    <Col sm={12} className="boxBorder">
                      <Row className="bgBillSite">
                        <Col sm={12}>
                          <Form.Group>
                            <Form.Label>SHIP TO SITE</Form.Label>
                            <FormFieldAuto
                              isRender={true}
                              name="ShipToSiteId"
                              type="FindSites"
                              value={fields.ShipToSiteId}
                              onSelect={this.onSelect}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {sData.locationInfo.ShipToSiteId.isLoading === "yes" ? (
                        <Row><Col sm={12}><Loader /></Col></Row>
                      ) : (
                        <React.Fragment>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToSiteId.Address1}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToSiteId.Address2}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Form.Group>
                                <Form.Label>Land Mark</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={sData.locationInfo.ShipToSiteId.Landmark}
                                  readOnly
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* Modal */}
            <Modal size="lg" show={isModal} backdrop="static">
              <Modal.Header>
                <Modal.Title>Add Item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Item Number</Form.Label>
                          <FormFieldAuto
                            isRender={true}
                            name="ItemId"
                            type="FindItems"
                            value={fieldsItem.ItemId}
                            onSelect={this.onItemSelect}
                          />
                          {errorsItem.ItemId.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.ItemId.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Uom</Form.Label>
                          <FormFieldSelect
                            isRender={true}
                            name="Uom"
                            type="UOM"
                            lookup={true}
                            value={fieldsItem.Uom}
                            onSelect={this.onItemSelect}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Quantity*</Form.Label>
                          <Form.Control
                            type="text"
                            name="Quantity"
                            value={fieldsItem.Quantity}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                          {errorsItem.Quantity.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.Quantity.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Rate*</Form.Label>
                          <Form.Control
                            type="text"
                            name="OrderDtlRate"
                            value={fieldsItem.OrderDtlRate}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                          {errorsItem.OrderDtlRate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.OrderDtlRate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Discount %</Form.Label>
                          <Form.Control
                            type="text"
                            name="DiscPer"
                            value={fieldsItem.DiscPer}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Tax %</Form.Label>
                          <Form.Control
                            type="text"
                            name="TaxPer"
                            value={fieldsItem.TaxPer}
                            onChange={(e) => this.onItemChange(e, "f")}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Requested Delivery Date*</Form.Label>
                          <DatePicker
                            name="ReqDeliveryDate"
                            value={fieldsItem.ReqDeliveryDate ? moment(fieldsItem.ReqDeliveryDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "ReqDeliveryDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.ReqDeliveryDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.ReqDeliveryDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Promised Delivery Date*</Form.Label>
                          <DatePicker
                            name="PromisedDelDate"
                            value={fieldsItem.PromisedDelDate ? moment(fieldsItem.PromisedDelDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "PromisedDelDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.PromisedDelDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.PromisedDelDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Scheduled Delivery Date*</Form.Label>
                          <DatePicker
                            name="SchShipDate"
                            value={fieldsItem.SchShipDate ? moment(fieldsItem.SchShipDate).toDate() : null}
                            onChange={(date) => this.onItemDateChange(date, "SchShipDate")}
                            format="dd/MM/yyyy"
                            autoComplete="off"
                          />
                          {errorsItem.SchShipDate.error !== "" && formSubmitItem && (
                            <Form.Text className="error">{errorsItem.SchShipDate.error}</Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Approved Status</Form.Label>
                          <FormFieldAuto
                            isRender={true}
                            name="DtlStatus"
                            type="FindTransactionStatus"
                            eType="INVOICE"
                            value={fieldsItem.DtlStatus}
                            onSelect={this.onItemSelect}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="saveBtn"
                  onClick={() => this.onSubmitItem()}
                  type="button"
                  disabled={isAddingItem}
                >{isAddingItem ? "Processing..." : "Submit"}</Button>
                <Button
                  variant="primary"
                  onClick={() => this.cancelItem()}
                  className="cancelBtn btn btn-primary"
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Action */}
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  onClick={() => this.onSubmit()}
                  type="button"
                  disabled={isAdding}
                >{isAdding ? "Processing..." : (!params.invoiceId ? "Save" : "Update")}</Button>
                <Button
                  className="cancelBtn"
                  onClick={() => history.push("/crm/invoice")}
                  type="button"
                  disabled={isAdding}
                >Cancel</Button>
              </Col>
            </Row>
          </div >
        )
      )
    );
  }
}
export default InvoiceCrud;